import React, { useState, useEffect } from "react";
import PistonTabs from 'components/alerts/pistonsTabs';
import SyncCharts from 'components/charts/genericSyncCharts/syncCharts';
import syncChartsOptions from 'components/charts/genericSyncCharts/syncChartsOptions';
import ChartsConfig from 'Utilities/charts';

const PistonsMonitoring = (props) => {

    const [syncCharts, setSyncCharts] = useState(syncChartsOptions());

    useEffect(() => {
        if(!props.chartData) return;
        let options = syncChartsOptions();
        options["series"] = ChartsConfig.syncChartsSeries.pistonsSyncChartSeries;
        options["yAxis"] = ChartsConfig.syncChartsAxes.pistonsSyncChartAxis;
        options.chart.marginTop = 35;
        options.chart.height = 550;
        options.xAxis.offset = -50;
        options.elementId = 'alert-pistons';

        if(props.chartData && props.chartData.series && props.chartData.series.length > 0) {
            options.series[0].data = props.chartData.series[0].data;
            options.series[1].data = props.chartData.series[1].data;
        }

        setSyncCharts(options);

        // eslint-disable-next-line
    }, [props.chartData])

    return (
        <div className='full-height pistonsMonitoring row piston-sync-charts-container'>
            <div className='pistonsMonitoring__tabs-container full-height'>
                <PistonTabs data={props} />
            </div>
            <div className='pistonsMonitoring__chart-container full-height '>
                <div className="headline2 chartTitle">PISTON {props.chartData.activePiston + 1}</div>
                <SyncCharts options={syncCharts} />
            </div>
        </div>
    );
}
export default PistonsMonitoring;