const initialNotificationsState = {
    showNotification: false,
    notificationText: ''
}

// notifications reducer
const notifications = (state = initialNotificationsState, action) => {
    switch(action.type) {
        case 'notifications/setShowNotification': {
            return {
                ...state,
                showNotification: action.payload
            };
        }
        case 'notifications/setNotificationText': {
            return {
                ...state,
                notificationText: action.payload
            };
        }
        default: 
            return state;
    }
}

export default notifications;