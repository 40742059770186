import service from 'common/js/service';
import Storage from 'common/js/Storage';
import moment from 'moment';
import {Urls} from 'common/js/urls';
import {reportsStore, userStore, vesselGroupStore} from 'common/store/storeUtils';

const userUtils = {
    // update user
    updateProfile: async (data) => service.updateProfile(data),

    changeTheme: async (data) => service.changeTheme(data),

    changePassword: async (data) => service.changePassword(data),

    updateUserFleetAssignment: async ({user}) => service.userAction({user, type: 'updateUserFleetAssignment'}),

    updateUserType: async ({user}) => service.userAction({user, type: 'toggleUserType'}),

    updateUserStatus: async ({user}) => service.userAction({user, type: 'toggleUserStatus'}),

    addUser: async ({user}) => service.userAction({user, type: 'newFleetUser'}),

    // user terms
    acceptTerms: async (userId, token) => {
        const date = moment.utc(new Date()).valueOf();
        const response = await service.acceptTerms({userId, token, date});

        //success response of this request is 204
        if(!response) {
            const userTerms = {
                accepted: true,
                acceptanceDate: date,
                effectiveDate: null
            };
            Storage.addCookie('userTerms', userTerms);
            return userTerms;
        } else if (response) {
            response.errorMessage = 'Something went wrong! Cannot accept Terms & Conditions';
        }
        return response;
    },

    activate: async ({userId, token}) => service.activateUser({userId, token}),

    getUserTermsInfo: async (userId, token) => service.getUserTermsInfo({userId, token}),

    getUserTerms: async () => {
        const user = {...userStore().user};
        const userTermsInfo = await userUtils.getUserTermsInfo(user.uuid, Storage.getCookie('token'));
        if (userTermsInfo && 'error' in userTermsInfo) return userTermsInfo;
        const {data} = userTermsInfo;
        data.effectiveDate = null;
        if (!data.accepted) {
            const futureDate = await service.getFutureTermsDate(Storage.getCookie('token'));
            if (futureDate === null) {
                const currentDate = await service.getTermsDate(Storage.getCookie('token'));
                if (currentDate && ('error' in currentDate)) {
                    return currentDate;
                } else {
                    data.effectiveDate = moment(currentDate.effectiveDate).valueOf();
                }
            } else if (futureDate && 'error' in futureDate) {
                return futureDate;
            } else {
                data.effectiveDate = moment(futureDate.effectiveDate).valueOf();
            }
        } else {
            data.acceptanceDate = moment(data.acceptanceDate).valueOf();
        }
        return data;
    },

    logout: async ({enableLoader = true, onlyLocal = false} = {}) => {
        const response = await service.logoutUser({enableLoader});
        Storage.clearSession();
        window.location.replace(Urls.ServicesUrl().redirectToAuthURL);
        return response;
    },
    // getFleetAssignments: async () => {
    //     if (!Storage.getCookie('user').company.id) return {error:true};
    //     return await service.getFleetAssignments({companyID: Storage.getCookie('user').company.id});
    // },

    getCompanyVessels: async (timestamp) => {
        if (!userStore().user.company?.id) return {error:true};
        return await service.getCompanyVessels({companyID: userStore().user.company.id, timeStamp: timestamp ? timestamp : moment().valueOf()});
    },
    getCompanyVesselsGroupReport: async () => {
        if (!userStore().user.company.id) return {error:true};
        return await service.getCompanyVesselsGroupReport({companyID: userStore().user.company.id, timeStamp: moment(reportsStore().fromTo.to).valueOf(), vesselGroupId: vesselGroupStore().selectedVesselGroup});
    },
    getLicenseSupported: () => {
        if (!userStore().user.company.id) return {error:true};
        return userStore().user.licenseSupported;
    }
}

export default userUtils;