import React from 'react';
import PropTypes from 'prop-types';
import Column from 'components/core/Column';

const Layout = props => {
    return (
        props.layout.map((row, index) => (
            <div className={`row ${props.className}`} key={'row_'+index}>
                {row.columns.map((col, i) => <Column {...col} key={'column_'+i} />)}
            </div>
        ))
    )
}

Layout.propTypes = {
    layout: PropTypes.array.isRequired,
    className: PropTypes.string
};

Layout.defaultProps = {
    className: '',
    layout: []
};

export default Layout;