
import Utilities from 'Utilities/global';
import Wind from 'Utilities/wind';
import {licensing} from "common/store/licensing";
import { vesselStore, vesselGroupUtils, vesselUtils } from 'common/store/storeUtils';


let vesselIsTelegramConfigured = false;

const generalKPISPayload = {
    metrics: [
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {metricName: "speed_overground", aggregation: "AVG"},
                {metricName: "ais_speed_overground", aggregation: "AVG"},
                {metricName: "apparent_wind_speed_calc", aggregation: "AVG"},
                {metricName: "apparent_wind_speed", aggregation: "AVG"},
                {metricName: "true_wind_speed_calc", aggregation: "AVG"},
                {metricName: "true_current_speed_calc", aggregation: "AVG"},
                {metricName: "total_foConsumption_daily_leg", aggregation: "ABS"},
                {metricName: "ge_foConsumption_daily_leg", aggregation: "ABS"},
                {metricName: "bo_foConsumption_daily_leg", aggregation: "ABS"}
            ]
        },
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {metricName: "rpm", aggregation: "AVG"},
                {metricName: "me_foConsumption_daily_leg", aggregation: "ABS"}
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {metricName: "me_1_rpm_avg", aggregation: "AVG"},
                {metricName: "ve_1_speed_avg", aggregation: "AVG"},
                {metricName: "ve_1_wind_speed_avg", aggregation: "AVG"},
                {metricName: "ve_1_currents_speed_avg", aggregation: "AVG" },
                {metricName: "total_1_foConsumption_daily_leg", aggregation: "ABS"},
                {metricName: "me_1_foConsumption_daily_leg", aggregation: "ABS"},
                {metricName: "ge_1_foConsumption_daily_leg", aggregation: "ABS"},
                {metricName: "bo_1_foConsumption_daily_leg", aggregation: "ABS"}
            ]
        }
    ]
};

const getHeaderTemplateWithUnit = (label, unit, display, style) => {
    return `<div class="ag-cell-label-container" role="presentation">
              <div ref="eLabel" style="${style ? style : 'justify-content: center'}" class="ag-header-cell-label" role="presentation">${label}
                <span style="display: ${display}" class="table-title-regular">&nbsp;(${unit})</span>
              </div>
            </div>`;
}

const updateVesselIsTelegramConfigured = (res, widget) => {
    vesselIsTelegramConfigured = res?.data[widget?.vesselIds[0]]?.hasTelegramConfiguration;
}

const generalKPISColumnDefs = [
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg SOG', 'kn', 'block')},
        field: "avgSog",
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgSog', teKey: 'teSog'},
        maxWidth: 160,
        minWidth: 160,
        cellStyle: {maxWidth: '160', minWidth: '160'}
    },
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg RPM', 'rpm', 'block')},
        field: "avgRpm",
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgRpm', teKey: 'teRpm'},
        maxWidth: 250,
        minWidth: 160,
        cellStyle: {maxWidth: '250px', minWidth: '160px'}
    },
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg Wind Speed', 'bf', 'block')},
        field: "avgWindSpeed",
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgWindSpeed', teKey: 'teWindSpeed'},
        maxWidth: 250,
        minWidth: 160,
        cellStyle: {maxWidth: '250px', minWidth: '160px'}
    },
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg Currents', 'kn', 'block')},
        field: "avgCurrentSpeed",
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgCurrentSpeed', teKey: 'teCurrentSpeed'},
        maxWidth: 250,
        minWidth: 160,
        cellStyle: {maxWidth: '250px', minWidth: '160px'}
    },
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg. Total FC', 'mt', 'block')},
        field: "avgTotalConsumption",
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgTotalConsumption', teKey: 'teTotalConsumption'},
        maxWidth: 250,
        minWidth: 160,
        cellStyle: {maxWidth: '250px', minWidth: '160px'}
    },
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg. M/E FC', 'mt', 'block')},
        field: "avgMeConsumption",
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgMeConsumption', teKey: 'teMeConsumption'},
        maxWidth: 250,
        minWidth: 160,
        cellStyle: {maxWidth: '250px', minWidth: '160px'}
    },
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg. G/E FC', 'mt', 'block')},
        field: "avgGeConsumption",
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgGeConsumption', teKey: 'teGeConsumption'},
        maxWidth: 250,
        minWidth: 160,
        cellStyle: {maxWidth: '250px', minWidth: '160px'}
    },
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg. BLR FC', 'mt', 'block')},
        field: "avgBoConsumption",
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgBoConsumption', teKey: 'teBoConsumption'},
        maxWidth: 250,
        minWidth: 160,
        cellStyle: {maxWidth: '250px', minWidth: '160px'}
    },
];

const updateGeneralKPIS = (id, response, updateState, extraChartConfigs, widget) => {

    let fleetVessels = [];
    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    if (widget?.inDashboard) {
        const tempFleetId = vesselGroupUtils.getVesselGroupIdFromVesselId(widget.vesselIds);
        fleetVessels = vesselGroupUtils.getFleetVesselsFromFleetId(tempFleetId);
    } else {
        fleetVessels = vesselStore().fleetVessels;
    }
    if(!response?.series) return;
    const tableData = response.series.map(object => {

        const dataObj = vesselIsTelegramConfigured ? {
            avgSOG: ['-', '-'], avgRPM: ['-', '-'], avgWindSpeed: ['-', '-'], avgCurrentSpeed: ['-', '-'], avgTotalConsumption: ['-', '-'], avgMeConsumption: ['-', '-'], avgGeConsumption: ['-', '-'], avgBoConsumption: ['-', '-']
        } : {
            avgSOG: ['-'], avgRPM: ['-'], avgWindSpeed: ['-'], avgCurrentSpeed: ['-'], avgTotalConsumption: ['-'], avgMeConsumption: ['-'], avgGeConsumption: ['-'], avgBoConsumption: ['-']
        };

        dataObj.vesselObj = fleetVessels?.find(vessel => vessel.vesselId === object.vessel);
        dataObj.isGeneralKPISWidget = true;
        dataObj.isTelegramConfigured = vesselIsTelegramConfigured;

        // wind speed metrics have priority => 1) apparent_wind_speed_calc 2) apparent_wind_speed 3) true_wind_speed_calc
        dataObj.avgWindSpeed[0] = Utilities.renderNumber(Wind.knotsToBeauforts(Wind.getPrioritizedWindSpeedMetric(['apparent_wind_speed_calc', 'apparent_wind_speed', 'true_wind_speed_calc'], object), 0));

        object.values.forEach(keyGp => {

            if (!isLight && keyGp.metricName === 'speed_overground') dataObj.avgSOG[0] = Utilities.renderNumber(Utilities.roundToDigits(keyGp.value, 1));
            if (isLight && keyGp.metricName === 'ais_speed_overground') dataObj.avgSOG[0] = Utilities.renderNumber(Utilities.roundToDigits(keyGp.value, 1)); // handle ais sog data case (when vessel is light)
            if (keyGp.metricName === 've_1_speed_avg') dataObj.avgSOG[1] = Utilities.renderNumber(Utilities.roundToDigits(parseFloat(keyGp.value), 1));

            if (keyGp.metricName === 'rpm') dataObj.avgRPM[0] = Utilities.renderNumber(Utilities.roundToDigits(keyGp.value, 1));
            if (keyGp.metricName === 'me_1_rpm_avg') dataObj.avgRPM[1] = Utilities.renderNumber(Utilities.roundToDigits(parseFloat(keyGp.value), 1));

            if (keyGp.metricName === 've_1_wind_speed_avg' && dataObj.avgWindSpeed[1]) dataObj.avgWindSpeed[1] = Utilities.renderNumber(Utilities.roundToDigits(parseFloat(keyGp.value), 0));

            if (keyGp.metricName === 'true_current_speed_calc') dataObj.avgCurrentSpeed[0] = Utilities.renderNumber(Utilities.roundToDigits(keyGp.value, 1));
            if (keyGp.metricName === 've_1_currents_speed_avg') dataObj.avgCurrentSpeed[1] = Utilities.renderNumber(Utilities.roundToDigits(parseFloat(keyGp.value), 1));

            if (keyGp.metricName === 'total_foConsumption_daily_leg') dataObj.avgTotalConsumption[0] = Utilities.renderNumber(Utilities.roundToDigits(keyGp?.value, 1));
            if (keyGp.metricName === 'total_1_foConsumption_daily_leg') dataObj.avgTotalConsumption[1] = Utilities.renderNumber(Utilities.roundToDigits(keyGp?.value, 1));

            if (keyGp.metricName === 'me_foConsumption_daily_leg') dataObj.avgMeConsumption[0] = Utilities.renderNumber(Utilities.roundToDigits(keyGp?.value, 1));
            if (keyGp.metricName === 'me_1_foConsumption_daily_leg') dataObj.avgMeConsumption[1] = Utilities.renderNumber(Utilities.roundToDigits(keyGp?.value, 1));

            if (keyGp.metricName === 'ge_foConsumption_daily_leg') dataObj.avgGeConsumption[0] = Utilities.renderNumber(Utilities.roundToDigits(keyGp?.value, 1));
            if (keyGp.metricName === 'ge_1_foConsumption_daily_leg') dataObj.avgGeConsumption[1] = Utilities.renderNumber(Utilities.roundToDigits(keyGp?.value, 1));

            if (keyGp.metricName === 'bo_foConsumption_daily_leg') dataObj.avgBoConsumption[0] = Utilities.renderNumber(Utilities.roundToDigits(keyGp?.value, 1));
            if (keyGp.metricName === 'bo_1_foConsumption_daily_leg') dataObj.avgBoConsumption[1] = Utilities.renderNumber(Utilities.roundToDigits(keyGp?.value, 1));

        });

        return dataObj;
    });

    updateState(id, tableData, widget?.id);
}


export {generalKPISPayload, updateGeneralKPIS, updateVesselIsTelegramConfigured, generalKPISColumnDefs}
