import React from 'react';


const LoadMore = (props) => {
    return (
        <div className='loadMoreBtn' onClick={props.fetchMore}>
            Load More
        </div>
    );
}

export default LoadMore;