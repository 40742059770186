import React, {useEffect, useState} from 'react';
import {createFilterOptions} from '@material-ui/lab/Autocomplete';
import AutocompleteField from "../core/AutocompleteField";
import { vesselGroupStore, vesselUtils, dashboardUtils } from 'common/store/storeUtils';

const FleetsVesselsAutocompleteField = (props) => {

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [defaultValue, setDefaultValue] = useState({name: ''});
    const fieldLabel = props.level === "GROUP" ? "Group Name" : "Vessel Name";
    const errorValue = props.level === "GROUP" ? "No groups available with this name" : "No vessels available with this name";

    const renderPortOptions = option => {
        return (
            <div className="flex-centered-col-start">
                <div className="autocomplete-title">{option.name}</div>
            </div>
        )
    }

    const autocompleteProps = {
        autocompleteOptions: options,
        noOptionsText: errorValue,
        loading: loading,
        value: defaultValue,
        classNames: {
            wrapper: 'wrapper'
        }
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: option => option.name,
        limit: 10
    });

    useEffect(() => {
        if (props.level === "GROUP") {
            setOptions(vesselGroupStore().allVesselGroups);
        } else if (props.level === "VESSEL") {
            setOptions(dashboardUtils.widgetLisenseState(props));

            // Add-Edit mode default dropdown value
            (props.vesselIds?.length > 0) ? setDefaultValue({name: vesselUtils.getVesselName(props.vesselIds[0])}) : setDefaultValue({name: ''});
        }

        return () => {
            setLoading(false);
            props.setErrorEmptyField(false);
            setOptions(null);
        }

        // eslint-disable-next-line
    }, [])

    const handleChange = () => {
        props.setErrorEmptyField(false)
    }

    const handleUserSelection = (event, values) => {
        if (!values) return props.setErrorEmptyField(true);
        setDefaultValue(values);
    }

    return (
        <div className="fleets-vessels-autocomplete">
            <AutocompleteField
                {...autocompleteProps}
                id="material-autocomplete"
                name="material-autocomplete"
                label={fieldLabel}
                getOptionLabel={(option) => option && option.name}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={handleUserSelection}
                onInputChange={(event, newInputValue) => {
                    handleChange(event, newInputValue);
                }}
                renderError={() => {}}
                openOnFocus={false}
                clearOnEscape={true}
                forcePopupIcon={false}
                closeIcon=""
                filterOptions={filterOptions}
                renderOption={(option) => {
                    return renderPortOptions(option);
                }}
            />
        </div>
    );

};

export default FleetsVesselsAutocompleteField;
