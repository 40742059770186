import React, { useState, useEffect } from 'react';
import FuelConsumpTotals from 'components/monitoringCategories/overviewComponents/fuelConsumpTotals';
import SyncCharts from 'components/charts/genericSyncCharts/syncCharts';
import syncChartsOptions from 'components/charts/genericSyncCharts/syncChartsOptions';
import ChartsConfig from 'Utilities/charts';
import AddEventsButton from 'components/addEventsButton/addEventsButton';
import { ReactComponent as NoDataIcon } from "assets/img/app/global-imgs/no-data.svg";

const FuelConsump = props => {
    const [syncCharts, setSyncCharts] = useState(syncChartsOptions());

    useEffect(() => {
        let options = syncChartsOptions(true);
        options["series"] = ChartsConfig.syncChartsSeries.fuelConsumpSyncChartSeries();
        options["yAxis"] = ChartsConfig.syncChartsAxes.fuelConsumpSyncChartAxis;
        options.chart.height = 460;
        options.elementId = 'vessel-rating-chart';
        options.resetZoomAfterUpdate = true;
        options.plotOptions.column.maxPointWidth = 50;
        options.tooltip = {
            headerFormat: '<div class="flex-centered-col" style="background-color: transparent">' +
                '<div class="highcharts-fonts"><b>' +
                '{point.tooltipText}</b></div>' +
                '<div class="flex-centered-col">',
            footerFormat: '</div></div>',
            animation: false,
            useHTML: true,
            shared: false,
            split: true
        }
        options.exporting.filename = props?.exportingChartTitle;
        options.xAxis.labels.step = 0;
        if (props.chartData) {
            options.series[0].data = props.chartData[0];
            options.series[1].data = props.chartData[1];
        }

        setSyncCharts(options);
        // eslint-disable-next-line
    }, [props.chartData])

    return (
        <div className="fuelConsump">
            {props.fuelConsumpTotals.length === 0 && props.chartData[0].length === 0 && props.chartData[1].length === 0
                ? <div className="fuelConsumpNoData align-items-center d-flex flex-column justify-content-center text-center" style={{height: props.style.height}}>
                    <NoDataIcon className="svg-path-no-data marg-b-20" />
                    { props.addEventProps.isLight 
                    ? props.addEventProps.allowTelegrams ?
                        <>
                            <div className="form-text">You haven't inserted Telegram data yet.<br /> Click the button below and visualize your data.</div>
                            <AddEventsButton {...props.addEventProps} />
                        </>
                        : <div className="form-text">We haven't integrated with your Telegrams yet</div>
                    : <div className="form-text">No data to display at the moment</div> }
                </div>
                : <div className="container-fluid full-height ">
                    <div className="row no-gutters full-height ">
                        <div className="col-12 full-height d-flex col-sm-12 col-xs-12 col-md-3 col-lg-3 flex-column align-self-start fuelConsumpTotals">
                            <FuelConsumpTotals data={props.fuelConsumpTotals} />
                        </div>
                        <div className="col-12 full-height d-flex justify-content-center align-items-center col-sm-12 col-xs-12 col-md-9 col-lg-9 navigationStatusChart">
                            <SyncCharts options={syncCharts} />
                        </div>
                    </div>
                </div>
            }
        </div>

    )
}

export default FuelConsump;