import React from 'react';
import TagManager from 'react-gtm-module';
import isEmpty from 'lodash/isEmpty';
import {useSelector} from 'react-redux'; 
import {userStore} from 'common/store/storeUtils';

const TagManagerComponent = (props) => {
    // store vars for JSX
    const userStoreJSX = useSelector(state => state.user);

    const tagManagerArgs = {
        gtmId: 'GTM-5WV46RH',
        dataLayer: {
            occupation: userStore().user ? userStore().user.occupation : '',
            userId: userStore().user ? userStore().user.id : '',
            company: userStore().user.company ? userStore().user.company.name : ''
        }
    }

    if (props.type === 'event' && props.data && !isEmpty(props.data)) {
        tagManagerArgs.events = {
            [props.data.name]: props.data.value
        }
    }

    return (
        <React.Fragment>
            {(!userStoreJSX.user?.email?.includes('deepsea')) && TagManager.initialize(tagManagerArgs)}
        </React.Fragment>
    )
}

export default TagManagerComponent;
