import React from 'react';
import Utilities from 'Utilities/global';
import AlertCircleSymbol from 'components/alerts/alertCircleSymbol';


const FuelOverConsumption = props => {
    return (
        <div className="alert-fuel-over-consumption d-flex align-items-center justify-content-around" style={props.style}>
            <AlertCircleSymbol {...props.data} />
            <div>
                <div className="report-big">{Utilities.roundToDigits(props.data.value, 2)}%</div>
                <div className="headline2">FUEL OVERCONSUMPTION</div>
            </div>
        </div>
    );
}
 
export default FuelOverConsumption;