import React, {useEffect, useState} from 'react';
import EventTypeCard from "./eventTypeCard";
import Popover from "../popover/popover";
import EventsUtilities from "../../Utilities/events";
import { userStore, calendarUtils } from 'common/store/storeUtils';
import service from 'common/js/service';

const EventTypeList = props => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [vEvent, setVEvent] = useState(null);
    const [activeEvent, setActiveEvent] = useState(false);

    const handleEventClick = (clickedElement, eventClickInfo) => {
        setActiveEvent(!activeEvent);
        EventsUtilities.selectedVessel = eventClickInfo.vesselId;
        setVEvent(eventClickInfo);
        setAnchorEl(clickedElement.target.offsetParent ? clickedElement.target.offsetParent : clickedElement.target);
        setPopoverOpen(!popoverOpen);
    }

    const popoverClose = () => setPopoverOpen(false);

    useEffect(() => {
        service.getGeInfo(userStore().user?.company.id).then(geInfo => { EventsUtilities.geInfo = geInfo })
    }, []);

    const itemProps = (props.items ? props.items : props.data).filter(item => calendarUtils.Filters().find(filter => filter.id === item.originalType));

    return (
        <div className='events-type-list' style={props.style}>
            {itemProps.map((item, index) => (
                <EventTypeCard key={index} {...item} vEvent={vEvent} activeEvent={activeEvent} handleEventClick={handleEventClick} />
            ))}

            {popoverOpen &&
            <Popover popoverOpen={popoverOpen}
                     popoverClose={popoverClose}
                     anchorEl={anchorEl}
                     vEvent={vEvent} /> }
        </div>
    )
}

export default EventTypeList;
