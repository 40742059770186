import React from "react";
import moment from 'moment';
import {ReactComponent as FuelOilIcon} from 'assets/img/app/timeline-imgs/fuelOil.svg';
import {ReactComponent as LubeOilIcon} from 'assets/img/app/timeline-imgs/lubeOil.svg';
import {ReactComponent as ChartererIcon} from 'assets/img/app/timeline-imgs/charterer.svg';
import {ReactComponent as MaintainanceGeIcon} from 'assets/img/app/timeline-imgs/maintainance_ge.svg';
import {ReactComponent as MaintainanceHullIcon} from 'assets/img/app/timeline-imgs/maintainance_hull.svg';
import {ReactComponent as MaintainanceMeIcon} from 'assets/img/app/timeline-imgs/maintainance_me.svg';
import {ReactComponent as NextportIcon} from 'assets/img/app/timeline-imgs/nextport.svg';
import {ReactComponent as OilGeneral} from 'assets/img/app/events-imgs/oilGeneral.svg';
import { vesselStore, eventsUtils } from "common/store/storeUtils";

const categMap = {
    'ge': 'Generator Engine',
    'me': 'Main Engine',
    'hull': 'hull',
    'lube': 'Lube oil malfunction',
    'fuel': 'Fuel malfunction',
    'maintenance': 'Incorrect Maintenance',
    'injectors': 'Fuel injectors failure',
    'fatigue': 'Fatigue/Cracking',
    'vibration': 'Vibration',
    'fOil': 'Fuel Oil',
    'lOil': 'Lube Oil',
    'hsfo': 'hsfo',
    'lsfo': 'lsfo',
    'hsgo': 'HS-MGO',
    'lsmgo': 'lsgo',
    'hbn co': 'hbn co',
    'lslbn co': 'lslbn co',
    'm/e': 'system oil',
    'g/e': 'go',
    'shaft': 'Shaft',
    'generator': 'Fresh Generator',
    'cooling': 'Cooling water',
    'air': 'Air',
    'lubrication': 'Lubrication',
    'oil': 'Fuel Oil',
    'pistons': 'Pistons',
    'tc': 'Turbo Chargers',
    'propeller': 'Propeller',
    'bowThruster': 'Bow Thruster',
    'seaChest': 'Sea Chest',
    'fullHull': 'Full Hull',
    'hullSide': 'Hull Side',
    'hullBottom': 'Hull Bottom',
    'bulb': 'Bulb',
    'thrustBearing': 'Thrust Bearing',
    'torquemeter': 'Torquemeter',
    'crankShaftBearing': 'Crank shaft Bearing',
    'camshaft': 'Camshaft',
    'preheater': 'Preheater',
    'aircooler': 'Air Cooler',
    'compressors': 'Compressors',
    'receiver': 'Receiver',
    'exhaust': 'Exhaust Valve',
    'filters': 'Filters',
    'pumps': 'Pumps',
    'heater': 'Heater',
    'injector': 'Injector',
    'seal': 'Seal',
    'pin': 'Pin',
    'turbine': 'Turbine',
    'compressor': 'Compressor',
    'mainShaft': 'Main Shaft',
    'bearing': 'Bearing',
    'winding': 'Winding',
    'overhauling': 'Overhauling',
    'assembling': 'Assembling',
    'tightening': 'Tightening',
    'calibration': 'Calibration',
    'clearance': 'Clearance',
    'cleaning': 'Cleaning',
    'polishing': 'Polishing',
    'rePainting': 'Re-Painting',
    'other': 'Other Action',
}

const firstCapital = string => {
    if (!string) return;
    return string
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
const eventTypes = {
    damages: ["me", "ge"],
    maintainance: ["ME", "GE", "HULL"],
    bunkering: ["fOil", "lOil"]
}

export const eventsLabels = {
    DTELEGRAM: 'Departure',
    ATELEGRAM: 'Arrival',
    NTELEGRAM: 'Noon Report',
    OTELEGRAM: 'Port Noon Report',
    VESSEL_MAINTENANCE: 'Maintenance Event',
    VESSEL_CHARTER_AGREEMENTS: 'Charter Party',
    VESSEL_BUNKERING: 'Bunkering',
    VESSEL_MACHINERY_DAMAGES: 'Machinery Damage',
};

const damagesMappingArrToString = arr => {
    let string = '';
    arr.cause.forEach((value, index) => {
        string += value === 'other' ? arr.otherCause : `${categMap[value]}`
        if (index !== (arr.cause.length - 1)) string += ', ';
    });
    return string;
}

const maintenanceMapping = value => {
    return value.action === 'other' ? value.otherAction : `${categMap[value.action]}`;
}

const tools = {
    getEventDecription: event => {
        if (event.type === "VESSEL_MACHINERY_DAMAGES") {
            let desc = '';
            event.damages.forEach((damage, index) => {
                desc += `${firstCapital(damage.part)} of ${categMap[damage.location]} ${firstCapital(categMap[damage.system])} System because of ${damagesMappingArrToString(damage)}`;

                if (index !== (event.damages.length - 1)) desc += ' & ';
            });
            return desc;
        }
        if (event.type === "VESSEL_CHARTER_AGREEMENTS") {
            return `With ${event.charterer} and Period of Validity from ${moment(event.dateFrom).format('DD/MM/YYYY')} till ${moment(event.dateTo).format('DD/MM/YYYY')}.`;
        }
        if (event.type === "VESSEL_MAINTENANCE") {
            let desc = '';
            event.operations.forEach((op, index) => {
                desc += `${maintenanceMapping(op)} of ${categMap[op.part]} of ${categMap[op.location.toLowerCase()]} ${(op.system) ? (categMap[op.system] + ' System') : ''}`;
                if (index !== (event.operations.length - 1)) desc += ' & ';
            });
            return desc;
        }
        if (event.type === "VESSEL_BUNKERING") {
            let desc = '';
            event.bunkerings.forEach((bunk, index) => {
                desc += `${categMap[bunk.oilType]} ${categMap[bunk.oil]} type: Supplied ${bunk.supplied} MT ROB ${bunk.rob} MT`;
                if (index !== (event.bunkerings.length - 1)) desc += ' & ';
            });
            return desc;
        }
        if (event.type === "ATELEGRAM") {
            return `Arrive at ${event.portName}. Fuel Oil Consumption is ${event.totalFOMassRateCalc || '0'} MT.`;
        }
        if (event.type === "DTELEGRAM") {
            return `Depart from ${event.portName}. Expected to arrive at ${event.nextPort} at ${(moment(event.etaNextPort).format('DD/MM/YYYY HH:mm') || '')}.`;
        }
        if (event.type === "OTELEGRAM") {
            return `Moored at ${event.portName}. Fuel Oil Consumption is ${event.totalFOMassRateCalc} MT.`;
        }
        if (event.type === "NTELEGRAM") {
            let minutesAtSea = '';
            if (event.minutesAtSea) minutesAtSea = `${event.minutesAtSea} mins`;
            return `Expected to arrive at ${event.nextPort} at ${moment(event.etaNextPort).format('DD/MM/YYYY HH:mm')}.
            ${parseInt(event.milesAtSea)} nm traveled within ${event.hoursAtSea} hours ${minutesAtSea}. Fuel Oil Consumption is ${event.totalFOMassRateCalc || '0'} MT.`;
        } else return [];

    },
    telegramSubtitle: telegram => {
        let subtitle = '';
        if (vesselStore().selectedVessel !== vesselStore().allVesselsObj.vesselId) {
            if (telegram.type === eventsUtils.getEventIdByType('ATELEGRAM')) {
                subtitle = `at ${telegram.port} at ${moment.utc(telegram.dateFrom).format('HH:mm')}`;
            } else if (telegram.type === eventsUtils.getEventIdByType('DTELEGRAM')) {
                subtitle = `from ${telegram.port} at ${moment.utc(telegram.dateFrom).format('HH:mm')}`;
            } else if (telegram.type === eventsUtils.getEventIdByType('NTELEGRAM') || telegram.type === eventsUtils.getEventIdByType('OTELEGRAM')) {
                subtitle = `travelling to ${telegram.nextPort}`;
                if(telegram.type === eventsUtils.getEventIdByType('OTELEGRAM')) subtitle = `from ${telegram.port} at ${moment.utc(telegram.dateFrom).format('HH:mm')}`;
            }
        }
        return subtitle;
    },
    eventTitle(event) {
        let title = 'Maintenance Event';
        if (vesselStore().selectedVessel !== vesselStore().allVesselsObj.vesselId) {
            if (event.type === "VESSEL_MAINTENANCE") {
                title = "Drydock Maintenance";
                if (event.maintenanceType === "sea") {
                    title = "Sea Maintenance";
                }
            }
        }
        return title;
    },
    eventSubTitle(event) {
        let tempArr = [];
        if (vesselStore().selectedVessel !== vesselStore().allVesselsObj.vesselId) {
            if (event.type === eventsUtils.getEventIdByType('BUNKERING')) {
                eventTypes.bunkering.forEach((type) => {
                    let count = 0;
                    event.bunkerings.forEach((bunkering, index) => {
                        if (bunkering.oilType === type) {
                            count += parseInt(bunkering.supplied);
                        }
                    })
                    count > 0 && tempArr.push(`${count} MT ${categMap[type]}`)
                })

            } else if (event.type === eventsUtils.getEventIdByType('DAMAGES')) {
                eventTypes.damages.forEach((type) => {
                    let count = 0;
                    event.damages.forEach((damage, index) => {
                        if (damage.location === type) {
                            count++;
                        }
                    })
                    count > 0 && tempArr.push(`${count} at ${categMap[type.toLowerCase()]}`)
                })
            } else if (event.type === eventsUtils.getEventIdByType('MAINTENANCE')) {
                eventTypes.maintainance.forEach((type) => {
                    let count = 0;
                    event.operations.forEach((operation, index) => {
                        if (operation.location === type) {
                            count++;
                        }
                    })
                    count > 0 && tempArr.push(`${count} at ${firstCapital(categMap[type.toLowerCase()])}`);
                })
            } else if (event.type === eventsUtils.getEventIdByType('VESSELCHARTER')) {
                tempArr.push(`${moment.utc(event.dateFrom).format('DD/MM/YYYY')} - ${moment.utc(event.dateTo).format('DD/MM/YYYY')} from ${event.charterer}`);
            }
        }
        return tempArr.join(" - ");
    },
    selectedVessel: '',
    geInfo: '',
    titleMapper: location => {
        const mapper = {
            'HULL': {
                short: 'Hull',
                long: 'Hull'
            },
            'ME': {
                short: 'M/E',
                long: 'Main Engine'
            },
            'GE': {
                short: 'G/E',
                long: 'Generator Engine'
            },
        }
        return mapper[location];
    },
    iconMapper: location => {
        const mapper = {
            'HULL': <MaintainanceHullIcon className={"svg-path"}/>,
            'ME': <MaintainanceMeIcon className={"svg-path"} />,
            'GE': <MaintainanceGeIcon className={"svg-path"} />,
            'fOil': <FuelOilIcon className={"svg-path"} />,
            'lOil': <LubeOilIcon className={"svg-path"} />
        }
        return mapper[location];
    },
    eventCardLeftInfo: event => {
        let headerInfo = {
            title: '',
            subtitle: ''
        }
        const eventType = event.originalType ? event.originalType : event.type;

        // Arrival Telegram
        if (eventType === eventsUtils.getEventIdByType('ATELEGRAM')) {
            headerInfo.title = `Arrival at ${firstCapital(event.portName)}`;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }
        // Departure Telegram
        else if (eventType === eventsUtils.getEventIdByType('DTELEGRAM')) {
            headerInfo.title = `Departure from ${firstCapital(event.portName)}`;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }
        // Noon Telegram
        else if (eventType === eventsUtils.getEventIdByType('NTELEGRAM')) {
            headerInfo.title = `Noon Report`;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }
        // Noon Port Telegram
        else if (eventType === eventsUtils.getEventIdByType('OTELEGRAM')) {
            headerInfo.title = `Port Noon Report`;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }
        // Maintenance Telegram
        else if (eventType === eventsUtils.getEventIdByType('MAINTENANCE')) {
            headerInfo.title = (event.maintenanceType === "drydock") ? "Drydock Maintenance" : "Sea Maintenance";
            ;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }
        // Bunkering Telegram
        else if (eventType === eventsUtils.getEventIdByType('BUNKERING')) {
            headerInfo.title = `Bunkering Event`;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }
        // Machinery Damage Telegram
        else if (eventType === eventsUtils.getEventIdByType('DAMAGES')) {
            headerInfo.title = `Machinery Damage`;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }
        // Charter Party Telegram
        else if (eventType === eventsUtils.getEventIdByType('VESSELCHARTER')) {
            headerInfo.title = `Charter Party Agreement`;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }

        return headerInfo;
    },
    eventCardRightInfo: event => {
        const rightInfo = [], tempArr = [];

        if (event.originalType === eventsUtils.getEventIdByType('DTELEGRAM')) {
            rightInfo.push({
                title: firstCapital(event.nextPort),
                image: <NextportIcon className={"svg-path"}/>
            })
        } else if (event.originalType === eventsUtils.getEventIdByType('ATELEGRAM') || event.originalType === eventsUtils.getEventIdByType('NTELEGRAM') || event.originalType === eventsUtils.getEventIdByType('OTELEGRAM')) {
            rightInfo.push({
                title: `${event.totalFOMassRateCalc ? event.totalFOMassRateCalc.toFixed(2) : 0} MT`,
                image: <OilGeneral className={"svg-path"}/>
            })
        } else if (event.originalType === eventsUtils.getEventIdByType('MAINTENANCE')) {
            event.operations.forEach(operation => {
                rightInfo.push({
                    title: event.operations.length > 1 ? tools.titleMapper(operation.location).short : tools.titleMapper(operation.location).long,
                    image: tools.iconMapper(operation.location)
                })
            })
        } else if (event.originalType === eventsUtils.getEventIdByType('BUNKERING')) {
            eventTypes.bunkering.forEach((type) => {
                let count = 0, icon = '';
                event.bunkerings.forEach((bunkering, index) => {
                    if (bunkering.oilType === type) {
                        count += parseInt(bunkering.supplied);
                        icon = tools.iconMapper(bunkering.oilType);
                    }
                })
                count > 0 && tempArr.push({value: `${count} MT`, icon: icon})
            })

            tempArr.forEach((bunkering, index) => {
                rightInfo.push({
                    title: tempArr[index].value,
                    image: tempArr[index].icon
                })
            })
        }  else if (event.originalType === eventsUtils.getEventIdByType('VESSELCHARTER')) {
            rightInfo.push({
                title: event.charterer,
                image: <ChartererIcon />
            })
        } else if (event.originalType === eventsUtils.getEventIdByType('DAMAGES')) {
            event.damages.forEach(damage => {
                rightInfo.push({
                    title: event.damages.length > 1 ? tools.titleMapper(damage.location.toUpperCase()).short : tools.titleMapper(damage.location.toUpperCase()).long,
                    image: tools.iconMapper(damage.location.toUpperCase())
                })
            })
        }

        return rightInfo;
    }
}

export default tools;
