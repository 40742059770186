import moment from "moment";
import Utils from 'Utilities/global';
import { calendarUtils } from "../storeUtils";

const initialCalendarState = {
    calendarEvents: [],
    calendarFilteredEvents: [],
    calendarFilteredCriteria: [],
    calendarFromTo: {
        from: moment(Utils?.nowOrDemo()).utc().subtract(180, 'days').startOf('month').valueOf(), 
        to: moment(Utils?.nowOrDemo()).utc().add(180, 'days').valueOf()
    },
    currentDate: moment(Utils?.nowOrDemo()),
    currentTelegram: {
        port: '',
        nextPort: '',
        etaNextPort: '',
        currentEventType: ''
    },
    calendarCurrentEvent: {
        editEvent: false,
        modalTitle: 'ADD EVENT',
        modalDate: '',
        eventObj: {},
        modalEventVessel: -1,
        modalShow: false
    }
}

// calendar reducer
const calendar = (state = initialCalendarState, action) => {
    switch(action.type) {
        case 'calendar/setCalendarEvents': {
            return {
                ...state,
                calendarEvents: action.payload
            };
        }
        case 'calendar/setCalendarFilteredEvents': {
            return {
                ...state,
                calendarFilteredEvents: action.payload
            };
        }
        case 'calendar/setCalendarFilteredCriteria': {
            return {
                ...state,
                calendarFilteredCriteria: action.payload
            };
        }
        case 'calendar/setCalendarFromTo': {
            return {
                ...state,
                calendarFromTo: action.payload
            };
        }
        case 'calendar/setCurrentDate': {
            return {
                ...state,
                currentDate: action.payload
            };
        }
        case 'calendar/setCurrentTelegram': {
            return {
                ...state,
                currentTelegram: action.payload
            };
        }
        case 'calendar/setCalendarCurrentEvent': {
            // some extra handling before updating the redux state for calendarCurrentEvent
            let {editEvent = false, modalDate = '', modalShow = false} = action.payload, 
                tmpCurrentEvent = {};

            if (editEvent === false) tmpCurrentEvent = calendarUtils.addCurrentEvent({modalDate, modalShow});
            else tmpCurrentEvent = calendarUtils.editCurrentEvent(editEvent);
                
            return {
                ...state,
                calendarCurrentEvent: tmpCurrentEvent
            };
        }
        default: 
            return state;
    }
}

export default calendar;