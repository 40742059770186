import React from 'react';

const WidgetTitleWrapper = ({title, children}) => {
    return (
        <div className="widget-panel">
            <div className="widget-panel__header">{title}</div>
            {children}
        </div>
    );
}

export default WidgetTitleWrapper;
