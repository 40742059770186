import React from "react";
import Utilities from "Utilities/global";
import CircleProgressChart from "components/charts/circleProgreesChart/circleProgressChart";
import { ReactComponent as VesselRatingIcon } from "assets/img/app/monitoring-imgs/cii-class-rating.svg";
import ThemeColors from "sass/_themeColorsForJs.scss";
import { ReactComponent as InformationIcon } from "assets/img/app/global-imgs/informationIcon.svg";
import Tooltip from "components/muiHTMLTooltip/muiTooltip";
import moment from "moment";

const VesselRating = (props) => {

	return (
		<div className="vessel-rating d-flex align-items-center justify-content-center flex-column" style={{ height: props.style.height }}>
			<div className="full-height d-flex justify-content-center align-items-center flex-wrap">
				<div className="marg-r-15 ciiOverview">
					<div className="ciiOverviewClass">
						<div className="rating-class">
							<div className="rating-class__text">Reports</div>
							<div className={`cii-class class${props?.data?.class || ''}`}>
								{props?.data?.class || "-"}
							</div>
							<div className="rating-class__text">CLASS</div>
						</div>
						<CircleProgressChart
							progressChartOptions={{
								withTooltip: true,
								id: `vessel-rating-${props?.data?.vesselType}-${props?.data?.class}`,
								insideText: '',
								titleY: 25,
								export: false,
								tooltip: props.data?.tooltip,
								y: props?.data?.telegramCiiRating || 0.1,
								colors: [ThemeColors['reports_color']],
								totalFuel: props.data?.totalFuel,
								totalCO2: props.data?.totalCO2,
								totalMiles: props.data?.totalMiles,
								telegramPercentage: props.data?.telegramPercentage,
								backColor: Utilities.getThemeColors.backColor(),
								width: 160,
								style: {
									color: Utilities.getThemeColors.text(),
									fontSize: '14px',
									fontFamily: "sans-serif"
								}
							}}
						/>
					</div>
				</div>
				<div className="vessel-rating-ico marg-r-15">
					<VesselRatingIcon className="svg-path-with-text" />
				</div>

				<div className="vessel-rating-info">
					<div className="statistic-value reportsData">
						{Utilities.renderNumber(Utilities.roundToDigits(props?.data?.telegramCiiRating,2))}
					</div>
					<div className="label table-element">
						<div>CARBON</div>
						<div>INTENSITY INDICATOR</div>
					</div>
					<div className="flex-centered label table-element">
						<div>{`(Required ${moment(props.data.dateTo).format("YYYY")}:`}</div>
						<div className="statistic-value reportsData ml-1 mr-1">
							{`${Utilities.renderNumber(Utilities.roundToDigits(props?.data?.requiredCii, 2))})`}
						</div>
						<Tooltip
							component={<InformationIcon className="svg-path pointer" />}
							height="auto"
							title={
								<div className="flex-centered flex-column p-2">
									<div>{props?.data?.vesselType}</div>
									<div>
										{props?.data && ` DWT/GT: ${props?.data["dwt"]} mt `}
									</div>
								</div>
							}
						/>
					</div>
				</div>
			</div>
			<div className="vessel-rating__footer">
				<div className="small-label pl-3">
					CII Breakdown:
					<span className="pl-2">M/E:</span>
					<span className="statistic-value reportsData pl-1">
						{Utilities.renderNumber(Utilities.roundToDigits(props?.data["meCiiTelegramRating"],2))}
					</span>
					<span className="pl-2">G/E:</span>
					<span className="statistic-value reportsData pl-1">
						{Utilities.renderNumber(Utilities.roundToDigits(props?.data["geCiiTelegramRating"],2))}
					</span>
					<span className="pl-2">BLR:</span>
					<span className="statistic-value reportsData pl-1">
						{Utilities.renderNumber(Utilities.roundToDigits(props?.data["boCiiTelegramRating"],2))}
					</span>
				</div>
			</div>
		</div>
	);
};

export default VesselRating;
