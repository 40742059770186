const geStorage = {
  fuelBarChartData: null,
  fuelLineChartData: null,
  loadHistoryData: null,
  geDetailsData: null,
  turboChargerLineChartData: null,
  runningTime: null,

  setFuelBarChartData: (data) => geStorage.fuelBarChartData = data,
  setLineChartData: (data) => geStorage.fuelLineChartData = data,
  setLoadHistoryData: (data) => geStorage.loadHistoryData = data,
  setGeDetailsData: (data) => geStorage.geDetailsData = data,
  setTurboChargerLineChartData: (data) => geStorage.turboChargerLineChartData = data,
  setRunningTime: (data) => geStorage.runningTime = data,

  resetGeStorage: () => {
    geStorage.setFuelBarChartData(null);
    geStorage.setLineChartData(null);
    geStorage.setLoadHistoryData(null);
    geStorage.setGeDetailsData(null);
    geStorage.setTurboChargerLineChartData(null);
    geStorage.setRunningTime(null);
  },

  isGlobalStateEmpty: () => {
    return !geStorage.fuelBarChartData || !geStorage.fuelLineChartData
        || !geStorage.loadHistoryData || !geStorage.geDetailsData || !geStorage.turboChargerLineChartData || !geStorage.runningTime;

  }
}

export default geStorage;
