import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ReportsHeader from 'reports/reportsHeader';
import MapComponent from 'components/map/map';
import BarChart from 'components/charts/barChart/barChart';
import InfoDeviderBox from 'reports/infoDeviderBox';
import RouteInfo from 'reports/routeInfo';
import LoadHistoryLineChart from "widgets/generatorEngine/loadHistoryLineChart.jsx";
import Layout from 'components/core/Layout';
import {vesselLog as updateVesselLog} from 'widgets/events/VesselLog';
import {fcrChartPayload, fcrChartLightPayload, fcr as updateFcr} from 'widgets/mainEngine/fcrVesselChart';
import { loadHistoryPayload, loadHistoryPayload_light, loadHistory as updateLoadHistory, setGesNum as setGesNumHistoryLoad} from "widgets/generatorEngine/loadHistoryLineChart.js";
import {routeInfoPayload, routeInfo as updateRouteInfo} from 'widgets/events/routeInfo';
import {sogPayload, sog as updateSOG} from 'reports/payloads/sog';
import {averageRpmPayload, averageRpm as updateAverageRpm} from 'reports/payloads/averageRPM';
import {averageCurrentsPayload, averageCurrents as updateAverageCurrents} from 'reports/payloads/averageCurrents';
import {averageWindPayload, averageWind as updateAverageWind} from 'reports/payloads/averageWind';
import ReportsService from 'common/js/reports';
import service from 'common/js/service';
import Utils from 'Utilities/global';
import {licensing} from "common/store/licensing";
import EventTypeList from "components/eventTypeList/eventTypeList";
import NavigationStatusChart from "components/monitoringCategories/overviewComponents/navigationStatusChart";
import {navStatus} from "../widgets/vesselData/navigationStatus";
import {navigationStatusChartUtils} from "../widgets/vesselData/navigationChart";
import VesselMonitoringChart from "../components/monitoringCategories/overviewComponents/vesselMonitoringChart";
import {vesselMonitoringChartPayload, vesselMonitoringChartPayloadLight, vesselMonitoringChartUpdate} from "../reports/payloads/vesselMonitoringChart";
import ReportPeriodComponent from 'reports/reportPeriodComponent';
import ArrivalDepartureFooter from "components/footers/staticFooters/arrivalDepartureFooter.jsx"
import RunningTime from "widgets/generatorEngine/runningTime.jsx";
import {updateRunningTime} from "widgets/generatorEngine/runningTime.js";
import TotalFuelConsumption from 'reports/totalFuelConsumption';
import {totalFuelConsumptionPayload, updateTotalFuelConsumption} from 'reports/payloads/totalFuelConsumption.js';
import {reportsStore, userStore, vesselStore, reportsUtils, vesselUtils} from 'common/store/storeUtils';
import {useSelector, useDispatch} from 'react-redux';

let isMounted = false, pushFromFleetReport = false;
let currentVesselId = -1;
let firstTelegramDate = null;

let registrationWidgetsLight = [], registrationWidgetsPremium = [];

const DailyVesselReport = (props) => {
    // store vars for JSX
    const reportsStoreJSX = useSelector(state => state.reports);
    const vesselStoreJSX = useSelector(state => state.vessel);
    const vesselGroupStoreJSX = useSelector(state => state.vesselGroup);
    const userStoreJSX = useSelector(state => state.user);
    
    const dispatch = useDispatch();

    const reportsService = new ReportsService();
    const [widgets, setWidgets] = useState({layout: []});
    const [fcr, setFcr] = useState({});
    const [init, setInit] = useState(false);
    const [averageWind, setAverageWind] = useState({});
    const [averageCurrents, setAverageCurrents] = useState({});
    const [averageRpm, setAverageRpm] = useState({});
    const [sog, setSog] = useState({});
    const [routeInfo, setRouteInfo] = useState({});
    const [loadHistory, setLoadHistory] = useState({});
    const [vesselLog, setVesselLog] = useState([]);
    const [navigationStatusChartData, setNavigationStatusChartData] = useState([]);
    const [vesselMonitoringChartData, setVesselMonitoringChartData] = useState([]);
    const [activeDates, setActiveDates] = useState([]);
    const [runningTime, setRunningTime] = useState({});
    const [totalFuelConsumption, setTotalFuelConsumption] = useState({});
    const [reportPeriodData, setReportPeriodData] = useState({
        from: reportsStoreJSX.fromTo.from,
        to: reportsStoreJSX.fromTo.to
    });
    const [requestOnGoing, setRequestOnGoing] = useState({
        navigationStatusChart: false,
        vesselRoute: false,
        averageWind: false,
        averageCurrents: false,
        averageRpm: false,
        sog: false,
        legends: false,
        routeInfo: false,
        fcr: false,
        loadHistory: false,
        vesselLog: false,
        vesselMonitoringChart: false,
        runningTime: false,
        totalFuelConsumption: false
    });

    let registrationWidgets = [];

    registrationWidgetsPremium = [
        {widgetId: 'averageWind', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: averageWindPayload, type: 'text'},
        {widgetId: 'averageCurrents', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: averageCurrentsPayload, type: 'text'},
        {widgetId: 'averageRpm', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: averageRpmPayload, type: 'text'},
        {widgetId: 'sog', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: sogPayload, type: 'text'},
        {widgetId: 'routeInfo', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: routeInfoPayload, type: 'text'},
        {widgetId: 'fcr', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: fcrChartPayload, type: 'basicChart'},
        {widgetId: 'vesselLog', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getVesselEvents, params:
            { value: vesselStore().selectedVessel, startingDate: reportsStore().fromTo.from, endingDate: reportsStore().fromTo.to }
        }}}}, payload: {}, type: 'table'},
        { widgetId: 'navigationStatusChart',
            inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false, overwriteHeader: 'get-navigation-status' },
            payload: '',
            type: 'text'
        },
        { widgetId: 'vesselMonitoringChart', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false }, payload: vesselMonitoringChartPayload, type: 'basicChart' },
        {widgetId: 'loadHistory', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: loadHistoryPayload, type: 'basicChart'},
        { widgetId: 'runningTime', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getRunningTimeSensoredAndTelegram, params:
            {vesselId: vesselStore().selectedVessel, from: reportsStore().fromTo.from, to: reportsStore().fromTo.to}}}}}, payload: '', type: 'text'},
        { widgetId: 'totalFuelConsumption', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false, overwriteHeader: 'get-generic-report' }, payload: totalFuelConsumptionPayload, type: 'text' },
    ];

    registrationWidgetsLight = [
        {widgetId: 'averageWind', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: averageWindPayload, type: 'text'},
        {widgetId: 'averageCurrents', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: averageCurrentsPayload, type: 'text'},
        {widgetId: 'averageRpm', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: averageRpmPayload, type: 'text'},
        {widgetId: 'sog', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: sogPayload, type: 'text'},
        {widgetId: 'routeInfo', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: routeInfoPayload, type: 'text'},
        {widgetId: 'fcr', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: fcrChartLightPayload, type: 'basicChart'},
        {widgetId: 'vesselLog', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getVesselEvents, params:
            { value: vesselStore().selectedVessel, startingDate: reportsStore().fromTo.from, endingDate: reportsStore().fromTo.to }
        }}}}, payload: {}, type: 'table'},
        { widgetId: 'navigationStatusChart',
            inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false, overwriteHeader: 'get-navigation-status' },
            payload: '',
            type: 'text'
        },
        { widgetId: 'vesselMonitoringChart', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false }, payload: vesselMonitoringChartPayloadLight, type: 'basicChart' },
        { widgetId: 'loadHistory', prerequisite: { value: true, request: () => {return {method: service.getGeInfo, params:
            userStore().user.company.id }}, callback: setGesNumHistoryLoad },
        inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: false}, payload: loadHistoryPayload_light, type: 'basicChart'},
        { widgetId: 'runningTime', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getRunningTimeSensoredAndTelegram, params:
            {vesselId: vesselStore().selectedVessel, from: reportsStore().fromTo.from, to: reportsStore().fromTo.to}}}}}, payload: '', type: 'text'},
        { widgetId: 'totalFuelConsumption', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false, overwriteHeader: 'get-generic-report' }, payload: totalFuelConsumptionPayload, type: 'text' },
    ]

    const updateRequestOnGoing = (key, value) => {
        setRequestOnGoing((CurrentRequestOnGoing) => { return {...CurrentRequestOnGoing, [key]: value} });
    }

    const updateStateMapper = (key, value) => {
        const updateMapper = {
            navigationStatusChart: setNavigationStatusChartData,
            vesselMonitoringChart: setVesselMonitoringChartData,
            fcr: setFcr,
            averageWind: setAverageWind,
            averageCurrents: setAverageCurrents,
            averageRpm: setAverageRpm,
            sog: setSog,
            routeInfo: setRouteInfo,
            loadHistory: setLoadHistory,
            vesselLog: setVesselLog,
            runningTime: setRunningTime,
            totalFuelConsumption: setTotalFuelConsumption
        }
        return updateMapper[key](value);
    }

    const reportsResponseMapper = (response, key) => {
        const mapper = {
            navigationStatusChart: navStatus,
            fcr: updateFcr,
            averageWind: updateAverageWind,
            averageCurrents: updateAverageCurrents,
            averageRpm: updateAverageRpm,
            sog: updateSOG,
            routeInfo: updateRouteInfo,
            loadHistory: updateLoadHistory,
            vesselLog: updateVesselLog,
            vesselMonitoringChart: vesselMonitoringChartUpdate,
            runningTime: updateRunningTime,
            totalFuelConsumption: updateTotalFuelConsumption
        }
        return mapper[key](key, response, updateStateMapper, null, registrationWidgets.filter(report => report.widgetId === key)[0]);
    }

    const register = () => {
        // if vessel is light, use the light widget requests, or else the premium ones
        registrationWidgets = licensing.lightCondition() ? registrationWidgetsLight : registrationWidgetsPremium;

        reportsService.registerReports([vesselStore().selectedVessel], registrationWidgets, reportsResponseMapper, updateRequestOnGoing, true);
    }

    const updateFromTo = (dates) => {
        const from = moment(dates.from).set({hour:3,minute:0,second:0,millisecond:0}).valueOf();
        const to = moment(dates.to).set({hour:20,minute:59,second:59,millisecond:0}).valueOf();
        dispatch({type: 'reports/setFromTo', payload: {from, to}})
    };

    const goToDates = () => {
        if(firstTelegramDate && reportsStore().isTelegramConfigured && reportsStore().fromTo.to <= firstTelegramDate) dispatch({type: 'reports/setIsTelegramConfigured', payload: false});
        if(firstTelegramDate && !reportsStore().isTelegramConfigured && reportsStore().fromTo.to > firstTelegramDate) dispatch({type: 'reports/setIsTelegramConfigured', payload: true});
        register();
        reportsUtils.updateFromTo(
            dispatch,
            { 
                from: reportsStore().fromTo.from, 
                to: reportsStore().fromTo.to 
            },
            reportsStore().fromToChanged
        );
    }

    const goToDatesButtonClicked = async () => {
        const {timeFrom, timeTo} = await getDailyReportsDates(reportsStore().fromTo.to);
        initToDates(timeFrom, timeTo);
    }

    const legendsData = () => {
        return [
            licensing.lightCondition()
                ? {color: 'var(--ais_color)', title: 'DATA FROM AIS'}
                : {color: 'var(--main_blue)', title: 'DATA FROM SENSORS'},
            {color: 'var(--reports_color)', title: 'DATA FROM REPORTS'}
        ]
    };

    const getTitle = () => (
        <div className={`flex-space-between full-width marg-r-15`}>
            <div>VESSEL'S ROUTE</div>
            <div>
                LEGEND: 
                <span className={`${licensing.lightCondition() ? '' : 'd-none'}`}>
                    <div className="ais-tile"/> AIS Data
                </span>
                <span className={`${!licensing.lightCondition() ? '' : 'd-none'}`}>
                    <div className="sensor-tile"/> Sensors Data
                </span>
                {reportsStore().isTelegramConfigured && <>
                    <div className="telegram-tile"/> Reports Data
                </>}
            </div>
        </div>
    );

    const getWidgetsPreferences = () => {
        return {
            className: 'padding-left-right-7',
            layout: [
                {
                    columns: [
                        {
                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 8, order: 1},
                            className: 'padding-left-right-7',
                            layout: [
                                {
                                    columns: [
                                        {
                                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                            components: [
                                                {id: 'vesselRoute', title: getTitle(), type: 'map', component: MapComponent, data: {}, style: {height: 'auto'}, props: {}}
                                            ]
                                        },
                                        {
                                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 4},
                                            components: [
                                                {id: 'averageWind', title: "AVERAGE WIND CONDITIONS", type: 'text', component: InfoDeviderBox, data: {}, style: {height: '332px'}, props: {}}
                                            ]
                                        },
                                        {
                                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 4},
                                            components: [
                                                {id: 'averageCurrents', title: "AVERAGE CURRENTS CONDITIONS", type: 'text', component: InfoDeviderBox, data: {}, style: {height: '332px'}, props: {}}
                                            ]
                                        },
                                        {
                                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 4},
                                            className: 'padding-left-right-7',
                                            layout: [
                                                {
                                                    columns: [
                                                        {
                                                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                                            components: [
                                                                {id: 'averageRpm', title: "AVERAGE RPM", type: 'text', component: InfoDeviderBox, data: {}, style: {height: '130px'}, props: {}}
                                                            ]
                                                        },
                                                        {
                                                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                                            components: [
                                                                {id: 'sog', title: "SOG", type: 'text', component: InfoDeviderBox, data: {}, style: {height: '127px'}, props: {}}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 4, order: 2},
                            className: 'padding-left-right-7',
                            layout: [
                                {
                                    columns: [
                                        {
                                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                            components: [
                                                {id: 'reportPeriod', title: "", type: 'text', component: ReportPeriodComponent, data: {}, style: {}, props: {}}
                                            ]
                                        },
                                        {
                                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                            components: [
                                                {id: 'routeInfo', title: "ROUTE INFO", type: 'text', component: RouteInfo, data: {}, style: {height: '83px'}, props: { removeFromLayout: !reportsStore().isTelegramConfigured  }}
                                            ]
                                        },
                                        {
                                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                            components: [
                                                {id: 'totalFuelConsumption', title: '', type: 'text', component: TotalFuelConsumption, data: {}, style: {height: '170px'}, props: {}}
                                            ]
                                        },
                                        {
                                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                                            components: [
                                                {id: 'fcr', title: "FUEL CONSUMPTION RATE", type: 'barChart', component: BarChart, data: {}, style: {height: '330px'}, props: {}}
                                            ]
                                        },
                                    ]
                                }
                            ]
                        }
                    ]
                },{
                    columns: [
                        {
                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 4},
                            className: 'padding-left-right-7',
                            layout: [
                                {
                                    columns: [
                                        {
                                            grid: {xs: 12, sm: 12, md: 12, lg: 6, xl: 12},
                                            components: [
                                                {component: EventTypeList, id: 'vesselLog', title: "VESSEL'S LOG", data: {}, style: {height: '485px'}, props: {}, type: 'text'}
                                            ]
                                        },
                                        {
                                            grid: {xs: 12, sm: 12, md: 12, lg: 6, xl: 12},
                                            components: [
                                                { id: 'navigationStatusChart', title: "NAVIGATION STATUS", component: NavigationStatusChart, data: {}, style: { height: '300px' }, props: { } }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            className: 'padding-left-right-7 widgets-cols-padding',
                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 8},

                            components: [
                                { id: 'vesselMonitoringSyncedCharts', footer: <ArrivalDepartureFooter/>, title: "VESSEL'S MONITORING", component: VesselMonitoringChart, data: {}, style: { height: '860px' }, props: { } },
                            ]
                        }
                    ]
                }, {
                    columns: [
                        { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 6 },
                            components: [
                            { id: 'runningTime', component: RunningTime,
                                title: 'GENERATOR ENGINE RUNNING TIME', data: {}, style: {minHeight: '211px'}, props: {} },
                            ]
                        },
                        { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 6 },
                        components: [
                          { id: 'loadHistory', component: LoadHistoryLineChart,
                          title: 'GENERATOR ENGINES LOAD HISTORY', data: {}, style: {height: '551px'}, props: {} },
                        ]
                      }
                    ]
                }
            ]
        };
    };

    const initToDates = (from, to, noRegister) => {
        setInit(true);
        setTimeout(() => dispatch({type: 'reports/setFromTo', payload: {from, to}}));

        reportsUtils.updateFromTo(
            dispatch,
            { 
                from: from, 
                to: to 
            },
            reportsStore().fromToChanged
        );

        setWidgets(getWidgetsPreferences());
        isMounted = true;
        currentVesselId = vesselStore().selectedVessel;
        if(!noRegister) goToDates();
        setReportPeriodData({from: reportsStore().fromTo.from, to: reportsStore().fromTo.to});
    }

    const getTelegramConfiguration = async () => {
        return new Promise((resolve) => {
            if(!vesselStore().selectedVessel || vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) return;
            let isVesselTelegramConfigured = false, lastTelegramDate = reportsStore().fromTo.from;
            reportsUtils.getTelegramsConfig({vesselIds: [vesselStore().selectedVessel] }).then(response => {
                if (response?.data) {
                    isVesselTelegramConfigured = response.data[vesselStore().selectedVessel]?.hasTelegramConfiguration;
                    lastTelegramDate = response.data[vesselStore().selectedVessel]?.lastTelegramDate || null;
                    firstTelegramDate = response.data[vesselStore().selectedVessel]?.firstTelegramDate || null;
                }
                dispatch({type: 'reports/setIsTelegramConfigured', payload: isVesselTelegramConfigured});
                resolve({isVesselTelegramConfigured, lastTelegramDate});
            })
        })
    }

    const getMonthlyReportsDates = async (selectedMonth) => {
        return new Promise((resolve) => {
            let from = moment(Utils.nowOrDemo()).subtract(1, 'days').set({hour:3,minute:0,second:0,millisecond:0}).valueOf();

            if(pushFromFleetReport) from = props.location?.state.reportPeriod?.timestampTo;
            if(!pushFromFleetReport && selectedMonth) from = moment(selectedMonth).set({hour:12,minute:0,second:0,millisecond:0}).valueOf();

            reportsUtils.getTelegramsMonthlyDates({
                vesselIds: [vesselStore().selectedVessel],
                datetime: from
            }).then(activeDatesInMonth => {
                const lastActiveDay = pushFromFleetReport 
                ? props.location?.state?.reportPeriod.timestampTo
                : activeDatesInMonth.data.length 
                ? activeDatesInMonth.data[activeDatesInMonth.data.length - 1]
                : Utils.nowOrDemo();
                if (activeDatesInMonth?.data?.length > 0) setActiveDates(activeDatesInMonth.data);
                resolve(lastActiveDay);
                pushFromFleetReport = false;
            })
        })
    }

    const getDailyReportsDates = async lastActiveDay => {
        return new Promise((resolve) => {
            reportsUtils.getTelegramsDailyDates({
                vesselIds: [vesselStore().selectedVessel],
                datetime: lastActiveDay,
            }).then(datesWithReports => {
                const timeFrom = datesWithReports.data[vesselStore().selectedVessel]?.timestampFrom;
                const timeTo = datesWithReports.data[vesselStore().selectedVessel]?.timestampTo;

                // todo: here we have to handle the case the vessel did not have report dates.
                if (!timeTo || !timeFrom) return; // todo: this is not acceptable. Causes page to crash.

                resolve({timeFrom, timeTo});
            })
        })
    }

    const mountFunction = async () => {
        if (vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) return;
        const telegramConfiguration = await getTelegramConfiguration();
        const hasTelegramConfiguration = telegramConfiguration.isVesselTelegramConfigured;
        const lastTelegramDate = telegramConfiguration.lastTelegramDate;

        if(hasTelegramConfiguration) {
            const lastActiveDay = await getMonthlyReportsDates(lastTelegramDate);
            if(lastActiveDay) {
                const {timeFrom, timeTo} = await getDailyReportsDates(lastActiveDay);
                initToDates(timeFrom, timeTo);
            } else {
                const timeFrom = moment(Utils.nowOrDemo()).set({hour:3,minute:0,second:0,millisecond:0}).valueOf();
                const timeTo = moment(Utils.nowOrDemo()).set({hour:23,minute:59,second:59,millisecond:0}).valueOf();
                initToDates(timeFrom, timeTo, true);
            }
        } else {
            const lastActiveDay = moment(Utils.nowOrDemo()).subtract(1, 'days').set({hour:12,minute:0,second:0,millisecond:0}).valueOf();
            const {timeFrom, timeTo} = await getDailyReportsDates(lastActiveDay);
            initToDates(timeFrom, timeTo);
        }
    }
    
    useEffect(() => {
        const controller = new AbortController();
        Utils.signal = controller.signal;

        // if initially no vessel group is selected, then select the user's default group
        if(vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id) dispatch({ type: 'vesselGroup/setSelectedVesselGroup', payload: userStoreJSX.user.defaultGroup });

        if(vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) dispatch({ type: 'vessel/setSelectedVessel', payload: vesselUtils.fleetVesselsIds()[0] });
        pushFromFleetReport = props.location?.state?.pushFromFleetReport;
        mountFunction();

        return () => {
            controller.abort();
            isMounted = false;
            Utils.signal = null;
            dispatch({ type: 'vessel/setSelectedVessel', payload: vesselStore().allVesselsObj.vesselId });
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!vesselStore().selectedVessel) return;
        if(!isMounted) return;
        if(!init) return;
        if(vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) {
            setTimeout(() => props.history.push('/reports/dailyGroupReport'));
        }

        if(vesselStore().selectedVessel !== currentVesselId) {
            currentVesselId = vesselStore().selectedVessel;
            mountFunction();
        }
    // eslint-disable-next-line
    }, [vesselStoreJSX.selectedVessel]);

    // eslint-disable-next-line
    useEffect(() => setWidgets(getWidgetsPreferences()), [reportsStoreJSX.isTelegramConfigured]);

    const createLayoutProps = (data, props = widgets) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
                    ('id' in newComponent) && !('element' in newComponent) && (newComponent.id in data) && (newComponent.props = data[newComponent.id]);
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    }


    const data = {
        vesselRoute: {
            reports: true,
            toDateIsToday: true,
            fromTo: reportsStoreJSX.fromTo,
            fromToChanged: reportsStoreJSX.fromToChanged,
            canInitMap: init
        },
        averageWind: {
            data: averageWind,
            loading: requestOnGoing.averageWind,
        },
        averageCurrents: {
            data: averageCurrents,
            loading: requestOnGoing.averageCurrents,
        },
        averageRpm: {
            data: averageRpm,
            loading: requestOnGoing.averageRpm,
            premiumValue: true,
        },
        sog: {
            data: sog,
            loading: requestOnGoing.sog,
        },
        legends: {legends: legendsData()},
        routeInfo: {
            data: routeInfo,
            loading: requestOnGoing.routeInfo,
        },
        fcr: {
            chartData: fcr,
            noStack: true,
            exportingChartTitle: 'FUEL CONSUMPTION RATE',
            loading: requestOnGoing.fcr
        },
        loadHistory: {
            data: loadHistory,
            exportingChartTitle: 'GENERATOR ENGINES LOAD HISTORY',
            loading: requestOnGoing.loadHistory
        },
        vesselLog: {
            items: vesselLog,
            componentGrid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
            iconGrid: {xs: 'hidden', sm: 'hidden', md: 'hidden', lg: 'hidden', xl: 3},
            loading: requestOnGoing.vesselLog,
            className: 'eventTypeList',
        },
        navigationStatusChart: {
            data: navigationStatusChartData,
            genericChartOptions: navigationStatusChartUtils,
            progressChartOptions: navigationStatusChartUtils.progressChartOptions(),
            loading: requestOnGoing.navigationStatusChart,
        },
        vesselMonitoringSyncedCharts: {
            data: vesselMonitoringChartData,
            exportingChartTitle: "VESSEL'S MONITORING",
            loading: requestOnGoing.vesselMonitoringChart,
        },
        reportPeriod: {
            from: reportsStoreJSX.isTelegramConfigured ? moment(reportPeriodData.from).utc().format('DD/MM/YYYY HH:mm') : moment(reportPeriodData.from).utc().format('DD/MM/YYYY HH:mm'),
            to: reportsStoreJSX.isTelegramConfigured ? moment(reportPeriodData.to).utc().format('DD/MM/YYYY HH:mm') : moment(reportPeriodData.to).utc().format('HH:mm'),
            fullHeight: !reportsStoreJSX.isTelegramConfigured,
        },
        runningTime: {
            data: runningTime,
            loading: requestOnGoing.runningTime,
            isReportsWidget: true
        },
        totalFuelConsumption: {
            data: totalFuelConsumption,
            loading: requestOnGoing.totalFuelConsumption,
        }
    };

    return (
        <div className="reports main-content-padding">
            <ReportsHeader from={moment(reportsStoreJSX.fromTo.from).utc()} to={moment(reportsStoreJSX.fromTo.to).utc()} updateFromTo={updateFromTo} 
             goToDates={goToDatesButtonClicked} activeDatesInMonth={activeDates} getMonthlyReportsDates={getMonthlyReportsDates} 
             getDailyReportsDates={getDailyReportsDates} firstTelegramDate={firstTelegramDate} />
            <div className="reports__widgets">
                <Layout {...createLayoutProps(data)}/>
            </div>
        </div>
    );

}

export default withRouter(DailyVesselReport);
