import React, { useEffect, useState } from 'react';
import {licensing} from "common/store/licensing";
import classnames from 'classnames';
import SensorsTelegramsDataDivided from 'reports/SensorsTelegramsDataDivided';
import {reportsStore} from 'common/store/storeUtils';

const doubleDataFirst = (isTelegramConfigured) => {
    return classnames('double-data__first', {'sensorDataOnly': !isTelegramConfigured})
}

const DoubleData = (props) => {
    const [isTelegramConfigured, setIsTelegramConfigured] = useState(false);    
    let sensorsOrAisValueTemp = '', reportsValueTemp= ''; 

    useEffect(() => {
        // in case we are in the general KPIS widget, then the isTelegramConfigured comes as a prop
        if(props?.data?.isTelegramConfigured) setIsTelegramConfigured(props?.data?.isTelegramConfigured);
        else setIsTelegramConfigured(reportsStore().isTelegramConfigured);
    // eslint-disable-next-line
    }, [props?.data]);

    switch (props?.colDef?.field) {
        case 'avgSog':
            sensorsOrAisValueTemp = props.data?.avgSOG && props.data?.avgSOG[0]; 
            reportsValueTemp = props.data?.avgSOG && props.data?.avgSOG[1];
            break;
        case 'avgRpm':
            sensorsOrAisValueTemp = props.data?.avgRPM && props.data?.avgRPM[0]; 
            reportsValueTemp = props.data?.avgRPM && props.data?.avgRPM[1];
            break;
        case 'avgWindSpeed':
            sensorsOrAisValueTemp = props.data?.avgWindSpeed && props.data?.avgWindSpeed[0]; 
            reportsValueTemp = props.data?.avgWindSpeed && props.data?.avgWindSpeed[1];
            break;
        case 'avgCurrentSpeed':
            sensorsOrAisValueTemp = props.data?.avgCurrentSpeed && props.data?.avgCurrentSpeed[0]; 
            reportsValueTemp = props.data?.avgCurrentSpeed && props.data?.avgCurrentSpeed[1];
            break;
        case 'avgTotalConsumption':
            sensorsOrAisValueTemp = props.data?.avgTotalConsumption && props.data?.avgTotalConsumption[0]; 
            reportsValueTemp = props.data?.avgTotalConsumption && props.data?.avgTotalConsumption[1];
            break;   
        case 'avgMeConsumption':
            sensorsOrAisValueTemp = props.data?.avgMeConsumption && props.data?.avgMeConsumption[0]; 
            reportsValueTemp = props.data?.avgMeConsumption && props.data?.avgMeConsumption[1];
            break;
        case 'avgGeConsumption':
            sensorsOrAisValueTemp = props.data?.avgGeConsumption && props.data?.avgGeConsumption[0]; 
            reportsValueTemp = props.data?.avgGeConsumption && props.data?.avgGeConsumption[1];
            break;
        case 'avgBoConsumption':
            sensorsOrAisValueTemp = props.data?.avgBoConsumption && props.data?.avgBoConsumption[0]; 
            reportsValueTemp = props.data?.avgBoConsumption && props.data?.avgBoConsumption[1];
            break;
        default:
        // do nothing
    }

    return (
        props?.data?.isGeneralKPISWidget
            ? <>
                {
                    (isTelegramConfigured || licensing.lightCondition()) // if vessel is premium with reports config or vessel is light, then show double data
                        ? <div className='double-data__generalKpisWrapper'>
                            <SensorsTelegramsDataDivided 
                                sensorsValue={sensorsOrAisValueTemp} 
                                reportsValue={reportsValueTemp} 
                                classes={{dividerClasses: 'textDivider small', sensorsClass: licensing.lightCondition() ? 'ais' : 'sensor', reportsClass: 'report', wrapperClass: 'flex-space-around'}}
                                unit='' 
                            />  
                        </div>
                        // or else (case where vessel is premium without reports config) show only sensors data
                        : <div className="double-data"> 
                            <div className={doubleDataFirst(isTelegramConfigured)}>
                                {sensorsOrAisValueTemp}
                            </div>
                        </div>
                }
            </>
            : <div className="double-data">
                <div className={doubleDataFirst(isTelegramConfigured)} style={{color: licensing.lightCondition(null, props?.data?.vesselObj) ? 'var(--ais_color)' : 'var(--main_blue)'}}>
                    { props.colDef?.field === 'avgWindSpeed' && props.data?.avgWindSpeed && props.data?.avgWindSpeed[0] }
                    { props.colDef?.field === 'avgSog' && props.data?.avgSOG && props.data?.avgSOG[0] }
                    { props.colDef?.field === 'avgRpm' && props.data?.avgRPM && props.data?.avgRPM[0] }
                </div>
                {isTelegramConfigured &&
                <div className="double-data__second">
                    { props.colDef?.field === 'avgWindSpeed' && props.data?.avgWindSpeed && props.data?.avgWindSpeed[1] }
                    { props.colDef?.field === 'avgSog' && props.data?.avgSOG && props.data?.avgSOG[1] }
                    { props.colDef?.field === 'avgRpm' && props.data?.avgRPM && props.data?.avgRPM[1] }
                </div>
                }
            </div>
    )
}

export default DoubleData;
