import React from 'react';

const ArrivalDepartureFooter = () => {
    return(
        <div className="full-width widget-footer-legend flex-centered-end">
            LEGEND: <div className="dottedLine dottedLine__red"><div /><div /><div /></div> Arrival <div className="dottedLine dottedLine__green"><div /><div /><div /></div>  Departure
        </div>
    )
}

export default ArrivalDepartureFooter;