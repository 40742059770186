import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import service from 'common/js/service';
import { BoilersUtils } from "Utilities/boilers";
import Overview from 'components/monitoringCategories/overview';
import Hull from 'components/monitoringCategories/hull';
import MainEngine from 'components/monitoringCategories/mainEngine';
import GeneratorEngines from 'components/monitoringCategories/generatorEngine';
import Boilers from 'components/monitoringCategories/boilers';
import DateRange from 'components/monitoringDateRangePicker/dateRangePicker';
import NavigationTabs from "components/navigationTabs/navigationTabs";
import ViewAllPopup from "components/monitoringCategories/viewAllPopup";
import {licensing} from "common/store/licensing";
import MapComponent from 'components/map/map';
import { useDispatch, useSelector } from 'react-redux';
import { monitoringStore, vesselStore } from 'common/store/storeUtils';

const _TABS = [
    {title: 'OVERVIEW', name: 'overview', visible: true},
    {title: 'HULL', name: 'hull', visible: true},
    {title: 'MAIN ENGINE', name: 'mainEngine', visible: true},
    {title: 'GENERATOR ENGINE', name: 'generatorEngine', visible: true},
    {title: 'BOILERS', name: 'boilers', visible: true} 
];

const activeTabsInLightView = [
    'overview', 'hull', 'mainEngine', 'generatorEngine', 'boilers'
];

let _CurrentTab = 'overview';

const VesselMonitoring = () => {
    const [tabs, setTabs] = useState(JSON.parse(JSON.stringify(_TABS)));
    const [currentTab, setCurrentTab] = useState('overview');
    const [boilerConfig, setBoilerConfig] = useState(true);

    // store vars
    const monitoringStoreJSX = useSelector(state => state.monitoring);
    const vesselStoreJSX = useSelector(state => state.vessel);
    const dispatch = useDispatch();

    const seTabsAndBoilersConfig = value => {
        let _tabs = [...tabs];
        setBoilerConfig(value);
        _tabs[4].visible = value;
        setTabs(_tabs);

        if(_CurrentTab === 'boilers' && !_tabs[4].visible) {
            setTimeout(() => setCurrentTab('overview'));
            _CurrentTab = 'overview';
        }
    }

    const checkForVesselBoilersConfig = () => {
        service.getBoilerTypes(vesselStore().selectedVessel).then(response => {
            response = response?.filter(item => item.type && item.subId && BoilersUtils.acceptedBoilerTypes.includes(item?.type));
            if(!response.length) seTabsAndBoilersConfig(false);
            else seTabsAndBoilersConfig(true);
        });
    }

    useEffect(() => {
        if(!vesselSelected) return;
        if(licensing.lightCondition()) {
            setBoilerConfig(true);
            return setTabs(JSON.parse(JSON.stringify(_TABS)));
        }
        checkForVesselBoilersConfig();

        return () => setBoilerConfig(true);
        // eslint-disable-next-line
    }, [vesselStoreJSX.selectedVessel]);

    const updateCurrentTab = tab => {
        if (!monitoringStore().fromTo.from && !monitoringStore().fromTo.to) return;
        setCurrentTab(tab);
        _CurrentTab = tab;
    }

    const homeClasses = () => {
        return classnames({"main-content-padding marg-t-30": vesselStore().allVesselsObj.vesselId !== vesselStore().selectedVessel});
    }

    const vesselSelected = vesselStore().allVesselsObj.vesselId !== vesselStore().selectedVessel;

    const navTabsProps = {
        tabs: tabs,
        activeTabsInLightView: activeTabsInLightView,
        tabsWithLocker: true,
        currentTab: currentTab,
        updateCurrentTab: updateCurrentTab
    }

    return (
        <>
        {vesselSelected &&
            <div className='date-range-picker-outer-div'>
                <DateRange/>
            </div>}
            <div className={homeClasses()}>

                {currentTab !== 'overview' && <ViewAllPopup />}

                <MapComponent
                    fromTo={monitoringStoreJSX.fromTo} 
                    resetLegView={monitoringStoreJSX.resetLegView} 
                    fromToChanged={monitoringStoreJSX.fromToChanged} 
                    setVesselDataHistory={(value) => dispatch({ type: 'monitoring/setVesselDataHistory', payload: value})} 
                    setDetailedData={(value) => dispatch({ type: 'monitoring/setDetailedData', payload: value})}  
                    />

                {
                    vesselSelected 
                    && <NavigationTabs 
                    {...navTabsProps} 
                    classes={{
                        mainClass: 'navigation-tabs',
                        extraClasses: 'flex-space-around'
                    }}
                    />
                }

                {vesselSelected && currentTab === 'overview' && <Overview/>}

                {vesselSelected && currentTab === 'hull' && <Hull/>}

                {vesselSelected && currentTab === 'mainEngine' && <MainEngine/>}

                {vesselSelected && currentTab === 'generatorEngine' && <GeneratorEngines/>}

                {vesselSelected && currentTab === 'boilers' && boilerConfig && <Boilers/>}
            </div>
        </>
    );
}

export default VesselMonitoring;
