import moment from 'moment';

// global store for saving information that the reports.js needs in order to make the widget requests (from/to dates)
// this information is being set in both the monitoring and the reports pages and overall cii page
const registerWidgetsStore = {
    fromTo: { 
        from: moment().subtract(1, 'day').toDate(), 
        to: moment().toDate() 
    },
    setFromTo: (fromTo) => registerWidgetsStore.fromTo = fromTo
}

export default registerWidgetsStore;