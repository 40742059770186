import React, {useEffect, useState} from 'react';
import SyncCharts from "../../components/charts/genericSyncCharts/syncCharts";
import syncChartsOptions from "../../components/charts/genericSyncCharts/syncChartsOptions";
import {monitoringStore} from 'common/store/storeUtils';

const FuelConsumptionLineChart = (props) => {
    const [syncCharts, setSyncCharts] = useState(syncChartsOptions());

    useEffect(() => {
        if (!props.data) return;
        let options = props.data;
        
        // in case of detailed data, add the detailed data plotlines along with the departure/arrival ones
        if(monitoringStore().detailedData?.status && options?.xAxis?.plotLines && props?.data?.plotLines) 
            options.xAxis.plotLines = [...options?.xAxis?.plotLines, ...props?.data?.plotLines];
        else options.xAxis.plotLines = props?.data?.plotLines;

        setSyncCharts(options);

        // eslint-disable-next-line
    }, [props.data])

    return (
        <div>
            {props.data !== "" && (
                <SyncCharts options={syncCharts} />
            )}
        </div>
    )
}

export default FuelConsumptionLineChart;
