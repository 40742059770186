import React, { createRef, useEffect } from 'react';
import SplitedButton from 'components/splitedButton/splitedButton';
import CheckboxOptions from 'components/checkboxOptions/checkboxOptions';
import PropTypes from 'prop-types';
import ArbitraryPvFvUtils from 'widgets/vesselData/arbitraryPvFvUtils';
import TypeOfFuelAutoComplete from 'components/autocomplete/typeOfFuelAutoComplete';
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import { ReactComponent as InformationIcon } from 'assets/img/app/global-imgs/informationIcon.svg';

const CURVES_SELECTED_OVER_LIMIT_TOOLTIP_TITLE = 'You can display up to 3 wind speed and direction combinations at a time. Please adjust the filters accordingly.';
const ONLY_ONE_OPTION_TITLE = 'You must have selected at least one option';
const DISABLED_OPTIONS_TITLE = 'Please enter a valid emission factor or select a fuel type first';

const ModelDetails = ({ data, selectedOptions, widgetTitle, isLight, setComponentData, setLoader, requestInfo, widget, componentData, footerData, setFooterData, ifFVEligible, getWeatherConditionsData, fuelOilSelectedOption, setFuelOilSelectedOption, disabledAllOptions, setDisabledAllOptions, plotBandsInfo, setPlotBandsInfo }) => {
    const windSpeedRef = createRef();
    const windDirectionRef = createRef();

    // 'Calm Sea State' is the default option, so we select it as soon as we have retrieved the wind speed data (we select the last wind speed option that will be the 'Calm Sea State')
    useEffect(() => {
        // if at least one option is already selected, then do not click the default Calm Sea State option,
        // or else, the user was navigated in this tab for the first time and the default option should be selected
        if(!windSpeedRef.current || !data?.windSpeedOptions || selectedOptions?.windSpeedSelectedOptions?.length) return;
        
        const windSpeedOptions = windSpeedRef.current.children;

        // the 3rd param determines if the requests will be made or not, and in case of the automated default wind speed selection, we do not want them to run (because they already run on the ArbitraryPvFv.jsx mount)
        onWindSpeedCheckBoxOptionClick({ currentTarget: windSpeedOptions[0] }, 'Calm Sea State', true);

        // eslint-disable-next-line
    }, [data?.windSpeedOptions, selectedOptions?.windSpeedSelectedOptions]);

    // if user tries to select more than 6 curves, then the limit is exceeded
    const selectionLimitIsExceeded = (updatedSelectedWindSpeedOptions, updatedSelectedWindDirectionOptions) => {
        let numberOfCurves = 0;
        
        updatedSelectedWindSpeedOptions.forEach(selectedOption => {
            if(selectedOption === 'Calm Sea State') numberOfCurves = numberOfCurves + 2;
            else numberOfCurves = numberOfCurves + (updatedSelectedWindDirectionOptions.length * 2)
        });

        return numberOfCurves > 6;
    }

    // if with the new option the selection limit will be exceeded, then show an appropriate tooltip message to the user
    // note: if the tooltip has an empty title, then it will not be shown at all
    const getTooltipTitle = (option, checkboxSelectedOptions, type) => {        
        const allOptions = [...checkboxSelectedOptions, option];

        if(type === 'windSpeed')
            return disabledAllOptions
                ? DISABLED_OPTIONS_TITLE
                : !checkboxSelectedOptions.includes(option) && selectionLimitIsExceeded(allOptions, selectedOptions.windDirectionSelectedOptions) 
                ? CURVES_SELECTED_OVER_LIMIT_TOOLTIP_TITLE 
                : (checkboxSelectedOptions.includes(option) && checkboxSelectedOptions.length === 1)
                ? ONLY_ONE_OPTION_TITLE
                : '';
        else if(type === 'windDirection')
            return disabledAllOptions
                ? DISABLED_OPTIONS_TITLE
                : !checkboxSelectedOptions.includes(option) && selectionLimitIsExceeded(selectedOptions.windSpeedSelectedOptions, allOptions) 
                ? CURVES_SELECTED_OVER_LIMIT_TOOLTIP_TITLE
                : (checkboxSelectedOptions.includes(option) && checkboxSelectedOptions.length === 1)
                ? ONLY_ONE_OPTION_TITLE
                : '';
        }

    const setWindDirectionOptions = (newWindDirectionOptions, newDisabledAllWindDirectionOptions, removeOption ) => {
        // in case of a light vessel, we do not show the wind direction options
        if(isLight) return selectedOptions.windDirectionSelectedOptions;

        // transform the 'HTMLCollection' element to a js array
        const allWindDirectionOptions = [].slice.call(windDirectionRef.current.children);

        if(newWindDirectionOptions !== selectedOptions.windDirectionSelectedOptions) selectedOptions.setWindDirectionSelectedOptions(newWindDirectionOptions);
        if(newDisabledAllWindDirectionOptions !== selectedOptions.disabledAllWindDirectionOptions) selectedOptions.setDisabledAllWindDirectionOptions(newDisabledAllWindDirectionOptions);

        if(removeOption) allWindDirectionOptions.forEach(option => option.classList.remove('active'));
        else allWindDirectionOptions.forEach(option => option.classList.add('active'));

        return newWindDirectionOptions;
    }

    const setWindDirectionOptionsAfterWindSpeedSelection = (option, removeOption, updatedSelectedOptions) => {
        // in case the user has selected only the 'Calm Sea State' wind speed option, then all the wind direction options are disabled
        if(updatedSelectedOptions.length === 1 && updatedSelectedOptions.includes('Calm Sea State')) return setWindDirectionOptions([], true, removeOption);
        // in case the user has deselected the 'Calm Sea State' option and there are not other selected options, initialize the wind direction options
        else if(updatedSelectedOptions.length === 0 && option === 'Calm Sea State') return setWindDirectionOptions([], false, removeOption);
        // in case we add an option that is the first non Calm Sea State option and also no wind direction option is selected yet, then select all the wind direction options
        // and also if options are disabled, then enable them again
        else if (!removeOption && option !== 'Calm Sea State') {
            if (selectedOptions.disabledAllWindDirectionOptions) selectedOptions.setDisabledAllWindDirectionOptions(false);
            if (
                selectedOptions.windDirectionSelectedOptions.length === 0
                && (updatedSelectedOptions.length === 1 || (updatedSelectedOptions.length === 2 && updatedSelectedOptions.includes("Calm Sea State")))
                // wind direction options are: 'Head', 'Side' and 'Tail' and we always need to show as default the first one of them.
                // To ensure that the wind directions are in the right order we sort them, and then we get the first element. The right order is ['Head' ,'Side', 'Tail'] which happens to be alphabetically, so
                // we always set as default the data.windDirectionOptions.sort()[0]
            ) { return setWindDirectionOptions([data.windDirectionOptions.sort()[0]], false, removeOption) }
        }

        return selectedOptions.windDirectionSelectedOptions;
    }
    
    const updateFooterDataOnRemoveOption = (option) => {
        const data = {...footerData};
        const validOption = option === 'Calm Sea State' ? 'Calm' : option;

        //for option (e.g. options = Side) remove from footerData from every
        //confidence (key) from array the option
        Object.keys(data).forEach(key => {
            data[key] = data[key].filter(string => !string.includes(validOption));

            if(!data[key]?.length) delete data[key];
        });

        setFooterData(data);
    }

    const onWindSpeedCheckBoxOptionClick = (e, option, notExecuteRequests) => {
        const currentCheckbox = e.currentTarget;
        if(!currentCheckbox) return;
        const limitWillBeExceeded = !currentCheckbox.classList.contains('active') && selectionLimitIsExceeded([...selectedOptions.windSpeedSelectedOptions, option], selectedOptions.windDirectionSelectedOptions);
        const removeOption = currentCheckbox.classList.contains('active');

        updateFooterDataOnRemoveOption(option);

        // in case the limit is about to be exceeded (in case we select an option) or user tries to de-select the only selected option, then clicking the option should not do anything
        if(limitWillBeExceeded || (removeOption && selectedOptions.windSpeedSelectedOptions.length === 1) || disabledAllOptions) return;

        currentCheckbox.classList.toggle('active');

        // update the wind speed and wind direction options appropriately
        selectedOptions.setWindSpeedSelectedOptions((prevSelectedOptions) => {
            const updatedSelectedOptions = [...prevSelectedOptions];

            // add or remove the option from the selected options (the real update occurs in the return statement)
            if(removeOption) {
                const indexOfItemToDelete = updatedSelectedOptions.findIndex((item) => item === option);
                updatedSelectedOptions.splice(indexOfItemToDelete, 1);

                setWindDirectionOptionsAfterWindSpeedSelection(option, removeOption, updatedSelectedOptions);

                if(selectedOptions.mode === 'chart') {
                    // since user deselects an option, remove from the chart all the series that is relevant with this option and update the chart (requests are not made in this case)
                    const componentDataTemp = cloneDeep(componentData); // cloneDeep is needed in order for the chart to update
                    // In case the deselected option is Calm Sea State remove all 4 calm sea state options both for scatter and curves ('Cleanest State (Calm)', 'Current (Calm)', 'Sea Trial', 'Source Data (Calm)') otherwise just remove the related wind speed options
                    componentDataTemp.chartDataJSON.series = componentDataTemp.chartDataJSON.series.filter((series) => {
                        const calmSeaStateOptions = ['Cleanest State (Calm)', 'Current (Calm)', 'Sea Trial', 'Source Data (Calm)'];
                        return (option === 'Calm Sea State') ? !calmSeaStateOptions.includes(series.name) : !series.name.includes(option);
                    });

                    setComponentData(componentDataTemp);
                }
            }
            // if user selects an option, then make all the necessary requests for all selected options, retrieve the data and update the chart
            else {
                updatedSelectedOptions.push(option);
                const updatedWindDirectionOptions = setWindDirectionOptionsAfterWindSpeedSelection(option, removeOption, updatedSelectedOptions);

                if(!notExecuteRequests) {
                    // make requests for retrieving data
                    ArbitraryPvFvUtils.getDataAndUpdateWidget(
                        widgetTitle,
                        setComponentData,
                        setLoader,
                        null,
                        requestInfo,
                        widget,
                        data.weatherConditionOptions,
                        // pass the updated wind speed and wind direction options (the state does not contain the updated values yet)
                        { ...selectedOptions, windSpeedSelectedOptions: updatedSelectedOptions, windDirectionSelectedOptions: updatedWindDirectionOptions },
                        setFooterData,
                        setPlotBandsInfo
                    );
                }
            }

            return updatedSelectedOptions;
        });
    }

    const onWindDirectionCheckBoxOptionClick = (e, option) => {
        const currentCheckbox = e.currentTarget;
        const limitWillBeExceeded = !currentCheckbox.classList.contains('active') && selectionLimitIsExceeded(selectedOptions.windSpeedSelectedOptions, [...selectedOptions.windDirectionSelectedOptions, option]);
        const removeOption = currentCheckbox.classList.contains('active');

        updateFooterDataOnRemoveOption(option);

        // in case the limit is about to be exceeded (in case we select an option) or user tries to de-select the only selected option, then clicking the option should not do anything
        if(limitWillBeExceeded || (removeOption && selectedOptions.windDirectionSelectedOptions.length === 1) || selectedOptions.disabledAllWindDirectionOptions || disabledAllOptions) return;

        currentCheckbox.classList.toggle('active');

        selectedOptions.setWindDirectionSelectedOptions((prevSelectedOptions) => {
            const updatedSelectedOptions = [...prevSelectedOptions];

            // add or remove the option from the selected options (the real update occurs in the return statement)
            if(removeOption) {
                const indexOfItemToDelete = updatedSelectedOptions.findIndex((item) => item === option);
                updatedSelectedOptions.splice(indexOfItemToDelete, 1);

                if(selectedOptions.mode === 'chart') {
                    // since user deselects an option, remove from the chart all the series that is relevant with this option and update the chart (requests are not made in this case)
                    const componentDataTemp = cloneDeep(componentData); // cloneDeep is needed in order for the chart to update
                    componentDataTemp.chartDataJSON.series = componentDataTemp.chartDataJSON.series.filter((series) => !series.name.includes(option));

                    setComponentData(componentDataTemp);
                }
            }
            // if user selects an option, then make all the necessary requests for all selected options, retrieve the data and update the chart
            else {
                updatedSelectedOptions.push(option);
                
                // make requests for retrieving data
                ArbitraryPvFvUtils.getDataAndUpdateWidget(
                    widgetTitle,
                    setComponentData,
                    setLoader,
                    null,
                    requestInfo,
                    widget,
                    data.weatherConditionOptions,
                    // pass the updated wind direction options (the state does not contain the updated values yet)
                    { ...selectedOptions, windDirectionSelectedOptions: updatedSelectedOptions },
                    setFooterData,
                    setPlotBandsInfo
                );
            }

            return updatedSelectedOptions;
        });
    };

    const onDraftModeOptionClick = async(option) => {
        // if the option that the user tries to select is the one that is already selected, then return
        if(option.name === selectedOptions.draftModeSelectedOption.name) return;

        // in monitoring, depending on if the fuel-speed has data, disable or enable the fuel-speed tab (only for power-speed widget and not applicable for dashboard)
        if(widgetTitle === 'POWER-SPEED') ifFVEligible(option);

        const weatherConditionOptions = await getWeatherConditionsData(option.type);

        // set the default draft mode option to the selected one
        selectedOptions.setDraftModeSelectedOption(option);

        // make requests for retrieving data
        ArbitraryPvFvUtils.getDataAndUpdateWidget(
            widgetTitle, 
            setComponentData, 
            setLoader, 
            null, 
            requestInfo, 
            widget, 
            weatherConditionOptions,
            {...selectedOptions, draftModeSelectedOption: option}, // pass the updated draft mode (because the draftModeSelectedOption state var is not yet updated)
            setFooterData,
            setPlotBandsInfo
        );
    };

    const getWindSpeedFormattedOptions = (windSpeedOptions) => {
        if(!windSpeedOptions) return [];

        if(isLight) return ['Calm Sea State']; // in case of a light vessel, we show only the Calm Sea State checkbox option

        return windSpeedOptions.sort().map(option => option === 0 ? 'Calm Sea State' : `${option} Bf`); // the '0' option is equivalent to the 'Calm Sea State' option
    };

    const getTitleClasses = (disabled) => classnames({ 'optionTitle-disabled': disabled });

    return(
        <div className='modelDetails'>
            {/* draft mode dropdown */}
            <div className='draftModeOptions'>
                <div className={getTitleClasses(disabledAllOptions)}>
                    Draft Mode (Fore - Aft)
                </div>
                <Tooltip 
                    component={
                        <div>
                            <SplitedButton 
                                dropdownOptions={data.draftModeOptions ? data.draftModeOptions : []}
                                defaultValue={selectedOptions.draftModeSelectedOption}
                                onOptionClickListener={onDraftModeOptionClick}
                                disabledAllOptions={disabledAllOptions}
                            />
                        </div>
                    }
                    title={disabledAllOptions ? DISABLED_OPTIONS_TITLE : ''}
                    height='auto'
                />
            </div>
            <div className='windSpeedOptions'>
                <span className={getTitleClasses(disabledAllOptions)}> 
                    Wind Speed
                    <Tooltip
                        component={<InformationIcon className="svg-path pointer marg-l-7 marg-b-2" />}
                        height="auto"
                        title={
                            <div className='flex-centered-col-start marg-t-10 marg-b-10 marg-r-5 marg-l-5'>
                                <div>
                                    The Calm Sea State curve is calculated for zero wind speed and
                                    is accompanied by scatter points representing wind speeds up to
                                    2 Bfs.
                                </div>
                                <div className='flex-centered-col-start marg-t-4 '>
                                    <div>3 Bf curves: Scatter points between 2 and 3 Bfs.</div>
                                    <div>4 Bf curves: Scatter points between 3 and 4 Bfs.</div>
                                    <div>5 Bf curves: Scatter points between 4 and 5 Bfs.</div>
                                </div>
                            </div>
                        }
                    />
                </span>
                <CheckboxOptions 
                    ref={windSpeedRef}
                    type='windSpeed'
                    options={getWindSpeedFormattedOptions(data.windSpeedOptions)}
                    getTooltipTitle={getTooltipTitle}
                    checkboxSelectedOptions={selectedOptions.windSpeedSelectedOptions}
                    onCheckBoxOptionClick={onWindSpeedCheckBoxOptionClick}
                    disabledAllOptions={disabledAllOptions}
                />
            </div>
            {
                !isLight // in case of a light vessel, we do not show the wind direction checkbox options
                    && <div className='windDirectionOptions'>
                            { 
                                data.windDirectionOptions?.length
                                    ? <span className={getTitleClasses(disabledAllOptions ? disabledAllOptions : selectedOptions.disabledAllWindDirectionOptions)}>
                                        Wind Direction
                                    </span>
                                    : null
                            }
                            <CheckboxOptions
                                ref={windDirectionRef}
                                type='windDirection'
                                options={
                                    data.windDirectionOptions
                                        ? data.windDirectionOptions.sort()
                                        : []
                                }
                                getTooltipTitle={getTooltipTitle}
                                checkboxSelectedOptions={selectedOptions.windDirectionSelectedOptions}
                                onCheckBoxOptionClick={onWindDirectionCheckBoxOptionClick}
                                disabledAllOptions={disabledAllOptions ? disabledAllOptions : selectedOptions.disabledAllWindDirectionOptions}
                            />
                        </div>
            }
            {
                (widgetTitle === 'CII-SPEED')
                    && <div className='typeOfFuelOptions'>
                            <span> Carbon Emission Factor </span>
                            <TypeOfFuelAutoComplete 
                                fuelOilSelectedOption={fuelOilSelectedOption}
                                setFuelOilSelectedOption={setFuelOilSelectedOption}
                                disabledAllOptions={disabledAllOptions}
                                setDisabledAllOptions={setDisabledAllOptions}
                                updateChart={
                                    (fuelOilSelectedOption) => ArbitraryPvFvUtils.getDataAndUpdateWidget(
                                        widgetTitle,
                                        setComponentData,
                                        setLoader,
                                        null,
                                        requestInfo,
                                        widget,
                                        data.weatherConditionOptions,
                                        {...selectedOptions, fuelOilSelectedOption},
                                        setFooterData,
                                        setPlotBandsInfo
                                    )
                                }
                            />
                        </div>
            }
        </div>
    )
}

ModelDetails.propTypes = {
    data: PropTypes.shape({}),
    selectedOptions: PropTypes.shape({}),
    widgetTitle: PropTypes.string, 
    isLight: PropTypes.bool,
    setComponentData: PropTypes.func,
    requestInfo: PropTypes.shape({}),
    widget: PropTypes.shape({}),
    componentData: PropTypes.shape({}),
    fuelOilSelectedOption: PropTypes.shape({}),
    setFuelOilSelectedOption: PropTypes.func,
    disabledAllOptions: PropTypes.bool,
    setDisabledAllOptions: PropTypes.func,
    plotBandsInfo: PropTypes.shape({}),
    setPlotBandsInfo: PropTypes.func
}

export default ModelDetails;