import hullStorage from "components/monitoringCategories/localStorages/hullStorage";
import meStorage from "components/monitoringCategories/localStorages/meStorage";
import geStorage from "components/monitoringCategories/localStorages/geStorage";
import overviewStorage from 'components/monitoringCategories/localStorages/overviewStorage';
import boStorage from 'components/monitoringCategories/localStorages/boStorage';
import { vesselGroupStore } from "common/store/storeUtils";
import { vesselStore } from 'common/store/storeUtils';
import moment from "moment";

const monitoringUtils = {
    updateFromTo: (dispatch, fromTo) => {
        dispatch({ type: 'monitoring/setLessThanADayPeriod', payload: {status: moment(fromTo.to).diff(moment(fromTo.from), 'hours') < 24}});
        dispatch({ type: 'monitoring/setDetailedData', payload: {status: false, ...fromTo}});
        dispatch({ type: 'monitoring/setFromTo', payload: fromTo});
        dispatch({ type: 'monitoring/setFromToChanged', payload: true});
    },
    
    allowTelegrams: (selectedVessel) => {
        // if vessel is selected, check it
        if (selectedVessel) return vesselStore().allVessels.filter(vessel => vessel.vesselId === selectedVessel)[0]?.allowFormTelegram;
        if (selectedVessel) return vesselStore().allVessels.filter(vessel => vessel.vesselCode === selectedVessel)[0]?.allowFormTelegram;
        // if vessel is not selected, take first of selected fleet
        if (!selectedVessel) return vesselStore().allVessels.filter(vessel => vessel.vesselGroupId === vesselGroupStore().selectedVesselGroup)[0]?.allowFormTelegram;
        // if vessel is not given as argument, get selected vessel from store(if exists )
        if ((vesselStore().selectedVessel !== vesselStore().allVesselsObj.vesselId || vesselStore().selectedVessel !== vesselStore().allVesselsObj.vesselCode) && !selectedVessel) return vesselStore().allVessels.filter(vessel => vessel.vesselId === vesselStore().selectedVessel)[0]?.allowFormTelegram;
        // else return false
        return false;
    },
    resetLocalMonitoringStores: () => {
      hullStorage.resetHullStorage();
      meStorage.resetMeStorage();
      geStorage.resetGeStorage();
      overviewStorage.resetOverviewStorage();
      boStorage.resetBoStorage();
    },
    resetFromTo: (dispatch) => {
        dispatch({ type: 'monitoring/setFromTo', payload: {from: null, to: null}});
        dispatch({ type: 'monitoring/setFromToChanged', payload: false});
    },
    resetLegView: (dispatch) => {
        dispatch({ type: 'monitoring/setResetLegView', payload: true });
        monitoringUtils.resetLocalMonitoringStores();
        dispatch({ type: 'monitoring/setDetailedData', payload: {status: false} });
    }
}

export default monitoringUtils;