const initialEventsState = {
    events: [],
    eventsUpdated: false
}

// events reducer
const events = (state = initialEventsState, action) => {
    switch(action.type) {
        case 'events/setEvents': {
            return {
                ...state,
                events: action.payload
            };
        }
        case 'events/setEventsUpdated': {
            return {
                ...state,
                eventsUpdated: action.payload
            };
        }
        default: 
            return state;
    }
}

export default events;