import React from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const fuelConsumptionBarChartGE = props => {
    return <div className="secondLevelCardColor">
        {props.data !== "" && (
            <HighchartsReact highcharts={Highcharts} options={props.data}/>
        )}
    </div>;
}

export default fuelConsumptionBarChartGE;
