import moment from "moment";
import {ReactComponent as fOil} from 'assets/img/app/timeline-imgs/fuelOil.svg';
import {ReactComponent as lOil} from 'assets/img/app/timeline-imgs/lubeOil.svg';
import { baseFormUtils } from 'common/store/storeUtils';

const bunkeringSchema = (editMode) => {
    return [
        {
            date: {
                type: 'datePicker',
                label: 'Bunkering Date',
                value: '',
                validation: {required: true},
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                    if (value === null) {
                        setFieldValue("date", "");
                    }
                }
            },
            port: {
                type: 'autocomplete',
                label: 'Port Bunkered',
                portsAutocomplete: true,
                value: '',
                validation: {required: true},
                onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                    if ( value !== null ) {
                        setFieldValue("port", value.port);
                    } else {
                        setFieldValue("port", '');
                    }
                },
            },
            person: {
                type: 'text',
                label: 'Responsible person',
                value: ''
            }
        },
        {
            bunkerings: {
                type: "array",
                label: {
                    title: "Bunkerings",
                    index: "Bunkering",
                    add: "Add Bunkering"
                },
                viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                structure: {
                    oilType: {
                        type: "segmentButtons",
                        label: "",
                        canRenderLabel: false,
                        options: [
                            {value: 'fOil', label: 'Fuel Oil', iconComponent: fOil},
                            {value: 'lOil', label: 'Lube Oil', iconComponent: lOil}
                        ],
                        onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                            if (value !== '') {
                                setFieldValue(`${parentName}.oil`, '');
                                setFieldValue(`${parentName}.supplied`, '');
                                setFieldValue(`${parentName}.rob`, '');
                                setFieldValue(`${parentName}.viscosity`, '');
                                setFieldValue(`${parentName}.density`, '');
                                setFieldValue(`${parentName}.sulfur`, '');
                                setFieldValue(`${parentName}.price`, '');
                                setFieldTouched(`${parentName}.oil`, false);
                                setFieldTouched(`${parentName}.supplied`, false);
                                setFieldTouched(`${parentName}.rob`, false);
                                setFieldTouched(`${parentName}.viscosity`, false);
                                setFieldTouched(`${parentName}.density`, false);
                                setFieldTouched(`${parentName}.sulfur`, false);
                                setFieldTouched(`${parentName}.price`, false);
                            }
                        },
                        value: '',
                        viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                        validation: {required: true},
                        cssClasses: {
                            wrapper: 'marg-t-15 bunkeringSegmentedButtons',
                            error: 'input-error-message marg-t-5'
                        }
                    },
                    oil: {
                        type: "select",
                        label: "Type of Oil",
                        options: [
                            {
                                oilType: ['fOil'],
                                options: [
                                    {value: 'hsfo', label: 'HSHFO'},
                                    {value: 'lsfo', label: 'LSHFO'},
                                    {value: 'hsgo', label: 'HS-MGO'},
                                    {value: 'lsmgo', label: 'LS-MGO'}
                                ]
                            },
                            {
                                oilType: ['lOil'],
                                options: [
                                    {value: 'hbn co', label: 'HS Cyl. Oil'},
                                    {value: 'lslbn co', label: 'LS Cyl. Oil'},
                                    {value: 'm/e', label: 'M/E Sys. Oil'},
                                    {value: 'g/e', label: 'D/G Lub. Oil'}
                                ]
                            }
                        ],
                        value: '',
                        viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                        validation: {
                            uniqueOfType: 'oil',
                            required: true,
                            disabled: {
                                oilType: []
                            }
                        }
                    },

                    supplied: {
                        type: "positiveNumber",
                        label: "Supplied",
                        value: '',
                        viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                        validation: {
                            required: true
                        },
                        unit: 'MT'
                    },
                    rob: {
                        type: "positiveNumber",
                        label: "ROB",
                        value: '',
                        viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                        validation: {
                            required: true
                        },
                        unit: 'MT'
                    },
                    price: {
                        type: "positiveNumber",
                        label: "Price",
                        value: '',
                        viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                        validation: {
                            required: true
                        },
                        unit: '$'
                    },
                    sulfur: {
                        type: "positiveNumber",
                        label: "Sulfur",
                        value: '',
                        validation: {
                            required: true
                        },
                        viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                        showOn: {
                            oilType: ['fOil']
                        },
                        unit: "%"
                    },
                    viscosity: {
                        type: "positiveNumber",
                        label: "Viscosity",
                        value: '',
                        viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                        unit: "cSt"
                    },
                    density: {
                        type: "positiveNumber",
                        label: "Density",
                        value: '',
                        viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                        unit: "kg/m3"
                    },
                    
                }
            }
        },
        
    ];
};

const bunkeringValues = (props, editMode, dateClicked) => {
    return [
        {
            date: editMode ? moment.utc(props.dateFrom) : moment(dateClicked).set({'hour': 12}).utc(),
            port: props.port || '',
            person: props.person || '',
        },
        {
            bunkerings: !props.hasOwnProperty('bunkerings') || props.bunkerings.length === 0
                ? [baseFormUtils.createValuesFromSchema(bunkeringSchema(editMode)[1].bunkerings.structure)]
                : props.bunkerings.map(bunkering => baseFormUtils.createValues(bunkering, bunkeringSchema(editMode)[1].bunkerings.structure))
        }
    ]
};

const bunkeringGroups = [{}, {}];

export {bunkeringSchema, bunkeringValues, bunkeringGroups};