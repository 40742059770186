import service from 'common/js/service';

const reportsUtils = {
    // the updateFromTo function is used in all reports pages in order to perform 2 dispatches together
    updateFromTo: (dispatch, fromTo, fromToChanged) => {
        dispatch({type: 'reports/setFromTo', payload: fromTo});
        dispatch({type: 'reports/setFromToChanged', payload: !fromToChanged});
    },
    // the updateFromToAndUserLeg function is used in leg report page and performs 2 dispatches together
    updateFromToAndUserLeg: (dispatch, fromTo, setUserLeg) => {
        dispatch({type: 'reports/setFromTo', payload: fromTo});
        dispatch({type: 'reports/setUserLeg', payload: setUserLeg});
    },
    async getTelegramsConfig({vesselIds}) {
        return service.getTelegramsConfig({vesselIds: vesselIds.join(',')});
    },
    async getTelegramsMonthlyDates({ vesselIds, datetime }) {
        return service.getTelegramsMonthlyDates({ vesselIds: vesselIds.join(','), datetime });
    },
    async getTelegramsDailyDates({ vesselIds, datetime }) {
        return service.getTelegramsDailyDates({ vesselIds: vesselIds.join(','), datetime });
    }
}

export default reportsUtils;