import moment from 'moment';
import registerWidgetsStore from 'common/store/registerWidgetsStore';

const initialReportsState = {
    fromTo: { 
        from: moment().subtract(1, 'day').toDate(), 
        to: moment().toDate() 
    },
    fromToChanged: false,
    setUserLeg: false,
    isTelegramConfigured: false
}

// reports reducer
const reports = (state = initialReportsState, action) => {
    switch(action.type) {
        case 'reports/setFromTo': {
            // set the register widgets store with the received fromTo value
            registerWidgetsStore.setFromTo(action.payload);
            
            return {
                ...state,
                fromTo: action.payload
            };
        }
        case 'reports/setFromToChanged': {
            return {
                ...state,
                fromToChanged: action.payload
            };
        }
        case 'reports/setUserLeg': {
            return {
                ...state,
                setUserLeg: action.payload
            };
        }
        case 'reports/setIsTelegramConfigured': {
            return {
                ...state,
                isTelegramConfigured: action.payload
            };
        }
        default: 
            return state;
    }
}

export default reports;