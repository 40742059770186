import React, {useEffect, useState} from 'react';
import service from 'common/js/service';
import FuelConsumptionBarChart from "../../widgets/boilers/fuelBarChart.jsx";
import { fuelBarChartPayload, fuelBarChartPayload_light, updateFuelBarChart,
  setBoilersFuelBarTypes, boilersFuelBarUpdatePayload} from "widgets/boilers/fuelBarChart.js";
import { fuelLineChartPayload, fuelLineChartTotalPayload, fuelLineChartPayload_light, updateFuelLineChart,
  setBoilersFuelLineTypes, boilersFuelLineUpdatePayload } from 'widgets/boilers/fuelLineChart.js';
import FuelConsumptionLineChart from "widgets/boilers/fuelLineChart.jsx";
import { boilersDetailsChartPayload, updateBoilersDetailsChart, setBoilersDetailsTypes, boilersDetailsUpdatePayload, boilersDetailsLineChartData } from "widgets/boilers/boilersDetailsChart.js";
import SyncedChartWithTabNavigation from "../syncedChartWithTabNavigation/syncedChartWithTabNavigation.jsx";
import Layout from 'components/core/Layout';
import boStorage from "./localStorages/boStorage";
import {licensing} from "common/store/licensing";
import ReportsService from "common/js/reports";
import chartOptions from "components/charts/lineChart/react-lineChart";
import {setBoilersRunningTimeTypes, updateBoilersRunningTime} from "widgets/boilers/runningTime.js";
import RunningTime from "widgets/boilers/runningTime.jsx";
import ArrivalDepartureFooter from "components/footers/staticFooters/arrivalDepartureFooter.jsx";
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import {monitoringStore, vesselStore, monitoringUtils} from 'common/store/storeUtils';
import {useDispatch, useSelector} from 'react-redux';
import GlobalUtilities from 'Utilities/global.js';

let isMounted = false;

const Boilers = () => {
    // local vars
    const reportsService = new ReportsService();
    let reportsComponents = [];

    // state vars
    const [fuelBarChartJson, setFuelBarChartJson] = useState({});
    const [fuelLineChartJson, setFuelLineChartJson] = useState({});
    const [boilersDetailsChartJson, setBoilersDetailsChartJson] = useState({});
    const [boilersRunningTimeJson, setBoilersRunningTimeJson] = useState({});

    // store vars
    const dispatch = useDispatch();
    const monitoringStoreJSX = useSelector(state => state.monitoring);

    const [requestOnGoing, setRequestOnGoing] = useState({
        fuelBarChartJson: false,
        fuelLineChartJson: false,
        boilersDetailsChartJson: false,
        boilersRunningTimeJson: false
    });

    // async actions for some useEffects
    const detailedDataChangedAction = async () => {
      if (!isMounted) return;
      if (monitoringStore().detailedData && monitoringStore().detailedData.status) return updateBoilersComponent();
    }

    const resetLegViewChangedAction = async () => {
      if (!monitoringStore().resetLegView) return;
      if (!isMounted) return;
      dispatch({ type: 'monitoring/setResetLegView', payload: false });
      return updateBoilersComponent();
    }

    const reportsComponentsLight = [
        {
            widgetId: 'fuelBarChartJson',
            inDashboard: false,
            vesselIds: [vesselStore().selectedVessel],
            singleRequest: {value: false},
            payload: fuelBarChartPayload_light,
            type: 'basicChart',
        },
        {
            widgetId: 'fuelLineChartJson',
            inDashboard: false,
            vesselIds: [vesselStore().selectedVessel],
            singleRequest: {value: false},
            payload: fuelLineChartPayload_light,
            type: 'basicChart',
            specificDates: false
        },
    ]

    const reportsComponentsPremium = [
        {
            widgetId: 'fuelBarChartJson',
            inDashboard: false,
            vesselIds: [vesselStore().selectedVessel],
            singleRequest: {value: false},
            payload: fuelBarChartPayload,
            type: 'basicChart',
            prerequisite: {
                prerequisiteBuildsPayload: true,
                updatePayloadCallback: boilersFuelBarUpdatePayload,
                value: true, request: () => {
                    return {method: service.getBoilerTypes, params: vesselStore().selectedVessel}
                }, callback: setBoilersFuelBarTypes
            },
        },
        {
            widgetId: 'fuelLineChartJson',
            inDashboard: false,
            vesselIds: [vesselStore().selectedVessel],
            singleRequest: {value: false},
            payload: fuelLineChartPayload,
            type: 'basicChart',
            prerequisite: {
                prerequisiteBuildsPayload: true,
                updatePayloadCallback: boilersFuelLineUpdatePayload,
                value: true, request: () => {
                    return {method: service.getBoilerTypes, params: vesselStore().selectedVessel}
                }, callback: setBoilersFuelLineTypes
            },
        },
        {
            widgetId: 'boilersDetailsChartJson',
            inDashboard: false,
            vesselIds: [vesselStore().selectedVessel],
            singleRequest: {value: false},
            payload: boilersDetailsChartPayload,
            type: 'basicChart',
            prerequisite: {
                prerequisiteBuildsPayload: true,
                updatePayloadCallback: boilersDetailsUpdatePayload,
                value: true, request: () => {
                    return {method: service.getBoilerTypes, params: vesselStore().selectedVessel}
                }, callback: setBoilersDetailsTypes
            },
        },
        {
            widgetId: 'boilersRunningTimeJson',
            notInDashboard: true,
            vesselIds: [vesselStore().selectedVessel],
            prerequisite: {
                value: true, request: () => {
                    return {method: service.getBoilerTypes, params: vesselStore().selectedVessel}
                }, callback: setBoilersRunningTimeTypes
            },
            singleRequest: {
                value: true, externalURequest: {
                    value: true, request: () => {
                        return {
                            method: service.getBoilersRunningTime, params:
                                {vesselId: vesselStore().selectedVessel, from: monitoringStore().fromTo.from, to: monitoringStore().fromTo.to}
                        }
                    }
                }
            },
            payload: '',
            type: 'text'
        }
    ];

    const updateRequestOnGoing = (key, value) => {
        setRequestOnGoing((currentRequestOnGoing) => {return {...currentRequestOnGoing, [key]: value}});
    }

    const setReportsPlotLineGuides = (data) => {
        let plotObject = { xAxis: {} };
        plotObject.xAxis = Object.assign({}, { plotBands: chartOptions().xAxis.plotBands }, { plotLines: chartOptions().xAxis.plotLines });

        const line = data[0]?.values.find((obj) => obj.value.length > 0) ? data[0].values.find((obj) => obj.value.length > 0).value : null;

        if (line && !line.length > 0) return;
        plotObject.xAxis.plotBands.from = plotObject.xAxis.plotLines[0].value = monitoringStore().detailedData.status
            ? monitoringStore().detailedData.guidesStart
            : null
        plotObject.xAxis.plotBands.to = plotObject.xAxis.plotLines[1].value = monitoringStore().detailedData.status
            ? monitoringStore().detailedData.guidesEnd
            : null;

        return plotObject;
    }

    const extraChartsConfigMapper = widgetId => {
      if(!monitoringStore().detailedData.status) return null;

      const mapper = {
        fuelBarChartJson: null,
        fuelLineChartJson: {
          plotLines: { setReportsPlotLineGuides }
        },
        boilersDetailsChartJson: {
          plotLines: { setReportsPlotLineGuides }
        },
        boilersRunningTimeJson: null,
      }

      return mapper[widgetId];
    }

    const stateMapper = (key, value) => {
      const mapper = {
          fuelBarChartJson: setFuelBarChartJson,
          fuelLineChartJson: setFuelLineChartJson,
          boilersDetailsChartJson: setBoilersDetailsChartJson,
          boilersRunningTimeJson: setBoilersRunningTimeJson,
      }
      return mapper[key](value);
    }

    const reportsResponseMapper = (data, widgetId) => {
      const mapper = {
        fuelBarChartJson: updateFuelBarChart,
        fuelLineChartJson: updateFuelLineChart,
        boilersDetailsChartJson: updateBoilersDetailsChart,
        boilersRunningTimeJson: updateBoilersRunningTime,
      }

      return mapper[widgetId](widgetId, data, stateMapper, extraChartsConfigMapper(widgetId), reportsComponents.filter(report => report.widgetId === widgetId)[0]);
    }

    const cachedResponsesMapper = (widgetId) => {
      const responseMapper = {
          fuelBarChartJson: boStorage.fuelBarChartData,
          fuelLineChartJson: boStorage.fuelLineChartData,
          boilersDetailsChartJson: boStorage.boDetailsChartData,
          boilersRunningTimeJson: boStorage.boilersRunningTimeData,
      }
      return responseMapper[widgetId];
    }

    const updateBoilersComponent = () => {
      getBoilersData();
    }

    const getBoilersData = () => {
        const from = monitoringStore().detailedData.status ? monitoringStore().detailedData.from : monitoringStore().fromTo.from;
        const to = monitoringStore().detailedData.status ? monitoringStore().detailedData.to : monitoringStore().fromTo.to;

        registerWidgetsStore.setFromTo({from, to});

        //  in detailed view, change the timeGroup to minutes (for fuel line chart and load history line chart)
        if (!licensing.lightCondition()) {
            fuelLineChartTotalPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
            fuelLineChartPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
            boilersDetailsChartPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
        }

        // Light View report component
        reportsComponents = licensing.lightCondition() ? reportsComponentsLight : reportsComponentsPremium;

        // if all the widgets have already data from before, then do not execute the widget requests again
        if(!boStorage.isGlobalStateEmpty()) {
          reportsComponents.map(comp => {
            return reportsResponseMapper(cachedResponsesMapper(comp.widgetId), comp.widgetId)
          })
          return;
        }

        reportsService.registerReports([vesselStore().selectedVessel], reportsComponents, reportsResponseMapper, updateRequestOnGoing, true);
    }

    const getWidgetsPreferences = () => {
        return {
          className: 'padding-left-right-7',
          layout: [
            { columns: [
                { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 4 },
                  components: [
                    { id: 'fuelBarChartJson', component: FuelConsumptionBarChart, noBoilersConfigSvg: 'boilers_fcr_bar_Icon',
                    title: 'BOILERS DAILY FUEL CONSUMPTION', data: {}, style: {height: '610px'}, props: {} },
                  ]
                },
                { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 8 },
                  components: [
                    { id: 'fuelLineChartJson', component: FuelConsumptionLineChart, footer: <ArrivalDepartureFooter/>, noBoilersConfigSvg: 'boilers_fcr_line_Icon',
                    title: 'BOILERS FUEL CONSUMPTION RATE', data: {}, style: {height: '610px'}, props: {} },
                  ]
                },
                { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 8 },
                  components: [
                    { id: 'boilersDetailsChartJson', component: SyncedChartWithTabNavigation, noBoilersConfigSvg: 'boilers_details_Icon',
                    title: 'BOILERS\' DETAILS', data: {}, style: {height: '1030px'}, props: {removeFromLayout: licensing.lightCondition()} },
                  ]
                },
                { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 4 },
                  components: [
                    { id: 'boilersRunningTimeJson', component: RunningTime, noBoilersConfigSvg: 'boilers_running_hours_Icon',
                    title: 'BOILERS\' RUNNING TIME ', data: {}, style: {height: '1030px'}, props: {removeFromLayout: licensing.lightCondition()} },
                  ]
                },
            ]}
          ]
        };
    };

    // useEffects
    useEffect(() => {
      if (!monitoringStore().fromToChanged) return;
      if (!isMounted) return;

      //reset fromToChanged redux var in order to trigger the next fromTo dates
      dispatch({ type: 'monitoring/setFromToChanged', payload: false });

      //invalid period if more than 3 months (91 days)
      if(monitoringStore().fromTo.to - monitoringStore().fromTo.from > 7862400000) return;

      // local storage needs to be initialized, since requests need to be made again for detailedData mode
      monitoringUtils.resetLocalMonitoringStores();

      updateBoilersComponent();
      // eslint-disable-next-line
    }, [monitoringStoreJSX.fromToChanged]);

    useEffect(() => {
      // local storage needs to be initialized, since requests need to be made again for detailedData mode
      monitoringUtils.resetLocalMonitoringStores();

      detailedDataChangedAction();
      // eslint-disable-next-line
    }, [monitoringStoreJSX.detailedData]);

    useEffect(() => {
      // local storage needs to be initialized, since requests need to be made again for detailedData mode
      monitoringUtils.resetLocalMonitoringStores();
      
      resetLegViewChangedAction();
      // eslint-disable-next-line
    }, [monitoringStoreJSX.resetLegView]);

    useEffect(() => {
      isMounted = true;
      const controller = new AbortController();
      GlobalUtilities.signal = controller.signal;

      if (!monitoringStore().fromTo.from && !monitoringStore().fromTo.to) return;
      
      updateBoilersComponent();
      return () => {
        controller.abort();
        isMounted = false;
        GlobalUtilities.signal = null;
      };
      // eslint-disable-next-line
    }, []);

    const createLayoutProps = (data, props = getWidgetsPreferences()) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
                    if (('id' in newComponent) && !('element' in newComponent)) {
                        if (newComponent.id in data) newComponent.props = data[newComponent.id];
                    }
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    };

    const data = {
        fuelBarChartJson: {
            data: fuelBarChartJson,
            loading: requestOnGoing.fuelBarChartJson
        },
        fuelLineChartJson: {
          data: {
            ...fuelLineChartJson,
            exporting: {...fuelLineChartJson.exporting, filename: 'BOILERS FUEL CONSUMPTION RATE'}
          },
          loading: requestOnGoing.fuelLineChartJson
        },
        boilersDetailsChartJson: {
          data: {...boilersDetailsChartJson},
          chartSeries: boilersDetailsLineChartData.chartSeries,
          navigationMapper: boilersDetailsLineChartData.navigationMapper,
          chartAxes: boilersDetailsLineChartData.chartAxes,
          multiplePlotLines: boilersDetailsLineChartData.multiplePlotLines,
          chartHeight: 930,
          className: "boilersDetailsChartJson",
          tabs: boilersDetailsLineChartData.boilersDetailsLineChartTabs,
          plotLinesSetter: setReportsPlotLineGuides,
          exportingChartTitle: "BOILER'S DETAILS",
          divideBySubId: true,
          tabsWithoutNumbers: true,
          loading: requestOnGoing.boilersDetailsChartJson,
          // title: {
          //   text: 'Boiler Status',
          //   align: 'center',
          //   y: 5,
          //   style: {
          //     color: '#687685',
          //     fontSize: '11px'
          //   }
          // },
        },
        boilersRunningTimeJson: {
            data: {...boilersRunningTimeJson},
            loading: requestOnGoing.boilersRunningTimeJson
        }
    }

    return (
      <div className="generator-engine-page">
        <div className="generator-engine-page__widgets">
          <Layout {...createLayoutProps(data)} />
        </div>
      </div>
    );
}

export default Boilers;
