import React from 'react'; 

//ToDo fix functionality for devider with props.enabledDevider
const ValuesTitlesIconsInline = props => {
    return (
        <div className="d-flex justify-content-around align-items-center main-content-padding full-height full-width">
            {props.data?.dataGroups?.filter(group => group).map((group, index) => (
                <div key={index} className="d-flex justify-content-center align-items-center full-height full-width">
                    <group.icon className="svg-path marg-r-10 flex-noShrink" />
                    <div>
                        <div className="info-value" style={group?.style}>
                            {group.value}
                        </div>
                        <div className="label" >
                            {group.label}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
 
export default ValuesTitlesIconsInline;