import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import NoData from 'components/noData/noData';
import NoDataReportsConfiguration from 'components/noDataReportsConfiguration/noDataReportsConfiguration';
import {licensing} from "common/store/licensing";

const WidgetPanel = (props) => {
    const checkIfNoData = () => {
        return (((props.compProps.options && Object.keys(props.compProps.options)?.length === 0) || (props.compProps.data && (Object.keys(props.compProps.data).length === 0 || (Object.keys(props.compProps.data).length === 1 && props.compProps.data.exporting))) || (props.compProps.items && props.compProps.items.length === 0))
        || (props.compProps.chartData && Object.keys(props.compProps.chartData).length === 0 ) || Object.keys(props.compProps).length === 0 )
    }

    const checkIfNoBoilersConfig = () => {
        if(props.compProps?.data?.noBoilersConfig) return true;
        return false;
    }

    return (
        <div className="widget-panel">
            {props.title !== "" && <div className="widget-panel__header">
                {(props.loading) ? <Skeleton animation="wave" width={'40%'} height={'60%'} /> : props.title}
            </div> }
            <div className={`widget-panel__body ${props.bodyClass||''} ${(licensing.lightCondition() && props.lightSvg) ? 'flex-centered' : ''}`} style={props.compStyle}>
                {props.loading
                    ? <div className="skeleton-absolute full-width full-height">
                        <Skeleton variant="rect"
                                  animation="wave"
                                  width={"100%"}
                                  height={props.compStyle.height === "auto" ? 250 : props.compStyle.height}
                        />
                    </div>
                    : null
                }
                {
                    (licensing.lightCondition() && props.lightSvg) ? <div style={{width: '100%', height: props.compStyle.height, backgroundRepeat:'no-repeat', backgroundPosition: 'center center', backgroundImage: `var(--${props.lightSvg})`}} />
                    : (licensing.lightCondition() && props.lightComp) ? <props.lightComp />
                    : checkIfNoBoilersConfig() ? <div style={{width: '100%', height: props.compStyle.height, backgroundRepeat:'no-repeat', backgroundPosition: 'center center', backgroundImage: `var(--${props.noBoilersConfigSvg})`}} />
                    : checkIfNoData()
                        ? props?.compProps?.hasNotTelegramConfiguration
                            ? <NoDataReportsConfiguration height={props.compStyle.height} minHeight={props?.compStyle?.minHeight}/>
                            : <NoData height={props.compStyle.height} minHeight={props?.compStyle?.minHeight} text='No data to display at the moment' />
                        : <props.component {...props.compProps} style={props.compStyle} />
                }
                {props.footer && props.footer !== "" && !checkIfNoData() && <div className="widget-panel__footer">
                    {(props.loading) ? <Skeleton animation="wave" width={'40%'} height={'60%'} /> : props.footer}
                </div> }
            </div>
        </div>
    );
}
 
export default WidgetPanel;
