import userUtilities from './StoreUtilities/userUtils';
import termsUtilities from './StoreUtilities/termsUtils';
import reportsUtilities from './StoreUtilities/reportsUtils';
import monitoringUtilities from './StoreUtilities/monitoringUtils';
import vesselGroupUtilities from './StoreUtilities/vesselGroupUtils';
import vesselUtilities from './StoreUtilities/vesselUtils';
import dashboardUtilities from './StoreUtilities/dashboardUtils';
import alertsUtilities from './StoreUtilities/alertsUtils';
import eventsUtilities from './StoreUtilities/eventsUtils';
import calendarUtilities from './StoreUtilities/calendarUtils';
import baseFormUtilities from './StoreUtilities/baseformUtils';
import store from 'common/store/store';

// utilities functions for each redux slice
export const userUtils = userUtilities;
export const termsUtils = termsUtilities;
export const reportsUtils = reportsUtilities;
export const monitoringUtils = monitoringUtilities;
export const vesselGroupUtils = vesselGroupUtilities;
export const vesselUtils = vesselUtilities;
export const dashboardUtils = dashboardUtilities;
export const alertsUtils = alertsUtilities;
export const eventsUtils = eventsUtilities;
export const calendarUtils = calendarUtilities;
export const baseFormUtils = baseFormUtilities;

// redux stores to use in JS code (and not JSX)
export const userStore = () => store.getState().user;
export const termsStore = () => store.getState().terms;
export const reportsStore = () => store.getState().reports;
export const monitoringStore = () => store.getState().monitoring;
export const vesselGroupStore = () => store.getState().vesselGroup;
export const vesselStore = () => store.getState().vessel;
export const notificationsStore = () => store.getState().notifications;
export const dashboardStore = () => store.getState().dashboard;
export const alertsStore = () => store.getState().alerts;
export const eventsStore = () => store.getState().events;
export const calendarStore = () => store.getState().calendar;