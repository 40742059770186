import { licensing } from "common/store/licensing";
import { vesselStore, vesselGroupStore } from 'common/store/storeUtils';

const vesselUtils = {
    fleetVesselsIds: () => {
        const fleetVessels = vesselStore().allVessels.filter(vessel => vessel.vesselGroupId === vesselGroupStore().selectedVesselGroup);
        return fleetVessels.map(vessel => vessel.vesselId);
    },
    getAllVesselIds: () => {
        return vesselStore().allVessels.map(el => el.vesselId);
    },
    getVesselsIdsFromVesselGroupId: (vesselGroupId) => {
        const fleetVessels = vesselStore().allVessels.filter(vessel => vessel.vesselGroupId === vesselGroupId);
        return fleetVessels.map(vessel => vessel.vesselId);
    },
    getSelectedVesselData: () => {
        return vesselStore().allVessels.filter(vessel => vessel.vesselId === vesselStore().selectedVessel)[0] || vesselStore().allVesselsObj;
    },
    getSelectedVesselObj: () => {
        if (vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) return;
        const fleetVessels = vesselStore().allVessels.filter(vessel => vessel.vesselGroupId === vesselGroupStore().selectedVesselGroup);
        return fleetVessels.filter(vessel => vessel.vesselId === vesselStore().selectedVessel)[0];
    },
    getFleetVessels: (dispatch) => {
        const fleetVessels = vesselStore().allVessels.filter(vessel => vessel.vesselGroupId === vesselGroupStore().selectedVesselGroup);
        if(dispatch) dispatch({ type: 'vessel/setFleetVessels', payload: fleetVessels});
        return fleetVessels;
    },
    getObjOfAVessel: vesselId => {
        return vesselStore().allVessels.filter(vessel => vessel.vesselId === vesselId)[0] || -1;
    },
    buildVesselsObject: r => {
        const tmpArr = [];

        r.companies.forEach(company => {
            company.vesselGroups.forEach(vesselGroup => {
                // add vessel_group id in each vessel
                vesselGroup.vessels.forEach(vessel => {
                    vessel.vesselGroupId = vesselGroup.id;
                    vessel.isPremium = !licensing.lightCondition(vessel.licenses);
                    tmpArr.push(vessel);
                });
            });
        });

        return tmpArr;
    },
    initialize: (dispatch, vessels) => {
        dispatch({ type: 'vessel/setAllVessels', payload: vessels});
        vesselUtils.getFleetVessels(dispatch);
    },
    getVesselName: id => {
        if (!id) return;
        return vesselStore().allVessels.filter(v => v.vesselId === id)[0].name;
    },
    getVesselIndex: vesselId => {
        const foundVesselIndex = vesselStore().allVessels.findIndex(vessel => vessel.vesselId === vesselId);
        if(foundVesselIndex > -1) return foundVesselIndex > 9 ? foundVesselIndex % 10 : foundVesselIndex; // logic for returning the right index with values 0-9 even when the vessel index exceeds 9
    }
}

export default vesselUtils;