import React from 'react';
import {licensing} from "common/store/licensing";
import ThemeColors from "sass/_themeColorsForJs.scss";

const FuelConsumpTotals = props => {
    const valueColor = () => {
        if(licensing.lightCondition()) return {color: ThemeColors['reports_color']};
        return {color: ThemeColors['main_blue']};
    }

    return (
        <React.Fragment>
            {props.data.map((item) => (
                <div className="d-flex justify-content-center fuelConsump__box align-items-center" key={`${item.id}-${item.value}`}>
                    <item.icon className="svg-path" />
                    <div className="fuelConsump__box__total d-flex justify-content-center flex-column">
                        <div className={`fuelConsump__box__total__value ${item.id}-value`} style={valueColor()}>{item.value} MT</div>
                        <div className="fuelConsump__box__total__label">{item.label}</div>
                    </div>
                </div>
            ))}
        </React.Fragment>
    )
}

export default FuelConsumpTotals;

