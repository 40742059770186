import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classnames from "classnames";


const IconSegmentButtons = props => {
    const inputsClasses = (value, fieldValue) => {
        return classnames("icon-segment-buttons__group", { "active": value === fieldValue });
    }

    const iconClasses = value => {
        return classnames({ [value]: value })
    }

    const disabledClasses = disabled => {
        return classnames({ "disabled": disabled });
    }

    const errorClasses = boxError => {
        return classnames({ "boxError": boxError && !props.value });
    }

    return (
        <Field name={props.name}>
            {({ field }) => (
                <React.Fragment>
                    <div className="flex-centered-col-start">
                        {props.label}
                        <div className="marg-b-15">{props.renderError()}</div>
                    </div>
                    <div {...props} className={`icon-segment-buttons flex-centered-start${props.disabled ? ' disabled' : ''}`}>
                        {props.options.map(button => (
                            <div key={button.value} className={`${inputsClasses(button.value, field.value)} ${disabledClasses(button.disabled)} ${errorClasses(props.boxError)}`}
                                onClick={e => { !props.disabled && !button.disabled && props.onChange({ target: { value: button.value } }) }}
                            >
                                <div className={`icon-segment-buttons__group__icon ${iconClasses(button.classname)}`}>
                                    <button.iconComponent />
                                </div>
                                <div className="icon-segment-buttons__group__label flex-centered headline4">{button.label}</div>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            )}
        </Field>
    );
};


IconSegmentButtons.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    renderError: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool
};

IconSegmentButtons.defaultProps = {
    className: '',
    disabled: false
};

export default IconSegmentButtons;