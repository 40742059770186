import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const getExtraClasses = (extraClasses) => {
    return {
        wrapperClasses: classnames('iconWithTextLabel', {[extraClasses?.wrapperClasses]: extraClasses?.wrapperClasses}),
        textClasses: classnames('iconWithTextLabel__text', {[extraClasses?.textClasses]: extraClasses?.textClasses})
    }
}

const IconWithTextLabel = ({icon, text, extraClasses}) => {
    return (
        <div className={getExtraClasses(extraClasses).wrapperClasses}> 
            {icon}
            <div className={getExtraClasses(extraClasses).textClasses}>
                {text}
            </div>
        </div>
    );
}

IconWithTextLabel.propTypes = {
    icon: PropTypes.element,
    text: PropTypes.string,
    extraClasses: PropTypes.shape({})
}
 
export default IconWithTextLabel;