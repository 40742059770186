import React from 'react';
import moment from 'moment';

const WidgetWithTimePeriod = (props) => {
    return <div className="widgetWithTimePeriod">
        <div className="widgetWithTimePeriod__timePeriod" style={props?.timePeriodExtraStyle}>
            {`${moment(props?.timePeriod?.from).utc().format('DD/MM/YYYY HH:mm')} - ${moment(props?.timePeriod?.to).utc().format('DD/MM/YYYY HH:mm')}`}
        </div>
        {props.component}
    </div>
}

export default WidgetWithTimePeriod;