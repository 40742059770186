const Group = {
    viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
    className: 'cols-padding',
    groupWrapper: 'general-settings',
    groupLabel: 'headline marg-b-10',
    paddingClassName: '',
    paddingViewports: {xs: 12, sm: 12, md: 10, lg: 8, xl: 7},
    renderLabel: true,
    ableToCollapse: false,
    hasAsterisk: false,
    isCollapsed: false
}

export default Group;