import highstockBarChartOptions from "components/charts/barChart/highstock-barChart";
import meStorage from 'components/monitoringCategories/localStorages/meStorage';
import moment from 'moment';
import {licensing} from "../../common/store/licensing";
import { ThemeColorsForCharts } from "Utilities/themeColorsForCharts";
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import { monitoringStore, vesselUtils } from 'common/store/storeUtils';
import GlobalUtilities from "Utilities/global";
import { downloadChartInCSVform, downloadChartInXLSXform } from "Utilities/highchartsUtils";

const FuelBarChartChartAxis = [{
    id: 'foBar-axis',
    title: {text: 'Fuel Consumption (mt/day)'},
    yMin: 0,
    min: 0,
    opposite: false
}];

const FuelBarChartChartData = [
    {
        name: 'Sensor FC',
        centerInCategory: true,
        legendTitle: 'Sensor FC',
        data: [],
        tooltipTitle: 'FC',
        yAxis: 'foBar-axis',
        min: 0,
        showInNavigator: true,
        showInLegend: true,
        metadata: { tooltipBackgroundColor: "#3d7af8", cssRule: 'background-color:' },
        tooltip: {valueSuffix: ' mt/day', valueDecimals: 2}, type: 'column', yMin: 0
    }, {
        name: 'Sensor Missing Data',
        legendTitle: 'Sensor Missing Data',
        data: [],
        tooltipTitle: 'FC',
        yAxis: 'foBar-axis',
        min: 0,
        metadata: { cssRule: 'background-color:', tooltipBackgroundColor: "#204175", title:'missing data', suffix: '%' },
        type: 'column',
        yMin: 0,
        showInNavigator: false,
        centerInCategory: true,
        tooltip: {valueSuffix: ' mt/day', valueDecimals: 2, padding: 0, className: 'custom-tooltip' },
        showInLegend: false,
    }
];

const FuelBarChartChartData_light = [{
    name: 'Report FC',
    centerInCategory: true,
    legendTitle: 'Report FC',
    data: [],
    tooltipTitle: 'FC',
    yAxis: 'foBar-axis',
    min: 0,
    metadata: { tooltipBackgroundColor: "#3d7af8", cssRule: 'background-color:' },
    tooltip: {valueSuffix: ' mt/day', valueDecimals: 2},
    type: 'column',
    yMin: 0
}];

const FuelBarChartPayload = {
    xAxis: "TIME",
    aggregation: "SUM",
    timeGroup: 'DAY',  
    withMetadata: true,
    metrics:[
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {
                    metricName: "foVolConsumption"
                }
            ]
        }
    ]
};

const FuelBarChartPayload_light = {
    xAxis: "TIME",
    aggregation: "SUM",
    timeGroup: 'DAY',
    withMetadata: true,
    metrics:[
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "me_1_foConsumption_calc"
                }
            ]
        }
    ]
};

const updateFuelBarChart = (id, data, updateState, extraChartConfigs, widget) => {
    meStorage.setFuelBarData(data);

    let tooltipHeader = '{point.reportPeriod}';
    if (widget?.inDashboard) tooltipHeader = '{point.point.reportPeriod}';
    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
    const isDetailedGranularity = monitoringStore().lessThanADayPeriod.status || monitoringStore().detailedData.status
    let fuelBarChartJson = { ...highstockBarChartOptions(":%d/%m", null, isLight ? tooltipHeader : false, isDetailedGranularity) };

    const {from, to} = GlobalUtilities.getDetailedGranularityPeriod();

    fuelBarChartJson.yAxis = [...FuelBarChartChartAxis];
    fuelBarChartJson.series = isLight ? JSON.parse(JSON.stringify(FuelBarChartChartData_light)) : JSON.parse(JSON.stringify(FuelBarChartChartData));
    fuelBarChartJson.chart = {...fuelBarChartJson.chart, height: 469,};     
    fuelBarChartJson.plotOptions.column.pointWidth = 29;

    fuelBarChartJson.tooltip =   {...fuelBarChartJson.tooltip, split: false, xDateFormat: '%d/%m'}

    fuelBarChartJson.exporting.filename = `${vesselUtils.getVesselName(widget.vesselIds[0])}-MAIN ENGINE FUEL CONSUMPTION`;
    
    fuelBarChartJson.exporting.buttons.contextButton= {
        menuItems: [
            "download",
            "customSeparator",
            {
                text: "XLSX",
                onclick: function () {
                    let name = `${vesselUtils.getVesselName(widget.vesselIds[0])}-MAIN ENGINE FUEL CONSUMPTION`
                    name = name.replace(/\./g, "");
                    let sheetsName   = name;
                    
                    if (sheetsName.length > 30) {
                        sheetsName = "MAIN ENGINE FUEL CONSUMPTION"
                    }
                    let headers = this.getDataRows(true)[0].map((row) => row.replace(/\(.*?\)/, "").trim())
                    const rows = this.getDataRows(true);
                    const newRows = rows.map((row) => {
                        return [
                            moment.utc(row[0]).format('DD-MM-YYYY HH:mm:ss'),
                            row[1] ? row[1] : row[2],  
                        ];
                    });       

                    const numberOfColumns = isLight ? headers.length : headers.length - 1

                    headers = headers.slice(0, numberOfColumns).map((val) => val === "Days" ? "Date" : `${val} (mt/day)`);

                    let updatedows = [headers, ...newRows.slice(1, rows.length)];            
               
                    downloadChartInXLSXform({xlsxRows: updatedows, sheetsName: sheetsName, name: name});
                }
            },
            {
                text: "CSV",
                onclick: function () {
                    let name = `${vesselUtils.getVesselName(widget.vesselIds[0])}-MAIN ENGINE FUEL CONSUMPTION`
                    name = name.replace(/\./g, "");
                    let headers = this.getDataRows(true)[0]
                    const rows = this.getDataRows(true);
                    
                    const newRows = rows.map((row) => {
                        return [
                            moment.utc(row[0]).format('DD-MM-YYYY HH:mm:ss'),
                            row[1] ? row[1] : row[2],  
                        ];
                    });
                    
                    const numberOfColumns = isLight ? headers.length : headers.length - 1

                    headers = headers.slice(0, numberOfColumns).map((val) => val === "Days" ? "Date" : `${val} (mt/day)`);

                    let updatedows = [headers, ...newRows.slice(1, rows.length)];            
                                    
                    const csvContent = updatedows.map(row => row.join(',')).join('\n');

                    downloadChartInCSVform({csvContent, name: name});

                }
            },
            "downloadPDF", "downloadPNG", "downloadJPEG", "downloadSVG",
        ]
    }
    const labelsFormat = (from && to) ? 'DD/MM HH:mm' : 'DD/MM';

    fuelBarChartJson.xAxis =  {
        ...fuelBarChartJson.xAxis,
        labels:   {
            align: 'center',
            step: 1,
            formatter : function() {
                return `${moment.utc(this.value).format(labelsFormat)}`
            }
        }
    }

    if(fuelBarChartJson.xAxis.title.text) fuelBarChartJson.xAxis.title.text = 'Days';
    fuelBarChartJson.legend = {
        enabled: true,
        align: 'center',
        layout: 'horizontal',
        verticalAlign: 'top',
        itemMarginTop: 20,
        itemMarginBottom: 25,
        itemDistance: 57,
        itemStyle: {
            "fontSize": "11px",
            "fontWeight": "600"
        }
    };

    // Missing Data Configuration - On light vessels, do not display missing data
    if(!isLight) {
        fuelBarChartJson.series[1].color = ThemeColorsForCharts()['missing_first_stack_sensor'];
        fuelBarChartJson.series[1].data = data[0] ? data[0].values[0].missingData.map(point => point = {
            x: point.x,
            y: point.y / 1000,
            missingValues: point.missingValues.toFixed(1)
        }) : [];
    }

    // Sensor/Telegram Data Configuration - On light vessels, change colors
    fuelBarChartJson.series[0].color = isLight ? ThemeColorsForCharts()['reports_color'] : ThemeColorsForCharts()['sensor_color'];
    fuelBarChartJson.series[0].data = data[0] ? data[0].values[0].value.map(point => 
        point = {
        x: point.x,
        y: isLight ? parseFloat(point.y) : point.y / 1000,
        reportPeriod: isLight ?
            moment(point?.period?.timestampFrom).utc().format('DD/MM HH:mm') + ' - ' + moment(point?.period?.timestampTo).utc().format('DD/MM HH:mm')
            : '',
        period: (from && to) && GlobalUtilities.periodString(data[0]?.values[0], point.x, point.y, {from, to})
    }) : [];

    fuelBarChartJson.chart.events = {
        load: function () {
            if (moment(registerWidgetsStore.fromTo.to).diff(moment(registerWidgetsStore.fromTo.from), 'days') > 7) {
                const chart = this,
                    series = [...this.series[0]?.xData, ...this.series[1]?.xData].sort(function(a, b){
                        return moment.utc(a).diff(moment.utc(b))
                    }),
                    xAxis = chart.xAxis[0],
                    newStart = series[series.length - 7],
                    newEnd = series[series.length - 1];

                // if the from-to difference is larger than 7 days, we get the whole datetime and configure zoom accordingly
                if (moment(registerWidgetsStore.fromTo.to).diff(moment(registerWidgetsStore.fromTo.from), 'days') > 7) xAxis.setExtremes(newStart, newEnd);
            }


            // For detailed data, display only the day's centered column
            if (moment(registerWidgetsStore.fromTo.to).diff(moment(registerWidgetsStore.fromTo.from), 'days') < 1 && !isLight) {
                fuelBarChartJson.series[0].centerInCategory = true;
                fuelBarChartJson.series[1].centerInCategory = true;
                if (fuelBarChartJson.series[0].data.length > 0) fuelBarChartJson.series[1].data = [];
            }
        }
    }

    const chartHasData = fuelBarChartJson.series.find(serie => serie?.data?.length > 0);
    if(!chartHasData) fuelBarChartJson = {};

    updateState(id, fuelBarChartJson, widget?.id);
}


export { FuelBarChartPayload, FuelBarChartPayload_light, updateFuelBarChart };
