import overviewStorage from "components/monitoringCategories/localStorages/overviewStorage";
import moment from "moment";
import EventsUtilities, {eventsLabels} from 'Utilities/events';
import { calendarUtils } from "common/store/storeUtils";

const columnDefs = [
    { headerName: "Date", field: "date", sortable: true,
        maxWidth: 160, minWidth: 140, cellStyle: {minWidth: '160px', maxWidth: '140px'}},
    { headerName: "Event Type", field: "type", sortable: true, cellRenderer: 'eventTypeGroup',
        maxWidth: 160, minWidth: 150, cellStyle: {minWidth: '150px', maxWidth: '160px'}},
    { headerName: "Description", field: "description", sortable: true, autoHeight: true,
        maxWidth: 800, minWidth: 310, cellStyle: {minWidth: '310px', maxWidth: '800px', paddingRight: '15px', autoHeight: true, whiteSpace: 'normal'}},
];

const vesselLog = (id, response, updateState, extraChartConfigs, widget) => {
    overviewStorage.setVesselLogData(response);

    if(!response) return updateState(id, [], widget?.id);
    
    const tableData = response.map(object => {
        const dateWithFormat = calendarUtils.belongsToTelegrams(object.type) ? moment(object.dateFrom).format('DD/MM/YYYY HH:mm') : moment(object.dateFrom).format('DD/MM/YYYY');

        return {
            date: moment(object.dateFrom).isValid() ? dateWithFormat : '-',
            type: eventsLabels[object.type],
            niceType: eventsLabels[object.type],
            description: EventsUtilities.getEventDecription(object),
            originalType: object.type,
            ...object
        };
    }).reverse();

    updateState(id, tableData, widget?.id);
}

export {vesselLog, columnDefs}








