import React from 'react';
import Modal from 'components/modals/eventsModal';
import { eventsUtils } from 'common/store/storeUtils';
import { useSelector, useDispatch } from 'react-redux';

const AddEventsButton = props => {
    // store vars
    const calendarStoreJSX = useSelector(state => state.calendar);
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <div className={props.classNameWrapper}>
                <button type="btn" className={props.classNameCta} onClick={props.action}>
                    {props.icon && <props.icon className="svg-path"/>}
                    <div className={props.classNameCtaText}>{props.label}</div>
                </button>
            </div>

            <Modal show={props.show}
                   onHide={
                       () => {
                            props.onHide();
                            dispatch({ type: 'calendar/setCalendarCurrentEvent', payload: {} })
                        }
                    }
                   editEvent={props.editEvent}
                   overviewPage={props.overviewPage}
                   telegramsOnly={props.telegramsOnly}
                   onSave={eventsUtils.eventSaveAction}
                   onDelete={eventsUtils.eventDeleteAction}
                   title={calendarStoreJSX.calendarCurrentEvent.modalTitle}
                   eventObj={calendarStoreJSX.calendarCurrentEvent.eventObj}
                   eventVessel={calendarStoreJSX.calendarCurrentEvent.modalEventVessel}
                   dateClicked={props.modalDate}
            />
        </React.Fragment>
    )
}

export default AddEventsButton;