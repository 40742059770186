import React, {useEffect, useState} from 'react';
import SessionStorage from 'common/js/Storage';
import Layout from 'components/core/Layout';
import BaseForm from 'components/core/Forms/BaseForm';
import ComponentWithIcon from 'components/componentWithIcon/componentWithIcon';
import { ReactComponent as UserIcon } from 'assets/img/app/settings-imgs/user.svg';
import { ReactComponent as PasswordReset } from 'assets/img/app/settings-imgs/changePass.svg';
import { ReactComponent as DarkThemeButton } from 'assets/img/app/settings-imgs/darktheme.svg';
import { ReactComponent as WhiteThemeButton } from 'assets/img/app/settings-imgs/whitetheme.svg';
import { ReactComponent as Personalization } from 'assets/img/app/settings-imgs/theme.svg';
import GridUtils from 'Utilities/gridUtils';
import {useDispatch, useSelector} from 'react-redux'; 
import {userStore, userUtils} from 'common/store/storeUtils';

let formState = {
    setFieldValue: () => {},
    value: '',
    field: ''
}

let oldPassError = false;

const Settings = () => {
    const [error, setError] = useState(false);
    
    // store vars for jsx
    const userStoreJSX = useSelector(state => state.user);
    const dispatch = useDispatch();

    const checkValidation = () => {
        return oldPassError;
    }

    const resetLocalValidation = () => {
        oldPassError = false;
    }

    const defaultProps = {
        separateValidationOnRequiredFields: true,
        cssClasses: {
            wrapper: '',
            label: 'form-text marg-b-5',
            element: 'ds-input full-width',
            error: 'input-error-message'
        }
    };

    const schema = {
        generalSchema: {
            firstName: {
                label: 'First Name',
                type: 'text',
                viewports: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
                validation: {
                    requiredField: true,
                    minLength: 3
                },
                value: userStore().user.firstName || '',
                ...defaultProps,
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => { handleChange('firstName', value, setFieldValue, setFieldTouched); }
            },
            lastName: {
                label: 'Last Name',
                type: 'text',
                viewports: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
                validation: {
                    requiredField: true,
                    minLength: 3
                },
                value: userStore().user.lastName || '',
                ...defaultProps,
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => { handleChange('lastName', value, setFieldValue, setFieldTouched); }
            },
            department: {
                label: 'Department',
                type: 'text',
                viewports: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
                validation: {
                    requiredField: true,
                },
                value: userStore().user.department || '',
                ...defaultProps,
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => { handleChange('department', value, setFieldValue, setFieldTouched); }
            },
            occupation: {
                label: 'Occupation',
                type: 'text',
                viewports: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
                value: userStore().user.occupation || '',
                ...defaultProps,
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => { handleChange('occupation', value, setFieldValue, setFieldTouched); }
            },
            telephone: {
                label: 'Phone',
                type: 'text',
                validation: {
                    regex: '^[+]?[0-9\\s]*$'
                },
                viewports: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
                value: userStore().user.telephone || '',
                ...defaultProps,
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => { handleChange('telephone', value, setFieldValue, setFieldTouched); }
            }
        },
        resetPasswordSchema: {
            oldPass: {
                label: 'Old Password',
                type: 'password',
                autoComplete: "new-password",
                validation: {
                    isSubmitting: [checkValidation, resetLocalValidation],
                    minLength: 4,
                    requiredFields: ['password', 'passConfirm'],
                },
                value: '',
                ...defaultProps,
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => { 
                    formState = {
                        field: 'oldPass',
                        value: value,
                        setFieldValue: setFieldValue
                    }
                }
            },
            password: {
                label: 'New Password',
                type: 'resetPassword',
                rules: [
                    {passRule: false, text: 'at least 8 characters', regex: '[A-Za-z0-9!@#$%^&*()-_+"|:"]{8,}'},
                    {passRule: false, text: '1 latin letter, only latin letters are accepted', regex: '[a-zA-Z]+', extraRegex: '^[A-Za-z!@%$#^&*()+-_]*$'},
                    {passRule: false, text: '1 number', regex: '\\d'},
                    {passRule: false, text: '1 symbol', regex: '[^A-Za-z0-9/]'}
                ],
                autoComplete: "new-password",
                validation: {
                    onlyLatinCharacters: true,
                    atLeastOneNumber: true,
                    atLeastEightCharacters: true,
                    atLeastOneSymbol: true,
                    noSlashes: true,
                    minLength: 4,
                    requiredFields: ['oldPass', 'passConfirm']
                },
                value: '',
                ...defaultProps,
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => { 
                    handleChange('password', value, setFieldValue, setFieldTouched); 
                    setFieldTouched('oldPass', true);
                }
            },
            passConfirm: {
                label: 'Confirm Password',
                type: 'password',
                autoComplete: "new-password",
                validation: {
                    equalTo: 'password',
                    requiredFields: ['password', 'oldPass']
                },
                value: '',
                ...defaultProps,
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => { 
                    handleChange('passConfirm', value, setFieldValue, setFieldTouched); 
                    setFieldTouched('oldPass', true);
                }
            },
        },
        themeSchema: {
            theme: {
                type: "iconSegmentButtons",
                value: !userStore().user.theme || userStore().user.theme,
                ...defaultProps,
                options: [
                    { value: 'dark', label: '', iconComponent: DarkThemeButton },
                    { value: 'ds-white', label: '', iconComponent: WhiteThemeButton }
                ],
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                    changeTheme(value);
                }
            }
        }
    };

    useEffect(() => {
        return () => {
            formState = {
                setFieldValue: () => {},
                value: '',
                field: ''
            }
        }
    }, []);

    const handleChange = (field, value, setFieldValue, setFieldTouched) => {
        if (value === null) {
            setFieldValue(field, "");
        }
    };

    const changeTheme = (value) => {
        const payload = { ...userStore().user, theme: value };
        payload.theme = payload.theme === 'dark' ? 'dark' : 'ds-white';        
 
        userUtils.changeTheme({ theme: value, defaultGroup: userStore().user?.defaultGroup }).then(
            result => {
                if (!result.error) {
                    const tempUser = {...userStore().user};
                    tempUser.theme = payload.theme;
                    (payload.theme === 'ds-white')
                        ? document.body.classList.add('ds-white')
                        : document.body.classList.remove('ds-white');
                        dispatch({ type: 'user/setUser', payload: tempUser });
                        SessionStorage.addCookie('user', tempUser);
                }
            }
        );
    };

    const changePass = (values) => {
        const payload = { oldPassword: values.oldPass, password1: values.password, password2: values.passConfirm, username: userStore().user?.username };

        setError(false);

        userUtils.changePassword(payload).then(result => {
            if(result.apiError?.status === 400) {
                oldPassError = true;
                formState.setFieldValue('oldPass', payload.oldPassword);
                if(payload?.oldPassword?.length === 0 && payload?.password1?.length === 0 && payload?.password2?.length === 0) setError(true);
            }
        });
    }

    const updateProfile = (values) => {
        const payload = { ...values, username: userStore().user?.username };

        userUtils.updateProfile(payload).then(
            result => {
                if (result.error) return;
                let user = {...userStore().user};
                user.firstName = result.data.firstName;
                user.lastName = result.data.lastName;
                user.department = result.data.department;
                user.occupation = result.data.occupation;
                user.telephone = result.data.telephone.trim();

                dispatch({ type: 'user/setUser', payload: user });
                SessionStorage.addCookie('user', user);
            }
        );
    };

    const getWidgetsPreferences = () => {
        return {
            className: 'padding-left-right-7 flex-centered-col',
            layout: [
                {
                    columns: [
                        {
                            grid: { xs: 12, sm: 12, md: 12, lg: 7, xl: 8 },
                            className: 'general-settings-widget single-widget',
                            components: [
                                { component: ComponentWithIcon, title: "PROFILE SETTINGS", id: 'profileSettings', style: { height: 'auto' }, props: {} }
                            ]
                        },
                        {
                            grid: { xs: 12, sm: 12, md: 12, lg: 7, xl: 8 },
                            className: 'password-reset-widget single-widget',
                            components: [
                                { component: ComponentWithIcon, title: "RESET PASSWORD", id: 'resetPassword', style: { height: 'auto' }, props: {} }
                            ]
                        },
                        {
                            grid: { xs: 12, sm: 12, md: 12, lg: 7, xl: 8 },
                            className: 'theme-options-widget single-widget',
                            components: [
                                { component: ComponentWithIcon, title: "THEME OPTIONS", id: 'personalization', style: { height: 'auto' }, props: {} }
                            ]
                        },
                    ]
                }
            ]
        };
    };

    const createLayoutProps = (data, props = getWidgetsPreferences()) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.map(newComponent => {
                    if (('id' in newComponent) && !('element' in newComponent)) {
                        if (newComponent.id in data) newComponent.props = data[newComponent.id];
                    }
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    };

    const submitButtonOptions = () => {        
        return {
            generalSchema: {
                buttonName: <div className='main-cta__text'>Update Profile</div>,
                buttonCssClass: 'main-cta',
                buttonWrapperCssClass: 'submitButtonsWrapper text-r',
                disabled: false
            },
            resetPasswordSchema: {
                buttonName: <div className='main-cta__text'>Change Password</div>,
                buttonCssClass: 'main-cta',
                buttonWrapperCssClass: 'submitButtonsWrapper',
                disabled: false
            },
            themeSchema: {
                buttonName: <div className='main-cta__text'>Change theme</div>,
                buttonCssClass: 'main-cta',
                buttonWrapperCssClass: 'd-none',
                disabled: false
            }
        }
    };

    const data = {
        profileSettings: {
            component: BaseForm,
            icon: UserIcon,
            componentGrid: { xs: 12, sm: 12, md: 8, lg: 8, xl: 8 },
            iconGrid: { xs: 'hidden', sm: 'hidden', md: 4, lg: 4, xl: 4 },
            iconComponentWrapper: 'flex-row-reverse',
            className: GridUtils.getHiddenClasses({ sm: 12, md: 12, lg: 12, xl: 12 }, `general-settings-widget__form`),
            extra: userStoreJSX.user.email,
            extraClasses: 'form-text text-right marg-t-15',
            /* start - BaseForm props */
            key: 'generalSchema',
            schema: schema['generalSchema'],
            submitButtonOptions:submitButtonOptions()['generalSchema'],
            onSubmit:updateProfile,
            /* end - BaseForm props */
        },
        resetPassword:{
            component: BaseForm,
            icon: PasswordReset,
            componentGrid: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            iconGrid: { xs: 'hidden', sm: 6, md: 6, lg: 6, xl: 6 },
            className: GridUtils.getHiddenClasses({ sm: 12, md: 12, lg: 12, xl: 12 }, `password-reset-widget__form`),
            /* start - BaseForm props */
            key: 'resetPasswordSchema',
            schema: schema['resetPasswordSchema'],
            submitButtonOptions:submitButtonOptions()['resetPasswordSchema'],
            onSubmit:changePass,
            error: error,
            /* end - BaseForm props */
        },
        personalization:{
            component: BaseForm,
            icon: Personalization,
            componentGrid: { xs: 12, sm: 12, md: 7, lg: 7, xl: 7 },
            iconGrid: { xs: 'hidden', sm: 'hidden', md: 5, lg: 5, xl: 5 },
            iconComponentWrapper: 'flex-centered-row-reverse',
            className: GridUtils.getHiddenClasses({ sm: 12, md: 12, lg: 12, xl: 12 }, `theme-options-widget__form`),
            /* start - BaseForm props */
            key: 'themeSchema',
            schema: schema['themeSchema'],
            submitButtonOptions:submitButtonOptions()['themeSchema'],
            onSubmit:changeTheme
            /* end - BaseForm props */
        }
    }

    return (
        <div className="settings main-content-padding">
            <div className="section-title marg-b-25">SETTINGS</div>
            <div className="settings__widgets">
                <Layout {...createLayoutProps(data)} />
            </div>
        </div>
    );
}

export default Settings;
