import Highstock from 'highcharts/highstock';
import Highcharts from 'highcharts';
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import {userStore} from 'common/store/storeUtils';
require("highcharts/modules/exporting")(Highstock);
require("highcharts/modules/export-data")(Highstock);
require("highcharts/modules/no-data-to-display")(Highstock);

const lineChartStockOptions = (decimals, zPointUnit) => {

    if(!decimals) decimals = '';
    if(!zPointUnit) zPointUnit = '';
    if (userStore().user && userStore().user.theme){
        (userStore()?.user?.theme !== 'ds-white') ? Highstock.setOptions(Highstock.darkTheme)
        : Highstock.setOptions(Highstock.whiteTheme);
        
        (userStore()?.user?.theme !== 'ds-white') ? Highcharts.setOptions(Highcharts.darkTheme)
        : Highcharts.setOptions(Highcharts.whiteTheme);
    }
        
    let options = {
        chart: {
            height: 396,
            panning: true,
            zoomType: 'x',
        },

        title: { text: '' },

        legend: {
            enabled: true,
            align: 'center',
            layout: 'horizontal',
            verticalAlign: 'top',
            itemMarginTop: 20,
            itemMarginBottom: 25,
            itemDistance: 77,
            itemStyle: {
                "fontSize": "13px",
                "fontWeight": "600"
            },
            symbolHeight: 50,
            symbolWidth: 22,
            symbolPadding: 8
        },

        rangeSelector: {
            enabled: false
        },

        xAxis: {
            title: {
                text: 'TIME (UTC)'
            },
            // startOnTick: true,
            // endOnTick: true,
            tickLength: 0,
            type: 'datetime',
            labels: {
                align: 'center',
                allowOverlap: false,
                step: 2 ,
                formatter: function() {
                    return Highstock.dateFormat('%d/%m %H:%M', this.value);
                }
            },

            lineColor: 'transparent',

            plotBands: {
                color: 'rgba(24,148,121,0.15)',
                from: null,
                to: null
            }
        },

        tooltip: {
            headerFormat: `<div class="flex-centered-col" style="min-width:150px;">
                                <div class="highcharts-fonts tooltip-title"><b>
                                    {series.userOptions.tootltipXTitle}{point.key:%d/%m %H:%M${decimals}}&nbsp;{series.userOptions.titleSuffix}
                                </b></div>
                                <div class="flex-centered-col tooltip-values" style="width: 100%;">`,
            pointFormat:
                `<div class="flex-space-between highcharts-fonts" id="{series.name}" style="width: 100%;">
                    <div class="flex-space-between">
                        <div class="tooltip-circle" style="background-color:{series.color};"></div>
                        <div style="margin-right: 24px;">{series.userOptions.tooltipTitle}: &nbsp;</div>
                    </div>
                    <div><b>{point.y} {point.z}</b></div>
                </div>`,
            footerFormat: '</div></div>',

            // Solution below for date in tooltip header - Must be tested to merge
            /*pointFormatter: function () {
                return `
                <div class="flex-centered-col" style="min-width:150px; padding:5px;">
                    <div class="highcharts-fonts" style="margin-bottom:5px;">
                        <b>
                           ${moment(this.x).format('DD/MM HH:mm')} ${this.series.userOptions.titleSuffix}
                        </b>
                    </div>
                    <div class="flex-space-between highcharts-fonts" id="${this.series.name}" style="padding:0; width: 100%; margin-top:5px;">
                        <div>${this.series.userOptions.tooltipTitle}: &nbsp;</div>
                        <div><b>${this.y} ${this.series.userOptions.tooltip.valueSuffix}</b></div>
                    </div>
                </div>
                `
            },
            headerFormat: '',*/
            animation: false,
            useHTML: true,
            split: false,
            shared: true,
            crosshairs: false,
            followPointer: false,
            xDateFormat: '%d/%m %H:%M'
        },

        plotOptions: {
            line: {
                states: {
                    hover: { animation: 0 }
                },
            },
            series: {
                cursor: 'pointer',
                label: { connectorAllowed: false },
                marker: {enabled: false, symbol: 'circle'},
                dataGrouping: {
                    enabled: false
                },
                connectNulls: false,
                turboThreshold: 300000
            },
            scatter: {
                marker: {
                    radius: 1,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                }
            }
        },

        credits: { enabled: false },

        series: [],

        lang: {
            noData: "No data to display at the moment"
        },

        exporting: {
            enabled: true,
            buttons: {contextButton: {}},
            chartOptions: {
                chart: {
                    backgroundColor: ThemeColorsForCharts()['platform_background']
                }
            }
        },

        navigation: {
            menuStyle: {}
        },

        navigator: {}
    };

    return options;
}

export default lineChartStockOptions;
