import React, { useState, useEffect } from 'react';
import pieChartOptions from 'components/charts/pieChart/pieChartOptions';
import PieChart from 'components/charts/pieChart/pieChart';

const NavigationStatusChart = props => {
    const [pieChart, setPieChart] = useState(pieChartOptions());

    useEffect(() => {
        if (!props.progressChartOptions) return;
        let options = pieChartOptions();
        options.chart.width = props.progressChartOptions.width;
        options.chart.height = props.progressChartOptions.height;
        options.chart.marginLeft = 20;
        options.title.style = props.progressChartOptions.style;
        options.title.y = props.progressChartOptions.titleY;
        options.title.x = props.progressChartOptions.titleX;
        options.series[0].innerSize = props.progressChartOptions.innerSize;
        options.series[0].showInLegend = true;
        options.series[0].data = props.data;
        options.series[0].point = props.genericChartOptions.point;
        options.exporting.enabled = props.progressChartOptions.export;
        options.legend = props.genericChartOptions.chartLegend;
        options.plotOptions.pie.dataLabels = props.genericChartOptions.dataLabels;
        options.chart.id = props?.progressChartOptions?.id + props?.widget?.id;

        setPieChart(options);
        // eslint-disable-next-line
    }, [props.progressChartOptions]);


    return (
        <div className="d-flex align-items-center justify-content-center" style={props.style}>
            <PieChart {...pieChart} />
        </div>
    );
}

export default NavigationStatusChart;
