import React from 'react';

const ReportsGoToDates = (props) => {
    return (
        <button className="go-to-dates-btn main-cta" onClick={props.goToDates}>
            <div className="main-cta__text">Apply</div>
        </button>
    );
}
 
export default ReportsGoToDates; 