import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import MapComponent from 'components/map/map';
import Layout from 'components/core/Layout';
import AlertWaste from 'components/alerts/alertWaste';
import FuelOverconsumption from 'components/alerts/fuelOverconsumprion';
import service from 'common/js/service';
import { useDispatch } from 'react-redux';

const FuelConsumptionLight = props => {
    const [widgets, setWidgets] = useState({ layout: [] });
    const [excessFuel, setExcessFuel] = useState('-');
    const [fuelWaste, setFuelWaste] = useState('-');
    const [fuelPricesDatetime, setFuelPricesDatetime] = useState('-');
    const [requestOnGoing, setRequestOnGoing] = useState({
        waste: false,
    });

    // store vars
    const dispatch = useDispatch();

    const updateRequestOnGoing = (key, value) => {
        setRequestOnGoing((currentRequestOnGoing) => {return {...currentRequestOnGoing, [key]: value}});
    }

    const getWidgetsPreferences = () => {
        return {
            className: 'padding-left-right-7',
            layout: [{
                columns: [{
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 4 },
                    className: 'padding-left-right-7',
                    layout: [{
                        columns: [{
                            grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                            components: [
                                { id: 'fuelOverconsumption', title: "", type: 'text', component: FuelOverconsumption, data: {}, style: { height: '137px', padding: '15px' }, props: {} }
                            ]
                        },
                        {
                            grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                            components: [
                                { id: 'waste', title: "", type: 'text', component: AlertWaste, data: {}, style: { height: '256px' }, props: {} }
                            ]
                        }]
                    }]
                }, {
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 8 },
                    className: 'padding-left-right-7',
                    layout: [{
                        columns: [{
                            grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                            components: [
                                { id: 'route', title: "ROUTE", type: 'map', component: MapComponent, data: {}, style: { height: '408px' }, props: {} }
                            ]
                        }
                        ]
                    }]
                }],
            }]
        };
    };

    const initAlertsPage = (activeAlert, firstTime) => {
        const tmpAlert = activeAlert;

        if (!firstTime) service.getAnomaliesForMapBullets({
            vesselId: tmpAlert.vesselId,
            from: tmpAlert.startingDate,
            to: tmpAlert.endingDate,
            granularity: 30,
            type: tmpAlert.alertType,
            timeUnit: 'minute'
        }).then(result => {
            dispatch({ type: 'alerts/setAlertMapBulletsAnomalies', payload: result.data });
        });

        updateRequestOnGoing('waste', true);
        service.getAlertFuel({ vesselId: tmpAlert.vesselId, fromDate: tmpAlert.startingDate, toDate: tmpAlert.endingDate }).then(result => {
            updateRequestOnGoing('waste', false);
            setExcessFuel(result.data ? result.data.fuelOverConsumption || '-' : '-');
            setFuelWaste(result.data.moneyWaste);
            setFuelPricesDatetime(result.data.fuelPricesDatetime || '-')
        });
    }

    const alertsCalls = (activeAlertTemp) => {
        service.getAnomaliesForMapBullets({
            vesselId: activeAlertTemp.vesselId,
            from: activeAlertTemp.startingDate,
            to: activeAlertTemp.endingDate,
            granularity: 30,
            type: activeAlertTemp.alertType,
            timeUnit: 'minute'
        }).then(result => {
            dispatch({ type: 'alerts/setAlertMapBulletsAnomalies', payload: result.data });
        });

        setWidgets(getWidgetsPreferences());
        initAlertsPage(activeAlertTemp, true);
    }

    //useEffect here------------------------------------------

    useEffect(() => {
        if(props.activeAlert?.id) alertsCalls(props.activeAlert);
    // eslint-disable-next-line
    }, [props.activeAlert]);

    //-------------------------------------------------------------

    const data = {
        route: {
            reports: true,
            alerts: true,
        },
        fuelOverconsumption: {
            data: Object.keys(props.activeAlert).length === 0 ? {}
                : {...props.activeAlert}
        },
        waste: {
            excessFuel: excessFuel,
            fuelWaste: fuelWaste,
            activeAlert: {...props.activeAlert},
            fuelPricesDatetime: fuelPricesDatetime,
            loading: requestOnGoing.waste,
        }
    }

    const createLayoutProps = (data, props = widgets) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
                    ('id' in newComponent) && !('element' in newComponent) && (newComponent.id in data) && (newComponent.props = data[newComponent.id]);
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    }

    return (
        <Layout {...createLayoutProps(data)} />
    )
}

export default withRouter(FuelConsumptionLight);
