import React from 'react';
import { ReactComponent as CreateNewDashboard } from 'assets/img/app/dashboard-imgs/createNewDashboard.svg';

const NewDashboardCard = (props) => {

    return(
        <div className="templatesCard" >
            <div className="templatesCard__new" onClick={props.openModal} >
                <CreateNewDashboard className="icon svg-path-no-data" />
                <div className="text">Create New Dashboard</div>
            </div>
        </div>
    )
}

export default NewDashboardCard;
