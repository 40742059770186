import React, { useState, useEffect } from 'react';
import {licensing} from "common/store/licensing";
import NavigationTabs from "components/navigationTabs/navigationTabs";
import ArbitraryPvFv from 'widgets/vesselData/arbitraryPvFv.jsx';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import { keys } from 'lodash';

const _Tabs = [
    { name: 'powerSpeed', title: 'POWER-SPEED' },
    { name: 'fuelSpeed', title: 'FUEL-SPEED' },
    { name: 'ciiSpeed', title: 'CII-SPEED' }
];

const HullPerformance = ({ data, setData, loader, setLoader, useOuterLoader, requestInfo, emptyComponent, setHullShowEmptyComponent }) => {
    const [lightVersion, setLightVersion] = useState(licensing.lightCondition());
    const [currentTab, setCurrentTab] = useState(!licensing.lightCondition() ? _Tabs[0]?.name : _Tabs[1]?.name); // depending on if its a light vessel, initiate the current tab with the appropriate widget
    const [tabs, setTabs] = useState(_Tabs);
    const [dataNeedToBeFetched, setDataNeedToBeFetched] = useState({
        value: false,
        reason: 'none'
    });

    // store vars
    const monitoringStoreJSX = useSelector(state => state.monitoring);

    useEffect(() => {
        if(!monitoringStoreJSX.fromToChanged) return;

        const from = monitoringStoreJSX.detailedData.status ? monitoringStoreJSX.detailedData.from : monitoringStoreJSX.fromTo.from;
        const to = monitoringStoreJSX.detailedData.status ? monitoringStoreJSX.detailedData.to : monitoringStoreJSX.fromTo.to;
        registerWidgetsStore.setFromTo({from, to});

        const isLight = licensing.lightCondition();
        
        setLightVersion(isLight);

        setDataNeedToBeFetched(prevObjValue => {
            return {
                value: !prevObjValue.value,
                reason: 'fromToChanged'
            }
        });
    // eslint-disable-next-line
    }, [monitoringStoreJSX.fromToChanged]);

    const getHullPerformanceHeaderClasses = (lightVersion) => {
        return classnames(
            'hullPerformanceHeader', 
            {
                'flex-space-between': !lightVersion, 
                'flex-centered-end': lightVersion
            }
        );
    };

    const updateCurrentTab = (tab) => {
        setCurrentTab(tab);
        setDataNeedToBeFetched(prevObjValue => {
            return {
                value: !prevObjValue.value,
                reason: 'tabWasSelected'
            }
        });
    };

    const toggleSpecificTabs = (tabsInfo) => {
        setTabs(prevTabs => {
            const updatedTabs = [...prevTabs].map(tab => {
                if(keys(tabsInfo).includes(tab.title)) return { ...tab, disabled: tabsInfo[tab.title].disabled };
                return tab;
            });

            return updatedTabs;
        });
    };

    return (
        <div className='hullPerformance'>
            {/* if a light vessel is selected, we hide the tabs and we show ony the fuel curves widget */}
            {
                (!useOuterLoader || !loader) && <div className={getHullPerformanceHeaderClasses(lightVersion)}>
                    {
                        (!emptyComponent) && <NavigationTabs
                            classes={{
                                mainClass: 'secondary-navigation-tabs',
                                extraClasses: 'd-flex align-items-center'
                            }}
                            noSpace
                            tabs={!lightVersion ? tabs : tabs.filter(tab => tab.name !== 'powerSpeed')} // in light version, we want to show all the tabs, except the power-speed tab
                            currentTab={currentTab}
                            updateCurrentTab={updateCurrentTab}
                        />
                    }
                </div>
            }

            <ArbitraryPvFv
                data={data}
                widgetTitle={_Tabs.find(tab => tab.name === currentTab)?.title}
                loader={loader}
                setLoader={setLoader}
                setData={setData}
                tabs={!lightVersion ? tabs : tabs.filter(tab => tab.name !== 'powerSpeed')} // in light version, we want to show all the tabs, except the power-speed tab
                isLight={lightVersion}
                dataNeedToBeFetched={dataNeedToBeFetched}
                requestInfo={requestInfo}
                setHullShowEmptyComponent={setHullShowEmptyComponent}
                emptyComponent={emptyComponent}
                toggleSpecificTabs={toggleSpecificTabs}
                setCurrentTab={setCurrentTab}
            />
        </div>
    );
}
 
export default HullPerformance;