import meStorage from 'components/monitoringCategories/localStorages/meStorage';

const setBarTablesDataFormat = (barsTableData) => {
    let tmpObj = {};
    barsTableData.forEach(obj => {
      if (obj.value.length === 0) return;
      tmpObj[obj.metricName] = obj.value
    });
    return tmpObj;
};

export const turboChargersChartPayload = {
  xAxis: "TIME",
  aggregation: "AVG",
  timeGroup: 'HOUR',
  metrics: [{
    metricCategory: "MAIN_ENGINE",
    subIds: [1],
    metricData: []
  }]
};

export const turboChargersLineChartData = {
  turboChargersChartMetrics: [
    'tcExhGasInTemp', 'tcExhGasOutTemp', 'tcLOInLETPress', 'tcLOOutLETTemp', 'tcRPM', 'coolingWOutLETTemp'
  ],
  chartAxes: {},              // Dynamically generated in buildNavigationChartAxes method
  turboChargersLineChartTabs: [],   // Dynamically generated in buildNavigationChartTabs method
  navigationMapper: {},       // Dynamically generated in buildNavigationMapper method
  chartSeries: {}             // Dynamically generated in buildNavigationChartSeries method
}

export const buildTurboChargersChartPayload = (result) => {
    turboChargersChartPayload.metrics[0].metricData = [];

    if (!result.noTurboCharger) result.noTurboCharger = 4;
    for (let i = 1; i <= result.noTurboCharger; i++) {
        turboChargersLineChartData.turboChargersChartMetrics.forEach((metric, index) => {
            turboChargersChartPayload.metrics[0].metricData.push({'metricName': `${metric}No${i}`});
        })
    }

    // Navigation Mapper - Build Navigation Mapper object
    buildNavigationMapper(result.noTurboCharger);

    // Navigation Chart - Build Tabs Object
    buildNavigationChartTabs(result.noTurboCharger);

    // Navigation Chart - Build Axes Object
    buildNavigationChartAxes(result.noTurboCharger);

    // Navigation Chart - Build Series Object
    buildNavigationChartSeries(result.noTurboCharger);
}

const buildNavigationMapper = noTurboCharger => {
    turboChargersLineChartData.navigationMapper = {};

    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        const tempArr = turboChargersLineChartData.turboChargersChartMetrics.map((metric) => {
        return `${metric}No${turboNum}`
        })

        turboChargersLineChartData.navigationMapper[`t/c${turboNum}`] = tempArr;
    }
}

const buildNavigationChartTabs = noTurboCharger => {
    turboChargersLineChartData.turboChargersLineChartTabs = [];
    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.turboChargersLineChartTabs.push({title: `T/C ${turboNum}`, name: `t/c${turboNum}`});
    }
}

const buildNavigationChartAxes = noTurboCharger => {
    turboChargersLineChartData.chartAxes = {};
  
    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.chartAxes[`t/c${turboNum}`] = [
        { id: 'exhTemp-in-axis', title: { text: 'Exh. Gas Inlet Temp (°C)'}, height: '60px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        { id: 'exhTemp-out-axis', title: { text: 'Exh. Gas Outlet Temp (°C)'}, height: '60px', top: '150px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        { id: 'rpm-axis', title: { text: 'RPM'}, height: '60px', top: '250px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        { id: 'loPress-in-axis', title: { text: 'LO Inlet Press (bar)'}, height: '60px', top: '350px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        { id: 'loTemp-out-axis', title: { text: 'LO Outet Temp (°C)'}, height: '60px', top: '450px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        { id: 'waterTemp-out-axis', title: { text: 'Air Cooler Water Outlet Temp (°C)'}, height: '60px', top: '550px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
      ]
    }
  }

const buildNavigationChartSeries = noTurboCharger => {
    turboChargersLineChartData.chartSeries = {};
    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.chartSeries[`t/c${turboNum}`] = [
        { id: `tcExhGasInTempNo${turboNum}`, name: 'Exh. Gas Inlet Temp', data: [], tooltipTitle: 'Exh. Gas Inlet Temp', type: 'line', yAxis: 'exhTemp-in-axis',
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
        { id: `tcExhGasOutTempNo${turboNum}`, name: 'Exh. Gas Outlet Temp', data: [], tooltipTitle: 'Exh. Gas Outlet Temp', type: 'line', yAxis: 'exhTemp-out-axis',
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
        { id: `tcRPMNo${turboNum}`, name: 'RPM', data: [], tooltipTitle: 'RPM', type: 'line', yAxis: 'rpm-axis',
          titleSuffix: '(UTC)', tooltip: { valueDecimals: 2 } },
        { id: `tcLOInLETPressNo${turboNum}`, name: 'LO Inlet Temp', data: [], tooltipTitle: 'Lube Oil Inlet Press', type: 'line', yAxis: 'loPress-in-axis',
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
        { id: `tcLOOutLETTempNo${turboNum}`, name: 'LO Outet Press', data: [], tooltipTitle: 'Lube Oil Outlet Temp', type: 'line', yAxis: 'loTemp-out-axis',
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
        { id: `coolingWOutLETTempNo${turboNum}`, name: 'Air Cooler Water Outlet Temp', data: [], tooltipTitle: 'Air Cooler Water Outlet Temp', type: 'line', yAxis: 'waterTemp-out-axis',
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
      ]
    }
}

export const updateTurboChargersChartJson = (id, data, updateState, extraChartConfigs, widget) => {
    meStorage.setTurboChargersData(data);
    let lineChartTurboChargersJSON = (data[0]?.values) ? setBarTablesDataFormat(data[0]?.values) : [];

    updateState(id, lineChartTurboChargersJSON, widget?.id);
};
