import React from "react";
import { ReactComponent as CloseIcon } from 'assets/img/app/global-imgs/close.svg';
import classnames from "classnames";
import {eventsLabels} from 'Utilities/events';


const ToastMessage = (props) => {

    const toastClasses = () => {
        return classnames("toast-wrapper flex-space-between", props.toastOpen ? "showToast" : "hideToast");
    }

    return (
        <div className={toastClasses()} style={{background: props.data.eventColor ? props.data.eventColor : 'transparent'}}>
            <div className="toast-wrapper__message">
            <strong>{props.data && eventsLabels[props.data.type]}</strong> event deleted.
            </div>
            <div className="toast-wrapper__actions d-flex">
                <div className="toast-wrapper__actions__undo pointer" onClick={props.toastUndo}>Undo</div>
                <div className="toast-wrapper__actions__close pointer">
                    <CloseIcon className="svg-path action" onClick={props.toastClose} />
                </div>
            </div>
        </div>
    );
}

export default ToastMessage;
