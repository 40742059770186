import React from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const HullPowerMonitoring = props => {
    return (
        <div className="me-lo-wrapper">
            {props.data !== "" && (
                <HighchartsReact highcharts={Highcharts} options={props.data} />
            )}
        </div>
    );
}

export default HullPowerMonitoring;
