import React, {useState, useEffect} from 'react';
import DropDown from "components/core/DropDown";
import {withRouter} from 'react-router-dom';
import LicenseLabel from 'components/labels/licenseLabel/licenseLabel';
import TagManagerComponent from 'components/analytics/tagManager';
import SplitedButton from 'components/splitedButton/splitedButton';
import { vesselGroupStore, vesselGroupUtils, vesselStore, vesselUtils, monitoringUtils, alertsUtils } from 'common/store/storeUtils';
import { useDispatch, useSelector } from 'react-redux';

const FleetsTopBar = () => {
  let isScrolling = false;
  let clientX = 0;
  let scrollX = 0;

  const ref = React.createRef();

  const onMouseDown = e => {
      isScrolling = true;
      clientX = e.clientX;
  };
  
  const onMouseUp = () => {
      isScrolling = false;
  };
  
  const onMouseMove = e => {
      if (isScrolling) {
          ref.current.scrollLeft = scrollX - e.clientX + clientX;
          scrollX = scrollX - e.clientX + clientX;
          clientX = e.clientX;
      }
  };

  const [eventType, setEventType] = useState({});
  const [renderPage, setRenderPage] = useState(false);

  // store vars
  const vesselGroupStoreJSX = useSelector(state => state.vesselGroup);
  const vesselStoreJSX = useSelector(state => state.vessel);
  const dispatch = useDispatch();

  const [fleets,setFleets] = useState([...vesselGroupStoreJSX.allVesselGroups] || []);

  const onGroupClickHandler = fleet => {
    vesselGroupUtils.setSelectedAndChange(dispatch, fleet.id);
    dispatch({ type: 'vessel/setSelectedVessel', payload: vesselStore().allVesselsObj.vesselId });
    monitoringUtils.resetLocalMonitoringStores();
    // when vessel group changes, we want to initialize the fromTo dates in order to avoid making double requests when user selects a vessel from a different fleet
    monitoringUtils.resetFromTo(dispatch); 
    alertsUtils.init(dispatch);
  }

  const locationWithAllFleetsOption = () => {
    if(window.location.pathname === '/' || window.location.pathname.includes('alerts')) return true;
    return false;
  }

  const vesselEntityOnTopbar = vessel => {
    return (
      <div key={vessel.vesselId} className="fleets-vessels-top-bar__container__vessels-selector__group">
        <div
          className={`fleets-vessels-top-bar__container__vessels-selector__group__vessel headline4${vesselUtils.getSelectedVesselData().name === vessel.name ? ' active' : ''}`}
          onClick={() => {
            dispatch({ type: 'vessel/setSelectedVessel', payload: vessel.vesselId });
            monitoringUtils.resetLocalMonitoringStores();
            alertsUtils.init(dispatch);
            setEventType({name: 'vesselClicked', value: vessel.name})
          }}>
          {vessel.name}
        </div>
      </div>
    )
  }

  const renderVessels = () => {
    if (vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id) {
      return '';
    }
    const fleetVessels = vesselUtils.getFleetVessels(); 
    const uniqueClass = fleetVessels.length === 1 ? ' unique' : '';

    return (
      <React.Fragment>
        <div className={`fleets-vessels-top-bar__container__vessels-selector${uniqueClass} flex-centered-start flex-shrink-0`}
        ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>
          {fleetVessels.filter(v => v.isPremium).map(vessel => vesselEntityOnTopbar(vessel))}

          {((fleetVessels.filter(v => !v.isPremium).length > 0) && (fleetVessels.filter(v => v.isPremium).length > 0))
          && <div className="fleets-vessels-top-bar__container__vessels-selector__vessels-ceperator">&nbsp;</div>}

          {fleetVessels.filter(v => !v.isPremium).map(vessel => vesselEntityOnTopbar(vessel))}

          {fleetVessels.filter(v => !v.isPremium).length > 0 && <LicenseLabel text={'Light'}/>}
        </div>

        <DropDown buttonLabel={vesselStoreJSX.allVesselsObj.vesselId === vesselStoreJSX.selectedVessel
          ? 'Select vessel' : vesselUtils.getSelectedVesselData().name}
                  options={fleetVessels.map(vessel => ({
                    label: vessel.name,
                    classNameWrapper: vesselStoreJSX.selectedVessel === vessel.vesselId && "selectedVessel",
                    onClick: () => {                      
                      dispatch({ type: 'vessel/setSelectedVessel', payload: vessel.vesselId });
                      monitoringUtils.resetLocalMonitoringStores();
                      alertsUtils.init(dispatch);
                      setEventType({name: 'vesselClicked', value: vessel.name});
                    }
                  }))}
                  classNames={{
                    button: `fleets-vessels-top-bar__container__vessels-selector${uniqueClass} main-cta flex-centered`,
                    menu: `vessels-selector${uniqueClass}`
                  }}
        />
      </React.Fragment>
    );
  };

  const pageHasTopBar = () => {
    if (['/', '/reports/dailyGroupReport', '/reports/dailyVesselReport', '/reports/legReport', '/reports/charterPartyReport',
      '/reports/sisterVesselReport', '/timeline'].indexOf(window.location.pathname) !== -1) {
      return setRenderPage(true);
    }
    setRenderPage(false);
  }

  const addTopBarClass = () => {
    setTimeout(() => {
      const element = document.querySelector('.main-container.main');
      if(!element) return;
      element.classList.add('with-fleets-top-bar')
    });
  }

  const removeTopBarClass = () => {
    setTimeout(() => {
      const element = document.querySelector('.main-container.main');
      if(!element) return;
      element.classList.remove('with-fleets-top-bar')
    });
  }

  useEffect(() => pageHasTopBar(), []);

  useEffect(() => {
    if(renderPage) addTopBarClass();
    else removeTopBarClass();
  }, [renderPage]);

  useEffect(() => {
    if(!vesselGroupStore().allVesselGroups) return;
    if(locationWithAllFleetsOption()) setFleets( [...vesselGroupStore().allVesselGroups, vesselGroupStore().allVesselGroupsObj] );
    else setFleets(vesselGroupStore().allVesselGroups);
  // eslint-disable-next-line
  }, [vesselGroupStoreJSX.allVesselGroups]);
  
  useEffect(() => {
    pageHasTopBar();
    if(!vesselGroupStore().allVesselGroups) return;
    if(locationWithAllFleetsOption()) setFleets( [...vesselGroupStore().allVesselGroups, vesselGroupStore().allVesselGroupsObj] );
    else setFleets(vesselGroupStore().allVesselGroups);
  // eslint-disable-next-line
  }, [window.location.pathname]);

  return (
    <>
      { renderPage &&
        <div className="fleets-vessels-top-bar d-flex align-items-center">
          <TagManagerComponent type={'event'} data={eventType} />
          <SplitedButton 
            dropdownOptions={fleets}
            defaultValue={vesselGroupUtils.getSelectedVesselGroupData()}
            onOptionClickListener={onGroupClickHandler}
          />
          { renderVessels() }
        </div>
      }
    </>
  );
}

export default withRouter(FleetsTopBar);