import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField} from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/img/app/global-imgs/close.svg';
import { dashboardStore } from 'common/store/storeUtils';

const TemplateModal = (props) => {
    const [errorMaxChars, setErrorMaxChars] = useState(false);
    const [errorEmptyTitle, setErrorEmptyTitle] = useState(false); 

    const TemplateActionAndCloseModal = (e) => {
        e.preventDefault();
        const tempTitle = e.target.title.value;
        if(tempTitle.length === 0) {
            setErrorEmptyTitle(true);
            return;
        } else setErrorEmptyTitle(false);
        props.dashboardTemplateAction(tempTitle, dashboardStore().currentSelectedId);
        props.closeModal();
    }

    const onChangeTemplateTitle = (e) => {
        const currentTitle = e.target.value;
        setErrorEmptyTitle(false);
        if(currentTitle.length > 25) setErrorMaxChars(true);
        else setErrorMaxChars(false);
    }

    const closeModal = () => {
        setErrorEmptyTitle(false);
        setErrorMaxChars(false);
        props.closeModal();
    } 

    return(
        <Dialog open={props.show} className="centered-modal dashboard-modal templates-modal" disableBackdropClick disableEnforceFocus aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.header}
                <div className="close-button flex-centered">
                    <div className="close-button__img flex-centered">
                        <CloseIcon className="closeIcon" onClick = {closeModal} />
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <form id="modalForm" onSubmit={TemplateActionAndCloseModal} >
                    <TextField
                        name="title"
                        id="standard-error-helper-text"
                        label="* Dashboard Title"
                        onChange={onChangeTemplateTitle}
                        error={errorMaxChars || errorEmptyTitle}
                        helperText={errorMaxChars ? "Enter maximum 25 characters" : errorEmptyTitle ? "Enter the Dashboard Title": ''}
                        defaultValue={props.currentSelectedTitle}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" className='cancelButton' onClick={closeModal}>
                    <div className='main-cta__text'>Cancel</div>
                </Button>
                <Button color="primary" className='saveButton' disabled={errorMaxChars || errorEmptyTitle} type="submit" form="modalForm">
                    <div className='main-cta__text'>{props.submitButtonLabel}</div>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TemplateModal;