import React from 'react';
import classnames from 'classnames';

const CiiClassBox = ({ reportsCiiClass, reportsValue, sensorsCiiClass, sensorsValue }) => {
	const getContainerClasses = ciiClass => {
		return classnames('classBoxContainer d-flex', {
			'classA-bg': ciiClass === 'A',
			'classB-bg': ciiClass === 'B',
			'classC-bg': ciiClass === 'C',
			'classD-bg': ciiClass === 'D',
			'classE-bg': ciiClass === 'E'
		});
	}

	return (
		<div className='classBox-wrapper'>
			{ (reportsCiiClass) && <div className='d-flex flex-column'>
				<div className={getContainerClasses(reportsCiiClass)}>
					<div className="classBoxContainer__ciiClass">{reportsCiiClass}</div>
					<div className="classBoxContainer__ciiValue reportsData">{reportsValue}</div>
				</div>
				<div className="classBoxContainer-data-source-footer reports-footer"></div>
			</div>}
			
			{ (reportsCiiClass  && sensorsCiiClass) && <div className='classBox-wrapper__devider'></div> }

			{ (sensorsCiiClass) && <div className='d-flex flex-column'>
				<div className={getContainerClasses(sensorsCiiClass)}>
					<div className="classBoxContainer__ciiClass">{sensorsCiiClass}</div>
					<div className="classBoxContainer__ciiValue sensorsData">{sensorsValue}</div>
				</div>
				<div className="classBoxContainer-data-source-footer sensors-footer"></div>
			</div>}
		</div>
	);
};

export default CiiClassBox