import moment from 'moment';
import Utilities from 'Utilities/global';

const routeInfoPayload = {
    metrics:[
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                { metricName: "hours_atsea_corrected", aggregation: "SUM" },
                { metricName: "minutes_atsea_corrected", aggregation: "SUM" },    
                { metricName: "next_port", aggregation: "NONE" },
                { metricName: "eta_next_port", aggregation: "NONE" }
            ]
        }
    ]
};

const routeInfo = (id, response, updateState) => {
    let data = [{
        values: [{}, {}, {}, {}]
    }];

    if(response.series.length > 0) response.series[0].values.forEach(value => {
        if(value.metricName === "hours_atsea_corrected") data[0].values[0] = value;
        if(value.metricName === "next_port") data[0].values[1] = value;
        if(value.metricName === "eta_next_port") data[0].values[2] = value;
        if(value.metricName === "minutes_atsea_corrected") data[0].values[3] = value;
    }); else data = [];

    updateState(id, data.length === 0  ? {} : {
        firstValue: {
            label: "HOURS AT SEA",
            value: `${Utilities.renderNumber(data[0].values[0].value)} hrs ${parseInt(data[0].values[3].value) || ""} ${
                parseInt(data[0].values[3].value) ? " mins" : ""}`
        },
        secondValue: 
            {
                label: "NEXT PORT",
                value: data[0].values[1].value || "-"
            },
        thirdValue: 
            {
                label: "ETA NEXT PORT",
                value: data[0].values[2].value ? moment(data[0].values[2].value).format("DD-MM-YYYY HH:mm") : "-"
            }
    });
}


export {routeInfoPayload, routeInfo};

