import React from 'react';
import classnames from 'classnames';

const DsDrawer = (props) => {

    const drawerClasses = () => {
        return classnames(`drawer ds-box-shadow ${props.anchor} ${props.classname}`);
    }

    return (
        <div className={drawerClasses()}>
            {props.component}
        </div>
    );
}

export default DsDrawer;
