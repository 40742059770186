import React from 'react';
import {getLastCleaningEvent} from 'widgets/vesselData/foulingPenalty';
import {ReactComponent as DrydockIcon} from 'assets/img/app/global-imgs/cleaning-event-dry.svg';
import {ReactComponent as DatePickerIcon} from 'assets/img/app/global-imgs/calendar.svg';
import {licensing} from "common/store/licensing";
import { vesselUtils } from 'common/store/storeUtils';
import moment from 'moment';
import HullInfo from 'components/footers/hullInfo';


const HullFoulingPenaltyFooter = (props) => {
    const isLight = props?.widget ? licensing.lightCondition(null, vesselUtils.getObjOfAVessel(props?.widget?.vesselIds[0])) : licensing.lightCondition();
    const lastCleaningEvent = getLastCleaningEvent(props?.foulingPenalty?.cleaningEvents, isLight);
    const selectedVesselObj = props?.widget ? vesselUtils.getObjOfAVessel(props?.widget?.vesselIds[0]) : vesselUtils?.getSelectedVesselData();

    return(
        <div className='flex-space-between full-width'>
            <div className='widget-footer-legend flex-space-around flex-wrap small-label hullFoulingPenaltyFooter'>
                <div className='flex-space-between'>
                    <DrydockIcon width={16} height={16} className='svg-path-main-text-color' />
                    <div className='foulingFooterInfo' > {lastCleaningEvent?.name}: &nbsp; {lastCleaningEvent?.date} </div>
                </div>
                <div className="separator" />
                <div className='flex-space-between yearBuild'>
                    <DatePickerIcon width={16} height={16} className='svg-path-st0' />
                    <div className='foulingFooterInfo'> Year Build: &nbsp; {(selectedVesselObj?.deliveredDate && moment(selectedVesselObj?.deliveredDate)?.utc()?.format('DD/MM/YYYY')) || "-" } </div>
                </div>
            </div>
            { (!props?.widget && !props.isReport) && <div>
                <HullInfo />
            </div> }
        </div>
    )
} 
 
export default HullFoulingPenaltyFooter;