import React from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const FuelConsumptionBarChart = (props) => { 
    return (
        <div className="ge-fc-barChart-wrapper" style={props.style}>
            {props.data !== "" && (
                <HighchartsReact highcharts={Highcharts} options={props.data} constructorType={"stockChart"}/>
            )}
        </div>
    )
}

export default FuelConsumptionBarChart;
