import Highstock from 'highcharts/highstock';
import Highcharts from "highcharts";
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import {userStore} from 'common/store/storeUtils';
require("highcharts/modules/exporting")(Highstock);
require("highcharts/modules/export-data")(Highstock);
require('highcharts/highcharts-more')(Highstock);
require("highcharts/modules/no-data-to-display")(Highstock);

const highstockBarChartOptions = (tooltipHeaderFormat, nonMissingValues, tooltipHeader, isDetailedGranularity) => {

    if(!tooltipHeaderFormat) tooltipHeaderFormat = '';

    (userStore().user?.theme !== 'ds-white') ? Highstock.setOptions(Highstock.darkTheme)
        : Highstock.setOptions(Highstock.whiteTheme);

    (userStore().user?.theme !== 'ds-white') ? Highcharts.setOptions(Highcharts.darkTheme)
        : Highcharts.setOptions(Highcharts.whiteTheme);

    let options = {
        chart: {
            marginTop: 75,
            type: "column",
            height: 396,
        },

        title: { text: '', style: {fontSize: '14px'} },

        legend:{
            layout: 'horizontal',
            verticalAlign: 'top',
            itemMarginBottom: 25,
            alignColumns: false
        },

        rangeSelector: {
            enabled: false
        },

        xAxis: {
            title: {
                text: 'Time (UTC)'
            },
            crosshair: false,
            tickLength: 0,
            type: 'datetime',
            labels: {
                align: 'center',
                formatter: function() {
                    return Highstock.dateFormat(tooltipHeaderFormat.replace(':', ''), this.value);
                }
            },
            plotLines: []
        },
        
        plotOptions: {
            column: {
                cursor: 'pointer',
                borderWidth: 0,
                borderRadius: 2,
                stacking: false,
                dataGrouping: {
                    enabled: false
                },
            },
            bar: {
                cursor: 'pointer',
                borderWidth: 0,
                borderRadius: 2,
                stacking: false,
                dataGrouping: {
                    enabled: false
                },
            },
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                cropThreshold: 200,
                turboThreshold: 900000
            }
        },

        tooltip: {
            headerFormat:
                `<div class="flex-centered-col" style="${nonMissingValues}">
                    <div class="highcharts-fonts tooltip-title">
                        <b>${tooltipHeader ? tooltipHeader : (isDetailedGranularity ? `{point.period}` : `{series.userOptions.tootltipXTitle}{point.key${tooltipHeaderFormat}}&nbsp;{series.userOptions.titleSuffix}`)}</b>
                    </div>
                    <div class="flex-centered-col tooltip-values" style="width: 100%;">`,
            pointFormat:
                '<div class="flex-space-between highcharts-fonts" id="{series.name}" width: 100%;">'+
                    '<div class="flex-space-between">'+
                        '<div class="tooltip-circle" style="background-color:{series.color};"></div>'+
                        '<div style="margin-right: 24px;">{series.userOptions.tooltipTitle}: &nbsp;</div>'+
                    '</div>'+
                    '<div><b>{point.y}</b></div>'+
                '</div>'+
                '<div class="highcharts-fonts" style="width: 100%; text-align: left;">'+
                    '<div style="margin-left: 12px;">{point.missingValues}{series.userOptions.metadata.suffix} {series.userOptions.metadata.title}</div>'+
                '</div>',
            footerFormat: '</div></div>',
            useHTML: true,
            xDateFormat: '%d/%m %H:%M'
        },

        lang: {
            noData: "No data to display at the moment"
        },

        series: [],

        credits: { enabled: false },

        exporting: {
            enabled: true,
            buttons: {contextButton: {}},
            chartOptions: {
                chart: {backgroundColor: ThemeColorsForCharts()['platform_background']}
            },
            csv: {
                dateFormat: '%Y-%m-%d'
            }
        }
    };

    return options;
}

export default highstockBarChartOptions;
