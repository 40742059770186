import moment from "moment";

export const ciiGroupOverviewUtils = (widgetId, response, updateState, widget) => {


	const resObj = (res) => {
		return res?.map((item) => {
			return {
				...item,
				monthlyFuelConsumption: {
					telegrams: item?.monthlyFuelConsumption?.telegrams?.map((tg) => {
						return {
							datetime: tg.x,
							y: tg.y,
							x: moment(tg.x).month()
						};
					})
				},
				monthlyCo2Emissions: {
					telegrams: item?.monthlyCo2Emissions?.telegrams?.map((tg) => {
						return {
							datetime: tg.x,
							y: tg.y,
							x: moment(tg.x).month()
						};
					})
				}
			};
		});
	};

	updateState(widgetId, response ? resObj(response) : [], widget?.id);
};

export const headCells = [
	{
		id: "vesselDetails",
		numeric: false,
		sortable: true,
		label: "Vessel Name"
	},
	{
		id: "fuelConsumption",
		numeric: false,
		sortable: true,
		label: "Total Fuel Consumption (mt)"
	},
	{
		id: "monthlyFuelConsuption",
		numeric: false,
		sortable: false,
		label: "Monthly Fuel Consumption (mt)"
	},
	{
		id: "co2Emissions",
		numeric: true,
		sortable: true,
		label: "Total CO2 Emissions (mt)"
	},
	{
		id: "monthlyCOEmissions",
		numeric: false,
		sortable: false,
		label: "Monthly CO2 Emissions (mt)"
	},
	{
		id: "yearToDateCii",
		numeric: false,
		sortable: false,
		label: "Year to date CII"
	},
	{
		id: "ciiDiffPercentageWithPreviousYear",
		numeric: false,
		sortable: false,
		label: "CII 12 months back (%)"
	},
	{
		id: "requiredCiiOneYearLater",
		numeric: true,
		sortable: true,
		label: "Required CII "
	},
	{
		id: "requiredCiiTwoYearsLater",
		numeric: true,
		sortable: true,
		label: "Required CII "
	}
];
