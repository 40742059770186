import { combineReducers } from 'redux';
import user from './userSlice';
import terms from './termsSlice';
import reports from './reportsSlice';
import monitoring from './monitoringSlice';
import vesselGroup from './vesselGroupSlice';
import vessel from './vesselSlice';
import notifications from './notificationsSlice';
import dashboard from './dashboardSlice';
import alerts from './alertsSlice';
import events from './eventsSlice';
import calendar from './calendarSlice';

const combinedReducer = combineReducers({
    user,
    terms,
    reports,
    monitoring,
    vesselGroup,
    vessel,
    notifications,
    dashboard,
    alerts,
    events,
    calendar
});

export default combinedReducer;