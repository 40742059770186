import Highcharts from 'highcharts';
import Utilities from 'Utilities/global';
import {licensing} from "common/store/licensing";
import moment from 'moment';
import { ThemeColorsForCharts } from "Utilities/themeColorsForCharts";
import { userStore, vesselStore } from 'common/store/storeUtils';

const userTheme = userStore().user?.theme !== 'ds-white' ? 'darkTheme' : 'whiteTheme';

const fcrChartPayload = {
    xAxis: "VESSEL",
    aggregation: "SUM",
    withMetadata: true,
    metrics:[
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {
                    metricName: "foVolConsumption"
                }
            ]
        },
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {
                    metricName: "commonDGFOMassRate"
                },
                {
                    metricName: "boilerCommonFoMassRate"
                }
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "me_1_foConsumption_calc"
                },
                {
                    metricName: "ge_1_foConsumption_calc"
                },
                {
                    metricName: "bo_1_foConsumption_calc"
                }
            ]
        }
    ]
};

const setToMT = (value, category) => {
    if(category === 'MAIN_ENGINE' || category === 'VESSEL') return value/1000;
    return value;
}

const changeVesselIdToVesselName = (array) => {
    let response = [];
    if(array.value) array.value.forEach( subArray => {
        response.push({
            period: `${moment(subArray?.dailyDateRange?.timestampFrom).utc().format('DD/MM HH:mm')} - ${moment(subArray?.dailyDateRange?.timestampTo).utc().format('DD/MM HH:mm')}`,
            name: vesselStore().allVessels.find(vessel => vessel.vesselId === subArray.x)?.name,
            y: setToMT(parseFloat(subArray.y), array.metricCategory) || null,
            missingValues: subArray.missingValues && subArray.missingValues > Utilities.missingValuesLimit
            ? subArray.missingValues.toFixed(1) : null,
            backgroundColor: array.metricName === "me_1_foConsumption_calc" || array.metricName === "ge_1_foConsumption_calc" || array.metricName === "bo_1_foConsumption_calc"
            ? ThemeColorsForCharts()['reports_color']
            : subArray.missingValues && subArray.missingValues > Utilities.missingValuesLimit 
            ? Highcharts[userTheme].tooltip.missingValuesBackgroundColor 
            : ThemeColorsForCharts()['sensor_color']

        });
    });
    return response;
};


const fcr = (id, response, updateState, extraChartConfigs, widget) => {
    if(response && response[0] && response[0]?.values?.length === 0) return updateState(id, {}, widget?.id);
    let datas = [1,2,3,4,5,6];
    
    if(response[0] && response[0].values) response[0].values.forEach(obj => {
        if(obj.metricName === 'commonDGFOMassRate') {
            datas[0] = {...obj};
            datas[0].value.forEach(serie => {
                if(licensing.lightCondition(null, vesselStore().fleetVessels.filter(v => v.vesselId === serie.x)[0])) serie.y = null;
            });
        }
        if(obj.metricName === 'foVolConsumption') {
            datas[1] = {...obj};
            datas[1].value.forEach(serie => {
                if(licensing.lightCondition(null, vesselStore().fleetVessels.filter(v => v.vesselId === serie.x)[0])) serie.y = null;
            });
        }
        if(obj.metricName === 'boilerCommonFoMassRate') {
            datas[2] = {...obj};
            datas[2].value.forEach(serie => {
                if(licensing.lightCondition(null, vesselStore().fleetVessels.filter(v => v.vesselId === serie.x)[0])) serie.y = null;
            });
        }
        if(obj.metricName === 'ge_1_foConsumption_calc') datas[3] = {...obj};
        if(obj.metricName === 'me_1_foConsumption_calc') datas[4] = {...obj};
        if(obj.metricName === 'bo_1_foConsumption_calc') datas[5] = {...obj};
    });

    updateState(id, { chart: {id: "fcrFleet"},
        yAxis: {
            title: { text: 'Fuel Consumption (mt)' }
        },
        customTooltip: true,
        tooltip: {
            formatter: function() {
                return (`
                <div class="flex-centered-col">
                    <div class="highcharts-fonts tooltip-title"><b>${this.point.period} (UTC)</b></div>

                    <div class="flex-centered-col tooltip-values" style="width: 100%;">
                        <div class="flex-space-between highcharts-fonts" style="width: 100%;" id="${this.point.stackTotal}">
                            <div class="flex-centered">
                                <div class="tooltip-circle" style="background-color:var(--primary_text);"></div>
                                <div>Total: &nbsp;</div>
                            </div>
                            <div><b>${this.point.stackTotal.toFixed(2)} mt</b></div>
                        </div>

                        <div class="flex-space-between highcharts-fonts" style="width: 100%;" id="${this.series.name}">
                            <div class="flex-space-between">
                                <div class="tooltip-circle" style="background-color:${this.point.backgroundColor};"></div>
                                <div style="margin-right: 24px;">${this.series.userOptions.tooltipTitle}: &nbsp;</div>
                            </div>
                            <div><b>${this.point.y.toFixed(2)} mt</b></div>
                        </div>

                        <div class="highcharts-fonts" id="${this.series.name}" style="width: 100%; text-align:left; ${!this.point.missingValues ? 'display:none' : 'display:block'}">
                            <div style="margin-left: 12px;">${this.point.missingValues > 0 ? this.point.missingValues + '% missing data' : ''}</div>
                        </div>
                        <div class="arrow-down" style="border-top-color:${this.point.backgroundColor}"></div>
                    </div>
                </div>
                `)
            },
            useHTML: true,
        },
        series: [
        {
            name: 'SensorBO',
            tooltipTitle: 'Sensor BO FC',
            data: changeVesselIdToVesselName(datas[2]),
            color: ThemeColorsForCharts()['third_stack_sensor'],
            stack: 'sensors',
            centerInCategory: true,
            groupPadding: 0.3,
            showInLegend: (changeVesselIdToVesselName(datas[2]).length > 0),
            tooltip: {valueDecimals: 2, valueSuffix: ` mt`} 
        },
        {
            name: 'SensorGE',
            tooltipTitle: 'Sensor GE FC',
            data: changeVesselIdToVesselName(datas[0]),
            color: ThemeColorsForCharts()['second_stack_sensor'],
            stack: 'sensors',
            centerInCategory: true,
            groupPadding: 0.3,
            showInLegend: (changeVesselIdToVesselName(datas[0]).length > 0),
            tooltip: {valueDecimals: 2, valueSuffix: ` mt`}    //${datas[0].unit}
        },
        {
            name: 'SensorME',
            tooltipTitle: 'Sensor ME FC',
            data: changeVesselIdToVesselName(datas[1]),
            color: ThemeColorsForCharts()['sensor_color'],
            stack: 'sensors',
            centerInCategory: true,
            groupPadding: 0.3,
            showInLegend: (changeVesselIdToVesselName(datas[1]).length > 0),
            tooltip: {valueDecimals: 2, valueSuffix: ` mt`}    //${datas[1].unit}
        }, 
        {
            name: 'TelegramBo',
            tooltipTitle: 'Reports BO FC',
            data: changeVesselIdToVesselName(datas[5]),
            color: ThemeColorsForCharts()['third_stack_reports'],
            stack: 'telegrams',
            centerInCategory: true,
            groupPadding: 0.3,
            showInLegend: (changeVesselIdToVesselName(datas[5]).length > 0),
            tooltip: {valueDecimals: 2, valueSuffix: ` ${datas[5].unit}`}
        },
        {
            name: 'TelegramGE',
            tooltipTitle: 'Reports GE FC',
            data: changeVesselIdToVesselName(datas[3]),
            color: ThemeColorsForCharts()['second_stack_reports'],
            stack: 'telegrams',
            centerInCategory: true,
            groupPadding: 0.3,
            showInLegend: (changeVesselIdToVesselName(datas[3]).length > 0),
            tooltip: {valueDecimals: 2, valueSuffix: ` ${datas[3].unit}`}
        },
        {
            name: 'TelegramME',
            tooltipTitle: 'Reports ME FC',
            data: changeVesselIdToVesselName(datas[4]),
            color: ThemeColorsForCharts()['reports_color'],
            stack: 'telegrams',
            centerInCategory: true,
            groupPadding: 0.3,
            showInLegend: (changeVesselIdToVesselName(datas[4]).length > 0),
            tooltip: {valueDecimals: 2, valueSuffix: ` ${datas[4].unit}`}
        },
    ]}, widget?.id);
};

export {fcrChartPayload, fcr};
