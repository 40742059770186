import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ThemeColors from 'sass/_themeColorsForJs.scss';
// import Typography from '@material-ui/core/Typography';


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: ThemeColors['main_blue'],
      color: ThemeColors['standaredWhite'],
      textAlign: 'center',
      maxWidth: 200,
      marginTop: 5,
      marginBottom: 5,
      paddingTop: 0,
      paddingBottom: 0,
    },
}))(Tooltip);


const TooltipComp = (props) => {
    return (
        <HtmlTooltip 
          PopperProps={{style:{zIndex:100005}}}
          placement={props.placement ? props.placement : "bottom"}
          title={ props.title !== '' ? <React.Fragment>
              {/* <Typography color="inherit"><span className="datePicker-bold" style={{color: '#fff', textAlign: 'center'}}>{props.title}</span></Typography> */}
              <div className="datePicker-bold d-flex flex-wrap align-items-center justify-content-center" style={{color: 'var(--standaredWhite)', height: props.height ? props.height : '26px'}}>{props.title}</div>
            </React.Fragment> : ''
          }
        >
        {props.component}
      </HtmlTooltip>
    );
}
 
export default TooltipComp;