const initialAlertsState = {
    alertInspection: { 
        status: false, 
        alert: null, 
        from: null, 
        to: null 
    },
    uninspectedAlertsNum: '',
    alertUpdate: null,
    activeAlert: {},
    onAlertInspection: false,
    alertInspectionMode: false,
    alertMapBulletsAnomalies: [],
    filtersVessel: -1,
    filtersGroup: {
        name: 'All fleets',
        id: -1
    }
}

// alerts reducer
const alerts = (state = initialAlertsState, action) => {
    switch(action.type) {
        case 'alerts/setAlertInspection': {
            return {
                ...state,
                alertInspection: action.payload
            };
        }
        case 'alerts/setUninspectedAlertsNum': {
            return {
                ...state,
                uninspectedAlertsNum: action.payload
            };
        }
        case 'alerts/setAlertUpdate': {
            return {
                ...state,
                alertUpdate: action.payload
            };
        }
        case 'alerts/setActiveAlert': {
            return {
                ...state,
                activeAlert: action.payload
            };
        }
        case 'alerts/setOnAlertInspection': {
            return {
                ...state,
                onAlertInspection: action.payload
            };
        }
        case 'alerts/setAlertInspectionMode': {
            return {
                ...state,
                alertInspectionMode: action.payload
            };
        }
        case 'alerts/setAlertMapBulletsAnomalies': {
            return {
                ...state,
                alertMapBulletsAnomalies: action.payload
            };
        }
        case 'alerts/setFiltersVessel': {
            return {
                ...state,
                filtersVessel: action.payload
            };
        }
        case 'alerts/setFiltersGroup': {
            return {
                ...state,
                filtersGroup: action.payload
            };
        }
        default: 
            return state;
    }
}

export default alerts;