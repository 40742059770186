import React, { useState, useEffect } from 'react';
import Layout from 'components/core/Layout';
import ReportsService from "common/js/reports";
import {navigationStatusChartUpdate} from 'widgets/vesselData/ciiCompanyOvervUtils';
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import {monitoringStore, vesselStore, monitoringUtils} from 'common/store/storeUtils';
import GlobalUtilities from 'Utilities/global';
import service from 'common/js/service';
import { useSelector } from 'react-redux';
import moment from 'moment';
import MapComponent from 'components/map/map';
import { navigationStatusChartUtils } from 'widgets/vesselData/navigationChart'; 
import PieChart from 'components/charts/pieChart/pieChart';
import CompanyOverviewFooter from 'components/footers/staticFooters/ciiPieChartOverviewFooter';
import WidgetTitleWrapper from 'components/widgetPanel/widgetTitleWrapper';
import ciiCompanyEfficiency from 'widgets/vesselData/ciiEfficiencyChart';
import { ciiEfficiencyUpdate, ciiGroupsOverviewUpdate } from 'widgets/vesselData/ciiEfficiency';
import SyncCharts from "components/charts/genericSyncCharts/syncCharts";

let isMounted = false;   
let thisYear = moment(GlobalUtilities.nowOrDemo()).year();

const CompanyMonitoring = ({ overallReportsFromToChanged, overallReportsPeriod }) => {
    const reportsService = new ReportsService();
    
    // const [navigationStatusChartData, setNavigationStatusChartData] = useState([]);
    const [ciiVesselOverview, setCiiVesselOverview] = useState([]);
    const [ciiCompanyEfficiencyData, setCiiCompanyEfficiencyData] = useState([]);
    const [ciiGroupsOverviewData, setCiiGroupsOverviewData] = useState([]);

    const [requestOnGoing, setRequestOnGoing] = useState({
        ciiVesselOverview: false,
        vesselRating: false,
        ciiCompanyEfficiency: false,
        ciiGroupsOverview: false
    });

    // store vars
    const vesselStoreJSX = useSelector(state => state.vessel)
    // this is the last year that the is been applied from the picker
    const selectedPickerYear = useSelector(state => state.monitoring.ciiSearchingYear.selectedPickerYear);

    const reportsComponents = [
        {
            widgetId: 'ciiVesselOverview',
            inDashboard: false,
            singleRequest: {
                value: true,
                externalURequest: {
                    value: true,
                    request: () => {
                        return { 
                            method: service.getCiiCompanyOverview, 
                            params: { 
                                from : moment(`01/01/${selectedPickerYear}`,'DD/MM/YYYY').utc(true).valueOf(), 
                                to:thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(), 
                            } 
                        }
                    }
                },
                overwriteHeader: 'get-vessel-class'
            },
            payload: '',
            type: 'text'
        },
        {
            widgetId: 'ciiCompanyEfficiency',
            inDashboard: false,
            singleRequest: {
                value: true,
                externalURequest: {
                    value: true, request: () => {
                        return { method: service.getCompanyEfficiency, 
                            params: { 
                                from : moment(`01/01/${selectedPickerYear}`,'DD/MM/YYYY').utc(true).valueOf(), 
                                to: thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(),
                                granularity : 'MONTH'
                            }
                        }
                    }
                },
            },
            payload: '',
            type: ''
        },
        {
            widgetId: 'ciiGroupsOverview',
            inDashboard: false,
            singleRequest: {
                value: true,
                externalURequest: {
                    value: true, request: () => {
                        return { method: service.getCiiGroupsOverview, 
                            params: { 
                                from : moment(`01/01/${selectedPickerYear}`,'DD/MM/YYYY').utc(true).valueOf(), 
                                to: thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(),
                            }
                        }
                    }
                },
            },
            payload: '',
            type: ''
        }
    ];

    const getTitlesFromWidgetIds = (widgetId) => {
        const idsTitlesMapper = {
            ciiVesselOverview: 'CII COMPANY OVERVIEW',
            ciiCompanyEfficiency: 'CII COMPANY EFFICIENCY',
            ciiGroupsOverview: 'CII GROUPS OVERVIEW'
        }
        
        return idsTitlesMapper[widgetId];
    }

    const getWidgetsPreferences = () => {
        return {
            className: 'padding-left-right-7 mt-5',
            layout: [{
                columns: [
                {
                    grid: { xs: 12, sm: 12, md: 12, lg: 4, xl: 4 },
                    components: [
                        { id: 'ciiVesselOverview', title: getTitlesFromWidgetIds('ciiVesselOverview'), component: PieChart, footer: <CompanyOverviewFooter />, data: {}, style: { height: '463px' }, props: { removeFromLayout: ciiVesselOverview === ""} }
                    ]
                },
                    {
                        grid: { xs: 12, sm: 12, md: 12, lg: 8, xl: 8 },
                        components: [
                            { id: 'ciiCompanyEfficiency', title: getTitlesFromWidgetIds('ciiCompanyEfficiency'), component: ciiCompanyEfficiency, data: {}, style: { height: '463px' }, props: { removeFromLayout: ciiCompanyEfficiencyData === ""} }
                        ]
                    },
                    {
                        grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                        components: [
                            { id: 'ciiGroupsOverview', title: getTitlesFromWidgetIds('ciiGroupsOverview'), component: SyncCharts, data: {}, style: { height: '370px', marginTop: '30px' }, props: { removeFromLayout: ciiGroupsOverviewData === ""} }
                        ]
                    }
                ]
            }]
        };
    };


    const createLayoutProps = (data, props = getWidgetsPreferences()) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
                    if (('id' in newComponent) && !('element' in newComponent)) {
                        if (newComponent.id in data) newComponent.props = data[newComponent.id];
                    }
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    };

    const updateRequestOnGoing = (key, value) => {
        setRequestOnGoing((currenRequestOnGoing) => { return {...currenRequestOnGoing, [key]: value}});
    }
    
    const reportsResponseMapper = (data, widgetId) => {
        const mapper = {
            ciiVesselOverview: navigationStatusChartUpdate,
            ciiCompanyEfficiency: ciiEfficiencyUpdate,
            ciiGroupsOverview: ciiGroupsOverviewUpdate
        }
        return mapper[widgetId](
			widgetId,
			data,
			updateStateMapper,
			null,
			reportsComponents.filter(
				(report) => report.widgetId === widgetId
			)[0],
			widgetId === "ciiVesselOverview" ? { navigationStatusChartUtils } : { selectedPickerYear }
		);
    }

    const updateStateMapper = (key, value) => {
        const updateMapper = {
            ciiVesselOverview: setCiiVesselOverview,
            ciiCompanyEfficiency: setCiiCompanyEfficiencyData,
            ciiGroupsOverview: setCiiGroupsOverviewData
        }
        return updateMapper[key](value);
    }


    const initCiiOverall = () => {
        registerWidgetsStore.setFromTo(monitoringStore().fromTo);
        // if overview storage has saved data, then use them and don't make new requests to get the data
        reportsService.registerReports([vesselStore().fleetVessels], reportsComponents, reportsResponseMapper, updateRequestOnGoing, true);
    }

    useEffect(() => {
        if (!isMounted) return;

        initCiiOverall();
        
        // eslint-disable-next-line
    }, [vesselStore().fleetVessels]);
    
    useEffect(() => {
        if (!isMounted) return;
        if (!overallReportsFromToChanged) return;
        if (!overallReportsPeriod?.from || !overallReportsPeriod?.to) return;
        
        return initCiiOverall();
        
        // eslint-disable-next-line
    }, [overallReportsFromToChanged]);
    
    useEffect(() => {
        isMounted = true;
        const controller = new AbortController();
        GlobalUtilities.signal = controller.signal;
        
        if (!overallReportsPeriod?.from || !overallReportsPeriod?.to) return;
        
        initCiiOverall();
        
        return () => {
            controller.abort();
            isMounted = false;
            GlobalUtilities.signal = null;
        }
        // eslint-disable-next-line
    }, []);
    
    const data = {
        ciiVesselOverview: {
            data: ciiVesselOverview,
            allowTelegrams: monitoringUtils.allowTelegrams(),
            genericChartOptions: navigationStatusChartUtils,
            progressChartOptions: navigationStatusChartUtils.progressChartOptions(),
            loading: requestOnGoing.ciiVesselOverview,
            fleetVessels: vesselStoreJSX.allVessels.length,
            ...ciiVesselOverview
        },
        ciiCompanyEfficiency: {
            options: ciiCompanyEfficiencyData,
            noStack: true,
            loading: requestOnGoing.ciiCompanyEfficiency,
            defaultInputProps: {
                options: [
                    { label: "Monthly", value: "MONTH" },
                    { label: "Quarterly", value: "QUARTER" }
                ],
                defaultActive: 'MONTH'
            },
            innerRequest: async selectedSegment => {
                return service.getCompanyEfficiency({
                    granularity: selectedSegment,
                    from: moment(`01/01/${selectedPickerYear}`,"DD/MM/YYYY").utc(true).valueOf(),
                    to: thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(),
                });
            },
            innerUpdate: (response, selectedSegment, setInnerUpdate, innerUpdate) => ciiEfficiencyUpdate(
                'ciiCompanyEfficiency', 
                response, 
                null, 
                setInnerUpdate, 
                innerUpdate, 
                { 
                    selectedPickerYear,
                    granularity: selectedSegment
                }
            )
        },
        ciiGroupsOverview: {
            options: ciiGroupsOverviewData,
            loading: requestOnGoing.ciiCompanyEfficiency,
        }
    }

    return (
        <>
            <div className="cii-vessel-monitoring main-content-padding pad-t-0">
                <WidgetTitleWrapper title="COMPANY'S VESSELS LATEST POSITION">
                    <MapComponent
                        timestamp={thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('year').utc(true).valueOf()} 
                        overallReportsFromToChanged={overallReportsFromToChanged}
                    />
                </WidgetTitleWrapper>                
                <div className="cii-vessel-monitoring__widgets">
                    <Layout {...createLayoutProps(data)} />
                </div>
            </div>
        </>
    );
}

export default CompanyMonitoring;