import {updateSFOCPowerChart, setSFOCPowerCurvesData} from 'widgets/mainEngine/SFOCPowerChart';
import {updateFuelPowerChart, setfuelPowerCurvesData} from 'widgets/mainEngine/fuelConsumptionPowerChart';
import {updatePowerRpmChart, setPowerRpmCurvesData} from 'widgets/mainEngine/powerRpmChart';
import {updateSCAVPowerChart} from 'widgets/mainEngine/SCAVPowerChart';
import {updateTCRpmPowerChart, setTCRpmPowerCurvesData} from 'widgets/mainEngine/turboChargerRpmPowerChart';
import {updateTCScavRpmChart} from 'widgets/mainEngine/turboChargerScavRpmChart';
import service from "common/js/service";
import { dashboardStore, dashboardUtils } from 'common/store/storeUtils';

const dashboardRequestsUtilities = {
    curvesScatterGeneralRequest: (widget, extraInfo) => {
        const requestObject = {
            widgetId: extraInfo.widgetId,
            payload: {},
            type: 'text',
            updateHandler: extraInfo.updateHandler,
            hasTimePeriod: true,
            singleRequest: {
                value: true, 
                externalURequest: {
                    value: true, 
                    request: () => { 
                        return {
                            method: extraInfo.getter,
                            params: { 
                                vesselId: widget.vesselIds[0], 
                                from: !extraInfo.isLeg ? dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject[extraInfo.widgetId])?.from : dashboardUtils.dashboardProperties?.timespansObject[widget?.widgetId + widget?.id]?.timestampFrom?.valueOf(), 
                                to: !extraInfo.isLeg ? dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties?.timespansObject[extraInfo.widgetId])?.to : dashboardUtils.dashboardProperties?.timespansObject[widget?.widgetId + widget?.id]?.timestampTo?.valueOf(),
                                scatterPlotCount: dashboardStore().editMode ? '' : -1,
                                dashboardTemplateId: extraInfo.templateId
                            }
                        }
                    }
                }
            }   
        }

        if(extraInfo.prerequisiteGetter) requestObject.prerequisite = {
            value: true, 
            request: () => {
                return {
                    method: extraInfo.prerequisiteGetter,
                    params: { vesselId: widget.vesselIds[0] }
                }
            }, 
            callback: extraInfo.prerequisiteSetter 
        }

        return requestObject;
    },
    meSfocPower: (widget, extraInfo) => dashboardRequestsUtilities.curvesScatterGeneralRequest(
        widget, 
        {
            ...extraInfo, 
            updateHandler: updateSFOCPowerChart, 
            prerequisiteGetter: service.getSFOCPowerCurvesData, 
            prerequisiteSetter: setSFOCPowerCurvesData, 
            getter: service.getSFOCPowerScatterPlotsData
        }
    ),
    meFCPower: (widget, extraInfo) => dashboardRequestsUtilities.curvesScatterGeneralRequest(
        widget, 
        {
            ...extraInfo, 
            updateHandler: updateFuelPowerChart, 
            prerequisiteGetter: service.getFuelPowerCurvesData, 
            prerequisiteSetter: setfuelPowerCurvesData, 
            getter: service.getFuelPowerScatterPlotsData
        }
    ),
    mePowerRpm: (widget, extraInfo) => dashboardRequestsUtilities.curvesScatterGeneralRequest(
        widget, 
        {
            ...extraInfo, 
            updateHandler: updatePowerRpmChart, 
            prerequisiteGetter: service.getPowerRpmCurvesData, 
            prerequisiteSetter: setPowerRpmCurvesData, 
            getter: service.getPowerRpmScatterPlotsData
        }
    ),
    mePscavPower: (widget, extraInfo) => dashboardRequestsUtilities.curvesScatterGeneralRequest(
        widget, 
        {
            ...extraInfo, 
            updateHandler: updateSCAVPowerChart,
            getter: service.getSCAVPowerScatterPlotsData
        }
    ),
    turbochargerRpmPower: (widget, extraInfo) => dashboardRequestsUtilities.curvesScatterGeneralRequest(
        widget, 
        {
            ...extraInfo, 
            updateHandler: updateTCRpmPowerChart,
            prerequisiteGetter: service.getTCRpmPowerCurvesData, 
            prerequisiteSetter: setTCRpmPowerCurvesData, 
            getter: service.getTCRpmPowerScatterPlotsData
        }
    ),
    turbochargerPscavRpm: (widget, extraInfo) => dashboardRequestsUtilities.curvesScatterGeneralRequest(
        widget, 
        {
            ...extraInfo, 
            updateHandler: updateTCScavRpmChart,
            getter: service.getTCScavRpmScatterPlotsData
        }
    )
}

export default dashboardRequestsUtilities;