import React from 'react';
import SourceInfoLabel from 'components/labels/sourceInfoLabel/sourceInfoLabel';
import PropTypes from 'prop-types';

const PanelHeaderWithTag = ({title, tag, icon}) => {
    return (
        <div className="flex-space-between full-width marg-r-15" style={{paddingRight: '110px'}}>
            <div>{title}</div>
            <SourceInfoLabel text={tag} icon={icon}/>
        </div>
    )
}

PanelHeaderWithTag.propTypes = {
    title: PropTypes.string,
    tag: PropTypes.string,
    icon: PropTypes.element
}

export default PanelHeaderWithTag;
