import React from 'react';
import moment from 'moment';
import service from 'common/js/service';
import { withRouter } from 'react-router-dom';
import Utilities from 'Utilities/global';
import AlertCircleSymbol from 'components/alerts/alertCircleSymbol';
import {ReactComponent as VesselIcon} from 'assets/img/app/alerts-imgs/alert-vessel.svg';
import Wind from 'Utilities/wind';
import AlertStatusLabel from 'components/labels/alertStatusLabel/alertStatusLabel';
import LabelWithBigIcon from 'components/labels/iconWithTextLabel/labelWithBigIcon';
import {userStore, vesselGroupStore, vesselStore, alertsStore, vesselGroupUtils} from 'common/store/storeUtils';
import { useDispatch } from 'react-redux';

const AlertView = props => {
    // store vars
    const dispatch = useDispatch();

    const ifDateRangeSameDay = () => {
        if(moment(props.alert.startingDate).utc().format('DD/MM/YYYY') === moment(props.alert.endingDate).utc().format('DD/MM/YYYY'))
            return true;
        return false;
    }

    const ifNotToday = () => {
        if(moment(props.alert.startingDate).utc().format('DD/MM/YYYY') !== moment().utc().format('DD/MM/YYYY'))
            return true;
        return false;
    }

    const getVesselName = id => {
        return vesselStore().allVessels.filter(v => v.vesselId === id)[0].name;
    }

    const inspectAlert = alert => {
        if(alertsStore().activeAlert?.id === alert?.id) return;
        if(!alert.read) {
            service.updateNotification({ 
                "alertId" : alert.id,
                "userId" : userStore().user.uuid
            });
            alert.read = true;

            dispatch({ type: 'alerts/setAlertUpdate', payload: alert });
            dispatch({ type: 'alerts/setUninspectedAlertsNum', payload: alertsStore().uninspectedAlertsNum - 1 });
        }
        
        dispatch({ type: 'alerts/setActiveAlert', payload: alert });
        
        dispatch({ 
            type: 'alerts/setAlertInspection',
            payload: {
                status: true, 
                alert, 
                from: moment(alert.startingDate).utc().subtract(6, 'h').valueOf(), 
                to: moment(alert.endingDate).utc().add(6, 'h').valueOf()
            }
        })

        const vessel = vesselStore().allVessels.filter(vessel => vessel.vesselId === alert.vesselId)[0];

        vesselGroupUtils.setSelectedAndChange(
            dispatch, 
            vesselGroupStore().allVesselGroups.filter(fleet => fleet.id === vessel.vesselGroupId)[0].id
        );

        dispatch({ type: 'vessel/setSelectedVessel', payload: vessel.vesselId });

        // when an alert is being selected from the alert's bell dropdown when we are already in the alerts page, then we need to update the filtersVessel and filtersGroup state vars
        // so that the alerts list and the selected topbar's vessel get updated
        // note: we want the update to happen only if the following 2 criteria are fulfilled: 
            // 1) if we select an alert from the alert's bell dropdown list, and not from the alerts page dropdown list
            // 2) if a specific vessel is selected 
        if(props.fromAlertsBell && alertsStore().filtersVessel !== vesselGroupStore().allVesselGroupsObj.id) {
            dispatch({ type: 'alerts/setFiltersVessel', payload: vessel.vesselId });
            dispatch({ type: 'alerts/setFiltersGroup', payload: vesselGroupStore().allVesselGroups.filter(fleet => fleet.id === vessel.vesselGroupId)[0] });
        }
        
        if (window.location.pathname !== '/alerts' ) props.history.push('/alerts');
    }

    const alertsTitleContent = () => {
        if(props.alert.alertType === 0 || props.alert.alertType === 1)
            return `Fuel Over - Consumption ${Utilities.roundToDigits(props.alert.value, 2)}%`;
        if(props.alert.alertType === 2 || props.alert.alertType === 3)
            return `Severe Weather - ${Wind.knotsToBeauforts(props.alert.value)} bf`;
    }

    return (
        <div className="alert-view d-flex align-items-center flex-shrink justify-content-between relative" style={{paddingLeft: props.alert.toast || props.alert.alertBell ? '15px' : '40px'}} onClick={() => inspectAlert({...props.alert})}>
            {!props.alert.toast && !props.alert.alertBell && props.activeAlert.id === props.alert.id && <div className="alert-view__active-alert"/>}

            {!props.alert.toast && !props.alert.read && <div className="alert-view__unread-alert" style={{left: !props.alert.alertBell ? '20px' : 0}}/>}

            <AlertCircleSymbol {...props.alert} />

            <div className="alert-view__alert-info">
                <div className="headline2">{ alertsTitleContent() }</div>
                {props.alert.toast && <div className="form-text inlineBlock">Click here to &nbsp;
                    <span className="form-text text-underline inlineBlock pointer" onClick={() => props.alert.history.push('/alerts')}> view alert</span>
                </div>}
                {!props.alert.toast && <div className="form-text">
                    on {moment(props.alert.startingDate).utc().format('DD/MM/YYYY')} at {moment(props.alert.startingDate).utc().format('HH:mm')} -&nbsp;
                    {ifDateRangeSameDay() ? moment(props.alert.endingDate).utc().format('HH:mm')
                    : <React.Fragment><br/>{moment(props.alert.endingDate).utc().format('DD/MM/YYYY')} at {moment(props.alert.endingDate).utc().format('HH:mm')}
                      </React.Fragment> 
                    }
                </div> }
                {!props.alert.toast && <div className="d-flex align-items-center justify-content-between marg-t-5">
                    { ifNotToday() && <div className="smallGrey">{moment(props.alert.endingDate).utc().fromNow()}</div> }
                    { props.alert.resolved && <AlertStatusLabel status='RESOLVED' /> } 
                </div>} 
            </div>

            {!props.alert.alertBell && <div className="alert-view__seperator marg-l-5 marg-r-5"></div>}

            <LabelWithBigIcon 
                icon={<VesselIcon className="svg-path-stroke" />}
                text={getVesselName(props.alert.vesselId)}
                extraClasses={{wrapperClasses: 'alert-view__vessel'}}
            />

            {/* {!props.toast && <OptionsIcon className="svg-path rotate-90 alert-view__" />} */}

        </div>
    );
}
 
export default withRouter(AlertView);
