import React from 'react';
import SensorsTelegramsDataDivided from 'reports/SensorsTelegramsDataDivided';
import {ReactComponent as FuelDropsIcon} from 'assets/img/app/global-imgs/fuel-drops.svg';
import { ReactComponent as MeIcon } from 'assets/img/app/monitoring-imgs/meEventIcon.svg';
import {ReactComponent as GeIcon} from 'assets/img/app/monitoring-imgs/generator-engine-icon.svg';
import { ReactComponent as BoilersIcon } from 'assets/img/app/boilers-imgs/boilerEventIcon.svg';
import keys from 'lodash/keys';

const getLastFuelType = (fuelTypesObj) => {
    return fuelTypesObj?.LSDO ? 'LSDO' : fuelTypesObj?.HSDO ? 'HSDO' : fuelTypesObj?.LSFO ? 'LSFO' : 'HSFO';
}

const fuelTypesExist = (fuelTypesObj) => {
    return fuelTypesObj?.HSFO || fuelTypesObj?.LSFO || fuelTypesObj?.HSDO || fuelTypesObj?.LSDO;
}

const getFuelMetricsObj = (props) => {
    return {
        me: {
            title: 'Main Engine',
            iconComponent: <MeIcon className="svg-path" />,
            sensorsValue: props?.data?.data?.totalFuelMESensors,
            reportsValue: props?.data?.data?.totalFuelMEReports,
            missingData: props?.data?.data?.totalFuelMESensorsMissing
        },
        ge: {
            title: 'Generator Engine',
            iconComponent: <GeIcon className="svg-path" />,
            sensorsValue: props?.data?.data?.totalFuelGESensors,
            reportsValue: props?.data?.data?.totalFuelGEReports,
            missingData: props?.data?.data?.totalFuelGESensorsMissing
        },
        bo: {
            title: 'Boilers',
            iconComponent: <BoilersIcon className="svg-path" />,
            sensorsValue: props?.data?.data?.totalFuelBOSensors,
            reportsValue: props?.data?.data?.totalFuelBOReports,
            missingData: props?.data?.data?.totalFuelBOSensorsMissing
        }
    }
}

const TotalFuelConsumption = (props) => {
    return(
        <div className='totalFuelConsumptionWidget flex-space-between-col'>
            <div className='full-height d-flex justify-content-center align-items-center flex-wrap'>
                <div className='d-flex totalFuelConsumptionWidget__body__wrapper'>
                    <FuelDropsIcon className="svg-path" />
                    <div className='totalFuelConsumptionWidget__body__text headline-metric'>
                        <SensorsTelegramsDataDivided 
                            sensorsValue={props?.data?.data?.totalFuelSensors} 
                            reportsValue={props?.data?.data?.totalFuelReports} 
                            classes={{dividerClasses: 'textDivider big', sensorsClass: 'sensor', reportsClass: 'report', wrapperClass: 'flex-centered'}}
                            unit='mt' 
                        />
                        <div className='label'>TOTAL FUEL CONSUMPTION</div>
                        {
                            props?.data?.data?.totalFuelSensorsMissing && 
                            <div className="missingFuelData small-label">
                                ({props?.data?.data?.totalFuelSensorsMissing}% Missing Data)
                            </div>
                        }
                    </div>
                    {props?.isLegReportWidget && <div className='textDivider huge' />}
                </div>
                {
                    props?.isLegReportWidget && (
                        <div className='d-flex marg-t-20'>
                            {
                                keys(getFuelMetricsObj(props)).map((metricCategory, index) => {
                                    return(
                                        <div className='d-flex align-items-start metricsData' key={index}>
                                            {getFuelMetricsObj(props)[metricCategory]?.iconComponent}
                                            <div className='totalFuelConsumptionWidget__body__text info-value'>
                                                <SensorsTelegramsDataDivided 
                                                    sensorsValue={getFuelMetricsObj(props)[metricCategory]?.sensorsValue} 
                                                    reportsValue={getFuelMetricsObj(props)[metricCategory]?.reportsValue} 
                                                    classes={{dividerClasses: 'textDivider big', sensorsClass: 'sensor', reportsClass: 'report', wrapperClass: 'flex-centered'}}
                                                    unit='mt' 
                                                />
                                                <div className='label'>{getFuelMetricsObj(props)[metricCategory]?.title}</div>
                                                {
                                                    getFuelMetricsObj(props)[metricCategory]?.missingData && 
                                                    <div className="missingFuelData small-label">
                                                        ({getFuelMetricsObj(props)[metricCategory]?.missingData}% Missing Data)
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
            <div className='totalFuelConsumptionWidget__footer small-label'>
                    <div className='totalFuelConsumptionWidget__footer__body flex-centered-start flex-wrap'>
                        {fuelTypesExist(props?.data?.data?.fuelConsumptionTypesData) && <div>Type of Fuel Consumption: &nbsp;</div>}
                        {
                            keys(props?.data?.data?.fuelConsumptionTypesData).map((type, index) => {
                                return props?.data?.data?.fuelConsumptionTypesData[type] && <React.Fragment key={index}>
                                    <div>{type} &nbsp;</div>
                                    <div className='report'>{props?.data?.data?.fuelConsumptionTypesData[type]} mt</div>
                                    {getLastFuelType(props?.data?.data?.fuelConsumptionTypesData) !== type && <div className='textDivider small'> </div>}   
                                </React.Fragment>
                            })
                        }
                        <>
                            {fuelTypesExist(props?.data?.data?.fuelConsumptionTypesData) && <div className='textDivider small'> </div>} 
                            <div>Total CO2 Emissions: &nbsp;</div>
                            <SensorsTelegramsDataDivided 
                                sensorsValue={props?.data?.data?.totalCO2Sensors} 
                                reportsValue={props?.data?.data?.totalCO2Reports} 
                                classes={{dividerClasses: 'textDivider small', sensorsClass: 'sensor', reportsClass: 'report', wrapperClass: 'flex-centered'}}
                                unit='mt' 
                            />
                        </>
                    </div>
            </div>
        </div>
    )
}

export default TotalFuelConsumption;