import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@material-ui/core';


const CheckBox = (props) => {
    return (
        <div className={`input-group ${props.wrapperClassName}`}>
            <Field name={props.name}>
                {({ field, form }) => (
                    <FormControl error={props.error} component="fieldset" className={props.className}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    {...props}
                                    checked={field.value}
                                    onChange={(e) => {
                                        this.props.onChange(e);
                                        form.setFieldValue(props.name, !field.value);
                                    }}
                                    color="primary"
                                />
                            }
                            label={props.label.type ? React.createElement(props.label.type, props.label.props, props.label.children) : props.label}
                            labelPlacement={props.displayLabelAtEnd}
                        />
                        {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
                    </FormControl>
                )}
            </Field>
        </div>
    );
}

CheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool
};

CheckBox.defaultProps = {
    placeholder: '',
    className: '',
    disabled: false,
};

export default CheckBox;