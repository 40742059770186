import React from "react";

const BurgerMenuIcon = (props) => {
    return (
        <div className="ds-navbar__burgers flex-centered" onClick={props.toggleSidebar}>
            <div className="ds-navbar__burgers__btn">
                <span></span> <span></span> <span></span>
            </div>
        </div>
    )
}

export default BurgerMenuIcon;