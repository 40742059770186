
export const ciiLegsEfficiencyUpdate = (id, response, updateState, extraChartConfigs, widget, dispatch, widgetId, page, prevState) => {
	const finalData = ( page && page !== 0 && response?.data && response?.data?.content?.length )
		? {
			...response.data,
			content: prevState?.content.concat(response.data.content),
		}
		: response.data?.content?.length ? response.data : {};

    updateState(
		id,
		finalData,
		widget?.id
	);
};

export const headCells = [
	{
		id: "departureDate",
		numeric: false,
		sortable: true,
		label: "Date & Time of Departure"
	},
	{
		id: "arrivalDate",
		numeric: false,
		sortable: true,
		label: "Date & Time of Arrival"
	},
	{
		id: "departure",
		numeric: false,
		sortable: true,
		label: "Departure"
	},
	{
		id: "arrival",
		numeric: false,
		sortable: true,
		label: "Arrival"
	},
	{
		id: "totalFuelConsumption",
		numeric: true,
		sortable: false,
		label: "Total Fuel Consumption (mt)"
	},
	{
		id: "totalCOEmissions",
		numeric: true,
		sortable: false,
		label: "Total CO2 Emissions (mt)"
	},
	{
		id: "milesTraveled",
		numeric: true,
		sortable: false,
		label: "Miles Traveled"
	},
	{
		id: "legCii",
		numeric: true,
		sortable: false,
		label: "Leg CII"
	},
	{
		id: "ytdCii",
		numeric: true,
		sortable: false,
		label: "YTD CII After Leg"
	},
	{
		id: "dCii",
		numeric: false,
		sortable: false,
		info:"ΔCII (%) is the percentage difference between the \"YTD CII After Leg\" and \"YTD CII Before Leg\"",
		label: "ΔCII (%)"
	}
];
