import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux'; 
import service from 'common/js/service';
import SessionStorage from 'common/js/Storage';
import {Urls} from 'common/js/urls';
import { vesselGroupUtils, vesselUtils } from 'common/store/storeUtils';

const _product = 'CASSANDRA';
let fetchedData = {};

const StoreInitiate = props => {
  // store vars
  const dispatch = useDispatch();

  const [fetchedCompanyData, setFetchedCompanyData] = useState(false);

  const getVesselsData = async () => {
    const res = await service.getCompanyVesselsGroups(_product);
    setFetchedCompanyData(true);
    fetchedData = res.data;
  }

  useEffect(() => {
    const user = SessionStorage.getCookie('user');
    const token = SessionStorage.getCookie('token');
    const userId = SessionStorage.getCookie('userId');
    const expiredDate = SessionStorage.getCookie('expiredDate');
    const userTerms = SessionStorage.getCookie('userTerms');

    //if user not logged in redirect to login
    if( !user || !token || !userId || !SessionStorage.isTokenValid(expiredDate) ) {
      SessionStorage.clearSession();
      SessionStorage.addCookie('product', _product);
      return window.location.replace(Urls.ServicesUrl().redirectToAuthURL);
    }

    //if user set a url different from his domain, redirect him to his domain url
    if( !window.location.href.includes('localhost') && !window.location.href.includes(Urls.ServicesUrl().BaseURL) )
      return window.location.replace(Urls.ServicesUrl().BaseURL);

    //add the product on cookies so login page and selectProduct page know from which product user redirected
    SessionStorage.addCookie('product', _product);

    //if user has not this product on his product list then redirect him on selection page
    if(!user.products.find(product => product.name === _product)) return window.location.replace(Urls.ServicesUrl().SelectProduct);

    //set terms
    if(userTerms) dispatch({ type: 'terms/setTerms', payload: {key: 'tac', value: userTerms}});

    // set the user data based on what we received inside the 'user' cookie
    dispatch({ type: 'user/setUser', payload: user });

    getVesselsData();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!fetchedCompanyData) return;

    setFetchedCompanyData(false);

    // Initialize vesselGroup store
    const vesselGroupsObj = vesselGroupUtils.buildVesselGroupObject(dispatch, fetchedData);

    vesselGroupUtils.initialize(
      dispatch,
      vesselGroupsObj
    );

    // Initialize vessel store
    const vesselsObj = vesselUtils.buildVesselsObject(fetchedData);
    vesselUtils.initialize(dispatch, vesselsObj);

    props.setInitApp(true);

    // eslint-disable-next-line
  }, [fetchedCompanyData]);

  return (<></>)
}

export default StoreInitiate;