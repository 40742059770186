import React from 'react';
import SimpleInfoTable from "components/table/simpleInfoTable/simpleInfoTable";
import {hullInfoNamesMapper} from "widgets/vesselData/hullInfoTable";

const HullInfo = (props) => {
    return(
        <div>
            {props.data !== "" && (
                <SimpleInfoTable tableInfo={props.data} unit='m' tableInfoNamesMapper={hullInfoNamesMapper} /> 
            )}
        </div>
    )
}
 
export default HullInfo;