import React, {useState} from 'react';
import NavigationTabs from "components/navigationTabs/navigationTabs";
import SyncedChartWithTabNavigation from "components/syncedChartWithTabNavigation/syncedChartWithTabNavigation.jsx";
import { useEffect } from 'react';

const GeneratorEngineDetails = (props) => {
    const [currentTab, setCurrentTab] = useState(props?.engineTabs[0]?.name);
    const [currentSubIdNumber, setCurrentSubIdNumber] = useState(1);

    const updateCurrentTab = tab => {
        setCurrentTab(tab);
    }

    useEffect(() => {
        if(currentTab) setCurrentSubIdNumber(currentTab.match(/\d+/)[0]);
    }, [currentTab]);

    return( 
        <div className={`syncedChartWithTabNavigation ${props.className}`}>
            <div className="geDetails">
                <NavigationTabs 
                    classes={{
                        mainClass: 'secondary-navigation-tabs',
                        extraClasses: 'd-flex align-items-center'
                    }}
                    tabs={props.engineTabs} 
                    currentTab={currentTab} 
                    updateCurrentTab={updateCurrentTab} 
                />
            </div>
            <SyncedChartWithTabNavigation {...props} currentSubIdNumber={currentSubIdNumber} />
        </div>
    )
}

export default GeneratorEngineDetails;