import React, {useEffect, useState} from 'react';
import SyncCharts from "../charts/genericSyncCharts/syncCharts";
import syncChartsOptions from "../charts/genericSyncCharts/syncChartsOptions";
import NavigationTabs from "../navigationTabs/navigationTabs";
import {monitoringStore, vesselUtils} from 'common/store/storeUtils';
import moment from 'moment';
import { downloadChartInCSVform, downloadChartInXLSXform } from "Utilities/highchartsUtils";
import { useSelector } from 'react-redux';

const SyncedChartWithTabNavigation = props => {
    const [syncCharts, setSyncCharts] = useState(syncChartsOptions());
    const [currentTab, setCurrentTab] = useState(props.tabs[0]?.name);
    const updateCurrentTab = tab => {
        setCurrentTab(tab);
    }
    const vesselStoreJSX = useSelector(state => state.vessel);

    useEffect(() => {
        if (!props.data) return;
        let options = syncChartsOptions();

        options["series"] = props.chartSeries[currentTab];
        options["yAxis"] = props.chartAxes[currentTab];
        options.chart.height = props.chartHeight;
        options.title = props?.title || '';
        options.elementId = props.elementId || `${moment().valueOf()}`;
        if(props.downloadIconY) options.exporting.buttons.contextButton.y = props.downloadIconY;
        if(options?.exporting?.chartOptions) {
            options.exporting.chartOptions.series = props.chartSeries[currentTab];
            options.exporting.chartOptions.yAxis = props.chartAxes[currentTab];
        }

        // we customize the highcharts.js export for main-engine-general, pistons-details, turbo-chargers-details widgets 
        if(["main-engine-general", "pistons-details", "turbo-chargers-details"].includes(props.elementId)) {
            // checking if we are on dashboards or in the mainpage
            const vesselId = props.widget?.vesselIds[0] ? props.widget?.vesselIds[0] : vesselStoreJSX.selectedVessel; 

            const tabName = currentTab === 'lOil' ? 'LUBE OIL' : currentTab === 'fOil' ? 'FUEL OIL' : currentTab;                            
            options.exporting.filename = `${vesselUtils.getVesselName(vesselId)}-${tabName.toUpperCase()}`

            options.exporting.buttons.contextButton = {
                menuItems: [
                    "download",
                    "customSeparator",
                    {
                        text: "XLSX",
                        onclick: function () {
                            let name = this.options.exporting.filename;
                            let sheetsName = name

                            if (sheetsName.length > 30) {
                                sheetsName = `${tabName.toUpperCase()}`
                            }

                            const rows = this.getDataRows(true);
                            const newRows = rows.map(row => row.map((value, index) =>
                                 index === 0 ? moment.utc(value).format('DD-MM-YYYY HH:mm:ss') : value))
        
    
                            let updatedows = [
                                props.headers[tabName.toUpperCase()],
                                ...newRows.slice(1, newRows.length)
                            ];
                            // remove special characters before the download function
                            // eslint-disable-next-line no-useless-escape
                            name = name.replace(/[:\/\\\?\*\[\]]/g, "");
                            name = name.replace(/\./g, "");
                            // eslint-disable-next-line no-useless-escape
                            sheetsName = sheetsName.replace(/[:\/\\\?\*\[\]]/g, "");
                            downloadChartInXLSXform({
                                xlsxRows: updatedows,
                                sheetsName: sheetsName,
                                name: name
                            });
                        }
                    },
                    {
                        text: "CSV",
                        onclick: function () {
                            let name = this.options.exporting.filename;
                            // eslint-disable-next-line no-useless-escape
                            name = name.replace(/[:\/\\\?\*\[\]]/g, "");
                            name = name.replace(/\./g, "");
    
                            const rows = this.getDataRows(true);
                            const newRows = rows.map(row => row.map((value, index) =>
                                index === 0 ? moment.utc(value).format('DD-MM-YYYY HH:mm:ss') : value))

                            let updatedows = [
                                props.headers[tabName.toUpperCase()],
                                ...newRows.slice(1, newRows.length)
                            ];
    
                            const csvContent = updatedows
                                .map((row) => row?.join(","))?.join("\n");
    
                            downloadChartInCSVform({ csvContent, name: name });
                        }
                    },
                    "downloadPDF",
                    "downloadPNG",
                    "downloadJPEG",
                    "downloadSVG"
                ]
            };
        } else options.exporting.filename = props?.exportingChartTitle;

        options.exporting.chartOptions.title.text = `${props?.tabs?.find((tab) => tab.name === currentTab)?.title} details`;
        options.xAxis.plotLines =  props.plotLines;
        if(props.multiplePlotLines) options.xAxis.plotLines =  props.multiplePlotLines[currentTab] || null;

        if (props.divideBySubId) {

            // Metrics must be compared with a suffix of no1, no2 etc.. SubID is used to differentiate.
            // This way we are sure that data from correct pagination is displayed each time
            const subIdNumber = props.currentSubIdNumber ? props.currentSubIdNumber : props.tabsWithoutNumbers ? currentTab : (currentTab && parseInt(currentTab.match(/\d+/)[0])),
                _dataObj = JSON.parse(JSON.stringify(props.data));

            props.navigationMapper && props.navigationMapper[currentTab] && props.navigationMapper[currentTab].forEach(category => {
                props.chartSeries[currentTab].forEach((serie, index) => {
                    if (category === serie.id) options.series[index].data = _dataObj[`${category}No${subIdNumber}`] || [];
                })
            })

        } else {

            // Metrics already are counted with no1, no2 etc.. No conversion needs to be made to match with axis
            props.navigationMapper && props.navigationMapper[currentTab] && props.navigationMapper[currentTab].forEach(category => {
                props.chartSeries[currentTab].forEach((serie, index) => {
                    if (category === serie.id) options.series[index].data = props.data[category] || [];
                })
            })
        }

        if (monitoringStore().detailedData?.status && props.plotLinesSetter) {
            let plotGuides = props.plotLinesSetter(props.data);
            if (plotGuides?.xAxis) options.xAxis = Object.assign({}, options.xAxis, plotGuides.xAxis);
            if(props.multiplePlotLines && props.multiplePlotLines[currentTab]) options.xAxis.plotLines =  [...options.xAxis.plotLines, ...props.multiplePlotLines[currentTab]];
        }

        setSyncCharts(options);

        // eslint-disable-next-line
    }, [currentTab, props.currentSubIdNumber, props.data])


    return (
        <div className={`syncedChartWithTabNavigation ${props?.className || moment().valueOf()}`}>
            <NavigationTabs 
                classes={{
                    mainClass: 'secondary-navigation-tabs',
                    extraClasses: 'd-flex align-items-center'
                }}
                {...props} 
                currentTab={currentTab} 
                updateCurrentTab={updateCurrentTab}
            />
            <SyncCharts options={syncCharts} />
        </div>
    );
}
export default SyncedChartWithTabNavigation;
