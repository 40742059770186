import React from "react";
import { withRouter } from 'react-router-dom';
import AlertsBell from './alertsBell';
import FleetsTopBar from 'components/fleetVesselsTopBar/fleetVesselsTopBar';
import BurgerMenuIcon from './burgerMenuIcon';
import DropDown from "components/core/DropDown";
import {ReactComponent as ProfileIcon} from 'assets/img/app/topbar-imgs/icon-profile.svg';
import {ReactComponent as LogoutIcon} from 'assets/img/app/topbar-imgs/icon-sign-out.svg';
import {ReactComponent as LogoImage} from "assets/img/app/deepsea-logo.svg";
import {useSelector, useDispatch} from 'react-redux'; 
import {userUtils} from 'common/store/storeUtils';

const DsNavbar = ({ history }) => {

    // store vars for JSX
    const userStoreJSX = useSelector(state => state.user);
    const dispatch = useDispatch();

    const toggleSidebar = () => {
        document.querySelectorAll('.sidebar, .ds-navbar__burgers__btn').forEach(el => el.classList.toggle('open'));
    }

    const returnHome = () => {
        window.location.valueOf().pathname = '/';
    }

    return (
        <React.Fragment>
            <div className="ds-navbar">
                <div className="ds-navbar__container flex-space-between">
                    <div className="ds-navbar__container__left flex-centered-start">
                        <BurgerMenuIcon toggleSidebar={toggleSidebar} />

                        <div className="ds-navbar__logo-wrapper flex-centered-start">
                            <div className="ds-navbar__logo flex-centered" onClick={returnHome}>
                                <LogoImage className="svg-g-path" />
                            </div>
                         </div>

                        <div className="ds-navbar__name flex-centered-start">
                            <div className="headline"> <span className="ds-navbar__name__divider"> | </span> Cassandra</div>
                        </div>
                    </div>

                    <div className="ds-navbar__container__right flex-centered-end">

                        <AlertsBell user={userStoreJSX.user} />

                        <div className="ds-navbar__container__right__user-menu-dropdown">
                            <DropDown buttonLabel={`${userStoreJSX.user?.lastName?.charAt(0)}${userStoreJSX.user?.firstName?.charAt(0)}`} showCaret={false}
                                options={[
                                    {label: "Profile Settings", onClick: () => {history.push("/settings")}, className:"profile-ico svg-path", image: ProfileIcon},
                                    {label: "Sign Out", onClick: () => {userUtils.logout({enableLoader: false})}, className:"signout-ico svg-path", id:"logoutBtn", image: LogoutIcon},
                                    {label: "Terms & Conditions", onClick: () => dispatch({type: 'terms/setTerms', payload: {key: 'show_modal', value: true}}), className:"terms"}
                                ]}
                                classNames={{button:"ds-navbar__container__right__user-menu headline3", menu:"hasArrow"}}/>
                        </div>
                    </div>
                </div>
            </div>

            <FleetsTopBar />

        </React.Fragment>
    );
}

export default withRouter(DsNavbar);
