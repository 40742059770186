import service from 'common/js/service';
import moment from "moment";

const termsUtils = {
    termsFlow: async (dispatch, hasReceivedTerms) => {
        if (!hasReceivedTerms) {
            dispatch({type: 'terms/setTerms', payload: {key: 'has_terms_server_error', value: false}});
            dispatch({type: "terms/setTerms", payload: {type: 'has_current_terms_error', value: false}});
            dispatch({type: "terms/setTerms", payload: {key: 'has_new_terms_error', value: false}});
            dispatch({type: "terms/setTerms", payload: {key: 'terms_response', value: false}});

            const response = await Promise.all([termsUtils.getCurrentTerms(dispatch), termsUtils.getFutureTerms(dispatch)]);
            
            if (response[0] && !response[0].error) dispatch({type: "terms/setTerms", payload: {key: 'has_received_terms'}});

            dispatch({type: "terms/setTerms", payload: {key: 'terms_response', value: true}});
        }
    },
    getCurrentTerms: async (dispatch) => {
        const r = await service.getCurrentTerms();

        if(r === undefined) return;

        if (r && !r.error) {
            let data = [];

            try {
                data = JSON.parse(r.content);
            } catch (e) {
                dispatch({type: "terms/setTerms", payload: {key: 'has_current_terms_error', value: 'Something went wrong!'}});
            }
            dispatch({type: "terms/setTerms", payload: {key: 'set_current_terms', value: {date: moment(r.effectiveDateFrom).valueOf(), data}}});
        } else {
            dispatch({type: "terms/setTerms", payload: {key: 'has_terms_server_error', value: 'n error has occured and the content is not available right now. Please try again after some time.'}})
        }

        return r;
    },
    getFutureTerms: async (dispatch) => {
        const r = await service.getFutureTerms();

        if(r === undefined) return;

        if (r && !r.error) {
            let data = [];

            try {
                data = JSON.parse(r.data.content);
            } catch (e) {
                dispatch({type: "terms/setTerms", payload: {key: 'has_new_terms_error', value: 'Something went wrong!'}});
            }
            if(r.data) {
                dispatch({type: "terms/setTerms", payload: {key: 'has_new_terms', value: true}});
                dispatch({type: "terms/setTerms", payload: {key: 'set_future_terms', value: {date: moment(r.data.effectiveDateFrom).valueOf(), data}}});
            }
        } else {
            dispatch({type: "terms/setTerms", payload: {key: 'has_terms_server_error', value: 'Αn error has occured and the content is not available right now. Please try again after some time.'}})
        }

        return r;
    }
}

export default termsUtils;