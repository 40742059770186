import React, {useState, useEffect} from 'react';
import moment from 'moment';
import Storage from "common/js/Storage";
import {ReactComponent as TermsImage} from "assets/img/app/global-imgs/terms.svg";
import {ReactComponent as CloseIcon} from 'assets/img/app/global-imgs/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import {termsStore, userStore, userUtils} from 'common/store/storeUtils';

const TermsBanner = () => {
    const [bannerDisplay, setBannerDisplay] = useState(false);

    // vars for JSX
    const termsStoreJSX = useSelector(state => state.terms);

    const dispatch = useDispatch();

    useEffect(() => {
        //if terms are not accepted and we have new terms effective date that is higher than today, then we send an auto accept request
        const token = Storage.getCookie('token');
        const userUuid = userStore().user?.uuid;
        if(!termsStore().tac?.accepted && termsStore().tac?.effectiveDate && termsStore().tac?.effectiveDate <= moment.utc().valueOf()) userUtils.acceptTerms(userUuid, token, true);

        setBannerDisplay(!termsStore().tac?.accepted);
    // eslint-disable-next-line
    }, [termsStoreJSX.tac]);

    const acceptTerms = async () => {
        const newTerms = await userUtils.acceptTerms(userStore().user?.uuid, Storage.getCookie('token'), true);
        dispatch({type: 'terms/setTerms', payload: {key: 'tac', value: newTerms}});
    };

    const renderFirstPart = () => {
        return (
            <div className="flex-centered-end">
                <div className="terms-img">
                    <TermsImage className="svg-path" />
                </div>
                <div>
                    <div className="terms-banner-title" onClick={() => dispatch({type: 'terms/setTerms', payload: {key: 'show_modal', value: true}})}>
                        Updated Terms & Conditions
                    </div>
                    <div className="terms-subHeader">
                        Effective {moment(termsStoreJSX.tac?.effectiveDate).utc().format('MMMM DD, YYYY')}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <> { (bannerDisplay && termsStoreJSX.tac?.effectiveDate) &&
            <div className="terms-banner-wrapper">
                <div className="flex-centered full-height">
                    {/* If we have */}
                    { termsStoreJSX.tac?.effectiveDate > moment.utc().valueOf()
                        // new Terms available
                        ? <React.Fragment>
                            <div className="terms-banner-first-section flex2">
                                {renderFirstPart()}
                            </div>
                            <div className="terms-banner-second-section flex2 terms-text">
                                By accepting, you agree to the updated terms and conditions. Please take a few minutes to read and understand them.
                            </div>
                            <div className="flex1">
                                <button type="btn" className="main-cta" onClick={acceptTerms}>
                                    <div className="main-cta__text">Accept</div>
                                </button>
                            </div>
                        </React.Fragment>
                        // autoAccepted
                        : <React.Fragment>
                            <div className="terms-banner-first-section flex1">
                                {renderFirstPart()}
                            </div>
                            <div className="terms-banner-second-section flex1 terms-text">
                                By using this platform, you have agreed to this use.
                            </div>
                            <div className="close-button" onClick={() => setBannerDisplay(false)}>        
                                <div className="close-button__img">
                                    <CloseIcon className="svg-defs-path-g-maskUse"/>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        } </>
    );
};

export default TermsBanner;