import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import MapComponent from 'components/map/map';
import Layout from 'components/core/Layout';
import AlertsPayloads from 'components/alerts/alertsPayloads';
import WeatherMonitoring from 'components/alerts/weatherMonitoring';
import ReportsService from "common/js/reports";
import { weatherMonitoringPayload, setWeatherMon } from 'components/alerts/weatherMonitoringUtils';
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import { vesselStore } from 'common/store/storeUtils';
import service from 'common/js/service';
import { useDispatch } from 'react-redux';

const SevereWindAlert = props => {
    const reportsService = new ReportsService();
    const [widgets, setWidgets] = useState({ layout: [] });
    const [weatherMonitoringData, setWeatherMonitoringData] = useState({});
    const [anomaliesData, setAnomaliesData] = useState({});
    const [requestOnGoing, setRequestOnGoing] = useState({
        weatherMonitoring: false
    });

    // store vars
    const dispatch = useDispatch();

    const updateRequestOnGoing = (key, value) => {
        setRequestOnGoing((currentRequestOnGoing) => {return {...currentRequestOnGoing, [key]: value}});
    }

    const registrationWidgets = [
        {widgetId: 'weatherMonitoring', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: false}, payload: weatherMonitoringPayload, type: 'basicChart'},
    ];

    const stateMapper = (key, value) => {
        const mapper = {
            weatherMonitoring: setWeatherMonitoringData,
        }
        return mapper[key](value);
    }

    const reportsResponseMapper = (response, key) => {
        const mapper = {
            weatherMonitoring: setWeatherMon,
        }
        return mapper[key](key, response, stateMapper);
    }

    const register = selectedVessel => {
        reportsService.registerReports([selectedVessel], registrationWidgets, reportsResponseMapper, updateRequestOnGoing, true);
    }

    const getWidgetsPreferences = () => {
        return {
            className: 'padding-left-right-7',
            layout: [{
                columns: [{
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                    components: [
                        { id: 'route', title: "ROUTE", type: 'map', component: MapComponent, data: {}, style: { height: '408px' }, props: {} }
                    ]
                }],
            }, {
                columns: [{
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                    components: [
                        { id: 'weatherMonitoring', title: "WEATHER MONITORING", type: 'lineChart', component: WeatherMonitoring, data: {}, style: { height: '620px' }, props: { } }
                    ]
                }]
            }]
        };
    };

    const initAlertsPage = (activeAlert, firstTime) => {
        const tmpAlert = activeAlert;
        AlertsPayloads.sensor.fromDate = tmpAlert.startingDate;
        AlertsPayloads.sensor.toDate = tmpAlert.endingDate;
        AlertsPayloads.sensor.vessels = [tmpAlert.vesselId];

        // + alert type
        if (!firstTime) service.getAnomaliesForMapBullets({
            vesselId: tmpAlert.vesselId,
            from: tmpAlert.startingDate,
            to: tmpAlert.endingDate,
            granularity: 30,
            timeUnit: 'minute',
            type: tmpAlert.alertType
        }).then(result => {
            dispatch({ type: 'alerts/setAlertMapBulletsAnomalies', payload: result.data });
        });

        service.getAlertAnomalies({
            from: tmpAlert.startingDate,
            to: tmpAlert.endingDate,
            vesselId: tmpAlert.vesselId,
            type: tmpAlert.alertType,
            timeUnit: 'hour'
        }).then(anomalies => {
            setAnomaliesData(anomalies.data);
        });

        registerWidgetsStore.setFromTo({
            from: tmpAlert.startingDate, 
            to: tmpAlert.endingDate
        });

        register(tmpAlert.vesselId);
    }

    const alertsCalls = (activeAlertTemp) => {
        service.getAnomaliesForMapBullets({
            vesselId: activeAlertTemp.vesselId,
            from: activeAlertTemp.startingDate,
            to: activeAlertTemp.endingDate,
            granularity: 30,
            type: activeAlertTemp.alertType,
            timeUnit: 'minute'
        }).then(result => {
            dispatch({ type: 'alerts/setAlertMapBulletsAnomalies', payload: result.data });
        });

        setWidgets(getWidgetsPreferences());

        initAlertsPage(activeAlertTemp, true);
    }

    //useEffect here------------------------------------------

    useEffect(() => {
        if(props.activeAlert?.id) alertsCalls(props.activeAlert);
    // eslint-disable-next-line
    }, [props.activeAlert]);

    //-------------------------------------------------------------

    const data = {
        route: {
            reports: true,
            alerts: true,
        },
        weatherMonitoring: {
            chartData: weatherMonitoringData,
            anomaliesData: anomaliesData,
            allData: {
                chartData: weatherMonitoringData,
                anomaliesData: anomaliesData,
            },
            loading: requestOnGoing.weatherMonitoring,
        },
    }

    const createLayoutProps = (data, props = widgets) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
                    ('id' in newComponent) && !('element' in newComponent) && (newComponent.id in data) && (newComponent.props = data[newComponent.id]);
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    }

    return (
        <Layout {...createLayoutProps(data)} />
    )
}

export default withRouter(SevereWindAlert);
