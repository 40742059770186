import React, { useState, useEffect } from 'react';
import Layout from 'components/core/Layout';
import ReportsService from "common/js/reports";
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import { monitoringStore, vesselStore, monitoringUtils } from 'common/store/storeUtils';
import GlobalUtilities from 'Utilities/global';
import service from 'common/js/service';
import { useSelector } from 'react-redux';
import moment from 'moment';
import MapComponent from 'components/map/map';
import ciiGroupEfficiency from 'widgets/vesselData/ciiEfficiencyChart';
import { ciiEfficiencyUpdate } from 'widgets/vesselData/ciiEfficiency';
import {navigationStatusChartUpdate} from 'widgets/vesselData/ciiCompanyOvervUtils';
import PieChart from 'components/charts/pieChart/pieChart';
import GroupOverviewFooter from 'components/footers/staticFooters/ciiPieChartOverviewFooter';
import { navigationStatusChartUtils } from 'widgets/vesselData/navigationChart'; 
import CiiVesselsOverview from 'widgets/vesselData/ciiGroupOverview';
import { ciiGroupOverviewUtils as ciiVesselsOverviewUtils  } from 'widgets/vesselData/ciiGroupOverviewUtils';
import WidgetTitleWrapper from 'components/widgetPanel/widgetTitleWrapper';

let isMounted = false;   
let thisYear = moment(GlobalUtilities.nowOrDemo()).year();
const defaultEfficiencyGranularity = 'MONTH';

const CompanyMonitoring = ({ overallReportsFromToChanged, overallReportsPeriod }) => {
    const reportsService = new ReportsService();

    const [ciiGroupEfficiencyData, setCiiGroupEfficiencyData] = useState([]);
    const [ciiGroupOverview, setCiiGroupOverview] = useState([]);
    const [ciiVesselsOverview, setCiiVesselsOverview] = useState([]);

    const [requestOnGoing, setRequestOnGoing] = useState({
        ciiGroupEfficiency: false,
        ciiGroupOverview: false,
        ciiVesselsOverview: false
    });
    
    // store vars
    const vesselStoreJSX = useSelector(state => state.vessel);
    const vesselGroupStoreJSX = useSelector(state => state.vesselGroup);
    // this is the last year that the is been applied from the picker
    const selectedPickerYear = useSelector(state => state.monitoring.ciiSearchingYear.selectedPickerYear);
    
    const reportsComponents = [
        {
            widgetId: 'ciiGroupOverview',
            inDashboard: false,
            singleRequest: {
                value: true,
                externalURequest: {
                    value: true,
                    request: () => {
                        return { 
                            method: service.getCiiFleetOverview, 
                            params: { 
                                from : moment(`01/01/${selectedPickerYear}`,'DD/MM/YYYY').utc(true).valueOf(), 
                                to:thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(),
                                groupID: vesselGroupStoreJSX.selectedVesselGroup 
                            } 
                        }
                    }
                },
                overwriteHeader: 'get-vessel-class'
            },
            payload: '',
            type: 'text'
        },
        {
            widgetId: 'ciiGroupEfficiency',
            inDashboard: false,
            singleRequest: {
                value: true,
                externalURequest: {
                    value: true, request: () => {
                        return { method: service.getGroupEfficiency, 
                            params: { 
                                from : moment(`01/01/${selectedPickerYear}`,'DD/MM/YYYY').utc(true).valueOf(), 
                                to: thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(),
                                groupID : vesselGroupStoreJSX.selectedVesselGroup,
                                granularity : defaultEfficiencyGranularity
                            }
                        }
                    }
                },
            },
            payload: '',
            type: ''
        },
        {
            widgetId: 'ciiVesselsOverview',
            inDashboard: false,
            singleRequest: {
                value: true,
                externalURequest: {
                    value: true,
                    request: () => {
                        return { 
                            method: service.getCiiGroupOverview, 
                            params: { 
                                from : moment(`01/01/${selectedPickerYear}`,'DD/MM/YYYY').utc(true).valueOf(), 
                                to:thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(), 
                                groupID: vesselGroupStoreJSX.selectedVesselGroup
                            } 
                        }
                    }
                },
                overwriteHeader: 'get-vessel-class'
            },
            payload: '',
            type: 'text'
        },
    ];

    const getTitlesFromWidgetIds = (widgetId) => {
        const idsTitlesMapper = {
            ciiGroupOverview: 'CII GROUP OVERVIEW',
            ciiGroupEfficiency: 'CII GROUP EFFICIENCY',
            ciiVesselsOverview: 'CII VESSELS OVERVIEW',
        }
        
        return idsTitlesMapper[widgetId];
    }

    const getWidgetsPreferences = () => {
        return {
            className: 'padding-left-right-7 mt-5',
            layout: [{
                columns: [
                    {
                        grid: { xs: 12, sm: 12, md: 12, lg: 4, xl: 4 },
                        components: [
                            { id: 'ciiGroupOverview', title: getTitlesFromWidgetIds('ciiGroupOverview'), component: PieChart, footer: <GroupOverviewFooter />, data: {}, style: { height: '463px' }, props: { removeFromLayout: ciiGroupOverview === ""} }
                        ]
                    },
                    {
                        grid: { xs: 12, sm: 12, md: 12, lg: 8, xl: 8 },
                        components: [
                            { id: 'ciiGroupEfficiency', title: getTitlesFromWidgetIds('ciiGroupEfficiency'), component: ciiGroupEfficiency, data: {}, style: { height: '463px' }, props: { removeFromLayout: ciiGroupEfficiencyData === ""} }
                        ]
                    },
                    {
                        grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                        components: [
                            { id: 'ciiVesselsOverview', title: getTitlesFromWidgetIds('ciiVesselsOverview'), component: CiiVesselsOverview, data: {}, style: { height: '463px' }, props: { removeFromLayout: ciiVesselsOverview === ""} }
                        ]
                    }
                ]
            }]
        };
    };

    const createLayoutProps = (data, props = getWidgetsPreferences()) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
                    if (('id' in newComponent) && !('element' in newComponent)) {
                        if (newComponent.id in data) newComponent.props = data[newComponent.id];
                    }
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    };

    const updateRequestOnGoing = (key, value) => {
        setRequestOnGoing((currenRequestOnGoing) => { return {...currenRequestOnGoing, [key]: value}});
    }
    
    const reportsResponseMapper = (data, widgetId) => {
        const mapper = {
            ciiGroupOverview: navigationStatusChartUpdate,
            ciiGroupEfficiency: ciiEfficiencyUpdate,
            ciiVesselsOverview: ciiVesselsOverviewUtils
        }
        return mapper[widgetId](
			widgetId,
			data,
			updateStateMapper,
			null,
			reportsComponents.filter(
				(report) => report.widgetId === widgetId
			)[0],
			widgetId === "ciiGroupOverview" ? { navigationStatusChartUtils } : { selectedPickerYear }
		);
    }

    const updateStateMapper = (key, value) => {
        const updateMapper = {
            ciiGroupOverview: setCiiGroupOverview,
            ciiGroupEfficiency: setCiiGroupEfficiencyData,
            ciiVesselsOverview: setCiiVesselsOverview,
        }
        return updateMapper[key](value);
    }

    const initCiiOverall = () => {
        registerWidgetsStore.setFromTo(monitoringStore().fromTo);
        // if overview storage has saved data, then use them and don't make new requests to get the data
        reportsService.registerReports([vesselStore().fleetVessels], reportsComponents, reportsResponseMapper, updateRequestOnGoing, true);
    }

    useEffect(() => {
        if (!isMounted) return;

        initCiiOverall();

        // eslint-disable-next-line
    }, [vesselGroupStoreJSX.selectedVesselGroup]);
    
    useEffect(() => {
        if (!isMounted) return;
        if (!overallReportsFromToChanged) return;
        if (!overallReportsPeriod?.from || !overallReportsPeriod?.to) return;
        
        return initCiiOverall();
        
        // eslint-disable-next-line
    }, [overallReportsFromToChanged]);
    
    useEffect(() => {
        isMounted = true;

        const controller = new AbortController();
        GlobalUtilities.signal = controller.signal;
        
        if (!overallReportsPeriod?.from || !overallReportsPeriod?.to) return;
        
        initCiiOverall();
        
        return () => {
            controller.abort();
            isMounted = false;
            GlobalUtilities.signal = null;
        }
        // eslint-disable-next-line
    }, []);

    const data = {
        ciiGroupOverview: {
            data: ciiGroupOverview,
            allowTelegrams: monitoringUtils.allowTelegrams(),
            genericChartOptions: navigationStatusChartUtils,
            progressChartOptions: navigationStatusChartUtils.progressChartOptions(),
            loading: requestOnGoing.ciiGroupOverview,
            fleetVessels: vesselStoreJSX.allVessels.length,
            ...ciiGroupOverview
        },
        ciiGroupEfficiency: {
            options: ciiGroupEfficiencyData,
            noStack: true,
            loading: requestOnGoing.ciiGroupEfficiency,
            defaultInputProps: {
                options: [
                    { label: "Monthly", value: "MONTH" },
                    { label: "Quarterly", value: "QUARTER" }
                ],
                defaultActive: 'MONTH'
            },
            innerRequest: async selectedSegment => {
                return service.getGroupEfficiency({
                    granularity: selectedSegment,
                    groupID: vesselGroupStoreJSX.selectedVesselGroup,
                    from: moment(`01/01/${selectedPickerYear}`,"DD/MM/YYYY").utc(true).valueOf(),
                    to: thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf(),
                });
            },
            innerUpdate: (response, selectedSegment, setInnerUpdate, innerUpdate) => ciiEfficiencyUpdate(
                'ciiGroupEfficiency', 
                response, 
                null,
                setInnerUpdate,
                innerUpdate,
                { 
                    selectedPickerYear,
                    granularity: selectedSegment
                }
            )
        },
        ciiVesselsOverview: {
            data: ciiVesselsOverview,
            allowTelegrams: monitoringUtils.allowTelegrams(),
            genericChartOptions: navigationStatusChartUtils,
            progressChartOptions: navigationStatusChartUtils.progressChartOptions(),
            loading: requestOnGoing.ciiVesselsOverview,
        },
    }

    return (
        <>
            <div className="cii-vessel-monitoring main-content-padding pad-t-0">
                <WidgetTitleWrapper title="GROUP'S VESSELS LATEST POSITION">
                    <MapComponent 
                        timestamp={thisYear === selectedPickerYear ? GlobalUtilities.nowOrDemo() : moment(`31/12/${selectedPickerYear}`,'DD/MM/YYYY').endOf('year').utc(true).valueOf()} 
                        overallReportsFromToChanged={overallReportsFromToChanged}
                    />
                </WidgetTitleWrapper>                
                <div className="cii-vessel-monitoring__widgets">
                    <Layout {...createLayoutProps(data)} />
                </div>
            </div>
        </>
    );
}

export default CompanyMonitoring;