import moment from "moment";
import { ExportToCsv } from "export-to-csv";

const compareDates = (a, b, order) => {
	const aTimestamp = moment(a, "DD/MM/YYYY HH:mm").valueOf();
	const bTimestamp = moment(b, "DD/MM/YYYY HH:mm").valueOf();

	if (order === "asc") {
		return aTimestamp > bTimestamp ? -1 : 1;
	} else {
		return aTimestamp < bTimestamp ? -1 : 1;
	}
};

export const stableSort = (array, id, order, tableParam) => {
	if (!array?.length) return;
	if (id === "departureDate" || id === "arrivalDate") {
		return array.sort((a, b) => compareDates(a[id], b[id], order));
	}
	if (id === "vesselDetails") {
		return array.sort((a, b) => {
			return order === "asc"
				? a[id]?.name > b[id]?.name
					? -1
					: 1
				: a[id]?.name < b[id]?.name
				? -1
				: 1;
		});
	}
	if (id.includes("requiredCiiOneYearLater")) {
		return array.sort((a, b) => {
			return order === "asc"
				? a['requiredCii']?.oneYlater > b['requiredCii']?.oneYlater
					? -1
					: 1
				: a['requiredCii']?.oneYlater < b['requiredCii']?.oneYlater
				? -1
				: 1;
		});
	}
	if (id.includes("requiredCiiTwoYearsLater")) {
		return array.sort((a, b) => {
			return order === "asc"
				? a['requiredCii']?.twoYlater > b['requiredCii']?.twoYlater
					? -1
					: 1
				: a['requiredCii']?.twoYlater < b['requiredCii']?.twoYlater
				? -1
				: 1;
		});
	}
	if (tableParam === "groupOverview") {
		return array.sort((a, b) => {
			return order === "asc"
				? a[id]?.telegrams > b[id]?.telegrams
					? -1
					: 1
				: a[id]?.telegrams < b[id]?.telegrams
				? -1
				: 1;
		});
	} else {
		return array.slice().sort((a, b) => {
			return order === "asc"
				? a[id] > b[id]
					? -1
					: 1
				: a[id] < b[id]
				? -1
				: 1;
		});
	}
};

export const handleExportCSVData = (data, headCells) => {
	const csvOptions = {
		fieldSeparator: ",",
		quoteStrings: '"',
		decimalSeparator: ".",
		showLabels: true,
		useBom: true,
		useKeysAsHeaders: false,
		headers: headCells.map((c) => c.label)
	};
	const csvExporter = new ExportToCsv(csvOptions);

	const newData = data.slice().map((prop) => {
		const entries = Object.entries(prop).map(([key, val]) => {
			return [key, val];
		});
		return Object.fromEntries(entries);
	});
	csvExporter.generateCsv(newData);
};
