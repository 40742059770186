import _ from "lodash";
import {FormElement} from 'components/core/Forms';

const FormElementStore = {
    // formElementStore vars
    initName: '',
    name: '',
    type: '',
    cssClasses: '',
    overwriteClassNames: '',
    onInputChange: '',
    renderAsterisk: '',
    structure: '',
    options: '',
    tile: '',
    validation: '',
    label: '',
    unit: '',
    placeholder: '',
    autoComplete: '',
    canRenderError: '',
    autoFocus: '',
    loading: '',
    portsAutocomplete: '',
    onChange: '',
    displayLabelAtEnd: '',
    separateValidationOnRequiredFields: '',
    formikHelpers: '',
    hidden: '',
    disabled: '',  

    // initialize func
    initFormElementStore: (props, FormElementStore) => {
        FormElementStore.initName = typeof props.name === 'string' ? [props.name] : props.name;
        FormElementStore.name = FormElementStore.generateName(FormElementStore.initName);
        FormElementStore.type = props.type;
        FormElementStore.cssClasses = props.cssClasses;
        FormElementStore.overwriteClassNames = props.overwriteClassNames;
        FormElementStore.onInputChange = props.onInputChange;
        FormElementStore.renderAsterisk = props.renderAsterisk;
        FormElementStore.structure = props.structure;
        FormElementStore.options = props.options;
        FormElementStore.tile = props.tile;
        FormElementStore.validation = props.validation;
        FormElementStore.label = props.label;
        FormElementStore.unit = props.unit;
        FormElementStore.placeholder = props.placeholder;
        FormElementStore.autoComplete = props.autoComplete;
        FormElementStore.canRenderError = props.canRenderError;
        FormElementStore.autoFocus = props.autoFocus;
        FormElementStore.loading = props.loading;
        FormElementStore.portsAutocomplete = props.portsAutocomplete;
        FormElementStore.onChange = props.onChange;
        FormElementStore.displayLabelAtEnd = props.displayLabelAtEnd;
        FormElementStore.separateValidationOnRequiredFields = props.separateValidationOnRequiredFields;

        FormElementStore.formikHelpers = props.formikHelpers;
        FormElementStore.hidden = props.hidden;
        FormElementStore.disabled = props.disabled;  

        return FormElementStore;
    },

    passDownProps: [
        'validation',
        'structure',
        'label',
        'unit',
        'options',
        'placeholder',
        'autoComplete',
        'cssClasses',
        'overwriteClassNames',
        'formChart',
        'withoutBackground',
        'tile',
        'separateValidationOnRequiredFields',
        'viewports',
        'maxItems',
        'canRenderLabel',
        'rules',
        'tableHeader',
        'portsAutocomplete',
        'canRenderError',
        'onChange',
        'customRender',
        'onInputChange',
        'renderOption',
        'autoFocus',
        'loading',
        'displayLabelAtEnd'
    ],
    
    getFormik: (key, initObject, name) => {
        return name.reduce((prevVal, nextVal) => {
            if (key === 'values') {
                return prevVal[nextVal];
            }
            return prevVal && prevVal[nextVal] ? prevVal[nextVal] : false
        }, initObject);
    },
    
    generateName: (arr) => {
        return arr.reduce((prevVal, nextVal) => {
            if (prevVal === '') {
                return nextVal;
            } else if (isNaN(Number(nextVal))) {
                return `${prevVal}.${nextVal}`;
            } else {
                return `${prevVal}[${nextVal}]`;
            }
        }, '');
    },

    getValue: (FormElementStore) => FormElementStore.getFormik('values', FormElementStore.formikHelpers.values, FormElementStore.initName),

    getError: (FormElementStore) => FormElementStore.getFormik('errors', FormElementStore.formikHelpers.errors, FormElementStore.initName),

    isTouched: (FormElementStore) => FormElementStore.getFormik('touched', FormElementStore.formikHelpers.touched, FormElementStore.initName),

    classNames: (FormElementStore) => {
        let classNames = {...FormElementStore.cssClasses};
        
        if (FormElementStore.overwriteClassNames) {
            classNames = _.mergeWith({}, FormElement.defaultProps.cssClasses, FormElementStore.cssClasses, function(a, b) {
                if (typeof a === 'string' && b.indexOf(a) === -1 && a.indexOf(b) === -1) return `${a} ${b}`;
            });
        }
        
        classNames = {/*arrayWrapper:'',*/ wrapper:'', label:'', element:'', error:'', ...classNames};
        
        if (FormElementStore.isDisabled(false, FormElementStore)) classNames.element += ' readonly';

        return classNames;
    },
    
    isDisabled: (name = false, FormElementStore) => {
        name = name === false ? FormElementStore.name : name;
        return FormElementStore.disabled.hasOwnProperty(name) && FormElementStore.disabled[name];
    },

    arrayDefaultProps: (arrayHelpers, index, element, FormElementStore) => {
        const arrayProps = {
            formikHelpers: FormElementStore.formikHelpers,
            arrayHelpers, 
            renderAsterisk: FormElementStore.renderAsterisk,
            hidden: FormElementStore.hidden,
            disabled: FormElementStore.disabled,
            name: [...FormElementStore.initName, index, element],
            type: FormElementStore.structure[element].type,
            parentStructure: FormElementStore.structure,     
            key: `${FormElementStore.name}-${element}`          
        };

        FormElementStore.passDownProps.forEach(val => {
            FormElementStore.structure[element].hasOwnProperty(val) && (arrayProps[val] = FormElementStore.structure[element][val]);
        });
        
        return arrayProps;
    },

    createOptions: (FormElementStore) => {
        let options = [];
        let dependentOptions = [];
        FormElementStore.options.forEach(option => {
            option.hasOwnProperty('options') ? dependentOptions.push(option) : options.push(option);
        });

        if (dependentOptions.length > 0) {
            let extraOptions = [];

            dependentOptions.forEach(option => {
                /**
                 * optionsOperator enum = ['AND', 'OR']
                 */
                const optionsOperator = option.hasOwnProperty('optionsOperator') ? option.optionsOperator : 'AND';
                const condition = Object.keys(option)
                    .filter(key => key !== 'options' && key !== 'optionsOperator')
                    .map(key => {
                        let name = FormElementStore.initName.length === 1 ? [key] : [...FormElementStore.initName.slice(0, FormElementStore.initName.length-1), key];
                        let value = FormElementStore.getFormik('values', FormElementStore.formikHelpers.values, name);
                        if (value === undefined) {
                            name = FormElementStore.initName.length === 1 ? [key] : [...FormElementStore.initName.slice(0, FormElementStore.initName.length-3), key];
                            value = FormElementStore.getFormik('values', FormElementStore.formikHelpers.values, name);
                        }
                        return typeof option[key] === 'function' ? option[key](value) : option[key].indexOf(value) !== -1;
                    })
                    .reduce((prev, next) => {
                        if (prev === null) return next;
                        return optionsOperator === 'AND' ? prev && next : prev || next;
                    }, null);
                if (condition) {
                    extraOptions = [...extraOptions, ...option.options];
                }
            });
            options = [...extraOptions, ...options];
        }

        return options;
    },

    onChangeElement: (name, value, FormElementStore) => {
        FormElementStore.formikHelpers.setFieldValue(name, value);

        const parentArray = FormElementStore.initName.slice(0, FormElementStore.initName.length-1);
        const parentValues = FormElementStore.initName.length === 1 ? {} : FormElementStore.getFormik('values', FormElementStore.formikHelpers.values, parentArray);
        const parentName = FormElementStore.initName.length === 1 ? '' : FormElementStore.generateName(parentArray);

        parentArray.length !== 0 && FormElementStore.formikHelpers.setFieldValue(name, value); 

        FormElementStore.onChange({
            values: FormElementStore.formikHelpers.values,
            value,
            parentValues,
            parentName,
            setFieldValue: FormElementStore.formikHelpers.setFieldValue,
            setFieldTouched: FormElementStore.formikHelpers.setFieldTouched
        });
        
        FormElementStore.formikHelpers.isSubmitting && FormElementStore.formikHelpers.setSubmitting(false);
    },

    defaultInputProps: (FormElementStore) => {
        const classNames = FormElementStore.classNames(FormElementStore);
        const hasError = ((!FormElementStore.separateValidationOnRequiredFields && FormElementStore.validation.hasOwnProperty('requiredFields')) || FormElementStore.isTouched(FormElementStore)) && FormElementStore.getError(FormElementStore);
        
        const props = {
            name: FormElementStore.name,
            id: FormElementStore.name,
            type: FormElementStore.type,
            className: classNames.element,
            placeholder: FormElementStore.placeholder,
            autoComplete: FormElementStore.autoComplete,
            autoFocus: FormElementStore.autoFocus,
            disabled: FormElementStore.isDisabled(false, FormElementStore),
            value: FormElementStore.getValue(FormElementStore),
            // start code for material ui
            wrapperClassName: classNames.wrapper,
            label: `${FormElementStore.renderAsterisk && (FormElementStore.validation.required || FormElementStore.validation.requiredField) ? '* ' : ''}${FormElementStore.label}`,
            unit: FormElementStore.unit,
            error: !!hasError,
            helperText: FormElementStore.canRenderError && hasError,
            onBlur: FormElementStore.formikHelpers.handleBlur,
            // end code for material ui
            onChange: el => { FormElementStore.onChangeElement(FormElementStore.name, el.target.value, FormElementStore) }
        };

        FormElementStore.type === "toggler" && (props.displayLabelAtEnd = FormElementStore.displayLabelAtEnd);
        
        if (FormElementStore.type === "checkbox") {
            props.label = FormElementStore.label;
            props.displayLabelAtEnd = FormElementStore.displayLabelAtEnd ? 'end' : 'start';
        }
        return props;
    }

}

export default FormElementStore;