import Utilities from "Utilities/global";

const totalFuelConsumptionPayload = {
    withMetadata: true,
    metrics: [
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {
                    metricName: "totalFOMassRate_calc", 
                    aggregation: "SUM"
                },
                {
                    metricName: "totalCO2",
                    aggregation: "SUM"
                }
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "totalFOMassRate_calc", 
                    aggregation: "SUM"
                },
                {
                    metricName: "totalCO2",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_hsfo_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lsfo_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_hsdo_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lsdo_cons_calc",
                    aggregation: "SUM"
                },
            ]
        }
    ]
};


const totalFuelConsumptionAllMetricsPayload = {
    withMetadata: true,
    metrics: [
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {
                    metricName: "totalFOMassRate_calc", 
                    aggregation: "SUM"
                },
                {
                    metricName: "commonDGFOMassRate", 
                    aggregation: "SUM"
                },
                {
                    metricName: "totalCO2",
                    aggregation: "SUM"
                },
                {
                    metricName: "boilerCommonFoMassRate", 
                    aggregation: "SUM"
                }
            ]
        },
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {
                    metricName: "foVolConsumption", 
                    aggregation: "SUM"
                }
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "totalFOMassRate_calc", 
                    aggregation: "SUM"
                },
                {
                    metricName: "me_1_foConsumption_calc", 
                    aggregation: "SUM"
                },
                {
                    metricName: "ge_1_foConsumption_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "bo_1_foConsumption_calc", 
                    aggregation: "SUM"
                },
                {
                    metricName: "totalCO2",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_hsfo_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lsfo_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_hsdo_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lsdo_cons_calc",
                    aggregation: "SUM"
                },
            ]
        }
    ]
};

const updateTotalFuelConsumption = (id, data, updateState, extraChartConfigs, widget) => {
    // handle no data case
    if(data?.series?.length === 0) return updateState(id, {}, widget?.id);

    const totalFuelSensorsValue = data?.series[0]?.values?.find((obj) => obj.metricName === 'totalFOMassRate_calc' && obj.metricCategory === 'VESSEL')?.value;
    const totalFuelMESensorsValue = data?.series[0]?.values?.find((obj) => obj.metricName === 'foVolConsumption')?.value !==  null ? data?.series[0]?.values?.find((obj) => obj.metricName === 'foVolConsumption')?.value/1000 : null;
    const totalFuelGESensorsValue = data?.series[0]?.values?.find((obj) => obj.metricName === 'commonDGFOMassRate')?.value !== null ? data?.series[0]?.values?.find((obj) => obj.metricName === 'commonDGFOMassRate')?.value/1000 : null;
    const totalFuelBOSensorsValue = data?.series[0]?.values?.find((obj) => obj.metricName === 'boilerCommonFoMassRate')?.value !== null ? data?.series[0]?.values?.find((obj) => obj.metricName === 'boilerCommonFoMassRate')?.value/1000 : null;

    const totalFuelSensorsTemp = Utilities.renderNumber(Utilities.roundToDigits(totalFuelSensorsValue, 2));
    const totalFuelMESensorsTemp = Utilities.renderNumber(Utilities.roundToDigits(totalFuelMESensorsValue, 2));
    const totalFuelGESensorsTemp = Utilities.renderNumber(Utilities.roundToDigits(totalFuelGESensorsValue, 2));
    const totalFuelBOSensorsTemp = Utilities.renderNumber(Utilities.roundToDigits(totalFuelBOSensorsValue, 2));

    const totalFuelSensorsMissingTemp = totalFuelSensorsValue && data?.series[0]?.values?.find((obj) => obj.metricName === 'totalFOMassRate_calc' && obj.metricCategory === 'VESSEL')?.missingValues;
    const totalFuelMESensorsMissingTemp = totalFuelMESensorsValue && data?.series[0]?.values?.find((obj) => obj.metricName === 'foVolConsumption')?.missingValues;
    const totalFuelGESensorsMissingTemp = totalFuelGESensorsValue && data?.series[0]?.values?.find((obj) => obj.metricName === 'commonDGFOMassRate')?.missingValues;
    const totalFuelBOSensorsMissingTemp = totalFuelBOSensorsValue && data?.series[0]?.values?.find((obj) => obj.metricName === 'boilerCommonFoMassRate')?.missingValues;

    const totalFuelReportsTemp = Utilities.renderNumber(Utilities.roundToDigits(data?.series[0]?.values?.find((obj) => obj.metricName === 'totalFOMassRate_calc' && obj.metricCategory === 'TELEGRAM')?.value, 2));
    const totalFuelMEReportsTemp = Utilities.renderNumber(Utilities.roundToDigits(data?.series[0]?.values?.find((obj) => obj.metricName === 'me_1_foConsumption_calc' && obj.metricCategory === 'TELEGRAM')?.value, 2));
    const totalFuelGEReportsTemp = Utilities.renderNumber(Utilities.roundToDigits(data?.series[0]?.values?.find((obj) => obj.metricName === 'ge_1_foConsumption_calc' && obj.metricCategory === 'TELEGRAM')?.value, 2));
    const totalFuelBOReportsTemp = Utilities.renderNumber(Utilities.roundToDigits(data?.series[0]?.values?.find((obj) => obj.metricName === 'bo_1_foConsumption_calc' && obj.metricCategory === 'TELEGRAM')?.value, 2));

    const totalCO2SensorsTemp = Utilities.renderNumber(Utilities.roundToDigits(data?.series[0]?.values?.find((obj) => obj.metricName === 'totalCO2' && obj.metricCategory === 'VESSEL')?.value, 2));
    const totalCO2ReportsTemp = Utilities.renderNumber(Utilities.roundToDigits(data?.series[0]?.values?.find((obj) => obj.metricName === 'totalCO2' && obj.metricCategory === 'TELEGRAM')?.value, 2));
    
    const totalHSFOTemp = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_hsfo_cons_calc')?.value;
    const totalLSFOTemp = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_lsfo_cons_calc')?.value;
    const totalHSDOTemp = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_hsdo_cons_calc')?.value;
    const totalLSDOTemp = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_lsdo_cons_calc')?.value;

    const updatedData = {
        data: {
            totalFuelSensors: (totalFuelSensorsTemp !== '-') && totalFuelSensorsTemp,
            totalFuelMESensors: (totalFuelMESensorsTemp !== '-') && totalFuelMESensorsTemp,
            totalFuelGESensors: (totalFuelGESensorsTemp !== '-') && totalFuelGESensorsTemp,
            totalFuelBOSensors: (totalFuelBOSensorsTemp !== '-') && totalFuelBOSensorsTemp,
            
            totalFuelSensorsMissing: (totalFuelSensorsMissingTemp > Utilities.missingValuesLimit) && Utilities.renderNumber(Utilities.roundToDigits(totalFuelSensorsMissingTemp, 1)),
            totalFuelMESensorsMissing: (totalFuelMESensorsMissingTemp > Utilities.missingValuesLimit) && Utilities.renderNumber(Utilities.roundToDigits(totalFuelMESensorsMissingTemp, 1)),
            totalFuelGESensorsMissing: (totalFuelGESensorsMissingTemp > Utilities.missingValuesLimit) && Utilities.renderNumber(Utilities.roundToDigits(totalFuelGESensorsMissingTemp, 1)),
            totalFuelBOSensorsMissing: (totalFuelBOSensorsMissingTemp > Utilities.missingValuesLimit) && Utilities.renderNumber(Utilities.roundToDigits(totalFuelBOSensorsMissingTemp, 1)),

            totalFuelReports: (totalFuelReportsTemp !== '-') && totalFuelReportsTemp,
            totalFuelMEReports: (totalFuelMEReportsTemp !== '-') && totalFuelMEReportsTemp,
            totalFuelGEReports: (totalFuelGEReportsTemp !== '-') && totalFuelGEReportsTemp,
            totalFuelBOReports: (totalFuelBOReportsTemp !== '-') && totalFuelBOReportsTemp,

            totalCO2Sensors: (totalCO2SensorsTemp !== '-') && totalCO2SensorsTemp,
            totalCO2Reports: (totalCO2ReportsTemp !== '-') && totalCO2ReportsTemp,
            
            fuelConsumptionTypesData: {
                HSFO: totalHSFOTemp > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalHSFOTemp, 2)),
                LSFO: totalLSFOTemp > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalLSFOTemp, 2)),
                HSDO: totalHSDOTemp > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalHSDOTemp, 2)),
                LSDO: totalLSDOTemp > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalLSDOTemp, 2)),  
            }  
        }
    }

    updateState(id, updatedData, widget?.id);
}

export {totalFuelConsumptionPayload, totalFuelConsumptionAllMetricsPayload, updateTotalFuelConsumption}
