import React from 'react';
import moment from 'moment/moment';
import Datepicker from 'components/reactDates/reactDatesYear';
import ReportsGoToDates from '../../reports/reportsGoToDates';
import { useSelector } from 'react-redux';

const OverallPeriodHeader = ({ reportsOverallPeriod, goToDates, selectedPickerYear, setSelectedPickerYear }) => {

    const monitoringStoreJSX = useSelector(state => state.monitoring);

    return (
        <div className="row padding-left-right-7">
            <div className='col-lg-4 padding-left-right-7'>
                <div className='overall-period-header widget-panel'>
                    <div className="d-flex align-items-center">
                        <div className='headline2'>
                            SOURCE LEGEND:
                        </div>
                        {/* <div className='sensor-tile circle'></div>
                        <div className='headline2'>
                            SENSOR
                        </div> */}
                        <div className='source-legend-telegram-tile circle'></div>
                        <div className='headline2'>
                            REPORTS
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-lg-8 padding-left-right-7'>
                <div className='overall-period-header widget-panel'>
                    <div className="d-flex">
                        <div className='headline2'>
                            PERIOD:
                        </div>
                        <div className='date-legends reports-period'>
                        { !monitoringStoreJSX.ciiFromTo.from || !monitoringStoreJSX.ciiFromTo.to 
                        ? `${moment(reportsOverallPeriod.from).utc().format('DD/MM/YYYY')} - ${moment(reportsOverallPeriod.to).utc().format('DD/MM/YYYY')}`
                        : `${moment(monitoringStoreJSX.ciiFromTo.from).utc().format("DD/MM/YYYY")} - ${moment(monitoringStoreJSX.ciiFromTo.to).utc().format("DD/MM/YYYY")}`}
                        </div>
                    </div>
                    <div className="d-flex align-items-center report-date-picker full-height">
                        <Datepicker selectedPickerYear={selectedPickerYear} setSelectedPickerYear={setSelectedPickerYear} customPopUpClass='overall-datepicker' />
                        <ReportsGoToDates goToDates={goToDates} />
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default OverallPeriodHeader;