const initialVesselState = {
    selectedVessel: -1,
    selectedVesselData: {},
    selectedVesselObj: {},
    changeVessel: false,
    allVessels: [],
    allVesselsObj: {
        name: 'ALL VESSELS', 
        vesselId: -1
    },
    noVessel: 0,
    fleetVessels: [],
}

// vessel reducer
const vessel = (state = initialVesselState, action) => {
    switch(action.type) {
        case 'vessel/setSelectedVessel': {
            return {
                ...state,
                selectedVessel: action.payload
            };
        }
        case 'vessel/setSelectedVesselData': {
            return {
                ...state,
                selectedVesselData: action.payload
            };
        }
        case 'vessel/setSelectedVesselObj': {
            return {
                ...state,
                selectedVesselObj: action.payload
            };
        }
        case 'vessel/setChangeVessel': {
            return {
                ...state,
                changeVessel: action.payload
            };
        }
        case 'vessel/setAllVessels': {
            return {
                ...state,
                allVessels: action.payload
            };
        }
        case 'vessel/setAllVesselsObj': {
            return {
                ...state,
                allVesselsObj: action.payload
            };
        }
        case 'vessel/setNoVessel': {
            return {
                ...state,
                noVessel: action.payload
            };
        }
        case 'vessel/setFleetVessels': {
            return {
                ...state,
                fleetVessels: action.payload
            };
        }
        default: 
            return state;
    }
}

export default vessel;