import moment from "moment";
import { ReactComponent as SmallThunder } from 'assets/img/app/events-imgs/small-thunder.svg';
import { ReactComponent as Ballast } from 'assets/img/app/events-imgs/laden.svg';
import { ReactComponent as Laden } from 'assets/img/app/events-imgs/ballast.svg';
import telegramUtils from 'common/js/events/telegramUtils';
import { baseFormUtils } from 'common/store/storeUtils';
import Group from "components/core/Forms/group";

const dtelegramSchema = () => {
    return [
        {
            telegramDate: {
                type: 'datePicker',
                label: 'Departure Date & Time (UTC)',
                group: 'General',
                value: moment().toDate(),
                viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
                cssClasses: {
                    wrapper: 'flex-centered-start',
                    label: 'form-text marg-r-15',
                    element: 'ds-input',
                    error: 'input-error-message'
                },
                validation: {
                    required: true,
                    withTime: true
                },
                onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                    if (value === null) {
                        setFieldValue("telegramDate", { value: null });
                    }
                }
            },
            portName: {
                label: 'Departure Port',
                group: 'General',
                type: 'autocomplete',
                portsAutocomplete: true,
                value: '',
                validation: { required: true },
                viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
                cssClasses: {
                    wrapper: 'flex-centered-start',
                    label: 'form-text marg-r-15',
                    error: 'input-error-message'
                },
                onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                    if (value !== null) {
                        setFieldValue("portName", value.port);
                    } else {
                        setFieldValue("portName", '');
                    }
                },
            },
            etaNextPort: {
                type: 'datePicker',
                label: 'Est. Date & Time of Arrival (UTC)',
                group: 'General',
                value: moment().toDate(),
                viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
                cssClasses: {
                    wrapper: 'flex-centered-start',
                    label: 'form-text marg-r-15',
                    element: 'ds-input',
                    error: 'input-error-message'
                },
                validation: {
                    arrivalDateLessThan: 'telegramDate',
                    required: true,
                    withTime: true
                },
                onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                    if (value === null) {
                        setFieldValue("etaNextPort", { value: null });
                    }
                }
            },
            nextPort: {
                label: 'Arrival Port',
                group: 'General',
                type: 'autocomplete',
                portsAutocomplete: true,
                value: '',
                validation: { 
                    notSamePorts: 'portName',
                    required: true 
                },
                viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
                cssClasses: {
                    wrapper: 'flex-centered-start',
                    label: 'form-text marg-r-15',
                    error: 'input-error-message'
                },
                onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                    if (value !== null) {
                        setFieldValue("nextPort", value.port);
                    } else {
                        setFieldValue("nextPort", '');
                    }
                },
            },
            ballastFlag: {
                type: "segmentButtons",
                label: "Load State",
                group: 'General',
                options: [
                    { value: 'B', label: 'Ballast', iconComponent: Ballast },
                    { value: 'L', label: 'Laden', iconComponent: Laden }
                ],
                value: '',
                viewports: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                validation: { required: true },
                cssClasses: {
                    wrapper: 'flex-centered-start',
                    label: 'form-text marg-t-20 ballastFlagLabel',
                    error: 'form-error'
                },
            },
            cargoOnBoard: {
                viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 3 },
                type: 'positiveNumber',
                label: 'Cargo Sailed',
                group: 'General',
                value: '',
                unit: 'mt',
                validation: { required: true }
            },
            draftAft: {
                viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 3 },
                type: 'positiveNumber',
                label: 'Draft aft',
                group: 'General',
                value: '',
                unit: 'm',
                validation: { required: true }
            },
            draftFore: {
                viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 3 },
                type: 'positiveNumber',
                label: 'Draft fore',
                group: 'General',
                value: '',
                unit: 'm',
                validation: { required: true }
            },
            geDetails: {
                type: 'table',
                group: 'General',
                label: {
                    title: 'Generator Engine Details',
                    index: 'Gen. Engine',
                    labelImage: SmallThunder
                },
                tableHeader: ["", "* Average Load ", "* Hours of Usage"],
                viewports: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                structure: {
                    ...telegramUtils.generatorStructure()
                }
            }
        },
        {
            generalValidation: {
                type: 'text',
                group: 'GroupedValidation',
                value: '',
                validation: {
                    requiredEvents: true
                },
                cssClasses: {
                    wrapper: 'generalValidationWrapper',
                    label: 'form-text',
                    element: 'generalValidation',
                    error: 'form-error'
                },
                viewports: { sm: 12, md: 12, lg: 12, xl: 12 },
                paddingViewports: { sm: 12, md: 12, lg: 6, xl: 6 }
            },
            telegramContent: {
                ...telegramUtils.telegramContentStructure()
            }
        }
    ];
};

const dtelegramValues = (props, editMode, dateClicked) => {
    telegramUtils.updateTelegramValues(props);
    const passDownValues = telegramUtils.passDownValues(props);

    return [
        {
            etaNextPort: editMode ? moment.utc(props.etaNextPort) : moment(dateClicked).set({ hour: moment().hour(), minute: moment().minutes(), second: moment().seconds() }).utc(),
            telegramDate: editMode ? moment.utc(props.dateFrom) : moment(dateClicked).set({ hour: moment().hour(), minute: moment().subtract(1, 'minutes').minutes(), second: moment().seconds() }).utc(),
            nextPort: props.nextPort || '',
            portName: props.portName || '',
            ballastFlag: props.ballastFlag || '',
            cargoOnBoard: props.cargoOnBoard || '',
            draftAft: props.draftAft || '',
            draftFore: props.draftFore || '',
            geDetails: telegramUtils.generatorArray(props)
        }, {
            telegramContent: editMode
                ? passDownValues.map((item) => baseFormUtils.createValuesFromSchema(item))
                : [],
            generalValidation: ''
        }
    ]
};

const dtelegramGroups = [
    {
        'General': {
            ...Group,
            viewports: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            paddingViewports: "none",
            renderLabel: false,
            className: '',
            groupWrapper: 'departureEventWrapper'
        }
    },
    {
        'GroupedValidation': {
            ...Group,
            viewports: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            paddingViewports: "none",
            renderLabel: false,
            className: 'groupedValidation'
        }
    },
    {}
];

export { dtelegramSchema, dtelegramValues, dtelegramGroups };