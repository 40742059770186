import React from 'react';
import classnames from "classnames";
import GridUtils from 'Utilities/gridUtils';

const ComponentWithIcon = (props) => {
    const iconComponentClasses = () => {
        return classnames('row component-with-icon', { [`${props.iconComponentWrapper}`]: props.iconComponentWrapper } );
    }

    return (
        <div className={iconComponentClasses()}>
            <div className={GridUtils.getHiddenClasses(props.componentGrid)}>
                <props.component {...props} />
            </div>
            <div className={GridUtils.getHiddenClasses(props.iconGrid)}>
                <div style={{backgroundImage: 'var(--'+props.iconName+')'}}
                className={`${ !props.extra ? 'full-height' : ''} d-flex align-items-center justify-content-center component-with-icon__icon-container`}>                                      
                    {props.icon && <props.icon />}
                </div>
                { props.extra ? (
                    <div className = { props.extraClasses } >
                        {props.extra}
                    </div>
                  ) : null
                }
            </div>
        </div>
    );
}
 
export default ComponentWithIcon;