import geStorage from 'components/monitoringCategories/localStorages/geStorage';

export const turboChargersChartPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    metrics: [
        {
            metricCategory: "GENERATOR_ENGINE",
            subIds: [],
            metricData: [
                {
                    metricName: "tCInAExhGasTemp"
                },
                {
                    metricName: "tCInBExhGasTemp"
                },
                {
                    metricName: "tCLoInLETPress"
                },
                {
                    metricName: "tCOutExhGasTemp"
                },
                {
                    metricName: "tCRPMPickup"
                }
            ]
        }
    ]
};

export const turboChargersLineChartData = {
  turboChargersChartMetrics: [
      'tCInAExhGasTemp',
      'tCInBExhGasTemp',
      'tCOutExhGasTemp',
      'tCRPMPickup',
      'tCLoInLETPress'
  ],
  chartAxes: {},                    // Dynamically generated in buildNavigationChartAxes method
  turboChargersLineChartTabs: [],   // Dynamically generated in buildNavigationChartTabs method
  navigationMapper: {},             // Dynamically generated in buildNavigationMapper method
  chartSeries: {}                   // Dynamically generated in buildNavigationChartSeries method
}

const buildNavigationMapper = noTurboCharger => {
    turboChargersLineChartData.navigationMapper = {};

    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        const tempArr = turboChargersLineChartData.turboChargersChartMetrics.map((metric) => {
            return metric;
        })
        turboChargersLineChartData.navigationMapper[`engine${turboNum}`] = tempArr;
    }
}

const buildNavigationChartTabs = noTurboCharger => {
    turboChargersLineChartData.turboChargersLineChartTabs = [];
    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.turboChargersLineChartTabs.push({title: `ENGINE ${turboNum}`, name: `engine${turboNum}`});
    }
}

const buildNavigationChartAxes = noTurboCharger => {
    turboChargersLineChartData.chartAxes = {};

    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.chartAxes[`engine${turboNum}`] = [
            { id: 'exhTemp-in-A-axis', title: { text: 'Exh. Gas Inlet A Temp (°C)'}, height: '80px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'exhTemp-in-B-axis', title: { text: 'Exh. Gas Inlet B Temp (°C)'}, height: '80px', top: '170px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'tCOutExhGasTemp-axis', title: { text: 'Exh. Gas Outlet Temp (°C)'}, height: '80px', top: '290px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'tCRPMPickup-axis', title: { text: 'RPM Pickup'}, height: '80px', top: '410px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'tCLoInLETPress-axis', title: { text: 'LO Inlet Pressure (bar)'}, height: '80px', top: '530px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        ]
    }
}

const buildNavigationChartSeries = noTurboCharger => {
    turboChargersLineChartData.chartSeries = {};
    for (let turboNum = 1; turboNum <= noTurboCharger; turboNum++) {
        turboChargersLineChartData.chartSeries[`engine${turboNum}`] = [
            { id: `tCInAExhGasTemp`, name: 'Exh. Gas Inlet A Temp', data: [], tooltipTitle: 'Exh. Gas Inlet A Temp', type: 'line', yAxis: 'exhTemp-in-A-axis',
              titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: `tCInBExhGasTemp`, name: 'Exh. Gas Inlet B Temp', data: [], tooltipTitle: 'Exh. Gas Inlet B Temp', type: 'line', yAxis: 'exhTemp-in-B-axis',
              titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: `tCOutExhGasTemp`, name: 'Exh. Gas Outlet Temp', data: [], tooltipTitle: 'Exh. Gas Outlet Temp', type: 'line', yAxis: 'tCOutExhGasTemp-axis',
              titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: `tCRPMPickup`, name: 'RPM Pickup', data: [], tooltipTitle: 'RPM Pickup', type: 'line', yAxis: 'tCRPMPickup-axis',
              titleSuffix: '(UTC)', tooltip: { valueDecimals: 2 } },
            { id: `tCLoInLETPress`, name: 'LO Inlet Pressure', data: [], tooltipTitle: 'Lube Oil Inlet Press', type: 'line', yAxis: 'tCLoInLETPress-axis',
              titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } }
        ]
    }
}

const setBarTablesDataFormat = (barsTableData) => {
    let tmpObj = {};
    barsTableData.forEach(obj => tmpObj[`${obj.metricName}No${obj.subId}`] = obj.value);
    return tmpObj;
};

export const updateTurboChargersChartJson = (id, data, updateState, extraChartConfigs, widget) => {

    const noTurboCharger = data[0]?.values[data[0]?.values.length-1].subId;

    // Navigation Mapper - Build Navigation Mapper object
    buildNavigationMapper(noTurboCharger);

    // Navigation Chart - Build Tabs Object
    buildNavigationChartTabs(noTurboCharger);

    // Navigation Chart - Build Axes Object
    buildNavigationChartAxes(noTurboCharger);

    // Navigation Chart - Build Series Object
    buildNavigationChartSeries(noTurboCharger);

    geStorage.setTurboChargerLineChartData(data);
    let lineChartTurboChargersJSON = (data[0]?.values) ? setBarTablesDataFormat(data[0].values) : [];

    updateState(id, lineChartTurboChargersJSON, widget?.id);
};
