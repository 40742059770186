import React, { useState, useEffect } from "react";
import SyncCharts from 'components/charts/genericSyncCharts/syncCharts';
import syncChartsOptions from 'components/charts/genericSyncCharts/syncChartsOptions';
import ChartsConfig from 'Utilities/charts';
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';

const RouteMonitoring = (props) => {
    const [syncCharts, setSyncCharts] = useState(syncChartsOptions());

    const colorMapper = (severity, value, borderColor) => {
        if(value < 0 && !borderColor) return 'transparent';

        if( (!value && value !== 0) || value >= 0 || (value < 0 && borderColor) ) {
            if(severity === 'LOW') return ThemeColorsForCharts()['low_severity'];
            if(severity === 'MEDIUM') return ThemeColorsForCharts()['medium_severity'];
            if(severity === 'HIGH') return ThemeColorsForCharts()['high_severity'];
        }
    }

    const setDeviationsSeries = (deviationSerie) => {
        deviationSerie.data = deviationSerie.data.map(value => {
            if(value.severity === 'NONE') return { x: value.x, y: value.y, color: ThemeColorsForCharts()['main_blue'] };
            return { x: value.x, y: value.y, color: colorMapper(value.severity, value.y), borderColor: colorMapper(value.severity, null, true) }; 
        });

        return deviationSerie;
    }

    useEffect(() => {
        if(!props.chartData) return;
        let options = syncChartsOptions();
        options["yAxis"] = ChartsConfig.syncChartsAxes.routeSyncChartsAxis;
        options["series"] = ChartsConfig.syncChartsSeries.routeSyncChartsSeries;
        options.chart.height = 990;
        options.defaultZoom = 1;
        options.elementId = 'alert-monitoring';
        
        if(props.chartData && props.chartData.length > 0) {
            options.series.forEach((serie, index) => {
                serie.data = props.chartData[index].value;
            });
            options.series[0] = setDeviationsSeries(options.series[0]);
        }

        setSyncCharts(options);
        
    // eslint-disable-next-line
    }, [props.chartData]);

    return (
        <div className="pad-r-15"><SyncCharts options={syncCharts} /></div>
    );
}
export default RouteMonitoring;

