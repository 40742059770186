import findIndex from 'lodash/findIndex';
import moment from "moment";
import NoonTelegramIcon from "assets/img/app/timeline-imgs/tl-telegram.svg";
import DepartureIcon from "assets/img/app/timeline-imgs/tl-departure.svg";
import ArrivallIcon from "assets/img/app/timeline-imgs/tl-arrival.svg";
import MaintenanceIcon from "assets/img/app/events-imgs/maintenance-b-icon.svg";
import BunkeringIcon from "assets/img/app/global-imgs/bunkering.svg";
import EngineDamageIcon from "assets/img/app/global-imgs/engine-damages.svg";
import CharterPartyIcon from "assets/img/app/global-imgs/charter-party.svg";
import NextportIcon from 'assets/img/app/timeline-imgs/nextport.svg';
import EventsUtilities from "Utilities/events";
import ThemeColors from 'sass/_themeColorsForJs.scss';
import { vesselGroupStore, vesselStore, vesselUtils, eventsUtils, calendarStore } from 'common/store/storeUtils';
import { cloneDeep } from 'lodash';

const calendarUtils = {
    // note: this needs to be a function in order for the 'eventUtils' imported object to be defined when the function is called
    Filters: () => [
        {title: 'PORT NOON', calendarTitle: 'Port Noon Report ', id: eventsUtils.getEventIdByType('OTELEGRAM'), class: 'chart', color: ThemeColors['cerulean'], bgcolor: {backgroundColor: ThemeColors['cerulean']}, imgUrl: NextportIcon},
        {title: 'NOON', calendarTitle: 'Noon Report', id: eventsUtils.getEventIdByType('NTELEGRAM'), class: 'tel', color: ThemeColors['green_bay'], bgcolor: {backgroundColor: ThemeColors['green_bay']}, imgUrl: NoonTelegramIcon},
        {title: 'DEPARTURES', calendarTitle: 'Departure', id: eventsUtils.getEventIdByType('DTELEGRAM'), class: 'dep', color: ThemeColors['amaranth'], bgcolor: {backgroundColor: ThemeColors['amaranth']}, imgUrl: DepartureIcon},
        {title: 'ARRIVALS', calendarTitle: 'Arrival', id: eventsUtils.getEventIdByType('ATELEGRAM'), class: 'arr', color: ThemeColors['tomato'], bgcolor: {backgroundColor: ThemeColors['tomato']}, imgUrl: ArrivallIcon},
        {title: 'MAINTENANCE EVENTS', calendarTitle: 'Maintenance Event', id: eventsUtils.getEventIdByType('MAINTENANCE'), class: 'clea', color: ThemeColors['curious_blue'], bgcolor: {backgroundColor: ThemeColors['curious_blue']}, imgUrl: MaintenanceIcon},
        {title: 'BUNKERINGS', calendarTitle: 'Bunkering', id: eventsUtils.getEventIdByType('BUNKERING'), class: 'bunk', color: ThemeColors['violet'], bgcolor: {backgroundColor: ThemeColors['violet']}, imgUrl: BunkeringIcon},
        {title: 'MACHINERY DAMAGES', calendarTitle: 'Machinery Damage', id: eventsUtils.getEventIdByType('DAMAGES'), class: 'dam', color: ThemeColors['ruby_color'], bgcolor: {backgroundColor: ThemeColors['ruby_color']}, imgUrl: EngineDamageIcon},
        {title: 'CHARTER PARTY', calendarTitle: 'Charter Party', id: eventsUtils.getEventIdByType('VESSELCHARTER'), class: 'chart', color: ThemeColors['carrot_orange'], bgcolor: {backgroundColor: ThemeColors['carrot_orange']}, imgUrl: CharterPartyIcon}
    ],
    addCurrentEvent: ({modalDate = '', modalShow = false}) => {
        return {
            editEvent: false,
            modalTitle: 'ADD EVENT',
            modalDate,
            eventObj: {},
            modalEventVessel: -1,
            modalShow,
        };
    },
    editCurrentEvent(vEvent) {
        return {
            editEvent: true,
            modalTitle: 'EDIT EVENT',
            modalDate: '',
            eventObj: vEvent,
            modalEventVessel: vEvent.vesselId,
            modalShow: true
        };
    },
    getDate: () => new Date().getUTCMilliseconds(),
    belongsToTelegrams: type => {
        return [
            eventsUtils.getEventIdByType('DTELEGRAM'),
            eventsUtils.getEventIdByType('ATELEGRAM'),
            eventsUtils.getEventIdByType('NTELEGRAM'),
            eventsUtils.getEventIdByType('OTELEGRAM'),
        ].indexOf(type) !== -1;
    },
    buildCurrentTelegram: v => {
        return {
            port: v.port,
            nextPort: v.nextPort,
            etaNextPort: v.etaNextPort,
            currentEventType: v.type,
        }
    },
    setCustomEventsViewProps(ue, eventsIndex) {
        return {
            imgUrl: calendarUtils.Filters()[eventsIndex].imgUrl,
            eventColor: calendarUtils.Filters()[eventsIndex].color,
            vesselName: vesselStore().allVessels.filter(vessel => vessel.vesselId === ue.vesselId)[0].name,
            calendarTitle: calendarUtils.Filters()[eventsIndex].calendarTitle,
            vesselClass: `vessel${vesselUtils.fleetVesselsIds().findIndex(elmt => elmt === ue.vesselId)}`
        }
    },
    // find index of current event in Filters
    findEventsIndex: (dataArray, value) => {
        return findIndex(dataArray, { id: value });
    },
    // format telegrams for calendar
    setTelegramsEvents: (ue) => {
        let index = calendarUtils.findEventsIndex(calendarUtils.Filters(), ue.type);
        let port = ue.portName;
        let uniqueClass = calendarUtils.getDate();
        uniqueClass = parseInt(uniqueClass * Math.random() * 1000);
        let eventClass = 'a' + uniqueClass.toString();

        if (ue.type === eventsUtils.getEventIdByType('NTELEGRAM')) port = "";

        return {
            ...ue,
            className: eventClass + " " + calendarUtils.Filters()[index].class,
            start: moment.utc(ue.dateFrom).format('YYYY-MM-DD'),
            dateFrom: ue.dateFrom,
            color: calendarUtils.Filters()[index].color,
            port,
            vesselGroupId: vesselGroupStore().selectedVesselGroup,
            nextPort: ue.nextPort ? ue.nextPort : '-',
            type: ue.type,
            telegramType: ue.type,
            etaNextPort: ue.etaNextPort ? moment(ue.etaNextPort).format('YYYY-MM-DD HH:mm:ss') : '-',
            vesselId: ue.vesselId,
            ...calendarUtils.setCustomEventsViewProps(ue, index)
        };
    },
    setCommonEvent: (ue, filtersIndex) => {
        return {
            ...ue,
            start: moment.utc(ue.dateFrom).format('YYYY-MM-DD'),
            end: moment.utc(ue.dateTo).add(1, 'days').format('YYYY-MM-DD'),
            color: calendarUtils.Filters()[filtersIndex].color,
            className: calendarUtils.Filters()[filtersIndex].class,
            ...calendarUtils.setCustomEventsViewProps(ue, filtersIndex)
        };
    },

    
    setCalendarEvents: (dispatch, data, filteredCriteriaToSet) => {
        let tempEvents = [];

        data.filter(ue => ue.type !== -1).forEach(ue => {
            let tempEvent = {};
            if (ue.type === eventsUtils.getEventIdByType('MAINTENANCE')) tempEvent = calendarUtils.setCommonEvent(ue, 4);
            if (ue.type === eventsUtils.getEventIdByType('VESSELCHARTER')) tempEvent = calendarUtils.setCommonEvent(ue, 7);
            if (ue.type === eventsUtils.getEventIdByType('BUNKERING')) tempEvent = calendarUtils.setCommonEvent(ue, 5);
            if (ue.type === eventsUtils.getEventIdByType('DAMAGES')) tempEvent = calendarUtils.setCommonEvent(ue, 6);
            if (calendarUtils.belongsToTelegrams(ue.type)) tempEvent = calendarUtils.setTelegramsEvents(ue);
            if (tempEvent !== 'hidden' && Object.keys(tempEvent).length > 0) tempEvents.push(tempEvent);
        });
        
        if(filteredCriteriaToSet) calendarUtils.updateCalendarEvents(dispatch, tempEvents);
        else dispatch({ type: 'calendar/setCalendarEvents', payload: tempEvents });
    },
    updateCalendarEvents: (dispatch, data) => {
        dispatch({ type: 'calendar/setCalendarEvents', payload: data });
        calendarUtils.setCalendarFilteredCriteria(dispatch, null);
    },
    getEvents: (dispatch, from, to) => {
        if (vesselGroupStore().selectedVesselGroup === vesselGroupStore().allVesselGroupsObj.id) return;

        let value = vesselGroupStore().selectedVesselGroup;

        dispatch({ type: 'calendar/setCalendarFromTo', payload: {from, to} });

        eventsUtils.getVesselGroupEvents({value, startingDate: from, endingDate: to}).then(response => {
            if(!response.error) {
                calendarUtils.setCalendarEvents(dispatch, response);
                calendarUtils.setCalendarFilteredCriteria(dispatch, null);
            }
        });
    },
    setCalendarFilteredEvents(dispatch) {
        let tmpCalendarFilteredEvents

        // note: use of cloneDeep here so that the redux state stays immutable
        let calendarFilteredEvents = cloneDeep(calendarStore().calendarEvents).map(event => {
            if (calendarUtils.belongsToTelegrams(event.type)) {
                event.subtitle = EventsUtilities.telegramSubtitle(event);
            } else {
                event.subtitle = EventsUtilities.eventSubTitle(event);
                event.type === eventsUtils.getEventIdByType('MAINTENANCE') && (event.calendarTitle = EventsUtilities.eventTitle(event));
            }
            return event;
        });

        calendarStore().calendarFilteredCriteria.forEach(c => {calendarFilteredEvents = calendarFilteredEvents.filter(event => event.type !== c)});
        
        tmpCalendarFilteredEvents = calendarFilteredEvents.filter(event => {
            return vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId || event.vesselId === vesselStore().selectedVessel;
        });

        dispatch({ type: 'calendar/setCalendarFilteredEvents', payload: tmpCalendarFilteredEvents });
    },
    setCalendarFilteredCriteria: (dispatch, criteria = null) => {
        if (criteria !== null) {
            const valueToUpdate = (calendarStore().calendarFilteredCriteria.indexOf(criteria) === -1)
                ? [...calendarStore().calendarFilteredCriteria, criteria]
                : calendarStore().calendarFilteredCriteria.filter(c => c !== criteria);

            dispatch({ type: 'calendar/setCalendarFilteredCriteria', payload: valueToUpdate });
        }
        calendarUtils.setCalendarFilteredEvents(dispatch);
    },
    canEditEvent: vEvent => {
        let canEdit = true;

        if (calendarUtils.belongsToTelegrams(vEvent.type) && !vesselStore().allVessels.filter(vessel => vessel.vesselId === vEvent.vesselId)[0].allowFormTelegram) canEdit = false;

        return canEdit;
    },
    calendarComponentPrevMonthHandler(dispatch) {
        dispatch({ type: 'calendar/setCurrentDate', payload: moment(calendarStore().currentDate).utc().subtract(1, 'month').startOf('month') });

        if(calendarStore().currentDate < moment(calendarStore().calendarFromTo.from)) {
            calendarUtils.getEvents(
                dispatch, 
                moment(calendarStore().currentDate).utc().subtract(180, 'days').startOf('month').valueOf(), 
                moment(calendarStore().currentDate).utc().add(60, 'days').endOf('month').valueOf()
            );

            dispatch({ 
                type: 'calendar/setCalendarFromTo', 
                payload: {
                    from: moment(calendarStore().currentDate).utc().subtract(180, 'days').startOf('month').valueOf(), 
                    to: moment(calendarStore().currentDate).utc().add(60, 'days').endOf('month').valueOf()
                } 
            });
        }
    },
    calendarComponentNextMonthHandler(dispatch) {
        dispatch({ 
            type: 'calendar/setCurrentDate', 
            payload: moment(calendarStore().currentDate).utc().add(1, 'month').endOf('month') 
        });

        if(calendarStore().currentDate > moment(calendarStore().calendarFromTo.to)) {
            calendarUtils.getEvents(
                dispatch, 
                moment(calendarStore().currentDate).utc().subtract(60, 'days').startOf('month').valueOf(), 
                moment(calendarStore().currentDate).utc().add(180, 'days').endOf('month').valueOf()
            );

            dispatch({ 
                type: 'calendar/setCalendarFromTo', 
                payload: {
                    from: moment(calendarStore().currentDate).utc().subtract(60, 'days').startOf('month').valueOf(), 
                    to: moment(calendarStore().currentDate).utc().add(180, 'days').endOf('month').valueOf()
                } 
            });
        }
    }
}

export default calendarUtils;