import React from "react";
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';

const PistonsGasWaterTabs = ({noData, classes, values}) => {
    return(<>
        {
            !noData ? (
                <div className={`pistonsMonitoring__tabs__body__piston__info__box ${classes.boxType}`}>
                    <div className="pistonsMonitoring__tabs__body__piston__info__box__value">{values.total}%</div>
                    <div className="pistonsMonitoring__tabs__body__piston__info__box__temp">{values.min} - {values.max} °C</div>
                </div>
            ) : (
                <Tooltip title="Not available for this vessel!" component={
                    <div className={`pistonsMonitoring__tabs__body__piston__info__box ${classes.boxType} ${classes.tooltip}`}>
                        <div className="pistonsMonitoring__tabs__body__piston__info__box__value">-</div>
                    </div>                                        
                } />
            )    
        }
    </>)
}

export default PistonsGasWaterTabs;