import React, { useState } from 'react';
import Tooltip from "../muiHTMLTooltip/muiTooltip";
import DashboardMenuOptions from './dashBoardMenuOptions';
import {ReactComponent as EditIcon} from "assets/img/app/global-imgs/edit.svg";
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardStore, dashboardUtils } from 'common/store/storeUtils';
import service from 'common/js/service';

const SingleDashboardHeader = props => {
    const [showLoader, setShowLoader] = useState(false);

    // store vars
    const dashboardStoreJSX = useSelector(state => state.dashboard);
    const dispatch = useDispatch();

    const handleEditClick = () => {
        dispatch({ type: 'dashboard/setEditMode', payload: true });
    }

    const loaderClasses = () => {
        return classnames({'d-none': !showLoader});
    }

    const publishBtnClasses = () => {
        return classnames('main-cta__text', {'d-none': showLoader, 'd-block': !showLoader});
    }

    const exitEditMode = () => {
        dashboardUtils.handleToggleDrawer(false, props.setDrawerClose, props.setExpandDrawer);
        dispatch({ type: 'dashboard/setEditMode', payload: false });
    }

    const storeUnpublished = async () => {
        exitEditMode();
        if (dashboardStore().templateHasChanges) return service.saveUnpublished(props.id, props.widgets);
    }

    const duplicateAndStoreUnpublished = async () => {
        const res = await service.duplicateDashboardTemplate(props.title, props.id);
        if (res.status === 201) {
            const locationUrl = res.headers.get('Location').split("/");
            const duplicatedTemplateID = parseInt(locationUrl[locationUrl.length - 1]);

            if (typeof duplicatedTemplateID === 'number') {
                service.saveUnpublished(duplicatedTemplateID, props.widgets).then(() => {
                    props.initializeTemplateWithWidgets(duplicatedTemplateID);
                    exitEditMode();
                    props.history.push(`/dashboard/${duplicatedTemplateID}`);
                })
            }

        }
    }

    const duplicateAndPublishDashboard = async () => {
        const res = await service.duplicateDashboardTemplate(props.title, props.id);
        if (res.status === 201) {
            const locationUrl = res.headers.get('Location').split("/");
            const duplicatedTemplateID = parseInt(locationUrl[locationUrl.length - 1]);

            if (typeof duplicatedTemplateID === 'number') {
                publishDashboard(duplicatedTemplateID).then(() => {
                    props.history.push(`/dashboard/${duplicatedTemplateID}`);
                });
            }
        }
    }

    const handlePublishClick = async () => {
        if(showLoader) return;
        setShowLoader(true);
        if (props.type === 'DEEPSEA') await duplicateAndPublishDashboard();
        else await publishDashboard();
    }

    const handleCloseClick = () => {
        if (props.type === 'DEEPSEA') {
            if (!dashboardStore().templateHasChanges) return exitEditMode();
            return duplicateAndStoreUnpublished();
        } else {
            return storeUnpublished();
        }
    }

    const publishDashboard = async (templateId) => {
        const publishTemplateId = templateId ? templateId : props.id;
        const res = await service.saveUnpublished(publishTemplateId, props.widgets);
        if(res.status === 204) {
            const resp = await service.savePublished(publishTemplateId);
            if(resp.status === 204) {
                props.initializeTemplateWithWidgets(publishTemplateId);
                setShowLoader(false);
            }
        }
        props.setJustPublished(true);
        exitEditMode();
    }

    const handleToggleDrawer = () => {
        dashboardUtils.handleToggleDrawer(props.drawerClose, props.setDrawerClose, props.setExpandDrawer);
    }

    return (
        <div className="single-dashboard__header d-flex justify-content-end align-items-center">
            {!dashboardStoreJSX.editMode
                ? <React.Fragment>
                    <div className="dashboard-options-action-button flex-centered" onClick={handleEditClick}>
                        <Tooltip title="Edit" component={<EditIcon className="editAction"/>}/>
                    </div>
                    <DashboardMenuOptions {...props} menuClassName="dashboardPopupMenu"/>
                </React.Fragment>

                // Edit Mode true
                : <React.Fragment>
                    <button className="main-cta flex-centered publishBtn" onClick={handlePublishClick}>
                        <div className={publishBtnClasses()}>PUBLISH</div>
                        <CircularProgress
                            className={loaderClasses()}
                            style={{'color': '#fff'}}
                            thickness={5}
                            size={28}
                        />
                    </button>
                    <Tooltip title="Save changes without publishing"
                             component={<button className="text-cta marg-l-10" onClick={handleCloseClick}>CLOSE</button>}/>
                    <DashboardMenuOptions {...props} menuClassName="dashboardPopupMenu" />
                    <div className="divider"/>

                    <button className="text-cta widget-library-toggle widgetLibraryBtn" onClick={handleToggleDrawer}>
                        WIDGET LIBRARY
                    </button>

                </React.Fragment>
            }
        </div>
    )
}

export default SingleDashboardHeader;
