export const drawerUtils = {

    // Open - Close Drawer
    openDrawer: (classname) => {
        setTimeout(() => document.querySelectorAll(classname).forEach(el => el.classList.add('open')));
    },
    closeDrawer: (classname) => {
        setTimeout(() => document.querySelectorAll(classname).forEach(el => {
            el.classList.remove('open')
            el.classList.remove('expanded')
        }));
    },
    toggleDrawer: (state, classname) => {
        if (state) return drawerUtils.openDrawer(classname);
        drawerUtils.closeDrawer(classname);
    },

    // Expand - Shrink Drawer
    expandDrawer: (classname) => {
        setTimeout(() => document.querySelectorAll(classname).forEach(el => el.classList.add('expanded')));
    },
    shrinkDrawer: (classname) => {
        setTimeout(() => document.querySelectorAll(classname).forEach(el => el.classList.remove('expanded')));
    },
    toggleExpandDrawer: (state, classname) => {
        if (!state) return drawerUtils.expandDrawer(classname);
        drawerUtils.shrinkDrawer(classname);
    }
}
