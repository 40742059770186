import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import BaseForm from "components/core/Forms";
import { ReactComponent as CloseIcon } from 'assets/img/app/global-imgs/close.svg';
import moment from 'moment';

const SetLegModal = props => {
    const schema = {
        departureDate: {
            type: 'datePicker',
            label: 'Departure Date & Time (UTC)',
            value: moment().subtract(1, 'minutes').toDate(),
            viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            cssClasses: {
                wrapper: 'flex-centered-start',
                label: 'form-text marg-r-15',
                element: 'ds-input',
                error: 'input-error-message'
            },
            validation: {
                maxDate: moment().toDate(),
                required: true,
                withTime: true
            },
            onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                handleChange("departureDate", value, setFieldValue, setFieldTouched);
            }
        },
        departurePort: {
            label: 'Departure Port',
            type: 'autocomplete',
            portsAutocomplete: true,
            value: '',
            viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            cssClasses: {
                wrapper: 'flex-centered-start',
                label: 'form-text marg-r-15',
                error: 'input-error-message'
            },
            onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                if (value !== null) {
                    setFieldValue("departurePort", value.port);
                } else {
                    setFieldValue("departurePort", '');
                }
            },
        },
        arrivalDate: {
            type: 'datePicker',
            label: 'Arrival Date & Time (UTC)',
            value: moment().toDate(),
            viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            cssClasses: {
                wrapper: 'flex-centered-start',
                label: 'form-text marg-r-15',
                element: 'ds-input',
                error: 'input-error-message'
            },
            validation: {
                maxDate: moment().toDate(),
                arrivalDateLessThan: 'departureDate',
                twoMonthsTimeSpan: 'departureDate',
                required: true,
                withTime: true
            },
            onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                handleChange("arrivalDate", value, setFieldValue, setFieldTouched);
            }
        },
        arrivalPort: {
            label: 'Arrival Port',
            type: 'autocomplete',
            portsAutocomplete: true,
            value: '',
            viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            cssClasses: {
                wrapper: 'flex-centered-start',
                label: 'form-text marg-r-15',
                error: 'input-error-message'
            },
            onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                if (value !== null) {
                    setFieldValue("arrivalPort", value.port);
                } else {
                    setFieldValue("arrivalPort", '');
                }
            },
        }
    };

    const handleChange = (field, value, setFieldValue, setFieldTouched) => {
        if (value === null) {
            setFieldValue(field, "");
        }
    }

    const submitButtonOptions = {
        buttonName: <div className='main-cta__text'>Search</div>,
        buttonCssClass: 'main-cta',
        buttonWrapperCssClass: '',
        disabled: false
    };

    return (
        <Dialog open={props.show} onClose={props.onHide} aria-labelledby="search-leg-dialog-title" className="centered-modal">
            <DialogTitle id="search-leg-dialog-title">
                SEARCH LEG REPORT
                <div className="close-button flex-centered" onClick={props.onHide}><div className="close-button__img flex-centered">
                    <CloseIcon className="svg-defs-path-g-maskUse" />
                </div></div>
            </DialogTitle>
            <BaseForm
                renderAsterisk={false}
                schema={schema}
                onSubmit={values => { props.onSave(values) }}
                submitButtonOptions={submitButtonOptions}
                contentWrapper={{ component: DialogContent, props: { className: "clearfix" } }}
                buttonsWrapper={{ component: DialogActions, props: {} }}
            />
        </Dialog>
    )
};

export default SetLegModal;