const initialTermsState = {
    hasNewTerms: false,
    loadTerms: false,
    hasReceivedTerms: false,
    modalShow: false,
    tac: {},
    errorNew: false,
    errorCurrent: false,
    errorServer: false,
    currentTerms: {
        data: [], 
        date: 1000000000000
    },
    futureTerms: {
        data: [], 
        date: 1000000000000
    }
}

// terms reducer
const terms = (state = initialTermsState, action) => {
    switch(action.type) {
        case 'terms/setTerms': {
            switch (action.payload.key) {
                case "tac":
                    return {
                        ...state, 
                        tac: action.payload.value
                    };
                case "show_modal":
                    return {
                        ...state, 
                        modalShow: action.payload.value
                    };
                case "terms_response":
                    return {
                        ...state, 
                        loadTerms: action.payload.value
                    };
                case "has_received_terms":
                    return {
                        ...state, 
                        hasReceivedTerms: true
                    };
                case "set_current_terms":
                    return {
                        ...state, 
                        currentTerms: action.payload.value
                    };
                case "set_future_terms":
                    return {
                        ...state, 
                        futureTerms: action.payload.value
                    };
                case "has_new_terms":
                    return {
                        ...state, 
                        hasNewTerms: action.payload.value
                    };
                case "has_terms_server_error":
                    return {
                        ...state, 
                        errorServer: action.payload.value
                    };
                case "has_current_terms_error":
                    return {
                        ...state, 
                        errorCurrent: action.payload.value
                    };
                case "has_new_terms_error":
                    return {
                        ...state, 
                        errorNew: action.payload.value
                    };
                default:
                    return state;
            }
        }
        default: 
            return state;
    }
}

export default terms;