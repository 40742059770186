import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as Options } from 'assets/img/app/dashboard-imgs/dashboardOptions.svg';

const MenuOptions = (props) => {

    return(
        <>
            <div className={props.iconWrapperClassName} onClick={props.optionsHandleClick}>
                <Options className={props.iconClassName} />
            </div>
            <Menu
                anchorEl={props.anchorEl}
                open={Boolean(props.anchorEl)}
                onClose={() => props.optionsHandleClose(null)}
                className={props.menuClassName}
                autoFocus={false}
                keepMounted
                disableScrollLock
                anchorOrigin={{vertical: "top", horizontal: "right" }}
                transformOrigin={{vertical: "top", horizontal: "right" }}
                getContentAnchorEl={null}
            >
                { props.menuItemOptions.map((option, index) => <MenuItem key={index} onClick={() => props.optionsHandleClose(option.action)}>{option.label}</MenuItem>) }
            </Menu>
        </>
    )
}

export default MenuOptions;