import React from 'react';
import classnames from 'classnames';

const InfoValuesGridWithIcon = props => {
    const {style} = props;
    const compClasses = () => classnames("flex-space-evenly infoValuesGridWithIcon");

    return (
        <div className={compClasses()} style={style}>
            <div className="infoValuesGridWithIcon__icon">
                <props.icon className="svg-path"/>
            </div>
            {props.data.map((element, index) => (
                <div className="infoValuesGridWithIcon__box" key={`${element.label}-${index}`}>
                    <div className="infoValuesGridWithIcon__box__value">{element.value}</div>
                    <div className="infoValuesGridWithIcon__box__label">{element.label}</div>
                </div>
            ))}
        </div>
    );
}
 
export default InfoValuesGridWithIcon;