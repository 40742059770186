import Utilities from 'Utilities/global';
import Wind from 'Utilities/wind';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import ThemeColors from 'sass/_themeColorsForJs.scss';
import {reportsStore, vesselUtils, vesselGroupUtils} from 'common/store/storeUtils';
import store from 'common/store/store';

const fleetDailyInfoPayload = {
    metrics: [
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {metricName: "apparent_wind_speed_calc", aggregation: "AVG"},
                {metricName: "apparent_wind_speed", aggregation: "AVG"},
                {metricName: "true_wind_speed_calc", aggregation: "AVG"},
                {metricName: "speed_overground", aggregation: "AVG"}
            ]
        },
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {metricName: "rpm", aggregation: "AVG"}
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {metricName: "ve_1_wind_speed_avg", aggregation: "AVG"},
                {metricName: "ve_1_speed_avg", aggregation: "AVG"},
                {metricName: "me_1_rpm_avg", aggregation: "AVG"},
                {metricName: "hours_atsea", aggregation: "NONE"},
                {metricName: "minutes_atsea", aggregation: "NONE"},
                {metricName: "next_port", aggregation: "NONE"},
                {metricName: "eta_next_port", aggregation: "NONE"},
                {metricName: "ordered_speed", aggregation: "AVG"}
            ]
        }
    ]
};

const getHeaderTemplateWithUnit = (label, unit, display, style) => {
    return `<div class="ag-cell-label-container" role="presentation">
              <div ref="eLabel" style="${style ? style : 'justify-content: center'}" class="ag-header-cell-label" role="presentation">${label}
                <span style="display: ${display}" class="table-title-regular">&nbsp;(${unit})</span>
              </div>
            </div>`;
}

const InstructedSpeedColumn = {
    headerComponentParams: {template: getHeaderTemplateWithUnit('Instructed SOG', 'kn', 'block')},
    field: "avgInstructedSOG",
    sortable: false,
    suppressSizeToFit: false,
    maxWidth: 250,
    minWidth: 160,
    cellStyle: {maxWidth: '250px', minWidth: '160px', color: ThemeColors['reports_color'], justifyContent: 'center'}
};

const fleetDailyInfoColumnDefs = [
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Vessel Name', '', 'none', 'padding-left: 8px;justify-content: flex-start')},
        sortable: false,
        suppressSizeToFit: false,
        cellRenderer: 'vesselNameBox',
        maxWidth: 250,
        minWidth: 205,
        cellStyle: {minWidth: '185x', maxWidth: '250px'}
    },

    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Report Period', '', 'none', 'justify-content: flex-start')},
        field: "reportPeriod",
        sortable: false,
        suppressSizeToFit: false,
        cellRenderer: 'reportPeriod',
        maxWidth: 220,
        minWidth: 185,
        cellStyle: {minWidth: '185x', maxWidth: '220px'}
    },

    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg Wind Speed', 'bf', 'block')},
        field: "avgWindSpeed",
        sortable: false,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgWindSpeed', teKey: 'teWindSpeed'},
        maxWidth: 250,
        minWidth: 160,
        cellStyle: {maxWidth: '250px', minWidth: '160px'}
    },

    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg SOG', 'kn', 'block')},
        field: "avgSog",
        sortable: false,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgSOG', teKey: 'teStw'},
        maxWidth: 250,
        minWidth: 160,
        cellStyle: {maxWidth: '250px', minWidth: '160px'}
    },

    {...InstructedSpeedColumn},

    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg RPM', 'rpm', 'block')},
        field: "avgRpm",
        sortable: false,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgRpm', teKey: 'teRpm'},
        maxWidth: 250,
        minWidth: 160,
        cellStyle: {maxWidth: '250px', minWidth: '160px'}
    },

    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Hours At Sea', '', 'none')},
        field: "hoursAtSea",
        sortable: false,
        suppressSizeToFit: false,
        maxWidth: 180,
        minWidth: 110,
        cellStyle: {maxWidth: '180px', minWidth: '110px', color: ThemeColors['reports_color'], justifyContent: 'center'}
    },

    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Next Port', '', 'none')},
        field: "nextPort",
        sortable: false,
        suppressSizeToFit: false,
        maxWidth: 280,
        minWidth: 170,
        cellStyle: {maxWidth: '280px', minWidth: '170px', color: ThemeColors['reports_color'], justifyContent: 'center'}
    },

    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('ETA Next Port', '', 'none')},
        field: "etaNextPort",
        sortable: false,
        suppressSizeToFit: false,
        maxWidth: 1250,
        minWidth: 180,
        cellStyle: {maxWidth: '1250px', minWidth: '180px', color: ThemeColors['reports_color'], justifyContent: 'center'}
    }
];

const fleetDailyInfoColumnDefsSensorDataOnly = [
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Vessel Name', '', 'none', 'padding-left: 8px;justify-content: flex-start')},
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'vesselNameBox'
    },
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Report Period', '', 'none', 'justify-content: flex-start')},
        field: "reportPeriod",
        sortable: false,
        suppressSizeToFit: false,
        cellRenderer: 'reportPeriod'
    },
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg Wind Speed', 'bf', 'block')},
        field: "avgWindSpeed",
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgWindSpeed', teKey: 'teWindSpeed'}
    },
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg SOG', 'kn', 'block')},
        field: "avgSog",
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgSOG', teKey: 'teStw'}
    },
    {
        headerComponentParams: {template: getHeaderTemplateWithUnit('Avg RPM', 'rpm', 'block')},
        field: "avgRpm",
        sortable: true,
        suppressSizeToFit: false,
        cellRenderer: 'doubleData',
        cellRendererParams: {sensorKey: 'avgRpm', teKey: 'teRpm'}
    }
];

const fleetDailyInfo = (id, response, updateState, extraChartConfigs, widget) => {
    let fleetVesselsWithData = [], fleetVessels = [];
    const portsCharacterLimit = 28;

    if (widget?.inDashboard) {
        const tempFleetId = vesselGroupUtils.getVesselGroupIdFromVesselId(widget.vesselIds);
        fleetVessels = vesselGroupUtils.getFleetVesselsFromFleetId(tempFleetId);
    } else {
        fleetVessels = vesselUtils.getFleetVessels(store.dispatch);
    }

    const tableData = response?.series?.map(object => {

        const dataObj = reportsStore().isTelegramConfigured ? {
            avgWindSpeed: ['-', '-'], avgSOG: ['-', '-'], avgInstructedSOG: '-', avgRPM: ['-', '-'],
            hoursAtSea: '-', nextPort: '-', etaNextPort: '-'
        } : {
            avgWindSpeed: ['-'], avgSOG: ['-'], avgRPM: ['-']
        };

        const v = fleetVessels.find(vessel => vessel.vesselId === object.vessel);
        fleetVesselsWithData.push(v?.vesselId);

        dataObj.vesselName = v?.name;
        dataObj.vesselObj = v;
        dataObj.reportPeriod = object.dailyDateRange;

        // wind speed metrics have priority => 1) apparent_wind_speed_calc 2) apparent_wind_speed 3) true_wind_speed_calc
        dataObj.avgWindSpeed[0] = Utilities.renderNumber(Wind.knotsToBeauforts(Wind.getPrioritizedWindSpeedMetric(['apparent_wind_speed_calc', 'apparent_wind_speed', 'true_wind_speed_calc'], object), 0));
        
        // check if at least one vessel of the group has ordered speed value
        // If not, then we will remove the ordered speed column from the table's columns.
        let hasOrderedSpeedValues = false;

        object.values.forEach(keyGp => {
            // check for ordered speed value
            if (keyGp.metricName === 'ordered_speed' && keyGp?.value) hasOrderedSpeedValues = true;

            if (keyGp.metricName === 've_1_wind_speed_avg' && dataObj.avgWindSpeed[1]) dataObj.avgWindSpeed[1] = Utilities.renderNumber(Utilities.roundToDigits(parseFloat(keyGp.value), 0));

            if (keyGp.metricName === 'ordered_speed') dataObj.avgInstructedSOG = Utilities.renderNumber(Utilities.roundToDigits(keyGp.value, 1));
            if (keyGp.metricName === 'speed_overground') dataObj.avgSOG[0] = Utilities.renderNumber(Utilities.roundToDigits(keyGp.value, 1));
            if (keyGp.metricName === 've_1_speed_avg' && dataObj.avgSOG[1]) dataObj.avgSOG[1] = Utilities.renderNumber(Utilities.roundToDigits(parseFloat(keyGp.value), 1));

            if (keyGp.metricName === 'rpm') dataObj.avgRPM[0] = Utilities.renderNumber(Utilities.roundToDigits(keyGp.value, 1));
            if (keyGp.metricName === 'me_1_rpm_avg') dataObj.avgRPM[1] = Utilities.renderNumber(Utilities.roundToDigits(parseFloat(keyGp.value), 1));

            if (keyGp.metricName === 'hours_atsea' && dataObj.hoursAtSea) dataObj.hoursAtSea = keyGp?.value ? `${parseInt(keyGp.value)} hrs` : '-';
            if (keyGp.metricName === 'minutes_atsea' && dataObj.hoursAtSea) dataObj.hoursAtSea += parseInt(keyGp.value) > 0 ? ` ${parseInt(keyGp.value)} mins` : '';
            if (keyGp.metricName === 'eta_next_port' && dataObj.etaNextPort) dataObj.etaNextPort = moment(keyGp.value, 'YYYY-MM-DD HH:mm:ss').isValid() ? moment(keyGp.value).format('DD/MM/YYYY - HH:mm') || '-' : '-';
            if (keyGp.metricName === 'next_port' && dataObj.nextPort) {
                if (keyGp.value && keyGp.value.length > portsCharacterLimit) keyGp.value = `${keyGp.value.substring(0, portsCharacterLimit)}...`;
                dataObj.nextPort = keyGp.value || '-';
            }
            dataObj.noReports = false;
            dataObj.clickable = true;
        });

        const orderedSpeedColumnExist = fleetDailyInfoColumnDefs.find(column => column.field === 'avgInstructedSOG')
        // if no ordered speed found on this group, remove the column
        if(!hasOrderedSpeedValues && orderedSpeedColumnExist) fleetDailyInfoColumnDefs.splice(4,1);
        if(hasOrderedSpeedValues && !orderedSpeedColumnExist) fleetDailyInfoColumnDefs.splice(4, 0, InstructedSpeedColumn);

        return dataObj;
    });

    fleetVessels.filter(obj => !fleetVesselsWithData.includes(obj.vesselId)).forEach(v => {
        tableData.push({
            noReports: true,
            vesselName: v.name,
            vesselObj: v,
            clickable: true,
        })
    })

    const sortedTableData = orderBy(tableData, item => item.vesselObj.vesselId, ['asc']);
    updateState(id, sortedTableData, widget?.id);
}


export {fleetDailyInfoPayload, fleetDailyInfo, fleetDailyInfoColumnDefs, fleetDailyInfoColumnDefsSensorDataOnly}
