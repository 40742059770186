import React from 'react';
import NameInitials from "../nameInitials/nameInitials";
import {ReactComponent as DefaultTemplateIcon} from "../../assets/img/app/dashboard-imgs/defaultTemplateIcon.svg";
import Utilities from 'Utilities/global';
import moment from "moment";

const SingleDashboardTitleInfo = (props) => {

    const displayFullName = type => {
        return type === 'DEEPSEA' ? 'Deepsea' : Utilities.getUserFullName();
    }

    return (
        <div className="marg-t-5 d-flex align-items-center">
            {props.type === "DEEPSEA" ? <DefaultTemplateIcon/> : <NameInitials name={props.initialsCreatedBy}/> }

            <div className="d-flex flex-column marg-l-15">
                <div className="small-label-regular">Created by {displayFullName(props.type)}</div>
                <div className="small-label-regular">
                    Last edit: {moment(props.updatedOn).utc().format('DD/MM/YYYY')}
                </div>
            </div>
        </div>
    )
}

export default SingleDashboardTitleInfo;
