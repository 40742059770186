import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {TextField, MenuItem, Select, InputLabel, Input,
    Checkbox, ListItemText, FormControl, FormHelperText } from '@material-ui/core';
import {ReactComponent as SmallArrow} from 'assets/img/app/global-imgs/small-arrow.svg';

const SelectField = props => {
    const [textFieldProps, setTextFieldProps] = useState({});

    useEffect(() => {
        if(!props.name) return;
        
        let tempTextFieldProps = {
            select: true,
            id: props.name,
            label: props.label,
            className: props.classNames.element,
            value: props.placeholder ? (props.value === '' ? -1 : props.value) : props.value,
            onChange: props.onChange,
            onBlur: props.onBlur,
            disabled: props.disabled,
            //focused: props.autoFocus,
            error: props.error,
            helperText: props.helperText,
            placeholder: props.placeholder,
            SelectProps: {
                MenuProps:{
                    anchorOrigin:{
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                },
                IconComponent: props => React.cloneElement(props.IconComponent || <SmallArrow />, {...props, className:`svg-path rotate-180 ${props.className}`})
            }
        };

        if(props.multi) {
            delete tempTextFieldProps.select;
            delete tempTextFieldProps.id;
            delete tempTextFieldProps.helperText;
            delete tempTextFieldProps.error;
            tempTextFieldProps.MenuProps = {...tempTextFieldProps.SelectProps.MenuProps};
            tempTextFieldProps.IconComponent = tempTextFieldProps.SelectProps.IconComponent;
            delete tempTextFieldProps.SelectProps;
            delete tempTextFieldProps.className;
            delete tempTextFieldProps.disabled;
           
        };

        setTextFieldProps(tempTextFieldProps);
    }, [props, props.multi]);

    return (
        <div className={`input-group ${props.classNames.wrapper}`}>
            { (props.multi) ?
                <FormControl className={props.classNames.element} disabled={props.disabled} error={props.error}>
                    <InputLabel id="multiselect-label" aria-describedby="multiselect-text">{props.label}</InputLabel>
                    <Select multiple
                        labelId="multiselect-label"
                        value={[]}
                        renderValue={selected => selected.map(sel => props.options.filter(opt => opt.value === sel)[0]).filter(und => und).map(se => (se.label)).join(', ')}
                        input={<Input />} {...textFieldProps}>
                        {props.options.map(option => (
                            <MenuItem className="flex-centered-start form-description" key={`${props.name}-${option.label}`} value={option.value}>
                                <Checkbox checked={props.value.indexOf(option.value) > -1} />
                                <ListItemText primary={option.label} />
                            </MenuItem>
                        ))}
                    </Select>
                    {props.helperText !== '' && <FormHelperText id="multiselect-text">{props.helperText}</FormHelperText>}
                </FormControl>
                : <TextField {...textFieldProps}>
                    {props.placeholder  && props.value === '' && <MenuItem value={-1} disabled><em>{props.placeholder}</em></MenuItem>}
                    {props.options.map(option => (
                        <MenuItem key={`${props.name}-${option.label}`} value={option.value}>{option.label}</MenuItem>
                    ))}
                </TextField>
            }
        </div>
    );
};

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
    ]).isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    IconComponent: PropTypes.object,
    classNames: PropTypes.object,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    multi: PropTypes.bool,
    // start code for material ui
    error: PropTypes.bool,
    helperText: PropTypes.string,
};

SelectField.defaultProps = {
    label: '',
    onBlur: () => {},
    IconComponent: <SmallArrow />,
    classNames: {wrapper: '', element: ''},
    placeholder: '',
    autoFocus: false,
    disabled: false,
    multi: false,
    // start code for material ui
    error: false,
    helperText: '',
};

export default SelectField;