import React from 'react';
import {ReactComponent as FuelOverConsumptionIcon} from 'assets/img/app/alerts-imgs/fuelOverConsumption.svg';
import {ReactComponent as SevereWind} from 'assets/img/app/alerts-imgs/SevereWeather.svg';

const AlertsTypeImgMapper = (props) => {
    return (
        <>
            { (props.type === 0 || props.type === 1) && <FuelOverConsumptionIcon className="svg-path-dark" /> }
            { (props.type === 2 || props.type === 3) && <SevereWind className="svg-path-dark" /> }
        </>
    );
}
 
export default AlertsTypeImgMapper;