import meStorage from 'components/monitoringCategories/localStorages/meStorage';
import ThemeColors from "sass/_themeColorsForJs.scss";
import chartOptions from "components/charts/lineChart/react-lineChart";
import chartUtils from 'Utilities/charts';
import { vesselUtils } from 'common/store/storeUtils';
import { csvXlsxExportForCurvesAndScatterWithDatetime, downloadChartInCSVform, downloadChartInXLSXform } from "../../Utilities/highchartsUtils";

const chartSeries = [  
    {name: 'Actual Data', data: [], tooltipTitle: 'Scavenge Air Press', yAxis: 'scav-axis', type: 'scatter', 
        color: ThemeColors['main_blue'], 
        customTooltipBorderColor: ThemeColors['main_blue'],
        marker: {enabled: true, symbol: 'circle'},
        tooltip: {valueSuffix: ' bar', valueDecimals: 2}
    }
];

const yAxisData = [{id: 'scav-axis', title: {text: "Scavenge Air Press (bar)"}, opposite: false}];

const columnHeaderMapper = {
    'Actual Data': 'Actual Data (bar)'
}

const fillSeriesWithData = (series, scatterData) => {
    // fill series and update chart's legend for scatter data
    series[0].data = scatterData?.DEFAULT?.SCATTER_PLOT || [];
    series[0].showInLegend = series[0]?.data?.length;
}

// the data that we receive here are for the scatter plots
const updateSCAVPowerChart = (id, data, updateState, extraChartConfigs, widget) => {

    const scatterData = meStorage.SCAVPowerScatterPlotsData ? meStorage.SCAVPowerScatterPlotsData : data;

    // handle empty data case, return empty object so that the noData component appears (show the NoData component when we do not have any scatter data)
    if(!scatterData) return updateState(id, {}, widget?.id);

    const chartData = Object.assign({}, chartOptions(':.2f', '%'));

    // set the series data of the chart
    chartData.yAxis = yAxisData;
    chartData.series = [...chartSeries];
    fillSeriesWithData(chartData.series, scatterData);

    // set some chart options that are common in curves-scatter charts
    chartUtils.curvesAndScatterDataChart.setCommonChartOptions(chartData);

    // set extra options that are specific for this chart
    chartData.chart.height = 360;
    chartData.chart.marginTop = 95;
    chartData.legend.y = 15;
    chartData.xAxis.title = { text: "Power (kW)", y: 10 };
    chartData.exporting.filename = `${vesselUtils.getVesselName(widget.vesselIds[0])}-MAIN ENGINE PSCAV VS POWER`;
    chartData.exporting.csv.columnHeaderFormatter = (item) => chartUtils.curvesAndScatterDataChart.setExportingColumns(item, columnHeaderMapper, 'Power (kW)');

    chartData.exporting.buttons.contextButton = {
        menuItems: [
            "download",
            "customSeparator",
            {
                text: "XLSX",
                onclick: function () {
                    const dto = csvXlsxExportForCurvesAndScatterWithDatetime(this, 'kW', 'bar')
                    downloadChartInXLSXform({xlsxRows: dto.rows, sheetsName: dto.sheetsName.trim().length > 30 ? 'MAIN ENGINE PSCAV VS POWER' : dto.sheetsName, name: dto.name});
                }
            },
            {
                text: "CSV",
                onclick: function () {
                    const dto = csvXlsxExportForCurvesAndScatterWithDatetime(this, 'kW', 'bar')
                    const csvContent = dto.rows.map(row => row.join(',')).join('\n');
                    downloadChartInCSVform({csvContent, name: dto.name});
                }
            },
        ]
    }
    // tooltip for scatter data
    const tooltipExtraOptions = {
        titleX: 'Power: ',
        valueXSuffix: 'kW',
        width: '130px'
    };
    chartData.tooltip.formatter = function() {return chartUtils.curvesAndScatterDataChart.tooltipFormatter.call(this, tooltipExtraOptions)};

    if (!widget?.inDashboard) meStorage.setSCAVPowerScatterPlotsData(scatterData);

    return updateState(id, chartData, widget?.id);
}

export { updateSCAVPowerChart };
