import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';


const TogglerButton = props => {
    return (
        <Field name={props.name}>
            {({ field, form }) => (
                <React.Fragment>
                    { !props.displayLabelAtEnd && <span className={props.labelClass}>{props.label}</span> }
                    <label className="switcher-toggle">
                        <input
                            type="checkbox"
                            {...props}
                            checked={field.value}
                            value={field.value || ''}
                            onChange={(e) => {
                                props.onChange(e);
                                form.setFieldValue(props.name, !field.value);
                            }}
                        />
                        <span className="switcher-slider"></span>
                    </label>
                    { props.displayLabelAtEnd && <span className={props.labelClass}>{props.label}</span> }
                </React.Fragment>
            )}
        </Field>
    );
}

TogglerButton.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    displayLabelAtEnd: PropTypes.bool,
    labelClass: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool
};

TogglerButton.defaultProps = {
    placeholder: '',
    displayLabelAtEnd: true,
    labelClass: '',
    className: '',
    disabled: false,
};

export default TogglerButton;