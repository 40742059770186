import React, { useState, useEffect } from 'react';
import ReportsService from "common/js/reports";
import Highcharts from "highcharts";
import chartOptions from "components/charts/lineChart/react-lineChart";
import Layout from 'components/core/Layout';
import geStorage from "./localStorages/geStorage";
import { loadHistoryPayload, loadHistoryPayload_light, loadHistory as updateLoadHistoryLineChartJSON, setGesNum} from "../../widgets/generatorEngine/loadHistoryLineChart.js";
import { fuelBarChartPayload, fuelBarChartPayload_light, updateFuelBarChart } from "widgets/generatorEngine/fuelBarChart.js";
import { fuelLineChartPayload,fuelLineChartPayload_light,updateFuelLineChart } from "widgets/generatorEngine/fuelLineChart.js";
import FuelConsumptionLineChart from "widgets/generatorEngine/fuelLineChart.jsx";
import { geDetailsPayload, updateGeDetails, geDetailsConfig, geDetailsHardCodedKeys} from "widgets/generatorEngine/geDetails";
import {licensing} from "common/store/licensing";
import LoadHistoryLineChart from "../../widgets/generatorEngine/loadHistoryLineChart.jsx";
import service from "../../common/js/service";
import FuelConsumptionBarChart from "../../widgets/generatorEngine/fuelBarChart.jsx";
import RunningTime from "../../widgets/generatorEngine/runningTime.jsx";
import {updateRunningTime} from "../../widgets/generatorEngine/runningTime.js";
import SyncedChartWithTabNavigation from "../syncedChartWithTabNavigation/syncedChartWithTabNavigation.jsx";
import {turboChargersChartPayload, turboChargersLineChartData, updateTurboChargersChartJson} from "../../widgets/generatorEngine/turboChargersLineChart";
import GeneratorEngineDetails from 'components/monitoringCategories/geComponents/generatorEngineDetails.jsx';
import ArrivalDepartureFooter from "components/footers/staticFooters/arrivalDepartureFooter.jsx";
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import {userStore, monitoringStore, vesselStore, monitoringUtils} from 'common/store/storeUtils';
import {useDispatch, useSelector} from 'react-redux';
import GlobalUtilities from 'Utilities/global';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);


let isMounted = false;

const GeneratorEngine = () => {
  const reportsService = new ReportsService();
  const [runningTime, setRunningTime] = useState({});
  const [geDetailsLineChartJSON, setGeDetailsLineChartJSON] = useState(1);
  const [lineChartTurboChargerJSON, setLineChartTurboChargerJSON] = useState({});
  const [fuelLineChartJson, setFuelLineChartJson] = useState({});
  const [fuelBarChartJson, setFuelBarChartJson] = useState({});
  const [lineChartLoadsHistoryJSON, setLineChartLoadsHistoryJSON] = useState({});
  const [requestOnGoing, setRequestOnGoing] = useState({
    loadHistory: false,
    fuelBarChartJson: false,
    fuelLineChartJson: false,
    geDetailsLineChartJSON: false,
    turboChargerLineChartJson: false,
    runningTime: false,
  });

  // store vars
  const dispatch = useDispatch();
  const monitoringStoreJSX = useSelector(state => state.monitoring);

  let reportsComponents = [];

  const reportsComponentsLight = [
    { widgetId: 'fuelLineChartJson', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: fuelLineChartPayload_light, type: 'basicChart', specificDates: false },
    { widgetId: 'fuelBarChartJson', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: false}, payload: fuelBarChartPayload_light, type: 'basicChart' },
    { widgetId: 'loadHistory', prerequisite: { value: true, request: () => {return {method: service.getGeInfo, params:
      userStore().user?.company?.id }}, callback: setGesNum },
      inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: false, externalURequest: false}, payload: loadHistoryPayload_light, type: 'basicChart'},
      { widgetId: 'runningTime', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getRunningTimeSensoredAndTelegram, params:
        {vesselId: vesselStore().selectedVessel, from: monitoringStore().fromTo.from, to: monitoringStore().fromTo.to}}}}}, payload: '', type: 'text'}
  ]

  const reportsComponentsPremium = [
    { widgetId: 'geDetailsLineChartJSON', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: geDetailsPayload, type: 'basicChart'},
    { widgetId: 'turboChargerLineChartJson', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: turboChargersChartPayload, type: 'basicChart' },
    { widgetId: 'fuelLineChartJson', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: fuelLineChartPayload, type: 'basicChart', specificDates: false },
    { widgetId: 'fuelBarChartJson', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: fuelBarChartPayload, type: 'basicChart' },
    { widgetId: 'loadHistory', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: false}, payload: loadHistoryPayload, type: 'basicChart' },
    { widgetId: 'runningTime', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getRunningTimeSensoredAndTelegram, params:
      {vesselId: vesselStore().selectedVessel, from: monitoringStore().fromTo.from, to: monitoringStore().fromTo.to}}}}}, payload: '', type: 'text'}
  ];

  const updateRequestOnGoing = (key, value) => {
    setRequestOnGoing((currentRequestOnGoing) => {return {...currentRequestOnGoing, [key]: value}});
  }

  const setReportsPlotLineGuides = (data) => {
    let plotObject = { xAxis: {} };
    plotObject.xAxis = Object.assign({}, { plotBands: chartOptions().xAxis.plotBands }, { plotLines: chartOptions().xAxis.plotLines });

    const line = data[0]?.values.find((obj) => obj.value.length > 0) ? data[0].values.find((obj) => obj.value.length > 0).value : null;

    if (line && !line.length > 0) return;
    plotObject.xAxis.plotBands.from = plotObject.xAxis.plotLines[0].value = monitoringStore().detailedData.status
        ? monitoringStore().detailedData.guidesStart
        : null
    plotObject.xAxis.plotBands.to = plotObject.xAxis.plotLines[1].value = monitoringStore().detailedData.status
        ? monitoringStore().detailedData.guidesEnd
        : null;

    return plotObject;
  }

  const extraChartsConfigMapper = widgetId => {
    if(!monitoringStore().detailedData.status) return null;

    const mapper = {
      loadHistory: {
        plotLines: { setReportsPlotLineGuides }
      },
      fuelLineChartJson: {
        plotLines: {setReportsPlotLineGuides}
      },
      turboChargerLineChartJson: {
        plotLines: {setReportsPlotLineGuides}
      },
      geDetailsLineChartJSON: {
        plotLines: {setReportsPlotLineGuides}
      },
      runningTime: null
    }

    return mapper[widgetId];
  }

  const stateMapper = (key, value) => {
    const mapper = {
      loadHistory: setLineChartLoadsHistoryJSON,
      fuelBarChartJson: setFuelBarChartJson,
      fuelLineChartJson: setFuelLineChartJson,
      geDetailsLineChartJSON: setGeDetailsLineChartJSON,
      turboChargerLineChartJson: setLineChartTurboChargerJSON,
      runningTime: setRunningTime
    }
    return mapper[key](value);
  }

  const reportsResponseMapper = (data, widgetId) => {
    const mapper = {
      fuelBarChartJson: updateFuelBarChart,
      fuelLineChartJson: updateFuelLineChart,
      loadHistory: updateLoadHistoryLineChartJSON,
      geDetailsLineChartJSON: updateGeDetails,
      turboChargerLineChartJson: updateTurboChargersChartJson,
      runningTime: updateRunningTime
    }

    return mapper[widgetId](widgetId, data, stateMapper, extraChartsConfigMapper(widgetId), reportsComponents.filter(report => report.widgetId === widgetId)[0]);
  }

  const cachedResponsesMapper = (widgetId) => {
    const responseMapper = {
      fuelBarChartJson: geStorage.fuelBarChartData,
      fuelLineChartJson: geStorage.fuelLineChartData,
      loadHistory: geStorage.loadHistoryData,
      geDetailsLineChartJSON: geStorage.geDetailsData,
      turboChargerLineChartJson: geStorage.turboChargerLineChartData,
      runningTime: geStorage.runningTime
    }
    return responseMapper[widgetId];
  }

  /**This function fetch generator engines data and set the state. */
  const getGesData = () => {
    const from = monitoringStore().detailedData.status ? monitoringStore().detailedData.from : monitoringStore().fromTo.from;
    const to = monitoringStore().detailedData.status ? monitoringStore().detailedData.to : monitoringStore().fromTo.to;

    registerWidgetsStore.setFromTo({from, to});

    // Light View report component
    reportsComponents = licensing.lightCondition() ? reportsComponentsLight : reportsComponentsPremium;

    //  in detailed view, change the timeGroup to minutes (for fuel line chart and load history line chart)
    fuelLineChartPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
    loadHistoryPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
    turboChargersChartPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
    geDetailsPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';

    if(!geStorage.isGlobalStateEmpty()) {
      reportsComponents.map(comp => {
        return reportsResponseMapper(cachedResponsesMapper(comp.widgetId), comp.widgetId)
      })
      return;
    }

    reportsService.registerReports([vesselStore().selectedVessel], reportsComponents, reportsResponseMapper, updateRequestOnGoing, true);
  }

  const updateGeComponent = () => {
    getGesData();
  }

  useEffect(() => {
    if (!monitoringStore().fromToChanged) return;
    if (!isMounted) return;

    //reset fromToChanged redux var in order to trigger the next fromTo dates
    dispatch({ type: 'monitoring/setFromToChanged', payload: false });

    //invalid period if more than 3 months (91 days)
    if(monitoringStore().fromTo.to - monitoringStore().fromTo.from > 7862400000) return;
    
    // local storage needs to be initialized, since requests need to be made again for detailedData mode
    monitoringUtils.resetLocalMonitoringStores();

    return updateGeComponent();
    // eslint-disable-next-line
  }, [monitoringStoreJSX.fromToChanged]);

  useEffect(() => {
    if (!isMounted) return;

    // local storage needs to be initialized, since requests need to be made again for detailedData mode
    monitoringUtils.resetLocalMonitoringStores();

    if (monitoringStore().detailedData && monitoringStore().detailedData.status) return updateGeComponent();
    // eslint-disable-next-line
  }, [monitoringStoreJSX.detailedData]);

  useEffect(() => {
    if (!monitoringStore().resetLegView) return;
    if (!isMounted) return;

    dispatch({ type: 'monitoring/setResetLegView', payload: false });

    // local storage needs to be initialized, since requests need to be made again for detailedData mode
    monitoringUtils.resetLocalMonitoringStores();

    return updateGeComponent();
    // eslint-disable-next-line
  }, [monitoringStoreJSX.resetLegView]);

  useEffect(() => {
    isMounted = true;
    const controller = new AbortController();
    GlobalUtilities.signal = controller.signal;

    if (!monitoringStore().fromTo.from && !monitoringStore().fromTo.to) return;

    updateGeComponent();

    return () => {
      controller.abort();
      isMounted = false;
      GlobalUtilities.signal = null;
    };
    // eslint-disable-next-line
  }, []);

  const getTitlesFromWidgetIds = (widgetId) => {
    const idsTitlesMapper = {
      loadHistory: 'GENERATOR ENGINES LOAD HISTORY',
      fuelBarChartJson: 'GENERATOR ENGINES FUEL CONSUMPTION',
      fuelLineChartJson: 'GENERATOR ENGINES FUEL CONSUMPTION RATE',
      generatorEngineDetails: 'GENERATOR ENGINE DETAILS',
      turboChargerLineChartJson: 'TURBOCHARGER',
      runningTime: 'RUNNING TIME'
    }

    return idsTitlesMapper[widgetId];
  }

  const getWidgetsPreferences = () => {

    return {
      className: 'padding-left-right-7',
      layout: [
        { columns: [
          { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            components: [
              { id: 'loadHistory', component: LoadHistoryLineChart,
              title: getTitlesFromWidgetIds('loadHistory'), data: {}, style: {height: '482px'}, props: {} },
            ]
          }
        ]},
        { columns: [
          { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 4 },
            components: [
              { id: 'fuelBarChartJson', component: FuelConsumptionBarChart,
              title: getTitlesFromWidgetIds('fuelBarChartJson'), data: {}, style: {height: '568px'}, props: {} },
            ]
          },
            { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 8 },
              components: [
                { id: 'fuelLineChartJson', component: FuelConsumptionLineChart, footer: <ArrivalDepartureFooter/>,
                  title: getTitlesFromWidgetIds('fuelLineChartJson'), data: {}, style: {height: '568px'}, props: {} },
              ]
            }
        ]},
        { columns: [
          { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            components: [
              { id: 'runningTime', component: RunningTime,
                title: getTitlesFromWidgetIds('runningTime'), data: {}, style: {minHeight: '211px'}, props: {} },
            ]
          },
        ]},
        { columns: [
          { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            components: [
              { id: 'geDetailsLineChartJSON', component: GeneratorEngineDetails,
              title: getTitlesFromWidgetIds('generatorEngineDetails'), data: {}, style: {height: '910px'}, props: {removeFromLayout: licensing.lightCondition()} },
            ]
          }
        ]},
        { columns: [
            { grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
              components: [
                { id: 'turboChargerLineChartJson', component: SyncedChartWithTabNavigation,
                  title: getTitlesFromWidgetIds('turboChargerLineChartJson'), data: {}, style: {height: '840px'}, props: { removeFromLayout: licensing.lightCondition() } },
              ]
            },
          ]
        }
      ]
    };
  };

  const createLayoutProps = (data, props = getWidgetsPreferences()) => {
    props.layout = props.layout.map(newLayout => {
        newLayout.columns = newLayout.columns.map(newColData => {
            ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
                if (('id' in newComponent) && !('element' in newComponent)) {
                    if (newComponent.id in data) newComponent.props = data[newComponent.id];
                }
                return newComponent;
            }));
            ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
            return newColData;
        });
        return newLayout;
    });
    return props;
  };

  const data = {
    loadHistory: {
      data: lineChartLoadsHistoryJSON,
      options: {
        exporting: {
          ...(lineChartLoadsHistoryJSON?.chartData?.exporting),
          filename: getTitlesFromWidgetIds('loadHistory')
        },
        height: 482
      },
      loading: requestOnGoing.loadHistory
    },
    fuelBarChartJson: {
      data: fuelBarChartJson,
      loading: requestOnGoing.fuelBarChartJson
    },
    fuelLineChartJson: {
      data: {
        ...fuelLineChartJson,
        exporting: {...fuelLineChartJson.exporting, filename: getTitlesFromWidgetIds('fuelLineChartJson')}
      },
      loading: requestOnGoing.fuelLineChartJson
    },
    turboChargerLineChartJson: {
      data: {...lineChartTurboChargerJSON},
      chartSeries: turboChargersLineChartData.chartSeries,
      navigationMapper: turboChargersLineChartData.navigationMapper,
      chartAxes: turboChargersLineChartData.chartAxes,
      chartHeight: 770,
      className: "turboChargerLineChartJson",
      tabs: turboChargersLineChartData.turboChargersLineChartTabs,
      plotLinesSetter: setReportsPlotLineGuides,
      exportingChartTitle: getTitlesFromWidgetIds('turboChargerLineChartJson'),
      divideBySubId: true,
      loading: requestOnGoing.turboChargerLineChartJson
    },
    runningTime: {
      data: runningTime,
      loading: requestOnGoing.runningTime
    },
    generatorEngineFuel: {
      fuelLineChartJson: {
        ...fuelLineChartJson,
        exporting: {...fuelLineChartJson.exporting, filename: getTitlesFromWidgetIds('generatorEngineFuel')}
      },
      loading: requestOnGoing.fuelLineChartJson
    },
    geDetailsLineChartJSON: {
      data: {...geDetailsLineChartJSON},
      chartSeries: geDetailsConfig.chartSeries,
      navigationMapper: geDetailsHardCodedKeys.navigationMapper,
      chartAxes: geDetailsConfig.chartAxes,
      chartHeight: 770,
      className: "geDetailsLineChartJSON",
      tabs: geDetailsHardCodedKeys.names,
      engineTabs: geDetailsHardCodedKeys.enginesMapper,
      plotLinesSetter: setReportsPlotLineGuides,
      exportingChartTitle: getTitlesFromWidgetIds('generatorEngineDetails'),
      divideBySubId: true,
      loading: requestOnGoing.geDetailsLineChartJSON
    }
  }

  return (
    <div className="generator-engine-page">
      <div className="generator-engine-page__widgets">
        <Layout {...createLayoutProps(data)} />
      </div>
    </div>
  );
}

export default GeneratorEngine;
