import moment from 'moment';

const initialDashboardState = {
    fromTo: {
        from: moment().subtract(1, 'day').valueOf(), 
        to: moment().valueOf()
    },
    editMode: false,
    selectedVesselDashboard: -1,
    selectedVesselGroupDashboard: {},
    currentSelectedId: -1,
    currentSelectedTitle: '',
    showRenameModal: false,
    showDeleteModal: false,
    showUndoDeletePopup: false,
    widgetToAdd: {},
    showAddWidgetModal: false,
    templateHasChanges: false
}

// dashboard reducer
const dashboard = (state = initialDashboardState, action) => {
    switch(action.type) {
        case 'dashboard/setFromTo': {
            return {
                ...state,
                fromTo: action.payload
            };
        }
        case 'dashboard/setEditMode': {
            return {
                ...state,
                editMode: action.payload
            };
        }
        case 'dashboard/setSelectedVesselDashboard': {
            return {
                ...state,
                selectedVesselDashboard: action.payload
            };
        }
        case 'dashboard/setSelectedVesselGroupDashboard': {
            return {
                ...state,
                selectedVesselGroupDashboard: action.payload
            };
        }
        case 'dashboard/setCurrentSelectedId': {
            return {
                ...state,
                currentSelectedId: action.payload
            };
        }
        case 'dashboard/setCurrentSelectedTitle': {
            return {
                ...state,
                currentSelectedTitle: action.payload
            };
        }
        case 'dashboard/setShowRenameModal': {
            return {
                ...state,
                showRenameModal: action.payload
            };
        }
        case 'dashboard/setShowDeleteModal': {
            return {
                ...state,
                showDeleteModal: action.payload
            };
        }
        case 'dashboard/setShowUndoDeletePopup': {
            return {
                ...state,
                showUndoDeletePopup: action.payload
            };
        }
        case 'dashboard/setWidgetToAdd': {
            return {
                ...state,
                widgetToAdd: action.payload
            };
        }
        case 'dashboard/setShowAddWidgetModal': {
            return {
                ...state,
                showAddWidgetModal: action.payload
            };
        }
        case 'dashboard/setTemplateHasChanges': {
            return {
                ...state,
                templateHasChanges: action.payload
            };
        }
        default: 
            return state;
    }
}

export default dashboard;