

import SessionStorage from 'common/js/Storage';

export const darkChartVariables = {
    platform_background: '#081522',
    second_topbar: '#091A29',
    first_level_card: '#0A1C2D',
    second_level_card: '#142535',
    hover_platform: '#1E2E3E',

    dropdown_bg: '#142535',

    widgets_shadow: '0 3px 6px rgba(0,0,0,0.16)',
    widgets_shadow_hover: '0 3px 16px rgba(0,0,0,0.16)',
    popover_shadow: '0 3px 14px rgba(0,0,0,0.6)',

    skeletonColor: '#192A3A',

    primary_text: '#FFFFFF',
    secondary_text: '#B6CAE6',
    error_text: '#FF4343',
    value_text: '#9CBAFF',
    main_blue: '#3F6FFF',
    disabled_text: '#78828B',
    fine_text: '#38B36D',
    reports_color: '#7FB4C5',
    sensor_color: '#3F6FFF',
    ais_color: '#B97BF0',
    standaredWhite: '#FFFFFF',

    hover_button: '#0134B4',
    disabled_button: '#1A3A81',
    hover_second_button: '#132D5E',

    high_severity: '#DA354A',
    medium_severity: '#FF8338',
    low_severity: '#FFD426',
    fine_status: '#1A8649',
    disabled_surface: '#394856',

    second_stack_sensor: '#345BD1',
    third_stack_sensor: '#2847A3',
    forth_stack_sensor: '#1D3375',
    fifth_stack_sensor: '#121F47',

    missing_first_stack_sensor: '#84A3FF',
    missing_second_stack_sensor: '#5979D9',
    missing_third_stack_sensor: '#4F68B4',
    missing_forth_stack_sensor: '#46588E',
    missing_fifth_stack_sensor: '#3D4768',
    
    second_stack_reports: '#6894A2',
    third_stack_reports: '#51737E',
    forth_stack_reports: '#3A535B',
    fifth_stack_reports: '#243237',

    missing_first_stack_reports: '#ADCFDA',
    missing_second_stack_reports: '#83A7B3',
    missing_third_stack_reports: '#708C95',
    missing_forth_stack_reports: '#5D7279',
    missing_fifth_stack_reports: '#4B575B',

    second_stack_ais: '#9865C5',
    third_stack_ais: '#764F9A',
    forth_stack_ais: '#463361',
    fifth_stack_ais: '#2B213E',
    missing_first_stack_ais: '#CEA3F5',
    missing_second_stack_ais: '#A582C4',
    missing_third_stack_ais: '#866A9F',
    missing_forth_stack_ais: '#5D496E',
    missing_fifth_stack_ais: '#3E314A',

    widget_header_divider: '#192A3A',
    lines_on_charts: '#31404F',

    golden_corn: '#B1A965',
    violet: '#9A3BA5',
    green_bay: '#68A4A2',
    amaranth: '#E9627D',
    carrot_orange: '#DF9825',
    cerulean: '#0289BA',
    brandy_rose: '#B88986',

    curious_blue: '#247DDC',

    amaranth_red: '#E8384F',

    sahara: '#C2A014',

    first_reference_line: '#9A3BA5',
    second_reference_line: '#C9184A',
    third_reference_line: '#FF758F',
    forth_reference_line: '#247DDC',
    fifth_reference_line: '#FEF6F6',
    sixth_reference_line: '#A2A2A2',
    seventh_reference_line: '#B6CAE6',

    malibu_blue: '#7FB0FB',
    tomato: '#FC664F',
    fuchsia: '#C669AF',

    brick_red: '#C94A34',
    terra_cotta: '#D97568',
    ruby_color: '#C91A62',
    amethyst: '#9455E3',
    tacao: '#F3B074'
};

export const whiteChartVariables = {
    platform_background: '#FAFAFB',
    second_topbar: '#FCFCFD',
    first_level_card: '#FFFFFF',
    second_level_card: '#FAFAFA',
    hover_platform: '#F2F2F2',

    dropdown_bg: '#FFFFFF',

    widgets_shadow: '0 3px 6px rgba(0,0,0,0.16)',
    widgets_shadow_hover: '0 3px 16px rgba(0,0,0,0.16)',
    popover_shadow: '0 3px 14px rgba(0,0,0,0.6)',

    skeletonColor: '#E2E4E5',

    primary_text: '#0A1C2D',
    secondary_text: '#516B92',
    error_text: '#C80E3C',
    value_text: '#2388E8',
    main_blue: '#3366FF',
    disabled_text: '#A3A8AC',
    fine_text: '#0B832C',
    reports_color: '#12799B',
    sensor_color: '#3F6FFF',
    ais_color: '#8B23E6',
    standaredWhite: '#FFFFFF',

    hover_button: '#0134B4',
    disabled_button: '#ACC1FF',
    hover_second_button: '#D8E2FF',

    high_severity: '#D21B2F',
    medium_severity: '#F6800A',
    low_severity: '#E6CF01',
    fine_status: '#00A72E',
    disabled_surface: '#C3C6C7',

    second_stack_sensor: '#2A54D1',
    third_stack_sensor: '#2141A3',
    forth_stack_sensor: '#172F75',
    fifth_stack_sensor: '#0E1D47',

    missing_first_stack_sensor: '#7C9DFF',
    missing_second_stack_sensor: '#5073D9',
    missing_third_stack_sensor: '#4963B4',
    missing_forth_stack_sensor: '#41548E',
    missing_fifth_stack_sensor: '#394668',

    second_stack_reports: '#0F637F',
    third_stack_reports: '#0C4D63',
    forth_stack_reports: '#083847',
    fifth_stack_reports: '#05222B',
    missing_first_stack_reports: '#ADCFDA',
    missing_second_stack_reports: '#3A7F96',
    missing_third_stack_reports: '#386D7F',
    missing_forth_stack_reports: '#345C68',
    missing_fifth_stack_reports: '#324A51',

    second_stack_ais: '#721DBD',
    third_stack_ais: '#591693',
    forth_stack_ais: '#663F87',
    fifth_stack_ais: '#523B66',
    missing_first_stack_ais: '#9D4AE8',
    missing_second_stack_ais: '#8945C7',
    missing_third_stack_ais: '#753FA5',
    missing_forth_stack_ais: '#7F619B',
    missing_fifth_stack_ais: '#6F5D80',

    widget_header_divider: '#E2E4E5',
    lines_on_charts: '#EBEDEF',

    golden_corn: '#9F985B',
    violet: '#772781',
    green_bay: '#68A4A2',
    amaranth: '#E63C5E',
    carrot_orange: '#E89E27',
    cerulean: '#017EAC',
    brandy_rose: '#B48683',

    curious_blue: '#0957AB',

    amaranth_red: '#C80E3C',

    sahara: '#CFAD22',

    first_reference_line: '#772781',
    second_reference_line: '#E6CF01',
    third_reference_line: '#e8384f',
    forth_reference_line: '#00A72E',
    fifth_reference_line: '#a9afbe',
    sixth_reference_line: '#3366FF',
    seventh_reference_line: '#F6800A',


    malibu_blue: '#4A8FF7',
    tomato: '#FF7A54',

    fuchsia: '#C641A5',
    brick_red: '#AB3C28',
    terra_cotta: '#D97264',
    ruby_color: '#B8195A',
    amethyst: '#7D2FE0',
    tacao: '#F2884E'
}

export const ThemeColorsForCharts = () => (SessionStorage.getCookie('user').theme !== 'ds-white') ? darkChartVariables : whiteChartVariables;