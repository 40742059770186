import React, { useEffect, useState } from 'react';
import {ReactComponent as CloseIcon} from 'assets/img/app/global-imgs/close.svg';
import {ReactComponent as DragHandle} from 'assets/img/app/dashboard-imgs/dragHandle.svg';
import WidgetItem from "./widgetItem";
import {widgetLibraryUtils} from 'components/widgetLibrary/widgetLibrary.js';
import LoadMoreContainer from "components/loadMoreContainer/loadMoreContainer";
import EditLevelModal from "components/dashboard/modals/editLevelModal";
import { useSelector, useDispatch } from 'react-redux';
import { dashboardUtils } from '../../common/store/storeUtils';
import service from '../../common/js/service';

const WidgetLibrary = (props) => {
    const [widgets, setWidgets] = useState({});
    const [loadMoreLimit, setLoadMoreLimit] = useState(dashboardUtils.dashboardProperties.EXPAND_MIN_LIMIT);

    // store vars
    const dashboardStoreJSX = useSelector(state => state.dashboard);
    const dispatch = useDispatch();

    async function getWidgetLibrary() {
        const res = await service.getWidgetLibrary();
        if (res) setWidgets(widgetLibraryUtils.normalizeResponse(res));
    }

    useEffect(() => {
        getWidgetLibrary();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.expandDrawer) {
            setLoadMoreLimit(dashboardUtils.dashboardProperties.EXPAND_MAX_LIMIT)
        } else {
            setLoadMoreLimit(dashboardUtils.dashboardProperties.EXPAND_MIN_LIMIT)
        }
        // eslint-disable-next-line
    }, [props.expandDrawer])

    return (
        <React.Fragment>
            <div className="widget-library__header justify-content-between align-items-center">
                <DragHandle className="svg-defs-path-g-maskUse se-resize dragHandle"
                            onClick={() => dashboardUtils.expandDrawer(props.expandDrawer, props.setExpandDrawer)}/>
                <div className="action-button flex-centered"
                     onClick={() => dashboardUtils.handleToggleDrawer(props.drawerClose, props.setDrawerClose, props.setExpandDrawer)}>
                    <CloseIcon className="svg-g-path-a1"/>
                </div>
            </div>
            <div className="widget-library">
                <div className="widget-library__body">
                    {Object.keys(widgets).length !== 0 && Object.keys(widgets).map((widgetGroup, index) => (
                        <div className="widget-library__body__widget-group-container" key={index}>
                            <div
                                className="headline2 widget-library__body__widget-group-container__title">{widgetLibraryUtils.orderListMapper[widgetGroup]}</div>
                            <LoadMoreContainer
                                children={widgets[widgetGroup]}
                                component={WidgetItem}
                                limit={loadMoreLimit}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <EditLevelModal
                title={"ADD WIDGET"}
                modalShow={dashboardStoreJSX.showAddWidgetModal}
                setModalShow={(value) => dispatch({ type: 'dashboard/setShowAddWidgetModal', payload: value })}
                widget={dashboardStoreJSX.widgetToAdd}
                onSubmit={props.handleAddWidget}
            />
        </React.Fragment>

    )
}

export default WidgetLibrary;
