import React from "react";
import PropTypes from "prop-types";

const FakeColumn = ({rowsCount}) => (
    <div className="table-col fake-table-col">
        <div className="header headline4"><br/></div>
        <div className="sub-col-container">
            <div className="table-col table-col-fake">
                <div className="header headline4"><br/></div>
                <div className="col-data">
                    {[...Array(rowsCount)].map((row, index) => <div key={index} className="row-element"></div>)}
                </div>
            </div>
        </div>
    </div>
);

const Column = ({column}) => {
    const isSog = column.colHeader === "SOG";
    return (
        <div className={`table-col ${isSog ? 'table-col-sog' : ''}`}>
            {column.colHeader && <div className={`header ${isSog ? 'header-sog' : ''}`}>{column.colHeader}</div>}
            {column.columns ? (
                <div className="sub-col-container">
                    {column.columns.map((subColumn, index) =>
                        <React.Fragment key={index}>
                            <Column column={subColumn}/>
                        </React.Fragment>
                    )}
                </div>
            ) : (
                <div className="col-data">
                    {column.colData.map((data, colDataIndex) => (
                        <div className="row-element" key={colDataIndex}>{data}</div>
                    ))}
                </div>
            )}
        </div>
    );
}

const TableSubColumns = ({columns, tableTitle, wrapperStyle}) => {

    const rowsCount = columns?.find(columnData => columnData.colHeader === "SOG")?.columns[0]?.colData?.length;

    return (
        <div className="table-container" style={wrapperStyle ? wrapperStyle : {width: '100%', height: '100%'}}>
            <div className="title headline4 pad-t-15 pad-b-15 ">{tableTitle}</div>
            <div className="table-data">
                {columns.map((column, index) => column.columns.length
                    ? <React.Fragment key={index}>
                        <Column column={column}/>
                        <FakeColumn rowsCount={rowsCount}/>
                    </React.Fragment>
                    : null
                )}
            </div>
        </div>
    );
};

TableSubColumns.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    tableTitle: PropTypes.element,
    wrapperStyle: PropTypes.shape({}),
}

export default TableSubColumns;