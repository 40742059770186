import React, {useEffect} from 'react';
import DropDown from "components/core/DropDown";
import {withRouter} from 'react-router-dom';
import LicenseLabel from 'components/labels/licenseLabel/licenseLabel';
import SplitedButton from 'components/splitedButton/splitedButton';
import { vesselGroupStore, vesselStore, alertsStore, vesselUtils } from 'common/store/storeUtils';
import { useSelector, useDispatch } from 'react-redux';

const FilteringFleetsTopBar = () => {
  let isScrolling = false;
  let clientX = 0;
  let scrollX = 0;

  const ref = React.createRef();

  // store vars
  const alertsStoreJSX = useSelector(state => state.alerts);
  const dispatch = useDispatch();

  const onMouseDown = e => {
      isScrolling = true;
      clientX = e.clientX;
  };
  
  const onMouseUp = () => {
      isScrolling = false;
  };
  
  const onMouseMove = e => {
      if (isScrolling) {
          ref.current.scrollLeft = scrollX - e.clientX + clientX;
          scrollX = scrollX - e.clientX + clientX;
          clientX = e.clientX;
      }
  };

  const onGroupClickHandler = fleet => {
    dispatch({ type: 'alerts/setFiltersVessel', payload: -1 });
    dispatch({ type: 'alerts/setFiltersGroup', payload: fleet });
  }

  const locationWithAllFleetsOption = () => {
    if(window.location.pathname === '/' || window.location.pathname.includes('alerts')) return true;
    return false;
  }

  let fleets = [...vesselGroupStore().allVesselGroups];
  if(locationWithAllFleetsOption()) fleets.push(vesselGroupStore().allVesselGroupsObj);

  const vesselEntityOnTopbar = vessel => {
    return (
      <div key={vessel.vesselId} className="fleets-vessels-top-bar__container__vessels-selector__group">
        <div
          className={`fleets-vessels-top-bar__container__vessels-selector__group__vessel headline4 ${vesselStore().allVessels.filter(vessel => vessel.vesselId === alertsStore().filtersVessel)[0]?.name === vessel.name ? 'active' : ''}`}
          onClick={() => {
            dispatch({ type: 'alerts/setFiltersVessel', payload: vessel.vesselId });
          }}>
          {vessel.name}
        </div>
      </div>
    )
  }

  const renderVessels = () => {
    if (alertsStore().filtersGroup.id === vesselGroupStore().allVesselGroupsObj.id) {
      return '';
    }

    const fleetVessels = vesselStore().allVessels.filter(vessel => vessel.vesselGroupId === alertsStore().filtersGroup.id); 
    const uniqueClass = fleetVessels.length === 1 ? ' unique' : '';
    return (
      <React.Fragment>
        <div className={`fleets-vessels-top-bar__container__vessels-selector${uniqueClass} flex-centered-start flex-shrink-0`}
        ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>
          {fleetVessels.filter(v => v.isPremium).map(vessel => vesselEntityOnTopbar(vessel))}

          {((fleetVessels.filter(v => !v.isPremium).length > 0) && (fleetVessels.filter(v => v.isPremium).length > 0))
          && <div className="fleets-vessels-top-bar__container__vessels-selector__vessels-ceperator">&nbsp;</div>}

          {fleetVessels.filter(v => !v.isPremium).map(vessel => vesselEntityOnTopbar(vessel))}

          {fleetVessels.filter(v => !v.isPremium).length > 0 && <LicenseLabel text={'Light'}/>}
        </div>

        <DropDown buttonLabel={vesselStore().allVesselsObj.vesselId === alertsStore().filtersVessel
          ? 'Select vessel' : vesselUtils.getSelectedVesselData().name}
                  options={fleetVessels.map(vessel => ({
                    label: vessel.name,
                    classNameWrapper: alertsStore().filtersVessel === vessel.vesselId && "selectedVessel",
                    onClick: () => {
                      dispatch({ type: 'alerts/setFiltersVessel', payload: vessel.vesselId });
                    }
                  }))}
                  classNames={{
                    button: `fleets-vessels-top-bar__container__vessels-selector${uniqueClass} main-cta flex-centered`,
                    menu: `vessels-selector${uniqueClass}`
                  }}
        />
      </React.Fragment>
    );
  };

  const addTopBarClass = () => {
    document.querySelector('.main-container')
      ? document.querySelector('.main-container').classList.add('with-fleets-top-bar')
      : setTimeout(() => document.querySelector('.main-container').classList.add('with-fleets-top-bar'));
  }

  const removeTopBarClass = () => {
    document.querySelector('.main-container')
      ? document.querySelector('.main-container').classList.remove('with-fleets-top-bar')
      : setTimeout(() => document.querySelector('.main-container').classList.remove('with-fleets-top-bar'));
  }

  useEffect(() => {
    addTopBarClass();
    return () => removeTopBarClass();
  });   

  return (
    <div className="fleets-vessels-top-bar d-flex align-items-center">
        <SplitedButton 
          dropdownOptions={fleets}
          defaultValue={alertsStoreJSX.filtersGroup}
          onOptionClickListener={onGroupClickHandler}
        />
        { renderVessels() }
    </div>
  );
}

export default withRouter(FilteringFleetsTopBar);