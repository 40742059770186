import Wind from 'Utilities/wind';
import Utilities from 'Utilities/global';
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';

const weatherMonitoringPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    metrics: [
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                { metricName: "true_wind_speed_calc" },
                { metricName: "apparent_wind_direction_calc" },
                { metricName: "true_current_speed_calc" },
			    { metricName: "true_current_direction_calc" },
                { metricName: "comb_wind_swell_wave_height" },
            ]
        }
    ]
};

const setWeatherMon = (id, response, updateState) => {
    if(response?.length === 0) return updateState(id, {});
    const series = [{},{},{},{},{},{},{}];

    //fill series with metrics data
    if(response[0] && response[0].values) response[0].values.forEach(keyGp => {
        if(keyGp.metricName === 'true_wind_speed_calc') {
            series[0].data = keyGp.value; 
            series[0].unit = keyGp.unit;
            series[5].data = keyGp.value; 
            series[5].unit = keyGp.unit;
        }

        if(keyGp.metricName === 'apparent_wind_direction_calc') {
            series[1].data = keyGp.value;
        }

        if(keyGp.metricName === 'true_current_speed_calc') {
            series[2].data = keyGp.value;
            series[2].unit = keyGp.unit;
            series[6].data = keyGp.value;
            series[6].unit = keyGp.unit;
        }

        if(keyGp.metricName === 'true_current_direction_calc') series[3].data = keyGp.value;

        if(keyGp.metricName === "comb_wind_swell_wave_height") {
            series[4].data = keyGp.value;
            series[4].unit = keyGp.unit;
        }
    });

    //if wind data then fix wind speed and direction convertions
    if(series[0].data) series[0].data = series[0].data.map((arr, index) => {
        const direction = series[1].data[index]?.y;
        return {
            x: arr.x,
            y: Wind.knotsToBeauforts(arr.y) === '-' ? null : Wind.knotsToBeauforts(arr.y),
            z: Wind.convertApparentWindAngle(direction) === '-' ? '' : ` (${Wind.convertApparentWindAngle(direction)}) `
        };
    });

    //if currents data fill currents speed and direction convertions
    if(series[2].data) series[2].data = series[2].data.map((arr, index) => {
        const direction = series[3].data[index]?.y;
        return {
            x: arr.x,
            y: Utilities.renderNumber(Utilities.roundToDigits(arr.y, 1)) === '-' ? null : Utilities.renderNumber(Utilities.roundToDigits(arr.y, 1)),
            z: Wind.convertApparentWindAngle(direction) === '-' ? '' : ` (${Wind.convertApparentWindAngle(direction)}) `
        };
    });

    //if wind data create windbarbs for wind direction
    if(series[0].data) series[5].data = series[0].data.map((arr, index) => {
        const direction = series[1].data[index]?.y;
        return {
            x: arr.x,
            value: Wind.knotsToBeauforts(arr.y) === '-' ? null : Wind.knotsToBeauforts(arr.y),
            direction: direction
        };
    });

    //if currents data create windbarbs for currents direction
    if(series[2].data) series[6].data = series[2].data.map((arr, index) => {
        const direction = series[3].data[index]?.y;
        return {
            x: arr.x,
            value: Utilities.renderNumber(Utilities.roundToDigits(arr.y, 1)) === '-' ? null : 3,
            direction: direction
        };
    });

    const chartHasData = series.find(serie => serie?.data?.length > 0);

    updateState(id, !chartHasData ? {} : {
        chart: {id: id},
        yAxis: [
            {id: 'wind-axis', title: {text: 'Wind (Bf)',}, height: '110px', top: '40px', min: 0, offset: 2, opposite: false},
            {id: 'currents-axis', title: {text: 'Currents (Kn)'}, height: '90px', top: '240px', min: 0, offset: 2, opposite: false},
            {id: 'wave-axis', title: {text: 'Sig. Wave Height (m)',}, height: '90px', top: '400px', min: 0, offset: 2, opposite: false},
        ],
        series: [
            {name: 'Wind', data: series[0].data, tooltipTitle: 'Wind', yAxis: 'wind-axis', type: 'column',
                yMin: 0, tooltip: {valueSuffix: ` bf`, valueDecimals: 1}},
            {name: 'Currents', data: series[2].data, tooltipTitle: 'Currents',
                yMin: 0, yAxis: 'currents-axis', tooltip: {valueSuffix: ` ${series[2].unit}`, valueDecimals: 1}},
            {name: 'Sig. Wave Height', data: series[4].data, tooltipTitle: 'Sig. Wave Height',
                yMin: 0, yAxis: 'wave-axis', tooltip: {valueSuffix: ` ${series[4].unit}`, valueDecimals: 1}},
            {name: 'WindArrows', type: 'windbarb', id: 'windbarbs', lineWidth: 1.5, enableMouseTracking: false, states: { inactive: { opacity: 1 } },
                data: series[5].data, vectorLength: 18, yOffset: 20, tooltip: { enabled: false }, dataGrouping: {enabled: false}},
            {name: 'CurrentsArrows', type: 'windbarb', id: 'windbarbsCurrents', lineWidth: 1.5, enableMouseTracking: false, states: { inactive: { opacity: 1 } },
                data: series[6].data, dataGrouping: {enabled: false}, vectorLength: 18, yOffset: 180, tooltip: { enabled: false }, color: ThemeColorsForCharts()['primary_text']},
        ],
    });
};


export {weatherMonitoringPayload, setWeatherMon};
