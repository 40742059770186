import moment from 'moment';
import Utilities from 'Utilities/global';
import {ReactComponent as MilesTraveled} from 'assets/img/app/global-imgs/miles_travelled.svg';
import {ReactComponent as HoursAtSea} from 'assets/img/app/global-imgs/hours_at_sea.svg';
import ThemeColors from "sass/_themeColorsForJs.scss";
import {reportsStore} from 'common/store/storeUtils';

const legInfoPayload = {
    metrics: [{
        metricCategory: "TELEGRAM",
        subIds: [1],
        metricData: [ 
            {metricName: "minutes_atsea_corrected", aggregation: "SUM"},
            {metricName: "hours_atsea_corrected", aggregation: "SUM"},
            {metricName: "miles_atsea", aggregation: "SUM"}
        ]
    }, {
        metricCategory: "VESSEL",
        subIds: [1],
        metricData: [
            {metricName: "miles_atsea", aggregation: "SUM"}
        ]
    }]
};

const legInfo = (id, response, updateState, extraChartConfigs, widget) => {
    response = response.series;
    if (!response) return;
    if (!reportsStore()?.setUserLeg && ((response?.length === 0) || (response[0]?.values?.every((value) => value.value === null))) ) return updateState(id, {}, widget?.id);
    
    let data = {};
    let firstHalfValue = '';
    let firstHalfValueStyle = {color: ThemeColors['reports_color']};
    let secondHalfValue = '';
    let secondHalfValueStyle = {};
    let foundMilesAtSea = false;

    //if it is a leg from db with no search then we fill them from response
    if (response?.length > 0 && response[0]?.values?.length) {
        response[0].values.forEach(obj => {
            if (obj?.metricName === "miles_atsea") {
                if (obj.metricCategory === 'TELEGRAM' && obj.value) {
                    foundMilesAtSea = true;
                    secondHalfValue = Utilities.roundToDigits(obj.value) || '-';
                    secondHalfValueStyle = {color: ThemeColors['reports_color']};
                } else if (obj.metricCategory === 'VESSEL' && !foundMilesAtSea) {
                    secondHalfValue = Utilities.roundToDigits(obj.value) || '-';
                    secondHalfValueStyle = {color: ThemeColors['sensor_color']};
                }
            }
            if(obj?.metricName === "hours_atsea_corrected")
                firstHalfValue = `${Utilities.renderNumber(parseInt(obj.value))} hrs `.concat(firstHalfValue);
            if(obj?.metricName === "minutes_atsea_corrected")
                firstHalfValue = firstHalfValue.concat(`${Utilities.renderNumber(parseInt(obj.value))} mins`);
        })
    } 

    //if user search a leg then the hours calculated with fromToDiff
    if (reportsStore().setUserLeg) {
        const hours = moment.duration(moment(reportsStore().fromTo.to).diff(moment(reportsStore().fromTo.from), 'hours'))._data.milliseconds;
        firstHalfValue = ` ${hours || '-'} hrs`;
        if(secondHalfValue === '') secondHalfValue = '-';
        firstHalfValueStyle = {color: ThemeColors['sensor_color']};
        secondHalfValueStyle = {color: ThemeColors['sensor_color']};
    }

    data = {
        dataGroups: [
            {label: 'Hours At Sea', value: firstHalfValue || '-', style: firstHalfValueStyle, icon: HoursAtSea},
            {label: 'Miles Traveled', value: secondHalfValue, style: secondHalfValueStyle, icon: MilesTraveled},
        ],
        enableDevider: false,
        setUserLeg: reportsStore().setUserLeg
    }

    updateState(id, data, widget?.id);
}


export {legInfoPayload, legInfo};