import React from 'react';
import PropTypes from 'prop-types';

const LicenseLabel = ({text}) => {
    return (
        <div className="license-label">
            {text}
        </div>
    );
}

LicenseLabel.propTypes = {
    text: PropTypes.string
}
 
export default LicenseLabel;