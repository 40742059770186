import React from 'react';
import {ReactComponent as Cpabreach} from 'assets/img/app/events-imgs/cpaBreach.svg';
import {ReactComponent as Multipleges} from 'assets/img/app/alerts-imgs/multipleGEs.svg';
import {ReactComponent as Mergencysegment} from 'assets/img/app/alerts-imgs/emergencySegment.svg';
import {ReactComponent as Fueloverconsumption} from 'assets/img/app/alerts-imgs/fuelOverConsumption.svg';
import {ReactComponent as BunkeringIcon} from 'assets/img/app/events-imgs/bunkering-icon.svg';
import {ReactComponent as EngineDamages} from 'assets/img/app/events-imgs/engineDamages-icon.svg';
import {ReactComponent as CharterParty} from 'assets/img/app/events-imgs/charterParty-icon.svg';
import {ReactComponent as MaintenanceIcon} from 'assets/img/app/events-imgs/maintenance-icon.svg';
import {ReactComponent as NoonIcon} from 'assets/img/app/events-imgs/noon-icon.svg';
import {ReactComponent as ArrivalIcon} from 'assets/img/app/events-imgs/arrival.svg';
import {ReactComponent as DepartureIcon} from 'assets/img/app/events-imgs/departure-icon.svg';
import {ReactComponent as NextportIcon} from 'assets/img/app/timeline-imgs/nextport.svg';

const GetImageComponent = (props) => {
    return (
        <React.Fragment>
            { props.imageName === 'cpaBreach' && <Cpabreach /> }
            { props.imageName === 'multipleGEs' && <Multipleges /> }
            { props.imageName === 'emergencySegment' && <Mergencysegment /> }
            { props.imageName === 'fuelOverConsumption' && <Fueloverconsumption /> }
            { props.imageName === 'DTELEGRAM' && <DepartureIcon /> }
            { props.imageName === 'ATELEGRAM' && <ArrivalIcon /> }
            { props.imageName === 'NTELEGRAM' && <NoonIcon /> }
            { props.imageName === 'OTELEGRAM' && <NextportIcon /> }
            { props.imageName === 'VESSEL_MAINTENANCE' && <MaintenanceIcon /> }
            { props.imageName === 'VESSEL_CHARTER_AGREEMENTS' && <CharterParty /> }
            { props.imageName === 'VESSEL_BUNKERING' && <BunkeringIcon /> }
            { props.imageName === 'VESSEL_MACHINERY_DAMAGES' && <EngineDamages /> }
        </React.Fragment>
    );
}
 
export default GetImageComponent;
