const GridUtils = {
    getHiddenClasses: (viewPorts, className='') =>  {
        let classes = className;
        Object.keys(viewPorts).forEach(viewPort => {
            if(viewPorts[viewPort] === "hidden"){
                switch (viewPort) {
                    case 'xs':
                        classes += ' d-none d-sm-block';
                        break;
                    case 'sm':
                        classes = classes.replace(' d-sm-block', '');
                        classes += ' d-sm-none d-md-block';
                        break;
                    case 'md':
                        classes = classes.replace(' d-md-block', '');
                        classes += ' d-md-none d-lg-block';
                        break;
                    case 'lg':
                        classes = classes.replace(' d-lg-block', '');
                        classes += ' d-lg-none d-xl-block';
                        break;
                    case 'xl':
                    default:
                        classes = classes.replace(' d-xl-block', '');
                        classes += ' d-xl-none';
                }
            } else if (viewPort === 'xs') {
                classes += ` col-${viewPorts[viewPort]}`;
            }
            else if(viewPort === 'order') {
                const orderNum = viewPorts[viewPort]
                classes += ` order-${orderNum}-custom`;
            } 
            else {
                classes += ` col-${viewPort}-${viewPorts[viewPort]}`;
            }
        });
        return classes;
    }
}

export default GridUtils;