import React from 'react';
import PropTypes from 'prop-types';
import IconWithTextLabel from './iconWithTextLabel';
import classnames from 'classnames';

const getExtraClasses = (extraClasses) => {
    return {
        wrapperClasses: classnames('verticalLabel flex-centered-col', {[extraClasses?.wrapperClasses]: extraClasses?.wrapperClasses}),
        textClasses: classnames('table-label-element', {[extraClasses?.textClasses]: extraClasses?.textClasses})
    }
}

const LabelWithBigIcon = ({icon, text, extraClasses}) => {
    return (
        <IconWithTextLabel 
            icon={icon}
            text={text}
            extraClasses={{
                wrapperClasses: getExtraClasses(extraClasses).wrapperClasses,
                textClasses: getExtraClasses(extraClasses).textClasses
            }}
        />
    );
}

LabelWithBigIcon.propTypes = {
    icon: PropTypes.element,
    text: PropTypes.string,
    extraClasses: PropTypes.shape({})
}
 
export default LabelWithBigIcon;