import React from 'react';
import {ReactComponent as FOil} from 'assets/img/app/timeline-imgs/fuelOil.svg';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import Utilities from "../../Utilities/global";

const FoulingWaste = props => {
    return (
        <div className="foulingWaste full-height">
            <div className="container-fluid full-height ">
                <div className="row no-gutters full-height ">
                    <div
                        className="align-self-center d-flex col-sm-12 col-xs-12 col-md-4 col-lg-4 flex-column justify-content-center foulingWasteTotals">
                        <div className="d-flex justify-content-center align-items-center foulingWaste__box">
                            <FOil className="svg-path" />
                            <div className="foulingWaste__box__total d-flex justify-content-center flex-column marg-l-15">
                                <div className={`foulingWaste__box__total__value value report-big`}>{Utilities.renderNumber(Utilities.roundToDigits(props.data.wasteTones, 2))} MT</div>
                                <div className="foulingWaste__box__total__label label">FUEL WASTE</div>
                            </div>
                        </div>

                    </div>
                    <div
                        className="full-height col-sm-12 col-xs-12 col-md-8 col-lg-8">
                        <div className="ge-fc-barChart-wrapper" style={props.style}>
                            {props.data !== "" && (
                                <HighchartsReact highcharts={Highcharts} options={props.data} constructorType={"stockChart"}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FoulingWaste;
