import Utilities from "Utilities/global";

const navigationStatusChartUtils = {
    progressChartOptions: () => { 
        return {
            id: "navigation-status-progress", innerSize: '70%',
            titleY: 15, titleX: -40, export: false, width: 400, height: 300, backColor: Utilities.getThemeColors.backColor(),
            style: { color: Utilities.getThemeColors.text(), fontSize: "15px", fontFamily: "sans-serif" }
        }
    },
    dataLabels: {
        enabled: true,
        distance: -90,
        format: "{point.z}%",
        align: 'center',
        style: {
            fontWeight: 'normal',
            fontSize: '20px',
            opacity: '0'
        }
    },
    point: {
        events: {
            mouseOver: function (e) {
                if (this.dataLabel) {
                    this.dataLabel.css({
                        opacity: '1',
                        color: Utilities.getThemeColors.text()
                    });
                }
            },
            mouseOut: function (e) {
                if (this.dataLabel) {
                    this.dataLabel.css({
                        opacity: '0'
                    });
                }
            }
        }
    },
    chartLegend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        itemMarginTop: 15,
        itemMarginBottom: 15,
        width: 160,
        itemStyle: { fontSize: "12px", fontFamily: "sans-serif" },
        labelFormatter: function () {
            if (this.name) {
                const words = this.name.split(/[\s]+/), str = [], numWordsPerLine = 1;
                words.forEach((word, index) => {
                    if (index > 0 && index % numWordsPerLine === 0 && words[index].length > 5) str.push('<br>');
                    str.push(words[index]);
                })
                return str.join(' ');
            }
        }
    }
}

const navigationStatusBuildChartData = (response, updateState, widget) => {
    const data = [];
    if (response && response[0] && response[0].status) {
        response[0].status.forEach(element => {
            data.push({
                name: Utilities.getNavigationStatus(element.status).name,
                y: Utilities.roundToDigits(element.hours, 0),
                color: Utilities.getNavigationStatus(element.status).color,
                z: Utilities.roundToDigits(element.percentage, 0)
            })
        });
    }
    updateState('navigationStatusChart', data, widget?.id);
}

export { navigationStatusChartUtils, navigationStatusBuildChartData };
