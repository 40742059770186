import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


let height = -1,
    element = null,
    timeOut = null,
    border = null,
    startingTime = (new Date()).getTime();


const ExpandCollapse = props => {
    const calcHeight = (onEnter = false) => {
        if (!element) {
            return;
        }
        if (props.isAbstract) {
            //on first render
            let maxHeight = props.in ? 0 : `${height}px`;
            if (onEnter) {
                element.style.position = "relative";
                const div = document.createElement("div");
                props.hasArrow && (div.className = "hasArrow");
                element.prepend(div);
                const elementTemp = window.getComputedStyle(element.childNodes[1], null);
                height = element.childNodes[1].clientHeight + parseInt(elementTemp.borderTopWidth) + parseInt(elementTemp.borderBottomWidth);
                border = elementTemp.border;
                maxHeight = props.in ? `${height}px`: 0;
            }
            const style = {
                overflow: "hidden",
                minHeight: 0,
                top: `${props.initialStyle.top}px`,
                border: onEnter ? 0 : border,
                maxHeight,
                transition: `max-height ${props.duration}ms ease-in-out`,
            };
            element.childNodes[0].style.visibility = onEnter ? "hidden" : "visible";
            Object.assign(element.childNodes[1].style, style);
        } else {
            //on first render
            if (onEnter) {
                element.style.height = props.in ? "auto" : "0";
                element.style.overflow = props.in ? "visible" : "hidden";
                return;
            }
            //calculate Height of the element
            Object.assign(element.style, {
                position: "relative",
                display: "block",
                left: "-9999px",
                height: "auto",
                overflow: "visible",
                transition: "none",
            });
            height = element.clientHeight;
            const style = {
                position: "static",
                left: props.initialStyle.left || 0,
                overflow: "hidden",
                height: props.in ? `${height}px` : "0",
                transition: `height ${props.duration}ms ease-in-out`
            };
            Object.assign(element.style, style);
        }
    };

    const bindDomElement = el => {
        element = el;
        if (element) {
            calcHeight(true);
        }
    };

    const clearTimeout = () => {
        if (timeOut !== null) {
            clearTimeout(timeOut);
            timeOut = null;
        }
    }

    const _onTransitionEnd = () => {
        if (!!element) {
            if (props.isAbstract) {
                element.childNodes[1].style.border = props.in ? border : 0;
                element.childNodes[1].style.overflow = props.in ? "visible" : "hidden";
                element.childNodes[1].style.maxHeight = props.in ? `${height}px`: 0;
                element.childNodes[0].style.visibility = props.in ? "visible" : "hidden";
            } else {
                props.in && (element.style.height = "auto");
            }
        }
        clearTimeout();
    };


    useEffect(() => {
        let remainingTime = (new Date()).getTime() - startingTime;
        remainingTime =  remainingTime < props.duration ? remainingTime : props.duration;
        startingTime = (new Date()).getTime();
        timeOut = setTimeout(_onTransitionEnd, remainingTime);

        return () => clearTimeout();
        // eslint-disable-next-line
    }, []);

     return (
            <div ref={bindDomElement}>
            {props.children}
        </div>
    );
}

ExpandCollapse.propTypes = {
    in: PropTypes.bool.isRequired,
    duration: PropTypes.number,
    isAbstract: PropTypes.bool,
    initialStyle: PropTypes.object,
    hasArrow: PropTypes.bool,
};

ExpandCollapse.defaultProps = {
    duration: 600,
    isAbstract: false,
    initialStyle: {},
    hasArrow: false,
};

export default ExpandCollapse;
