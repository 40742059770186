import meStorage from "../../components/monitoringCategories/localStorages/meStorage";

export const mainEngineInfoNamesMapper = {
  'meMaker': 'Main Engine Maker',
  'meModel': 'Main Engine Model',
  'meMcrBhp': 'Main Engine MCR (bhp)',
  'meMcrKw': 'Main Engine MCR (kW)',
  'noCyllinders': 'Cylinders',
  'noTurboCharger': 'Turbochargers',
  'sfoc': 'Sea Trial SFOC (g/kWh)'
};

export const updateMeInfoJson = (id, data, updateState, extraChartConfigs, widget) => {
  meStorage.setMeInfoData(data);
  updateState(id, data, widget?.id);
};
