import Highcharts from 'highcharts';
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import {userStore} from 'common/store/storeUtils';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);


const stackBarChartOptions = () => {
    (userStore().user?.theme !== 'ds-white') ? Highcharts.setOptions(Highcharts.darkTheme)
    : Highcharts.setOptions(Highcharts.whiteTheme);

    let options = {
        chart: { 
            marginTop: 75,
            marginBottom: 55,
            type: "column",
            height: 340,
        },

        title: { text: '', style: {fontSize: '14px'} },

        legend:{
            layout: 'horizontal',
            verticalAlign: 'top',
            itemMarginBottom: 25,
            alignColumns: false,
            labelFormat: '{userOptions.tooltipTitle}',
        },

        yAxis: {
            title: { text: '' }
        },

        xAxis: { 
            title: { text: '' },
            tickLength: 0,
            type: 'category',
            labels: {},
            offset: 10,
            lineColor: 'transparent'
        },

        plotOptions: {
            column: {
                cursor: 'pointer',
                borderRadius: 0,
                borderWidth: 0,
                pointWidth: 32,
                stacking: 'normal',
                dataGrouping: {
                    enabled: false
                }
            },
        },

        tooltip: {
            headerFormat: 
                '<div class="flex-centered-col" style="min-width:150px; padding:5px;">'+
                    '<div class="highcharts-fonts" style="margin-bottom:8px;"><b>{point.key}</b></div>'+
                    '<div class="flex-centered-col" style="width: 100%;">',
            pointFormat:
                        '<div class="flex-space-between highcharts-fonts" id="{point.stackTotal}" style="padding:0; width: 100%; margin-top:4px;">'+
                            '<div>Total: &nbsp;</div>'+
                            '<div><b>{point.stackTotal:.2f} MT</b></div>'+
                        '</div>'+
                        '<div class="flex-space-between highcharts-fonts" id="{series.name}" style="padding:0; width: 100%; margin-top:4px;">'+
                            '<div class="flex-space-between">'+
                                '<div class="tooltip-circle" style="background-color:{series.color};"></div>'+
                                '<div>{series.userOptions.tooltipTitle}: &nbsp;</div>'+
                            '</div>'+
                            '<div><b>{point.y}</b></div>'+
                        '</div>',
            footerFormat:
                    '</div>'+
                '</div>',
            shared: false,
            useHTML: true,
            xDateFormat: '%d/%m %H:%M',
            followPointer: false
        },

        lang: {
            noData: "No data to display at the moment"
        },

        series: [],

        credits: { enabled: false },
        
        exporting: {
            enabled: true,
            buttons: {contextButton: {}},
            chartOptions: {
                chart: {backgroundColor: ThemeColorsForCharts()['platform_background']}
            }
        }
    };

    return options;
}

export default stackBarChartOptions;