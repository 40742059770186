import React, { useEffect, useState, useRef } from 'react';
import ReportsService from "../common/js/reports";
import service from "../common/js/service";
import DynamicWidgetList from "../components/dynamicWidget/dynamicWidgetList";
import ComponentsIndex from "widgets/models/componentsIndex";
import PayloadsIndex from "widgets/models/payloadsIndex";
import {keys} from 'lodash';
import SingleDashboardHeader from "../components/dashboard/singleDashboardHeader";
import SingleDashboardTitleInfo from "../components/dashboard/singleDashboardTitleInfo";
import DsDrawer from "../components/drawer/drawer.jsx";
import WidgetLibrary from "../components/widgetLibrary/widgetLibrary.jsx";
import TemplateModal from 'components/dashboard/modals/templateModal.jsx';
import TemplateModalDeletion from 'components/dashboard/modals/templateModalDeletion.jsx';
import EditLevelModal from "components/dashboard/modals/editLevelModal";
import { useSelector, useDispatch } from 'react-redux';
import { vesselStore, dashboardStore, vesselUtils, dashboardUtils } from 'common/store/storeUtils';

let justPublished = false;
const SingleDashboard = props => {
    let tempInfo = useRef();
    const reportsService = new ReportsService();
    const [widgets, setWidgets] = useState([]);
    const [allWidgets, setAllWidgets] = useState([]);
    const [requestOnGoing, setRequestOnGoing] = useState({});
    const [appState, setAppState] = useState({});
    const [dashboardInfo, setDashboardInfo] = useState({})
    const [drawerClose, setDrawerClose] = useState(true);
    const [expandDrawer, setExpandDrawer] = useState(false);
    const [showEditLevelModal, setShowEditLevelModal] = useState(false);
    const [widgetToEdit, setWidgetToEdit] = useState();

    // store vars
    const dashboardStoreJSX = useSelector(state => state.dashboard);
    const dispatch = useDispatch();

    const setJustPublished = (value) => {
        justPublished = value
    };

    const reportsResponseMapper = (response, key, widget) => {
        // console.log(response, key, widget)
        return PayloadsIndex[key]().updateHandler(key, response, updateStateMapper, null, widget);
    }

    const updateStateMapper = (key, value, widget) => {
        // console.log(key, value, widget)
        return setAppState({[key]: {data: value, widget: widget}});
    }

    const updateRequestOnGoing = (key, value, widget) => {
        setRequestOnGoing((CurrentRequestOnGoing) => {
            return {...CurrentRequestOnGoing, [key]: {loading: value, widget: widget}}
        });
    }

    const initializeTemplateWithWidgets = (id) => {
        service.getWidgets(id).then(res => {
            if (res) {
                dispatch({ type: 'dashboard/setTemplateHasChanges', payload: false });

                setDashboardInfo(res);
                setAllWidgets(res.widgets);
                const publishedWidgets = res.widgets.filter(widget => widget.published);
                res.widgets = publishedWidgets;
                createDynamicWidgetProps(res.widgets);
            }
        });
    }

    const duplicateAndStoreUnpublished = async () => {
        const res = await service.duplicateDashboardTemplate(tempInfo.current.title, tempInfo.current.templateId);
        if (res.status === 201) {
            const locationUrl = res.headers.get('Location').split("/");
            const duplicatedTemplateID = parseInt(locationUrl[locationUrl.length - 1]);
            if (typeof duplicatedTemplateID === 'number') {
                await service.saveUnpublished(duplicatedTemplateID, tempInfo.current.unpublishedWidgets)
            }
        }
    }

    const showNotificationSavedMessage = (text) => {
        dispatch({ type: 'notifications/setNotificationText', payload: text });
        dispatch({ type: 'notifications/setShowNotification', payload: true });
    }

    const onBrowserExitConfirmation = (e) => {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        return e;
    }

    // Show/Hide Edit level modal
    const handleEditClick = (item) => {
        setShowEditLevelModal(!showEditLevelModal);
        setWidgetToEdit(item);
    }

    const informStoresWithUpdatedWidget = async (eventName, item, existingWidgets, existingAllWidgets) => {

        item.vesselIds = eventName ? dashboardUtils.getVesselIdsByInputName(eventName, item.level) : (item.level === 'COMPANY') ? vesselUtils.getAllVesselIds() : [];

        // Inform timespans object, if empty
        if (!dashboardUtils.dashboardProperties.timespansObject[item.widgetId] && item.timespan) dashboardUtils.dashboardProperties.timespansObject[item.widgetId] = item.timespan[0];

        if (item.timespan) {
            if(dashboardUtils.dashboardTimespans(item.timespan[0])) {
                dispatch({ 
                    type: 'dashboard/setFromTo', 
                    payload: {
                        from: dashboardUtils.dashboardTimespans(item.timespan[0]).from, 
                        to: dashboardUtils.dashboardTimespans(item.timespan[0]).to
                    }
                });
            } 
            else if(item?.timespan[0] === 'LEG') {
                const legsFromTo = await service.getLegsTimespanFromTo(item?.vesselIds[0]);
                if(legsFromTo) {
                    dashboardUtils.dashboardProperties.timespansObject[item.widgetId+item.id] = legsFromTo;
                    dispatch({ 
                        type: 'dashboard/setFromTo', 
                        payload: {
                            from: legsFromTo.timestampFrom, 
                            to: legsFromTo.timestampTo
                        } 
                    });
                }
            }
        }

        // if this specific widget has only inner requests, then do not call the 'register' or 'registerWithoutReports' functions
        if(!dashboardUtils.widgetsWithInnerRequestsOnly(item)) {
            // Fetch data via report service, and update appState
            if (dashboardUtils.widgetsWithoutReports(item)) {
                registerWithoutReports(item);
            } else {
                register(item);
            }
        } 
        // widget loader starts as true and when the requests are made (inside the widget) it will change to false and stop loading
        else updateRequestOnGoing(item?.widgetId, true, item);

        // Inform widgets object in page
        setWidgets([...existingWidgets, item]);
        setAllWidgets([...existingAllWidgets, item]);

        // if the updated widget is an 'inner requests' widget, then
        // after the widgets are updated, update the 'appState' variable, so that the 'appState' useEffect can catch it
        // and trigger a rerender with the updated data
        if(dashboardUtils.widgetsWithInnerRequestsOnly(item) && item?.widgetId) 
            setAppState({[item?.widgetId]: {data: dashboardUtils.initialValuesForWidgetsWithInnerRequests(item?.widgetId), widget: item?.id}});
    }

    // Change vessel/vesselGroup of dynamic widget
    const handleEditWidget = (event) => {
        event.preventDefault();
        setShowEditLevelModal(false);
        const fieldValue = event.target[0].value,
            item = Object.assign({}, widgetToEdit);

        delete item.component;

        const widgetsWithoutItem = widgets.filter(widgets => widgets.id !== item.id);
        const allWidgetsWithoutItem = allWidgets.filter(widgets => widgets.id !== item.id);

        informStoresWithUpdatedWidget(fieldValue, item, widgetsWithoutItem, allWidgetsWithoutItem);

        dispatch({ type: 'dashboard/setTemplateHasChanges', payload: true });
    }

    // Add dynamic widget in dashboard
    const handleAddWidget = event => {
        event && event.preventDefault();

        dispatch({ type: 'dashboard/setShowAddWidgetModal', payload: false });

        const fieldValue = event && event.target[0].value;
        const existingWidgets = Object.assign([], widgets),
            existingAllWidgets = Object.assign([], allWidgets),
            item = Object.assign({}, dashboardStore().widgetToAdd);

        // Reset widgetToAdd value
        dispatch({ type: 'dashboard/setWidgetToAdd', payload: {} });

        // Give the widget the necessary layout object
        dashboardUtils.constructWidgetLayout(item, widgets)

        informStoresWithUpdatedWidget(fieldValue, item, existingWidgets, existingAllWidgets);

        dispatch({ type: 'dashboard/setTemplateHasChanges', payload: true });
    }

    // Update delete dynamic widget from dashboard
    const handleDeleteClick = (deleteItem) => {
        const item = Object.assign({}, deleteItem),
            widgetWithoutItem = widgets.filter(widgets => widgets.id !== item.id),
            allWidgetWithoutItem = allWidgets.filter(widgets => widgets.id !== item.id);

        // Inform dashboardUtils.dashboardProperties.layoutsObject for the deletion of the item so next addition displays in correct position
        dashboardUtils.dashboardProperties.layoutsObject = dashboardUtils.dashboardProperties.layoutsObject.filter(function(item) {
            return parseInt(item.i) !== parseInt(deleteItem?.layout.i)
        })

        // Update state without the deleted item
        setWidgets(widgetWithoutItem);
        setAllWidgets(allWidgetWithoutItem);

        dispatch({ type: 'dashboard/setTemplateHasChanges', payload: true });
    }


    const register = (widget) => {
        const dataObj = [{
            ...widget,
            inDashboard: true,
            ...PayloadsIndex[widget.widgetId](widget, props.dashboardID)
        }]

        reportsService.registerReports(widget.vesselIds, dataObj, reportsResponseMapper, updateRequestOnGoing, true);
    }

    const registerWithoutReports = widget => {
        reportsResponseMapper(widget, widget.widgetId, widget)
        updateRequestOnGoing(widget.widgetId, true, widget)

        // Because no call happens, give a false loading screen for UI purpose
        setTimeout(() => {
            updateRequestOnGoing(widget.widgetId, false, widget)
        }, 2000)
    }

    const createDynamicWidgetProps = (widgets) => {
        const widgetsWithVesselIds = widgets.filter(widget => widget?.vesselIds?.length > 0);
        const widgetsWithInnerRequestsOnly = [];

        widgetsWithVesselIds.forEach(async widget => {
            if(widget?.timespan[0] === "LEG") {
                const legsFromTo = await service.getLegsTimespanFromTo(widget?.vesselIds[0]);
                if(legsFromTo) dashboardUtils.dashboardProperties.timespansObject[widget.widgetId+widget.id] = legsFromTo;
            } else {
                dashboardUtils.dashboardProperties.timespansObject[widget.widgetId] = widget.timespan[0];
            }

            dispatch({ 
                type: 'dashboard/setFromTo', 
                payload: {
                    from: dashboardUtils.dashboardTimespans(widget.timespan[0])?.from,
                    to: dashboardUtils.dashboardTimespans(widget.timespan[0])?.to
                } 
            });

            // if this specific widget has only inner requests, then push it to 'widgetsWithInnerRequestsOnly' array and go to check the next widget
            // do not call the 'register' or 'registerWithoutReports' functions
            if(dashboardUtils.widgetsWithInnerRequestsOnly(widget)) {
                // widget loader starts as true and when the requests are made (inside the widget) it will change to false and stop loading
                updateRequestOnGoing(widget?.widgetId, true, widget);
                return widgetsWithInnerRequestsOnly.push(widget);
            }

            if (dashboardUtils.widgetsWithoutReports(widget)) {
                registerWithoutReports(widget);
            } 
            else {
                register(widget);
            }
        });

        setWidgets(widgetsWithVesselIds);

        // after widgets are set, if there are any widgets with inner requests, update the 'appState' variable, so that the 'appState' useEffect can catch it
        // and trigger a rerender with the updated data
        widgetsWithInnerRequestsOnly.forEach((widget) => {
            setTimeout(() => setAppState({[widget.widgetId]: {data: dashboardUtils.initialValuesForWidgetsWithInnerRequests(widget.widgetId), widget: widget?.id}}));
        });
    }

    // for rename functionality
    const renameAction = async (newTitle, id) => {
        const res = await service.renameDashboardTemplate(newTitle, id);
        if (res.status === 204) {
            service.getWidgets(id).then(res => {
                if (res) setDashboardInfo(res);
            });
        }
    };

    const handleTemplateRename = (id, title) => {
        dispatch({ type: 'dashboard/setCurrentSelectedId', payload: id });
        dispatch({ type: 'dashboard/setShowRenameModal', payload: true });
        dispatch({ type: 'dashboard/setCurrentSelectedTitle', payload: title });
    }

    // for duplicate functionality
    const duplicateAction = async (sameTitle, id) => {
        const res = await service.duplicateDashboardTemplate(sameTitle, id);

        if (res.status === 201) {
            const locationUrl = res.headers.get('Location').split("/");
            const duplicatedTemplateID = parseInt(locationUrl[locationUrl.length - 1]);

            if (typeof duplicatedTemplateID === 'number') {
                props.history.push(`/dashboard/${duplicatedTemplateID}`);
                initializeTemplateWithWidgets(duplicatedTemplateID);
            }
        }
    };

    const handleTemplateDuplicate = (title, id) => {
        duplicateAction(title, id);
    }

    // for delete functionality
    const deleteAction = () => {
        props.history.push({
            pathname: `/dashboards`,
            state: {onGoingDeletion: true}
        });
    };

    const handleTemplateDelete = () => {
        dispatch({ type: 'dashboard/setCurrentSelectedId', payload: dashboardInfo.id });
        dispatch({ type: 'dashboard/setShowDeleteModal', payload: true });
    }

    // filter out of the requestOnGoing object all the loaders of the widgets that have inner requests only (they have their own loader prop)
    const widgetLoaders = keys(requestOnGoing).filter((key) => !dashboardUtils.widgetsWithInnerRequestsOnly(requestOnGoing[key].widget)).reduce((obj, key) => {
        obj[key] = {...requestOnGoing[key]};
        return obj;
    }, {});

    useEffect(() => {
        const filteredWidgets = allWidgets.filter(widget => dashboardStore().editMode ? !widget.published : widget.published);
        setWidgets(filteredWidgets);
        if (!justPublished) createDynamicWidgetProps(filteredWidgets);
        else setJustPublished(false);

        if(!dashboardStore().editMode && dashboardInfo.type === 'DEEPSEA' && dashboardStore().templateHasChanges) {
            showNotificationSavedMessage(`Following your changes, the dashboard has been SAVED under the name "${dashboardInfo.title}"`);
        }

        dispatch({ type: 'dashboard/setTemplateHasChanges', payload: false });
        
        if(tempInfo?.current) tempInfo.current.templateHasChanges = false;
        // eslint-disable-next-line
    }, [dashboardStoreJSX.editMode]);

    useEffect(() => {
        initializeTemplateWithWidgets(props.dashboardID);

        // Listener for browser navigation - On exit confirm
        window.addEventListener('beforeunload', onBrowserExitConfirmation);

        return async () => { // when user navigates to another page in the app, then the unpublished widgets request runs
            if (tempInfo?.current?.templateId && tempInfo?.current?.templateHasChanges) {
                if (tempInfo?.current?.type === 'DEEPSEA') await duplicateAndStoreUnpublished();
                else await service.saveUnpublished(tempInfo.current.templateId, tempInfo.current.unpublishedWidgets);
                showNotificationSavedMessage("Editions have been saved to the edit mode");
                tempInfo.current = {};
            }

            dispatch({ type: 'dashboard/setEditMode', payload: false });
            dispatch({ type: 'dashboard/setWidgetToAdd', payload: {} });

            // Reset stores
            dispatch({ type: 'vessel/setSelectedVessel', payload: vesselStore().allVesselsObj.vesselId });

            window.removeEventListener('beforeunload', onBrowserExitConfirmation);
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        tempInfo.current = {
            templateId: props.dashboardID,
            unpublishedWidgets: allWidgets.filter(widget => !widget.published),
            type: dashboardInfo.type,
            title: dashboardInfo.title,
            templateHasChanges: dashboardStore().templateHasChanges
        };

        // eslint-disable-next-line
    }, [allWidgets]);

    useEffect(() => {
        // For company level widgets, we must bypass the modal (get vessel/vessel group functionality)
        if (Object.keys(dashboardStore().widgetToAdd).length === 0 ) return;
        if (dashboardStore().widgetToAdd?.level !== 'COMPANY') return;

        return handleAddWidget();

        // eslint-disable-next-line
    }, [dashboardStoreJSX.widgetToAdd])

    useEffect(() => {
        // create a deep copy of the widgets state var, so when setWidgets is called, the widgets are updated
        const widgetsTemp = widgets.map(widget => ({...widget}));

        if (!appState || Object.keys(widgetsTemp).length === 0) return;
        const widgetId = keys(appState)[0];
        const ComponentToRender = ComponentsIndex[widgetId];

        dispatch({type: 'reports/setIsTelegramConfigured', payload: true});

        widgetsTemp.forEach((widget) => {
            // console.log(widget, widgetId, appState)
            if (widget.widgetId === widgetId && appState[widgetId].widget === widget.id) {
                widget.component =
                    <ComponentToRender
                        data={appState[widgetId].data}
                        // the 'setData', 'setLoader' and 'loader' props will only be added to the component in case the component has inner requests only
                        {...(
                            dashboardUtils.widgetsWithInnerRequestsOnly(widget) 
                                ? {
                                    setData: (data) => setAppState({[widget?.widgetId]: {data: data, widget: widget?.id}}), 
                                    setLoader: (id, value) => updateRequestOnGoing(widgetId, value, widget),
                                    loader: requestOnGoing[widgetId]?.loading
                                } 
                                : {}
                            )
                        }
                        chartData={appState[widgetId].data}
                        items={appState[widgetId].data}
                        keyId={widgetId}
                        widget={widget}
                        style={PayloadsIndex[widgetId]().style}
                        {...PayloadsIndex[widgetId](widget).extraProps}
                    />;
                if(PayloadsIndex[widgetId]()?.hasTimePeriod) {
                    if(widget?.timespan[0] === "LEG") {
                        widget.timePeriod = {
                            from: dashboardUtils.dashboardProperties.timespansObject[widget?.widgetId+widget?.id]?.timestampFrom,
                            to: dashboardUtils.dashboardProperties.timespansObject[widget?.widgetId+widget?.id]?.timestampTo
                        };
                    } else {
                        widget.timePeriod = dashboardUtils.dashboardTimespans(widget?.timespan[0]);
                    }
                }
                if(PayloadsIndex[widgetId]()?.bypassReports) widget.bypassReports = PayloadsIndex[widgetId]()?.bypassReports;
            }
        })

        setWidgets(widgetsTemp);

        // eslint-disable-next-line
    }, [appState])

    return (
        <div className="single-dashboard">
            <SingleDashboardHeader
                {...props} {...dashboardInfo} widgets={widgets} drawerClose={drawerClose}
                setDrawerClose={setDrawerClose}
                expandDrawer={expandDrawer} setExpandDrawer={setExpandDrawer}
                handleTemplateDelete={handleTemplateDelete}
                handleTemplateDuplicate={handleTemplateDuplicate}
                handleTemplateRename={handleTemplateRename}
                initializeTemplateWithWidgets={initializeTemplateWithWidgets}
                setJustPublished={setJustPublished}/>

            <div className="main-content-padding">
                <div className="single-dashboard__title section-title">
                    {dashboardInfo.title}
                    {!dashboardStoreJSX.editMode && <SingleDashboardTitleInfo {...dashboardInfo} />}
                </div>
                <div className="single-dashboard__widgets">
                    <DynamicWidgetList
                        widgets={widgets}
                        setWidgets={setWidgets}
                        loading={widgetLoaders}
                        handleAddWidget={handleAddWidget}
                        handleEditClick={handleEditClick}
                        handleDeleteClick={handleDeleteClick}
                    />
                </div>
            </div>

            <EditLevelModal
                title={"CUSTOMIZE WIDGET"}
                modalShow={showEditLevelModal}
                setModalShow={setShowEditLevelModal}
                widget={widgetToEdit}
                onSubmit={handleEditWidget}
            />

            <DsDrawer anchor={'right'}
                      classname={'widget-drawer'}
                      component={
                          <WidgetLibrary
                              drawerClose={drawerClose}
                              setDrawerClose={setDrawerClose}
                              expandDrawer={expandDrawer}
                              setExpandDrawer={setExpandDrawer}
                              handleAddWidget={handleAddWidget}
                          />
                      }
            />

            <TemplateModal
                header="RENAME DASHBOARD"
                submitButtonLabel="Save"
                show={dashboardStoreJSX.showRenameModal}
                dashboardTemplateAction={renameAction}
                closeModal={() => dispatch({ type: 'dashboard/setShowRenameModal', payload: false })}
                currentSelectedTitle={dashboardStoreJSX.currentSelectedTitle}
            />

            <TemplateModalDeletion
                header="DELETE DASHBOARD"
                submitButtonLabel="Delete"
                show={dashboardStoreJSX.showDeleteModal}
                deleteAction={deleteAction}
                closeModal={() => dispatch({ type: 'dashboard/setShowDeleteModal', payload: false })}
            />
        </div>
    );
};

export default SingleDashboard;
