import React, { useState, useEffect } from "react";
import SyncCharts from "components/charts/genericSyncCharts/syncCharts";
import SegmentButtons from "components/segmentButtons/SegmentButtons";
import classnames from 'classnames';

const CiiEfficiencyChart = (props) => {
	const [selectedSegment, setSelectedSegment] = useState(null);
	const [innerUpdate, setInnerUpdate] = useState(null);

	const setNavigatorExtremes = (data, chartRef, chartOptions) => {
		if (chartOptions.options.granularity !== selectedSegment) return;
		// we use setTimeout to be sure the destructuring will took place lastly in the call stack
		setTimeout(() => {
			// we use setExtremes to set the zoom out level on xAxis for the space between
			// the minimum and maximum value that we destruct from chartRef
			const { dataMin, dataMax } = chartRef.current.chart.xAxis[0];
			chartRef.current.chart.xAxis[0].setExtremes(dataMin, dataMax);
		});
	};
	
	useEffect(() => {
		if (!selectedSegment) return;

		if(props.innerRequest && props.innerUpdate) props.innerRequest(selectedSegment).then(response => props.innerUpdate(response, selectedSegment, setInnerUpdate, innerUpdate));

		// eslint-disable-next-line
	}, [selectedSegment]);

	useEffect(() => {
		setInnerUpdate(props);
		// eslint-disable-next-line
	}, [props.options, selectedSegment]);

	const getSegmentButtonsWrapperClasses = () => classnames('cii-segment-buttons', { 'marg-t-35': !props.footerInfo });

	return (
		<div className="cii-vessel-efficiency vessel-rating d-flex flex-column" style={{ height: props.style?.height }}>
			<div className={getSegmentButtonsWrapperClasses()}>
				<SegmentButtons
					{...props.defaultInputProps}
					setSelectedSegment={setSelectedSegment}
				/>
			</div>

			<SyncCharts {...innerUpdate} setNavigatorExtremes={setNavigatorExtremes} />

			{props.footerInfo ? <div className="vessel-rating__footer justify-content-end pr-3">
				<div className="small-label d-flex flex-row align-items-center justify-space-between">
					<span className="headline2">{ props.footerInfo?.title }</span>
					<div className="dashed-line"></div>
					<span className="headline2 pl-1">{ props.footerInfo?.subtitle }</span>
				</div>
			</div> : null}
		</div>
	);
};

export default CiiEfficiencyChart;
