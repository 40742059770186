import Highcharts from 'highcharts';
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import {userStore} from 'common/store/storeUtils';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

const chartOptions = (decimals, zPointUnit) => {

    if(!decimals) decimals = '';
    if(!zPointUnit) zPointUnit = '';

    (userStore().user?.theme !== 'ds-white') ? Highcharts.setOptions(Highcharts.darkTheme)
    : Highcharts.setOptions(Highcharts.whiteTheme);

    let options = {
        chart: { 
            height: 396,
            panning: true,
            zoomType: 'x',
        },

        title: { text: '' },

        legend: {
            align: 'center',
            layout: 'horizontal',
            verticalAlign: 'top',
            itemMarginBottom: 25,
            // alignColumns: false
        },

        xAxis: { 
            title: { text: 'TIME (UTC)', offset: 30 },
            tickLength: 0,
            offset: 16,
            // startOnTick: true,
            // endOnTick: true,
            type: 'datetime',
            labels: {
                step: 2,
                formatter: function() {
                    return Highcharts.dateFormat('%d/%m %H:%M', this.value);
                }
            },

            lineColor: 'transparent',
            // tickInterval: 12 * 3600 * 1000,
            // tickPositioner: function () {
            //     let positions = [], tick = Math.floor(this.dataMin), increment = Math.ceil(((this.dataMax - this.dataMin) / 6));
            //     if (this.dataMax === null && this.dataMin === null) return;
            //     for (tick; tick - increment <= this.dataMax; tick += increment) positions.push(tick);
            //     return positions;
            // },

            plotBands: {
                color:  ThemeColorsForCharts()['lines_on_charts'],
                from: null,
                to: null
            },
            plotLines: [{
                value: null,
                color: ThemeColorsForCharts()['primary_text'],
                dashStyle: 'LongDashDotDot',
                width: 1,
                label: {
                    text: '',
                }
            }, {
                value: null,
                color: ThemeColorsForCharts()['primary_text'],
                dashStyle: 'LongDashDotDot',
                width: 1,
                label: {
                    text: '',
                }
            }]
        },

        tooltip: {
            headerFormat: '<div class="flex-centered-col">'+
                '<div class="highcharts-fonts tooltip-title"><b>'+
                    '{series.userOptions.tootltipXTitle}{point.key:%d/%m %H:%M'+decimals+'}&nbsp;{series.userOptions.titleSuffix}</b></div>'+
                '<div class="flex-centered-col tooltip-values" style="width: 100%;">',
            pointFormat:
                `<div class="flex-space-between highcharts-fonts" id="{series.name}" style="width: 100%;">
                    <div class="flex-centered">
                        <div class="tooltip-circle" style="background-color:{series.color};"></div>
                        <div style="margin-right: 24px;">{series.userOptions.tooltipTitle}: &nbsp;</div>
                    </div>
                    <div><b>{point.y} {point.z}</b></div>
                </div>`,
            footerFormat: '</div></div>',
            shared: true,
            useHTML: true,
            padding: 8,
            xDateFormat: '%d/%m %H:%M'
        },

        plotOptions: {
            line: {
                states: {
                    hover: { animation: 0 }
                }
            },
            series: {
                cursor: 'pointer',
                label: { connectorAllowed: false },
                marker: {enabled: false, symbol: 'circle'},
                dataGrouping: {
                    enabled: false
                },
                connectNulls: false,
                turboThreshold: 50000
            },
            scatter: {
                marker: {
                    radius: 1,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                }
            }
        },

        credits: { enabled: false },

        series: [],

        lang: {
            noData: "No data to display at the moment"
        },

        exporting: {
            enabled: true,
            buttons: {contextButton: {}},
            chartOptions: {
                chart: {
                    backgroundColor: ThemeColorsForCharts()['platform_background']
                }
            },
            csv: {}
        },
        navigation: {
            menuStyle: {}
        }
    };

    return options;
}

export default chartOptions;