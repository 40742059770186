import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import moment from 'moment';
import EventsUtilities from 'Utilities/events';
import { cleaningSchema, cleaningValues, cleaningGroups } from 'common/js/events/cleaning';
import { charterPartySchema, charterPartyValues, charterPartyGroups } from 'common/js/events/charterParty';
import { damagesSchema, damagesValues, damagesGroups } from 'common/js/events/damages';
import { bunkeringSchema, bunkeringValues, bunkeringGroups } from 'common/js/events/bunkering';
import { atelegramSchema, atelegramValues, atelegramGroups } from 'common/js/events/atelegram';
import { ntelegramSchema, ntelegramValues, ntelegramGroups } from 'common/js/events/ntelegram';
import { dtelegramSchema, dtelegramValues, dtelegramGroups } from 'common/js/events/dtelegram';
import FormWizard, { defaultWizardStepValues } from 'components/core/Forms/FormWizard';
import { ReactComponent as EngineDamagesIcon } from 'assets/img/app/global-imgs/engine-damages.svg';
import { ReactComponent as CloseIcon } from 'assets/img/app/global-imgs/close.svg';
import { ReactComponent as BunkeringIcon } from 'assets/img/app/global-imgs/bunkering.svg';
import { ReactComponent as CharterPartyIcon } from 'assets/img/app/global-imgs/charter-party.svg';
import { ReactComponent as MaintenanceIcon } from 'assets/img/app/events-imgs/maintenance-b-icon.svg';
import { ReactComponent as ArrivallIcon } from 'assets/img/app/events-imgs/tl-arrival.svg';
import { ReactComponent as DepartureIcon } from 'assets/img/app/events-imgs/tl-departure.svg';
import { ReactComponent as NoonTelegramIcon } from 'assets/img/app/events-imgs/tl-telegram.svg';
import { userStore, vesselGroupStore, vesselStore, vesselGroupUtils, monitoringUtils, vesselUtils, eventsUtils } from 'common/store/storeUtils';
import { useDispatch, useSelector } from 'react-redux';
import service from 'common/js/service';

const EventsModal = props => {
    // store vars
    const vesselGroupStoreJSX = useSelector(state => state.vesselGroup);
    const vesselStoreJSX = useSelector(state => state.vessel);
    const dispatch = useDispatch();

    const EVENT_TYPES = [
        { label: 'Bunkering', value: eventsUtils.getEventIdByType('BUNKERING'), classname: eventsUtils.getEventIdByType('BUNKERING'), iconComponent: BunkeringIcon },
        { label: 'Maintenance Event', value: eventsUtils.getEventIdByType('MAINTENANCE'), classname: eventsUtils.getEventIdByType('MAINTENANCE'), iconComponent: MaintenanceIcon },
        { label: 'Machinery Damages', value: eventsUtils.getEventIdByType('DAMAGES'), classname: eventsUtils.getEventIdByType('DAMAGES'), iconComponent: EngineDamagesIcon },
        { label: 'Charter Party Agreement', value: eventsUtils.getEventIdByType('VESSELCHARTER'), classname: eventsUtils.getEventIdByType('VESSELCHARTER'), iconComponent: CharterPartyIcon }
    ];

    const TELEGRAM_TYPES = [
        { label: 'Noon Report', value: eventsUtils.getEventIdByType('NTELEGRAM'), classname: eventsUtils.getEventIdByType('NTELEGRAM'), iconComponent: NoonTelegramIcon },
        { label: 'Arrival', value: eventsUtils.getEventIdByType('ATELEGRAM'), classname: eventsUtils.getEventIdByType('ATELEGRAM'), iconComponent: ArrivallIcon },
        { label: 'Departure', value: eventsUtils.getEventIdByType('DTELEGRAM'), classname: eventsUtils.getEventIdByType('DTELEGRAM'), iconComponent: DepartureIcon }
    ];

    const defaultSteps = (props = null) => {
        if (vesselGroupStore().selectedVesselGroup === vesselGroupStore().allVesselGroupsObj.id) {
            vesselGroupUtils.setSelectedAndChange(dispatch, vesselGroupStore().assignedVesselGroup.id);
        }
        const editMode = props !== null;
        const fleetVessels = vesselUtils.getFleetVessels();
        const selectedVesselStoreID = (vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) ?
            (fleetVessels[0] ? fleetVessels[0].vesselId : vesselStore().noVessel)
            : vesselStore().selectedVessel;
        EventsUtilities.selectedVessel = selectedVesselStoreID;

        const defaultStepsArray = [
            {
                ...defaultWizardStepValues,
                schema: {
                    type: {
                        type: "iconSegmentButtons",
                        label: "Event type",
                        options: [
                            {
                                vesselId: (value) => monitoringUtils.allowTelegrams(value),
                                options: TELEGRAM_TYPES
                            },
                            ...EVENT_TYPES
                        ],
                        value: '',
                        validation: {
                            requiredEvents: true,
                            disabled: {
                                always: editMode
                            }
                        }
                    },
                    vesselGroupId: {
                        type: "select",
                        label: "Fleet",
                        options: vesselGroupStore().allVesselGroups.map(fleet => ({ value: fleet.id, label: fleet.name })),
                        value: '',
                        validation: {
                            required: true,
                            disabled: {
                                always: editMode
                            }
                        },
                        onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                            if (value !== '') {
                                setFieldValue('vesselId', '');
                                setFieldTouched('vesselId', false);
                            }
                        }
                    },
                    vesselId: {
                        type: "select",
                        label: "Vessel",
                        options: vesselGroupStore().allVesselGroups.map(fleet => ({
                            options: vesselStore().allVessels.filter(v => v.vesselGroupId === fleet.id).map(ves => {
                                return { value: ves.vesselId, label: ves.name };
                            }),
                            vesselGroupId: [fleet.id]
                        })),
                        value: 0,
                        validation: {
                            required: true,
                            disabledOperator: 'OR',
                            disabled: {
                                always: editMode,
                                vesselGroupId: ['']
                            }
                        },
                        onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                            if (value !== '') {
                                EventsUtilities.selectedVessel = value;
                                handleChangeVessel(value, values);
                            }
                        }
                    }
                },
                values: {
                    type: props === null ? '' : props.type,
                    vesselGroupId: props === null ? (vesselGroupStore().selectedVesselGroup === vesselGroupStore().allVesselGroupsObj.id ? vesselGroupStore().allVesselGroups[0].id : vesselGroupStore().selectedVesselGroup) : props.vesselGroupId,
                    vesselId: props === null ? selectedVesselStoreID : props.vesselId
                },
                label: 'Choose an Event'                
            },
            {
                ...defaultWizardStepValues,
                schema: {},
                values: {},
                label: 'Add details',
                groups: {}
            },
            {
                ...defaultWizardStepValues,
                schema: {},
                values: {},
                label: 'Almost done!',
                groups: {}
            }
        ]

        return defaultStepsArray;
    }

    const [steps, setSteps] = useState(defaultSteps());
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        service.getGeInfo(userStore().user?.company?.id).then(geInfo => { EventsUtilities.geInfo = geInfo })
    }, [])

    useEffect(() => {
        if(!vesselGroupStoreJSX.changeVesselGroup) return;

        if (props.overviewPage) return;
        
        dispatch({ type: 'vesselGroup/setChangeVesselGroup', payload: false});

        defaultState();
    // eslint-disable-next-line
    }, [vesselGroupStoreJSX.changeVesselGroup])

    useEffect(() => {
        if(!vesselStore().changeVessel) return;
        if (props.overviewPage) return;
        dispatch({ type: 'vessel/setChangeVessel', payload: false});
        defaultState();
    // eslint-disable-next-line
    }, [vesselStoreJSX.changeVessel])

    useEffect(() => {
        if (!props.editEvent) return;
        setEditMode(true);
        setSteps(defaultSteps(props.eventObj));
        typeHandler(props.eventObj.type);
    // eslint-disable-next-line
    }, [props.editEvent])

    const defaultState = () => {
        setSteps(defaultSteps());
        setEditMode(false);
    };

    const onHide = () => {
        props.onHide();
        defaultState();
    };

    const onSubmit = (event) => {
        return props.onSave(dispatch, { event: setEventObj(event) }).then(r => { r && !r.error && defaultState(); });
    };

    const onDelete = () => {
        return props.onDelete(dispatch, { event: props.eventObj }).then(r => { !r.error && defaultState(); });
    };

    const getObjFormProps = () => {
        return editMode ? props.eventObj : {};
    }

    const getDateClicked = () => {
        return props.dateClicked;
    }

    const handleChangeVessel = (value, values) => {
        if (!monitoringUtils.allowTelegrams(value) && TELEGRAM_TYPES.filter(event => event.value === values.type).length > 0) {
            return values.type = '';
        }
    }

    const setEventObj = (values) => {
        let event = { ...filterValues({ ...values }) };
        if (values.hasOwnProperty('date')) {
            event.dateFrom = event.dateTo = moment(values.date).set({ h: 12, m: 0, s: 0 }).utc().format('YYYY-MM-DD HH:mm:ss');
            delete event.date;
        } else {
            event.dateFrom = moment(values.dateFrom).set({ h: 12, m: 0, s: 0 }).utc().format('YYYY-MM-DD HH:mm:ss');
            event.dateTo = moment(values.dateTo).set({ h: 12, m: 0, s: 0 }).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        event.vesselId = parseInt(values.vesselId);
        event.vesselGroupId = parseInt(values.vesselGroupId);
        if (event.hasOwnProperty('helper')) delete event.helper;
        if (editMode) event.id = (props.eventObj && props.eventObj.id) ? props.eventObj.id : '';
        if (event.hasOwnProperty('geDetails')) {
            Object.assign(event, flattenObject(event.geDetails));
            delete event.geDetails;
        }
        if (event.hasOwnProperty('telegramContent')) {
            Object.assign(event, flattenObject(event.telegramContent));
            event.telegramType = event.type.substring(0, 1);
            delete event.telegramContent;
            delete event.vesselGroupId;
        }
        if (event.hasOwnProperty('telegramDate')) {
            event.telegramDate = event.dateFrom = event.dateTo = moment(values.telegramDate).format('YYYY-MM-DD HH:mm:ss');
        }
        if (event.hasOwnProperty('etaNextPort')) {
            event.etaNextPort = moment(values.etaNextPort).format('YYYY-MM-DD HH:mm:ss');
        }
        if (event.hasOwnProperty('generalValidation')) {
            delete event.generalValidation;
        }

        return event;
    };

    const filterValues = (values) => {
        let getKeys = () => { };
        let filteredValues = {};

        switch (values.type) {
            case eventsUtils.getEventIdByType('MAINTENANCE'):
                getKeys = cleaningValues;
                break;
            case eventsUtils.getEventIdByType('VESSELCHARTER'):
                getKeys = charterPartyValues;
                break;
            case eventsUtils.getEventIdByType('BUNKERING'):
                getKeys = bunkeringValues;
                break;
            case eventsUtils.getEventIdByType('DAMAGES'):
                getKeys = damagesValues;
                break;
            case eventsUtils.getEventIdByType('ATELEGRAM'):
                getKeys = atelegramValues;
                break;
            case eventsUtils.getEventIdByType('NTELEGRAM'):
                getKeys = ntelegramValues;
                break;
            case eventsUtils.getEventIdByType('DTELEGRAM'):
                getKeys = dtelegramValues;
                break;
            default:
            // do nothing
        }

        const allKeys = {
            ...defaultSteps()[0].values,
            ...getKeys({}, false, props.dateClicked).reduce((prev, next) => ({ ...prev, ...next }), {})
        };
        Object.keys(allKeys).forEach(key => { filteredValues[key] = values[key] });

        return filteredValues;
    }

    const flattenObject = (object) => {
        return Object.assign({}, ...function _flattenItem(item, path = []) {
            if(!item) return null;
            return [].concat(...Object.keys(item).map(key => typeof item[key] === 'object'
                ? _flattenItem(item[key], path.concat([key]))
                : ({ [key]: item[key] })
            ));
        }(object));
    };

    const typeHandler = (type) => {
        let schema = () => { };
        let values = () => { };
        let groups = [];

        switch (type) {
            case eventsUtils.getEventIdByType('MAINTENANCE'):
                schema = cleaningSchema;
                values = cleaningValues;
                groups = cleaningGroups;
                break;
            case eventsUtils.getEventIdByType('VESSELCHARTER'):
                schema = charterPartySchema;
                values = charterPartyValues;
                groups = charterPartyGroups;
                break;
            case eventsUtils.getEventIdByType('BUNKERING'):
                schema = bunkeringSchema;
                values = bunkeringValues;
                groups = bunkeringGroups;
                break;
            case eventsUtils.getEventIdByType('DAMAGES'):
                schema = damagesSchema;
                values = damagesValues;
                groups = damagesGroups;
                break;
            case eventsUtils.getEventIdByType('ATELEGRAM'):
                schema = atelegramSchema;
                values = atelegramValues;
                groups = atelegramGroups;
                break;
            case eventsUtils.getEventIdByType('NTELEGRAM'):
                schema = ntelegramSchema;
                values = ntelegramValues;
                groups = ntelegramGroups;
                break;
            case eventsUtils.getEventIdByType('DTELEGRAM'):
                schema = dtelegramSchema;
                values = dtelegramValues;
                groups = dtelegramGroups;
                break;
            default:
            // do nothing
        }

        if (!editMode && props.editEvent) return;

        const tempSteps = steps.map((step, index) => index === 0 ? step : {
            ...defaultWizardStepValues,
            label: step.label,
            schema: schema(editMode)[index - 1],
            values: values(getObjFormProps(), editMode, getDateClicked())[index - 1],
            groups: groups[index - 1]
        });

        setSteps(tempSteps);
    };

    return (
        <Dialog disableBackdropClick disableEnforceFocus open={props.show} onClose={onHide}
                aria-labelledby="form-dialog-title" className="centered-modal events-modal">
            <DialogTitle id="form-dialog-title">
                {props.title}
                <div className="close-button flex-centered" onClick={onHide}>
                    <div className="close-button__img flex-centered">
                        <CloseIcon className="svg-defs-path-g-maskUse"/>
                    </div>
                </div>
            </DialogTitle>
            <FormWizard
                typeHandler={typeHandler}
                steps={steps}
                onSubmit={onSubmit}
                onDelete={onDelete}
                editEvent={editMode}
                modalClose={onHide}
                deleteButton={false}
                canRenderButtons={false}
            />
        </Dialog>
    )
}

export default EventsModal;
