import Utilities from 'Utilities/global';
import { ReactComponent as FuelDropsIcon } from 'assets/img/app/global-imgs/fuel-drops.svg';
import { ReactComponent as CoConsumptionIcon } from 'assets/img/app/global-imgs/co-consumption.svg';
import overviewStorage from 'components/monitoringCategories/localStorages/overviewStorage';

const fuelConsumpFcrPayload = {
    metrics: [{
        metricCategory: 'VESSEL',
        subIds: [1],
        metricData: [{
            metricName: "totalFOMassRate_calc",
            aggregation: "SUM"
        }, {
            metricName: "totalCO2",
            aggregation: "SUM"
        }]
    }]

}

const fuelConsumpFcrPayloadLight = {
    metrics: [{
        metricCategory: 'TELEGRAM',
        subIds: [1],
        metricData: [{
            metricName: "totalFOMassRate_calc",
            aggregation: "SUM"
        }, {
            metricName: "totalCO2",
            aggregation: "SUM"
        }]
    }]
}

const fuelConsumpTotalsUpdate = (id, response, updateState) => {
    let data = [], fcrTotal = 0, co2Total = 0;

    overviewStorage.setFuelConsumpTotalsData(response);
    
    if (response.series && response.series.length > 0) {
        response.series.forEach(timespampGroup => {
            if (timespampGroup.values && timespampGroup.values.length > 0) {
                timespampGroup.values.forEach(value => {
                    value.metricName === 'totalCO2'
                        ? co2Total = (isNaN(value.value) || value.value === null) ? 0 : Utilities.roundToDigits(value.value, 2)
                        : fcrTotal = (isNaN(value.value) || value.value === null) ? 0 : Utilities.roundToDigits(value.value, 2)
                })
            }
        })
    }

    if (fcrTotal || co2Total) {
        data = [
            { id: 'fcr', label: 'TOTAL FUEL CONSUMPTION', value: fcrTotal, icon: FuelDropsIcon },
            { id: 'co', label: 'CO2 EMISSIONS', value: co2Total, icon: CoConsumptionIcon }
        ]
    }

    updateState(id, data)
}

export { fuelConsumpFcrPayload, fuelConsumpTotalsUpdate, fuelConsumpFcrPayloadLight }