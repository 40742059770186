import L from 'leaflet';
import moment from 'moment';
import MapUtilities from 'Utilities/map';
import MarkersPopup from './markersPopup';
import AnchorIcon from 'assets/img/app/map-imgs/anchor-bg.svg';
import bulletCircleMarker from './bulletCircleMarker';
import {licensing} from "common/store/licensing";
import ThemeColors from 'sass/_themeColorsForJs.scss';
import { alertsStore } from 'common/store/storeUtils';

let circleAroundMarker = null;

const VesselRoot = {
    AnchorIcon,
    markers: [],
    markersObj: {},
    currentVesselName: '',
    currentVesselNameObj: {},
    lastMarkersPopups: [],
    lastMarkersPopupsObj: {},

    markerOnHoverPopup: vd => {
        let con = MarkersPopup.getOnHoverPopupHtml(vd);
        let popup = L.popup({closeButton: false, autoClose: false, closeOnClick: false, autoPan: false}).setContent(con);
        return popup;
    },

    /**This function create the first label marker of a vessel's root */
    // createVesselFirstMarker: (count, vd, map) => {
    //     let anchorIcon = L.icon({
    //         iconUrl: VesselRoot.AnchorIcon,
    //         iconSize: [28, 28],
    //         popupAnchor: [172, 172],
    //         iconAnchor: [14, 14],
    //     });
    //     VesselRoot.markers[count] = L.marker([vd.latitude, vd.longitude], {icon: anchorIcon}).addTo(map);
    //     VesselRoot.markers[count].bindPopup(VesselRoot.markerOnHoverPopup(vd));
    //     VesselRoot.markers[count].vesselData = vd;
    //     VesselRoot.markers[count].setZIndexOffset(99);
    // },

    getBulletColor: (severity, marker) => {
        if(severity === 'HIGH') return MapUtilities.alertPulse('high_severity');
        if(severity === 'MEDIUM') return MapUtilities.alertPulse('medium_severity');
        if(severity === 'LOW') return MapUtilities.alertPulse('low_severity');
        return marker;
    },

    insideAlertPeriod: date => {
        if( date >= alertsStore().alertInspection.activeAlert.startingDate && date <= alertsStore().alertInspection.activeAlert.endingDate)
            return true;
        return false;
    },

    /**Returns correct bullet svg icon with color to build gradient vessel leg with svgs */
    getCurrentSvgLegBullet: (vd, vesselColorNumber) => {
        let tempMarker = MapUtilities.markerBullet(`fleetColor${vesselColorNumber}`), size = 10, anchor = 5;

        //if there is active alert check for coloered map bullets
        if( alertsStore().alertInspection?.status ) {
            //if alert is light check only if bullet is inside alert period and give alert severity to all those bullets
            if( alertsStore().alertInspection?.activeAlert?.alertType === 1 ) {
                if( VesselRoot.insideAlertPeriod(vd.datetime) ) {
                    tempMarker = VesselRoot.getBulletColor(alertsStore().alertInspection.activeAlert.severity, tempMarker);
                    size = 30;
                    anchor = 15;
                }
            }
            //if alert isn't light check the anomalies points and give its anomaly severity to its bullet with same timestamp (offset 25 minutes)
            else {
                if( alertsStore().alertMapBulletsAnomalies.length > 0 ) {
                    alertsStore().alertMapBulletsAnomalies.forEach(anomaly => {
                        if(Math.abs(moment(vd.datetime).diff(moment(anomaly.x), 'minutes')) <= 25) {
                            tempMarker = VesselRoot.getBulletColor(anomaly.severity, tempMarker);
                            size = 30;
                            anchor = 15;
                        }
                    });
                }
            }
        }

        return {bullet: tempMarker, size, anchor};
    },
    
    /**This function create root points markers of a vessel's root */
    createVesselRootMarkers: (count, vd, vdPiece, map, isReport, vesselColorNumber, widgetId) => {
        const svgBullet = VesselRoot.getCurrentSvgLegBullet(vd, vesselColorNumber);
        const size = svgBullet.size;
        const anchor = svgBullet.anchor;

        let markerIcon = L.divIcon({
            html: svgBullet.bullet,
            iconSize: [size, size],
            popupAnchor: [158, 140],
            iconAnchor: [anchor, anchor],
        });
        if (widgetId) {
            VesselRoot.markersObj[widgetId][count] = L.marker([vd.latitude, vd.longitude], {icon: markerIcon}).addTo(map);
            VesselRoot.markersObj[widgetId][count].vesselData = vd;
            VesselRoot.markersObj[widgetId][count].bindPopup(VesselRoot.markerOnHoverPopup(vd));
            VesselRoot.markersObj[widgetId][count].setZIndexOffset(1);
        } else {
            VesselRoot.markers[count] = L.marker([vd.latitude, vd.longitude], {icon: markerIcon}).addTo(map);
            VesselRoot.markers[count].vesselData = vd;
            VesselRoot.markers[count].bindPopup(VesselRoot.markerOnHoverPopup(vd));
            VesselRoot.markers[count].setZIndexOffset(1);
        }
    },
    
    /**This function create the label label marker of a vessel's root */
    createVesselLastMarker: (count, vd, map, vesselImageColored, vesselColorNumber, widgetId) => {
        if (!vesselImageColored) return;
        let vesselPosIcon = L.divIcon({
            html: MapUtilities.vesselPulse(vesselImageColored),
            iconSize: [60,60],
            popupAnchor: [165, 145],
            iconAnchor: [30, 30],
        });
        if (widgetId) {
            VesselRoot.markersObj[count] = L.marker([vd.latitude, vd.longitude], {icon: vesselPosIcon}).addTo(map);
            let con = `<div class="last-marker-popup marker-${vesselColorNumber}">` + VesselRoot.currentVesselNameObj[widgetId]+'</div>';
            let popup = L.popup({closeButton: false, autoClose: false, closeOnClick: false, autoPan: false})
                .setLatLng([vd.latitude, vd.longitude]).setContent(con);
            VesselRoot.lastMarkersPopups.push(popup);
            VesselRoot.markersObj[count].bindPopup(VesselRoot.markerOnHoverPopup(vd));
            VesselRoot.markersObj[count].vesselData = vd;
            VesselRoot.markersObj[count].setZIndexOffset(99);
        } else {
            VesselRoot.markers[count] = L.marker([vd.latitude, vd.longitude], {icon: vesselPosIcon}).addTo(map);
            let con = `<div class="last-marker-popup marker-${vesselColorNumber}">` + VesselRoot.currentVesselName+'</div>';
            let popup = L.popup({closeButton: false, autoClose: false, closeOnClick: false, autoPan: false})
                .setLatLng([vd.latitude, vd.longitude]).setContent(con).openOn(map);
            VesselRoot.lastMarkersPopups.push(popup);
            VesselRoot.markers[count].bindPopup(VesselRoot.markerOnHoverPopup(vd));
            VesselRoot.markers[count].vesselData = vd;
            VesselRoot.markers[count].setZIndexOffset(99);
        }

    },

    addCircleAroundMarker: (marker, map, vesselColorNumber) => {
        if (!marker) return;
        const fleetColor = `fleetColor${vesselColorNumber}`;
        let bulletCircleIcon = L.divIcon({
            iconSize: [20, 20],
            iconAnchor: [10, 10],
            className: 'bullet-circle-wrapper',
            html: bulletCircleMarker(ThemeColors[fleetColor]),
        })
        circleAroundMarker = L.marker([marker._latlng.lat, marker._latlng.lng], {icon: bulletCircleIcon}).addTo(map);
        circleAroundMarker.setZIndexOffset(0);
    }, 

    removeCircleAroundMarker: (map) => {
        if(circleAroundMarker) map.removeLayer(circleAroundMarker);
    },     
    
    /**This function adds listeners to every root point marker in map */
    createMarkersListeners: (count, vd, markerClickedFromMap, map, vesselColorNumber, widgetId) => {
        let markersObj = null;
        if (widgetId) {
            markersObj = VesselRoot.markersObj[widgetId][count]
        } else {
            markersObj = VesselRoot.markers[count]
        }

        if(!licensing.lightCondition() && typeof markersObj !== 'undefined' ) {
            markersObj.on('click', () => {
                markerClickedFromMap(count, vd, vesselColorNumber);
            });
        }
        markersObj && markersObj.on('mouseover', function (e) { this.openPopup(); VesselRoot.addCircleAroundMarker(markersObj, map, vesselColorNumber);});
        markersObj && markersObj.on('mouseout', function (e) { this.closePopup(); VesselRoot.removeCircleAroundMarker(map);});
    },

    /**This function gets data for a vessel and draw to google map this vessel's history */
    renderVesselRootMarkersToMap: (vesselName, map, data, tempMarkers, tempLastMarkersPopups, legWithAnchor, markerClickedFromMap, alwaysAnchor, isReport, vesselImageColored, vesselColorNumber, objOfMarkers, widgetId) => {
        let count = 0;

        if (widgetId) {
            objOfMarkers[widgetId] = tempMarkers;
            VesselRoot.markersObj[widgetId] = tempMarkers;
            VesselRoot.currentVesselNameObj[widgetId] = vesselName;
            VesselRoot.lastMarkersPopups[widgetId] = tempLastMarkersPopups;
        } else {
            VesselRoot.markers = tempMarkers;
            VesselRoot.currentVesselName = vesselName;
            VesselRoot.lastMarkersPopups = tempLastMarkersPopups;
        }

        let vdPiece = parseInt(data.length/7);
        let closedLeg = false;
        data.forEach( dt => {
            if(!closedLeg && dt) {               
                if(dt.latitude !== null && dt.longitude !== null) {
                    if(count !== data.length-1) VesselRoot.createVesselRootMarkers(count, dt, vdPiece, map, isReport, vesselColorNumber, widgetId);
                    else VesselRoot.createVesselLastMarker(count, dt, map, vesselImageColored, vesselColorNumber, widgetId);
                    VesselRoot.createMarkersListeners(count, dt, markerClickedFromMap, map, vesselColorNumber, widgetId);
                }
                count++;
            }  
        });
        return {'markers': VesselRoot.markers};
    }


}

export default VesselRoot;
