import meStorage from 'components/monitoringCategories/localStorages/meStorage';
import ThemeColors from "sass/_themeColorsForJs.scss";
import chartOptions from "components/charts/lineChart/react-lineChart";
import chartUtils from 'Utilities/charts';
import { vesselUtils } from 'common/store/storeUtils';
import { csvXlsxExportForCurvesAndScatterWithDatetime, downloadChartInCSVform, downloadChartInXLSXform } from "Utilities/highchartsUtils";

let SFOCPowerCurvesData = {}, 
    SFOCPowerCurvesDataObj = {}, 
    scatterData = {};

const chartSeries = [  
    {name: 'Actual Data', data: [], tooltipTitle: 'SFOC', yAxis: 'sfoc-axis', type: 'scatter', 
        color: ThemeColors['main_blue'], 
        customTooltipBorderColor: ThemeColors['main_blue'],
        marker: {enabled: true, symbol: 'circle'},
        tooltip: {valueSuffix: ' gr/kWh', valueDecimals: 0}
    },
    {name: 'Shop Test', data: [], tooltipTitle: 'Shop Test', yAxis: 'sfoc-axis', color: ThemeColors['first_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'Power: ', tooltip: {valueSuffix: ' gr/kWh', valueDecimals: 0}},
    {name: 'HFO', data: [], tooltipTitle: 'HFO', yAxis: 'sfoc-axis', color: ThemeColors['second_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'Power: ', tooltip: {valueSuffix: ' gr/kWh', valueDecimals: 0}},
    {name: 'LFO', data: [], tooltipTitle: 'LFO', yAxis: 'sfoc-axis', color: ThemeColors['third_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'Power: ', tooltip: {valueSuffix: ' gr/kWh', valueDecimals: 0}}
];

const yAxisData = [{id: 'sfoc-axis', title: {text: "SFOC (gr/kWh)"}, opposite: false}];

const columnHeaderMapper = {
    'Actual Data': 'Actual Data (gr/kWh)',
    'Shop Test': 'Shop Test (gr/kWh)',
    'HFO': 'HFO (gr/kWh)',
    'LFO': 'LFO (gr/kWh)'
}

// the data that we set here are for the sfoc power curves (called in the prerequisite)
const setSFOCPowerCurvesData = (data, widget) => {    
    if (widget?.inDashboard) SFOCPowerCurvesDataObj[widget.id] = data?.meCurves?.DEFAULT?.curves ? data.meCurves.DEFAULT.curves : {};
    else SFOCPowerCurvesData = data?.meCurves?.DEFAULT?.curves ? data.meCurves.DEFAULT.curves : {};
}

const fillSeriesWithData = (series, curvesData, scatterData) => {
    // fill series and update chart's legend for scatter data
    series[0].data = scatterData?.DEFAULT?.SCATTER_PLOT || [];
    series[0].showInLegend = series[0]?.data?.length;

    // fill series and update chart's legend for curves data 
    series[1].data = curvesData?.SHOP_TEST || [];
    series[1].showInLegend = series[1]?.data?.length;

    series[2].data = curvesData?.HFO || [];
    series[2].showInLegend = series[2]?.data?.length;

    series[3].data = curvesData?.LFO || [];
    series[3].showInLegend = series[3]?.data?.length;
}

// the data that we receive here are for the scatter plots
const updateSFOCPowerChart = (id, data, updateState, extraChartConfigs, widget) => {

    if(meStorage.SFOCPowerScatterPlotsData) scatterData = meStorage.SFOCPowerScatterPlotsData;
    else scatterData = data;

    if(meStorage.SFOCPowerData) SFOCPowerCurvesData = meStorage.SFOCPowerData;

    if (widget?.inDashboard) {
        SFOCPowerCurvesData = SFOCPowerCurvesDataObj[widget?.id];
        scatterData = data;
    }

    // handle empty data case, return empty object so that the noData component appears (show the NoData component when we do not have any scatter data)
    if(!scatterData) return updateState(id, {}, widget?.id);

    const chartData = Object.assign({}, chartOptions(':.2f', '%'));

    // set the series data of the chart
    chartData.yAxis = yAxisData;
    chartData.series = [...chartSeries];
    fillSeriesWithData(chartData.series, SFOCPowerCurvesData, scatterData);

    // set some chart options that are common in curves-scatter charts
    chartUtils.curvesAndScatterDataChart.setCommonChartOptions(chartData);

    // set extra options that are specific for this chart
    chartData.chart.height = 380;
    chartData.xAxis.title = { text: "Power (kW)", y: 10 };
    chartData.exporting.filename = `${vesselUtils.getVesselName(widget.vesselIds[0])}-MAIN ENGINE SFOC VS POWER`;
    chartData.exporting.csv.columnHeaderFormatter = (item) => chartUtils.curvesAndScatterDataChart.setExportingColumns(item, columnHeaderMapper, 'Power (kW)');


    chartData.exporting.buttons.contextButton= {
        menuItems: [
            "download",
            "customSeparator",
            {
                text: "XLSX",
                onclick: function () {
                    const dto = csvXlsxExportForCurvesAndScatterWithDatetime(this, 'kW', 'gr/kWh')
                    downloadChartInXLSXform({xlsxRows: dto.rows, sheetsName: dto.sheetsName.trim().length > 30 ? 'MAIN ENGINE SFOC VS POWER' : dto.sheetsName, name: dto.name});
                }
            },
            {
                text: "CSV",
                onclick: function () {
                    const dto = csvXlsxExportForCurvesAndScatterWithDatetime(this, 'kW', 'gr/kWh')
                    const csvContent = dto.rows.map(row => row.join(',')).join('\n');
                    downloadChartInCSVform({csvContent, name: dto.sheetsName});
                }
            },
        ]
    }

    // a common formatter for creating the tooltip's UI for both scatter data and curves data
    const tooltipExtraOptions = {
        titleX: 'Power: ',
        valueXSuffix: 'kW',
    };
    chartData.tooltip.formatter = function() {return chartUtils.curvesAndScatterDataChart.tooltipFormatter.call(this, tooltipExtraOptions)};

    if (!widget?.inDashboard) {
        meStorage.setSFOCPowerData(SFOCPowerCurvesData);
        meStorage.setSFOCPowerScatterPlotsData(scatterData);
    }

    SFOCPowerCurvesData = {};
    scatterData = {};

    return updateState(id, chartData, widget?.id);
}

export { updateSFOCPowerChart, setSFOCPowerCurvesData };
