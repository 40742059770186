import Utilities from 'Utilities/global';
import React from 'react';
import HullPerformanceTableCell from "./hullPerformanceTableCell";

const optionsKeyToTextMapper = {
	ACTUAL: 'Current',
	CLEANEST_STATE: 'Cleanest State',
	EXTRAPOLATED: 'Sea Trial',
	calm: 'Calm Sea State',
	sog: 'SOG'
}

const optionsTextToKeyMapper = {
	'Current': 'ACTUAL',
	'Cleanest State': 'CLEANEST_STATE',
	'Sea Trial': 'EXTRAPOLATED',
	'Calm Sea State': 'calm',
	'SOG': 'sog',
	'Head': 'HEAD',
	'Side': 'SIDE',
	'Tail': 'TAIL',
}

const numToBf = (num) => `${num} Bf`
const bfToNum = (bf) => String(parseInt(bf))
const isNumeric = (value) => /^-?\d+$/.test(value);

const filterResults = (data, keys) => {
	return data.map(item => {
		const filteredItem = {};
		keys.forEach(key => {
			if (item.hasOwnProperty(key)) {
				filteredItem[key] = item[key];
			}
		});
		return filteredItem;
	});
}

const getTableTitle = (selectedOptions, widgetTitle) => {
	const selectedDraftType = selectedOptions?.draftModeSelectedOption?.type
		? (
			!selectedOptions?.draftModeSelectedOption?.type.includes('CUSTOM')
				? `${Utilities.upperCaseFirstLetter(selectedOptions.draftModeSelectedOption.type.toLowerCase())} Draft`
				: selectedOptions.draftModeSelectedOption.name
		)
		: '';


	return <>
		{widgetTitle === "POWER-SPEED"
			? <span className="headline4">Power <span className="table-title-regular">(kW)</span> in {selectedDraftType}</span>
			: <span className="headline4">FCR <span className="table-title-regular">(mt/day)</span> in {selectedDraftType}</span>
		}
	</>

}

const getTableData = (responseData, selectedOptions) => {

	const { windSpeedSelectedOptions, windDirectionSelectedOptions } = selectedOptions;

	const selectedWindSpeeds = windSpeedSelectedOptions.map(windSpeed => windSpeed.includes('Calm') ? optionsTextToKeyMapper[windSpeed] : bfToNum(windSpeed));
	selectedWindSpeeds.push('sog');

	const selectedWindDirections = windDirectionSelectedOptions.map(windDirection => optionsTextToKeyMapper[windDirection])

	responseData = filterResults(responseData, selectedWindSpeeds);

	// Column order should always be [SOG, Sea Calm State, Wind Speeds]
	const windDirections = [ ...new Set(responseData.flatMap(data => Object.keys(data))) ];
	const sortingArr = ['sog', 'calm']

	const sortedWindDirections = windDirections.sort((a, b) => {
		if(sortingArr.indexOf(a) === -1) return 1
		if(sortingArr.indexOf(b) === -1) return -1
		return sortingArr.indexOf(a) - sortingArr.indexOf(b)
	});

	const getValuesOf = (wcData, key) => {
		const values = wcData.map(obj => obj[key]).filter(v => v);
		return [...new Set(values)]
	}
	const getSubColumns = (wc) => {

		// Building SOG column
		if(wc === 'sog') {
			return [{
				colHeader: <br/>,
				colData: responseData.map(colData => colData[wc])
			}]
		}

		const types = [...new Set(responseData.flatMap(colData => getValuesOf(colData[wc], 'type')))];
		const windDirections = [...new Set(responseData.flatMap(colData => getValuesOf(colData[wc], 'windDirection')))];

		// Building Wind columns
		if(windDirections.length) {
			return types.map(type => ({
				colHeader: optionsKeyToTextMapper[type],
				columns: windDirections.filter(wd => selectedWindDirections.includes(wd)).map(wd => ({
					colHeader: '',
					colData: responseData
						.flatMap(colData => colData[wc])
						.map(data => {
							const cell = (data.type === type && data.windDirection === wd && data);
							const { confidenceLevel, windDirection, value } = cell;
							return cell && <HullPerformanceTableCell confidenceLevel={confidenceLevel} windDirection={windDirection} value={value} type={cell.type} />;
						})
						.filter(v => v)
				}))
			}))
		} else {  // Building Sea Calm State columns
			return types.map(type => ({
				colHeader: optionsKeyToTextMapper[type],
				colData: responseData
					.flatMap(colData => colData[wc])
					.map(data => {
						const cell = (data.type === type && data);
						const { confidenceLevel, value } = cell;
						return cell && <HullPerformanceTableCell confidenceLevel={confidenceLevel} value={value} type={cell.type} />;
					})
					.filter(v => v)
			}))
		}
	};

	const tableData = sortedWindDirections.map(wc => ({
		colHeader: isNumeric(wc) ? numToBf(wc) : optionsKeyToTextMapper[wc],
		columns: getSubColumns(wc)
	}));

	return tableData;
}


export { getTableData, getTableTitle };
