import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const ClickAwayWrapper = (props) => {
    return(
        props.active ? (
            <ClickAwayListener onClickAway={props.handleClick}>
                {props.children}
            </ClickAwayListener>
        ) : props.children
    )
};

export default ClickAwayWrapper;