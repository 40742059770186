import EventsUtilities from 'Utilities/events';
import _ from 'lodash';
import { baseFormUtils } from 'common/store/storeUtils';

const telegramUtils = {
    generatorOptions: () => {
        let tmpArr = [];
        if (EventsUtilities.geInfo && EventsUtilities.selectedVessel && EventsUtilities.geInfo[EventsUtilities.selectedVessel]) {
            EventsUtilities.geInfo[EventsUtilities.selectedVessel].forEach((element, index) => {
                tmpArr.push({ value: `ge${index + 1}` })
            })
        }
        return tmpArr;
    },
    generatorStructure: () => {
        const generatorStructure = {};
        telegramUtils.generatorOptions().forEach((option, index) => {
            generatorStructure[option.value + 'LoadAvgCalc'] = telegramUtils.loadAvgCalcStructure(option.value + 'LoadAvgCalc');
            generatorStructure[option.value + 'UsageHrs'] = telegramUtils.usageHrsStructure(option.value + 'UsageHrs');
        });
        return generatorStructure;
    },
    telegramValues: {
        me1LsfoCons: '',
        ge1LsfoCons: '',
        bo1LsfoCons: '',
        me1HsfoCons: '',
        ge1HsfoCons: '',
        bo1HsfoCons: '',
        me1HsdoCons: '',
        ge1HsdoCons: '',
        bo1HsdoCons: '',
        me1LsdoCons: '',
        ge1LsdoCons: '',
        bo1LsdoCons: '',
    },
    updateTelegramValues: (props) => {
        telegramUtils.telegramValues.me1LsfoCons = props.me1LsfoCons || '';
        telegramUtils.telegramValues.ge1LsfoCons = props.ge1LsfoCons || '';
        telegramUtils.telegramValues.bo1LsfoCons = props.bo1LsfoCons || '';
        telegramUtils.telegramValues.me1HsfoCons = props.me1HsfoCons || '';
        telegramUtils.telegramValues.ge1HsfoCons = props.ge1HsfoCons || '';
        telegramUtils.telegramValues.bo1HsfoCons = props.bo1HsfoCons || '';
        telegramUtils.telegramValues.me1HsdoCons = props.me1HsdoCons || '';
        telegramUtils.telegramValues.ge1HsdoCons = props.ge1HsdoCons || '';
        telegramUtils.telegramValues.bo1HsdoCons = props.bo1HsdoCons || '';
        telegramUtils.telegramValues.me1LsdoCons = props.me1LsdoCons || '';
        telegramUtils.telegramValues.ge1LsdoCons = props.ge1LsdoCons || '';
        telegramUtils.telegramValues.bo1LsdoCons = props.bo1LsdoCons || '';
    },
    telegramContentValues: (props) => {
        return {
            lsfo: {
                me1LsfoCons: props.me1LsfoCons || '',
                ge1LsfoCons: props.ge1LsfoCons || '',
                bo1LsfoCons: props.bo1LsfoCons || '',
            },
            hsfo: {
                me1HsfoCons: props.me1HsfoCons || '',
                ge1HsfoCons: props.ge1HsfoCons || '',
                bo1HsfoCons: props.bo1HsfoCons || '',
            },
            hsdo: {
                me1HsdoCons: props.me1HsdoCons || '',
                ge1HsdoCons: props.ge1HsdoCons || '',
                bo1HsdoCons: props.bo1HsdoCons || '',
            },
            lsdo: {
                me1LsdoCons: props.me1LsdoCons || '',
                ge1LsdoCons: props.ge1LsdoCons || '',
                bo1LsdoCons: props.bo1LsdoCons || ''
            }
        }
    },
    telegramContentStructure: () => {
        return {
            type: 'selectionBoxesArray',
            group: 'GroupedValidation',
            viewports: { sm: 12, md: 12, lg: 12, xl: 12 },
            label: {
                title: '* Fuel Consumption since last report'
            },
            options: [
                { value: 'lsfo', label: 'LHSFO' },
                { value: 'hsfo', label: 'HSHFO' },
                { value: 'hsdo', label: 'LS-MGO' },
                { value: 'lsdo', label: 'HS-MGO' },
            ],
            formChart: [
                { label: 'Main Engine', color: 'var(--main_blue)' },
                { label: 'Generator Engine', color: 'var(--golden_corn)' },
                { label: 'Boilers', color: 'var(--violet)' }
            ],
            validation: {
                requiredSelectBoxes: true
            },
            structure: {
                me1LsfoCons: {
                    type: 'noNegativeNumber',
                    label: 'Main Engine',
                    value: telegramUtils.telegramValues.me1LsfoCons,
                    unit: 'mt',
                    validation: {
                        oneOfAll: ['me1LsfoCons', 'ge1LsfoCons', 'bo1LsfoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'me1LsfoCons', ['ge1LsfoCons', 'bo1LsfoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                },
                ge1LsfoCons: {
                    type: 'noNegativeNumber',
                    label: 'Gen. Engine',
                    unit: 'mt',
                    value: telegramUtils.telegramValues.ge1LsfoCons,
                    validation: {
                        oneOfAll: ['me1LsfoCons', 'ge1LsfoCons', 'bo1LsfoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'ge1LsfoCons', ['me1LsfoCons', 'bo1LsfoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                },
                bo1LsfoCons: {
                    type: 'noNegativeNumber',
                    label: 'Boilers',
                    unit: 'mt',
                    value: telegramUtils.telegramValues.bo1LsfoCons,
                    validation: {
                        oneOfAll: ['me1LsfoCons', 'ge1LsfoCons', 'bo1LsfoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'bo1LsfoCons', ['me1LsfoCons', 'ge1LsfoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                },
                me1HsfoCons: {
                    type: 'noNegativeNumber',
                    label: 'Main Engine',
                    value: telegramUtils.telegramValues.me1HsfoCons,
                    unit: 'mt',
                    validation: {
                        oneOfAll: ['me1HsfoCons', 'ge1HsfoCons', 'bo1HsfoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'me1HsfoCons', ['ge1HsfoCons', 'bo1HsfoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                },
                ge1HsfoCons: {
                    type: 'noNegativeNumber',
                    label: 'Gen. Engine',
                    unit: 'mt',
                    value: telegramUtils.telegramValues.ge1HsfoCons,
                    validation: {
                        oneOfAll: ['me1HsfoCons', 'ge1HsfoCons', 'bo1HsfoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'ge1HsfoCons', ['me1HsfoCons', 'bo1HsfoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                },
                bo1HsfoCons: {
                    type: 'noNegativeNumber',
                    label: 'Boilers',
                    unit: 'mt',
                    value: telegramUtils.telegramValues.bo1HsfoCons,
                    validation: {
                        oneOfAll: ['me1HsfoCons', 'ge1HsfoCons', 'bo1HsfoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'bo1HsfoCons', ['me1HsfoCons', 'ge1HsfoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                },
                me1HsdoCons: {
                    type: 'noNegativeNumber',
                    label: 'Main Engine',
                    value: telegramUtils.telegramValues.me1HsdoCons,
                    unit: 'mt',
                    validation: {
                        oneOfAll: ['me1HsdoCons', 'ge1HsdoCons', 'bo1HsdoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'me1HsdoCons', ['ge1HsdoCons', 'bo1HsdoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                },
                ge1HsdoCons: {
                    type: 'noNegativeNumber',
                    label: 'Gen. Engine',
                    unit: 'mt',
                    value: telegramUtils.telegramValues.ge1HsdoCons,
                    validation: {
                        oneOfAll: ['me1HsdoCons', 'ge1HsdoCons', 'bo1HsdoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'ge1HsdoCons', ['me1HsdoCons', 'bo1HsdoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                },
                bo1HsdoCons: {
                    type: 'noNegativeNumber',
                    label: 'Boilers',
                    unit: 'mt',
                    value: telegramUtils.telegramValues.bo1HsdoCons,
                    validation: {
                        oneOfAll: ['me1HsdoCons', 'ge1HsdoCons', 'bo1HsdoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'bo1HsdoCons', ['me1HsdoCons', 'ge1HsdoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                },
                me1LsdoCons: {
                    type: 'noNegativeNumber',
                    label: 'Main Engine',
                    value: telegramUtils.telegramValues.me1LsdoCons,
                    unit: 'mt',
                    validation: {
                        oneOfAll: ['me1LsdoCons', 'ge1LsdoCons', 'bo1LsdoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'me1LsdoCons', ['ge1LsdoCons', 'bo1LsdoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                },
                ge1LsdoCons: {
                    type: 'noNegativeNumber',
                    label: 'Gen. Engine',
                    unit: 'mt',
                    value: telegramUtils.telegramValues.ge1LsdoCons,
                    validation: {
                        oneOfAll: ['me1LsdoCons', 'ge1LsdoCons', 'bo1LsdoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'ge1LsdoCons', ['me1LsdoCons', 'bo1LsdoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                },
                bo1LsdoCons: {
                    type: 'noNegativeNumber',
                    label: 'Boilers',
                    unit: 'mt',
                    value: telegramUtils.telegramValues.bo1LsdoCons,
                    validation: {
                        oneOfAll: ['me1LsdoCons', 'ge1LsdoCons', 'bo1LsdoCons']
                    },
                    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                        telegramUtils.handleChange(parentName, 'bo1LsdoCons', ['me1LsdoCons', 'ge1LsdoCons'], setFieldTouched, setFieldValue, value, values)
                    }
                }
            }
        }
    },
    removeWhiteSpaceAndCharacters: (value) => {
        let counter = 0;
        let validatedStr = value.replace(/[^0-9.]|\./g, function ($0) {
            if ($0 === "." && !(counter++)) { return "."; }
            return "";
        });
        return validatedStr;
    },
    handleChange: (parentName, property, valuesArray, setFieldTouched, setFieldValue, value, values) => {
        if (value !== '') {
            values['generalValidation'] = value;
            setFieldTouched(`${parentName}.${property}`, false);

            valuesArray.forEach(element => {
                setFieldValue(`${parentName}.${property}`, telegramUtils.removeWhiteSpaceAndCharacters(value));
            });
        }
    },
    generatorArray: (props) => {
        const generatorArray = [];
        telegramUtils.generatorOptions().forEach((option, index) => {
            let tmpObj = {};
            tmpObj[option.value + 'LoadAvgCalc'] = telegramUtils.loadAvgCalcStructure(option.value + 'LoadAvgCalc', props && props[option.value + 'LoadAvgCalc']);
            tmpObj[option.value + 'UsageHrs'] = telegramUtils.usageHrsStructure(option.value + 'UsageHrs', props && props[option.value + 'UsageHrs']);
            generatorArray.push(baseFormUtils.createValuesFromSchema(tmpObj));
        })

        return generatorArray;
    },
    loadAvgCalcStructure: (item, itemValue = '') => {
        return {
            type: 'noNegativeNumber',
            label: '',
            unit: '%',
            group: 'General',
            value: itemValue,
            viewports: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 },
            validation: {
                maxValue: 100,
                required: true
            }
        }
    },
    usageHrsStructure: (item, itemValue = '') => {
        return {
            type: 'noNegativeNumber',
            label: '',
            group: 'General',
            unit: 'hrs',
            value: itemValue,
            viewports: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 },
            validation: {
                required: true
            }
        }
    },

    passDownValues: (props) => {
        let telegramValues = telegramUtils.telegramContentValues(props), telegramStructure = telegramUtils.telegramContentStructure().structure, passDownValues = [];
        _.keys(telegramValues).forEach(key => {
            telegramValues[key] && _.keys(telegramValues[key]).forEach(innerKey => {
                if (telegramValues[key][innerKey]) passDownValues.push(_.pick(telegramStructure, _.keys(telegramStructure).filter(type => type.includes(_.capitalize(key)))))
            })
        })
        passDownValues = _.map(_.uniq(_.map(passDownValues, (obj) => { return JSON.stringify(obj) })),
            (obj) => { return JSON.parse(obj) });

        return passDownValues;
    }
}

export default telegramUtils;