// this file contains utilities functions regarding the 'fuel-speed' and 'power-speed' HULL performance widgets
import chartOptions from "components/charts/lineChart/react-lineChart";
import chartUtils from 'Utilities/charts';
import { ThemeColorsForCharts } from "Utilities/themeColorsForCharts";
import {vesselUtils} from 'common/store/storeUtils';
import { vesselStore } from 'common/store/storeUtils';
import { updateArbitraryPvFvChart } from 'widgets/vesselData/arbitraryPvFvChart.js';
import ModelDetailsUtils from 'components/modelDetails/modelDetailsUtils';
import moment from 'moment';
import service from 'common/js/service';
import Utils from 'Utilities/global';
import { keys } from 'lodash';
import { downloadChartInCSVform, downloadChartInXLSXform } from "Utilities/highchartsUtils";

const ELIGIBLE_CONFIDENCE_LEVEL = [2, 3];

const ArbitraryPvFvUtils = {
    widgetInfo: () => {
        return {
            'POWER-SPEED': {
                widgetId: 'powerSpeedChartJSON',
                curvesDataRequest: service.getPvData,
                scatterPlotsDataRequest: service.getScatterPlots,
                extraChartInfo: {
                    unit: 'Kw', 
                    scatterTooltipTitle: 'Power',
                    tabTitle: 'POWER-SPEED',
                    yAxis: {
                        id: 'power-axis', 
                        title: { text: 'Power (kw)' }, 
                        opposite: false
                    }
                }
            },
            'FUEL-SPEED': {
                widgetId: 'fuelSpeedChartJSON',
                curvesDataRequest: service.getFuelSpeedData,
                scatterPlotsDataRequest: service.getFuelSpeedScatterPlots,
                extraChartInfo: {
                    unit: 'mt/day', 
                    scatterTooltipTitle: 'Fuel',
                    tabTitle: 'FUEL-SPEED',
                    yAxis: {
                        id: 'fuel-axis', 
                        title: { text: 'Fuel Consumption (mt/day)' }, 
                        opposite: false
                    }
                }
            },
            'CII-SPEED': {
                widgetId: 'ciiSpeedChartJSON',
                curvesDataRequest: service.getCiiSpeedData,
                extraChartInfo: {
                    unit: '', 
                    tabTitle: 'CII-SPEED',
                    yAxis: {
                        id: 'cii-axis', 
                        title: { text: 'CII' }, 
                        opposite: false
                    }
                }
            }
        }
    },
    classThresholdsColors: {
        'A': ThemeColorsForCharts()['fine_status'],
        'B': ThemeColorsForCharts()['cerulean'],
        'C': ThemeColorsForCharts()['sahara'],
        'D': ThemeColorsForCharts()['medium_severity'],
        'E': ThemeColorsForCharts()['high_severity']
    },
    fuelOilOptions: [
        {
            text: 'Heavy Fuel Oil',
            type: 'heavyFuelOil',
            optionType: 'basicOption'
        }, 
        {
            text: 'Light Fuel Oil',
            type: 'lightFuelOil',
            optionType: 'basicOption'
        }, 
        {
            text: 'Diesel/Gas Oil',
            type: 'dieselGasOil',
            optionType: 'basicOption'
        }, 
        {
            text: 'Propane',
            type: 'propane',
            optionType: 'basicOption'
        }, 
        {
            text: 'Butane',
            type: 'butane',
            optionType: 'basicOption'
        }, 
        {
            text: 'Liquefied Natural Gas',
            type: 'liquefiedNaturalGas',
            optionType: 'basicOption'
        }, 
        {
            text: 'Methanol',
            type: 'methanol',
            optionType: 'basicOption'
        }, 
        {
            text: 'Ethanol',
            type: 'ethanol',
            optionType: 'basicOption'
        }
    ],
    carbonEmissionFactorsMapper: {
        'heavyFuelOil': 3.114,
        'lightFuelOil': 3.151,
        'dieselGasOil': 3.206,
        'propane': 3.000,
        'butane': 3.030,
        'liquefiedNaturalGas': 2.750,
        'methanol': 1.375,
        'ethanol': 1.913
    },
    initialSavedOptions: (draftModeSelectedOption) => {
        return {
            'POWER-SPEED': {
                windSpeedSelectedOptions: [],
                windDirectionSelectedOptions: [],
                disabledAllWindDirectionOptions: false,
                draftModeSelectedOption: draftModeSelectedOption,
                mode: 'chart'
            },
            'FUEL-SPEED': {
                windSpeedSelectedOptions: [], 
                windDirectionSelectedOptions: [],
                disabledAllWindDirectionOptions: false,
                draftModeSelectedOption: draftModeSelectedOption,
                mode: 'chart'
            },
            'CII-SPEED': {
                windSpeedSelectedOptions: [], 
                windDirectionSelectedOptions: [],
                disabledAllWindDirectionOptions: false,
                draftModeSelectedOption: draftModeSelectedOption,
                mode: 'chart',
                fuelOilSelectedOption: ArbitraryPvFvUtils.fuelOilOptions[0]
            }
        }
    },
    createArbitraryChartPlotBands: thresholds => {
        if(!thresholds) return {};

        const sortedThresholdKeys = keys(thresholds).sort();
        
        const plotBandsLimits = sortedThresholdKeys.map((thresholdKey, index) => {
            return {
                color: ArbitraryPvFvUtils.classThresholdsColors[thresholdKey],
                className: 'arbitraryChartPlotbands',
                from: thresholds[sortedThresholdKeys[index]].lower,
                to: thresholds[sortedThresholdKeys[index]].upper,
                label: {
                    text: `Class ${thresholdKey}`,
                    style: { fill: ThemeColorsForCharts()['secondary_text'] }
                },
                zIndex: 5 // needed for showing label above curve lines
            }
        });

        return plotBandsLimits;
    },
    getFormattedTableData: (data, draftModeSelectedOption) => {
        if(!data) return [];
        const sogs =  data.find(obj => obj.value === draftModeSelectedOption)?.sogs;

        if(!sogs) return [];

        // return an array of rows, each row is an object of the form: {sog: ..., calm: ..., 1: [], 2: [], 3: [],...}
        // the 1,2 and 3 correspond to wind speed bfs
        const tableData = sogs.map(sog => {
            sog.sog = Utils.roundToDigits(sog.sog, 1);
            Object.assign(sog, sog.windSpeeds);
            delete sog.windSpeeds;
            return sog;
        });

        return tableData;
    },
    // function that returns an array of draft mode options that each one contains the appropriate name
    getFormattedDraftModeOptions: (draftModeOptions) => {
        return draftModeOptions?.map((option) => {
            return {
                name: `${option.draftFore}m - ${option.draftAft}m ${!option?.type?.includes('CUSTOM') ? `(${Utils.upperCaseFirstLetter(option.type.toLowerCase())})` : ''}`,
                type: option.type
            }
        });
    },
    getWeatherConditionIds: (weatherConditionOptions, selectedOptions) => {
        const weatherConditionIds = [];
        
        // in case no options are selected (on mount), we add to the array the id: 1, which is the id of the default 'Calm Sea State' option
        if(!selectedOptions.windSpeedSelectedOptions.length) return [1];

        // for every selected wind speed option, we loop through all the selected wind direction options so we can find the ids of each 'wind speed - wind direction' combination
        selectedOptions.windSpeedSelectedOptions.forEach((windSpeedOption) => {
            if(windSpeedOption === 'Calm Sea State') return weatherConditionIds.push(1);

            selectedOptions.windDirectionSelectedOptions.forEach((windDirectionOption) => {
                const windSpeedOptionValue = parseInt(windSpeedOption); // extract the arithmetic wind speed value from the string
                const id = weatherConditionOptions.find((wc) => wc.description.windSpeed === windSpeedOptionValue && wc.description.windDirection === windDirectionOption)?.id;

                weatherConditionIds.push(id);
            });
        });

        // sort the integers in ascending order, so that the default Calm (ISO) option with id:1 comes first 
        // (in order for it to be shown first in the chart also)
        return weatherConditionIds.filter(wcid => wcid).sort((id1, id2) => id1 - id2);
    },
    // we show the curve's info on the footer only if the confidence level is different than 'low' or 'high' (codes 2 and 3 respectively)
    // note: the 'low', 'high' confidence levels info are shown in the chart's tooltip 
    // filterFooterInfo: (footerArray) => footerArray.filter(curve => curve.confidence !== 2 && curve.confidence !== 3),
    // make the necessary requests for retrieving the power-speed or the fuel-speed widget data depending on the mode (chart mode/ table mode)
    getDataAndUpdateWidget: async (widgetTitle, setData, setLoader, extraAction, requestInfo, widget, weatherConditionOptions, selectedOptions, setFooterData, setPlotBandsInfo) => {
        // from all the selected options, find the weather condition ids that these options correspond to
        const weatherConditionIds = ArbitraryPvFvUtils.getWeatherConditionIds(weatherConditionOptions, selectedOptions);
        const seaTrialType = selectedOptions?.draftModeSelectedOption?.type ? selectedOptions.draftModeSelectedOption.type : 'SCANTLING';

        if(selectedOptions.mode === 'chart') 
            ArbitraryPvFvUtils.getDataAndUpdateChart(widgetTitle, setData, setLoader, extraAction, requestInfo, widget, weatherConditionOptions, weatherConditionIds, seaTrialType, setFooterData, selectedOptions, setPlotBandsInfo);
        else if(selectedOptions.mode === 'table') 
            ArbitraryPvFvUtils.getDataAndUpdateTable(widgetTitle, setLoader, setData, selectedOptions, requestInfo, weatherConditionIds, seaTrialType);
    },
    getDataAndUpdateTable: async (widgetTitle, setLoader, setData, selectedOptions, requestInfo, weatherConditionIds, seaTrialType) => {
        let tableData = [];

        setLoader(true);

        // depending on the widgetTitle, perform the appropriate request for retrieving the table data
        if(widgetTitle === 'POWER-SPEED') {

            tableData = await service.getPowerSpeedTableData({
                ...requestInfo,
                weatherConditionIds: weatherConditionIds,
                seaTrialType: seaTrialType
            });

        } else if(widgetTitle === 'FUEL-SPEED') tableData = await service.getFuelSpeedTableData({
            ...requestInfo,
            weatherConditionIds: weatherConditionIds,
            seaTrialType: seaTrialType
        });
        
        setLoader(false);

        const formattedTableData = ArbitraryPvFvUtils.getFormattedTableData(tableData, selectedOptions.draftModeSelectedOption.type);
        
        setData({tableData: formattedTableData});
    },
    convertWeatherCondIDtoDescr: (weatherConditionId, weatherConditionOptions) => {
        if (!weatherConditionId) return '-';
        const weather = weatherConditionOptions?.find(wc => wc.id === weatherConditionId);
        if (weatherConditionId === 1) return '(Calm)';
        if (!weather) return '-';
        return `(${weather.description.windDirection} ${weather.description.windSpeed} Bf)`;
    },
    getDataAndUpdateChart: async (widgetTitle, setData, setLoader, extraAction, requestInfo, widget, weatherConditionOptions, weatherConditionIds, seaTrialType, setFooterData, selectedOptions, setPlotBandsInfo) => {
        // for dashboard widgets, we pass the widget info through params, while for monitoring we create the widget info here
        if(!widget) widget = { 
            widgetId: ArbitraryPvFvUtils.widgetInfo()[widgetTitle].widgetId,
            inDashboard: false, 
            vesselIds: [vesselStore().selectedVessel]
        };

        setLoader(true);

        let footerData = {};
        const thresholds = {};
        
        const dataPerWeatherCondition = await Promise.all(weatherConditionIds.map(async wcId => {
            const paramsObj = {
                ...requestInfo,
                weatherConditionId: wcId,
                seaTrialType: seaTrialType
            };

            // for the CII-SPEED widget, we want add an extra param to the charts request regarding the fuel oil type that the user selected
            // if the param is a custom number typed by the user, then we send that number. Or else if it its a fuel type option then we send the equivalent fuel type's number
            if(widgetTitle === 'CII-SPEED' && selectedOptions.fuelOilSelectedOption)
                paramsObj.fuelOilType = (Utils.stringIsNumeric(selectedOptions.fuelOilSelectedOption.type))
                    ? selectedOptions.fuelOilSelectedOption.type
                    : ArbitraryPvFvUtils.carbonEmissionFactorsMapper[selectedOptions.fuelOilSelectedOption.type];

            const curvesData = await ArbitraryPvFvUtils.widgetInfo()[widgetTitle].curvesDataRequest(paramsObj);

            if(curvesData?.ciiClassLimits?.thresholds) Object.assign(thresholds, curvesData.ciiClassLimits.thresholds);

            const confidence = curvesData?.curves[seaTrialType]?.confidence;
            const cleanestStateConfidenceLevel = (confidence?.CLEANEST_STATE || confidence?.CLEANEST_STATE === 0) ? confidence?.CLEANEST_STATE : confidence === null ? null : undefined;
            const currentConfidenceLevel = confidence === null ? null : (confidence?.ACTUAL || confidence?.ACTUAL === 0) ? confidence?.ACTUAL : undefined;
            const isConfidenceLeveleligIble = ELIGIBLE_CONFIDENCE_LEVEL.includes(currentConfidenceLevel);
            const isCleanestStateConfidenceLeveleligIble = ELIGIBLE_CONFIDENCE_LEVEL.includes(cleanestStateConfidenceLevel);

            //check if confidence level
            //fix hull performance footer data for all currents (Actual) curves.
            if (currentConfidenceLevel !== undefined && !isConfidenceLeveleligIble) {
                const footerTitle = `Current ${ArbitraryPvFvUtils.convertWeatherCondIDtoDescr(wcId, weatherConditionOptions)}`;
                footerData[currentConfidenceLevel]?.length
                    ? footerData[currentConfidenceLevel].push(footerTitle)
                    : footerData[currentConfidenceLevel] = [footerTitle];
            }

            //fix hull performance footer data for cleanest state curve
            if (cleanestStateConfidenceLevel !== undefined && !isCleanestStateConfidenceLeveleligIble) {
                const footerTitle = `Cleanest State ${ArbitraryPvFvUtils.convertWeatherCondIDtoDescr(wcId, weatherConditionOptions)}`;
                const conf = cleanestStateConfidenceLevel;
                footerData[conf]?.length
                    ? footerData[conf].push(footerTitle)
                    : footerData[conf] = [footerTitle];
            }

            const scatterPlotsData = ArbitraryPvFvUtils.widgetInfo()[widgetTitle].scatterPlotsDataRequest ? await ArbitraryPvFvUtils.widgetInfo()[widgetTitle].scatterPlotsDataRequest(
                { 
                    ...requestInfo,
                    scatterPlotCount: weatherConditionIds.length, // the total number of scatter plots will be equivalent to the number of the selected weather conditions
                    dashboardTemplateId: '',
                    weatherConditionId: wcId,
                    seaTrialType: seaTrialType
                }
            ) : null;

            // the wind info will be in the form of (Head 1 Bf), (Tail 2 Bf) or (ISO) for the default Calm (ISO) case
            // this information goes to the chart's tooltip and legend for every series
            const windInfo = ArbitraryPvFvUtils.convertWeatherCondIDtoDescr(wcId, weatherConditionOptions);

            return(
                {
                    weatherConditionId: wcId,
                    curvesData: curvesData?.curves || {},
                    scatterData: scatterPlotsData || {},
                    tooltipExtraInfo: {
                        windInfo
                    }
                }
            );
        }));

        setFooterData(footerData);

        if(setPlotBandsInfo) setPlotBandsInfo({
            thresholds
        });

        const dataObj = {
            draftModeSelectedOption: seaTrialType,
            dataPerWeatherCondition,
            thresholds
        }

        setLoader(false);

        if(extraAction) extraAction();

        updateArbitraryPvFvChart(
            widget.widgetId, 
            dataObj, 
            (id, data) => setData(data), 
            { widgetTitle },
            widget,
        );
    },
    // depending on the type of the series (curves or scatter) return the appropriate series object
    getSeriesObject: (type, seriesInfo) => {
        const commonValues = {
            name: seriesInfo.name, 
            data: seriesInfo.data, 
            tooltipTitle: seriesInfo.tooltipTitle, 
            yAxis: seriesInfo.axisName,
            color: seriesInfo.color
        }
        return type === 'curves'
            ? {
                ...commonValues,
                titleSuffix:'Kn', 
                tootltipXTitle: 'Speed: ', 
                tooltip: {
                    valueSuffix: ` ${seriesInfo.unit}`, 
                    valueDecimals: 0
                }
            }
        : {
            ...commonValues,
            type: 'scatter', 
            customTooltipBorderColor: seriesInfo.color,
            marker: {enabled: true, symbol: 'circle'}
        }
    },
    getSeriesColors: colorIndex => {
        const referenceLinesColors = [
            ThemeColorsForCharts()['first_reference_line'],
            ThemeColorsForCharts()['second_reference_line'],
            ThemeColorsForCharts()['third_reference_line'],
            ThemeColorsForCharts()['forth_reference_line'],
            ThemeColorsForCharts()['fifth_reference_line'],
            ThemeColorsForCharts()['sixth_reference_line'],
            ThemeColorsForCharts()['seventh_reference_line'],
        ];

        return referenceLinesColors[colorIndex];
    },
    getDynamicSeriesFilledWithData: (dataObj, axisName, extraChartInfo) => {
        const series = [];
        let colorIndex = 0;

        // for every weather condition selected add the appropriate series to the chart
        dataObj.dataPerWeatherCondition.forEach((data, index) => {
            // add the current and sensor data series
            const currentCurvesSeries = ArbitraryPvFvUtils.getSeriesObject(
                'curves',
                {
                    ...extraChartInfo,
                    axisName,
                    name: `Current ${data?.tooltipExtraInfo?.windInfo || ''}`,
                    tooltipTitle: `Current ${data?.tooltipExtraInfo?.windInfo || ''}`,
                    color: ArbitraryPvFvUtils.getSeriesColors(colorIndex),
                    data: data?.curvesData[dataObj?.draftModeSelectedOption]?.curves?.ACTUAL || []
                }
            );

            series.push(currentCurvesSeries);

            // CII-SPEED tab does not have scatter plots series
            if(extraChartInfo.tabTitle !== 'CII-SPEED') {
                const sensorDataSeries = ArbitraryPvFvUtils.getSeriesObject(
                    'scatter',
                    {
                        ...extraChartInfo,
                        axisName,
                        name: `Source Data ${data?.tooltipExtraInfo?.windInfo || ''}`,
                        tooltipTitle: `${extraChartInfo.scatterTooltipTitle} ${data?.tooltipExtraInfo?.windInfo || ''}`,
                        color: ArbitraryPvFvUtils.getSeriesColors(colorIndex++),
                        data: data?.scatterData[dataObj.draftModeSelectedOption] || []
                    }
                );

                series.push(sensorDataSeries);
            }
            
            const cleanestStateCurvesSeries = ArbitraryPvFvUtils.getSeriesObject(
                'curves',
                {
                    ...extraChartInfo,
                    axisName,
                    name: `Cleanest State ${data?.tooltipExtraInfo?.windInfo || ''}`,
                    tooltipTitle: `Cleanest State ${data?.tooltipExtraInfo?.windInfo || ''}`,
                    color: ArbitraryPvFvUtils.getSeriesColors(colorIndex++),
                    data: data?.curvesData[dataObj.draftModeSelectedOption]?.curves?.CLEANEST_STATE || []
                }
            );
            series.push(cleanestStateCurvesSeries);
                
            if (data.weatherConditionId === 1) {

                const seaTrialCurvesSeries = ArbitraryPvFvUtils.getSeriesObject(
                    'curves',
                    {
                        ...extraChartInfo,
                        axisName,
                        name: `Sea Trial`,
                        tooltipTitle: `Sea Trial`,
                        color: ArbitraryPvFvUtils.getSeriesColors(colorIndex++),
                        data: data?.curvesData[dataObj.draftModeSelectedOption]?.curves?.EXTRAPOLATED || []
                    }
                );

                series.push(seaTrialCurvesSeries);
            }

        });

        return series;
    },
    drawChart: (id, dataObj, updateState, widget, extraChartInfo) => {
        const chartDataTemp = {
            chartDataJSON: null
        };

        const getConfidenceColor = confidence => {
            return ModelDetailsUtils.getInfoOfGivenConfidenceLevel(
                ModelDetailsUtils.confidenceLeveStringMapper(confidence)
            ).style.color;
        }

        let tempPvJSON = Object.assign({}, chartOptions(":.2f", '%'));

        // create the chart's series and fill it with data
        tempPvJSON.series = ArbitraryPvFvUtils.getDynamicSeriesFilledWithData(dataObj, extraChartInfo?.yAxis?.id, extraChartInfo);
        tempPvJSON.series = tempPvJSON.series.map(serie => {
            if (!serie.data || !serie.data.length) serie.showInLegend = false;
            return serie;
        });

        tempPvJSON.chart.height = 342;
        tempPvJSON.legend.itemDistance = 10;
        tempPvJSON.legend.itemMarginBottom = 7;
        tempPvJSON.legend.margin = 25;
        tempPvJSON.yAxis = extraChartInfo?.yAxis;
        tempPvJSON.xAxis.title = { text: "SPEED (Kn)" };
        tempPvJSON.xAxis.title.y = 10;
        tempPvJSON.xAxis.type = "number";
        tempPvJSON.xAxis.tickPositioner = undefined;
        tempPvJSON.xAxis.labels.formatter = undefined;
        tempPvJSON.plotOptions.series.connectNulls = true;
        tempPvJSON.tooltip.borderColor = chartUtils.tootlipBorderColor.mixedData();
        tempPvJSON.tooltip.outside = true;
        tempPvJSON.tooltip.style = {"zIndex": 10002};
        
        // a common formatter for creating the tooltip's UI for both scatter data and curves data
        tempPvJSON.tooltip.formatter = function() {
            // get the points array depending on if the user hovers on scatter data or on curves data (in case of scatter data, we have only one point)
            const isScatterSeries = !this?.points?.length;
            const points = !isScatterSeries ? this?.points : [this];

            const tooltip = points?.length ? `<div class="full-width highcharts-fonts">
                <div class="flex-centered-col">
                    <div class="small-label">
                        ${!isScatterSeries ? 'Based on the hull state of: ' : ''} ${moment(points[0]?.point?.datetime)?.utc()?.format('DD/MM/YYYY HH:mm')} (UTC)
                    </div>
                    <div class="marg-t-5">
                        <span class="autocomplete-title">
                            Speed:
                        </span>
                        <span class="small-label">
                            ${points[0]?.point?.x?.toFixed(2)} Kn
                        </span>
                    </div>
                </div>
                ${
                    points.map((pointObj, index) => `
                        <div class="full-width ${index === 0 ? 'marg-t-10' : 'marg-t-5'}">
                            <div class="full-width flex-space-between">
                                <div class="flex-centered">
                                    <div class="tooltip-circle" style="background-color:${pointObj?.color};"></div>
                                    <div class="inlineBlock">
                                        ${pointObj?.series?.userOptions?.tooltipTitle}: 
                                    </div>
                                </div>
                                ${extraChartInfo.tabTitle !== 'CII-SPEED' ? `<div class="small-label">
                                    ${pointObj?.point?.y ? Utils.renderNumber(Utils.roundToDigits(pointObj.point.y, 2)) : ''} ${extraChartInfo?.unit ? extraChartInfo.unit : ''} ${pointObj?.point?.z ? pointObj.point.z : ''}
                                </div>` : ''}
                                ${extraChartInfo.tabTitle === 'CII-SPEED' ? `<div class="small-label">
                                    ${pointObj?.point?.z ? pointObj.point.z : ''}
                                </div>` : ''}
                            </div>
                            ${(!isScatterSeries && !(extraChartInfo.tabTitle === 'CII-SPEED' && pointObj?.point?.series?.name?.includes('Sea Trial')) && (pointObj?.point?.v === 'Low' || pointObj?.point?.v === 'High'))
                                ? `<div class='marg-l-12'>Confidence Level: 
                                    <span style="color: ${getConfidenceColor(pointObj?.point?.v)}"> 
                                        ${pointObj?.point?.v} 
                                    </span>
                                </div>`
                                : ''
                            }
                        </div>
                    `)?.join('')
                }
            </div>` : '';

            return tooltip;
        };
        const csvXlsxData = (obj) => {

            // creating dynamic headers base on user selection from the UI with the callback obj.getDataRows(true)
            // remove the metric units in order to mach them correctly in the loop and put the unit after    
            let headers = obj.getDataRows(true)[0].map((row) => row.includes("SPEED") ? row.replace("(kn)", "") : row)
            // adding a column with the name Datetime
            headers.unshift('Datetime')
            //add (kW) or (mt/day) to the columns headers that needs to change
            const name = obj.options.exporting.filename
            const metricValueUnit = name.includes("POWER-SPEED") ? ' (kW)' : name.includes("FUEL-SPEED")  ? ' (mt/day)' : ''
            

            const dataMap = new Map();
            
            obj.options.series.forEach(serie => { 
                serie.data.forEach(({ datetime, x, y }) => { 
                    const key = `${datetime}-${x}`; 
                    if (!dataMap.has(key)) { 
                        // Initialize the row with empty values 
                        dataMap.set(key, [moment(datetime).utc().format('DD-MM-YYYY HH:mm:ss'), x, "", "", "", ""]); 
                    } 
                    // Insert the y value into the correct column based on the series name
                    const row = dataMap.get(key); 
                    const columnIndex = headers.indexOf(serie.name);
                    row[columnIndex] = y; 
                });
            });
            // Add the unit to the headers after the loop in order not to break the list mapping
            headers = headers.map(val => (val.includes("(") && !val.includes('Kn')) ? `${val}  ${metricValueUnit}` : val);

            // Step 3: Convert map values to an array and sort by speed (x) and datetime
            let rows = [headers, ...Array.from(dataMap.values()).sort((a, b) => a[1] - b[1] || a[0] - b[0])];            
                        
            const sheetsName = name ? name.substr(0, 100) : 'Sheet 1';

            return {rows, sheetsName, name};
        }

        tempPvJSON.exporting.buttons.contextButton= {
            menuItems: [
                "download",
                "customSeparator",
                {
                    text: "XLSX",
                    onclick: function () {
                        const dto = csvXlsxData(this)
  
                        downloadChartInXLSXform({xlsxRows: dto.rows, sheetsName: dto.sheetsName, name: dto.name});

                    }
                },
                {
                    text: "CSV",
                    onclick: function () {
                        const dto = csvXlsxData(this)

                        const csvContent = dto.rows.map(row => row.join(',')).join('\n');

                        downloadChartInCSVform({csvContent, name: dto.sheetsName});

                    }
                },
            ]
            };
        tempPvJSON.exporting.buttons.contextButton.x = 0;
        tempPvJSON.exporting.buttons.contextButton.y = 30;
        
        // if widget is in HULL page, then the exporting file's name will be {Vessel Name}-{Tab Title}
        if(!widget?.inDashboard) tempPvJSON.exporting.filename = `${vesselUtils?.getVesselName(widget?.vesselIds[0])}-${extraChartInfo?.tabTitle}`;
        // if widget is in Dashboard, then the exporting file's name will be just {Vessel Name}-{Widget Title}
        else tempPvJSON.exporting.filename = `${vesselUtils?.getVesselName(widget?.vesselIds[0])}-HULL PERFORMANCE`;
        
        tempPvJSON.navigation.menuStyle.height = 150;
    
        chartDataTemp.chartDataJSON = tempPvJSON;
    
        return updateState(id, chartDataTemp, widget?.id);
    }
}

export default ArbitraryPvFvUtils;