import React, { useState } from 'react';
import SetLegModal from 'components/modals/setLegModal';

const SearchAnotherLeg = props => {
    const [modalShow, setModalShow] = useState(false);

    const setUserLeg = values => {
        props.selectUserLeg(values);
        setModalShow(false);
    }

    return (
        <div className="marg-b-15">
            <button type="btn" className="main-cta" onClick={() => setModalShow(true)}>
                <div className="main-cta__text">Search Another Leg</div>
            </button>
            <SetLegModal show={modalShow} onHide={() => setModalShow(false)} onSave={setUserLeg} data={{}} />
        </div>
    );
}

export default SearchAnotherLeg;