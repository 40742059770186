import React, { useState, useEffect } from 'react';
import NavigationTabs from "components/navigationTabs/navigationTabs";
import { useDispatch, useSelector } from 'react-redux';
import VesselPerformance from 'components/monitoringCategories/vesselPerformance';
import CiiVesselMonitoring from 'components/monitoringCategories/ciiVesselMonitoring';
import OverallPeriodHeader from 'components/monitoringCategories/overallPeriodHeader';
import moment from 'moment/moment';
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import classNames from 'classnames';
import CompanyMonitoring from 'components/monitoringCategories/companyMonitoring';
import GroupMonitoring from 'components/monitoringCategories/groupMonitoring';
import service from 'common/js/service';

// Overall and performance tabs
const _VESSEL_MONITORING_TABS = [
    {title: 'OVERALL', name: 'overall', visible: true},
    {title: 'PERFORMANCE', name: 'performance', visible: true},
];

const VesselMonitoring = () => {
    const [currentTab, setCurrentTab] = useState('overall');
    const [overallReportsFromToChanged, setOverallReportsFromToChanged] = useState(null);
    
    const selectedYearFromPicker = useSelector(state => state.monitoring.ciiSearchingYear.selectedPickerYear);
    const dispatch = useDispatch()

    const [selectedPickerYear, setSelectedPickerYear] = useState(selectedYearFromPicker);
    
    const selectedPeriod = {
        from: moment(`01/01/${selectedYearFromPicker}`,'DD/MM/YYYY').utc(true).valueOf(),
        to: selectedYearFromPicker === moment().year() 
            ? moment.utc().valueOf() 
            : moment(`31/12/${selectedYearFromPicker}`,'DD/MM/YYYY').endOf('day').utc(true).valueOf()
    }
    
    // On year change from picker set the selected year to state
    const goToDates = () => {
        dispatch({ type: 'monitoring/setCiiSearchingDatesFromTo', payload: { selectedPickerYear: selectedPickerYear }})
        setOverallReportsFromToChanged(new Date());
    };

    //this is the request to set the dates in the OverallPeriodHeader Component through redux store
    const getResponseDates = async () => {
        const data = (vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id) 
            ? await service.getCompanyPeriod({from: selectedPeriod.from, to: selectedPeriod.to})
            : (vesselGroupStoreJSX.selectedVesselGroup !== vesselGroupStoreJSX.allVesselGroupsObj.id)
                && ( vesselStoreJSX.selectedVessel === vesselStoreJSX.allVesselsObj.vesselId ) 
                    ? await  service.getFleetPeriod({from: selectedPeriod.from, to: selectedPeriod.to, id: vesselGroupStoreJSX.selectedVesselGroup})
                    : await service.getVesselPeriod({from: selectedPeriod.from, to: selectedPeriod.to, id: vesselStoreJSX.selectedVessel})

        dispatch({ type: 'monitoring/setCiiDatesFromTo', payload: { from: data?.dateFrom, to: data?.dateTo }})
        registerWidgetsStore.setFromTo({from: data?.dateFrom, to: data?.dateTo});
    }


    // store vars
    const vesselStoreJSX = useSelector(state => state.vessel);
    const vesselGroupStoreJSX = useSelector(state => state.vesselGroup);

    const updateCurrentTab = tab => setCurrentTab(tab);

    // Vessel monitoring all navigation tabs props
    const navTabsProps = {
        tabs: _VESSEL_MONITORING_TABS,
        currentTab: currentTab,
        updateCurrentTab: updateCurrentTab
    }

    // On mount, change fleet or vessel
    useEffect(() => {
        if(currentTab === "overall") getResponseDates();

        // eslint-disable-next-line
    }, [vesselGroupStoreJSX.selectedVesselGroup, vesselStoreJSX.selectedVessel, overallReportsFromToChanged]);

    // classnames for page titles
    const getSectionTitleClass = isPageHeader => {
        return classNames(
            'section-title','cii-page-title',
            {
                'marg-b-65': vesselStoreJSX.selectedVessel === vesselStoreJSX.allVesselsObj.vesselId,
                'companyMonitoringPageTitle': isPageHeader && (vesselStoreJSX.selectedVessel === vesselStoreJSX.allVesselsObj.vesselId && vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id),
                'groupMonitoringPageTitle': isPageHeader && (vesselStoreJSX.selectedVessel === vesselStoreJSX.allVesselsObj.vesselId && vesselGroupStoreJSX.selectedVesselGroup !== vesselGroupStoreJSX.allVesselGroupsObj.id),
                'vesselMonitoringPageTitle': isPageHeader && (vesselStoreJSX.selectedVessel !== vesselStoreJSX.allVesselsObj.vesselId)
            }
        );
    }

    return (
		<div className="vessel-monitoring-container">

            {/* On this div "main-content-padding" we have the fixed elements (titles, tabs, period header) */}
			<div className="main-content-padding pad-b-0 marg-b-15">
                {/* Page titles */}
                <div className={getSectionTitleClass(true)}>
					{vesselStoreJSX.selectedVessel === vesselStoreJSX.allVesselsObj.vesselId
						? vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id
							? "COMPANY MONITORING"
							: "GROUP MONITORING"
						: "VESSEL MONITORING"}
				</div>

                {/* Vessel monitoring tabs only if selectedVessel */}
                {/* And CIIOverall period header only at overall tab not on performance */}
				<div className='vessel-monitoring-fixed-header'>
                    {/* Navigation tabs when selectedVessel */}
                    {vesselStoreJSX.allVesselsObj.vesselId !== vesselStoreJSX.selectedVessel && (
                        <NavigationTabs 
                            {...navTabsProps} 
                            classes={{
                                mainClass: 'secondary-navigation-tabs',
                                extraClasses: 'd-flex align-items-center marg-b-2 cii-vessel-monitoring-navigator'
                            }}
                        />
                    )}

                    {/* CII Overall period header */}
					{ !(vesselStoreJSX.selectedVessel !== vesselStoreJSX.allVesselsObj.vesselId && currentTab === 'performance')  &&
                        <OverallPeriodHeader 
                            reportsOverallPeriod={selectedPeriod}
                            selectedPickerYear={selectedPickerYear}
                            setSelectedPickerYear={setSelectedPickerYear}
                            goToDates={goToDates}
                        />
                    }
				</div>
			</div>

            {/* If not selected group then render Company Page */}
            { (vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id) 
                ? <div className="monitoring-pages">
                    <div className={getSectionTitleClass()}>
                        <CompanyMonitoring 
                            overallReportsPeriod={selectedPeriod}
                            overallReportsFromToChanged={overallReportsFromToChanged}
                        />
                    </div>
                </div>
                : null
            }

            {/* if selected group and not selected vessel then render Group Page */}
            {
                (vesselGroupStoreJSX.selectedVesselGroup !== vesselGroupStoreJSX.allVesselGroupsObj.id)
                && ( vesselStoreJSX.selectedVessel === vesselStoreJSX.allVesselsObj.vesselId )
                    ? <div className="monitoring-pages">
                        <div className={getSectionTitleClass()}>
                            <GroupMonitoring
                                overallReportsPeriod={selectedPeriod}
                                overallReportsFromToChanged={overallReportsFromToChanged}
                            />
                        </div>
                    </div>
                    : null
            }

            {/*if selected vessel then render tabs + ( Overall page or Performance page ) */}
            {
                (vesselGroupStoreJSX.selectedVesselGroup !== vesselGroupStoreJSX.allVesselGroupsObj.id)
                && ( vesselStoreJSX.selectedVessel !== vesselStoreJSX.allVesselsObj.vesselId )
                    ? <div className="monitoring-pages">
                        {currentTab === "overall" && (
                            <CiiVesselMonitoring
                                overallReportsPeriod={selectedPeriod}
                                overallReportsFromToChanged={overallReportsFromToChanged}
                            />
                        )}
                        {currentTab === "performance" && <VesselPerformance />}
                    </div>
                : null
            }

		</div>
	)
}

export default VesselMonitoring;