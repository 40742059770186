import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import SelectField from "components/core/SelectField";


const _REPORTS = [
    {label: 'DAILY GROUP REPORT', value: 0, uri: '/reports/dailyGroupReport'},
    {label: 'DAILY VESSEL REPORT', value: 1, uri: '/reports/dailyVesselReport'},
    {label: 'LEG REPORT', value: 2, uri: '/reports/legReport'}
];


const ReportsSelectNavigation = (props) => {
    const [currentReportView, setCurrentReportView] = useState(_REPORTS[0].value);

    useEffect(() => {
        const report = _REPORTS?.find(r => r.uri === window.location.pathname);
        if(!report) return;
        setCurrentReportView(report.value);
    }, []);

    const changeReportView = (event, report) => {
        setCurrentReportView( event.target.value );
        props.history.push(report.uri);
    }

    return (
        <SelectField name="reports" value={currentReportView} options={_REPORTS} classNames={{element: "select-section-title"}}
        onChange={(event) => changeReportView(event, _REPORTS.find(r => r.value === event.target.value))} />
    );
}
 
export default withRouter(ReportsSelectNavigation);
