import { BoilersUtils } from "Utilities/boilers";
import boStorage from 'components/monitoringCategories/localStorages/boStorage';
import keys from 'lodash/keys';
import Utilities from "Utilities/global";
import moment from "moment";
import values from 'lodash/values';
import invert from 'lodash/invert';
import {licensing} from "common/store/licensing";
import { vesselUtils } from 'common/store/storeUtils';

let boilersSubIds = {}, boilerSubIdsWithWidget = {}, noBoilersConfig = {};

export const boilersDetailsChartPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    timeGroup: 'HOUR',
    withBoilerStatus: false,
    metrics: [
        {
            metricCategory: "BOILER",
            subIds: [],
            metricData: [
                {
                    metricName: "boilerSteamPress"
                }
            ]
        },
        {
            metricCategory: "BOILER",
            subIds: [],
            metricData: [
                {
                    metricName: "boilerExhGasOutTemp"
                }
            ]
        },
        {
            metricCategory: "BOILER",
            subIds: [],
            metricData: [
                {
                    metricName: "boilerFOInLETTemp"
                }
            ]
        },
        {
            metricCategory: "BOILER",
            subIds: [],
            metricData: [
                {
                    metricName: "boilerFOInLETPress"
                }
            ]
        },
    ]
};

export const setBoilersDetailsTypes = (data, widget) => {
    let tempArrTypes = [], typeCount = 1;
    boilersSubIds = {};

    data = data.filter(item => item.type && item.subId && BoilersUtils.acceptedBoilerTypes.includes(item?.type));

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]))

    // Add label field in returned object - add index if multiple identical types exist
    data.forEach((obj, index) => {
        if (data[index]?.type === data[index + 1]?.type) {
            tempArrTypes.push(`${data[index].type} ${typeCount}`);
            tempArrTypes.push(`${data[index].type} ${typeCount + 1}`);
            typeCount++;
        } else {
            (tempArrTypes.indexOf(`${data[index].type} ${typeCount}`) < 0) && tempArrTypes.push(`${data[index].type}`);
            typeCount = 1;
        }
    });
    tempArrTypes = tempArrTypes.filter(function (value, index, array) {
        return array.indexOf(value) === index;
    });

    data.forEach((obj, index) => {
        if(obj.type && obj.subId) boilersSubIds[tempArrTypes[index]] = obj.subId;
    });

    boilerSubIdsWithWidget[widget?.id] = boilersSubIds;

    (!keys(boilersSubIds).length && !isLight) ? noBoilersConfig[widget?.id] = true : noBoilersConfig[widget?.id] = false;

};

export const boilersDetailsUpdatePayload = (widget) => {
    boilersDetailsChartPayload.metrics.forEach(metric => {
        metric.subIds = values(boilerSubIdsWithWidget[widget?.id]);
    });

    return boilersDetailsChartPayload;
}

const yAxisData = [
    { id: 'steamPress-axis', title: { text: 'Steam Press (bar)'}, height: '135px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
    { id: 'exhGasOutletTemp-axis', title: { text: 'Exh. Gas Outlet Temp (°C)'}, height: '135px', top: '240px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
    { id: 'fuelOilPress-axis', title: { text: 'Fuel Oil Press (bar)'}, height: '135px', top: '430px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
    { id: 'fuelOilTemp-axis', title: { text: 'Fuel Oil Temp (°C)'}, height: '140px', top: '633px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
];

const seriesData = [
    { id: `boilerSteamPress`, name: 'boilerSteamPress', data: [], tooltipTitle: 'Steam Pressure', type: 'line', yAxis: 'steamPress-axis',
    titleSuffix: '(UTC)',tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
    { id: `boilerExhGasOutTemp`, name: 'boilerExhGasOutTemp', data: [], tooltipTitle: 'Exh. Gas Outlet Temp', type: 'line', yAxis: 'exhGasOutletTemp-axis',
    titleSuffix: '(UTC)',tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
    { id: `boilerFOInLETPress`, name: 'boilerFOInLETPress', data: [], tooltipTitle: 'Fuel Oil Pressure', type: 'line', yAxis: 'fuelOilPress-axis',
    titleSuffix: '(UTC)',tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
    { id: `boilerFOInLETTemp`, name: 'boilerFOInLETTemp', data: [], tooltipTitle: 'Fuel Oil Temperature', type: 'line', yAxis: 'fuelOilTemp-axis',
    titleSuffix: '(UTC)',tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
];

const boilerStatusSeries = {
	name: '',
	data: {},
	lineWidth: 4,
	pointPlacement: 5000000000,
	type: 'line',
	enableMouseTracking: false,
	yAxis: 'boilerStatus-axis',
	color: '#CAA3FF',
	states: {
		inactive: {
			opacity: 1
		}
	}
}

export const boilersDetailsLineChartData = {
    boilersDetailsChartMetrics: [
        'boilerSteamPress',
        'boilerExhGasOutTemp',
        'boilerFOInLETTemp',
        'boilerFOInLETPress',
    ],
    chartAxes: {},                    // Dynamically generated in buildNavigationChartAxes method
    navigationMapper: {},             // Dynamically generated in buildNavigationMapper method
    boilersDetailsLineChartTabs: [],   // Dynamically generated in buildNavigationChartTabs method
    chartSeries: {},                   // Dynamically generated in buildNavigationChartSeries method
    multiplePlotLines: {},
}
  
const buildNavigationMapper = (widget) => {
    const boilerTypeTempArray = boilerSubIdsWithWidget[widget?.id];

    keys(boilerTypeTempArray).forEach((boilerType) => {
        const tempArr = boilersDetailsLineChartData.boilersDetailsChartMetrics.map((metric) => {
            return metric;
        });
        boilersDetailsLineChartData.navigationMapper[boilerType] = tempArr;
    });
};
  
const buildNavigationChartTabs = (widget) => {
    const boilerTypeTempArray = boilerSubIdsWithWidget[widget?.id];

    boilersDetailsLineChartData.boilersDetailsLineChartTabs = keys(boilerTypeTempArray).map(boilerType => {
        return {title: boilerType, name: boilerType, type: Utilities.hasNumber(Utilities.removeNumbersFromString(boilerType))};
    });
}

const buildNavigationChartAxes = (widget) => {
    const boilerTypeTempArray = boilerSubIdsWithWidget[widget?.id];

    boilersDetailsLineChartData.chartAxes = {};

    keys(boilerTypeTempArray).forEach(boilerType => {
        boilersDetailsLineChartData.chartAxes[boilerType] = [...yAxisData];
    });
}
  
const buildNavigationChartSeries = (widget) => {
    const boilerTypeTempArray = boilerSubIdsWithWidget[widget?.id];

    boilersDetailsLineChartData.chartSeries = {};
    
    keys(boilerTypeTempArray).forEach(boilerType => {
        boilersDetailsLineChartData.chartSeries[boilerType] = [...seriesData];
    });
}

const setBoilersDetailsDataFormat = (values, data, widget) => {
    const boilerTypeTempArray = boilerSubIdsWithWidget[widget?.id];

    if(keys(boilerTypeTempArray).length === 0) return;

    let tmpObj = {}, boilerStatusLine = {}, plotLinesArray = {}, serieWithData = [];

    values.forEach(obj => {
        tmpObj[`${obj.metricName}No${invert(boilerTypeTempArray)[obj.subId]}`] = obj.value
        if(obj.value.length) serieWithData = obj.value
    });

    data[0]?.boilerStatus?.length && data[0].boilerStatus.forEach(boilerStatus => {
        const serieKey = keys(boilersSubIds).find(key => boilersSubIds[key] === boilerStatus.subId);

        boilerStatusLine[serieKey] = [];
        plotLinesArray[serieKey] = [];

        boilerStatus.statuses.forEach((status, index) => {

            /** Get navigation status datetime, and connect each one with the next one
                For the last status, connect it with the last datetime of the main response body */
            const median = moment((status.datetime +
                (data[0].boilerStatus[0].statuses[index + 1] ? data[0].boilerStatus[0].statuses[index + 1].datetime : serieWithData[serieWithData.length-1].x)) / 2).valueOf();

            status && boilerStatusLine[serieKey].push({
                ...boilerStatusSeries,
                color: Utilities.getBoilerStatus(status.status).color,
                data: [{
                    "x": status.datetime, "y": 1
                }, {
                    "x": data[0].boilerStatus[0].statuses[index + 1] ?
                        moment(data[0].boilerStatus[0].statuses[index + 1].datetime).subtract(10, 'seconds').valueOf() :
                        serieWithData[serieWithData.length-1].x,
                    "y": 1
                }]
            });

            plotLinesArray[serieKey].push({
                value: median,
                dashStyle: 'LongDashDotDot',
                width: 0,
                color: Utilities.getNavigationStatus(status.status).color,
                label: {
                    text: `<div class="plotlines-label navigation-status-label" style="background-color:${Utilities.getBoilerStatus(status.status).color}">${Utilities.getBoilerStatus(status.status).name}</div>`,
                    rotation: 0,
                    y: -20, x: -3,
                    useHTML: true,
                    align: 'center',
                    style: {
                        fontSize: '12px'
                    }
                }
            });
        });
    });

    keys(boilerStatusLine).forEach( boilerKey => {
        boilersDetailsLineChartData.multiplePlotLines[boilerKey] = [];

        boilersDetailsLineChartData.chartSeries[boilerKey] = boilersDetailsLineChartData.chartSeries[boilerKey].concat(boilerStatusLine[boilerKey]);
        boilersDetailsLineChartData.multiplePlotLines[boilerKey] = boilersDetailsLineChartData.multiplePlotLines[boilerKey].concat(plotLinesArray[boilerKey]);
    });
    //end

    return tmpObj;
};

export const updateBoilersDetailsChart = async (id, data, updateState, extraChartConfigs, widget) => {
    const tempData = JSON.parse(JSON.stringify(data));

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    if(isLight) noBoilersConfig[widget?.id] = false;
    if(noBoilersConfig[widget?.id]) return updateState(id, {noBoilersConfig: noBoilersConfig[widget?.id]}, widget?.id);

    buildNavigationMapper(widget);
    buildNavigationChartTabs(widget);
    buildNavigationChartAxes(widget);
    buildNavigationChartSeries(widget);

    boStorage.setBoDetailsChartData(tempData);
    let boDetailsJson = (tempData[0]?.values) ? setBoilersDetailsDataFormat(tempData[0].values, tempData, widget) : [];

    if (extraChartConfigs && extraChartConfigs.plotLines) {
        let plotGuides = extraChartConfigs.plotLines.setReportsPlotLineGuides(tempData);
        if (plotGuides?.xAxis) {
            boDetailsJson.xAxis = Object.assign({}, boDetailsJson.xAxis, plotGuides.xAxis);
        }
    }

    updateState(id, boDetailsJson, widget?.id);
}
