import Utilities from 'Utilities/global';
import {navigationStatusBuildChartData} from 'widgets/vesselData/navigationChart';
import {ReactComponent as AtSeaIcon} from 'assets/img/app/overview-imgs/atSea.svg';
import {ReactComponent as MooredIcon} from 'assets/img/app/overview-imgs/moored.svg';
import overviewStorage from 'components/monitoringCategories/localStorages/overviewStorage';

let hoursMoored, hoursAnchored, hoursAtSea, milesTravelled, milesTravelledArray = {};

const navigationStatusMilesPayload = {
  metrics: [{
    metricCategory: "TELEGRAM",
    subIds: [1],
    metricData: [{metricName: "miles_atsea", aggregation: "SUM"}]
  }, {
        metricCategory: "VESSEL",
        subIds: [1],
        metricData: [{ metricName: "miles_atsea", aggregation: "SUM" }]
    }]
};

const checkEmptyData = (data) => {
  data.forEach((obj) => {
    if(obj.data.every((item) => item.value === '-')) {
      obj.data = {};
    }
  });
}

const setMiles = (response, callback, widget) => {
  overviewStorage.setNavigationStatusMilesData(response);
  const dataSource = widget ? response.series : response;
  let foundMilesAtSea = false;
  milesTravelled = "-";

  if (dataSource && dataSource.length > 0) {
    dataSource[0].values.forEach(element => {
      if (element && element.metricName === "miles_atsea") {
        if (element.metricCategory === 'TELEGRAM' && element.value) {
          foundMilesAtSea = true;
          milesTravelled = Utilities.roundToDigits(parseInt(element.value)) || '-';
          milesTravelledArray[widget?.id] = milesTravelled;
        } else if (element.metricCategory === 'VESSEL' && !foundMilesAtSea) {
          milesTravelled = Utilities.roundToDigits(parseInt(element.value)) || '-';
          milesTravelledArray[widget?.id] = milesTravelled;
        }
      }
    })
  }
};

const navStatus = (id, response, updateState, extraChartConfigs, widget) => {
  // if milesData field exists then it means that 'milesTraveled' are taken from overviewStorage and not a new request is made, so set the miles here
  if(response?.milesData) {
    setMiles(response.milesData);
    response = response.navigationData; 
  }

  if (id === 'navigationStatusChart') return navigationStatusBuildChartData(response, updateState, widget);

  overviewStorage.setNavigationStatusData(response);
  let data = [];
  if (response && response.length > 0) {
    if (milesTravelled && response[0] && response[0].status) {
      hoursMoored = "-"; hoursAnchored = "-"; hoursAtSea = "-";
      response[0].status.forEach(element => {
        if (element.status === "TRAVELLING") hoursAtSea = Utilities.roundToDigits(parseInt(element.hours), 0);
        if (element.status === "ANCHORED") hoursAnchored = Utilities.roundToDigits(parseInt(element.hours), 0);
        if (element.status === "MOORED") hoursMoored = Utilities.roundToDigits(parseInt(element.hours), 0);
      });
      // Pass data to navigation data pie chart
      navigationStatusBuildChartData(response, updateState);
    } 
  } else {
    hoursMoored = "-"; hoursAnchored = "-"; hoursAtSea = "-";
    navigationStatusBuildChartData(response, updateState)
  }

  let tempMilesTravelled;
  if (widget?.inDashboard) {
    tempMilesTravelled = milesTravelledArray[widget.id]
  } else {
    tempMilesTravelled = milesTravelled;
  }

  data = [{
    icon: AtSeaIcon,
    data: [
      {label: 'HOURS AT SEA', value: hoursAtSea},
      {label: 'MILES TRAVELED', value: tempMilesTravelled}
    ]
  }, {
    icon: MooredIcon,
    data: [
      {label: 'HOURS MOORED', value: hoursMoored},
      {label: 'HOURS ANCHORED', value: hoursAnchored}
    ]
  }]

  checkEmptyData(data); // check if data is empty and assign empty object if it is

  updateState(id, data, widget?.id);
}

export {navStatus, navigationStatusMilesPayload, setMiles};
