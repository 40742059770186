import moment from 'moment';
import Utilities from '../../Utilities/global';
import WindUtilities from '../../Utilities/wind';


const MarkersPopup = {

    getOnHoverPopupHtml: vd => {
        return `
            <div class="markers-OnHover-popup">

                <div class="markers-OnHover-popup__header">${moment(vd.datetime).utc().format('DD/MM/YYYY  HH:mm')} (UTC)</div>

                <div class="markers-OnHover-popup__body">
                
                    <div class="row">
                        <div class="col-6 popup-group">
                            <div class="popup-img popup-power-img"></div>
                            <div class="popup-label">
                                <div class="popup-label__value">${Utilities.renderNumber(parseInt(vd.power))} kW</div>
                                <div>Power</div>
                            </div>
                        </div>
                        <div class="col-6 popup-group">
                            <div class="popup-img popup-stw-img"></div>
                            <div class="popup-label">
                                <div class="popup-label__value">${Utilities.renderNumber(Utilities.roundToDigits(vd.stwCalc || vd.aisSpeedOverground, 1))} kn</div>
                                <div>Speed</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6 popup-group">
                            <div class="popup-img popup-draft-img"></div>
                            <div class="popup-label">
                                <div class="popup-label__value">${Utilities.renderNumber(Utilities.roundToDigits(vd.midDraft, 1))}</div>
                                <div>Mid Draft</div>
                            </div>
                        </div>
                        <div class="col-6 popup-group">
                            <div class="popup-img popup-trim-img"></div>
                            <div class="popup-label">
                                <div class="popup-label__value">${Utilities.renderNumber(Utilities.roundToDigits(vd.trim, 1))} m</div>
                                <div>Trim</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6 popup-group">
                            <div class="popup-img popup-wind-img"></div>
                            <div class="popup-label">
                                <div class="popup-label__value">${WindUtilities.getConvertedWindSpeed(vd.apparentWindSpeedCalc, vd.apparentWindSpeed, vd.trueWindSpeedCalc)} Bf (${WindUtilities.convertApparentWindAngle(vd.apparentWindDirectionCalc || vd.apparentWindAngle)})</div>
                                <div>Wind</div>
                            </div>
                        </div>
                        <div class="col-6 popup-group">
                            <div class="popup-img currents-img"></div>
                            <div class="popup-label">
                                <div class="popup-label__value">${Utilities.renderNumber(Utilities.roundToDigits(vd.trueCurrentSpeedCalc, 1))} kn (${WindUtilities.convertApparentWindAngle(vd.trueCurrentDirectionCalc)})</div>
                                <div>Currents</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`
        ;
    }
}

export default MarkersPopup;