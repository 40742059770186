import React from 'react';
import Skeleton from "@material-ui/lab/Skeleton";
import {licensing} from "../../common/store/licensing";
import NoData from "../noData/noData";
import DynamicWidgetTitle from "./dynamicWidgetTitle";
import classnames from 'classnames';
import WidgetWithTimePeriod from 'components/widgetWithTimePeriod/widgetWithTimePeriod';
import keys from 'lodash/keys';
import moment from 'moment';
import { dashboardUtils } from 'common/store/storeUtils';

const DynamicWidget = props => {

    const checkEmptyDataProps = () => {
        return ((props.component?.props?.data && Object.keys(props.component?.props?.data)?.length === 0 && !props.bypassReports)
            || (props.component?.props?.chartData?.series?.length === 1 && props.component?.props?.chartData?.series[0]?.data.length === 0))
    }

    const checkIfNoData = () => {
        if (isLeg()) {
            return ((isLeg() && hasFutureDate()) || checkEmptyDataProps())
        } else {
            return checkEmptyDataProps()
        }
    }

    const checkIfNoBoilersConfig = () => {
        return !!props.component?.props?.data?.noBoilersConfig;
    }

    const dynamicPanelBodyClasses = () => {
        return classnames({"flex-centered full-height-minus-widget-header": checkIfNoData()});
    }

    const hasFutureDate = () => {
        return moment(dashboardUtils.dashboardProperties.timespansObject[props.widgetId+props.id]?.timestampFrom).isAfter();
    }

    const isLeg = () => {
        return props?.timespan && props?.timespan[0] === 'LEG';
    }

    const getText = () => {
        return (isLeg() && hasFutureDate()) ? 'There is no active trip at the moment' : 'No data to display at the moment'
    }

    return (
        <React.Fragment>
            <div className="dynamic-widget-panel">
                {props.name !== "" &&
                <div className="dynamic-widget-panel__header flex-space-between">
                    {(props.loading[props.widgetId]?.loading && props.loading[props.widgetId]?.widget?.id === props.id)
                        ? <Skeleton animation="wave" width={'40%'} height={'60%'}/>
                        : <DynamicWidgetTitle {...props} />}
                </div>}
                <div
                    className={`${dynamicPanelBodyClasses()} dynamic-widget-panel__body ${props.bodyClass || ''} ${(licensing.lightCondition() && props.lightSvg) ? 'flex-centered' : ''}`}>
                    {props.loading[props.widgetId]?.loading && props.loading[props.widgetId]?.widget?.id === props.id
                        ? <div className="skeleton-absolute full-width full-height">
                            <Skeleton variant="rect"
                                      animation="wave"
                                      width={"100%"}
                                      height={150} />
                        </div>
                        : null}
                            {(licensing.lightCondition() && props.lightSvg) ? <div style={{
                                width: '100%',
                                height: (props.layout.h*dashboardUtils.dashboardProperties.rowHeight),
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundImage: `var(--${props.lightSvg})`
                            }}/>
                            : (licensing.lightCondition() && props.lightComp) ? <props.lightComp/>
                                : checkIfNoBoilersConfig() ? <div style={{width: '100%', height: (props.layout.h*dashboardUtils.dashboardProperties.rowHeight), backgroundRepeat:'no-repeat', backgroundPosition: 'center center', backgroundSize: '100% 100%', backgroundImage: `var(--${props.component.props.noBoilersConfigSvg})`}} />
                                : checkIfNoData()
                                    ? <NoData height={180} displayNoDataIcon={!isLeg()} text={getText()} />
                                : keys(props?.timePeriod).length > 0
                                    ? <WidgetWithTimePeriod {...props} timePeriodExtraStyle={props?.component?.props?.timePeriodExtraStyle} />
                                    : props.component
                    }

                    {
                        props?.component?.props?.footer && props?.component?.props?.footer !== "" && !checkIfNoData() && !checkIfNoBoilersConfig() &&
                        <div className="dynamic-widget-panel__footer">
                            {props.loading[props.widgetId]?.loading && props.loading[props.widgetId]?.widget?.id === props.id 
                            ? <Skeleton animation="wave" width={'40%'} height={'60%'}/> 
                            : props?.component?.props?.footer(props?.component?.props?.data, props?.component?.props?.widget)}
                        </div>
                    }
                </div>

            </div>
        </React.Fragment>
    );
}

export default DynamicWidget;
