const payloads = {
    sensor: {
        "withMetadata":false,
        "fromDate": null,
        "toDate": null,
        "reportType": "HIGHCHARTS_BASIC",
        "xAxis": "TIME",
        "timeGroup":"MINUTE",
        "aggregation":"AVG",
        "vessels": [1000],
        "metrics": [{
            "metricCategory": "MAIN_ENGINE",
            "subIds": [],
            "metricData": [
                {"metricName": "power"},
                {"metricName": "rpm"}
            ]
        }, {
            "metricCategory": "MAIN_ENGINE",
            "subIds": [],
            "metricData": [
                {"metricName": "foVolConsumption"},
                {"metricName": "foInPress"},
                {"metricName": "foInTemp"},
                {"metricName": 'cylExhGasOutTempNo1'},
                {"metricName": "cylJCFWOutTempNo1"},
                {"metricName": 'cylExhGasOutTempNo2'},
                {"metricName": "cylJCFWOutTempNo2"},
                {"metricName": 'cylExhGasOutTempNo3'},
                {"metricName": "cylJCFWOutTempNo3"},
                {"metricName": 'cylExhGasOutTempNo4'},
                {"metricName": "cylJCFWOutTempNo4"},
                {"metricName": 'cylExhGasOutTempNo5'},
                {"metricName": "cylJCFWOutTempNo5"},
                {"metricName": 'cylExhGasOutTempNo6'},
                {"metricName": "cylJCFWOutTempNo6"},
                {"metricName": 'cylExhGasOutTempNo7'},
                {"metricName": "cylJCFWOutTempNo7"},
                {"metricName": 'cylExhGasOutTempNo8'},
                {"metricName": "cylJCFWOutTempNo8"},
                {"metricName": 'cylExhGasOutTempNo9'},
                {"metricName": "cylJCFWOutTempNo9"},
                {"metricName": 'cylExhGasOutTempNo10'},
                {"metricName": "cylJCFWOutTempNo10"},
                {"metricName": 'cylExhGasOutTempNo11'},
                {"metricName": "cylJCFWOutTempNo11"},
                {"metricName": 'cylExhGasOutTempNo12'},
                {"metricName": "cylJCFWOutTempNo12"}
            ]
        }
        ]
    },

}


export default payloads