import {licensing} from "common/store/licensing";
import Wind from 'Utilities/wind';
import Utilities from "Utilities/global";
import overviewStorage from 'components/monitoringCategories/localStorages/overviewStorage';
import { vesselUtils } from "common/store/storeUtils";
import moment from 'moment';

const vesselMonitoringChartPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    withNavigationStatus: true,
    withTelegramNavigationStatus: true,
    metrics: [{
        metricCategory: "MAIN_ENGINE",
        subIds: [1],
        metricData: [
            {metricName: "foVolConsumption"},
            {metricName: "rpm"}
        ]
    }, {
        metricCategory: "VESSEL",
        subIds: [1],
        metricData: [
            {metricName: "speed_overground"},
            {metricName: "apparent_wind_speed_calc"},
            {metricName: "apparent_wind_speed" },
            {metricName: "true_wind_speed_calc" },
            {metricName: "apparent_wind_direction_calc"},
            {metricName: "apparent_wind_angle"},
            {metricName: "true_current_speed_calc"},
            {metricName: "true_current_direction_calc"},
        ]
    }]
}

const vesselMonitoringChartPayloadLight = {
    xAxis: "TIME",
    aggregation: "AVG",
    withTelegramNavigationStatus: true,
    withNavigationStatus: true,
    metrics: [{
        metricCategory: "VESSEL",
        subIds: [1],
        metricData: [
            {metricName: "ais_speed_overground"},
            {metricName: "apparent_wind_speed_calc"},
            {metricName: "true_wind_speed_calc" },
            {metricName: "apparent_wind_direction_calc"},
            {metricName: "true_current_speed_calc"},
            {metricName: "true_current_direction_calc"},
        ]
    }, {
        metricCategory: "TELEGRAM",
        subIds: [1],
        metricData: [{
            metricName: "me_1_foConsumption_calc"
        }, {
            metricName: "me_1_rpm_avg"
        }
        ]
    }]
}

const navigationStatusSeries = {
    name: '',
    data: {},
    lineWidth: 4,
    pointPlacement: 5000000000,
    type: 'line',
    enableMouseTracking: false,
    yAxis: 'navigationStatus-axis',
    color: '#CAA3FF',
    states: {
        inactive: {
            opacity: 1
        }
    }
}

const supportedNavigationStatuses = ["DEPARTURE", "ARRIVAL"];

const vesselMonitoringChartUpdate = (id, response, updateState, extraChartConfigs, widget) => {
    const series = [{data: []}, {data: []}, {data: []}, {data: []}, {data: []}, {data: []}], plotLinesArray = [],
        navigationStatusLine = [];
    const helpingMetrics = [{data: []}, {data: []}];
    let data = {};

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    overviewStorage.setVesselMonitoringChartData(response);

    if (response[0] && response[0].values) {
        Wind.findWindSpeedWithPriority(response, ['apparent_wind_speed_calc', 'apparent_wind_speed', 'true_wind_speed_calc'], series, 2);

        response[0].values.forEach(keyGp => {
            if (keyGp.metricName === 'true_current_speed_calc') {
                series[0].data = keyGp.value;
            }
            if (!isLight) {
                if (keyGp.metricName === 'speed_overground') series[1].data = keyGp.value;
            } else {
                if (keyGp.metricName === 'ais_speed_overground') series[1].data = keyGp.value;
            }
            if (!isLight) {
                if (keyGp.metricName === 'rpm') series[4].data = keyGp.value;
            } else {
                if (keyGp.metricName === 'me_1_rpm_avg') {
                    keyGp.value.forEach(value => {
                        value.y = parseFloat(value?.y)
                    })
                    series[4].data = keyGp.value;
                }
            }
            if (!isLight) {
                if (keyGp.metricName === 'foVolConsumption') series[5].data = keyGp.value; series[5].unit = ' kg/min';
            } else {
                if (keyGp.metricName === 'me_1_foConsumption_calc') {
                    keyGp.value.forEach(value => {
                        value.y = parseFloat(value?.y)
                    })
                    series[5].data = keyGp.value;
                    series[5].unit = ' mt';
                }
            }
            if (keyGp.metricName === 'apparent_wind_angle') helpingMetrics[0].data = keyGp.value;
            if (keyGp.metricName === 'apparent_wind_direction_calc' && (keyGp.value || keyGp.value === 0)) helpingMetrics[0].data = keyGp.value;
            if (keyGp.metricName === 'true_current_direction_calc') helpingMetrics[1].data = keyGp.value;

        });
    }

    if (series[0].data) series[0].data = series[0].data.map((arr, index) => {
        const direction = helpingMetrics[1]?.data[index]?.y;
        return {
            x: arr.x,
            y: arr.y,
            z: Wind.convertApparentWindAngle(direction) === '-' ? '' : ` (${Wind.convertApparentWindAngle(direction)}) `
        };
    });

    if (series[2].data) series[2].data = series[2].data.map((arr, index) => {
        const direction = helpingMetrics[0]?.data?.length > 0 && helpingMetrics[0]?.data[index]?.y;
        return {
            x: arr.x,
            y: Wind.knotsToBeauforts(arr.y) === '-' ? null : Wind.knotsToBeauforts(arr.y),
            z: Wind.convertApparentWindAngle(direction) === '-' ? '' : ` (${Wind.convertApparentWindAngle(direction)}) `
        };
    });

    let navigationStatusLabels = [];
    let supportedStatusPlotLines = [];

    if(response[0]?.navigationStatus) {
        navigationStatusLabels = response[0]?.navigationStatus[0]?.statuses?.filter(status => !supportedNavigationStatuses.includes(status.status));
        supportedStatusPlotLines = response[0]?.navigationStatus[0]?.statuses?.filter(status => supportedNavigationStatuses.includes(status.status));
    }

    navigationStatusLabels?.length > 0 && navigationStatusLabels.forEach((status, index) => {

        let serieWithData = [];
        response[0].values.forEach(obj => {if(obj.value.length) serieWithData = obj.value});

        /** Get navigation status datetime, and connect each one with the next one
         For the last status, connect it with the last datetime of the main response body */
        const median = moment((navigationStatusLabels[index].datetime +
            (navigationStatusLabels[index + 1] ? navigationStatusLabels[index + 1].datetime : serieWithData[serieWithData.length-1].x)) / 2).valueOf();

        navigationStatusLabels[index] && !supportedNavigationStatuses.includes(status.status) && navigationStatusLine.push({
            ...navigationStatusSeries,
            color: Utilities.getNavigationStatus(status.status).color,
            data: [{
                "x": navigationStatusLabels[index].datetime, "y": 1
            }, {
                "x": navigationStatusLabels[index + 1] ?
                    moment(navigationStatusLabels[index + 1].datetime).subtract(30, 'seconds').valueOf() :
                    serieWithData[serieWithData.length-1].x,
                "y": 1
            }]
        })

        plotLinesArray.push({
            value: median,
            width: 0,
            color: Utilities.getNavigationStatus(status.status).color,
            label: {
                text: `<div class="daily-reports-status-label plotlines-label" style="background-color:${Utilities.getNavigationStatus(status.status).color}">${Utilities.getNavigationStatus(status.status).name}</div>`,
                rotation: 0,
                y: -50, x: -3,
                useHTML: true,
                align: 'center',
                style: {
                    fontSize: '12px'
                }
            }
        })
    })

    supportedStatusPlotLines?.length > 0 && supportedStatusPlotLines.forEach(status => {
        plotLinesArray.push({
            value: status.datetime,
            dashStyle: 'LongDash',
            width: 2,
            color: Utilities.getNavigationStatus(status.status).color,
            label: {
                style: {
                    display: 'none'
                }
            }
        })
    })

    // check if series has empty data and assign empty object if it has
    if (!(series.every((obj) => obj?.data?.length === 0))) {
        data = {
            data: {
                chart: {id: id},
                marginTop: 35,
                height: 810,
                yAxis: [
                    {
                        id: 'navigationStatus-axis',
                        title: {text: ''},
                        min: 0,
                        height: '38',
                        top: '2%',
                        gridLineColor: 'transparent',
                        labels: {enabled: false}
                    },
                    {
                        id: 'fc-axis',
                        title: {text: `Fuel Consumption (${series[5]?.unit})`, style: {fontSize: "10px"}},
                        min: 0,
                        height: '80px',
                        top: '88px',
                        allowDecimals: false,
                        lineWidth: 0,
                        offset: 2,
                        opposite: false
                    },
                    {
                        id: 'rpm-axis',
                        title: {text: 'RPM', style: {fontSize: "10px"}},
                        min: 0,
                        height: '80px',
                        top: '208px',
                        allowDecimals: false,
                        lineWidth: 0,
                        offset: 2,
                        opposite: false
                    },
                    {
                        id: 'sog-axis',
                        title: {text: 'Speed Over Ground (Kn)', style: {fontSize: "10px"}},
                        min: 0,
                        height: '80px',
                        top: '328px',
                        allowDecimals: false,
                        lineWidth: 0,
                        offset: 2,
                        opposite: false
                    },
                    {
                        id: 'wind-axis',
                        title: {text: 'Wind (Bf)', style: {fontSize: "10px"}},
                        min: 0,
                        height: '80px',
                        top: '448px',
                        allowDecimals: false,
                        lineWidth: 0,
                        offset: 2,
                        opposite: false
                    },
                    {
                        id: 'current-axis',
                        title: {text: 'Currents (Kn)', style: {fontSize: "10px"}},
                        min: 0,
                        height: '80px',
                        top: '568px',
                        allowDecimals: false,
                        lineWidth: 0,
                        offset: 2,
                        opposite: false
                    }
                ],
                series: [
                    {
                        name: 'Fuel Consumption',
                        data: series[5].data,
                        tooltipTitle: 'FC',
                        type: 'line',
                        titleSuffix: '',
                        yMin: 0,
                        yAxis: 'fc-axis',
                        tooltip: {valueSuffix: series[5].unit, valueDecimals: 1},
                    },
                    {
                        name: 'RPM',
                        data: series[4].data,
                        tooltipTitle: 'RPM',
                        type: 'line',
                        titleSuffix: '',
                        yMin: 0,
                        yAxis: 'rpm-axis',
                        tooltip: {valueSuffix: ``, valueDecimals: 1},
                    },
                    {
                        name: 'SOG',
                        data: series[1].data,
                        tooltipTitle: 'SOG',
                        type: 'line',
                        titleSuffix: '(UTC)',
                        yMin: 0,
                        yAxis: 'sog-axis',
                        tooltip: {valueSuffix: ` Kn`, valueDecimals: 1},
                    },
                    {
                        name: 'Wind',
                        data: series[2].data,
                        tooltipTitle: 'Wind',
                        type: 'line',
                        titleSuffix: '(UTC)',
                        yMin: 0,
                        yAxis: 'wind-axis',
                        tooltip: {valueSuffix: ` Bf`, valueDecimals: 0},
                    },
                    {
                        name: 'Currents',
                        data: series[0].data,
                        tooltipTitle: 'Currents',
                        type: 'line',
                        titleSuffix: '(UTC)',
                        yMin: 0,
                        yAxis: 'current-axis',
                        tooltip: {valueSuffix: ` Kn`, valueDecimals: 1},
                    },
                    ...navigationStatusLine,
                ],
                navigationStatus: response[0] && response[0].navigationStatus,
                title: {
                    text: 'Navigational Status',
                    align: 'center',
                    y: 5,
                    style: {
                        color: '#687685',
                        fontSize: '11px'
                    }
                },
                plotLines: plotLinesArray
            }
        }
    }

    updateState(id, data, widget?.id);
}

export {vesselMonitoringChartUpdate, vesselMonitoringChartPayload, vesselMonitoringChartPayloadLight}
