import React from 'react';
import RadialChart from 'components/charts/radial/radialChart';

const LegFuelConsumption = (props) => { 
    return (
        <div className="leg-fcr" style={{height: props.style.height}}>
            <div className="container-fluid full-height ">
                <div className="row no-gutters full-height ">
                    <div className="col-12 full-height d-flex flex-space-around radials-container col-sm-12 col-xs-12 col-md-12 col-lg-12">
                        <RadialChart className="marg-r-5" options={props?.chartData?.radials?.me} widget={props?.widget}/>
                        <RadialChart options={props.chartData?.radials?.ge} widget={props?.widget}/>
                        <RadialChart options={props.chartData?.radials?.bo} widget={props?.widget}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default LegFuelConsumption;
