import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as DropdownTriangle} from 'assets/img/app/global-imgs/triangle.svg';

const DropDown = props => {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState([]);
    
    // refs
    const element = useRef();
    // a ref is used also here to track the value of the 'isOpen' state variable, so that we read the updated value inside the 'clickOutsideList' listener
    const openRef = useRef(isOpen);
    
    useEffect(() => {
        openRef.current = isOpen;
    }, [isOpen]);

    const toggleMenu = () => {
        if(!openRef.current) props.onOpen();
        setIsOpen(!openRef.current);
    };

    const clearSelectedCategory = () => {
        if(openRef.current) toggleMenu();
    };

    const clickOutsideList = (event) => {
        const domNodeCollection = element?.current ? [...element.current.getElementsByClassName('dropdown')] : [];
        let elementClicked = false;

        for (const domNode of domNodeCollection) {
            domNode.contains(event.target) && (elementClicked = true);
        }
        !elementClicked && clearSelectedCategory();
    };

    const createOptions = option => {
        return (
            <div className={`dropdown-my-element-wrapper ${option.classNameWrapper || ''}`} key={`${props.buttonLabel}-${option.label}`}>
                <div className={`dropdown-my-element ${option.className || ''}`} id={option.id || ''}
                        onClick={e => {
                            option.onClick();
                            toggleMenu();
                        }}>
                    {option.image && <option.image className="marg-r-10" />}
                    {option.label}
                </div>
            </div>
        )
    }

    useEffect(() => {
        document.body.addEventListener("click", clickOutsideList);
        // component unmount callback
        return () => document.body.removeEventListener("click", clickOutsideList);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(props.options.length) setOptions(props.options.map(option => createOptions(option)));
        else setOptions(props.render({closeDropDown: clearSelectedCategory}));
    // eslint-disable-next-line
    }, [props.options, props.render]);


    return (
        <div ref={element}>
            <div className={`dropdown ${props.classNames.wrapper || ''}`}>
                <button className={`btn ${props.classNames.button || ''}`}
                type="button" onClick={toggleMenu}>
                    <span>{props.buttonLabel}</span>
                    {props.showCaret &&
                        <DropdownTriangle className={`marg-l-5 transform-04 ${isOpen ? ' rotate-right' : ''}`} />
                    }
                </button>
                {isOpen &&
                <div className={`dropdown-my-menu ${props.classNames.menu || ''}`}>
                    {options}
                </div>
                }
            </div>
        </div>
    );
};

DropDown.propTypes = {
    buttonLabel: PropTypes.node.isRequired,
    options: PropTypes.array,
    render: PropTypes.func,
    classNames: PropTypes.object,
    onOpen: PropTypes.func,
    showCaret: PropTypes.bool,
    hasArrow: PropTypes.bool,
    initialStyle: PropTypes.object,
};

DropDown.defaultProps = {
    options: [],
    render: ({closeDropDown}) => {},
    classNames: {wrapper: '', button: '', menu: ''},
    onOpen: () => {},
    showCaret: true,
    hasArrow: false,
    initialStyle: {},
};

export default DropDown;