import React from 'react';
import { licensing } from 'common/store/licensing';
import {ReactComponent as MidDraft} from 'assets/img/app/global-imgs/reports_mid_draft.svg';
import {ReactComponent as Ballast} from 'assets/img/app/global-imgs/ballast-small.svg';
import {ReactComponent as Laden} from 'assets/img/app/global-imgs/laden-small.svg';
import DoubleData from '../../reports/doubleData';
import ThemeColors from "sass/_themeColorsForJs.scss";
import {reportsStore} from 'common/store/storeUtils';

let _loading = {};

const loadConditionPayload = {
    metrics: [{
        metricCategory: "TELEGRAM",
        subIds: [1],
        metricData: [ 
            {metricName: "mid_draft", aggregation: "AVG"}
        ]
    }, {
        metricCategory: "VESSEL",
        subIds: [1],
        metricData: [
            {metricName: "mid_draft", aggregation: "AVG"},
        ]
    }]
};

const loadConditionDataPayload = {
    metrics: [{
        metricCategory: "TELEGRAM",
        subIds: [1],
        metricData: [ 
            {metricName: "ballast_flag", aggregation: "NONE"}
        ]
    }]
};

const loadConditionLightPayload = {
    metrics: [{
        metricCategory: "TELEGRAM",
        subIds: [1],
        metricData: [ 
            {metricName: "mid_draft", aggregation: "AVG"}
        ]
    }, {
        metricCategory: "VESSEL",
        subIds: [1],
        metricData: [
            {metricName: "ais_mid_draft", aggregation: "AVG"}
        ]
    }]
}

const loadUpdate = (response) => {
    if(!response.length) return _loading = {};
    _loading[response[0]?.vesselId] = response[0]?.ballastFlag || null;
}

const loadDataUpdate = (response, widget) => {
    response = response.series;

    if (response?.length > 0 && response[0]?.values?.length) {
        response[0].values.forEach(obj => {
            if( obj.metricName === 'ballast_flag' && obj.metricCategory === 'TELEGRAM') 
                _loading[response[0]?.vessel] = obj.value;
        });
    } 
}

const getConditionLabel = vessel => {
    if(_loading[vessel] === 'B') return 'Ballast';
    if(_loading[vessel] === 'HB') return 'Heavy Ballast';
    if(_loading[vessel] === 'L') return 'Laden';
    if(_loading[vessel] === 'PL') return 'Partial Laden';
    return '-';
}

const loadCondition = (id, response, updateState, extraChartConfigs, widget) => {
    response = response.series;
    if(!response) return
    
    const vessel = response[0]?.vessel;

    if((response?.length === 0) || (response && response[0]?.values.every((value) => value.value === null))) return updateState(id, {}, widget?.id);

    let data = {};
    let sensorMidDraft = null;
    let reporstMidDraft = null;
    let aisMidDraft = null;

    //if it is a leg from db with no search then we fill them from response
    if (response?.length > 0 && response[0]?.values?.length) {
        response[0].values.forEach(obj => {
            if( obj.metricName === 'mid_draft' && obj.metricCategory === 'VESSEL') sensorMidDraft = obj.value || null;
            if( obj.metricName === 'mid_draft' && obj.metricCategory === 'TELEGRAM') reporstMidDraft = obj.value || null;
            if( obj.metricName === 'ais_mid_draft' && obj.metricCategory === 'VESSEL') aisMidDraft = obj.value || null;
        });
    } 

    data = {
        dataGroups: [
            (Object.keys(_loading).length && _loading?.[vessel]) &&
            { 
                label: 'Condition',
                value:  getConditionLabel(vessel),
                icon: (_loading[vessel] === 'B' || _loading[vessel] === 'HB') ? Ballast : Laden,
                style: {color: ThemeColors['reports_color']}
            },
            {
                label: 'Mid Draft', 
                value: <DoubleData
                            firstPart={ {value: licensing.lightCondition() ? (aisMidDraft || '-') : (sensorMidDraft || '-'), unit: 'm', classNames: 'info-value', extraStyle: {color: licensing.lightCondition() ? 'var(--ais_color)' : 'var(--main_blue)'}} }
                            secondPart={ reporstMidDraft ? {value: (reporstMidDraft || '-'), unit: 'm', classNames: 'info-value', extraStyle: {color: 'var(--reports_color)'}} : null }
                            enableDevider={ true }
                            deviderExtraStyle={ {height: '18px'} }
                        />,
                icon: MidDraft},
        ],
        enableDevider: false,
        setUserLeg: reportsStore().setUserLeg
    }

    updateState(id, data, widget?.id);
}


export {loadConditionPayload, loadConditionLightPayload, loadConditionDataPayload, loadCondition, loadUpdate, loadDataUpdate};