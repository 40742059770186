import {licensing} from "../common/store/licensing";
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import chartUtils from 'Utilities/charts';
import Highcharts from 'highcharts';
import moment from 'moment';

const chartsUtilities = {
    tootlipBorderColor: {
      mixedData: () => {
        return ThemeColorsForCharts()['primary_text'];
      },
      mixedDataWithReports: () => {
        if(!licensing.lightCondition()) return ThemeColorsForCharts()['primary_text'];
        return ThemeColorsForCharts()['reports_color'];
      },
      premiumLight: () => {
        if(licensing.lightCondition()) return ThemeColorsForCharts()['reports_color'];
        return ThemeColorsForCharts()['main_blue'];
      },
    },
    syncChartsSeries: {
      routeSyncChartsSeries: [
        { name: 'Deviation (%)', data: [], tooltipTitle: 'Fuel Overconsumption', type: 'column', yAxis: 'deviation-axis', yMax: 100, yMin: -100,
         titleSuffix: '(UTC)', tooltip: { valueSuffix: ' %', valueDecimals:  2} },
        { name: 'Power (kW)', data: [], tooltipTitle: 'Power', type: 'line', yAxis: 'power-axis', yMax: 500, yMin: 0,
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' kW', valueDecimals: 2 } },
        { name: 'RPM', data: [], tooltipTitle: 'RPM', type: 'line', yAxis: 'rpm-axis', yMax: 500, yMin: 0,
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' rpm', valueDecimals: 2 } },
        { name: 'FCR (kg/min)', data: [], tooltipTitle: 'FC', type: 'line', yAxis: 'fcr-axis', yMax: 500, yMin: 0,
          titleSuffix: 'UTC', tooltip: { valueSuffix: ' kg/min', valueDecimals: 2 } },
        { name: 'FO Inlet Pressure', data: [], tooltipTitle: 'FO Inlet P', type: 'line', yAxis: 'foInlet-axis',
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
        { name: 'FO Temp', data: [], tooltipTitle: 'FO Temp', type: 'line', yAxis: 'fo-axis', yMax: 500, yMin: 0,
          titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
      ],

      pistonsSyncChartSeries: [
        { name: 'EXHAUST GAS OUTLET TEMP', data: [], tooltipTitle: 'Exh. Gas Out Temp', type: 'line', yAxis: 'temp-axis', 
        yMax: 500, yMin:-500, color: ThemeColorsForCharts()['fine_status'], titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 1 } },
        { name: 'JACKET COOLING FRESH WATER OUTLET TEMP', data: [], tooltipTitle: 'JCWF Temp', type: 'line', yAxis: 'cool-axis', 
        yMax: 500, yMin:-500, color: ThemeColorsForCharts()['cerulean'], titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 1 } }
      ],
      fuelConsumpSyncChartSeries: () => [
        { name: 'FUEL CONSUMPTION', data: [], tooltipTitle: 'Fuel Consumption', type: 'column', yAxis: 'vesselfcr-axis', yMax: 500, yMin: -500,
         titleSuffix: '(UTC)', tooltip: { valueSuffix: ' mt'}, color: licensing.lightCondition() ? ThemeColorsForCharts()['reports_color'] : ThemeColorsForCharts()['main_blue'] },
        { name: 'CO2 EMISSIONS', data: [], tooltipTitle: 'CO2 Emissions', type: 'column', yAxis: 'co2-axis', yMax: 500, yMin: -500,
         titleSuffix: '(UTC)', tooltip: { valueSuffix: ' mt'}, color: licensing.lightCondition() ? ThemeColorsForCharts()['reports_color'] : ThemeColorsForCharts()['main_blue'] },
      ]
    },

    syncChartsAxes: {
      routeSyncChartsAxis: [
        { id: 'deviation-axis', title: { text: 'Deviation (%)'}, height: '90px', top: '40px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
        { id: 'power-axis', title: { text: 'Power (kW)'}, height: '90px', top: '180px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, tickInterval: 2000 },
        { id: 'rpm-axis', title: { text: 'RPM'}, height: '90px', top: '320px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
        { id: 'fcr-axis', title: { text: 'FCR (kg/min)'}, height: '90px', top: '460px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
        { id: 'foInlet-axis', title: { text: 'FO Inlet Pressure (bar)'}, height: '90px', top: '600px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
        { id: 'fo-axis', title: { text: 'FO Temp (°C)'}, height: '90px', top: '740px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false }
      ],
      pistonsSyncChartAxis: [
        { id: 'temp-axis', title: { text: 'TEMPERATURE (°C)'}, height: '120', top: '0%', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
        { id: 'cool-axis', title: { text: 'TEMPERATURE (°C)'}, height: '120', top: '50%', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
      ],
      fuelConsumpSyncChartAxis: [
        { id: 'vesselfcr-axis',title: { text: 'Fuel Consumption (mt)', style: {fontSize: "10px"}}, height: '100px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
        { id: 'co2-axis', title: { text: 'CO2 Emissions (mt)', style: {fontSize: "10px"}}, height: '100px', top: '200px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
      ]
    },
    curvesAndScatterDataChart: {
      tooltipFormatter: function(tooltipExtraOptions) {
          // get the points array depending on if the user hovers on scatter data or on curves data (in case of scatter data, we have only one point)
          const points = this?.points?.length ? this?.points : [this];
  
          // create the ui of the tooltips content
          const tooltip = points?.length ? `<div class="highcharts-fonts" id="{obj?.series?.name}" style="width: 100%;">
              <div class="flex-centered-col">
                  ${!this?.points?.length ? `<div class="small-label">
                      ${moment(points[0]?.point?.datetime)?.utc()?.format('DD/MM/YYYY HH:mm')} (UTC)
                  </div>` : ''}
                  <div class="marg-t-5">
                      <span class="autocomplete-title">
                          ${tooltipExtraOptions?.titleX}
                      </span>
                      <span class="small-label">
                          ${points[0]?.point?.x?.toFixed(2)} ${tooltipExtraOptions?.valueXSuffix}
                      </span>
                  </div>
              </div>
              ${
                  points.map((pointObj, index) => `
                      <div class=${index === 0 ? 'marg-t-10' : 'marg-t-5'}>
                          <span class="tooltip-circle" style="background-color:${pointObj?.color};"></span>
                          <span style="display: inline-block; width: ${tooltipExtraOptions?.width ? tooltipExtraOptions.width : '70px'};">
                              ${pointObj?.series?.userOptions?.tooltipTitle}: 
                          </span>
                          <span class="small-label">
                              ${pointObj?.point?.y ? pointObj.point.y.toFixed(pointObj?.series?.tooltipOptions?.valueDecimals) : ''} ${pointObj?.series?.tooltipOptions?.valueSuffix ? pointObj.series.tooltipOptions.valueSuffix : ''}
                          </span>
                      </div>
                  `)?.join('')
              }
          </div>` : '';
  
          return tooltip;
      },
      setCommonChartOptions: (chartData) => {
        // some common options for chart, xaxis and tooltip
        chartData.chart.marginLeft = 70;
        chartData.chart.marginRight = 40;
        chartData.xAxis.type = "number";
        chartData.xAxis.tickPositioner = undefined;
        chartData.xAxis.labels.formatter = undefined;
        chartData.plotOptions.series.connectNulls = true;
        chartData.tooltip.borderColor = chartUtils.tootlipBorderColor.mixedData();

        // show only the 'xlsx' and 'csv' exporting options
        chartData.exporting.buttons.contextButton.menuItems = ["download", "customSeparator", "downloadXLSX", "csv"];
        chartData.exporting.buttons.contextButton.x = -30;
        chartData.exporting.buttons.contextButton.y = 30;
        chartData.navigation.menuStyle.height = 150;
      },
      setExportingColumns : function (item, columnHeaderMapper, xAxisColumnName) {
        if (!item || item instanceof Highcharts.Axis) return xAxisColumnName;

        return {
            topLevelColumnTitle: columnHeaderMapper[item?.name],
            columnTitle: columnHeaderMapper[item?.name]
        };
      }
  }
}

export default chartsUtilities;
