import React from 'react';

const NameInitials = props => {
    return (
        <div className="customTemplateInitialsIcon">
            <div className="customTemplateInitialsIcon-text">
                {props.name}
            </div>
        </div>
    )
}
 
export default NameInitials;
