import React, {useState, useEffect} from 'react';
import {licensing} from "common/store/licensing";
import radialOptions from 'components/charts/radial/radialOptions';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { vesselUtils } from 'common/store/storeUtils';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const RadialChart = (props) => {
    const [options, setOptions] = useState(radialOptions());

    useEffect(() => {
        if(!props.options) return;
        const isLight = props.widget ? licensing.lightCondition(null, vesselUtils.getObjOfAVessel(props?.widget?.vesselIds[0])) : licensing.lightCondition();
        let temp = radialOptions(); 
        temp.series = props.options.series;
        temp.title = props.options.title;
        if(isLight) temp.xAxis.categories = [ 'Reports ' ];
        setOptions(temp);
        // eslint-disable-next-line
    }, [props.options]);

    return (
        <HighchartsReact options={options} highcharts={Highcharts} />
    );
}
 
export default RadialChart;