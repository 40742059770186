import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import { ReactComponent as Locker } from 'assets/img/app/light-imgs/Small_Lock.svg';
import {licensing} from "common/store/licensing";
import { useSelector } from 'react-redux';

const NavigationTabs = (props) => {
    const [lightVersion, setLightVersion] = useState(licensing.lightCondition());

    // store vars
    const monitoringStoreJSX = useSelector(state => state.monitoring);
    
    const getWrapperClasses = () => {
        return classnames({
            [props?.classes?.mainClass]: props?.classes?.mainClass, 
            [props?.classes?.extraClasses]: props?.classes?.extraClasses, 
            'marg-t-15': !props.noSpace,
            'small': props.noSpace
        });
    }

    const getTabClasses = tab => {
        return classnames(
            'd-flex align-items-center', 
            { 
                'active': props.currentTab === tab.name, 
                [`${props?.classes?.mainClass}__group`]: props?.classes?.mainClass 
            }
        );
    }

    const getTitleClasses = () => {
        return classnames({[`${props?.classes?.mainClass}__group__title`]: props?.classes?.mainClass}, 'headline3');
    }

    useEffect(() => {
        setLightVersion(licensing.lightCondition());
    }, [monitoringStoreJSX.fromToChanged]);

    const tabClickHandler = tab => {
        if(tab.disabled) return;
        props.updateCurrentTab(tab.name);
    }

    return (
        <div className={getWrapperClasses()}>
            {props.tabs.map(tab => (
                // we want to show each tab if it has the 'visible' prop === true, or if it does not have it at all (undefined)
                ((typeof tab.visible === 'boolean' && tab.visible) || (typeof tab.visible !== 'boolean'))
                    && <div key={tab.name} className={getTabClasses(tab)} onClick={() => tabClickHandler(tab)} id={`${tab.name}Tab`}>
                            <div className={getTitleClasses()}>
                                { tab.disabled 
                                    ? <Tooltip 
                                        title="Not available data"
                                        component={
                                            <div className="disabled">{tab.title}</div>
                                        } />

                                    : <span>{tab.title}</span>
                                }
                                { (props.tabsWithLocker && lightVersion && !props.activeTabsInLightView.includes(tab.name)) && 
                                    <Locker className="monitoring-tabs-locker-icon" />
                                }
                            </div>
                        </div>
            ))}
        </div>
    );
}

export default NavigationTabs;
