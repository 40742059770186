import React, { useEffect, useState } from 'react';
import service from 'common/js/service';
import Tooltip from "components/muiHTMLTooltip/muiTooltip";
import { ReactComponent as InformationIcon } from "assets/img/app/global-imgs/informationIcon.svg";
import { vesselStore } from 'common/store/storeUtils';
import Utilities from 'Utilities/global';


const HullInfo = () => {
    const [hullInfo, setHullInfo] = useState([]);

    const hullInfoNamesMapper = [
        {key: 'lengthOverall', title: 'Length Overall', value: ''},
        {key: 'lengthPerpendiculars', title: 'Length BP', value: ''},
        {key: 'breadth', title: 'Breadth (Mid)', value: ''},
        {key: 'depth', title: 'Depth (Mid)', value: ''},
        {key: 'td', title: 'Design Draft', value: ''},
        {key: 'ts', title: 'Scantling Draft', value: ''},
        {key: 'keelToMast', title: 'Keel to Mast', value: ''},
    ];

    useEffect(() => {
        service.getHullData({vesselId: vesselStore().selectedVessel}).then(response => {
            if(!response) return;
            setHullInfo(hullInfoNamesMapper.map(info => {
                return{
                    ...info,
                    value: response[info.key],
                }
            }));
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div className="d-flex">
            <div className='form-text marg-r-5'>Hull</div>
            <Tooltip
                component={<InformationIcon className="marg-t-4 svg-path pointer" />}
                height="auto"
                title={
                    <div className="hull-info-tooltip flex-centered flex-column">
                        <div className='form-text marg-t-5'>HULL INFO</div>
                        <div className='hull-info-body full-width'>
                            { hullInfo.map(info => (
                                <div className='hull-info-body__row full-width d-flex marg-t-5' key={info.key}>
                                    <div className='hull-info-body__row__key'>{info.title}:</div>
                                    <div className='hull-info-body__row__value'>{Utilities.renderNumber(Utilities.roundToDigits(info.value, 2))} m</div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default HullInfo;