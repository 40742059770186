import React from 'react';
import moment from 'moment';
import Utilities from 'Utilities/global';
import {ReactComponent as AlertWasteIcon} from 'assets/img/app/alerts-imgs/alertWaste.svg';
import {ReactComponent as OilTank} from 'assets/img/app/events-imgs/bunkering-with-oil.svg';

const AlertWaste = props => {
    return (
        <div className="alert-waste d-flex justify-content-center align-items-center flex-column" style={props.style}>
            { props.activeAlert.id && <><div className="alert-waste__body d-flex justify-content-around align-items-center full-width flex-column">
                <div className="d-flex justify-content-around full-width align-items-center">
                    <OilTank className="svg-path" />
                    <div className="alert-tag" style={{
                            fontSize: '28px', 
                            color: `var(--${props.activeAlert.severity.toLowerCase()}_severity)`
                        }}>
                        {Utilities.roundToDigits(props.excessFuel, 2) || '-'}mt
                        <div className="form-text">EXCESS FUEL</div>
                    </div>
                </div>
                <div className="d-flex justify-content-around full-width align-items-center">
                    <AlertWasteIcon className="svg-path" />
                    <div className="alert-tag" style={{fontSize: '28px', color: `var(--${props.activeAlert.severity.toLowerCase()}_severity`}}>
                        ${Utilities.roundToDigits(props.fuelWaste, 2) || '-'}
                        <div className="form-text">FUEL WASTE</div>
                    </div>
                </div>
            </div>
            <div className="alert-waste__footer full-width d-flex justify-content-start align-items-center small-label">
                { props.fuelWaste ?    
                    `Calculated with the average price of IFO380, VLSFO and MGO at ${moment(props.fuelPricesDatetime).isValid() ? 
                    moment(props.fuelPricesDatetime).utc().format('DD/MM/YYYY')
                    : '-' }`
                : 'There is no available fuel price for this date' }
            </div>
            </>}
        </div>
    );
}
 
export default AlertWaste;