import moment from "moment";
import { baseFormUtils } from 'common/store/storeUtils';

const damagesSchema = (editMode) => {
    return [
        {
            damages: {
                type: 'array',
                label: {
                    title: '',
                    index: 'Damage',
                    add: 'Add Damage',
                },
                viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                structure: {
                    location: {
                        type: "segmentButtons",
                        label: "",
                        canRenderLabel: false,
                        options: [
                            {value: 'me', label: 'Main Engine'},
                            {value: 'ge', label: 'Generator Engine'}
                        ],
                        onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                            if (value !== '') {
                                setFieldValue(`${parentName}.system`, '');
                                setFieldValue(`${parentName}.part`, '');
                                setFieldValue(`${parentName}.cause`, []);
                                setFieldValue(`${parentName}.otherCause`, '');
                                setFieldTouched(`${parentName}.system`, false);
                                setFieldTouched(`${parentName}.part`, false);
                                setFieldTouched(`${parentName}.cause`, false);
                                setFieldTouched(`${parentName}.otherCause`, false);
                            }
                        },
                        value: '',
                        validation: {required: true}
                    },
                    system: {
                        type: "select",
                        label: "System",
                        options: [
                            {
                                location: ['me'],
                                options: [
                                    {value: 'shaft', label: 'Shaft System'}
                                ]
                            },
                            {
                                location: ['ge'],
                                options: [
                                    {value: 'generator', label: 'Generator System'}
                                ]
                            },
                            {value: 'cooling', label: 'Cooling water System'},
                            {value: 'air', label: 'Control Air System'},
                            {value: 'lubrication', label: 'Lubrication System'},
                            {value: 'oil', label: 'Fuel Oil System'},
                            {value: 'pistons', label: 'Pistons'},
                            {value: 'tc', label: 'Turbo Chargers'}
                        ],
                        onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                            if (value !== '') {
                                setFieldValue(`${parentName}.part`, '');
                                setFieldValue(`${parentName}.cause`, []);
                                setFieldValue(`${parentName}.otherCause`, '');
                                setFieldTouched(`${parentName}.part`, false);
                                setFieldTouched(`${parentName}.cause`, false);
                                setFieldTouched(`${parentName}.otherCause`, false);
                            }
                        },
                        value: '',
                        validation: {
                            required: true,
                            disabled: {
                                location: []
                            }
                        }
                    },
                    part: {
                        type: "select",
                        label: "Part",
                        options: [
                            {
                                system: ['shaft'],
                                options: [
                                    {value: 'thrustBearing', label: 'Thrust Bearing'},
                                    {value: 'torquemeter', label: 'Torquemeter'},
                                    {value: 'crankShaftBearing', label: 'Crank shaft Bearing'},
                                    {value: 'camshaft', label: 'Camshaft'}
                                ]
                            },
                            {
                                system: ['cooling'],
                                options: [
                                    {value: 'pumps', label: 'Pumps'},
                                    {value: 'generator', label: 'Fresh Generator'},
                                    {value: 'preheater', label: 'Preheater'},
                                    {value: 'aircooler', label: 'Air Cooler'},
                                ]
                            },
                            {
                                system: ['air'],
                                options: [
                                    {value: 'compressors', label: 'Compressors'},
                                    {value: 'air', label: 'Air Start Valve'},
                                    {value: 'receiver', label: 'Receiver'},
                                    {value: 'exhaust', label: 'Exhaust Valve'},
                                ]
                            },
                            {
                                system: ['lubrication'],
                                options: [
                                    {value: 'filters', label: 'Filters'},
                                    {value: 'pumps', label: 'Pumps'},
                                ]
                            },
                            {
                                system: ['oil'],
                                options: [
                                    {value: 'pumps', label: 'Pumps'},
                                    {value: 'heater', label: 'Heater'},
                                    {value: 'injector', label: 'Injector'},
                                ]
                            },
                            {
                                system: ['pistons'],
                                options: [
                                    {value: 'seal', label: 'Seal'},
                                    {value: 'pin', label: 'Pin'},
                                    {value: 'bearing', label: 'Bearing'},
                                ]
                            },
                            {
                                system: ['tc'],
                                options: [
                                    {value: 'turbine', label: 'Turbine'},
                                    {value: 'compressor', label: 'Compressor'},
                                    {value: 'mainShaft', label: 'Main Shaft'},
                                    {value: 'bearing', label: 'Bearing'},
                                ]
                            },
                            {
                                system: ['generator'],
                                options: [
                                    {value: 'winding', label: 'Winding'}
                                ]
                            }
                        ],
                        onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                            if (value !== '') {
                                setFieldValue(`${parentName}.cause`, []);
                                setFieldValue(`${parentName}.otherCause`, '');
                                setFieldTouched(`${parentName}.cause`, false);
                                setFieldTouched(`${parentName}.otherCause`, false);
                            }
                        },
                        value: '',
                        validation: {
                            required: true,
                            disabled: {
                                system: []
                            }
                        }
                    },
                    cause: {
                        type: "multiSelect",
                        label: "Cause",
                        options: [
                            {value: 'lube', label: 'Lube oil malfunction'},
                            {value: 'fuel', label: 'Fuel malfunction'},
                            {value: 'maintenance', label: 'Incorrect Maintenance'},
                            {value: 'injectors', label: 'Fuel injectors failure'},
                            {value: 'fatigue', label: 'Fatigue/Cracking'},
                            {value: 'vibration', label: 'Vibration'},
                            {value: 'other', label: 'Other cause of damage'}
                        ],
                        onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                            if (value.indexOf('other') === -1) {
                                setFieldValue(`${parentName}.otherCause`, '');
                                setFieldTouched(`${parentName}.otherCause`, false);
                            } else {
                                setFieldValue(`${parentName}.cause`, parentValues.cause.indexOf('other') === -1 ? ['other'] : value.filter(cause => cause !== 'other'));
                            }
                        },
                        value: [],
                        validation: {
                            required: true,
                            disabled: {
                                part: []
                            }
                        }
                    },
                    otherCause: {
                        type: "text",
                        label: "Other Cause",
                        value: '',
                        validation: {
                            required: true
                        },
                        showOn: {
                            cause: ['other']
                        }
                    }
                }
            }
        },
        {
            date: {
                type: 'datePicker',
                label: 'Damage Date',
                value: '',
                validation: {
                    required: true
                },
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                    if (value === null) {
                        setFieldValue("date", "");
                    }
                }
            }
        }
    ];
};

const damagesValues = (props, editMode, dateClicked) => {
    return [
        {
            damages: !props.hasOwnProperty('damages') || props.damages.length === 0
                ? [baseFormUtils.createValuesFromSchema(damagesSchema(editMode)[0].damages.structure)]
                : props.damages.map(damage => baseFormUtils.createValues(damage, damagesSchema(editMode)[0].damages.structure))
        },
        {
            date: editMode ? moment.utc(props.dateFrom) : moment(dateClicked).set({'hour': 12}).utc()
        }
    ]
};

const damagesGroups = [{}, {}];

export {damagesSchema, damagesValues, damagesGroups};