import {licensing} from "common/store/licensing";
import geStorage from "components/monitoringCategories/localStorages/geStorage";
import ThemeColors from "sass/_themeColorsForJs.scss";
import { vesselUtils } from 'common/store/storeUtils';

export const updateRunningTime = (id, data, updateState, extraChartConfigs, widget) => {
    geStorage.setRunningTime(data);

    const _data = Object.assign({}, data);

    const sensorsStyle = {
        path: {stroke: ThemeColors['sensor_color']},
        trail: {stroke: ThemeColors['disabled_surface']}
    }

    const reportsStyle = {
        path: {stroke: ThemeColors['reports_color']},
        trail: {stroke: ThemeColors['disabled_surface']}
    }

    const missingDataStyle = {
        path: {stroke: ThemeColors['missing_first_stack_sensor']},
        trail: {stroke: ThemeColors['disabled_surface']}
    }

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    let updatedData = {
        data: _data,
        style: isLight ? reportsStyle : sensorsStyle,
        styles: {sensorsStyle, reportsStyle},
        missingDataStyle: missingDataStyle,
        isLight: isLight
    }

    // check if it has data depending on the response case
    const hasData = _data?.sensors?.find(engine => engine?.loadAvg || engine?.loadAvg === 0) || _data?.telegrams?.find(engine => engine?.loadAvg || engine?.loadAvg === 0);
    if(!hasData) updatedData = {};

    updateState(id, updatedData, widget?.id);
}
