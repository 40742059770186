import weatherUtilities from './wind';
/* eslint no-unused-vars: "off" */
import { velocityLayer } from 'common-lib';
import L from 'leaflet';
import service from "common/js/service";
import ThemeColors from 'sass/_themeColorsForJs.scss';


const MapUtilities = {

    ecaStyle: {
        "color": ThemeColors['fine_status'],
        "weight": "1.5",
        "dashArray": "9, 10",
    },
    
    piracyStyle: {
        "color": ThemeColors['high_severity'],
        "weight": "1.5",
        "dashArray": "9, 10",
    },

    updateWeather: (timeStamp) => {
        return service.updateWeather(timeStamp);
    },

    windLayer: theme => L.velocityLayer({
        displayOptions: {
            velocityType: 'Global Wind',
            displayPosition: 'bottomleft',
            displayEmptyString: 'No wind data'
        },
        data: {},
        maxVelocity: 15,
        colorScale: (['ds-white'].includes(theme)) ? weatherUtilities.whiteThemeColorScale() : weatherUtilities.darkThemeColorsScale()
    }),

    currentsLayer: theme => L.velocityLayer({
        displayOptions: {
            velocityType: 'GBR Water',
            displayPosition: 'bottomleft',
            displayEmptyString: 'No water data'
        },
        data: {},
        maxVelocity: 1.1,
        velocityScale: 0.1,
        colorScale: (['ds-white'].includes(theme)) ? weatherUtilities.whiteThemeColorScale() : weatherUtilities.darkThemeColorsScale()
    }),

    circleAccordingZoom: zoom => {
        if(zoom >= 8) return 15500;
        if(zoom === 7 || zoom === 6) return 38000;
        if(zoom === 5 || zoom === 4) return 99000;
        else return 158000;
    },

    getImportantZones: zone => {
        return service.getImportantZones({zone}).then(
            result => {
                if (!result.error) {
                    let tempGeojson = [];
                    result.data.forEach(ec => {
                        let geoJson = null;
                        try{
                            geoJson = JSON.parse(ec);
                        } catch (e) {}
                        geoJson !== null && tempGeojson.push(geoJson);
                    });
                    return {
                        error: false,
                        data: L.geoJSON(tempGeojson, {
                            style: (zone.includes('ECA')) ? MapUtilities.ecaStyle : MapUtilities.piracyStyle
                        })
                    };
                }
                return result.data;
            }
        );
    },

    vesselPulse: color => {
        return `
            <svg width="60" height="60" viewbox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" fill="none" r="10" stroke=${ThemeColors[color]} stroke-width="2">
                    <animate attributeName="r" from="8" to="15" dur="1.5s" begin="0s" repeatCount="indefinite"/>
                    <animate attributeName="opacity" from="1.05" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>
                </circle>
                <circle cx="20" cy="20" fill=${ThemeColors[color]} r="10"/>
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="-31.7 -30.2 100 100">
                    <path id="Path_1085" data-name="Path 1085" d="M8.509,5,.369,7.923,3.528,17H13.5l3.15-9L8.516,5l0,.006ZM7.927,0V1.194H6.183V3.58H4.439V5.964L8.309,4.51a.582.582,0,0,1,.183-.036h.057a.684.684,0,0,1,.17.036L12.58,6V3.58H10.836V1.194H9.09V0Z"
                    transform="translate(-0.369)" fill="white" fill-rule="evenodd"/>
                </svg>
            </svg
        `;
    },

    alertPulse: color => {
        return `
            <svg width="30" height="30" viewbox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" fill="none" r="10" stroke=${ThemeColors[color]} stroke-width="2">
                    <animate attributeName="r" from="8" to="15" dur="1.5s" begin="0s" repeatCount="indefinite"/>
                    <animate attributeName="opacity" from="2" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>
                </circle>
                <circle cx="20" cy="20" fill=${ThemeColors[color]} r="10" />
            </svg>
        `;
    },

    markerBullet: color => {
        return `
            <svg width="20" height="20" viewbox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" fill=${ThemeColors[color]} r="10" />
            </svg>
        `;
    }

};

export default MapUtilities;