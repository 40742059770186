import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { FormElement } from './';
import ExpandCollapse from "../ExpandCollapse";
import {ReactComponent as SmallArrow} from 'assets/img/app/global-imgs/small-arrow.svg';
import {ReactComponent as ErrorTriangle} from 'assets/img/app/login/error_triangle.svg';
import PropTypes from 'prop-types';
import GridUtils from 'Utilities/gridUtils';
import { baseFormUtils } from 'common/store/storeUtils';
import { cloneDeep } from 'lodash';

const BaseForm = ({schema, onSubmit, values, groups, submitButtonOptions, renderExtraButtons, renderExtraButtonsAfter, renderAsterisk, className, contentWrapper, buttonsWrapper, error}) => {
    const [finalGroups, setFinalGroups] = useState(null);
    const [baseFormInstanceJSX, setBaseFormInstanceJSX] = useState({});

    let baseFormInstance = cloneDeep(baseFormUtils);
    
    // when schema is updated, we update also this baseFormInstance (for js and jsx)
    useEffect(() => {
        baseFormInstance.schema = schema;
        setBaseFormInstanceJSX(baseFormInstance);    
    // eslint-disable-next-line
    }, [schema]);

    useEffect(() => {        
        const groupsObj = {};

        Object.keys(groups).forEach(tempGroup => {groupsObj[tempGroup] = groups[tempGroup].isCollapsed});
        
        setFinalGroups(groupsObj);
    }, [groups]);

    const renderWrapper = (content, wrapper) => {
        if (wrapper === null) {
            return content;
        }

        return <wrapper.component {...wrapper.props}>
            {wrapper.hasOwnProperty('children') && !wrapper.after ? wrapper.children : ''}
            {content}
            {wrapper.hasOwnProperty('children') && wrapper.after ? wrapper.children : ''}
        </wrapper.component>;
    }

    const renderContent = ({setFieldValue, setFieldTouched, setSubmitting, handleBlur, isSubmitting, touched, errors, values, groups}) => {
        const content = renderGroups(schema, {setFieldValue, setFieldTouched, setSubmitting, handleBlur, isSubmitting, touched, errors, values}, groups);

        return renderWrapper(content, contentWrapper);
    }

    const renderButtons = ({values,errors,touched,handleSubmit,isSubmitting,setFieldValue}) => {
        const buttons = <div className={submitButtonOptions.buttonWrapperCssClass}>
            {renderExtraButtons({values,errors,touched,handleSubmit,isSubmitting,setFieldValue})}
            <button type="submit" className={submitButtonOptions.buttonCssClass}
                    disabled={baseFormInstance.hasErrors(errors, touched) || isSubmitting || submitButtonOptions.disabled}>
                {
                    typeof submitButtonOptions.buttonName === "function"
                        ? submitButtonOptions.buttonName()
                        : submitButtonOptions.buttonName
                }
                {submitButtonOptions.nextButton && <span className="small-triangle next"></span>}
            </button>
            {renderExtraButtonsAfter({values,errors,touched,handleSubmit,isSubmitting,setFieldValue})}
        </div>;

        return renderWrapper(buttons, buttonsWrapper);
    }

    const renderGroupLabel = (groups, group) => {
        if (!groups[group].renderLabel) {
            return '';
        }
        let labelProps = {
            className: groups[group].groupLabel
        };
        if (groups[group].ableToCollapse) {
            labelProps.onClick = () => {
                setFinalGroups((prevFinalGroups) => { return {...prevFinalGroups, group: !prevFinalGroups[group]}});
            };
            labelProps.className = `${groups[group].groupLabel} pointer`;
        }
        const renderTriangle = <span className={"marg-l-5 bunk"}>
            <SmallArrow className={`svg-path transform-04 ${finalGroups[group] ? '' : ' rotate--180'}`} />
        </span>

        return (
            <div {...labelProps}>
                {groups[group].hasAsterisk && '* '}
                {group}
                {groups[group].ableToCollapse && renderTriangle}
            </div>
        );
    }

    const renderGroupContent = (filterFields, formikHelpers,groups, group) => {
        const groupContent = (
            <div className="row">
                {renderFields(filterFields, formikHelpers, groups[group])}
            </div>
        );
        if (!groups[group].ableToCollapse) {
            return groupContent;
        }
        return (
            <ExpandCollapse in={!finalGroups[group]}>
                {groupContent}
            </ExpandCollapse>
        );
    }

    // @todo make groups nested?
    const renderGroups = (fields, formikHelpers, groups) => {
        if (Object.keys(groups).length === 0) {
            return <div className="row">{renderFields(fields, formikHelpers)}</div>;
        }
        return (
            <div className="row">
                {
                    Object.keys(groups).map(group => {
                        let filterFields = baseFormInstance.groupFields(fields, group);
                        if (Object.keys(filterFields).length === 0) {
                            return '';
                        }

                        return (
                            <div key={`group-${group}`} className={GridUtils.getHiddenClasses(groups[group]?.viewports, groups[group]?.className)}>
                                <div className={groups[group].groupWrapper}>
                                    {renderGroupLabel(groups, group)}
                                    {renderGroupContent(filterFields, formikHelpers,groups, group)}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    const renderFields = (fields, formikHelpers, group=false) => {
        return Object.keys(fields).map(field=>{
            let props = baseFormInstanceJSX.fieldProps(fields, field, formikHelpers, renderAsterisk, group, baseFormInstanceJSX);
            group && group.paddingViewports === 'none' && (props.key = field);
            const element = <FormElement {...props}/>;

            return group && group.paddingViewports !== 'none' ? (
                <div key={field} className={GridUtils.getHiddenClasses(group.paddingViewports, group.paddingClassName)}>
                    {element}
                </div>
            ) : element;
        });
    }

    return (
        baseFormInstanceJSX?.validate ? <Formik initialValues={Object.keys(values).length === 0 ? baseFormInstanceJSX.getValues(baseFormInstanceJSX) : values}
                validate={(values) => baseFormInstanceJSX.validate(values, baseFormInstanceJSX)}
                onSubmit={(values, extra) => onSubmit(baseFormInstanceJSX.filterOutNotNeededValues(values, [], baseFormInstanceJSX), extra)}
        >
            {({ values,errors,touched,handleSubmit,handleBlur,isSubmitting,setFieldValue,setFieldTouched,setSubmitting }) => {
                baseFormInstanceJSX.updateFieldAbilityToRender(values, baseFormInstanceJSX.schema, [], baseFormInstanceJSX);

                return (
                    <form onSubmit={handleSubmit}>
                        <div className={className}>
                            {error && isSubmitting && 
                                <div className='form-change-pass-error'>
                                    <span className="marg-r-10"><ErrorTriangle /></span>
                                    <p> Please fill all the fields</p>
                                </div>
                            }
                            {renderContent({setFieldValue, setFieldTouched, setSubmitting, handleBlur, isSubmitting, touched, errors, values, groups: groups})}
                            {renderButtons({values,errors,touched,handleSubmit,isSubmitting,setFieldValue})}
                        </div>
                    </form>
                )
            }}
        </Formik>: <></>
    );
}

BaseForm.propTypes = {
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    // values: PropTypes.object,
    values: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    groups: PropTypes.object,
    submitButtonOptions: PropTypes.object,
    renderExtraButtons: PropTypes.func,
    renderExtraButtonsAfter: PropTypes.func,
    renderAsterisk: PropTypes.bool,
    className: PropTypes.string,
    contentWrapper: PropTypes.object,
    buttonsWrapper: PropTypes.object,
}

BaseForm.defaultProps = {
    values: {},
    groups: {},
    submitButtonOptions: {
        buttonName: <div className='main-cta__text'>Submit</div>,
        buttonCssClass: 'main-cta',
        buttonWrapperCssClass: 'full-width flex-centered',
        disabled: false
    },
    renderExtraButtons: ({values,errors,touched,handleSubmit,isSubmitting,setFieldValue}) => {},
    renderExtraButtonsAfter: ({values,errors,touched,handleSubmit,isSubmitting,setFieldValue}) => {},
    renderAsterisk: true,
    className: '',
    contentWrapper: null,
    buttonsWrapper: null,
};

export default BaseForm;