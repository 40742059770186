import React, { useState, useEffect } from "react";
import SyncCharts from 'components/charts/genericSyncCharts/syncCharts';
import syncChartsOptions from 'components/charts/genericSyncCharts/syncChartsOptions';

const VesselMonitoringChart = props => {

    const [syncCharts, setSyncCharts] = useState(syncChartsOptions());

    useEffect(() => {
        if(!props.data) return;
        let options = syncChartsOptions();
        options["series"] = props.data?.data?.series;
        options["yAxis"] = props.data?.data?.yAxis;
        options.chart.marginTop = props.data?.data?.marginTop;
        options.chart.height = props.data?.data?.height;
        options.elementId = props.data?.data?.chart.id;
        options.title = props.data?.data?.title;
        options.xAxis.plotLines = props.data?.data?.plotLines;
        options.resetZoomAfterUpdate = true;
        options.defaultZoom = 60;
        options.exporting.filename = props?.exportingChartTitle;

        setSyncCharts(options);
        // eslint-disable-next-line
    }, [props.data])

    return (
        <div className='vesselMonitoring' style={props.style}>
            <SyncCharts options={syncCharts} />
        </div>
    );
}

export default VesselMonitoringChart;
