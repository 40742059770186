import React from 'react';
import {ReactComponent as NoDataIcon} from "assets/img/app/global-imgs/no-data.svg";


const NoData = (props) => {
    return (
        <div className="no-data-state d-flex justify-content-center align-items-center flex-column" style={{height: props.height, padding: '5px 0 15px 0', minHeight: props.minHeight ? props.minHeight : 'auto'}}>
            { (parseInt(props.height) > 200 || parseInt(props?.minHeight) > 200 || props.height === 'auto' || props.displayNoDataIcon) && <div className="no-data-icon marg-b-15"><NoDataIcon className="svg-path-no-data" /></div> }
            <div className="form-text">{props.text}</div>
        </div>
    );
}
 
export default NoData;
