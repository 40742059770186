import meStorage from 'components/monitoringCategories/localStorages/meStorage';
import chartOptions from "components/charts/lineChart/react-lineChart";
import chartUtils from 'Utilities/charts';
import { vesselUtils } from 'common/store/storeUtils';
import { csvXlsxExportForCurvesAndScatterWithDatetime, downloadChartInCSVform, downloadChartInXLSXform } from "Utilities/highchartsUtils";

let scatterData = {},
    chartSeries = [],
    columnHeaderMapper = {};

const yAxisData = [{id: 'scav-axis', title: {text: "Scavenge Air Press (bar)"}, opposite: false}];

const buildChartSeriesAndFillWithData = (scatterData) => {
    chartSeries = [];
    const scatterKeys = Object?.keys(scatterData?.DEFAULT);

    // build the scatter series depending on how many turbo chargers we receive
    scatterKeys.forEach((key, index) => {
        const scatterDataToFill = scatterData?.DEFAULT[key] || [];

        chartSeries[index] = {
            name: key, 
            data: scatterDataToFill, 
            tooltipTitle: `${key} Scavenge Air Press`, 
            yAxis: 'scav-axis', 
            type: 'scatter', 
            marker: {enabled: true, symbol: 'circle'},
            tooltip: {valueDecimals: 2, valueSuffix: ' bar'},
            showInLegend: true, // the TC series should always appear in legend, even if they do not have data (empty array)
            visible: scatterDataToFill?.length > 0, // in case the series does not have data, then it should be deactivated in the legend
            customTooltipBorderColor: 'defaultSeriesColor' // the tooltip border color for the scatter series should be the same as the series default color
        };
        columnHeaderMapper[key] = `${key} (bar)`
    });
}

// the data that we receive here are for the scatter plots
const updateTCScavRpmChart = (id, data, updateState, extraChartConfigs, widget) => {

    if(meStorage.TCScavRpmScatterPlotsData) scatterData = meStorage.TCScavRpmScatterPlotsData;
    else scatterData = data;

    // handle empty data case, return empty object so that the noData component appears (show the NoData component when we do not have any scatter data)
    if(!scatterData) return updateState(id, {}, widget?.id);

    const chartData = Object.assign({}, chartOptions(':.2f', '%'));

    // set the series data of the chart
    chartData.yAxis = yAxisData;
    buildChartSeriesAndFillWithData(scatterData);
    chartData.series = [...chartSeries];

    // set some chart options that are common in curves-scatter charts
    chartUtils.curvesAndScatterDataChart.setCommonChartOptions(chartData);

    // set extra options that are specific for this chart
    chartData.chart.height = 360;
    chartData.chart.marginTop = 95;
    chartData.legend.y = 15;
    chartData.xAxis.title = { text: "T/C RPM", y: 10 };
    chartData.exporting.filename = `${vesselUtils.getVesselName(widget.vesselIds[0])}-TURBOCHARGER PSCAV VS RPM`;
    chartData.exporting.csv.columnHeaderFormatter = (item) => chartUtils.curvesAndScatterDataChart.setExportingColumns(item, columnHeaderMapper, 'RPM (rpm)');

    const tooltipExtraOptions = {
        titleX: 'T/C RPM: ',
        valueXSuffix: '',
        width: '150px'
    };
    chartData.tooltip.formatter = function() {return chartUtils.curvesAndScatterDataChart.tooltipFormatter.call(this, tooltipExtraOptions)};

    chartData.exporting.buttons.contextButton = {
        menuItems: [
            "download",
            "customSeparator",
            {
                text: "XLSX",
                onclick: function () {
                    const dto = csvXlsxExportForCurvesAndScatterWithDatetime(this, 'rpm', 'bar')
                    downloadChartInXLSXform({xlsxRows: dto.rows, sheetsName: dto.sheetsName.trim().length > 30 ? 'TURBOCHARGER PSCAV VS RPM' : dto.sheetsName, name: dto.name});
                }
            },
            {
                text: "CSV",
                onclick: function () {
                    const dto = csvXlsxExportForCurvesAndScatterWithDatetime(this, 'rpm', 'bar')
                    const csvContent = dto.rows.map(row => row.join(',')).join('\n');
                    downloadChartInCSVform({csvContent, name: dto.name});
                }
            },
        ]

    }

    if (!widget?.inDashboard) meStorage.setTCScavRpmScatterPlotsData(scatterData);

    scatterData = {};

    return updateState(id, chartData, widget?.id);
}

export { updateTCScavRpmChart };