import React from "react";
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import { ReactComponent as WindDirectionHeadIcon } from "assets/img/app/global-imgs/windDirectionHeadIcon.svg";
import { ReactComponent as WindDirectionTailIcon } from "assets/img/app/global-imgs/windDirectionTailIcon.svg";
import { ReactComponent as WindDirectionSideIcon } from "assets/img/app/global-imgs/windDirectionSideIcon.svg";
import Utilities from 'Utilities/global';
import classnames from "classnames";

// this component is inside the hull performance table and it can contain multiple boxes one next to the other
// each box contains the wind speed, wind direction and confidence level info
const HullPerformanceTableCell = ({ value, windDirection, confidenceLevel, type }) => {

    const getWindDirectionIcon = (windDirection) => {
        return (windDirection === 'HEAD')
            ? <WindDirectionHeadIcon className='svg-path marg-l-10' />
            : (windDirection === 'TAIL')
                ? <WindDirectionTailIcon className='svg-path marg-l-10' />
                : (windDirection === 'SIDE')
                    ? <WindDirectionSideIcon className='svg-path-stroke marg-l-10' />
                    : <></>
    };

    const getItemClasses = (confidenceLevel, type, value) => classnames(
        {
            'HullPerformanceTableComponent': !(type && !value),
            // show an empty div in case the item is on of the 'Calm (ISO)' ones and it's value is null (in order to fill up the space and show the calm info with the right order)
            'HullPerformanceTableComponent-empty': (type && !value),
            'lowConfidenceLevel': confidenceLevel === 2,
            'highConfidenceLevel': confidenceLevel === 3
        }
    );

    const getDirection = direction => {
        if(direction === 'HEAD') return 'Head';
        if(direction === 'TAIL') return 'Tail';
        if(direction === 'SIDE') return 'Side';
        return '';
    }

    return (
        <>
            <Tooltip
                component={
                    <div className={getItemClasses(confidenceLevel, type, value)}>
                        <div>{Utilities.roundToDigits(value, 1)}</div>
                        {windDirection && getWindDirectionIcon(windDirection)}
                    </div>
                }
                title={getDirection(windDirection)}
            />
        </>
    );
};

export default HullPerformanceTableCell;
