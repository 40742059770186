import React, { useEffect } from 'react';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {ReactComponent as DatePickerIcon} from 'assets/img/app/global-imgs/calendar.svg';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const Datepicker = ({ selectedPickerYear, setSelectedPickerYear, customPopUpClass }) => {

    const vesselStoreJSX = useSelector(state => state.vessel)
    const vesselGroupStoreJSX = useSelector(state => state.vesselGroup);
    const monitoringStoreJSX = useSelector(state => state.monitoring);

    useEffect(() => {
        setSelectedPickerYear(monitoringStoreJSX?.ciiSearchingYear?.selectedPickerYear)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[vesselStoreJSX.selectedVessel, vesselGroupStoreJSX.selectedVesselGroup])

    const enableDatePicker = () => {
        document.querySelector('.report-date-picker input.MuiInput-input').click();
    }

    const updateYear = date => {
        setSelectedPickerYear(moment(date).year());
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePickerIcon className="calendar-icon" onClick={() => enableDatePicker()} />
                <DatePicker
                    autoOk
                    variant="inline"
                    openTo="year"
                    views={["year"]}
                    minDate={new Date("2023-01-01")}
                    maxDate={moment().endOf('year').subtract(1, 'day')}
                    value={moment(`01/01/${selectedPickerYear}`, 'DD/MM/YYYY').toDate()}
                    format="YYYY"
                    animateYearScrolling={true}
                    className="ds-input as-btn"
                    onChange={updateYear}
                    PopoverProps = {{
                        className: classNames('reportsDatePickerPopover-leg', {[customPopUpClass]:customPopUpClass}),
                        style:{ zIndex: 100000 }
                    }}
                />
        </MuiPickersUtilsProvider>
    );
}

export default Datepicker;
