import React from 'react';
import { ReactComponent as NoDataIcon } from "assets/img/app/global-imgs/no-data.svg";
import Utilities from "Utilities/global";
import CircleProgressChart from 'components/charts/circleProgreesChart/circleProgressChart';
import {ReactComponent as VesselRatingIcon} from 'assets/img/app/monitoring-imgs/vessel-rating.svg';
import ThemeColors from 'sass/_themeColorsForJs.scss';


const VesselRating = props => {

    const vesselTypeMapper = type => {
        if(!type) return '-';
        if(type === "BULK_CARRIER") return 'Bulk Carrier';
        if(type === "CHEMICAL_TANKERS") return 'Chemical Tanker';
        if(type === "OIL_TANKERS") return 'Oil Tanker';
        if(type === "CONTAINER") return 'Containership';
        if(type === "RO_RO_SHIP") return 'Ro-ro ship';
        if(type === "GAS_CARRIER") return 'Gas Carrier';
        if(type === "VEHICLE_CARRIER") return 'Vehicle Carrier';
        if(type === "GENERAL_CARGO_SHIP") return 'General Gargoship';
        if(type === "OTHER_SHIP_TYPES") return 'Other Ship';
        if(type === "REFRIGERATED_CARGO_CARRIER") return 'Refrigerated Cargoship';
    }

    return (
        <div className="vessel-rating d-flex align-items-center justify-content-center flex-column" style={{height: props.style.height}}>
            { props.allowTelegrams && props.data.emptyData
            ? <div className="vessel-rating-soon" />
            : props.data.emptyData
            ? props.data.pastData
                ? <>
                    <NoDataIcon className="svg-path-no-data marg-b-20" />
                    <div className="form-text">There are no classifications for those historical periods</div>
                </>
                : <>
                    <NoDataIcon className="svg-path-no-data marg-b-20" />
                    <div className="form-text">No data to display at the moment</div>
                </>
            : <>
                <div className="d-flex align-items-center justify-content-center" style={{height: `calc( ${props.style.height} - 60px`}}>
                    <div className="marg-r-15">
                        <CircleProgressChart progressChartOptions={{
                            id: `vessel-rating-${props.data.data.vesselId}`, insideText: `<b><span class='rating-class'>${props.data.data.ratingClass || '-'}</b><br> <span class='rating-class__text'>CLASS</span></span>`,
                            titleY: 25, export: false, load: props.data.data.ratingPercentage || 0.1,
                            colors: [ThemeColors['reports_color']],
                            width: 160, backColor: Utilities.getThemeColors.backColor(),
                            style: {
                                color: Utilities.getThemeColors.text(),
                                fontSize: "14px", fontFamily: "sans-serif"
                            } } }
                        />
                    </div>

                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                        <div className="vessel-rating-ico marg-r-15">
                            <VesselRatingIcon className="svg-path-with-text" />
                        </div>
                        <div className="vessel-rating-info">
                            <div className="statistic-value">{Utilities.renderNumber(Utilities.roundToDigits(props.data.data.ratingIndex, 1))} g CO2 / MT .NM</div>
                            <div className="label">ENERGY EFFICIENCY OPERATIONAL INDICATOR</div>
                        </div>
                    </div>
                </div>
                <div className="vessel-rating__footer d-flex justify-content-center align-items-center flex-column" style={{height: '60px'}}>
                    <div className="table-outstanding-element">Class {props.data.data.ratingClass || '-'} {vesselTypeMapper(props.data.data.vesselType)}</div>
                    <div className="table-element">{Utilities.renderNumber(Utilities.roundToDigits(props.data.data.classLowerLimit, 1), true)} - {Utilities.renderNumber(Utilities.roundToDigits(props.data.data.classUpperLimit, 1), true)} g CO2 / mt .nm</div>
                </div>
            </> }
        </div>
    );
}
 
export default VesselRating;
