import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classnames from "classnames";

const SegmentButtons = props => {
    const inputsClasses = (value, fieldValue) => {
        return classnames("segment-buttons__button form-description", { "active": value === fieldValue });
    }

    const iconClasses = (value, fieldValue) => {
        return classnames("segment-buttons__iconButton flex-centered form-description", { "active": value === fieldValue });
    }


    return (
        <Field name={props.name}>
            {({ field, form }) => (
                <React.Fragment>
                    {props.label}
                    <div {...props} className={`segment-buttons flex-centered-start${props.disabled ? ' disabled' : ''}`}>
                        {props.options.map((button, i) => (
                            button.iconComponent ? <div key={button.value} className={iconClasses(button.value, field.value)} onClick={el => { !props.disabled && props.onChange({ target: { value: button.value } }) }} >
                                <button.iconComponent />
                                <input
                                    type="button"
                                    name={`${button.label}-${i}`}
                                    value={button.label}
                                    onClick={el => { !props.disabled && props.onChange({ target: { value: button.value } }) }} />
                            </div> :
                                <input key={button.value}
                                    type="button"
                                    className={inputsClasses(button.value, field.value)}
                                    name={`${button.label}-${i}`}
                                    value={button.label}
                                    onClick={el => { !props.disabled && props.onChange({ target: { value: button.value } }) }} />
                        ))}
                    </div>
                    {props.renderError()}
                </React.Fragment>
            )}
        </Field>
    );
}

SegmentButtons.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    renderError: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool
};

SegmentButtons.defaultProps = {
    className: '',
    disabled: false,
};

export default SegmentButtons;