// import hullStorage from 'components/monitoringCategories/localStorages/hullStorage';
import ArbitraryPvFvUtils from './arbitraryPvFvUtils.js';

// the data that we receive here are for the scatter plots
const updateArbitraryPvFvChart = (id, dataObj, updateState, extraChartConfigs, widget) => {
    const extraChartInfo = ArbitraryPvFvUtils.widgetInfo()[extraChartConfigs.widgetTitle].extraChartInfo;

    // only for the CII-SPEED widget we want to create the plotbands that will show to the user the limits of each class
    if(extraChartConfigs.widgetTitle === 'CII-SPEED' && dataObj?.thresholds) {
        const plotBands = ArbitraryPvFvUtils.createArbitraryChartPlotBands(dataObj.thresholds);

        if(plotBands) extraChartInfo.yAxis.plotBands = plotBands;
    }

    ArbitraryPvFvUtils.drawChart(
        id,
        dataObj,
        updateState,
        widget,
        extraChartInfo
    );
}

export { updateArbitraryPvFvChart };
