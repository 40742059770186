import React from 'react';
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const CheckboxOptions = React.forwardRef(({ options, checkboxSelectedOptions, getTooltipTitle, onCheckBoxOptionClick, disabledAllOptions, type }, ref) => {
    const getCheckboxOptionClasses = (option) => {
        return classnames(
            'checkboxOption', 
            {
                'active': checkboxSelectedOptions.includes(option),
                'withTooltip': getTooltipTitle(option, checkboxSelectedOptions, type) !== '',
                'onlyOneOption': getTooltipTitle(option, checkboxSelectedOptions, type) !== '' && checkboxSelectedOptions.includes(option) && checkboxSelectedOptions.length === 1,
                'disabledOption': disabledAllOptions
            }
        )
    };

    return(
        <div ref={ref} className='checkboxOptions flex-centered-start marg-t-15'>
            {
                options.map(
                    (option, index) => <Tooltip 
                        key={index}
                        component={
                            <div className={getCheckboxOptionClasses(option)} onClick={(e) => onCheckBoxOptionClick(e, option)}>
                                <div>{option}</div>
                                {
                                    !checkboxSelectedOptions.includes(option) || disabledAllOptions
                                        ? <div className='checkboxOption-circle'/>
                                        : <div className='checkboxOption-circle selectedOption'> &#x2715; </div>
                                }
                            </div>
                        }
                        title={getTooltipTitle(option, checkboxSelectedOptions, type)}
                        height='auto'
                    />
                )
            }
        </div>
    )
});

CheckboxOptions.propTypes = {
    options: PropTypes.array,
    getTooltipTitle: PropTypes.func,
    checkboxSelectedOptions: PropTypes.array,
    onCheckBoxOptionClick: PropTypes.func,
    disabledAllOptions: PropTypes.bool,
    type: PropTypes.string
}

export default CheckboxOptions;