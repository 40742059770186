import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { ReactComponent as CloseIcon } from "assets/img/app/global-imgs/close.svg";
import PropTypes from "prop-types";

const Modal = ({
	showModal,
	setShowModal,
	widgetTitle,
	children
}) => {
	return (
		<Dialog
			maxWidth="md"
			open={showModal}
			className="hullPerformanceTableModal centered-modal"
		>
			<DialogTitle className="modalTitle">
				<div>{widgetTitle}</div>
				<CloseIcon
					className="svg-path closeTableIcon"
					onClick={() => setShowModal(false)}
				/>
			</DialogTitle>
			<DialogContent>
				{children}
			</DialogContent>
		</Dialog>
	);
};

Modal.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	widgetTitle: PropTypes.string,
    children: PropTypes.node
};

export default Modal;
