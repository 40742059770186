import React, { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Paper
} from "@material-ui/core";
import { headCells } from "./ciiLegsEfficiencyUtils";
import CiiClassBox from "components/ciiClasses/ciiClassBox";
import { ReactComponent as RedUp } from "assets/img/app/overview-imgs/RedUp.svg";
import { ReactComponent as GreenDown } from "assets/img/app/overview-imgs/GreenDown.svg";
import { ReactComponent as InformationIcon } from "assets/img/app/global-imgs/informationIcon.svg";
import DoubleData from "../../reports/doubleData";
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import LoadMore from "components/table/loadMore/loadMore";
import classnames from "classnames";
import { stableSort, handleExportCSVData } from "Utilities/muiTableUtils";

const CiiLegsEfficiency = (props) => {
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("dateArr");

	const createSortHandler = (property) => (event) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const getLoadMoreClasses = () => {
		return classnames("loadMoreContainer", "flex-centered", {
			"d-none": props?.extra?.totalPages === props?.extra?.page + 1
		});
	};

	return (
		<Paper className="material-ui-paper">
			<button
				style={{ display: "none" }}
				onClick={() => handleExportCSVData(stableSort(props?.data, orderBy, order),headCells)}
				type="button"
			>
				Export CSV
			</button>
			<TableContainer className="ds-mui-table">
				<Table
					className="material-ui-table"
					aria-labelledby="tableTitle"
					aria-label="enhanced table"
				>
					<TableHead>
						<TableRow>
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.id}
									align="center"
									sortDirection={
										orderBy === headCell.id ? order : false
									}
								>
									{headCell.sortable ? (
										<TableSortLabel
											active={orderBy === headCell.id}
											direction={
												orderBy === headCell.id
													? order
													: "asc"
											}
											onClick={createSortHandler(
												headCell.id
											)}
										>
											{headCell.label}
										</TableSortLabel>
									) : (
										headCell.label
									)}
									{headCell?.info ? <Tooltip component={<InformationIcon className='svg-path mb-1 ml-1'/> } height='auto' title={<div className="dcii-tooltip">{headCell.info}</div>} /> : "" }
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{stableSort(props?.data, orderBy, order,"legsEfficiency")?.map(
							(row, index) => (
								<TableRow hover tabIndex={-1} key={index}>
									<TableCell align="center">{row.departureDate || "-"}</TableCell>
									<TableCell align="center">{row.arrivalDate || "-"}</TableCell>
									<TableCell align="center">{row.departure || "-"}</TableCell>
									<TableCell align="center">{row.arrival || "-"}</TableCell>
									<TableCell align="center">
										<DoubleData
											enableDevider={true}
											firstPart={{
												classNames: "reportsData",
												value: row?.fuelConsumption?.telegrams
											}}
										/>
									</TableCell>
									<TableCell align="center">
										<DoubleData
											enableDevider={true}
											firstPart={{
												classNames: "reportsData",
												value: row?.co2Emissions?.telegrams
											}}
										/>
									</TableCell>
									<TableCell align="center">
										<DoubleData
											enableDevider={true}
											firstPart={{
												classNames: "reportsData",
												value: row?.milesTravelled?.telegrams
											}}
											/>
									</TableCell>
									<TableCell align="center">
										{(row?.cii?.telegramClass && row?.cii?.telegramRating && <CiiClassBox
											reportsCiiClass={row?.cii?.telegramClass}
											reportsValue={row?.cii?.telegramRating}
										/>) || <span className="reportsData">-</span>}
									</TableCell>
									<TableCell align="center">
										{(row?.ciiYtdAfterLeg?.telegramClass && row?.ciiYtdAfterLeg?.telegramRating && <CiiClassBox
											reportsCiiClass={row?.ciiYtdAfterLeg?.telegramClass}
											reportsValue={row?.ciiYtdAfterLeg?.telegramRating}
										/>) || <span className="reportsData">-</span>}
									</TableCell>
									<TableCell align="center">
									{ row?.ciiDelta?.telegramDelta  ? <DoubleData
											enableDevider={true}
											firstPart={{
												classNames: "reportsData",
												value: row?.ciiDelta?.telegramDelta,
												unit: "%",
												icon: row?.ciiDelta?.telegramDelta?.includes("-") ? (
													<GreenDown className="mb-1" />
													) : (
													<RedUp className="mb-1" />
												)
											}}
										/> : <span className="reportsData">-</span>}
									</TableCell>
								</TableRow>
						))
					}
					</TableBody>
				</Table>
				<div className={getLoadMoreClasses()}>
					<LoadMore fetchMore={props?.extra?.fetchMore} />
				</div>
			</TableContainer>
		</Paper>
	);
};

export default CiiLegsEfficiency;
