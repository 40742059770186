const overviewStorage = {
    navigationStatusData: null,
    navigationStatusMilesData: null,
    vesselLogData: null,
    vesselRating: null,
    fuelConsumpChartData: null,
    fuelConsumpTotalsData: null,
    vesselMonitoringChartData: null,

    setNavigationStatusData: (data) => overviewStorage.navigationStatusData = data,
    setNavigationStatusMilesData: (data) => overviewStorage.navigationStatusMilesData = data,
    setVesselLogData: (data) => overviewStorage.vesselLogData = data,
    setVesselRating: (data) => overviewStorage.vesselRating = data,
    setFuelConsumpChartData: (data) => overviewStorage.fuelConsumpChartData = data,
    setFuelConsumpTotalsData: (data) => overviewStorage.fuelConsumpTotalsData = data,
    setVesselMonitoringChartData: (data) => overviewStorage.vesselMonitoringChartData = data,

    resetOverviewStorage: () => {
        overviewStorage.setNavigationStatusData(null);
        overviewStorage.setNavigationStatusMilesData(null);
        overviewStorage.setVesselLogData(null);
        overviewStorage.setVesselRating(null);
        overviewStorage.setFuelConsumpChartData(null);
        overviewStorage.setFuelConsumpTotalsData(null);
        overviewStorage.setVesselMonitoringChartData(null);
    },

    isGlobalStateEmpty: () => {
        return !overviewStorage.navigationStatusData || !overviewStorage.navigationStatusMilesData || !overviewStorage.vesselLogData
            || !overviewStorage.vesselRating || !overviewStorage.fuelConsumpChartData || !overviewStorage.fuelConsumpTotalsData
            || !overviewStorage.vesselMonitoringChartData;

    }
}

export default overviewStorage;