import moment from "moment";
import service from "common/js/service";
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import { dashboardUtils } from 'common/store/storeUtils';

class ReportsService {
    constructor() {
        this.initGenericRequest();
        this.initBasicChartsRequest();
        this.prerequisiteReportChart = {};
    }

    initGenericRequest() {
        this.genericRequest = {
            fromDate: moment().valueOf(),
            toDate: moment().valueOf(),
            reportType: 'GENERIC',
            withMetadata: false,
            withNavigationStatus: false,
            withTelegramNavigationStatus: false,
            withBoilerStatus: false,
            vessels: [],
            metrics: []
        };
    }

    initBasicChartsRequest() {
        this.basicChartsRequest = {
            fromDate: moment().valueOf(),
            toDate: moment().valueOf(),
            reportType: 'HIGHCHARTS_BASIC',
            predictionCategory: '',
            aggregation: 'avg',
            withMetadata: false,
            withNavigationStatus: false,
            withBoilerStatus: false,
            withTimestampRange: false,
            withTelegramNavigationStatus: false,
            xAxis: 'time',
            vessels: [],
            metrics: []
        }
    }

    resolveReports(vessels, reports, setResponse, updateRequestOnGoing, noNoon, groupedReportsIds, MaxGenericRequestNumber, ifNoon) {
        reports.forEach(report => {
            if(updateRequestOnGoing) setTimeout(() => updateRequestOnGoing(report.widgetId, true, report));

            // Grouping generic requests with max components at 6. More than 6 are requested separated.
            if(MaxGenericRequestNumber > 6) report.singleRequest.value = true;

            if (report.prerequisite && report.prerequisite.request) {
                const params = report.prerequisite.request().params;
                report.prerequisite.request().method(params).then(response => {
                    report.prerequisite.callback(response, report);
                    if(report.prerequisite.prerequisiteBuildsPayload) {
                        report.payload = report.prerequisite.updatePayloadCallback(report);
                    }
                    this.resolveEachReport(vessels, report, setResponse, updateRequestOnGoing, noNoon, groupedReportsIds, MaxGenericRequestNumber);
                    this.makeRequestsForGroupedGeneric(groupedReportsIds, setResponse, updateRequestOnGoing, ifNoon);
                });
                return;
            }

            this.resolveEachReport(vessels, report, setResponse, updateRequestOnGoing, noNoon, groupedReportsIds, MaxGenericRequestNumber);
        });
        this.makeRequestsForGroupedGeneric(groupedReportsIds, setResponse, updateRequestOnGoing, ifNoon);
    }

    makeRequestsForGroupedGeneric(groupedIds, setter, updateRequestOnGoing, ifNoon) {
        if(this.genericRequest.metrics.length > 0) service.getReports('/reports', this.genericRequest, `get-${ifNoon}generic-report`).then(response => {
            groupedIds.forEach(id => {
                response.data ? setter(response.data, id) : setter([], id);
                if(updateRequestOnGoing)
                    setTimeout(() => updateRequestOnGoing(id, false) );
            });
        });
    }

    registerReports(vessels, reports, setResponse, updateRequestOnGoing, noNoon) {
        if(vessels && vessels[0] === -1) return;
        this.initGenericRequest();
        this.initBasicChartsRequest();

        const ifNoon = noNoon ? '' : 'daily-';
        let groupedReportsIds = [];
        let MaxGenericRequestNumber = 0;

        let groupedPrerequisiteReportIds = [];
        let prerequisiteReportSetters = {};
        let promises = [];

        // group together all the prerequisites that are of report type
        reports.forEach(report => {
            if (report.prerequisite && report.prerequisite.isReport) {
                this.prerequisiteReportChart = {};
                const setter = report.prerequisite.callback;
                prerequisiteReportSetters[report.prerequisite.report.widgetId] = setter;
                this.resolveEachReport(vessels, report.prerequisite.report, setter, updateRequestOnGoing, noNoon, groupedPrerequisiteReportIds, MaxGenericRequestNumber);
                if(this.prerequisiteReportChart.urlExtension) {
                    if(this.basicChartsRequest.metrics.length > 0) {
                        promises.push(service.getReports(`/reports${this.prerequisiteReportChart.urlExtension}`, this.basicChartsRequest, `get-${this.prerequisiteReportChart.ifNoon}highchart-report`).then(response => {
                            response.data ? setter(response.data.series, report) : setter([]);
                        }));
                    }
                }
            }
        });

        // if there are any report prerequisites, then execute their requests first and then all the others (inside 'then')
        Promise.all(promises).then(values => {
            if(groupedPrerequisiteReportIds.length > 0) {
                if(this.genericRequest.metrics.length > 0) service.getReports('/reports', this.genericRequest, `get-${ifNoon}generic-report`).then(response => {
                    groupedPrerequisiteReportIds.forEach(id => {
                        const setter = prerequisiteReportSetters[id];
                        response.data ? setter(response.data.series) : setter([]);
                    });
                    this.initGenericRequest(); // initialize genericRequest object for the next requests (grouped requests) because it was filled with the prerequisites metrics

                    this.resolveReports(vessels, reports, setResponse, updateRequestOnGoing, noNoon, groupedReportsIds, MaxGenericRequestNumber, ifNoon);
                });
            } else {
                this.resolveReports(vessels, reports, setResponse, updateRequestOnGoing, noNoon, groupedReportsIds, MaxGenericRequestNumber, ifNoon);
            }
        });
    }

    constructSeparateChartsRequest(vessels, report, setResponse, updateRequestOnGoing, noNoon) {
        let genericRequest = {
            fromDate: moment().valueOf(),
            toDate: moment().valueOf(),
            reportType: 'GENERIC',
            vessels: [],
            metrics: []
        };
        const ifNoon = noNoon ? '' : 'daily-';
        const reportObj = report.innerPrerequisite ? report.widget : report;

        let widgetFromTo = {};
        if(reportObj?.inDashboard && !report.specificDates) {
            widgetFromTo = {
                from: reportObj?.timespan[0] !== "LEG" ? dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties.timespansObject[reportObj.widgetId])?.from : dashboardUtils.dashboardProperties.timespansObject[reportObj.widgetId+reportObj.id]?.timestampFrom,
                to: reportObj?.timespan[0] !== "LEG" ? dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties.timespansObject[reportObj.widgetId])?.to : dashboardUtils.dashboardProperties.timespansObject[reportObj.widgetId+reportObj.id]?.timestampTo
            }
        }

        genericRequest.fromDate = report?.inDashboard ? report.specificDates ? reportObj.specificDates.from : widgetFromTo.from : registerWidgetsStore.fromTo.from;
        genericRequest.toDate = report?.inDashboard ? report.specificDates ? reportObj.specificDates.to : widgetFromTo.to : registerWidgetsStore.fromTo.to;

        genericRequest.vessels = vessels;
        genericRequest.metrics = report.payload.metrics;
        genericRequest.withMetadata = report.payload.withMetadata;
        genericRequest.withNavigationStatus = report.payload.withNavigationStatus;
        genericRequest.withTelegramNavigationStatus = report.payload.withTelegramNavigationStatus;
        genericRequest.withBoilerStatus = report.payload.withBoilerStatus;

        service.getReports('/reports', genericRequest, report.singleRequest.overwriteHeader ? report.singleRequest.overwriteHeader : `get-${ifNoon}generic-report`).then(response => {
            response.data ?
                setResponse(response.data, report.widgetId, reportObj)
                : setResponse([], report.widgetId, reportObj);
            if(updateRequestOnGoing) setTimeout(() => updateRequestOnGoing(report.widgetId, false, reportObj));
        });
    }

    constructGenericChartsRequest(vessels, report, setResponse, updateRequestOnGoing, noNoon, groupedReportsIds, MaxGenericRequestNumber) {
        let widgetFromTo = {};
        const reportObj = report.innerPrerequisite ? report.widget : report;

        if(reportObj?.inDashboard && !report.specificDates) {
            widgetFromTo = {
                from: reportObj?.timespan[0] !== "LEG" ? dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties.timespansObject[report.id])?.from : dashboardUtils.dashboardProperties.timespansObject[reportObj.widgetId+reportObj.id]?.timestampFrom,
                to: reportObj?.timespan[0] !== "LEG" ? dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties.timespansObject[report.id])?.to : dashboardUtils.dashboardProperties.timespansObject[reportObj.widgetId+reportObj.id]?.timestampTo
            }
        }

        this.genericRequest.fromDate = reportObj?.inDashboard ? widgetFromTo.from : registerWidgetsStore.fromTo.from;
        this.genericRequest.toDate = reportObj?.inDashboard ? widgetFromTo.to : registerWidgetsStore.fromTo.to;
        this.genericRequest.vessels = vessels;
        this.genericRequest.withMetadata = report.payload.withMetadata;
        this.genericRequest.withNavigationStatus = report.payload.withNavigationStatus;
        this.genericRequest.withTelegramNavigationStatus = report.payload.withTelegramNavigationStatus;
        this.genericRequest.withBoilerStatus = report.payload.withBoilerStatus;
        groupedReportsIds.push(report.widgetId);
        report.payload.metrics.forEach(metric => {
            let found = false;   //metricData from different components must grouped in by metric category in generic request.
            this.genericRequest.metrics.forEach(rMetric => {
                if(rMetric.metricCategory === metric.metricCategory && !found) {
                    found = true;
                    let mFound = false;
                    metric.metricData.forEach(metricObj => {
                        rMetric.metricData.forEach(rMetricObj => {
                            if(metricObj.metricName === rMetricObj.metricName) mFound = true;
                        });
                        if(!mFound) rMetric.metricData.push(metricObj);
                    });
                }
            });
            if(!found) this.genericRequest.metrics.push(metric);
        });
        MaxGenericRequestNumber += 1;
        this.genericRequest.metrics.concat(report.payload.metricData);
    }

    constructBasicChartsRequest(vessels, report, setResponse, updateRequestOnGoing, noNoon) {
        this.initBasicChartsRequest();
        const ifNoon = noNoon ? '' : 'daily-';
        let urlExtension = '';
        const reportObj = report.innerPrerequisite ? report.widget : report;

        if(report.payload.predictionCategory) urlExtension = '/predictions/highcharts';
        if(report.payload.predictionCategory) this.basicChartsRequest.predictionCategory = report.payload.predictionCategory;
        if(report.payload.predictionCategory) this.basicChartsRequest.vesselId = vessels[0];

        let widgetFromTo = {};
        if(reportObj?.inDashboard && !report.specificDates) {
            widgetFromTo = {
                from: reportObj?.timespan[0] !== "LEG" ? dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties.timespansObject[reportObj.widgetId]).from : dashboardUtils.dashboardProperties.timespansObject[reportObj.widgetId+reportObj.id].timestampFrom,
                to: reportObj?.timespan[0] !== "LEG" ? dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties.timespansObject[reportObj.widgetId]).to : dashboardUtils.dashboardProperties.timespansObject[reportObj.widgetId+reportObj.id].timestampTo
            }
        }
        
        this.basicChartsRequest.fromDate = report.specificDates ? report.specificDates.from : reportObj?.inDashboard ? widgetFromTo.from : registerWidgetsStore.fromTo.from;
        this.basicChartsRequest.toDate = report.specificDates ? report.specificDates.to : reportObj?.inDashboard ? widgetFromTo.to : registerWidgetsStore.fromTo.to;

        this.basicChartsRequest.metrics = report.payload.metrics;
        this.basicChartsRequest.vessels = vessels;
        this.basicChartsRequest.xAxis = report.payload.xAxis;
        this.basicChartsRequest.aggregation = report.payload.aggregation;
        this.basicChartsRequest.timeGroup = report.payload.timeGroup;
        this.basicChartsRequest.withMetadata = report.payload.withMetadata;
        this.basicChartsRequest.withTimestampRange = report.payload.withTimestampRange;
        this.basicChartsRequest.withNavigationStatus = report.payload.withNavigationStatus;
        this.basicChartsRequest.withTelegramNavigationStatus = report.payload.withTelegramNavigationStatus;
        this.basicChartsRequest.withBoilerStatus = report.payload.withBoilerStatus;

        this.prerequisiteReportChart = { urlExtension, ifNoon };

        if(!report.innerPrerequisite) service.getReports(`/reports${urlExtension}`, this.basicChartsRequest, report.singleRequest.overwriteHeader ? report.singleRequest.overwriteHeader : `get-${ifNoon}highchart-report`).then(response => {
            if(response.data?.dateRange) response.data.series.push(response.data.dateRange);
            response.data
                ? report.singleRequest.overwriteHeader
                    ? setResponse(response.data, report.widgetId, reportObj) : setResponse(response.data.series, report.widgetId, reportObj)
                : setResponse([], report.widgetId, reportObj);
            if(updateRequestOnGoing) setTimeout(() => updateRequestOnGoing(report.widgetId, false, reportObj) );
        });
    }

    resolveEachReport(vessels, report, setResponse, updateRequestOnGoing, noNoon, groupedReportsIds, MaxGenericRequestNumber) {

        //  (1)  Concat generic request body for max first 6 components
        if(report.type !== 'basicChart' && !report.singleRequest?.value) {
            this.constructGenericChartsRequest(vessels, report, setResponse, updateRequestOnGoing, noNoon, groupedReportsIds, MaxGenericRequestNumber);
        }


        //  (2)   Do separated requests for more than 6 components one by one! ?
        if(report.type !== 'basicChart' && report.singleRequest?.value && !report.singleRequest?.externalURequest?.value) {
            this.constructSeparateChartsRequest(vessels, report, setResponse, updateRequestOnGoing, noNoon)
        }


        //  (3)  Do single requests with theirs own request url one by one.
        if(report.type !== 'basicChart' && report.singleRequest?.value && report.singleRequest?.externalURequest?.value) {
            const params = report.singleRequest?.externalURequest.request().params;
            report.singleRequest.externalURequest.request().method(params).then(response => {
                setResponse(response, report.widgetId, report);
                if(updateRequestOnGoing) setTimeout(() => updateRequestOnGoing(report.widgetId, false, report) );
            });
        }


        //  (4)   Do basicChart requests one by one.
        if(report.type === 'basicChart') {
            this.constructBasicChartsRequest(vessels, report, setResponse, updateRequestOnGoing, noNoon);
        }
    }

    registerLegs({id, from, to}) {
        return new Promise(resolve => {
            service.getLegs(id, from, to).then(response => {
                resolve(response.data);
            });
        });
    }
}

export default ReportsService;
