
import React from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const mainEngineFuelLineChart = (props) => {
    return(
        <div className = "me-load-fuel-wrapper">
            {props.data !== "" && (
                <HighchartsReact highcharts={Highcharts} options={props.data} constructorType={"stockChart"} />
            )}
        </div>
    )
}

export default mainEngineFuelLineChart;