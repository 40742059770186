import { eventsStore } from "../storeUtils";
import service from "common/js/service";
import _ from "lodash";
import { vesselUtils } from 'common/store/storeUtils';
import store from "../store";

const eventsUtils = {
    // event mappers
    cleaningEventTypes: Object.freeze({
        "drydock": "DRYDOCK",
        "sea": "SEA"
    }),
    eventTypes: Object.freeze({
        DTELEGRAM: 'DTELEGRAM',
        ATELEGRAM: 'ATELEGRAM',
        NTELEGRAM: 'NTELEGRAM',
        OTELEGRAM: 'OTELEGRAM',
        4: 'DRYDOCK',
        5: 'SOFTCLEAN',
        6: 'HARDCLEAN',
        7: 'REPAINTING',
        VESSEL_MAINTENANCE: 'MAINTENANCE',
        11: 'POLISHING',
        8: 'CHARTERALERT',
        VESSEL_CHARTER_AGREEMENTS: 'VESSELCHARTER',
        VESSEL_BUNKERING: 'BUNKERING',
        VESSEL_MACHINERY_DAMAGES: 'DAMAGES'
    }),

    // event actions
    getVesselGroupEvents: async ({value, startingDate, endingDate}) => {
        const result = await service.getVesselGroupEvents({value, startingDate, endingDate});
        
        if (!result.error) store.dispatch({ type: 'events/setEvents', payload: result });

        return result;
    },
    eventSaveAction: async (dispatch, {event}) => {
        let result = null;

        if (!event.id) {
            result = event.telegramType ? await service.createTelegram({event}) : await service.createEvent({event});
            
            if (!result.error && vesselUtils.getFleetVessels(dispatch).find(vessel => vessel.vesselId === event.vesselId)) {
                dispatch({ type: 'events/setEvents', payload: [...eventsStore().events, result[0]] });
                dispatch({ type: 'events/setEventsUpdated', payload: true });
            }
        } else {
            result = event.telegramType ? await service.updateTelegram({event}) : await service.updateEvent({event})
            if (result && !result.error) {
                dispatch({ type: 'events/setEvents', payload: eventsStore().events.map(e => parseInt(e.id) === parseInt(result[0].id) ? result[0] : e) });
                dispatch({ type: 'events/setEventsUpdated', payload: true });
            }
        }
        return result;
    },
    eventDeleteAction: async (dispatch, {event}) => {
        const result = event.telegramType ? await service.deleteTelegram({event}) : await service.deleteEvent({event});

        if (!result.error) {
            const index = eventsStore().events.findIndex(e => parseInt(e.id) === parseInt(event.id));
            dispatch({ type: 'events/setEvents', payload: [...eventsStore().events.slice(0, index), ...eventsStore().events.slice(index + 1)] });
            dispatch({ type: 'events/setEventsUpdated', payload: true });
        }
        return result;
    },
    updateDeleteTelegramObject: (event) => {
        if (event.type.indexOf('TELEGRAM') !== -1) {
            event.telegramType = event.type.substring(0, 1);
        }
        return event;
    },

    updateDeleteObject: (event) => {
        _.keys(event).forEach((key) => {
            (event[key] === '-') && (event[key] = '');
        })
        eventsUtils.updateDeleteTelegramObject(event);
        return event;
    },

    getEventTypeById(id) {
        return eventsUtils.eventTypes[id];
    },

    getEventIdByType: (type) => {
        const array = Object.keys(eventsUtils.eventTypes).filter(id => eventsUtils.eventTypes[id] === type);
        return array.length === 1 ? array[0] : null;
    }
}

export default eventsUtils;