import Utilities from "../../Utilities/global";
import highstockBarChartOptions from "components/charts/barChart/highstock-barChart";
import {licensing} from "../../common/store/licensing"; 
import moment from "moment";
import { BoilersUtils } from "Utilities/boilers";
import boStorage from "components/monitoringCategories/localStorages/boStorage";
import keys from 'lodash/keys';
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import { monitoringStore, vesselUtils } from 'common/store/storeUtils';
import GlobalUtilities from "../../Utilities/global";

// 'allSeriesData' is used in case we use this widget in the dashboard and we have multiple instances of it for different vessels (it assigns to each vessel the appropriate series data)
let boilersSubIds = [], seriesData = [], allSeriesData = {}, noBoilersConfig = {};
const barColors = [ThemeColorsForCharts()['sensor_color'], ThemeColorsForCharts()['second_stack_sensor'], ThemeColorsForCharts()['third_stack_sensor']];

export const fuelBarChartPayload = {
    xAxis: "TIME",
    aggregation: "SUM",
    timeGroup: 'DAY',
    withMetadata: true,
    metrics: [
        {
            metricCategory: "BOILER",
            subIds: [],
            metricData: [
                {
                    metricName: "boilerFoMassRate"
                }
            ]
        },
    ]
};

export const fuelBarChartPayload_light = {
    xAxis: "TIME",
    aggregation: "SUM",
    timeGroup: 'DAY',
    metrics: [
        {
            metricCategory: "TELEGRAM",
            subIds: [],
            metricData: [{
                metricName: "bo_1_foConsumption_calc"
            }]
        }
    ]
};

const yAxisData = [
    {id: 'foBar-axis', title: {text: 'Fuel Consumption (mt/day)'}, yMin: 0, allowDecimals: false, min: 0, opposite: false}
];

export const seriesOptions = {
    AUXILIARY: {
        name: 'Sensor Auxiliary',
        legendTitle: 'Sensor Auxiliary',
        data: [],
        tooltipTitle: 'Auxiliary FC',
        yAxis: 'foBar-axis',
        tooltip: {valueSuffix: ' mt/day', valueDecimals: 2},
        type: 'column',
        yMin: 0,
        stack: 'normal',
    },
    COMPOSITE: {
        name: 'Sensor Composite',
        legendTitle: 'Sensor Composite',
        data: [],
        tooltipTitle: 'Composite FC',
        yAxis: 'foBar-axis',
        tooltip: {valueSuffix: ' mt/day', valueDecimals: 2},
        type: 'column',
        yMin: 0,
        stack: 'normal',
    }
}

const seriesDataLight = [
    {
        name: 'Report FC',
        legendTitle: 'Fuel Consumption',
        data: [],
        tooltipTitle: 'Report FC',
        yAxis: 'foBar-axis',
        tooltip: {valueSuffix: ' mt/day', valueDecimals: 2},
        type: 'column',
        yMin: 0
    }
];

export const setBoilersFuelBarTypes = (data, widget) => {
    boilersSubIds = [];
    data && data.filter(item => item.type && item.subId && BoilersUtils.acceptedBoilerTypesFuel.includes(item?.type)).forEach(obj => {
        if(obj.type && obj.subId) {
            const tempObj = {subId: obj.subId, type: obj.type};
            boilersSubIds.push(tempObj);
        }
    });

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    (!boilersSubIds.length && !isLight) ? noBoilersConfig[widget?.id] = true : noBoilersConfig[widget?.id] = false;

    setFuelBarChartSeries(boilersSubIds, widget);
};

export const boilersFuelBarUpdatePayload = (widget) => {
    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
    if(isLight) return fuelBarChartPayload_light;

    fuelBarChartPayload.metrics.forEach(metric => {
        const tempSubIds = [];
        boilersSubIds.forEach((item) => {
            tempSubIds.push(item.subId);
        })
        metric.subIds = tempSubIds;
    });

    return fuelBarChartPayload;
}

const changeTooltipTitlesIfNeeded = (type, boilerTypeInfoObject, seriesOptionTemp) => {
    if(boilerTypeInfoObject[type].total > 1 && boilerTypeInfoObject[type].current <= boilerTypeInfoObject[type].total) {
        let capitalizedFirstLetterType = type?.toLowerCase()?.replace(/^\w/, c => c?.toUpperCase());
        seriesOptionTemp.name = `Sensor ${capitalizedFirstLetterType} ${boilerTypeInfoObject[type].current}`;
        seriesOptionTemp.legendTitle = `Sensor ${capitalizedFirstLetterType} ${boilerTypeInfoObject[type].current}`;
        seriesOptionTemp.tooltipTitle = `${capitalizedFirstLetterType} ${boilerTypeInfoObject[type].current} FC`;
        boilerTypeInfoObject[type].current++;
    }
}

const setFuelBarChartSeries = (boilersSubIdsLocal, widget) => {
    seriesData = [];
    const boilerTypeInfoObject = {          // info needed to change the tooltip and legend titles in order to include the appropriate index
        AUXILIARY: {total: boilersSubIdsLocal.filter(item => item.type === 'AUXILIARY').length, current: 1},
        COMPOSITE: {total: boilersSubIdsLocal.filter(item => item.type === 'COMPOSITE').length, current: 1}
    }
    boilersSubIdsLocal.forEach(item => {
      if(keys(seriesOptions).includes(item.type)) {
        const seriesOptionTemp = {...seriesOptions[item.type], metricName: `boilerFoMassRate${item.subId}`};
        changeTooltipTitlesIfNeeded(item.type, boilerTypeInfoObject, seriesOptionTemp);
        seriesData.push(seriesOptionTemp);
      }
    });

    allSeriesData[widget?.id] = seriesData;
}

const setBarTablesDataFormat = (barsTableData, fuelBarChartJson, isLight) => {
    const { from, to } = GlobalUtilities.getDetailedGranularityPeriod();    
    let tmpObj = {};
    barsTableData.forEach(obj => {
        obj.value = [...obj.value.concat(obj.missingData)];
        tmpObj[`${obj.metricName}${obj.subId}`] = (
            obj.value.map(point => point = {
                x: point?.x,
                y: isLight ? parseFloat(point?.y) : point?.y/1000,
                missingValues: !isLight && point?.missingValues > Utilities.missingValuesLimit && point?.missingValues.toFixed(1),
                showTotal: !isLight && fuelBarChartJson?.series?.length > 1,
                color: !isLight && point?.missingValues > Utilities.missingValuesLimit && ThemeColorsForCharts()['missing_first_stack_sensor'],
                reportPeriod: isLight ?
                    moment(point?.period?.timestampFrom).utc().format('DD/MM HH:mm') + ' - ' + moment(point?.period?.timestampTo).utc().format('DD/MM HH:mm')
                    : '',
                period: (from && to ) && GlobalUtilities.periodString(barsTableData[0], point.x, point.y, {from, to})
            })
        )
    });

    return tmpObj;
};



export const updateFuelBarChart = async (id, data, updateState, extraChartConfigs, widget) => {
    boStorage.setFuelBarChartData(data);

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    if(isLight) noBoilersConfig[widget?.id] = false;
    if(noBoilersConfig[widget?.id]) return updateState(id, {noBoilersConfig: noBoilersConfig[widget?.id]}, widget?.id);

    let fuelBarChartJson = { ...highstockBarChartOptions(":%d/%m", null) };

    fuelBarChartJson.chart = {...fuelBarChartJson.chart, height: 573, marginTop: 150, marginLeft: 81, marginRight: 38};
    fuelBarChartJson.yAxis = [...yAxisData];
    fuelBarChartJson.series = isLight ? JSON.parse(JSON.stringify(seriesDataLight)) : widget?.inDashboard ? JSON.parse(JSON.stringify(allSeriesData[widget?.id])) : JSON.parse(JSON.stringify(seriesData));
    fuelBarChartJson.xAxis = {...fuelBarChartJson.xAxis, title: { text: "Days", y: 12 }, offset: 18, lineColor: 'transparent'};

    fuelBarChartJson.plotOptions.column.stacking = 'normal';
    fuelBarChartJson.plotOptions.column.pointWidth = 29;
    fuelBarChartJson.plotOptions.column.borderRadius = 0;

    fuelBarChartJson.exporting.filename = 'BOILERS DAILY FUEL CONSUMPTION';

    fuelBarChartJson.tooltip = {...fuelBarChartJson.tooltip, split: false, xDateFormat: '%d/%m', useHTML: true};
    fuelBarChartJson.tooltip.formatter = function() {
        return (`
        <div class="flex-centered-col">
            <div class="highcharts-fonts tooltip-title"><b>${!isLight 
                ? (monitoringStore().lessThanADayPeriod.status || monitoringStore().detailedData.status) 
                    ? this.point.period
                    : moment(this.point.x).utc().format('DD/MM') 
                : this.point.reportPeriod
                }</b>
            </div>

            <div class="flex-centered-col tooltip-values" style="width: 100%;">
                <div class="flex-space-between highcharts-fonts" style="width: 100%; ${!this.point.showTotal && 'display:none'}" id="${this.point.stackTotal}">
                    <div class="flex-centered">
                        <div class="tooltip-circle" style="background-color:var(--primary_text);"></div>
                        <div>Total FC: &nbsp;</div>
                    </div>
                    <div><b>${this.point.total.toFixed(2)} mt/day</b></div>
                </div>

                <div class="flex-space-between highcharts-fonts" style="width: 100%;" id="${this.series.name}">
                    <div class="flex-space-between">
                        <div class="tooltip-circle" style="background-color:${this.series.color};"></div>
                        <div>${this.series.userOptions.tooltipTitle}: &nbsp;</div>
                    </div>
                    <div><b>${this.point.y.toFixed(2)} mt/day</b></div>
                </div>

                <div class="highcharts-fonts" id="${this.series.name}" style="width: 100%; text-align:left; ${!this.point.missingValues ? 'display:none' : 'display:block'}">
                    <div style="margin-left: 12px;">${this.point.missingValues > 0 ? this.point.missingValues + '% missing data' : ''}</div>
                </div>
                <div class="arrow-down" style="border-top-color:${this.point.backgroundColor}"></div>
            </div>
        </div>
        `)
    };
    
    fuelBarChartJson.legend = {
        enabled: true,
        align: 'center',
        layout: 'horizontal',
        verticalAlign: 'top',
        itemMarginTop: 45,
        itemMarginBottom: 15,
        itemDistance: 37,
        itemStyle: {
            "fontSize": "11px",
            "fontWeight": "600"
        }
    };

    const barsTableData = (data[0]?.values) ? setBarTablesDataFormat(data[0]?.values, fuelBarChartJson, isLight) : [];
    const lightMetricFound = keys(barsTableData).find((metricName) => metricName.startsWith('bo_1_foConsumption_calc'));

    // assign the correct series depending on the metricName
    if(lightMetricFound && fuelBarChartJson.series[0]) {  // light case
        fuelBarChartJson.series[0].data = barsTableData[lightMetricFound];
        fuelBarChartJson.series[0].color = ThemeColorsForCharts()['reports_color'];
    }
    else {                                                                                    // premium case
        fuelBarChartJson.series.forEach((serie, i) => { // if we have total consumption, then series.data[0] should not change again, since it'a already initialized with the correct data
            serie.data = barsTableData[serie.metricName] ? [...barsTableData[serie.metricName]] : []
            serie.color = isLight ? ThemeColorsForCharts()['reports_color'] : barColors[i];
        });
    }

    fuelBarChartJson.chart.events = {
        load: function () {
            if (moment(registerWidgetsStore.fromTo.to).diff(moment(registerWidgetsStore.fromTo.from), 'days') > 7) {
                const chart = this,
                    series = [...this.series[0]?.xData, ...this.series[1]?.xData].sort(function(a, b){
                        return moment.utc(a).diff(moment.utc(b))
                    }),
                    xAxis = chart.xAxis[0],
                    newStart = series[series.length - 7],
                    newEnd = series[series.length - 1];

                // if the from-to difference is larger than 7 days, we get the whole datetime and configure zoom accordingly
                if (moment(registerWidgetsStore.fromTo.to).diff(moment(registerWidgetsStore.fromTo.from), 'days') > 7) xAxis.setExtremes(newStart, newEnd);
            }


            // For detailed data, display only the day's centered column
            if (moment(registerWidgetsStore.fromTo.to).diff(moment(registerWidgetsStore.fromTo.from), 'days') < 1 && !isLight) {
                if(fuelBarChartJson?.series[0]) fuelBarChartJson.series[0].centerInCategory = true;
                if(fuelBarChartJson?.series[1]) fuelBarChartJson.series[1].centerInCategory = true;
            }
        }
    }

    const chartHasData = fuelBarChartJson.series.find(serie => serie?.data?.length > 0);
    if(!chartHasData) fuelBarChartJson = {};

    updateState(id, fuelBarChartJson, widget?.id);
}
