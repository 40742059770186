import React from 'react';
import CircleProgressChart from 'components/charts/circleProgreesChart/circleProgressChart';
import classNames from 'classnames';

const PercentageInfo = props => {
    const getClassNames = () => {
        return classNames('percentage-info flex-centered-col', {'smallInfo': props?.legReportsWidget}, {'bigInfo': !props?.legReportsWidget});
    }

    return (
        <div className={getClassNames()}>
            <CircleProgressChart progressChartOptions={props.progressChartOptions} />
        </div>        
    );
}
 
export default PercentageInfo;