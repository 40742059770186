import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const DatePickerLoader = () => {
    const week = (
        <div className = 'calendarWeek'>
            {[1, 2, 3, 4, 5, 6, 7].map((name) => {
                 return <Skeleton key={name} className="datePickerSkeleton" animation="wave" variant="circle" height={27} width={27} />
            })}
        </div>
    )
    const lastWeek = (
        <div className = 'calendarWeek'>
             {[1, 2, 3].map((name) => {return <Skeleton key={name} className="datePickerSkeleton" variant="circle" animation="wave" height={27} width={27} />})}
        </div>
    )
    const month = (
        <div className = 'calendarYear'>
            {week} {week} {week} {week} {lastWeek}
        </div>
    )
    return month;
}

export default DatePickerLoader;