import React from 'react';
import {ReactComponent as BoilerRunningTime} from 'assets/img/app/monitoring-imgs/boiler-running-time.svg';
import Utilities from "Utilities/global";
import {CircularProgressbarWithChildren} from "react-circular-progressbar";

const RunningTime = props => {

    const runningTimeStyles = element => {
        return element?.missingData > Utilities.missingValuesLimit ? props.data?.missingDataStyle : props.data?.sensorsStyle;
    }

    return (
        <div className="boiler-running-time d-flex align-items-center flex-column justify-content-around">
            {props.data?.data?.map(element => (
                <div className="boiler-running-time__container d-flex align-items-center" key={element.subId}>
                    <BoilerRunningTime className="svg-g-path marg-r-15"/>
                    <div className="boiler-running-time__container__info marg-r-35">
                        <div
                            className="boiler-running-time__container__info__hours report-big">{Utilities.renderNumberWithNoZerosAfterDigit(element.hours, 1)} hrs
                        </div>
                        <div className="boiler-running-time__container__info__type headline2">{element.label}</div>
                        {(element.missingData && element.missingData > Utilities.missingValuesLimit) ?
                        <div className="boiler-running-time__container__info__missingData form-text">
                            ({Utilities.renderNumber(Utilities.roundToDigits(element.missingData, 2))}% Missing Data)
                        </div> : <></>}
                    </div>
                    <div className="circular-chart" style={{width: "125px"}}>
                        <CircularProgressbarWithChildren value={element.hoursPercentage} strokeWidth={5}
                                                         styles={runningTimeStyles(element)}>
                            <div className="progressBar-text">
                                <div className={`activity-value ${element.missingData > Utilities.missingValuesLimit ? 'missingData' : 'sensor'}`}>
                                    {Utilities.renderNumber(Utilities.roundToDigits(element.hoursPercentage, 2))}%
                                </div>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default RunningTime
