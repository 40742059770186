import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const PieChart = (props) => {
    return (
        <HighchartsReact options={props} highcharts={Highcharts} />
    );
}

export default PieChart;
