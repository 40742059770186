import React from 'react';
import HighchartsReact from "highcharts-react-official";
import Highstock from 'highcharts/highstock';

const DeviationBarChart = props => {
    return (
        <div className="main-panel deviationBarChart" style={props.style}>
            <div className="main-panel__body">
                <HighchartsReact options={props.data} constructorType={"stockChart"} highcharts={Highstock} />
            </div>
        </div>
    );
}

export default DeviationBarChart;
