const hullStorage = {
    powerSpeedData: null,
    fuelSpeedData: null,
    powerSpeedScatterPlots: null,
    fuelSpeedScatterPlots: null,
    powerMonitoringData: null,
    hullInfoData: null,
    foulingPenalty: null,
    foulingWasteData: null,

    setPowerSpeedData: (data) => hullStorage.powerSpeedData = data,
    setFuelSpeedData: (data) => hullStorage.fuelSpeedData = data,
    setPowerSpeedScatterPlots: (data) => hullStorage.powerSpeedScatterPlots = data,
    setFuelSpeedScatterPlots: (data) => hullStorage.fuelSpeedScatterPlots = data,
    setPowerMonitoringData: (data) => hullStorage.powerMonitoringData = data,
    setHullInfoData: (data) => hullStorage.hullInfoData = data,
    setFoulingPenalty: (data) => hullStorage.foulingPenalty = data,
    setFoulingWasteData: (data) => hullStorage.foulingWasteData = data,

    resetHullStorage: () => {
        hullStorage.setPowerSpeedData(null);
        hullStorage.setFuelSpeedData(null);
        hullStorage.setPowerSpeedScatterPlots(null);
        hullStorage.setFuelSpeedScatterPlots(null);
        hullStorage.setPowerMonitoringData(null);
        hullStorage.setHullInfoData(null);
        hullStorage.setFoulingPenalty(null);
        hullStorage.setFoulingWasteData(null);
    },

    isGlobalStateEmpty: () => {
        return !hullStorage.powerSpeedData
            || !hullStorage.powerSpeedScatterPlots
            || !hullStorage.powerMonitoringData || !hullStorage.hullInfoData
            || !hullStorage.foulingPenalty;
    }
}

export default hullStorage;
