import Utilities from "Utilities/global";
import moment from "moment";

const tooltipContent = ({from, to}) => {
	return {
		formatter: function () {
			if (
				!this.point.totalFuel &&
				!this.point.totalCO2 &&
				!this.point.totalMiles
			)
				return null;
			return `
				<div class="cii-vessel-overview-tooltip flex-centered-col p-2">
					<div class="highcharts-fonts tooltip-title">
						${moment(from).utc().format("DD/MM/YYYY HH:mm")} - ${moment(to).utc().format("DD/MM/YYYY HH:mm")}
					</div>
					<div class="tooltip-values">
						<div class="flex-space-between highcharts-fonts">
							<div>Total Fuel Consumption:</div>
							<div class="reportsData">${Utilities.renderNumber(Utilities.roundToDigits(this.point.totalFuel,2))} mt</div>
						</div>
						<div class="flex-space-between highcharts-fonts">
							<div>Total CO2 Emissions:</div>
							<div class="reportsData">${Utilities.renderNumber(Utilities.roundToDigits(this.point.totalCO2,2))} mt</div>
						</div>
						<div class="flex-space-between highcharts-fonts">
							<div>Miles Traveled:</div>
							<div class="reportsData">${Utilities.renderNumber(Utilities.roundToDigits(this.point.totalMiles,2))} nm</div>
						</div>
					</div>
				</div>
			`;
		}
	}
};

const ciiVesselOverviewUpdate = (
	id,
	response,
	updateState,
	extraChartConfigs,
	widget,
	dispatch
) => {
	if (response.data) {
		response.data.tooltip = tooltipContent({from: response.data.dateFrom, to: response.data.dateTo});
	}

	updateState(id, response.data || {}, widget?.id);
};

export { ciiVesselOverviewUpdate };
