import React from "react";
import GetImageComponent from "../getImageComponent/getImageComponent";
import EventsUtilities from 'Utilities/events';
import { calendarUtils } from "common/store/storeUtils";

const EventTypeCard = props => {
    const title = EventsUtilities.eventCardLeftInfo(props).title;
    const subtitle = EventsUtilities.eventCardLeftInfo(props).subtitle;
    const rightInfo = EventsUtilities.eventCardRightInfo(props);
    const bgColor = calendarUtils.Filters().filter(filter => filter.id === props.originalType)[0].bgcolor;

    return (
        <div className='eventTypeCard flex-space-between marg-b-15 pointer'
             onClick={(e) => props.handleEventClick(e, props)}>

            { props.description && props.date && props.activeEvent
            && props.vEvent.description === props.description && props.vEvent.date === props.date && <div className="eventTypeCard__active"/> }

            <div className='eventTypeCard__left d-flex'>
                <div className='eventTypeCard__left__icon flex-centered' style={bgColor}>
                    <GetImageComponent imageName={props.originalType}/>
                </div>
                <div className='eventTypeCard__left__text'>
                    <div className="eventTypeCard__left__text__main headline2 marg-b-10">{title}</div>
                    <div className="eventTypeCard__left__text__helper">{subtitle}</div>
                </div>
            </div>
            <div className='eventTypeCard__right flex-space-around'>
                {rightInfo.map((element, index) => (
                    <div className='eventTypeCard__right__item' key={index}>
                        {element.image}
                        <div className='eventTypeCard__right__item__helper'>{element.title}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EventTypeCard;
