const Wind = {

    noWindData: (obj) => {
        if(!obj) return true;
        return !obj?.value || obj?.value?.length === 0;
    },

    findWindSpeedWithPriority:  (data, metricsArray, series, seriesIndex) => {
        let windSpeed = data[0].values.find((keyGp) => keyGp.metricName === metricsArray[0]);
        
        if(Wind.noWindData(windSpeed) && metricsArray[1]) {
            windSpeed = data[0].values.find((keyGp) => keyGp.metricName === metricsArray[1]);
            if(Wind.noWindData(windSpeed) && metricsArray[2]) windSpeed = data[0].values.find((keyGp) => keyGp.metricName === metricsArray[2]);
        }
        series[seriesIndex].data = windSpeed ? windSpeed?.value : [];
        series[seriesIndex].unit = windSpeed ? windSpeed?.unit : [];
    },

    getPrioritizedWindSpeedMetric: (metrics, object) => {
      const windSpeedMetric1 = object?.values?.find((keyGp) => keyGp?.metricName === metrics[0]);
      const windSpeedMetric2 = object?.values?.find((keyGp) => keyGp?.metricName === metrics[1]);
      const windSpeedMetric3 = object?.values?.find((keyGp) => keyGp?.metricName === metrics[2]);
      if(windSpeedMetric1?.value || windSpeedMetric1?.value === 0) return windSpeedMetric1?.value;
      if(windSpeedMetric2?.value || windSpeedMetric2?.value === 0) return windSpeedMetric2?.value;
      return windSpeedMetric3?.value;
    },

    getConvertedWindSpeed(windSpeedCorrected, apparentWindSpeed, trueWindSpeedCalc) {
      if(windSpeedCorrected || windSpeedCorrected === 0) return Wind.knotsToBeauforts(windSpeedCorrected);
      if(apparentWindSpeed || apparentWindSpeed === 0) return Wind.knotsToBeauforts(apparentWindSpeed);
      return Wind.knotsToBeauforts(trueWindSpeedCalc);
    },

    msToBeauforts : value => {
      if( value === 0 ) return 0;
      if( !value ) return '-';
      if( value < 0.5 ) return 0;
      if( value >= 0.5 && value <= 1.5 ) return 1;
      if( value > 1.5 && value <= 3.3 ) return 2;
      if( value > 3.3 && value <= 5.5 ) return 3;
      if( value > 5.5 && value <= 7.9 ) return 4;
      if( value > 7.9 && value <= 10.7 ) return 5;
      if( value > 10.7 && value <= 13.8 ) return 6;
      if( value > 13.8 && value <= 17.1 ) return 7;
      if( value > 17.1 && value <= 20.7 ) return 8;
      if( value > 20.7 && value <= 24.4 ) return 9;
      if( value > 24.4 && value <= 28.4 ) return 10;
      if( value > 28.4 && value <= 32.6 ) return 11;
      if( value > 32.6) return 12;
      return '-';
    },

    knotsToBeauforts: value => {
        if( value === 0 ) return 0;
        if( !value ) return '-';
        if( value < 1.0 ) return 0;
        if( value >= 1.0 && value <= 3.0 ) return 1;
        if( value > 3.0 && value <= 6.0 ) return 2;
        if( value > 6.0 && value <= 10.0 ) return 3;
        if( value > 10.0 && value <= 16.0 ) return 4;
        if( value > 16.0 && value <= 21.0 ) return 5;
        if( value > 21.0 && value <= 27.0 ) return 6;
        if( value > 27.0 && value <= 33.0 ) return 7;
        if( value > 33.0 && value <= 40.0 ) return 8;
        if( value > 40.0 && value <= 47.0 ) return 9;
        if( value > 47.0 && value <= 55.0 ) return 10;
        if( value > 55.0 && value <= 63.0 ) return 11;
        if( value > 63.0 ) return 12;
        return '-';
    },

    convertApparentWindAngle: value => {
      if(value === null || value === false || value === undefined) return '-';
      if( value >= 0.0 && value <= 30.0 ) return 'Side';
      else if( value > 30.0 && value <= 120 ) return 'Head';
      else if( value > 120.0 && value<= 210.0 ) return 'Side';
      else if( value > 210.0 && value <= 320.0 ) return 'Tail';
      else if( value > 320.0 && value < 360.0 ) return 'Side';
      else return '-';
    },

    darkThemeColorsScale: () => [
      "rgb(36,104, 180)",
      "rgb(60,157, 194)",
      "rgb(128,205,193 )",
      "rgb(151,218,168 )",
      "rgb(198,231,181)",
      "rgb(238,247,217)",
      "rgb(255,238,159)",
      "rgb(252,217,125)",
      "rgb(255,182,100)",
      "rgb(252,150,75)",
      "rgb(250,112,52)",
      "rgb(245,64,32)",
      "rgb(237,45,28)",
      "rgb(220,24,32)",
      "rgb(180,0,35)"
    ],

    whiteThemeColorScale: () => [
      "rgb(0,210,255)",
      "rgb(31,195,229)",
      "rgb(0,177,255)",
      "rgb(0,100,255)",
      "rgb(0,33,255)",
      "rgb(73,68,248)",
      "rgb(122,17,219)",
      "rgb(149,4,210)",
      "rgb(217,126,255)",
      "rgb(220,52,255)",
      "rgb(255,0,211)",
      "rgb(227,33,131)",
      "rgb(255,0,50)",
      "rgb(222,0,44)",
      "rgb(125,1,26)"
    ],
    
}

export default Wind;
