import Wind from 'Utilities/wind';
import Utilities from 'Utilities/global';
import {licensing} from "../../common/store/licensing";
import {reportsStore, vesselUtils} from 'common/store/storeUtils';


const averageWindPayload = {
    metrics: [
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                { metricName: "apparent_wind_speed_calc", aggregation: "AVG" },
                { metricName: "apparent_wind_speed", aggregation: "AVG" },
                { metricName: "true_wind_speed_calc", aggregation: "AVG" },
                { metricName: "apparent_wind_direction_calc", aggregation: "AVG"},
                { metricName: "apparent_wind_angle", aggregation: "AVG"},
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                { metricName: "ve_1_wind_speed_avg", aggregation: "AVG" },
                { metricName: "ve_1_wind_angle_avg", aggregation: "AVG" },
            ]
        }
    ]
};


const averageWind = (id, response, updateState, extraChartConfigs, widget) => {
    const data = [{
        values: [{}, {}, {}, {}]
    }];

    if(response?.series?.length > 0) response.series[0].values.forEach(value => {
        //speeds
        if(value.metricName === "ve_1_wind_speed_avg") data[0].values[1] = value;
        
        //directions
        if(value.metricName === "ve_1_wind_angle_avg") data[0].values[3] = value;
    });

    data[0].values[0] = Wind.getPrioritizedWindSpeedMetric(["apparent_wind_speed_calc", "apparent_wind_speed", "true_wind_speed_calc"], response.series[0]);
    data[0].values[2] = Wind.getPrioritizedWindSpeedMetric(["apparent_wind_direction_calc", "apparent_wind_angle"], response.series[0]);

    const hasEmptyData = Utilities.hasNotValue(data[0]?.values[0])
     && Utilities.hasNotValue(data[0]?.values[1]?.value)
     && Utilities.hasNotValue(data[0]?.values[2])
     && Utilities.hasNotValue(data[0]?.values[3]?.value);
     
    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
     
    let updateStateData = {};
    
    if (!hasEmptyData){
        let firstPart = [
            {
                label: 'Speed', 
                value: data[0]?.values[0] ? (Utilities.renderNumber(Wind.knotsToBeauforts(data[0]?.values[0])))  + ' Bft' : "-",
                color: isLight ? "ais" : "sensorsData" 
            },
            {
                label: 'Direction', 
                value: data[0]?.values[2] ? Wind.convertApparentWindAngle(data[0]?.values[2]) : "-",
                color: isLight ? "ais" : "sensorsData" 

            },
        ]

        const secondPart = reportsStore().isTelegramConfigured ? [
            {
                label: 'Speed',
                value: data[0]?.values[1]?.value ? (Utilities.renderNumber(Utilities.roundToDigits(data[0]?.values[1]?.value, 0))) + ' ' + (data[0]?.values[1]?.unit || '') : "-",
                color:"reportsData"
            },
            {
                label: 'Direction', 
                value: data[0]?.values[3]?.value ? Wind.convertApparentWindAngle(data[0]?.values[3]?.value) : "-", 
                color:"reportsData"
            },
        ] : null

        if (isLight && !data[0]?.values[0] && !data[0]?.values[2]) firstPart = null

        updateStateData = {
            firstPart,
            secondPart,
            firstDevider: firstPart && secondPart ? true : false,
        };
    }

    updateState(id, updateStateData, widget?.id);
};



export {averageWindPayload, averageWind};
