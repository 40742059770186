import { keys } from 'lodash'
import ThemeColors from 'sass/_themeColorsForJs.scss';
import syncChartsOptions from 'components/charts/genericSyncCharts/syncChartsOptions';
import Utilities from "Utilities/global";
import moment from 'moment';

let ciiBarsSeriesMaxValue = null,
    plotLinesMaxValue = null,
    accumulatedSeriesMaxValue = null;

const getTooltipFormat = (granularity) => {
	return {
        pointFormatter: function () {
            return `
                <div class="flex-centered-col" style="background-color: transparent">
                    <div class="highcharts-fonts">
                        ${moment(this.options.dateFrom).utc().format('DD/MM/YYYY')}
                        ${' - '}
                        ${moment(this.options.dateTo).utc().format('DD/MM/YYYY')}
                    </div>
                    <div class="flex-centered-col tooltip-values">
                        <div class="d-flex flex-column justify-content-between">
                            <div class="highcharts-fonts tooltip-title flex-centered flex-row">
                                <div class="tooltip-circle" style="background-color:${this.options.color};"></div>
                                ${this.series.name === "Accumulated CII" ? "Accumulated" : granularity} CII: Class ${this.options.vesselClass}
                            </div>
                            <div class="tooltip-values">
                                <div class="flex-space-between highcharts-fonts">
                                    <div>CII Rating:</div>
                                    <div class="reportsData ml-4">${Utilities.renderNumber(Utilities.roundToDigits(this.options.y, 2))}</div>
                                </div>
                                <div class="flex-space-between highcharts-fonts">
                                    <div>Fuel Consumption:</div>
                                    <div class="reportsData ml-4">${Utilities.renderNumber(Utilities.roundToDigits(this.options.totalFuel, 2))} mt</div>
                                </div>
                                <div class="flex-space-between highcharts-fonts">
                                    <div>CO2 Emissions:</div>
                                    <div class="reportsData ml-4">${Utilities.renderNumber(Utilities.roundToDigits(this.options.totalCO2, 2))} mt</div>
                                </div>
                                <div class="flex-space-between highcharts-fonts">
                                    <div>Miles Traveled:</div>
                                    <div class="reportsData ml-4">${Utilities.renderNumber(Utilities.roundToDigits(this.options.totalMiles, 2))} nm</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            `;
        }
    };
};

const getCustomLabel = (rating, ciiClass, source) => {
    return {
        y: -26,
        x: -26,
        align: 'right',
        useHTML: true,
        formatter: function() {
            return `
                <div class="wrapper-plot">
                    <div class="plot-label">CII ${rating}</div>
                    <div class="plot-source ${source}-bg"></div>
                </div>
            `
        },
        textAlign: 'center',
        rotation: 0,
        style: {
            backgroundColor: ThemeColors[`Class${ciiClass}`],
            color: ThemeColors['primary_text'],
            fontWeight: 'bold',
            borderRadius: '4px',
            zIndex: 10001
        }
    }
};

const chartYAxis = [{
    id: 'ciiFromReport',
    title: { text: 'CII From Report'},
    height: '180px',
    top: '10%',
    plotLines: {},
    allowDecimals: false,
    lineWidth: 0,
    offset: 2,
    opposite: false,
}];

const setPlotLines = response => {
    let currentClassColor;
    const plotLines = [];

    //get the current plotLine color
    keys(response.ciiClassLimits.thresholds).forEach(key => {
        if(response.telegrams.totalCii >= response.ciiClassLimits.thresholds[key].lower
        && response.telegrams.totalCii < response.ciiClassLimits.thresholds[key].upper) {
            currentClassColor = key;
        }
    });

    plotLines.push({
        color: ThemeColors['high_severity'],
        dashStyle: 'Dash',
        value: response['requiredCii'],
        width:2,
    });

    plotLines.push({
        color: ThemeColors[`Class${currentClassColor}`],
        dashStyle: 'Dash',
        value: response.telegrams['totalCii'],
        label: getCustomLabel(response.telegrams['totalCii'], currentClassColor, 'reports'),
        width:2,
    });

    //set plotlines max value for yAxis range purpose
    plotLinesMaxValue = response['requiredCii'] > response.telegrams['totalCii'] ? response['requiredCii'] : response.telegrams['totalCii'];

    return plotLines;
};

const getLegend = (props) => {
    return {
        labelFormatter : function () {
			return this.name === 'E'
            ? `<span>
                    Class ${this?.name} > ${Utilities.renderNumber(Utilities.roundToDigits(props[this?.name]?.lower, 1))}
                </span>`
            : this.name === "Accumulated CII"
                ? `<span>${this?.name}</span>`
                : `<span>
                    Class ${this?.name} ${Utilities.renderNumber(Utilities.roundToDigits(props[this.name]?.lower, 1))} - 
                    ${Utilities.renderNumber(Utilities.roundToDigits(props[this?.name]?.upper, 1))}
                </span>`;
		},
    }
};

const setSeries = response => {
    const navigatorSerieSeries = response.telegrams.series.CIIBars;
    const accumulatedCiiSeries = response.telegrams.series.Accumulated;

    let series = keys(response.ciiClassLimits.thresholds).map(threshold => {
        return { //tosa osa einai oi classes/thresholds
            name: threshold, //classes e.g. A, B, C, D, E
            color: ThemeColors[threshold],
            tooltipTitle: threshold, //classes
            data: [],
            titleSuffix: "",
            type: 'column',
            centerInCategory: true,
            tooltip: {valueSuffix: '', valueDecimals: 2},
            yMin: 0,
            min: 0,
            yAxis: 'ciiFromReport',
            maxPointWidth: 15,
            showInLegend: false,
            showInNavigator: false,
        };
    });

    //telegrams keys = months
    navigatorSerieSeries.forEach(point => {
        //set full class name e.g. ClassA
        const ciiClass = `Class${point.vesselClass}`;

        //keep the max y value of all the data for yAxis range purpose
        ciiBarsSeriesMaxValue = point.y > ciiBarsSeriesMaxValue ? point.y : ciiBarsSeriesMaxValue;

        //fix series (per class) data from response
        series.forEach((serie) => {
            if(serie.name === point.vesselClass) {
                serie.data.push({
                    color: ThemeColors[ciiClass],
                    name: ciiClass,
                    ...point,
                });
                serie.showInLegend = true;
                serie.color = ThemeColors[ciiClass];
            }
        });
    });

    // iterate line chart's data to set up colors and name for every point
    accumulatedCiiSeries.forEach(point => {
        const ciiClass = `Class${point.vesselClass}`;
        point.color = ThemeColors[ciiClass];
        point.name = ciiClass;
        accumulatedSeriesMaxValue = point.y > accumulatedSeriesMaxValue ? point.y : accumulatedSeriesMaxValue;
    });

    // line chart serie
    series.push({
        name: 'Accumulated CII',
        color: ThemeColors['reports_color'],
        tooltipTitle: 'Accumulated CII', //classes
        data: accumulatedCiiSeries,
        titleSuffix: "",
        type: 'line',
        tooltip: {valueSuffix: '', valueDecimals: 2},
        yMin: 0,
        min: 0,
        yAxis: 'ciiFromReport',
        showInLegend: true,
        showInNavigator: true,
    });

    return series;
};

export const vesselEfficiencyDataUpdate = (id, response, updateState, updateInnerState, innerUpdate, dispatch, granularity) => {
    const isAccumulatedEmpty = !response.data?.telegrams?.series?.Accumulated?.length;
    const isCiiEmpty = !response.data?.telegrams?.series?.CIIBars?.length;

    const efficiencyData = (isAccumulatedEmpty && isCiiEmpty) ? null : response.data;

    ciiBarsSeriesMaxValue = null;
    plotLinesMaxValue = null;
    accumulatedSeriesMaxValue = null;

    const defaultChartOptions = !efficiencyData ? {} : {...syncChartsOptions(true)};

    if (efficiencyData) {
        defaultChartOptions.chart.height = 400;
        defaultChartOptions.chart.type = 'column';
        defaultChartOptions.yAxis = chartYAxis;
        defaultChartOptions.series = setSeries(efficiencyData);
        defaultChartOptions.yAxis[0].plotLines = setPlotLines(efficiencyData);
        defaultChartOptions.yAxis[0].type = 'logarithmic';
        defaultChartOptions.yAxis[0].minorTickInterval = 0.1;
        defaultChartOptions.yAxis[0].minorGridLineColor = 'transparent';
        defaultChartOptions.xAxis.labels.step = 0;
        defaultChartOptions.xAxis.title.text = "Months";
        // minRange the default set up is 3600000 which means that the maximum zoom in is 1 hour
        // and the xAxis will display more than one value in the space of one hour if we have one bar
        // that's why we use minRange 1 to display only one label when we have one bar
        defaultChartOptions.xAxis.minRange = 1;
        defaultChartOptions.exporting = { enabled: false };
        defaultChartOptions.yAxis[0].max = Math.max(ciiBarsSeriesMaxValue, plotLinesMaxValue, accumulatedSeriesMaxValue);

        defaultChartOptions.yAxis[0].accessibility = {
            rangeDescription: 'Range: 0.1 to 1000'
        };

        defaultChartOptions.tooltip = {
            ...defaultChartOptions.tooltip,
            headerFormat: '',
            pointFormat: '',
            footerFormat: '',
            split: false,
            shape: 'rect',
            outside: true,
            ...getTooltipFormat(granularity === "WEEK" ? 'Weekly' : 'Monthly'),
        };

        defaultChartOptions.plotOptions.series.marker = {
            enabled: true,
            symbol: 'circle',
            radius: 4,
            lineWidth: 1,
        };

        defaultChartOptions.legend = {
            enabled: true,
            ...getLegend(efficiencyData.ciiClassLimits.thresholds),
            itemMarginBottom: 10,
            verticalAlign: 'top',
            align: 'center',
        };
		defaultChartOptions.granularity = granularity;
	}

    !updateState
        ? updateInnerState({...innerUpdate, options: defaultChartOptions})
        : updateState(id, defaultChartOptions)
};