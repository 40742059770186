import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {AlphaNumericField} from './';
import {ReactComponent as EyeShowIcon} from 'assets/img/app/global-imgs/eye_open.svg';
import {ReactComponent as EyeHideIcon} from 'assets/img/app/global-imgs/eye_closed.svg';


const PasswordField = props => {
    const [type, setType] = useState("password");
    const [showPassword, setShowPassword] = useState(false);
    const [inputProps, setInputProps] = useState({});

    const togglePasswordVisibility = () => {
        setType(showPassword ? "password" : "text");
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        setInputProps({
            ...props,
            type,
            extraImg: (
                <span className={`inputs-group-btn input-password ${props.disabled ? ' disabled' : ''}`} onClick={togglePasswordVisibility}>
                    { !showPassword 
                        ? <EyeShowIcon className="svg-g-path" />
                        : <EyeHideIcon className="svg-g-path" />
                    }
                </span>
            )
        });
        // eslint-disable-next-line
    }, [props, showPassword, type]);

    return <AlphaNumericField {...inputProps} />;
}

PasswordField.propTypes = {
    ...Field.propTypes,
    disabled: PropTypes.bool
};

PasswordField.defaultProps = {
    disabled: false,
};

export default PasswordField;