const isQa = window.location.href.includes('cassandra-qa') && window.location.href.includes('.internal');
const isDev = window.location.href.includes('cassandra-dev') && !window.location.href.includes('cassandra-devai') && window.location.href.includes('.internal');
const isStaging = window.location.href.includes('cassandra-staging') && window.location.href.includes('.internal');
const isDevai = window.location.href.includes('cassandra-devai') && window.location.href.includes('.internal');
const isDemo = window.location.href.includes('cassandra-demo') && window.location.href.includes('deepsea.ai');
const isLocalhost = window.location.href.includes('localhost');
const isProd = (window.location.href.includes('deepsea.ai') && window.location.href.includes('cassandra')
                && !window.location.href.includes('qa') 
                && !window.location.href.includes('dev')
                && !window.location.href.includes('demo')
                && !window.location.href.includes('devai')
                && !window.location.href.includes('staging')
                && !window.location.href.includes('localhost')); 

const HOST_ENV = 'dev';

export const Urls = {
    ServicesUrl: () => {
        let LoginURL = "https://home.deepsea.ai",
        redirectToAuthURL = "https://home.deepsea.ai", 
        BaseURL = "https://cassandra.deepsea.ai",
        SelectProduct;

        if (isDemo) {
            LoginURL = "https://home-demo.deepsea.ai";
            redirectToAuthURL = "https://home-demo.deepsea.ai";
            BaseURL = "https://cassandra-demo.deepsea.ai";
        }

        if (isQa) {
            LoginURL = "https://home-qa.deepsea.internal";
            redirectToAuthURL = "https://home-qa.deepsea.internal";
            BaseURL = "https://cassandra-qa.deepsea.internal";
        }

        if (isDev) {
            LoginURL = "https://home-dev.deepsea.internal";
            redirectToAuthURL = "https://home-dev.deepsea.internal";
            BaseURL = "https://cassandra-dev.deepsea.internal";
        }

        if (isDevai) {
            LoginURL = "https://home-devai.deepsea.internal";
            redirectToAuthURL = "https://home-devai.deepsea.internal";
            BaseURL = "https://cassandra-devai.deepsea.internal";
        }

        if (isStaging) {
            LoginURL = "https://home-staging.deepsea.internal";
            redirectToAuthURL = "https://home-staging.deepsea.internal";
            BaseURL = "https://cassandra-staging.deepsea.internal";
        }

        if (isLocalhost) {
            redirectToAuthURL = "http://localhost:3006";

            LoginURL = `https://home-${HOST_ENV}.deepsea.internal`;
            BaseURL = `https://cassandra-${HOST_ENV}.deepsea.internal`;

            // for production
            // LoginURL = `https://home.deepsea.ai`;
            // BaseURL = `https://cassandra.deepsea.ai`;

            // localhost with custom ip
            // BaseURL = "http://10.212.134.24:12200";
        }

        SelectProduct = LoginURL + '/selectProduct';

        return { LoginURL, BaseURL, SelectProduct, redirectToAuthURL }
    },
    CookieUrl: () => {
        if(isQa || isDev || isStaging || isDevai) return 'deepsea.internal';
        if(isProd || isDemo) return 'deepsea.ai';
        if(isLocalhost) return '';
    },
    PythiaUrl: () => {
        if(isLocalhost) return 'http://localhost:3001';
        if(isQa) return 'https://pythia-qa.deepsea.internal';
        if(isDev ) return 'https://pythia-dev.deepsea.internal';
        if(isStaging ) return 'https://pythia-staging.deepsea.internal';
        if(isDevai ) return 'https://pythia-devai.deepsea.internal';
        if(isProd) return 'https://pythia.deepsea.ai';
        if(isDemo) return 'https://pythia-demo.deepsea.ai';
    },
    AmsUrl: () => {
        if(isLocalhost) return 'http://localhost:3010';
        if(isQa) return 'https://ams-qa.deepsea.internal';
        if(isDev ) return 'https://ams-dev.deepsea.internal';
        if(isStaging ) return 'https://ams-staging.deepsea.internal';
        if(isStaging ) return 'https://ams-devai.deepsea.internal';
        if(isProd) return 'https://ams.deepsea.ai';
        if(isDemo) return 'https://ams-demo.deepsea.ai';
    },
}
