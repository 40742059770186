import React, {useState} from 'react';
import {ReactComponent as CloseIcon} from "../../../assets/img/app/global-imgs/close.svg";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import FleetsVesselsAutocompleteField from "components/fleetsVesselsAutocompleteField/fleetsVesselsAutocompleteField";

const EditLevelModal = props => {

    const [errorEmptyField, setErrorEmptyField] = useState(false);

    const submitAction = (event) => {
        event.preventDefault();
        if (!event.target[0].value) {
            setErrorEmptyField(true);
        } else {
            setErrorEmptyField(false);
            props.onSubmit(event);
        }
    }

    return (
        <Dialog open={props.modalShow} className="centered-modal dashboard-modal templates-modal" disableBackdropClick
                disableEnforceFocus aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.title}
                <div className="close-button flex-centered">
                    <div className="close-button__img flex-centered">
                        <CloseIcon className="closeIcon" onClick={() => props.setModalShow(false)}/>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <form id="modalForm" onSubmit={(e) => submitAction(e)}>
                    <FleetsVesselsAutocompleteField {...props.widget} errorEmptyField={errorEmptyField} setErrorEmptyField={setErrorEmptyField}/>
                    {errorEmptyField && <div className="input-error-message">
                        Field is required
                    </div> }
                </form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" className='cancelButton' onClick={() => props.setModalShow(false)}>
                    <div className='main-cta__text'>Cancel</div>
                </Button>
                <Button color="primary" type="submit" className='saveButton' form="modalForm">
                    <div className='main-cta__text'>Save</div>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditLevelModal;
