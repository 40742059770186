import moment from "moment";
import { baseFormUtils } from 'common/store/storeUtils';
import Group from "components/core/Forms/group";

const charterPartySchema = (editMode) => {
    return [
        {
            agreements: {
                type: 'array',
                label: {
                    title: 'Agreements',
                    index: 'Agreement',
                    add: 'Add Agreement',
                },
                withoutBackground: true,
                tile: false,
                maxItems: 3,
                structure: {
                    draft: {
                        type: 'positiveNumber',
                        label: 'Draft',
                        value: '',
                        viewports: {xs: 12, sm: 6, md: 3, lg: 3, xl: 3},
                        validation: {
                            required: true
                        },
                        unit: "m"
                    },
                    wind: {
                        type: 'positiveNumber',
                        label: 'Wind Speed',
                        value: '',
                        viewports: {xs: 12, sm: 6, md: 3, lg: 3, xl: 3},
                        validation: {
                            required: true,
                            minValue: 0,
                            maxValue: 13
                        },
                        unit: "Bft"
                    },
                    conditions: {
                        type: 'array',
                        label: {
                            title: '',
                            index: '',
                            add: 'Add Condition',
                        },
                        maxItems: 3,
                        viewports: {xs: 12, sm: 6, md: 3, lg: 3, xl: 3},
                        structure: {
                            speed: {
                                type: "positiveNumber",
                                label: "STW",
                                value: '',
                                validation: {
                                    required: true
                                },
                                unit: "kn"
                            },
                            fcr: {
                                type: "positiveNumber",
                                label: "FCR",
                                value: '',
                                validation: {
                                    required: true
                                },
                                unit: "t/day"
                            }
                        }
                    }
                }
            }
        },
        {
            dateFrom: {
                type: 'datePicker',
                label: 'Start Day',
                group: 'General',
                viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                value: '',
                validation: {
                    required: true
                },
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                    if (value === null) {
                        setFieldValue("dateFrom", "");
                    }
                }
            },
            dateTo: {
                type: 'datePicker',
                label: 'End Day',
                group: 'General',
                viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                value: '',
                validation: {
                    greaterThan: 'dateFrom',
                    required: true,
                },
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                    if (value === null) {
                        setFieldValue("dateTo", "");
                    }
                }
            },
            charterer: {
                type: 'text',
                label: 'Charterer',
                group: 'General',
                value: '',
                validation: {
                    required: true
                }
            }
        }
    ];
};

const charterPartyValues = (props, editMode, dateClicked) => {
    return [
        {
            agreements: !props.hasOwnProperty('agreements') || props.agreements.length === 0
                ? [baseFormUtils.createValuesFromSchema(charterPartySchema(editMode)[0].agreements.structure)]
                : props.agreements.map(agreement => baseFormUtils.createValues(agreement, charterPartySchema(editMode)[0].agreements.structure))
        },
        {
            dateFrom: editMode ? moment.utc(props.dateFrom).set({'hour': 12}) : moment(dateClicked).set({'hour': 12}).utc(),
            dateTo: editMode ? moment.utc(props.dateTo).set({'hour': 12}) : moment(dateClicked).set({'hour': 12}).utc(),
            charterer: props.charterer || '',
        }
    ]
};

const charterPartyGroups = [
    {},
    {
        'General': {
            ...Group,
            viewports: {xs: 12, sm: 6, md: 6, lg: 6, xl: 6},
            paddingViewports: "none",
            renderLabel: false,
            className: ''
        }
    }
];

export {charterPartySchema, charterPartyValues, charterPartyGroups};