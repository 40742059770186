const bulletCircleMarker = (color) => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_11312" data-name="Group 11312" transform="translate(-743 -457)">
                <g id="Path_9174" data-name="Path 9174" transform="translate(743 457)" fill="${color}" fill-opacity="0.32">
                    <path d="M 9.999999046325684 19.5 C 4.761679172515869 19.5 0.4999990463256836 15.23831939697266 0.4999990463256836 9.999999046325684 C 0.4999990463256836 4.761679172515869 4.761679172515869 0.4999990463256836 9.999999046325684 0.4999990463256836 C 15.23831939697266 0.4999990463256836 19.5 4.761679172515869 19.5 9.999999046325684 C 19.5 15.23831939697266 15.23831939697266 19.5 9.999999046325684 19.5 Z" stroke="none"/>
                    <path d="M 9.999999046325684 0.9999980926513672 C 5.037379264831543 0.9999980926513672 0.9999980926513672 5.037379264831543 0.9999980926513672 9.999999046325684 C 0.9999980926513672 14.96261882781982 5.037379264831543 19 9.999999046325684 19 C 14.96261882781982 19 19 14.96261882781982 19 9.999999046325684 C 19 5.037379264831543 14.96261882781982 0.9999980926513672 9.999999046325684 0.9999980926513672 M 9.999999046325684 -1.9073486328125e-06 C 15.52284908294678 -1.9073486328125e-06 20 4.47714900970459 20 9.999999046325684 C 20 15.52284908294678 15.52284908294678 20 9.999999046325684 20 C 4.47714900970459 20 -1.9073486328125e-06 15.52284908294678 -1.9073486328125e-06 9.999999046325684 C -1.9073486328125e-06 4.47714900970459 4.47714900970459 -1.9073486328125e-06 9.999999046325684 -1.9073486328125e-06 Z" stroke="none" fill="${color}"/>
                </g>
            </g>
        </svg>
    `;
}

export default bulletCircleMarker;