import moment from 'moment';
import React from 'react';
import { ReactComponent as TimeIcon } from 'assets/img/app/timeline-imgs/time.svg';
import { ReactComponent as FuelOilIcon } from 'assets/img/app/timeline-imgs/fuelOil.svg';
import { ReactComponent as LubeOilIcon } from 'assets/img/app/timeline-imgs/lubeOil.svg';
import { ReactComponent as ChartererIcon } from 'assets/img/app/timeline-imgs/charterer.svg';
import { ReactComponent as CharterShipIcon } from 'assets/img/app/timeline-imgs/charterShip.svg';
import { ReactComponent as ConductorIcon } from 'assets/img/app/timeline-imgs/conductor.svg';
import { ReactComponent as MaintainanceGeIcon } from 'assets/img/app/timeline-imgs/maintainance_ge.svg';
import { ReactComponent as MaintainanceHullIcon } from 'assets/img/app/timeline-imgs/maintainance_hull.svg';
import { ReactComponent as MaintainanceMeIcon } from 'assets/img/app/timeline-imgs/maintainance_me.svg';
import { ReactComponent as NextportIcon } from 'assets/img/app/timeline-imgs/nextport.svg';
import { ReactComponent as PortIcon } from 'assets/img/app/timeline-imgs/port.svg';
import {ReactComponent as AtSeaIcon} from 'assets/img/app/overview-imgs/atSea.svg';
import { ReactComponent as FuelDropsIcon } from 'assets/img/app/global-imgs/fuel-drops.svg';
import { ReactComponent as DistanceTravelled } from 'assets/img/app/events-imgs/distanceTravelled.svg';
import EventsUtilities from "../../Utilities/events";
import { eventsUtils } from 'common/store/storeUtils';

const categMap = {
    system: {
        "cooling": "Cooling water System",
        "air": "Control Air System",
        "lubrication": "Lubrication System",
        "oil": "Fuel Oil System",
        "pistons": "Pistons",
        "tc": "Turbo Chargers",
        "shaft": "Shaft System",
        "generator": "Generator System",
    },
    type: {
        "m/e": "System Oil",
        "hsfo": "HSFO",
        "lsfo": "LSFO",
        "hsgo": "HSGO",
        "lsmgo": "LSMGO",
        "ge": "Generator Engine",
        "me": "Main Engine",
        "hull": "Hull",
        "fOil": "Fuel Oil",
        "lOil": "Lube Oil",
        "hbn co": "HBN CO",
        "lslbn co": "LSBN CO",
        "g/e": "GO",
    },
    part: {
        "propeller" : "Propeller",
        "bowThruster": "Bow Thruster",
        "seaChest": "Sea Chest",
        "fullHull": "Full Hull",
        "hullSide": "Hull Side",
        "hullBottom": "Hull Bottom",
        "bulb": "Bulb",
        "thrustBearing": "Thrust Bearing",
        "torquemeter": "Torquemeter",
        "crankShaftBearing": "Crank shaft Bearing",
        "camshaft": "Camshaft",
        "pumps": "Pumps",
        "generator": "Fresh Generator",
        "preheater": "Preheater",
        "aircooler": "Air Cooler",
        "compressors": "Compressors",
        "air": "Air Start Valve",
        "receiver": "Receiver",
        "exhaust": "Exhaust Valve",
        "filters": "Filters",
        "heater": "Heater",
        "injector": "Injector",
        "seal": "Seal",
        "pin": "Pin",
        "turbine": "Turbine",
        "compressor": "Compressor",
        "mainShaft": "Main Shaft",
        "bearing": "Bearing",
        'winding': 'Winding',
    },
    action: {
        "overhauling": "Overhauling",
        "assembling": "Assembling",
        "tightening": "Tightening",
        "calibration": "Calibration",
        "clearance": "Clearance",
        "cleaning": "Cleaning",
        "polishing": "Polishing",
        "rePainting": "Re-Painting",
    },
    cause: {
        "lube": "Lube oil malfunction",
        "fuel": "Fuel malfunction",
        "maintenance": "Incorrect Maintenance",
        "injectors": "Fuel injectors failure",
        "fatigue": "Fatigue/Cracking",
        "vibration": "Vibration",
    }
}

const firstCapital = string => {
    if (!string) return;
    return string
         .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

let count = 0;
const eventTypes = {
    damages: ["me", "ge"],
    maintainance: ["ME", "GE", "HULL"],
    bunkering: ["fOil", "lOil"]
}

const telegramFuelInfo = event => {
    let topValues = [], botValues = [];

    if (event.totalHsfoConsCalc) {
        topValues.push(`HSFO ${event.totalHsfoConsCalc} MT`);
        botValues.push(`ROB ${event.robFoHsfo} MT`)
    }

    if (event.totalLsfoConsCalc) {
        topValues.push(`LSFO ${event.totalLsfoConsCalc} MT`);
        botValues.push(`ROB ${event.robFoLsfo} MT`)
    }

    if (event.totalHsdoConsCalc) {
        topValues.push(`HSDO ${event.totalHsdoConsCalc} MT`);
        botValues.push(`ROB ${event.robFoHsdo} MT`)
    }

    if (event.totalLsdoConsCalc) {
        topValues.push(`LSDO ${event.totalLsdoConsCalc} MT`);
        botValues.push(`ROB ${event.robFoLdfo} MT`)
    }

    return {topValues, botValues};
}

const telegramBodyAN = (event) => {
    let tmpArr = [], hoursAtSea = '', minutesAtSea = '';

    if (event.hoursAtSea) hoursAtSea = `${event.hoursAtSea} hrs`;
    if (event.minutesAtSea) minutesAtSea = `${event.minutesAtSea} mins`;

    tmpArr.push(
        ["Hours at sea", `${hoursAtSea} ${minutesAtSea}`, <AtSeaIcon />, "hoursAtSea"],
        ["Miles Traveled", `${event.milesAtSea || '0'} nm`, <DistanceTravelled />, "milesAtSea"],
        ["Fuel Oil", `${event.totalFOMassRateCalc || '0'} MT`, <FuelDropsIcon  />, "fuel"],
        [telegramFuelInfo(event).topValues, telegramFuelInfo(event).botValues, '', "array"],
        ["Next Port", firstCapital(event.nextPort), <NextportIcon />, "nextport"],
        ["ETA", event.etaNextPort !== '-' ? `${moment.utc(event.etaNextPort).format('DD/MM/YYYY')} at ${moment.utc(event.etaNextPort).format('HH:mm')}` : `-`, <TimeIcon />, "eta"]
    )
    return tmpArr;
}

const telegramBodyD = (event) => {
    let tmpArr = [];
    tmpArr.push(
        ["Next Port", firstCapital(event.nextPort), <NextportIcon />, "nextport"],
        ["ETA", event.etaNextPort !== '-' ? `${moment.utc(event.etaNextPort).format('DD/MM/YYYY')} at ${moment.utc(event.etaNextPort).format('HH:mm')}` : `-`, <TimeIcon />, "eta"],
        ["Miles to Next Port", `${event.milesToNextPort} nm`, <DistanceTravelled />, "milesToNextPort"],
        ["Fuel Oil", `${event.totalFOMassRateCalc ? event.totalFOMassRateCalc : 0} MT`, <FuelDropsIcon  />, "fuel"],
        [telegramFuelInfo(event).topValues, telegramFuelInfo(event).botValues, '', "array"]
    )
    return tmpArr;
}

const telegramBodyO = (event) => {
    const generatorCount = EventsUtilities.geInfo[EventsUtilities.selectedVessel]?.length || '8';
    let tmpArr = [], passGeCount = 0, geArr = [];

    for (let i = 1; i <= generatorCount; i++) {
        if(event[`ge${i}UsageHrs`] || event[`ge${i}PowerAvg`]) {
            passGeCount++;
            geArr.push([`${event[`ge${i}UsageHrs`]} hrs - ${event[`ge${i}PowerAvg`]} avg. kw`, `GE ${i}`, '', 'geUsage'])
        }
    }

    tmpArr.push(
        ["Fuel Oil", `${event.totalFOMassRateCalc} MT`, <FuelDropsIcon  />, "fuel"],
        [telegramFuelInfo(event).topValues, telegramFuelInfo(event).botValues, '', "array"],
        ["Active Generator Engines", `${passGeCount} out of ${generatorCount}`, <MaintainanceGeIcon />, "activeGe"],
        ...geArr
    )

    return tmpArr;
}

const damagesBody = (event) => {
    let tmpArr = [];
    eventTypes.damages.forEach((type) => {
        count = 0;
        let  damagesJoin = [];
        event.damages.forEach((damage) => {
            if (damage.location === type) {
                count++;
                damage.cause.forEach((cause, index) => { damagesJoin.push(categMap.cause[cause]) });
                tmpArr.push(
                    [`because of ${ damage.cause[0] === "other" ? damage.otherCause : damagesJoin.join(", ")}`, `${categMap.system[damage.system]} at ${categMap.part[damage.part]}`, '', "damages" + damage.location]
                )
            }
        })
        count > 0 && tmpArr.push([categMap.type[type.toLowerCase()], `${count} Damage${count > 1 ? "s" : ""}`, type === "me" ? <MaintainanceMeIcon /> : <MaintainanceGeIcon />, "damagesme_title popover-title"]);
    })
    return tmpArr.reverse();
}

const maintainanceBody = (event) => {
    let tmpArr = [], icon = '';
    eventTypes.maintainance.forEach((type) => {
        count = 0;
        event.operations.forEach((operation) => {
            if (operation.location === type) {
                count++;
                tmpArr.push(
                    [`action taken ${operation.action === "other" ? operation.otherAction : categMap.action[operation.action]}`, `${operation.system ? `${categMap.system[operation.system]} at ` : ""}` + categMap.part[operation.part], '', "maintenance" + operation.location]
                )
            }
        })
        icon = (type === "ME") ? <MaintainanceMeIcon /> : (type === "GE") ? <MaintainanceGeIcon /> : <MaintainanceHullIcon />;
        count > 0 && tmpArr.push([`${count} Maintenance${count > 1 ? "s" : ""}`, categMap.type[type.toLowerCase()], icon, "popover-title"]);
    })
    tmpArr = tmpArr.reverse();
    event.conductor && tmpArr.push(["Conductor", event.conductor, <ConductorIcon />, "conductor"]);
    event.port && tmpArr.push(["", event.port, <PortIcon />, "port"]);
    return tmpArr;
}

const chartererBody = (event) => {
    let tmpArr = [];
    event.agreements.forEach((agreement) => {
        tmpArr.push(["Agreement", `Draft ${agreement.draft} m - Wind Speed ${agreement.wind}`, <CharterShipIcon />, "charterShip popover-title"])
        agreement.conditions.forEach((condition) => {
            tmpArr.push(
                ["", `STW ${condition.speed} kn FCR ${condition.fcr} t/day`, '', "charterCondition"]
            )
        })
    })
    event.charterer && tmpArr.push(["Charterer", event.charterer, <ChartererIcon />, "charterer"]);
    return tmpArr;
}

const bunkeringBody = event => {
    let tmpArr = [], personName = '';
    eventTypes.bunkering.forEach((type) => {
        let total = 0;
        event.bunkerings.forEach((bunkering) => {
            if (bunkering.oilType === type) {
                total += parseInt(bunkering.supplied)
                total > 0 && tmpArr.push([`ROB ${bunkering.rob} MT ${bunkering.viscosity ? " - Viscosity " + bunkering.viscosity + " cSt" : ''} ${bunkering.density ? " - Density " + bunkering.density + " 𝑘𝑔/𝑚3" : ''}`, `${categMap.type[bunkering.oil].toUpperCase()} ${bunkering.supplied} MT`, '', 'bunkeringFOil'])
            }
        })
        total > 0 && tmpArr.push([categMap.type[type], `${total} MT`, type === "lOil" ? <LubeOilIcon /> : <FuelOilIcon />, "bunkering popover-title"])
    })
    tmpArr = tmpArr.reverse();

    if (event.person) {
        let nameArr = '';
        nameArr = event.person.split(" ");
        personName = nameArr[0].slice(0,1).toUpperCase() + (!nameArr[1] ? nameArr[0].slice(1,2).toUpperCase() : nameArr[1].slice(0,1).toUpperCase() ) ;
        event.person && tmpArr.push(["Responisble Person", event.person, personName, "responsiblePerson"]);
    }

    event.port && tmpArr.push(["", event.port, <PortIcon className="svg-path" />, "port"]);
    return tmpArr;
}


const popoverUtils = {
    popOverTitle: event => {
        let headerInfo = {
            title: '',
            subtitle: ''
        }

        const eventType = event.originalType ? event.originalType : event.type;
        const portName = event.port ? event.port : event.portName;
        const maintainanceTitle = eventType === eventsUtils.getEventIdByType('MAINTENANCE') && event.maintenanceType === "drydock" ? "Drydock Maintenance" : "Sea Maintenance";

        // Arrival Telegram
        if (eventType === eventsUtils.getEventIdByType('ATELEGRAM')) {
            headerInfo.title = `Arrival at ${firstCapital(portName)}`;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }
        // Departure Telegram
        else if (eventType === eventsUtils.getEventIdByType('DTELEGRAM')) {
            headerInfo.title = `Departure from ${firstCapital(portName)}`;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }
        // Noon Telegram
        else if (eventType === eventsUtils.getEventIdByType('NTELEGRAM')) {
            headerInfo.title = `Noon Report`;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }
        // Noon Port Telegram
        else if (eventType === eventsUtils.getEventIdByType('OTELEGRAM')) {
            headerInfo.title = `Port Noon Report from ${firstCapital(portName)}`;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} at ${moment.utc(event.dateFrom).format('HH:mm')}`;
        }
        // Maintenance Telegram
        else {
            headerInfo.title = eventType === eventsUtils.getEventIdByType('MAINTENANCE') ? maintainanceTitle : event.calendarTitle ? event.calendarTitle : event.niceType;
            headerInfo.subtitle = `${moment.utc(event.dateFrom).format('DD/MM/YYYY')} `;
            headerInfo.subtitle += (event.dateFrom !== event.dateTo) ? ` - ${moment.utc(event.dateTo).format('DD/MM/YYYY')}` : "";
        }
        return headerInfo;
    },
    popoverBody: event => {
        if (event.type === eventsUtils.getEventIdByType('DTELEGRAM')) {
            return telegramBodyD(event);
        } else if (event.type === eventsUtils.getEventIdByType('NTELEGRAM') || event.type === eventsUtils.getEventIdByType('ATELEGRAM')) {
            return telegramBodyAN(event);
        } else if (event.type === eventsUtils.getEventIdByType('OTELEGRAM')) {
            return telegramBodyO(event);
        } else if (event.type === eventsUtils.getEventIdByType('DAMAGES')) {
            return damagesBody(event);
        } else if (event.type === eventsUtils.getEventIdByType('MAINTENANCE')) {
            return maintainanceBody(event);
        } else if (event.type === eventsUtils.getEventIdByType('VESSELCHARTER')) {
            return chartererBody(event);
        } else if (event.type === eventsUtils.getEventIdByType('BUNKERING')) {
            return bunkeringBody(event);
        }
    },

}

export default popoverUtils;
