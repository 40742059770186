import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/img/app/global-imgs/close.svg';

const TemplateModalDeletion = (props) => {

    const TemplateActionAndCloseModal = () => { 
        props.deleteAction();
        props.closeModal();
    }

    return(
        <Dialog open={props.show} className="centered-modal dashboard-modal templates-modal" disableBackdropClick disableEnforceFocus aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.header}
                <div className="close-button flex-centered">
                    <div className="close-button__img flex-centered">
                        <CloseIcon className="closeIcon" onClick = {props.closeModal} />
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="dashboardDeletionMessage">
                    Dashboard will be deleted and you will not be able to access it. Are you sure that you want to delete it?
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" className='cancelButton' onClick={props.closeModal}>
                    <div className='main-cta__text'>Cancel</div>
                </Button>
                <Button color="primary" type="submit" className='saveButton' onClick={TemplateActionAndCloseModal} >
                    <div className='main-cta__text'>{props.submitButtonLabel}</div>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TemplateModalDeletion;