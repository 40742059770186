import React from 'react';
import { ReactComponent as InformationIcon } from 'assets/img/app/global-imgs/informationIcon.svg';
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import PropTypes from 'prop-types';

const HullPerformanceHeaderInfoIcon = ({ isLight, vesselName, date }) => 
    <Tooltip 
        component={<InformationIcon className='svg-path'/>}
        height='auto'
        title={
            <div className='hullPerformanceInfoIcon'>
                <div className='hullPerformanceInfoIcon-infoTitle'>
                    AI Performance Model
                </div>
                <div className='hullPerformanceInfoIcon-infoParagraph'>
                    All the data collected from { vesselName } was used to train an advanced neural network (AI) model of its hull. This model can predict the instantaneous { isLight ? 'fuel consumption rate' : 'power' } required to maintain any speed through water under any set of weather, draft and fouling conditions, and also learns the fouling state at every point in time within the whole data provided. This is a data-driven AI model, which does not use sea trials, theoretical equations or reference periods. 
                </div>
 
                {isLight && <div className='hullPerformanceInfoIcon-infoParagraph'>
                    Even though fuel consumption data is only available on a daily basis, the AI model can make instantaneous fuel consumption rate predictions using speed data from AIS, having learnt to distribute the daily fuel consumption among the different speeds and conditions recorded throughout the day.
                </div>}

                <div className='hullPerformanceInfoIcon-infoParagraph'>
                    The curves, generated by the AI model, show the performance of { vesselName } { date }, and immediately following a full hull cleaning.
                </div>

                {!isLight && <div className='hullPerformanceInfoIcon-infoParagraph'>
                    For the Fuel-speed curves, power is converted to fuel consumption rate using the shop trial SFOC curve.
                </div>}

                <div className='hullPerformanceInfoIcon-infoParagraph'>
                    For the CII-speed curves, fuel is converted to CII rating using the provided fuel emission factor.
                </div>

                <div className='hullPerformanceInfoIcon-infoParagraph lastParagraph'>
                    For comparison, the sea trial curve for the selected draft (extrapolated from the provided figures using a standard model) is displayed alongside a selection of raw data (captured between the most recent full hull cleaning and today, and filtered for the selected weather and draft conditions).
                </div>
            </div>
        }
    />;

HullPerformanceHeaderInfoIcon.propTypes = {
    isLight: PropTypes.bool,
    vesselName: PropTypes.string,
    date: PropTypes.string
};

export default HullPerformanceHeaderInfoIcon;