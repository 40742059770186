import React from 'react';
import {ReactComponent as NoDataReportsConfigurationIcon} from "assets/img/app/global-imgs/no-dataReportsConfiguration.svg";


const NoDataReportsConfiguration = (props) => {
    return (
        <div className="no-data-state d-flex justify-content-center align-items-center flex-column" style={{height: props.height, padding: '5px 0 15px 0', minHeight: props.minHeight ? props.minHeight : 'auto'}}>
            { (parseInt(props.height) > 200 || parseInt(props?.minHeight) > 200 || props.height === 'auto') && <div className="no-data-icon marg-b-15"><NoDataReportsConfigurationIcon className="svg-path-no-data" /></div>}
            <div className="form-text text-center w-50">Enable Reports configuration in order to see the content</div>
        </div>
    );
}
 
export default NoDataReportsConfiguration;
