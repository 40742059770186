import React from 'react';
import {ReactComponent as AIstatusIcon} from 'assets/img/app/global-imgs/AIstatusIcon.svg';
import {ReactComponent as HighConfidenceLevelIcon} from 'assets/img/app/global-imgs/highConfidenceLevelIcon.svg';
import {ReactComponent as LowConfidenceLevelIcon} from 'assets/img/app/global-imgs/lowConfidenceLevelIcon.svg';
import {ReactComponent as InaccurateConditionStatusIcon} from 'assets/img/app/global-imgs/inaccurateConditionStatusIcon.svg';
import {ReactComponent as LimitedInputStatusIcon} from 'assets/img/app/global-imgs/limitedInputStatusIcon.svg';
import {ReactComponent as MissingParametersStatusIcon} from 'assets/img/app/global-imgs/missingParametersStatusIcon.svg';

const ModelDetailsUtils = {
    confidenceLeveStringMapper: confidence => {
        switch (confidence) {
            case 'Data processing by A.I.':
                return null;
            case 'Missing key input':
                return -1;
            case 'Limited Input':
                return 0;
            case 'Bad data quality':
                return 1;
            case 'Low':
                return 2;
            case 'High':
                return 3;
            default:
                break;
        }
    },
    getInfoOfGivenConfidenceLevel: (confidenceLevel) => {
        if (confidenceLevel === 'null' || confidenceLevel === null)
            return {
                text: 'Data Processing by AI',
                icon: <AIstatusIcon className='svg-path' />,
                style: {
                    color: 'var(--value_text)', 
                    maxWidth: '120px'
                }
            }
        if (parseInt(confidenceLevel) === -1) 
            return {
                text: 'Missing Key Input',
                icon: <MissingParametersStatusIcon className='svg-path-stroke' />,
                style: {color: 'var(--error_text)'}
            };
        if (parseInt(confidenceLevel) === 0) 
            return {
                text: 'Limited Input',
                icon: <LimitedInputStatusIcon className='svg-path' />,
                style: {color: 'var(--disabled_text)'}
            };
        if (parseInt(confidenceLevel) === 1) 
            return {
                text: 'Bad Data Quality',
                icon: <InaccurateConditionStatusIcon className='svg-path' />,
                style: {color: 'var(--medium_severity)'}
            };
        if (parseInt(confidenceLevel) === 2) 
            return {
                text: 'Low',
                icon: <LowConfidenceLevelIcon className='svg-path' />,
                style: {color: 'var(--low_severity)'}
            };
        if (parseInt(confidenceLevel) === 3) 
            return {
                text: 'High',
                icon: <HighConfidenceLevelIcon className='svg-path' />,
                style: {color: 'var(--fine_text)'}
            };
    }
}

export default ModelDetailsUtils;