import React from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { ReactComponent as DatePickerIcon } from 'assets/img/app/global-imgs/calendar.svg';
import classnames from "classnames";
import moment from 'moment';

const DatePicker = props => {

    const getPickerClassNames = (isNotGroup) => {
        return classnames({'input-group': !isNotGroup, [props.classNames.wrapper]: !isNotGroup})
    }

    return(
        <div className={getPickerClassNames(props.isNotGroup)}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {props.onlyTime ?
                    <KeyboardTimePicker
                        ampm={false}
                        format={'HH:mm'}
                        autoOk
                        PopperProps={{
                            disablePortal: true,
                            style: { zIndex: 1000000}
                        }}
                        DialogProps={{
                            style: { zIndex: 1000000}
                        }}
                        keyboardIcon={<DatePickerIcon className="svg-path" />}
                        dateRangeIcon={<DatePickerIcon />}
                        id={props.name}
                        label={props.label}
                        value={props.value || null}
                        className={props.classNames.element}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        variant="inline"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    /> : props.withTime ?
                        <KeyboardDateTimePicker
                            ampm={false}
                            placeholder='__/__/____ __:__'
                            format={'DD/MM/YYYY HH:mm'}
                            autoOk
                            PopperProps={{
                                disablePortal: true,
                                style: { zIndex: 1000000}
                            }}
                            DialogProps={{
                                style: { zIndex: 1000000}
                            }}
                            keyboardIcon={<DatePickerIcon className="svg-path" />}
                            dateRangeIcon={<DatePickerIcon />}
                            id={props.name}
                            label={props.label}
                            value={props.isUTC ? (moment(props.value, "DD/MM/YYYY HH:mm").valueOf()) : props.value || null}
                            className={props.classNames.element}
                            onChange={props.onChange}
                            disabled={props.disabled}
                            variant="inline"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            {...(props?.extraProps ? props.extraProps : {})}
                        /> :
                        <KeyboardDatePicker
                            autoOk
                            format={props.format}
                            id={props.name}
                            PopperProps={{
                                disablePortal: true,
                                style: { zIndex: 1000000}
                            }}
                            DialogProps={{
                                style: { zIndex: 1000000}
                            }}
                            label={props.label}
                            keyboardIcon={<DatePickerIcon className="svg-path" />}
                            value={props.value || null}
                            className={props.classNames.element}
                            onChange={props.onChange}
                            //focused={props.autoFocus}
                            disabled={props.disabled}
                            variant="inline"
                            //InputAdornmentProps={{ position: "start" }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                }
            </MuiPickersUtilsProvider>
            {props.renderError()}
        </div>
    );
};

DatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    renderError: PropTypes.func,
    classNames: PropTypes.object,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    withTime: PropTypes.bool,
    onlyTime: PropTypes.bool,
};

DatePicker.defaultProps = {
    renderError: () => '',
    classNames: { wrapper: '', element: '' },
    autoFocus: false,
    disabled: false,
    withTime: false,
    onlyTime: false
};

export default DatePicker;

