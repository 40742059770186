/***
 * Ripple Effect for any element
 * How to use:
 * 1. Import this file
 * 2. Add the .ripple classname in the elements 
 * 3. Init ripple effect using inside method
 ***/

import { debounce } from "lodash";

const rippleEffect = {

    initRipple: function () {
        let ripplesArray = document.getElementsByClassName('ripple'), rippleEffectContainer, ripple;
        for (var i = 0, len = ripplesArray.length; i < len; i++) {
            ripple = ripplesArray[i];
            rippleEffectContainer = document.createElement('div');
            rippleEffectContainer.className = 'ripple--container';
            ripple.addEventListener('mousedown', animateRipple);
            ripple.addEventListener('mouseup', debounce(cleanRippleEffect, 1000));
            ripple.rippleEffectContainer = rippleEffectContainer;
            ripple.appendChild(rippleEffectContainer);
        }
        function animateRipple(e) {
            const ripple = this,
                    size = ripple.offsetWidth,
                    pos = ripple.getBoundingClientRect(),
                    rippler = document.createElement('span'),
                    x = e.pageX - pos.left - (size / 2),
                    y = e.pageY - pos.top - (size / 2),
                    style = 'top:' + y + 'px; left:' + x + 'px; height: '
                        + size + 'px; width: ' + size + 'px;';
            ripple.rippleEffectContainer.appendChild(rippler);
            rippler.setAttribute("style", style);
        }
        function cleanRippleEffect() {
            let container = this.rippleEffectContainer;
            while (this.rippleEffectContainer.firstChild) {
                container.removeChild(container.firstChild);
            }
        }   
    }
};

export default rippleEffect;