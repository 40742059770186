
import moment from 'moment';

const adjustTimezone = (dateTime, isUTC) => {
    if( isUTC ) return moment(dateTime)?.utc()?.format('DD/MM/YYYY HH:mm');
    return moment(dateTime)?.format('DD/MM/YYYY HH:mm');
}

const updateVesselTripsPorts = (id, response, updateState, extraChartConfigs, widget) => {

    if(response?.length === 0) return updateState(id, {}, widget?.id);

    const legTemp = response[response?.length - 1];
    const from = moment(legTemp?.dateFrom).valueOf();
    const to = moment(legTemp?.dateTo).valueOf();

    const data = {
        departureDate: adjustTimezone(from, true),
        departurePort: legTemp?.portFrom,
        arrivalDate: adjustTimezone(to, true),
        arrivalPort: legTemp?.portTo
    };

    updateState(id, data, widget?.id);
}

export {updateVesselTripsPorts};