import React from 'react';

const SensorsTelegramsDataDivided = (props) => {
    return(
        ((props.sensorsValue || parseFloat(props.sensorsValue) >= 0) && (props.reportsValue || parseFloat(props.reportsValue) >= 0))
            ? <div className={props.classes.wrapperClass}>
                <div className={props.classes.sensorsClass}>{props?.sensorsValue} {props?.unit}</div>
                <div className={props.classes.dividerClasses}> </div>
                <div className={props.classes.reportsClass}>{props?.reportsValue} {props?.unit}</div>
            </div>
            : (props.sensorsValue || parseFloat(props.sensorsValue) >= 0)
            ? <div className={props.classes.sensorsClass}>{props?.sensorsValue} {props?.unit}</div>
            : (props.reportsValue || parseFloat(props.reportsValue) >= 0)
            ? <div className={props.classes.reportsClass}>{props?.reportsValue} {props?.unit}</div>
            : <> </>
    )
}

export default SensorsTelegramsDataDivided;