import Storage from "common/js/Storage";

const initialUserState = {
    user: Storage.getCookie('user') || {}
}

// user reducer
const user = (state = initialUserState, action) => {
    switch(action.type) {
        case 'user/setUser': {
            return {
                ...state,
                user: action.payload
            };
        }
        default: 
            return state;
    }
}

export default user;