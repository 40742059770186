import React, { useEffect } from 'react';
import {ReactComponent as CloseIcon} from 'assets/img/app/global-imgs/close.svg';
import { useSelector, useDispatch } from 'react-redux';
import { notificationsStore } from 'common/store/storeUtils';

const Notifications = () => {
    // store vars
    const notificationsStoreJSX = useSelector(state => state.notifications);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!notificationsStore().showNotification) return;

        setTimeout(() => {
            dispatch({ type: 'notifications/setShowNotification', payload: false });
        }, 2000);
        // eslint-disable-next-line
    }, [notificationsStoreJSX.showNotification]);

    const closeNotification = () => dispatch({ type: 'notifications/setShowNotification', payload: false });

    return(
        <div className={`toasterPopup bottom-center`}>
            <div className="notificationsToaster">
                <div className="notificationsToaster__text">
                    {notificationsStoreJSX.notificationText}
                </div>
                <div className="closeNotification" onClick={closeNotification}>
                    <CloseIcon />
                </div>
            </div>
        </div>
    )
}

export default Notifications;
