import hullStorage from "components/monitoringCategories/localStorages/hullStorage";
import highstockBarChartOptions from "components/charts/barChart/highstock-barChart";
import { ThemeColorsForCharts } from "Utilities/themeColorsForCharts";
import moment from "moment";

let wasteTonesObj = {};

const yAxisData = [{
    id: 'fuelWaste-axis',
    title: {text: 'Excess Fuel (mt)'},
    yMin: 0,
    min: 0,
    opposite: false
}];

const fuelWasteChartData = [
    {
        name: 'Excess fuel',
        centerInCategory: false,
        legendTitle: 'Sensor FC',
        data: [],
        tooltipTitle: 'Excess Fuel',
        yAxis: 'fuelWaste-axis',
        min: 0,
        type: 'column',
        yMin: 0
    },
    {
        name: 'Missing excess fuel',
        legendTitle: 'Sensor Missing Data',
        data: [],
        tooltipTitle: 'Excess Fuel',
        yAxis: 'fuelWaste-axis',
        min: 0,
        metadata: {beforeText: 'missing predictions at', title: ' out of 2 reports', suffix: ''},
        type: 'column',
        yMin: 0,
        centerInCategory: false,
    }
];

// Payload will be extended with vesselId, fromDate, toDate information
export const foulingWastePayload = {
    "reportType": "HIGHCHARTS_BASIC",
    "predictionCategory": "AIS_CONSUMPTION",
    "aggregation": "SUM",
    "metrics": [
        "fuelWaste_formula"
    ],
    "timeGroup": "DAY",
    "withMissingReports": true,
    "withTelegramPeriod": true
}

export const setWasteTones = (data, widget) => {
    wasteTonesObj[widget?.id] = data?.wasteTones;
}

export const updateFoulingWaste = (id, data, updateState, extraChartConfigs, widget) => {
    hullStorage.setFoulingWasteData(data);
    if (data.series.length === 0) return updateState(id, {}, widget?.id);

    let tooltipHeader = '{this.point.reportPeriod}';
    if (widget?.inDashboard) tooltipHeader = '{this.point.point.reportPeriod}';

    let foulingWasteJson = {...highstockBarChartOptions(":%d/%m", null, tooltipHeader)};
    foulingWasteJson.yAxis = [...yAxisData];
    foulingWasteJson.series = JSON.parse(JSON.stringify(fuelWasteChartData));
    foulingWasteJson.chart = {...foulingWasteJson.chart/*, height: 400, width: 469*/};
    foulingWasteJson.legend.enabled = false
    foulingWasteJson.plotOptions.column.pointWidth = 10;
    foulingWasteJson.tooltip = {...foulingWasteJson.tooltip, split: false, xDateFormat: '%d/%m'};
    foulingWasteJson.xAxis = {
        ...foulingWasteJson.xAxis,
        title: {text: "Days", y: 12},
        offset: 18,
        lineColor: 'transparent'
    };

    foulingWasteJson.series[1].color = ThemeColorsForCharts()['missing_first_stack_sensor'];
    data.series[0] && data.series[0].values.forEach( point => {
        const arrayPosition = point.missingReports ? 1 : 0;

        foulingWasteJson.series[arrayPosition].data.push({
            x: point.x,
            y: point.y,
            period: point?.period,
            missingValues: point.missingReports
        })
    })

    foulingWasteJson.tooltip.formatter = function() {
        return (`
        <div class="flex-centered-col">
            <div class="highcharts-fonts tooltip-title">
                <b>${moment(this.point?.period?.timestampFrom).utc().format('DD/MM HH:mm') + ' - ' + moment(this.point?.period?.timestampTo).utc().format('DD/MM HH:mm')} (UTC)</b>
            </div>
            <div class="tooltip-values" style="width: 100%;">
                <div class="flex-space-between highcharts-fonts" id="${this.series.name}">
                    <div class="flex-space-between"> 
                        <div class="tooltip-circle" style="background-color:${this.series.color};"></div>
                        <div>${this.series.userOptions.tooltipTitle}: &nbsp;</div>
                        <div><b>${this.point.y.toFixed(2)} mt</b></div>
                    </div>
                </div>
                <div class="highcharts-fonts" style="width: 100%; text-align: left;">
                    <div style="margin-left: 12px;">
                    ${this.point.missingValues ? 'missing predictions at ' + this.point.missingValues + ' out of 2 reports' : ''} 
                    </div>    
                </div>
            </div>
        </div>
        `);
    }


    foulingWasteJson.series[0].pointPlacement = 0.14;
    const chartHasData = foulingWasteJson.series.find(serie => serie?.data?.length > 0);
    if (!chartHasData) foulingWasteJson = {};

    updateState(id, {...foulingWasteJson, wasteTones: wasteTonesObj[widget?.id]}, widget?.id);
};
