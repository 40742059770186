import React, { useEffect, useState } from 'react';
import Storage from "common/js/Storage";
import { ThemeProvider, createTheme, withStyles, withTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import PropTypes from 'prop-types';
import {DialogActions, DialogContent} from "@material-ui/core";
import BaseForm from './BaseForm';
import {ReactComponent as SmallArrowIcon} from "assets/img/app/global-imgs/small-arrow.svg";

const _MAINBLUE = '#3366FF';
const _SVGPATH = 'div.MuiStep-completed span.MuiStepLabel-root.MuiStepLabel-horizontal.MuiStepLabel-alternativeLabel span.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel svg';
const _SVGINNERHTML = `<circle cx="12" cy="12" r="12"></circle>
    <path fill="#fff" transform="translate(6,6)" class="st0" d="M0.4,6.3C0.7,6,1.1,5.6,1.4,5.2c0,0,0,0,0,0C1.7,5.5,2,5.8,2.3,6c0.6,0.6,1.2,1.1,1.8,1.7
    c0.2,0.2,0.4,0.4,0.7,0.6c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1C5,8.1,5,8.1,5.1,8c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
    c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1C6,6.9,6,6.9,6,6.8
    c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
    c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
    c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1
    c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1
    c0,0,0.1-0.1,0.1-0.1C9.1,3,9.2,3,9.2,2.9c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1
    c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1
    c0,0,0,0,0,0c0.2,0.2,0.4,0.4,0.7,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C9.4,5.1,7.2,7.9,5,10.6
    c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.2
    C4.3,10,4.1,9.8,3.9,9.7c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C2.6,8.5,1.5,7.4,0.4,6.3
    C0.4,6.3,0.4,6.3,0.4,6.3z"/>`;

// Stepper base style
const _USESTYLE = theme => ({
  root: { width: '100%' },
  backButton: { marginRight: theme.spacing(1) },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
});

const defaultWizardStepValues = {
    label: '',
    content: '',
    invalidBack: false,
    invalidNext: false,
    action: () => Promise.resolve(),
    schema: {},
    values: {},
    groups: {} 
}

let activeStepLocal = 0,
    finalFormLocal = {},
    init = false;

const FormWizard = (props) => {
    const [formWizardState, setFormWizardState] = useState({
        activeStep: 0,
        finalForm: {},
        rerenderForm: false
    });

    const setFormWizardStateFunc = (wizardStateToUpdate) => {
        setFormWizardState((prevFormWizardState) => { return {...prevFormWizardState, ...wizardStateToUpdate}});

        Object.keys(wizardStateToUpdate).forEach((key) => {
            if(key === 'activeStep') {
                activeStepLocal = wizardStateToUpdate[key];
            }
            if(key === 'finalForm') finalFormLocal = wizardStateToUpdate[key];
        });
    }

    useEffect(() => {
        setFormWizardStateFunc({ finalForm: props.steps.reduce((prevStep, nextStep) => ({...prevStep, ...nextStep.values}) , {}) });
    }, [props.steps]);

    useEffect(() => {
        if(formWizardState.rerenderForm) setFormWizardStateFunc({ rerenderForm: false});
    }, [formWizardState.rerenderForm]);

    useEffect(() => {
        if(!init) finalFormLocal = props.steps.reduce((prevStep, nextStep) => ({...prevStep, ...nextStep.values}) , {});
    });

    useEffect(() => {
        init = true;

        return () => {
            setFormWizardStateFunc({
                rerenderForm: false,
                activeStep: 0,
                finalForm: {}
            });
        }
    }, []);

    const submitButtonOptions = () => {
        return {
            buttonName: () => (activeStepLocal === (props.steps.length - 1)) 
                ? (props.editEvent ? <div className='main-cta__text'>Update</div> : <div className='main-cta__text'>Finish</div>) 
                : <span className='main-cta__text'>Next<SmallArrowIcon className="svg-path small-triangle next" /></span>,
            buttonCssClass: 'main-cta',
            buttonWrapperCssClass: 'flex-centered-start form-wizard-buttons-wrapper',
            disabled: false,
            nextButton: true
        }
    }

    const diabledColor = (Storage.getCookie('user').theme === 'ds-white') ? '#D1D1D1' : '#767F89';
    
    const theme = createTheme({
        overrides: {
            MuiStepIcon: {
            root: {
                color: diabledColor,
                '&$completed': { color: _MAINBLUE },
                '&$active': { color: _MAINBLUE }
            }, active: {
                '&$completed': { color: _MAINBLUE },
                '&$active': { color: 'transparent', border: `2px solid ${_MAINBLUE}`, borderRadius: '100%' }
            }, completed: {
                '&$completed': { color: _MAINBLUE },
                '&$active': { color: _MAINBLUE }
            }
            },
        },
    });

    const onDelete = (values) => {
        props.onDelete(values);
        props.modalClose();
    };

    const formGoBack = () => {
        handleBack(false);
        
        setFormWizardStateFunc({
            rerenderForm: true,
            activeStep: (activeStepLocal - 1),
            finalForm: finalFormLocal
        });
    };

    const formSubmit = values => {
        handleNext(false);
        
        if(activeStepLocal === 0) props.typeHandler(values.type);
        
        values.hasOwnProperty('maintenanceType') && (values.helper = values.maintenanceType);

        const updatedFinalForm = {
            ...props.steps.reduce((prevStep, nextStep) => ({...prevStep, ...nextStep.values}) , {}),
            ...finalFormLocal,
            ...values
        };

        setFormWizardStateFunc({
            rerenderForm: true,
            activeStep: (activeStepLocal + 1),
            finalForm: updatedFinalForm
        });

        if (activeStepLocal === props.steps.length) {
            props.onSubmit(updatedFinalForm);
            props.modalClose();
        }
    };

    const setCompletedIcon = () => {
        const icons = document.querySelectorAll(_SVGPATH);
        icons.forEach(icon => icon.innerHTML = _SVGINNERHTML);
    };

    const addStylesToStepper = (activeStep) => {
        document.querySelectorAll('.MuiStepConnector-line')[activeStep].style.borderColor = _MAINBLUE;
        if(Storage.getCookie('user').theme !== 'ds-white')
        document.querySelectorAll('.MuiStepConnector-line')[activeStep].style.boxShadow = `0px 0px 4px .5px ${_MAINBLUE}`;
    }

    const handleNext = (updateState = true) => {    
        if(activeStepLocal === props.steps.length) return;

        if(updateState) {
            setFormWizardStateFunc({ activeStep: (activeStepLocal + 1) });
            activeStepLocal !== props.steps.length && addStylesToStepper(activeStepLocal - 1);
        } 
        else activeStepLocal !== props.steps.length - 1 && addStylesToStepper(activeStepLocal);
        
        return setTimeout(() => setCompletedIcon());
    };

    const handleBack = (updateState = true) => {    
        document.querySelectorAll('.MuiStepConnector-line')[(activeStepLocal - 1)].style.borderColor = diabledColor;

        if(Storage.getCookie('user').theme !== 'ds-white') document.querySelectorAll('.MuiStepConnector-line')[(activeStepLocal - 1)].style.boxShadow = 'none';
        
        updateState && setFormWizardStateFunc({ activeStep: (activeStepLocal - 1) });
    };

    const getFormValues = (values, step, steps) => {
        return Object.keys(values).reduce(
            (prev, next) => {
                return Object.keys(steps[step].values).indexOf(next) === -1 ?
                    prev : {...prev, [next]: values[next]}
            }, {}
        );
    }

    return (
        <div className={"stepper-wizard " + props.classes.root}>
            <div className="stepper-wizard__stepper">
                {
                    (formWizardState.activeStep !== props.steps.length )
                        && <ThemeProvider theme={theme}>
                            <Stepper activeStep={formWizardState.activeStep} alternativeLabel>
                                {props.steps.map(step => (
                                    <Step key={step.label}>
                                    <StepLabel>{step.label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </ThemeProvider>
                }
            </div>
            <div className="stepper-wizard__body">
                {
                    (!formWizardState.rerenderForm && (formWizardState.activeStep !== props.steps.length))
                        && <div className={`form-description ${props.classes.instructions}`}>
                            <BaseForm 
                                schema = {props.steps[formWizardState.activeStep].schema}
                                values = {getFormValues(formWizardState.finalForm, formWizardState.activeStep, props.steps)}
                                groups = {props.steps[formWizardState.activeStep].groups}
                                onSubmit = {formSubmit}
                                contentWrapper = {
                                    {
                                        component: DialogContent, 
                                        props: {className:`clearfix`}
                                    }
                                }
                                buttonsWrapper = {{component:DialogActions, props:{}}}
                                submitButtonOptions = {submitButtonOptions()}
                                renderExtraButtons = {({isSubmitting, values, errors}) => (
                                    <button disabled={!formWizardState.activeStep} onClick={formGoBack}
                                            className={`main-cta previous ${props.classes.backButton}`}>
                                        <SmallArrowIcon className="small-triangle prev svg-path" />
                                        <div className="main-cta__text">Previous</div>
                                    </button>
                                )}
                                renderExtraButtonsAfter = {({isSubmitting, values, errors}) => (
                                    !props.editEvent ? '' :
                                        props.deleteButton && 
                                        <button type="button" className="main-cta danger" onClick={() => onDelete(values)}>
                                            <div className="main-cta__text">Delete</div>
                                        </button>
                                )} 
                            >
                            </BaseForm>
                        </div>
                }
            </div>
            <div className="stepper-wizard__footer">
                {
                    (formWizardState.activeStep !== props.steps.length) 
                        && <React.Fragment>{ (props.canRenderButtons) && <React.Fragment>
                                <button disabled={!formWizardState.activeStep || props.steps[formWizardState.activeStep].invalidBack} onClick={handleBack} className={`main-cta ${props.classes.backButton}`}>
                                    <div className="main-cta__text">Back</div>
                                </button>
                                <button className="main-cta" variant="contained" color="primary" disabled={props.steps[formWizardState.activeStep].invalidNext} onClick={handleNext}>
                                    <div className="main-cta__text">{formWizardState.activeStep === props.steps.length - 1 ? `${props.finalLabel}` : 'Next'}</div>
                                </button>
                            </React.Fragment> }
                        </React.Fragment>
                }
            </div>
        </div>
    );
}

FormWizard.propTypes = {
  steps: PropTypes.arrayOf({}).isRequired,
  classes: PropTypes.object.isRequired,
  finalLabel: PropTypes.string,
  canRenderButtons: PropTypes.bool,
  editEvent: PropTypes.bool,
  onDelete: PropTypes.func,
  modalClose: PropTypes.func,
  onSubmit: PropTypes.func,
  typeHandler: PropTypes.func
};
FormWizard.defaultProps = {
  finalLabel: 'Finish',
  canRenderButtons: true,
  editEvent: false,
  onDelete: () => {},
  modalClose: () => {},
  onSubmit: () => {},
  typeHandler: () => {}
};

export default withTheme(withStyles(_USESTYLE)(FormWizard));
export { defaultWizardStepValues };