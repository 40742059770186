import pieChartOptions from "components/charts/pieChart/pieChartOptions";
import Utilities from "Utilities/global";
import ThemeColors from "sass/_themeColorsForJs.scss";
import { entries, omitBy, omit, isNil } from "lodash";

const setSeries = (data) => {
	let series = [
		{
			type: "pie",
			size: "80%",
			innerSize: "70%",
			showInLegend: true,
			colors: [],
			data: entries(omitBy(omit(data, ["totalVessels"]), isNil))
			.map(([key, value]) => {
				return {
					name: key === 'Unrated' ? key : `Class ${key}`,
					y: value.percentage,
					color:  ThemeColors[key === 'Unrated' ? `disabled_text` : `Class${key}`],
					z: value.totalVessels
				};
			}),
			dataLabels: {
				useHTML: true,
				format: '',
			},
			point: {
				events: {
					mouseOver: function () {
						if (this.dataLabel) {
							this.dataLabel.css({
								opacity: "1",
								color: Utilities.getThemeColors.text()
							});
							this.dataLabel.show().attr({
								text: `
								<div class="activity-value-percent cii-pie-chart-onHover">
									<div style="color:${this.color};">${this.name}</div>
									<div class="table-element">${this.z} Vessels</div>
									<div> ${this.y}%</div><div class="table-element"> of Company</div>
								</div>`
							});
						}
					},
					mouseOut: function (e) {
						if (this.dataLabel) {
							this.dataLabel.css({
								opacity: "0"
							});
							this.dataLabel.hide()
						}
					}
				}
			}
		}
	];

	return series;
};

export const navigationStatusChartUpdate = ( id, response, updateState, extraChartConfigs, widget, extraInfo) => {
	const defaultData = response ? response : [];

	let options = pieChartOptions({ size: 400 });
	options.chart.width = extraInfo.navigationStatusChartUtils.progressChartOptions.width;
	options.chart.height = extraInfo.navigationStatusChartUtils.progressChartOptions.height;
	options.title.style = extraInfo.navigationStatusChartUtils.progressChartOptions.style;
	options.title.y = extraInfo.navigationStatusChartUtils.progressChartOptions.titleY;
	options.title.x = extraInfo.navigationStatusChartUtils.progressChartOptions.titleX;
	options.series = setSeries(defaultData);
	options.plotOptions.pie.dataLabels =
		extraInfo.navigationStatusChartUtils?.dataLabels;
		options.chart.id = id + widget?.id;
	options.plotOptions.pie.dataLabels.distance = '-100%'
	options.legend = {
		layout: "horizontal",
		align: "center",
		verticalAlign: "bottom",
		itemDistance: 10,
		itemMarginTop: 2,
		itemMarginBottom: 2,
		width: 160,
		className:"primary_text",
		itemStyle: {
			fontSize: "12px",
			fontFamily: "sans-serif",
		}
	};
	updateState(id, options, widget?.id);
};