import React from 'react';
import PercentageInfo from "../../percentageInfo/percentageinfo";
import {setPowerPenaltyData} from 'widgets/vesselData/foulingPenalty.js';
import Utilities from "Utilities/global";
import ThemeColors from "sass/_themeColorsForJs.scss";

const HullFoulingPenalty = (props) => {

    return(
        <PercentageInfo 
            progressChartOptions={{
                id: `power-penalty-progress${props?.widget?.id ? props?.widget?.id : ''}`, 
                titleY: 15, 
                width: 160, 
                export: false,
                colors: [ThemeColors['value_text']], 
                backColor: Utilities.getThemeColors.backColor(),
                style: {
                    color: ThemeColors['value_text'],
                    fontSize: "20px", fontFamily: "sans-serif"
                },
                insideText: props?.data?.data ? setPowerPenaltyData(props?.data?.data)?.powerPenaltyInnerText : '',
                load: setPowerPenaltyData(props?.data?.data)?.todayPowerPenalty,
            }}
            legReportsWidget={props?.legReportsWidget}
        /> 
    )
}
 
export default HullFoulingPenalty;