import React from 'react';
import PropTypes from 'prop-types';

const TextButton = ({components, extraProps, extraClasses}) => {
    return(
        <div 
            className={`text-btn flex-centered ${extraClasses}`}
            {...extraProps}    
        >
            {
                components.map((component, index) => <div className='textOrIcon' key={index}>
                    {component}
                </div>)
            }
        </div>
    )
}

TextButton.propTypes = {
    components: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.element, 
            PropTypes.string
        ])
    ),
    extraProps: PropTypes.shape({}),
    extraClasses: PropTypes.string
}

export default TextButton;