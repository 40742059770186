import React from 'react';
import classnames from 'classnames';

const Toaster = (props) => {
    const getToasterClassnames = () => {return classnames('toasterPopup', props.toasterClasses);}

    return(
        <div className={getToasterClassnames()}>
            <props.toasterComponent {...props} />
        </div>
    )
}

export default Toaster;