import React from 'react';
import {ReactComponent as DatePickerIcon} from 'assets/img/app/global-imgs/calendar.svg';
import classnames from 'classnames';

const ReportPeriodComponent = (props) => {
    const from = props.from;
    const to = props.to;
    const fullHeight = props.fullHeight;
    const getReportPeriodBodyClasses = () => {
        return classnames("reportPeriod-body ", {"reportPeriod-body-centered": fullHeight})
    }
    const getReportPeriodClasses = () => {
        return classnames("reportPeriod", {"reportPeriod-fullHeight": fullHeight})
    }
    return(
        <div className = {getReportPeriodClasses()}>
            <div className = {getReportPeriodBodyClasses()}>
                <div className = 'reportPeriod-body-icon'>
                    <DatePickerIcon className="calendar-icon" width={35} height={35} />
                </div>
                <div className = 'reportPeriod-body-dates'>
                    <div className = 'reportPeriod-body-dates-date'>{from} - {to}</div> 
                    <div className = 'reportPeriod-body-dates-period'>Report Period</div>
                </div>
            </div>
        </div>
    )
}

export default ReportPeriodComponent;