import Utilities from 'Utilities/global';
import moment from 'moment';
import overviewStorage from 'components/monitoringCategories/localStorages/overviewStorage';
import {licensing} from "common/store/licensing";

const fuelConsumpChartPayloadLight = {
    xAxis: "TIME",
    timeGroup: 'DAY',
    metrics: [{
        metricCategory: 'TELEGRAM',
        subIds: [1],
        metricData: [{ 
            metricName: "totalFOMassRate_calc",
            aggregation: "SUM"
        }, {
            metricName: "totalCO2",
            aggregation: "SUM"
        }]
    }]
}
const fuelConsumpChartPayload = {
    xAxis: "TIME",
    aggregation: "SUM",
    timeGroup: 'DAY',
    metrics: [{
        metricCategory: 'VESSEL',
        subIds: [1],
        metricData: [{
            metricName: "totalFOMassRate_calc"
        }, {
            metricName: "totalCO2"
        }]
    }]
}

const fuelConsumpChartUpdate = (id, response, updateState) => {
    const data = [[], []];
    const responseData = licensing.lightCondition() ? response.series : response;

    overviewStorage.setFuelConsumpChartData(response);

    if (responseData && responseData.length > 0 && responseData[0] && responseData[0].values.length > 0) {
        responseData[0].values.forEach(element => {
            if (element.metricName !== 'totalCO2') {
                element.value && element.value.forEach(valueRow => {
                    data[0].push({
                        x: valueRow.x,
                        y: Utilities.roundToDigits(valueRow.y, 2),
                        tooltipText: licensing.lightCondition() ? `${moment(valueRow.telegramPeriod.dateFrom).utc().format('DD/MM')} - ${moment(valueRow.telegramPeriod.dateTo).utc().format('DD/MM')}` : moment(valueRow.x).utc().format('DD/MM')
                    })
                })
            } else {
                element.value && element.value.forEach(valueRow => {
                    data[1].push({
                        x: valueRow.x,
                        y: Utilities.roundToDigits(valueRow.y, 2),
                        tooltipText: licensing.lightCondition() ? `${moment(valueRow.telegramPeriod.dateFrom).utc().format('DD/MM')} - ${moment(valueRow.telegramPeriod.dateTo).utc().format('DD/MM')}` : moment(valueRow.x).utc().format('DD/MM')
                    })
                })
            }
        })
    }
    updateState(id, data)
}

export { fuelConsumpChartUpdate, fuelConsumpChartPayload, fuelConsumpChartPayloadLight }