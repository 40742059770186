import highstockBarChartOptions from "components/charts/barChart/highstock-barChart";
import {licensing} from "../../common/store/licensing";
import moment from "moment";
import geStorage from "components/monitoringCategories/localStorages/geStorage";
import { ThemeColorsForCharts } from "Utilities/themeColorsForCharts";
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import { monitoringStore, vesselUtils } from 'common/store/storeUtils';
import GlobalUtilities from "Utilities/global";

export const fuelBarChartPayload = {
    xAxis: "TIME",
    aggregation: "SUM",
    timeGroup: 'DAY',
    withMetadata: true,
    metrics: [
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {
                    metricName: "commonDGFOMassRate"
                }
            ]
        },
    ]
};

export const fuelBarChartPayload_light = {
    xAxis: "TIME",
    aggregation: "SUM",
    timeGroup: 'DAY',
    withMetadata: true,
    metrics: [
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [{
                metricName: "ge_1_foConsumption_calc"
            }]
        }
    ]
};

const yAxisData = [
    {id: 'foBar-axis', title: {text: 'Fuel Consumption (mt/day)'}, yMin: 0, min: 0, opposite: false}
];

const seriesData = [
    {
        name: 'Sensor FC',
        legendTitle: 'Fuel Consumption',
        data: [],
        tooltipTitle: 'Sensor FC',
        yAxis: 'foBar-axis',
        metadata: {cssRule: 'background-color:', tooltipBackgroundColor: "#3d7af8"},
        tooltip: {valueSuffix: ' mt/day', valueDecimals: 2},
        type: 'column',
        yMin: 0
    },
    {
        name: 'Missing Data', legendTitle: 'Missing Data', data: [], tooltipTitle: 'FCR', yAxis: 'foBar-axis',
        metadata: {cssRule: 'background-color:', tooltipBackgroundColor: "#204175", title: 'missing data', suffix: '%'},
        min: 0, tooltip: {valueSuffix: ' mt/day', valueDecimals: 2}, type: 'column', yMin: 0, showInLegend: false
    }
];

const seriesDataLight = [
    {
        name: 'Report FC',
        legendTitle: 'Fuel Consumption',
        data: [],
        tooltipTitle: 'Report FC',
        yAxis: 'foBar-axis',
        metadata: {cssRule: 'background-color:', tooltipBackgroundColor: "#3d7af8"},
        tooltip: {valueSuffix: ' mt/day', valueDecimals: 2},
        type: 'column',
        yMin: 0
    }
];



export const updateFuelBarChart = (id, data, updateState, extraChartConfigs, widget) => {
    geStorage.setFuelBarChartData(data);
    let tooltipHeader = '{point.reportPeriod}'

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));
    const { from, to } =  GlobalUtilities.getDetailedGranularityPeriod()
    const isDetailedGranularity = monitoringStore().lessThanADayPeriod.status || monitoringStore().detailedData.status

    let fuelBarChartJson = { ...highstockBarChartOptions(":%d/%m", null, isLight ? tooltipHeader : false, isDetailedGranularity) };
    fuelBarChartJson.yAxis = [...yAxisData];
    fuelBarChartJson.series = isLight ? JSON.parse(JSON.stringify(seriesDataLight)) : JSON.parse(JSON.stringify(seriesData));
    fuelBarChartJson.chart = {...fuelBarChartJson.chart, height: 500, marginTop: 95, marginLeft: 81, marginRight: 38};
    fuelBarChartJson.plotOptions.column.pointWidth = 29;
    fuelBarChartJson.tooltip = {...fuelBarChartJson.tooltip, split: false, xDateFormat: '%d/%m'};
    if(fuelBarChartJson.xAxis.title.text) fuelBarChartJson.xAxis.title.text = 'Days';
    fuelBarChartJson.legend = {
        enabled: true,
        align: 'center',
        layout: 'horizontal',
        verticalAlign: 'top',
        itemMarginTop: 20,
        itemMarginBottom: 25,
        itemDistance: 77,
        itemStyle: {
            "fontSize": "11px",
            "fontWeight": "600"
        }
    };

    fuelBarChartJson.exporting.filename = 'GENERATOR ENGINES FUEL CONSUMPTION';

    // Missing Data Configuration - On light vessels, do not display missing data
    if(!isLight) {
        fuelBarChartJson.series[1].color = ThemeColorsForCharts()['missing_first_stack_sensor'];
        fuelBarChartJson.series[1].data = data[0] ? data[0].values[0].missingData.map(point => point = {
            x: point.x,
            y: point.y / 1000,
            missingValues: point.missingValues.toFixed(1)
        }) : [];
    }

    // Sensor/Telegram Data Configuration - On light vessels, change colors
    fuelBarChartJson.series[0].color = isLight ? ThemeColorsForCharts()['reports_color'] : ThemeColorsForCharts()['sensor_color'];
    fuelBarChartJson.series[0].data = data[0] ? data[0].values[0].value.map(point => point = {
        x: point.x,
        y: isLight ? parseFloat(point.y) : point.y/1000,
        reportPeriod: isLight ?
            moment(point?.period?.timestampFrom).utc().format('DD/MM HH:mm') + ' - ' + moment(point?.period?.timestampTo).utc().format('DD/MM HH:mm')
            : '',
        period: (from && to ) && GlobalUtilities.periodString(data[0].values[0], point.x, point.y, {from, to})
    }) : [];

    fuelBarChartJson.chart.events = {
        load: function () {
            if (moment(registerWidgetsStore.fromTo.to).diff(moment(registerWidgetsStore.fromTo.from), 'days') > 7) {
                const chart = this,
                    series = [...this.series[0]?.xData, ...this.series[1]?.xData].sort(function(a, b){
                        return moment.utc(a).diff(moment.utc(b))
                    }),
                    xAxis = chart.xAxis[0],
                    newStart = series[series.length - 7],
                    newEnd = series[series.length - 1];

                // if the from-to difference is larger than 7 days, we get the whole datetime and configure zoom accordingly
                if (moment(registerWidgetsStore.fromTo.to).diff(moment(registerWidgetsStore.fromTo.from), 'days') > 7) xAxis.setExtremes(newStart, newEnd);
            }


            // For detailed data, display only the day's centered column
            if (moment(registerWidgetsStore.fromTo.to).diff(moment(registerWidgetsStore.fromTo.from), 'days') < 1 && !isLight) {
                fuelBarChartJson.series[0].centerInCategory = true;
                fuelBarChartJson.series[1].centerInCategory = true;
                if (fuelBarChartJson.series[0].data.length > 0) fuelBarChartJson.series[1].data = [];
            }
        }
    }

    const chartHasData = fuelBarChartJson.series.find(serie => serie?.data?.length > 0);
    if(!chartHasData) fuelBarChartJson = {};

    updateState(id, fuelBarChartJson, widget?.id);
}
