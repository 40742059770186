import React from 'react';
import {ReactComponent as WayPoint} from 'assets/img/app/monitoring-imgs/waypoint.svg';
import { useSelector, useDispatch } from 'react-redux';
import { monitoringUtils } from 'common/store/storeUtils';

const ViewAllPopup = () => {
    // store vars
    const monitoringStoreJSX = useSelector(state => state.monitoring);
    const dispatch = useDispatch();

    const resetLegView = () => monitoringUtils.resetLegView(dispatch);

    return(
        <>
            {monitoringStoreJSX.detailedData.status && (
                <div className = "viewAllPopupMonitoring">
                    <div className = "viewAllPopupMonitoring-main">
                        <WayPoint />
                        <div className = "viewAllPopupMonitoring-main-viewMode">
                            <div className = "title" > 
                                Waypoint view mode
                            </div>
                            <div className = "text" >
                                Focused on the period of the selected waypoint
                            </div>
                        </div>
                        <div className = "seperator" />
                        <button type="btn" className="cta2-btn exitViewAllButton" onClick={resetLegView}>EXIT</button>
                    </div>
                </div>
            )}
        </>

    )
}

export default ViewAllPopup;