import radialOptions from 'components/charts/radial/radialOptions';
import moment from 'moment';
import Utilities from 'Utilities/global';
import Highcharts from 'highcharts';
import {licensing} from "common/store/licensing";
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import {userStore, vesselUtils} from 'common/store/storeUtils';

const userTheme = userStore().user?.theme !== 'ds-white' ? 'darkTheme' : 'whiteTheme';

const legFuelConsumptionPayload = {
    metrics:[
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {
                    metricName: "foVolConsumption",
                    aggregation: "SUM"
                }
            ]
        },
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {
                    metricName: "commonDGFOMassRate",
                    aggregation: "SUM"
                }
            ]
        },
        {
            metricCategory: "BOILER",
            subIds: [1],
            metricData: [
                {
                    metricName: "boilerCommonFoMassRate", 
                    aggregation: "SUM"
                }
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "me_1_foConsumption_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "ge_1_foConsumption_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "bo_1_foConsumption_calc", 
                    aggregation: "SUM"
                },
            ]
        }
    ],
    withMetadata: true
};

const legFuelConsumption = (id, response, updateState, extraChartConfigs, widget) => {
    if(response && response[0] && response[0]?.series?.length === 0) return updateState(id, {}, widget?.id);
    let data = {me: {...radialOptions()}, ge: {...radialOptions()}, bo: {...radialOptions()}};
    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    data.ge.title.text = 'MAIN ENGINE';
    data.ge.title.text = 'GENERATOR ENGINE';
    data.bo.title.text = 'BOILERS';

    if(response.length > 0) {
        data.me.series = response.map(timespampGroup => {
            if(timespampGroup.series && timespampGroup.series.length > 0 && timespampGroup.series[0].values && timespampGroup.series[0].values.length > 0) {
                const meSensorValue = timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'foVolConsumption').length > 0 
                    ? timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'foVolConsumption')[0].value/1000 : null;
                const geSensorValue = timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'commonDGFOMassRate').length > 0 
                    ? timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'commonDGFOMassRate')[0].value/1000 : null;
                const boSensorValue = timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'boilerCommonFoMassRate').length > 0 
                    ? timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'boilerCommonFoMassRate')[0].value/1000 : null;
                const meTelegramValue = timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'me_1_foConsumption_calc').length > 0 
                    ? isNaN(parseFloat(timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'me_1_foConsumption_calc')[0].value)) ? 0
                    : parseFloat(timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'me_1_foConsumption_calc')[0].value) : null;
                const geTelegramValue = timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'ge_1_foConsumption_calc').length > 0 
                    ? isNaN(parseFloat(timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'ge_1_foConsumption_calc')[0].value)) ? 0
                    : parseFloat(timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'ge_1_foConsumption_calc')[0].value) : null;
                const boTelegramValue = timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'bo_1_foConsumption_calc').length > 0 
                    ? isNaN(parseFloat(timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'bo_1_foConsumption_calc')[0].value)) ? 0
                    : parseFloat(timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'bo_1_foConsumption_calc')[0].value) : null;

                const meMissingValue = timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'foVolConsumption').length > 0 
                    ? timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'foVolConsumption')[0].missingValues > Utilities.missingValuesLimit
                    ? timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'foVolConsumption')[0].missingValues.toFixed(1) : null : null;
                const geMissingValue = timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'commonDGFOMassRate').length > 0 
                    ? timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'commonDGFOMassRate')[0].missingValues > Utilities.missingValuesLimit
                    ? timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'commonDGFOMassRate')[0].missingValues.toFixed(1) : null : 0;
                const boMissingValue = timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'boilerCommonFoMassRate').length > 0 
                    ? timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'boilerCommonFoMassRate')[0].missingValues > Utilities.missingValuesLimit
                    ? timespampGroup.series[0].values.filter(metricObj => metricObj.metricName === 'boilerCommonFoMassRate')[0].missingValues.toFixed(1) : null : 0;

                const meSensorSeries = {
                    z: 'Sensor GE FCR', 
                    y: isLight ? null : geSensorValue,
                    color: geMissingValue ? ThemeColorsForCharts()['missing_first_stack_sensor'] : ThemeColorsForCharts()['sensor_color'],
                    backgroundColor: geMissingValue ? Highcharts[userTheme].tooltip.missingValuesBackgroundColor : Highcharts[userTheme].tooltip.backgroundColor,
                    missingValues: geMissingValue
                };

                const meTelegramsSeries = {
                    z: 'Report GE FCR',
                    y: geTelegramValue, 
                    backgroundColor: ThemeColorsForCharts()['sensor_color'],
                    color: ThemeColorsForCharts()['reports_color'],
                    missingValues: ''
                };

                const getMeFullSeries = () => {
                    return [meSensorSeries, meTelegramsSeries];
                }

                const getMeLightSeries = () => {
                    return [meTelegramsSeries];
                }

                data.ge.series.push({
                    name: `${moment(timespampGroup.fromDate).utc().format('DD/MM HH:mm')} - ${moment(timespampGroup.toDate).utc().format('DD/MM HH:mm')}`,
                    data: isLight ? getMeLightSeries() : getMeFullSeries(),
                    tooltip: { valueDecimals: 1, valueSuffix: ' MT' }
                });
                
                const boSensorSeries = {
                    z: 'Sensor BO FCR', 
                    y: isLight ? null : boSensorValue,
                    color: boMissingValue ? ThemeColorsForCharts()['missing_first_stack_sensor'] : ThemeColorsForCharts()['sensor_color'],
                    backgroundColor: boMissingValue ? Highcharts[userTheme].tooltip.missingValuesBackgroundColor : Highcharts[userTheme].tooltip.backgroundColor,
                    missingValues: boMissingValue
                };

                const boTelegramsSeries = {
                    z: 'Report BO FCR',
                    y: boTelegramValue, 
                    backgroundColor: ThemeColorsForCharts()['sensor_color'],
                    color: ThemeColorsForCharts()['reports_color'],
                    missingValues: ''
                };

                const getBoFullSeries = () => {
                    return [boSensorSeries, boTelegramsSeries];
                }

                const getBoLightSeries = () => {
                    return [boTelegramsSeries];
                }

                data.bo.series.push({
                    name: `${moment(timespampGroup.fromDate).utc().format('DD/MM HH:mm')} - ${moment(timespampGroup.toDate).utc().format('DD/MM HH:mm')}`,
                    data: isLight ? getBoLightSeries() : getBoFullSeries(),
                    tooltip: { valueDecimals: 1, valueSuffix: ' MT' }
                });

                const geSensorSeries = {
                    z: 'Sensor ME FCR', 
                    y: isLight ? null : meSensorValue,
                    backgroundColor: meMissingValue ? Highcharts[userTheme].tooltip.missingValuesBackgroundColor : Highcharts[userTheme].tooltip.backgroundColor,
                    color: meMissingValue ? ThemeColorsForCharts()['missing_first_stack_sensor'] : ThemeColorsForCharts()['sensor_color'],
                    missingValues: meMissingValue
                };

                const geTelegramsSeries = {
                    z: 'Report ME FCR', 
                    y: meTelegramValue, 
                    backgroundColor: ThemeColorsForCharts()['sensor_color'],
                    color: ThemeColorsForCharts()['reports_color'],
                    missingValues: ''
                };

                const getGeFullSeries = () => {
                    return [geSensorSeries, geTelegramsSeries];
                }

                const getGeLightSeries = () => {
                    return [geTelegramsSeries];
                }

                return {
                    name: `${moment(timespampGroup.fromDate).utc().format('DD/MM HH:mm')} - ${moment(timespampGroup.toDate).utc().format('DD/MM HH:mm')}`,
                    data: isLight ? getGeLightSeries() : getGeFullSeries(),
                    tooltip: { valueDecimals: 1, valueSuffix: ' MT' }
                };
            }

            data.ge.series.push({
                name: ``,
                data: null
            });

            data.bo.series.push({
                name: ``,
                data: null
            });

            return {
                name: '',
                data: null
            }
        });
    }

    updateState(id, {
        radials: {
            me: data.me,
            ge: data.ge,
            bo: data.bo
        }
    }, widget?.id);
}

export {legFuelConsumptionPayload, legFuelConsumption};
