
const ComponentsIndex = {
    averageWind: require('../../reports/infoDeviderBox.jsx').default,
    averageCurrents: require('../../reports/infoDeviderBox.jsx').default,
    averageRPM: require('../../reports/infoDeviderBox.jsx').default,
    sog: require('../../reports/infoDeviderBox.jsx').default,
    vesselLog: require('../../components/eventTypeList/eventTypeList').default,
    hullInfo: require('../../components/monitoringCategories/hullComponents/hullInfo').default,
    meInfo: require('../../components/monitoringCategories/meComponents/mainEngineInfo').default,
    meLoad_hourly: require('../../components/monitoringCategories/meComponents/mainEngineLoad.jsx').default,
    meFC_daily: require('../../components/monitoringCategories/meComponents/mainEngineFuelBarChart.jsx').default,
    meFC_hourly: require('../../components/monitoringCategories/meComponents/mainEngineFuelLineChart.jsx').default,
    dailyGeFc: require('../generatorEngine/fuelConsumptionChartGE.jsx').default,
    hourlyGeFc: require('../generatorEngine/fuelConsumptionChartGE.jsx').default,
    vesselRoute: require('../../components/map/map.jsx').default,
    meDetails_hourly: require('../../components/syncedChartWithTabNavigation/syncedChartWithTabNavigation.jsx').default,
    mePistons_hourly: require('../../components/syncedChartWithTabNavigation/syncedChartWithTabNavigation.jsx').default,
    meTC_hourly: require('../../components/syncedChartWithTabNavigation/syncedChartWithTabNavigation.jsx').default,
    mePistonsdeviation_hourly: require('../mainEngine/deviationBarChart.jsx').default,
    geLoad_hourly: require('../../widgets/generatorEngine/loadHistoryLineChart.jsx').default,
    geLoad_hourly_leg: require('../../widgets/generatorEngine/loadHistoryLineChart.jsx').default,
    navigationStatusChart: require('../../components/monitoringCategories/overviewComponents/navigationStatusChart.jsx').default,
    vesselMonitoring: require('../../components/monitoringCategories/overviewComponents/vesselMonitoringChart.jsx').default,
    vesselMonitoring_leg: require('../../components/monitoringCategories/overviewComponents/vesselMonitoringChart.jsx').default,
    splittedFC: require('../../components/charts/barChart/barChart.jsx').default,
    splittedGroupFC: require('../../components/charts/barChart/barChart.jsx').default,
    groupLog: require('../../components/componentWithIcon/componentWithIcon.jsx').default,
    vesselRating: require('../../components/monitoringCategories/overviewComponents/vesselRating.jsx').default,
    groupInfo: require('../../components/table/table.jsx').default,
    destinationCard: require('../../reports/vesselTripPorts.jsx').default,
    stoppageInfo: require('../vesselData/stoppageInfo.jsx').default,
    travellingInfo: require('../vesselData/travellingInfo.jsx').default,
    travellingInfo_leg: require('reports/valuesTitlesIconsInline.jsx').default,
    mapVessel: require('../../components/map/dashboardMap.jsx').default,
    mapGroup: require('../../components/map/dashboardMap.jsx').default,
    mapCompany: require('../../components/map/dashboardMap.jsx').default,
    legMapVessel: require('../../components/map/dashboardMap.jsx').default,
    splittedFC_leg: require('../../reports/legFuelConsumption.jsx').default,
    geFC_daily: require('../generatorEngine/fuelBarChart.jsx').default,
    geFC_hourly: require('../generatorEngine/fuelLineChart.jsx').default,
    geTC_hourly: require('../../components/syncedChartWithTabNavigation/syncedChartWithTabNavigation.jsx').default,
    geRunningTime_hourly: require('../../widgets/generatorEngine/runningTime.jsx').default,
    geRunningTime_hourly_leg: require('../../widgets/generatorEngine/runningTime.jsx').default,
    geDetails_hourly: require('../../components/monitoringCategories/geComponents/generatorEngineDetails.jsx').default,
    boRunningTime_hourly: require('../boilers/runningTime.jsx').default,
    boFC_daily: require('../../widgets/boilers/fuelBarChart.jsx').default,
    boFC_hourly: require('../../widgets/boilers/fuelLineChart.jsx').default,
    boDetails_hourly: require('../../components/syncedChartWithTabNavigation/syncedChartWithTabNavigation.jsx').default,
    pvCurves: require('../vesselData/arbitraryPvFv.jsx').default,
    fvCurves: require('../vesselData/arbitraryPvFv.jsx').default,
    hullPowerMonitoring: require('../../components/monitoringCategories/hullComponents/hullPowerMonitoring.jsx').default,
    hullFoulingWaste: require('../../widgets/vesselData/foulingWaste.jsx').default,
    hullFoulingWaste_leg: require('../../widgets/vesselData/foulingWaste.jsx').default,
    hullFoulingPenalty: require('../../components/monitoringCategories/hullComponents/hullFoulingPenalty.jsx').default,
    hullFoulingPenalty_leg: require('../../components/monitoringCategories/hullComponents/hullFoulingPenalty.jsx').default,
    totalFuelConsumption_leg: require('../../reports/totalFuelConsumption.jsx').default,
    generalKPIS_leg: require('components/table/table').default,
    loadCondition_leg: require('reports/valuesTitlesIconsInline').default,
    
    meSfocPower_monthly: require('components/charts/lineChart/lineChart').default,
    meSfocPower_weekly: require('components/charts/lineChart/lineChart').default,
    meSfocPower_leg: require('components/charts/lineChart/lineChart').default,

    meFCPower_monthly: require('components/charts/lineChart/lineChart').default,
    meFCPower_weekly: require('components/charts/lineChart/lineChart').default,
    meFCPower_leg: require('components/charts/lineChart/lineChart').default,
    
    mePowerRpm_monthly: require('components/charts/lineChart/lineChart').default,
    mePowerRpm_weekly: require('components/charts/lineChart/lineChart').default,
    mePowerRpm_leg: require('components/charts/lineChart/lineChart').default,
    
    mePscavPower_monthly: require('components/charts/lineChart/lineChart').default,
    mePscavPower_weekly: require('components/charts/lineChart/lineChart').default,
    mePscavPower_leg: require('components/charts/lineChart/lineChart').default,
    
    turbochargerRpmPower_monthly: require('components/charts/lineChart/lineChart').default,
    turbochargerRpmPower_weekly: require('components/charts/lineChart/lineChart').default,
    turbochargerRpmPower_leg: require('components/charts/lineChart/lineChart').default,
    
    turbochargerPscavRpm_monthly: require('components/charts/lineChart/lineChart').default,
    turbochargerPscavRpm_weekly: require('components/charts/lineChart/lineChart').default,
    turbochargerPscavRpm_leg: require('components/charts/lineChart/lineChart').default,
};

export default ComponentsIndex;
