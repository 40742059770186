import React, { useState } from "react";

const SegmentButtons = (props) => {
	const [active, setActive] = useState(props.defaultActive || '');

	const handleChange = value => {
		setActive(value);
		props.setSelectedSegment(value);
	}

	return (
		<div className="segment-buttons flex-centered-start">
			{props.options.map((input, index) => {
				return (
					<div
						className={`flex-centered segment-buttons__iconButton headline4 ${
							active === input.value ? "active" : ""
						}`}
						key={index}
						onClick={() => handleChange(input.value)}
					>
						{input.label}
					</div>
				);
			})}
		</div>
	);
};

export default SegmentButtons;
