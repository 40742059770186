import React from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const mainEngineLoad = (props) => {
    return(
        <div>
            {props.data !== "" && (
                    <HighchartsReact highcharts={Highcharts} options={props.data} constructorType={"stockChart"} />
                )}
        </div>
    )
}

export default mainEngineLoad; 