import React from 'react';
import {ReactComponent as DefaultTemplateIcon} from 'assets/img/app/dashboard-imgs/defaultTemplateIcon.svg';
import DashboardMenuOptions from './dashBoardMenuOptions';
import NameInitials from "components/nameInitials/nameInitials";
import ClickAwayWrapper from 'components/clickAwayListener/clickAwayWrapper';
import moment from "moment";

const SavedDashboardCard = (props) => {

    const templateCardClick = (item) => {
        props.history.push(`/dashboard/${item.id}`)
    }

    const handleClickAway = () => {
        if (document?.getElementById('activeTemplate')?.id) document.getElementById('activeTemplate').removeAttribute('id');
    }

    return (
        <ClickAwayWrapper handleClick={handleClickAway} {...props}>
            <div className="templatesCard">
                <div className="templatesCard__saved" id={props.active ? 'activeTemplate' : ''}>
                    <div className="header">
                        <div className="header__title"> {props.title} </div>
                        <DashboardMenuOptions {...props} menuClassName="dashboardPopupMenu"/>
                    </div>
                    <div className="templatesCard__body pointer" onClick={() => templateCardClick(props)}/>
                    <div className="footer">
                        <div className="footer__title"> last edit: {moment(props.updatedOn).utc().format('DD/MM/YYYY')} </div>
                        {props.type === "DEEPSEA" ? <DefaultTemplateIcon/> : <NameInitials name={props.initialsCreatedBy}/>}
                    </div>
                </div>
            </div>
        </ClickAwayWrapper>
    )
}

export default SavedDashboardCard;
