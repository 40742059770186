import geStorage from "../../components/monitoringCategories/localStorages/geStorage";

export const geDetailsPayload = {
    xAxis: "TIME",
    aggregation: "AVG", 
    timeGroup: 'HOUR',
    metrics: [
      {
        metricCategory: "GENERATOR_ENGINE",
        subIds: [],
        metricData: [
          {metricName: "windingTempR", aggregation: 'AVG'},
          {metricName: "chargeAirPress", aggregation: 'AVG'},
          {metricName: "chargeAirTemp", aggregation: 'AVG'},
          {metricName: "power", aggregation: 'AVG'},
          {metricName: "starItngAirInLETPress", aggregation: 'AVG'},
          {metricName: "fOInLETPress", aggregation: 'AVG'},
          {metricName: "fOInLETTemp", aggregation: 'AVG'},
          {metricName: "hTWaterInLETPress", aggregation: 'AVG'},
          {metricName: "engineRPMPickup", aggregation: 'AVG'},
          {metricName: "No1CylExhGasTemp", aggregation: 'AVG'},
          {metricName: "lOInLETPress", aggregation: 'AVG'},
          {metricName: "lOInLETTemp", aggregation: 'AVG'},
          {metricName: "lTWaterInLETPress", aggregation: 'AVG'},
          {metricName: "lTWaterInLETTemp", aggregation: 'AVG'},
          {metricName: "hTWaterOutLETPress", aggregation: 'AVG'},
          {metricName: "lTWaterTempOutLET", aggregation: 'AVG'},
        ]
      }
    ]
}

export const geDetailsHardCodedKeys = {
    names: [
        {title: 'Generator', name: 'generator'},
        {title: 'Fuel Oil', name: 'fOil'},
        {title: 'Lube Oil', name: 'lOil'},
        {title: 'Water', name: 'water'},
        {title: 'Air', name: 'air'},
    ],
    general: {
      'windingTempR': {name: 'Winding Temp-R'},
      'chargeAirPress': {name: 'Charge Air Pressure (bar)'},
      'chargeAirTemp': {name: 'Charge Air Temperature (' + String.fromCharCode(176) + 'C)'},
      'engineRPMPickup': {name: 'Engine RPM Pick-up'},
      'power': {name: 'Power (kW)'},
      'starItngAirInLETPress': {name: 'Starting Air Inlet Pressure (bar)'},
      'fOInLETPress': {name: 'Fuel Oil Inlet Pressure (bar)'},
      'fOInLETTemp': {name: 'Fuel Oil Inlet Temperature (' + String.fromCharCode(176) + 'C)'},
      'hTWaterOutLETPress': {name: 'High Temperature Water Outlet Temperature (' + String.fromCharCode(176) + 'C)'},
      'hTWaterInLETPress': {name: 'High Temperature Water Inlet Pressure (bar)'},
      'lOInLETPress': {name: 'Lube Oil Inlet Pressure (bar)'},
      'lOInLETTemp': {name: 'Lube Oil Inlet Temperature (' + String.fromCharCode(176) + 'C)'},
      'lTWaterInLETPress': {name: 'Low Temperature Water Inlet Pressure (bar)'},
      'lTWaterInLETTemp': {name: 'Low Temperature Water Inlet Temperature (' + String.fromCharCode(176) + 'C)'},
      'lTWaterTempOutLET': {name: 'Low Temperature Water Outlet Temperature (' + String.fromCharCode(176) + 'C)'},
      'No1CylExhGasTemp': {name: 'Cylinder Exhaust Gas Temperature (' + String.fromCharCode(176) + 'C)'}
    },
    navigationMapper: {
        generator: ['windingTempR', 'engineRPMPickup', 'power', 'No1CylExhGasTemp'],
        fOil: ['fOInLETPress', 'fOInLETTemp'],
        lOil: ['lOInLETPress', 'lOInLETTemp'],
        water: ['hTWaterOutLETPress', 'hTWaterInLETPress', 'lTWaterInLETPress', 'lTWaterInLETTemp', 'lTWaterTempOutLET'],
        air: ['chargeAirPress', 'chargeAirTemp', 'starItngAirInLETPress'],
    },
    enginesMapper: {}
}

export const geDetailsConfig = {
    chartAxes: {
        generator: [
            { id: 'power-axis', title: { text: 'Power (kW)'}, height: '100px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'No1CylExhGasTemp-axis', title: { text: 'Cylinder Exhaust Gas Temp (°C)'}, height: '100px', top: '200px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'engineRPMPickup-axis', title: { text: 'Engine RPM Pick-up'}, height: '100px', top: '350px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'windingTempR-axis', title: { text: 'Winding Temp-R (°C)'}, height: '100px', top: '500px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        ],
        fOil: [
            { id: 'fOInLETPress-axis', title: { text: 'Fuel Oil Inlet Pressure (bar)'}, height: '200px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'fOInLETTemp-axis', title: { text: 'Fuel Oil Inlet Temperature (°C)'}, height: '200px', top: '370px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        ],
        lOil: [
            { id: 'lOInLETPress-axis', title: { text: 'Lube Oil Inlet Pressure (bar)'}, height: '200px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'lOInLETTemp-axis', title: { text: 'Lube Oil Inlet Temperature (°C)'}, height: '200px', top: '370px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        ],
        water: [
            { id: 'lTWaterInLETTemp-axis', title: { text: 'Low Temperature Water Inlet Temperature (°C)'}, height: '80px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'lTWaterTempOutLET-axis', title: { text: 'Low Temperature Water Outlet Temperature (°C)'}, height: '80px', top: '170px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'lTWaterInLETPress-axis', title: { text: 'Low Temperature Water Inlet Pressure (bar)'}, height: '80px', top: '290px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'hTWaterOutLETPress-axis', title: { text: 'High Temperature Water Outlet Temperature (°C)'}, height: '80px', top: '410px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'hTWaterInLETPress-axis', title: { text: 'High Temperature Water Inlet Pressure (bar)'}, height: '80px', top: '530px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },        
        
        ],
        air: [
            { id: 'starItngAirInLETPress-axis', title: { text: 'Starting Air Inlet Pressure (bar)'}, height: '130px', top: '50px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'chargeAirPress-axis', title: { text: 'Charge Air Pressure (bar)'}, height: '130px', top: '250px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
            { id: 'chargeAirTemp-axis', title: { text: 'Charge Air Temperature (°C)'}, height: '130px', top: '450px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false, min : 0 },
        ],
    },
    chartSeries: {
        generator: [
            { id: 'power', name: 'Power', data: [], tooltipTitle: 'Power', type: 'line', yAxis: 'power-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' kW', valueDecimals: 2 } },
            { id: 'No1CylExhGasTemp', name: 'Cylinder Exhaust Gas Temp', data: [], tooltipTitle: 'Cylinder Exhaust Gas Temp', type: 'line', yAxis: 'No1CylExhGasTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: 'engineRPMPickup', name: 'Engine RPM Pick-up', data: [], tooltipTitle: 'Engine RPM Pick-up', type: 'line', yAxis: 'engineRPMPickup-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: '', valueDecimals: 2 } },
            { id: 'windingTempR', name: 'Winding Temp-R', data: [], tooltipTitle: 'Winding Temp-R', type: 'line', yAxis: 'windingTempR-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
        ],
        fOil: [
            { id: 'fOInLETPress', name: 'Fuel Oil Inlet Pressure', data: [], tooltipTitle: 'Fuel Oil Inlet Pressure', type: 'line', yAxis: 'fOInLETPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'fOInLETTemp', name: 'Fuel Oil Inlet Temperature', data: [], tooltipTitle: 'Fuel Oil Inlet Temperature', type: 'line', yAxis: 'fOInLETTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
        ],
        lOil: [
            { id: 'lOInLETPress', name: 'Lube Oil Inlet Pressure', data: [], tooltipTitle: 'Lube Oil Inlet Pressure', type: 'line', yAxis: 'lOInLETPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'lOInLETTemp', name: 'Lube Oil Inlet Temperature', data: [], tooltipTitle: 'Lube Oil Inlet Temperature', type: 'line', yAxis: 'lOInLETTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
        ],
        water: [
            { id: 'lTWaterInLETTemp', name: 'Low Temperature Water Inlet Temperature', data: [], tooltipTitle: 'Low Temperature Water Inlet Temperature', type: 'line', yAxis: 'lTWaterInLETTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: 'lTWaterTempOutLET', name: 'Low Temperature Water Outlet Temperature', data: [], tooltipTitle: 'Low Temperature Water Outlet Temperature', type: 'line', yAxis: 'lTWaterTempOutLET-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: 'lTWaterInLETPress', name: 'Low Temperature Water Inlet Pressure', data: [], tooltipTitle: 'Low Temperature Water Inlet Pressure', type: 'line', yAxis: 'lTWaterInLETPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'hTWaterOutLETPress', name: 'High Temperature Water Outlet Temperature', data: [], tooltipTitle: 'High Temperature Water Outlet Temperature', type: 'line', yAxis: 'hTWaterOutLETPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
            { id: 'hTWaterInLETPress', name: 'High Temperature Water Inlet Pressure', data: [], tooltipTitle: 'High Temperature Water Inlet Pressure', type: 'line', yAxis: 'hTWaterInLETPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } }
        ],
        air: [
            { id: 'starItngAirInLETPress', name: 'Starting Air Inlet Pressure', data: [], tooltipTitle: 'Starting Air Inlet Pressure', type: 'line', yAxis: 'starItngAirInLETPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'chargeAirPress', name: 'Charge Air Pressure', data: [], tooltipTitle: 'Charge Air Pressure', type: 'line', yAxis: 'chargeAirPress-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' bar', valueDecimals: 2 } },
            { id: 'chargeAirTemp', name: 'Charge Air Temperature', data: [], tooltipTitle: 'Charge Air Temperature', type: 'line', yAxis: 'chargeAirTemp-axis',
                titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
        ]
    },
}

const setBarTablesDataFormat = (barsTableData) => {
    let tmpObj = {};
    barsTableData.forEach(obj => tmpObj[`${obj.metricName}No${obj.subId}`] = obj.value);
    return tmpObj;
};

const buildNavigationChartTabs = noGeDetails => {
    geDetailsHardCodedKeys.enginesMapper = [];
    for (let geDetailNum = 1; geDetailNum <= noGeDetails; geDetailNum++) {
        geDetailsHardCodedKeys.enginesMapper.push({title: `ENGINE ${geDetailNum}`, name: `engine${geDetailNum}`});
    }
}

export const updateGeDetails = (id, data, updateState, extraChartConfigs, widget) => {
    geStorage.setGeDetailsData(data);
    const geDetailsData = (data[0]?.values) ? setBarTablesDataFormat(data[0]?.values) : [];

    const noTurboCharger = data[0]?.values[data[0]?.values.length-1].subId;
    buildNavigationChartTabs(noTurboCharger);

    updateState(id, geDetailsData, widget?.id);
}
