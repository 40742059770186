import Highcharts from 'highcharts';
import {userStore} from 'common/store/storeUtils';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);


const radialOptions = () => {

    setTimeout(() => (userStore().user?.theme !== 'ds-white') ? Highcharts.setOptions(Highcharts.darkTheme)
    : Highcharts.setOptions(Highcharts.whiteTheme));

    let options = {
        chart: {
            id: "fcrVessel",
            polar: true,
            inverted: true,
            height: 251,
            marginBottom: 30,
            width: 220,
            type: 'column',
            backgroundColor: 'transparent'
        },
        title: { 
            text: 'MAIN ENGINE',
            style: {fontSize: '11px'},
            y: 230
        },
        xAxis: {
            labels: {
                align: 'right',
                allowOverlap: true,
                step: 1,
                y: 18,
                x:-17,
                style: {
                    fontSize: '10px'
                }
            },
            gridLineColor: 'transparent',
            categories: [
                'Sensor ',
                'Report ',
            ]
        },
        yAxis: {
            crosshair: { enabled: false },
            lineWidth: 0,
            gridLineColor: 'transparent',
            tickInterval: 50,
            reversedStacks: false,
            endOnTick: true,
            showLastLabel: true,
            labels: {
                x: 0,
                y: 0,
                style: {
                    fontSize: '10px'
                }
            },
            
        },
        pane: {
            size: '85%',
            innerSize: '60%',
            endAngle: 270
        },
        plotOptions: {
            column: {
                cursor: 'pointer',
                stacking: 'normal',
                pointPadding: 0,
                groupPadding: 0.15,
                pointWidth: 8
            },
            series: {
                borderWidth: 1,
                borderColor: userStore().user?.theme !== 'ds-white' ? '#142536' : '#fff'
            },
            dataLabels: { enabled: true }
        },
        tooltip: {
            formatter: function() {
                return (`
                <div class="flex-centered-col">
                    <div class="highcharts-fonts"><b>${this.series.name} (UTC)</b></div>
                    <div class="flex-centered-col" style="width: 100%;">
                        <div class="flex-centered highcharts-fonts" id="${this.series.name}" style="padding:0; width: 100%; margin-top:4px;">
                            <div class="flex-centered">
                                <div class="tooltip-circle" style="background-color:${this.point.color}; margin-right: 10px;"></div>
                                <div class="highcharts-fonts">${this.point.z}: &nbsp;</div>
                            </div>
                            <div><b class="highcharts-fonts">${this.point.y.toFixed(2)} MT</b></div>
                        </div>
                        <div class="highcharts-fonts" style="text-align: left; width: 100%;">
                            <div style="margin-left: 12px;">${this.point.missingValues ? this.point.missingValues + '% missing data' : ''}</div>
                        </div> 
                    </div>
                </div>`)
            },
            // padding: 8,
            outside: true,
            useHTML: true,
        },
        lang: {
            noData: "No data to display at the moment"
        },
        series: [],
        credits: { enabled: false },
        legend: { enabled: false },
        exporting: { enabled: false }
    };

    return options;
}

export default radialOptions;