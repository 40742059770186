import Wind from 'Utilities/wind';
import Utilities from 'Utilities/global';
import {licensing} from "common/store/licensing";
import {vesselUtils, reportsStore} from 'common/store/storeUtils';


const averageCurrentsPayload = {
    metrics:[
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                { metricName: "true_current_speed_calc", aggregation: "AVG" },
                { metricName: "true_current_direction_calc", aggregation: "AVG" },
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                { metricName: "ve_1_currents_speed_avg", aggregation: "AVG" },
                { metricName: "ve_1_currents_angle_avg", aggregation: "AVG" },
            ]
        }
    ]
};

const averageCurrents = (id, response, updateState, extraChartConfigs, widget) => {
    let data = [{
        values: [{}, {}, {}, {}]
     }];

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    if(response.series.length > 0) response.series[0].values.forEach(value => {
         if(value.metricName === "true_current_speed_calc" && value.metricCategory === "VESSEL") data[0].values[0] = value;
         if(value.metricName === "ve_1_currents_speed_avg" && value.metricCategory === "TELEGRAM") data[0].values[2] = value;
         if(value.metricName === "true_current_direction_calc" && value.metricCategory === "VESSEL") data[0].values[1] = value;
         if(value.metricName === "ve_1_currents_angle_avg" && value.metricCategory === "TELEGRAM") data[0].values[3] = value;
     }); else data = [];

    const hasEmptyData = Utilities.hasNotValue(data[0]?.values[0]?.value)
     && Utilities.hasNotValue(data[0]?.values[1]?.value)
     && Utilities.hasNotValue(data[0]?.values[2]?.value)
     && Utilities.hasNotValue(data[0]?.values[3]?.value);

    
    let updateStateData = {};
    if (!hasEmptyData){
        let firstPart = [
            {
                label: 'Speed', 
                value: data[0].values[0]?.value ? (Utilities.renderNumber(Utilities.roundToDigits(data[0].values[0].value, 1)))  + ' ' + (data[0].values[0].unit || '') : "-",
                color: isLight ? "ais" : "sensorsData"
            },
            {
                label: 'Direction', 
                value: data[0].values[1]?.value ? Wind.convertApparentWindAngle(data[0].values[1].value, 'currents') : "-",
                color: isLight ? "ais" : "sensorsData"
            },
        ]

        const secondPart = reportsStore().isTelegramConfigured ? [
            {
                label: 'Speed', 
                value: data[0].values[2]?.value ? Utilities.renderNumber(Utilities.roundToDigits(parseFloat(data[0].values[2].value)))  + ' ' + (data[0].values[2].unit || '') : "-",
                color: "reportsData"
            },
            {
                label: 'Direction', 
                value: data[0].values[3]?.value ? Wind.convertApparentWindAngle(data[0].values[3].value, 'currents') : "-",
                color: "reportsData"
            },
        ] : null;
        
        if (isLight && !data[0].values[0].value && !data[0].values[1].value) firstPart = null

        updateStateData = {
            firstPart,
            secondPart,
            firstDevider: firstPart && secondPart ? true : false,
        };
    }

    updateState(id, updateStateData, widget?.id);
};



export {averageCurrentsPayload, averageCurrents};
