import React from 'react';
import MapComponent from 'components/map/map';
import { useSelector, useDispatch } from 'react-redux';

const DashboardMap = props => {
    // store vars
    const monitoringStoreJSX = useSelector(state => state.monitoring);
    const dispatch = useDispatch();

    return (
        <MapComponent
            mapContainerID={`${props.data.widgetId}-map-container-${props.data.id}`}
            widgetId={props.data.id}
            widgetVesselIds={props.data.vesselIds}
            widgetLevel={props.data.level}
            widgetData={props.data}
            inDashboard={true}
            fromTo={monitoringStoreJSX.fromTo}
            resetLegView={monitoringStoreJSX.resetLegView}
            fromToChanged={monitoringStoreJSX.fromToChanged}
            setDetailedData={(value) => dispatch({ type: 'monitoring/setDetailedData', payload: value})}  
            setVesselDataHistory={(value) => dispatch({ type: 'monitoring/setVesselDataHistory', payload: value})}
        />
    )
}

export default DashboardMap;
