import React from 'react';
import ReportsSelectNavigation from 'reports/reportsSelectNavigation';
import Datepicker from 'components/reactDates/reactDates';
import ReportsGoToDates from './reportsGoToDates';

const ReportsHeader = (props) => {
    const changeDatesRange = (startDate, endDate) => {
        props.updateFromTo({from: startDate, to: endDate});
    }

    return (
        <div className="reports__header ">
            <ReportsSelectNavigation />
            <div className="reports__header__tools d-flex justify-content-between align-items-center flex-nowrap">
                {props.extraComps?.map( (Comp, index) => (
                    <div className="toolbarWrapper" key={index} style={{visibility: props.showExtraComp ? 'visible' : 'hidden' }}>
                        {Comp}
                    </div>
                ))}
                {!props.extraComps &&
                    <div className="full-width"></div>
                }
                <div className="d-flex justify-content-between align-items-center report-date-picker">
                    <Datepicker changeDatesRange={changeDatesRange} {...props} />
                    <ReportsGoToDates {...props} />
                </div>
            </div>
        </div>
    );
}
 
export default ReportsHeader;
