import React from 'react';

const UndoDeletePopup = (props) => {
    return(
        <div className="undoDeletePopup-body">
            <div>The Dashboard has been deleted</div>
            <div className="seperator" />
            <button type="btn" className="undoDeleteButton" onClick={props.action}>UNDO</button>
        </div>
    )
} 

export default UndoDeletePopup;