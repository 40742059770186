import Highcharts from 'highcharts';
import moment from 'moment';
import Utilities from 'Utilities/global';
import {licensing} from "common/store/licensing";
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import { reportsStore, userStore, vesselUtils } from 'common/store/storeUtils';

const userTheme = userStore().user?.theme !== 'ds-white' ? 'darkTheme' : 'whiteTheme';

const fcrChartPayload = {
    xAxis: "METRIC_CATEGORY",
    aggregation: "SUM",
    withMetadata: true,
    withTimestampRange: true,
    metrics:[
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {
                    metricName: "foVolConsumption"
                }
            ]
        },
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {
                    metricName: "commonDGFOMassRate"
                },
                {
                    metricName: "boilerCommonFoMassRate"
                }
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "me_1_foConsumption_calc"
                },
                {
                    metricName: "ge_1_foConsumption_calc"
                },
                {
                    metricName: "bo_1_foConsumption_calc"
                }
            ]
        }
    ]
};

const fcrChartLightPayload = {
    xAxis: "METRIC_CATEGORY",
    aggregation: "SUM",
    withMetadata: false,
    withTimestampRange: true,
    metrics:[
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "me_1_foConsumption_calc"
                },
                {
                    metricName: "ge_1_foConsumption_calc"
                },
                {
                    metricName: "bo_1_foConsumption_calc"
                }
            ]
        }
    ]
};



const fcr = (id, response, updateState, extraChartConfigs, widget) => {
    const series = [{data: []}, {data: []}];

    const isLightVessel = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    if(response[0] && response[0].values) {
        response[0].values.forEach(keyGp => {
            if(keyGp.metricName === 'foVolConsumption') series[0].data[0] = keyGp;
            if(keyGp.metricName === 'commonDGFOMassRate') series[0].data[1] = keyGp;
            if(keyGp.metricName === 'boilerCommonFoMassRate') series[0].data[2] = keyGp;
            if(keyGp.metricName === 'me_1_foConsumption_calc') series[1].data[0]  = keyGp;
            if(keyGp.metricName === 'ge_1_foConsumption_calc') series[1].data[1] = keyGp;
            if(keyGp.metricName === 'bo_1_foConsumption_calc') series[1].data[2] = keyGp;
        });

        //set sensor data values /1000 to be MT
        if(series[0].data[0]) {
            if(series[0].data[0].value && series[0].data[0].value[0]) {
                series[0].data[0].value[0] = {
                    x: series[0].data[0].value[0].x = "MAIN ENGINE",
                    y: series[0].data[0].value[0].y/1000, 
                    missingValues: series[0].data[0].value[0].missingValues && series[0].data[0].value[0].missingValues > Utilities.missingValuesLimit
                    ? series[0].data[0].value[0].missingValues.toFixed(1) : null,
                    dailyDateRange: series[0]?.data[0]?.value[0]?.dailyDateRange
                };
            }
        }

        if(series[0].data[1]) {
            if(series[0].data[1].value && series[0].data[1].value[0]) {
                series[0].data[1].value[0] = {
                    x: series[0].data[1].value[0].x = "GENERATOR ENGINE", 
                    y: series[0].data[1].value[0].y/1000, 
                    missingValues: series[0].data[1].value[0].missingValues && series[0].data[1].value[0].missingValues > Utilities.missingValuesLimit
                    ? series[0].data[1].value[0].missingValues.toFixed(1) : null,
                    dailyDateRange: series[0]?.data[1]?.value[0]?.dailyDateRange
                };
            }
        }

        if(series[0].data[2]) {
            if(series[0].data[2].value && series[0].data[2].value[0]) {
                series[0].data[2].value[0] = {
                    x: series[0].data[2].value[0].x = "BOILERS", 
                    y: series[0].data[2].value[0].y/1000, 
                    missingValues: series[0].data[2].value[0].missingValues && series[0].data[2].value[0].missingValues > Utilities.missingValuesLimit
                    ? series[0].data[2].value[0].missingValues.toFixed(1) : null,
                    dailyDateRange: series[0]?.data[2]?.value[0]?.dailyDateRange
                };
            }
        }

        // remove Sensor Data from fcr Vessel bar chart for light vessels
        series[0].data.map(serie => { return (isLightVessel && serie.value[0]) && (serie.value[0].y = null); })

        //convert telegrams values from strings to numbers
        if(series[1].data[0]) if(series[1].data[0].value) series[1].data[0].value[0] = {name: 'MAIN ENGINE', y: parseFloat(series[1]?.data[0]?.value[0]?.y || 0), dailyDateRange: series[1]?.data[0]?.value[0]?.dailyDateRange};
        if(series[1].data[1]) if(series[1].data[1].value) series[1].data[1].value[0] = {name: 'GENERATOR ENGINE', y: parseFloat(series[1]?.data[1]?.value[0]?.y || 0), dailyDateRange: series[1]?.data[1]?.value[0]?.dailyDateRange};
        if(series[1].data[2]) if(series[1].data[2].value) series[1].data[2].value[0] = {name: 'BOILERS', y: parseFloat(series[1]?.data[2]?.value[0]?.y || 0), dailyDateRange: series[1]?.data[2]?.value[0]?.dailyDateRange};

        const lightSeries = {
            name: 'Telegrams',
            centerInCategory: true,
            groupPadding: 0.3,
            tooltipTitle: 'Report',
            data: [
                series[1].data[0] && (series[1].data[0].value[0] || []),
                series[1].data[1] && (series[1].data[1].value[0] || []),
                series[1].data[2] && (series[1].data[2].value[0] || [])
            ],
            color: ThemeColorsForCharts()['reports_color'],
            yMin: 0,
            backgroundColor: Highcharts[userTheme].tooltip.backgroundColor,
            tooltip: {valueDecimals: 2, valueSuffix: series[1].data[0] ? ` mt` : ''}  //${series[1].data[0].unit}
        };

        const sensorSeries = {
            name: 'Sensors',
            tooltipTitle: 'Sensors',
            centerInCategory: true,
            groupPadding: 0.3,
            data: [
                series[0]?.data[0]?.value[0] && { 
                    name: series[0].data[0].value[0].x, 
                    y: series[0].data[0].value[0].y, 
                    missingValues: series[0].data[0].value[0].missingValues,
                    color: series[0].data[0].value[0].missingValues ? Highcharts[userTheme].tooltip.missingValuesBackgroundColor : ThemeColorsForCharts()['sensor_color'],
                    dailyDateRange: series[0]?.data[0]?.value[0]?.dailyDateRange
                },
                series[0]?.data[1]?.value[0] && { 
                    name: series[0]?.data[1]?.value[0]?.x,
                    y: series[0]?.data[1]?.value[0]?.y,
                    missingValues: series[0]?.data[1]?.value[0]?.missingValues,
                    color: series[0]?.data[1]?.value[0]?.missingValues ? Highcharts[userTheme].tooltip.missingValuesBackgroundColor : ThemeColorsForCharts()['sensor_color'],
                    dailyDateRange: series[0]?.data[1]?.value[0]?.dailyDateRange
                },
                series[0]?.data[2]?.value[0] && { 
                    name: series[0]?.data[2]?.value[0]?.x,
                    y: series[0]?.data[2]?.value[0]?.y,
                    missingValues: series[0]?.data[2]?.value[0]?.missingValues,
                    color: series[0]?.data[2]?.value[0]?.missingValues ? Highcharts[userTheme].tooltip.missingValuesBackgroundColor : ThemeColorsForCharts()['sensor_color'],
                    dailyDateRange: series[0]?.data[2]?.value[0]?.dailyDateRange
                },
            ],
            color: ThemeColorsForCharts()['sensor_color'],
            yMin: 0,
            tooltip: { valueDecimals: 2, valueSuffix: series[0].data[0] ? ` mt` : '', }  //${series[0].data[0].unit}
        };
        
        sensorSeries.data = sensorSeries.data.filter(data => data);
        lightSeries.data = lightSeries.data.filter(data => data);

        const getLightSeries = () => {
            return [lightSeries];
        }

        const getFullSeries = () => {
            return [sensorSeries, lightSeries];
        }

        updateState(id, {
            chart: {id: "fcrVessel"},
            yAxis: {
                title: { text: 'Fuel Consumption (mt)' }
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    borderRadius: 0,
                    borderWidth: 0,
                    pointWidth: 32,
                    stacking: false,
                    dataLabels: {
                        enabled: true,
                        inside: false,
                        crop: false,
                        overflow: 'none'
                    }
                },
            },
            legend: {
                enabled: (reportsStore().isTelegramConfigured && !isLightVessel)
            },
            tooltip: {
                formatter: function() {
                    return (`
                    <div class="flex-centered-col">
                        <div class="highcharts-fonts tooltip-title"><b>${`${moment(this.point?.dailyDateRange?.timestampFrom).utc().format('DD/MM HH:mm')} - ${moment(this.point?.dailyDateRange?.timestampTo).utc().format('DD/MM HH:mm')}`} (UTC)</b></div>
                        <div class="flex-centered-col tooltip-values" style="width: 100%;">
                            <div class="flex-space-between highcharts-fonts" id="${this.series.name}" style="width: 100%;">
                                <div class="flex-space-between">
                                    <div class="tooltip-circle" style="background-color:${this.point.color};"></div>
                                    <div style="margin-right: 24px;">${this.series.userOptions.tooltipTitle}: &nbsp;</div>
                                </div>
                                <div><b>${this.point.y.toFixed(2)} mt</b></div>
                            </div>
                            <div class="highcharts-fonts" style=" text-align: left; width: 100%;">
                                <div style="margin-left: 12px;">${this.point.missingValues > 0 ? this.point.missingValues + '% missing data' : ''}</div> 
                            </div>
                        </div>
                    </div>`)
                },
                padding: 8,
                shared: false,
                useHTML: true,
                followPointer: false
            },
            series: licensing.lightCondition() ? getLightSeries() : getFullSeries(),
            exporting: {enabled: false}
        }, 
        widget?.id);
    } else updateState(id, {}, widget?.id);
};

export {fcrChartPayload, fcrChartLightPayload, fcr};
