import React from 'react';

const RouteInfo = (props) => {
    const {firstValue, secondValue, thirdValue} = props.data;
    const {style} = props;

    return (
        <div className="flex-space-around" style={style}>
            {(firstValue) && <div className="flex-centered-col">
                <div className="label">{firstValue.label}</div>
                <div className="route-info-values">{firstValue.value}</div>
            </div>} 
            {(secondValue) && <div className="flex-centered-col">
                <div className="label">{secondValue.label}</div>
                <div className="route-info-values">{secondValue.value}</div>
            </div>}
            {(thirdValue) && <div className="flex-centered-col">
                <div className="label">{thirdValue.label}</div>
                <div className="route-info-values">{thirdValue.value}</div>
            </div>}
        </div>
    );
}

export default RouteInfo;
