import React from "react";
import { ReactComponent as VesselIcon } from 'assets/img/app/timeline-imgs/vessel-icon.svg';
import { useSelector } from 'react-redux';

const CustomEvent = (props) => {
    // store vars
    const vesselStoreJSX = useSelector(state => state.vessel);

    return (
        <div className="fc-content fc-daygrid-event__container flex-space-between" >
            <div className="fc-daygrid-event__container__evenType flex-centered-start">
                <div className="fc-daygrid-event__container__evenType__icon d-flex" style={{ backgroundColor: props.data.eventColor }}>
                    <img src={props.data.imgUrl} alt="" />
                </div>
                <div className="fc-daygrid-event__container__evenType__title table-label-element">
                    {props.data.calendarTitle}
                    {props.data.subtitle && <><br/><span className="subtitle">{props.data.subtitle}</span></> }
                </div>
            </div>
            {vesselStoreJSX.selectedVessel === vesselStoreJSX.allVesselsObj.vesselId &&
            <div className={`fc-daygrid-event__container__vessel flex-centered-col ${props.data.vesselClass}`}>
                <div className="fc-daygrid-event__container__vessel__icon">
                    <VesselIcon />
                </div>
                <div className="fc-daygrid-event__container__vessel__name table-small-label-element">
                    {props.data.vesselName}
                </div>
            </div>}
        </div>
    )
}

export default CustomEvent;