import React, { useEffect, useState, useCallback } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ArbitraryPvFvUtils from './arbitraryPvFvUtils.js';
import Utilities from 'Utilities/global';
import ModelDetails from 'components/modelDetails/modelDetails';
import HullPerformanceTable from 'components/monitoringCategories/hullComponents/hullPerformanceTable';
import Modal from "components/modal"
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import service from 'common/js/service';
import ArbitraryPvFvFooter from 'components/footers/staticFooters/arbitraryPvFvFooter.jsx';
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import NoData from 'components/noData/noData';
import moment from "moment/moment.js";
import { ReactComponent as TableIcon } from 'assets/img/app/global-imgs/tableIcon.svg';
import { ReactComponent as ChartIcon } from 'assets/img/app/global-imgs/chartIcon.svg';


require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

// savedOptions contains the previously selected options for either PV or FV, so we can show it to the user again when he goes back to the same tab
let isMounted = false,
    savedOptions = ArbitraryPvFvUtils.initialSavedOptions({});

const CHANGE_MODE_ICON_TITLE = {
    chart: 'Change to table',
    table: 'Change to chart'
}

const ArbitraryPvFv = ({ data, setData, loader, setLoader, isLight, widgetTitle, dataNeedToBeFetched, requestInfo, inDashboard, widget, setHullShowEmptyComponent, emptyComponent, toggleSpecificTabs, setCurrentTab, tabs }) => {
    const [showModal, setShowModal] = useState(false);
    const [innerLoader, setInnerLoader] = useState(false);
    const [modelDetailsData, setModelDetailsData] = useState({});
    const [windSpeedSelectedOptions, setWindSpeedSelectedOptions] = useState([]);
    const [windDirectionSelectedOptions, setWindDirectionSelectedOptions] = useState([]);
    const [disabledAllWindDirectionOptions, setDisabledAllWindDirectionOptions] = useState(false);
    const [draftModeSelectedOption, setDraftModeSelectedOption] = useState({});
    const [fuelOilSelectedOption, setFuelOilSelectedOption] = useState(ArbitraryPvFvUtils.fuelOilOptions[0]);
    const [footerData, setFooterData] = useState([]);
    const [plotBandsInfo, setPlotBandsInfo] = useState({});
    const [mode, setMode] = useState('chart');
    const [disabledAllOptions, setDisabledAllOptions] = useState(false);

    const getWeatherConditionsData = async(draft, draftModeOptions) => {
        const weatherConditionOptions = await service.getWeatherConditionOptions({...requestInfo, seaTrialType: draft});

        // create 2 arrays filled with the unique (without duplicates) wind speed and wind direction options respectively
        // add as the last option the 0 that indicates that the default 'Calm Sea State' should be shown
        const windSpeedOptions = [...new Set(weatherConditionOptions?.map(wc => wc.description.windSpeed))];

        const windDirectionOptions = [...new Set(weatherConditionOptions?.map(wc => wc?.description?.windDirection).filter(Boolean))];

        setModelDetailsData((prevState) => {
            const drafts = draftModeOptions ? draftModeOptions : prevState.draftModeOptions;
            return {
                draftModeOptions: drafts,
                windSpeedOptions,
                windDirectionOptions,
                weatherConditionOptions
            }
        });

        return weatherConditionOptions;
    };

    const getWeatherAndUpdateData = async(option) => {
        const weatherConditionOptions = await getWeatherConditionsData(option.type);

        // run requests to get data
        ArbitraryPvFvUtils.getDataAndUpdateWidget(
            widgetTitle, 
            setData, 
            (value) => setLoader('hullPerformance', value), 
            () => !inDashboard && setLoader('useHullPerformanceOuterLoader', false),
            requestInfo,
            widget,
            weatherConditionOptions,
            getSelectedOptions(savedOptions[widgetTitle].windSpeedSelectedOptions, savedOptions[widgetTitle].windDirectionSelectedOptions, savedOptions[widgetTitle].disabledAllWindDirectionOptions, savedOptions[widgetTitle].draftModeSelectedOption, savedOptions[widgetTitle].mode, savedOptions[widgetTitle].fuelOilSelectedOption),
            setFooterData,
            setPlotBandsInfo
        );
    };

    useEffect(() => {
        if (!isMounted || !dataNeedToBeFetched) return;
        //no dates yet
        if (!requestInfo.to || !requestInfo.from) return;
        // invalid period if more than 3 months (91 days) in case we are in monitoring pages
        if(!inDashboard && (requestInfo.to - requestInfo.from > 7862400000)) return;

        // depending on the reason why data need to be fetched again, do the appropriate actions
        if(dataNeedToBeFetched.reason === 'tabWasSelected') {
            // restore the previously saved options (for either PV or FV)
            setWindSpeedSelectedOptions(savedOptions[widgetTitle].windSpeedSelectedOptions);
            setWindDirectionSelectedOptions(savedOptions[widgetTitle].windDirectionSelectedOptions);
            setDisabledAllWindDirectionOptions(savedOptions[widgetTitle].disabledAllWindDirectionOptions);
            setDraftModeSelectedOption(savedOptions[widgetTitle].draftModeSelectedOption);
            setMode(savedOptions[widgetTitle].mode);
            setDisabledAllOptions(false);
            if(widgetTitle === 'CII-SPEED') setFuelOilSelectedOption(savedOptions[widgetTitle].fuelOilSelectedOption);
        
            getWeatherAndUpdateData(savedOptions[widgetTitle].draftModeSelectedOption);

        } else if(dataNeedToBeFetched.reason === 'fromToChanged') {
            // when date or vessel changes, then we want to initialize again the previously saved options
            resetSavedOptions();
            
            // run initial requests along with requests to get data
            initialRequests();
        }
    // eslint-disable-next-line
    }, [dataNeedToBeFetched?.value]);

    // when user selects a modelDetails option, update also the saved options object
    useEffect(() => {
        savedOptions[widgetTitle].windSpeedSelectedOptions = [...windSpeedSelectedOptions];
    // eslint-disable-next-line
    }, [windSpeedSelectedOptions]);
    
    useEffect(() => {
        savedOptions[widgetTitle].windDirectionSelectedOptions = [...windDirectionSelectedOptions];
    // eslint-disable-next-line
    }, [windDirectionSelectedOptions]);

    useEffect(() => {
        savedOptions[widgetTitle].disabledAllWindDirectionOptions = disabledAllWindDirectionOptions;
    // eslint-disable-next-line
    }, [disabledAllWindDirectionOptions]);

    useEffect(() => {
        savedOptions[widgetTitle].draftModeSelectedOption = {...draftModeSelectedOption};
    // eslint-disable-next-line
    }, [draftModeSelectedOption]);

    useEffect(() => {
        savedOptions[widgetTitle].mode = mode;
    // eslint-disable-next-line
    }, [mode]);

    useEffect(() => {
        if(widgetTitle === 'CII-SPEED') savedOptions[widgetTitle].fuelOilSelectedOption = fuelOilSelectedOption;
    // eslint-disable-next-line
    }, [fuelOilSelectedOption]);

    useEffect(() => {
        isMounted = true;

        //no dates yet
        if (!requestInfo.to || !requestInfo.from) return;
        // invalid period if more than 3 months (91 days) in case we are in monitoring pages
        if (!inDashboard && (requestInfo.to - requestInfo.from > 7862400000)) return;
        
        // run initial requests along with requests to get data
        initialRequests();

        return () => {
            isMounted = false;
        }
    // eslint-disable-next-line
    }, []);

    // in monitoring, depending on if the fuel-speed has data, disable or enable the fuel-speed and cii-speed tab (not applicable for dashboard)
    const ifFVEligible = async(option) => {
        if (!inDashboard) {
            const fuelSpeedHasData = await service.fuelSpeedHasData({...requestInfo, seaTrialType: option.type});
            const requestedYear = moment(requestInfo.from).year();

            toggleSpecificTabs({ 
                'FUEL-SPEED': { disabled: !fuelSpeedHasData }, 
                'CII-SPEED': { disabled: (Utilities.isDemo() && requestedYear <= 2019) || (!Utilities.isDemo() && requestedYear <= 2021) ? true : !fuelSpeedHasData }
            });
        }
    };

    // make 3 initial requests for:
        // 1) checking if fuel-speed widget has data (and if it has, then the retrieving of data will occur upon user clicking on the fuel-speed tab)
        // 2) get the draft mode options
        // 3) get the weather condition options (wind speed and wind direction)
    const initialRequests = async() => {
        // used in order to make the request with the updated widgetTitle value
        let widgdetTitleTemp = widgetTitle;

        const draftModeOptions = ArbitraryPvFvUtils.getFormattedDraftModeOptions(await service.getDraftModeOptions(requestInfo));
        setLoader('hullPerformance', true);

        // if we do not receive any draft mode options, then return (the following requests will not be made and the component will remain in 'noData' state)
        if (!draftModeOptions || !draftModeOptions.length) {
            if(inDashboard) return setData({});
            else return setHullShowEmptyComponent(true);
        }
        if(setHullShowEmptyComponent) setHullShowEmptyComponent(false);

        const weatherConditionOptions = await getWeatherConditionsData(draftModeOptions[0].type, draftModeOptions);

        // set the current tab (depending on if the vessel that the user selected is light or not) and disable or not the FUEL-SPEED and CII-SPEED tabs in case of a premium vessel
        // note: applicable only for monitoring and not dashboard
        const requestedYear = moment(requestInfo.from).year();

        if(!inDashboard) {
            if(isLight) {
                if(tabs[0]?.disabled || tabs[1]?.disabled) toggleSpecificTabs({ 
                    'FUEL-SPEED': { disabled: false }, 
                    'CII-SPEED': { disabled: (Utilities.isDemo() && requestedYear <= 2019) || (!Utilities.isDemo() && requestedYear <= 2021) ? true : false }
                });
    
                widgdetTitleTemp = 'FUEL-SPEED';
                setCurrentTab('fuelSpeed');
            }
            else {
                ifFVEligible(draftModeOptions[0]);
    
                widgdetTitleTemp = 'POWER-SPEED';
                setCurrentTab('powerSpeed');
            }
        }

        // set the initially selected draft mode option (default option) as the first option
        setDraftModeSelectedOption(draftModeOptions[0]);
        savedOptions = ArbitraryPvFvUtils.initialSavedOptions(draftModeOptions[0]);
    
        ArbitraryPvFvUtils.getDataAndUpdateWidget(
            widgdetTitleTemp, 
            setData,  
            (value) => setLoader('hullPerformance', value), 
            () => !inDashboard && setLoader('useHullPerformanceOuterLoader', false),
            requestInfo,
            widget,
            weatherConditionOptions,
            getSelectedOptions(savedOptions[widgdetTitleTemp].windSpeedSelectedOptions, savedOptions[widgdetTitleTemp].windDirectionSelectedOptions, savedOptions[widgdetTitleTemp].disabledAllWindDirectionOptions, draftModeOptions[0], savedOptions[widgdetTitleTemp].mode, savedOptions[widgdetTitleTemp].fuelOilSelectedOption),
            setFooterData,
            setPlotBandsInfo
        );
    }

    const resetSavedOptions = () => {
        savedOptions = ArbitraryPvFvUtils.initialSavedOptions({});
        setDraftModeSelectedOption({});
        setWindSpeedSelectedOptions([]);
        setWindDirectionSelectedOptions([]);
        setDisabledAllWindDirectionOptions(false);
        setMode('chart');
        setDisabledAllOptions(false);
        if(widgetTitle === 'CII-SPEED') setFuelOilSelectedOption(ArbitraryPvFvUtils.fuelOilOptions[0]);
    };

    // returns the state variables for the selected options or the params that we pass as the selected options (used in case that the state has not been updated yet)
    const getSelectedOptions = (windSpeed, windDirection, disabledAllWindDirections, draftMode, modeParam, fuelOil) => {
        return {
            windSpeedSelectedOptions: windSpeed ? windSpeed : windSpeedSelectedOptions,
            windDirectionSelectedOptions: windDirection ? windDirection : windDirectionSelectedOptions,
            disabledAllWindDirectionOptions: disabledAllWindDirections ? disabledAllWindDirections : disabledAllWindDirectionOptions,
            draftModeSelectedOption: draftMode ? draftMode : draftModeSelectedOption,
            mode: modeParam ? modeParam : mode,
            fuelOilSelectedOption: fuelOil ? fuelOil : fuelOilSelectedOption,
            setWindSpeedSelectedOptions: setWindSpeedSelectedOptions,
            setWindDirectionSelectedOptions: setWindDirectionSelectedOptions,
            setDisabledAllWindDirectionOptions: setDisabledAllWindDirectionOptions,
            setDraftModeSelectedOption: setDraftModeSelectedOption
        };
    }

    // functions regarding the change mode functionality
    const changeMode = () => {
        const newMode = (mode === 'chart') ? 'table' : 'chart';
        setMode(newMode);

        ArbitraryPvFvUtils.getDataAndUpdateWidget(
            widgetTitle, 
            setData, 
            setInnerLoader, 
            null,
            requestInfo,
            widget,
            modelDetailsData.weatherConditionOptions,
            getSelectedOptions(savedOptions[widgetTitle].windSpeedSelectedOptions, savedOptions[widgetTitle].windDirectionSelectedOptions, savedOptions[widgetTitle].disabledAllWindDirectionOptions, savedOptions[widgetTitle].draftModeSelectedOption, newMode),
            setFooterData,
            setPlotBandsInfo
        );
    };

    const getChangeModeIcon = (mode) =>
        (mode === 'chart')
            ? <TableIcon className='svg-path changeMode-icon' onClick={changeMode} />
            : <ChartIcon className='svg-path changeMode-icon' onClick={changeMode} />

    const HullPerformanceComponent = useCallback(
        (props) => <HullPerformanceTable {...props} />,
        // eslint-disable-next-line
        [windDirectionSelectedOptions, windSpeedSelectedOptions]
    );

    return (
        <>
            { (emptyComponent)
                ? <NoData height="400px" text='No data to display at the moment' />
                : (!loader && data && Object.keys(data).length > 0)
                    ? <div className="arbitraryPvFv">
                        {/* we do not show the change mode icon for the CII-SPEED widget */}
                        {
                            (widgetTitle !== 'CII-SPEED') && <div className='changeMode flex-centered-start'>
                                <Tooltip
                                    title={CHANGE_MODE_ICON_TITLE[mode]}
                                    component={getChangeModeIcon(mode)}
                                />
                            </div>
                        }
                        <div className="arbitraryPvFv-body">
                            <ModelDetails
                                data={modelDetailsData}
                                selectedOptions={getSelectedOptions()}
                                widgetTitle={widgetTitle}
                                isLight={isLight}
                                setComponentData={setData}
                                componentData={data}
                                setLoader={setInnerLoader}
                                requestInfo={requestInfo}
                                widget={widget}
                                footerData={footerData}
                                setFooterData={setFooterData}
                                ifFVEligible={ifFVEligible}
                                getWeatherConditionsData={getWeatherConditionsData}
                                fuelOilSelectedOption={fuelOilSelectedOption}
                                setFuelOilSelectedOption={setFuelOilSelectedOption}
                                disabledAllOptions={disabledAllOptions}
                                setDisabledAllOptions={setDisabledAllOptions}
                                plotBandsInfo={plotBandsInfo}
                                setPlotBandsInfo={setPlotBandsInfo}
                            />
                            {
                                <div className="arbitraryPvFv-body-chartOrTable">
                                    <div className="arbitraryPvFv-body-container h-100">
                                        {(mode === 'chart')
                                            ? <HighchartsReact
                                                highcharts={Highcharts}
                                                options={data.chartDataJSON}
                                                immutable={true}
                                            />
                                            : <HullPerformanceComponent
                                                items={data?.tableData ? data.tableData : []}
                                                selectedOptions={getSelectedOptions()}
                                                widgetTitle={widgetTitle}
                                                setShowModal={setShowModal}
                                            />
                                        }
                                        {
                                            innerLoader &&
                                            <div className="skeleton-absolute full-width full-height">
                                                <Skeleton className="customSkeleton"
                                                          animation="wave"
                                                          width={"47%"}
                                                          height={290}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {(footerData && Object.keys(footerData).length && mode === 'chart') ? <ArbitraryPvFvFooter
                            curvesData={footerData}
                        /> : null}
                        {/* the modal will be shown if user clicks the 'expand view' icon that is in the table's footer */}
                        <Modal
                            showModal={showModal}
                            setShowModal={setShowModal}
                            widgetTitle={widgetTitle}
                        >
                            <HullPerformanceComponent
                                items={data?.tableData ? data.tableData : []}
                                selectedOptions={getSelectedOptions()}
                                widgetTitle={widgetTitle}
                                setShowModal={setShowModal}
                                modalView={true}
                            />
                        </Modal>
                    </div>
                    : null
            }
            {(loader && !emptyComponent) ? <Skeleton className="customSkeleton" animation="wave" width={"100%"} height={442} /> : null}
        </>
    )
};

ArbitraryPvFv.propTypes = {
    data: PropTypes.shape({}),
    setData: PropTypes.func, 
    loader: PropTypes.bool, 
    setLoader: PropTypes.func,
    isLight: PropTypes.bool,
    widgetTitle: PropTypes.string,
    dataNeedToBeFetched: PropTypes.shape({}),
    inDashboard: PropTypes.bool,
    requestInfo: PropTypes.shape({}),
    toggleSpecificTabs: PropTypes.func,
    setCurrentTab: PropTypes.func,
    tabs: PropTypes.arrayOf(PropTypes.shape({}))
};

export default ArbitraryPvFv;
