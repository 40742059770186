import overviewStorage from 'components/monitoringCategories/localStorages/overviewStorage';
import { monitoringStore } from 'common/store/storeUtils';

const vesselRatingPayload = {
    metrics: []
};

 // before the timestamp date '1514764800000', there are no classifications for the widget. 
 // So we show a speacial message, instead of showing the noData component
const VESSEL_RATING_CLASSIFICATION_EARLIEST_DATE = 1514764800000;

const vesselRatingUpdate = (id, response, updateState, extraChartConfigs, widget) => {
    let data = [];

    overviewStorage.setVesselRating(response);

    if (response.length > 0) { 
        data = {data: response[0], loading:false};
    }

    //emptyData param created to passthrough the noData component on widget panel and get the inside component no data conditions   TODO: need to be on noData component!
    if(data.length === 0)
        data = {
            emptyData: true, 
            pastData: monitoringStore()?.fromTo?.from ? monitoringStore().fromTo.from < VESSEL_RATING_CLASSIFICATION_EARLIEST_DATE : false
        };
    updateState(id, data, widget?.id);
}

export { vesselRatingUpdate, vesselRatingPayload };
