import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { monitoringStore, monitoringUtils, alertsUtils } from 'common/store/storeUtils';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "../core/DatePicker";
import { ReactComponent as InformationIcon } from 'assets/img/app/global-imgs/informationIcon.svg';
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';

let isMounted = false;

const DateRange = () => {

    const [localFrom, setLocalFrom] = useState(moment.utc().subtract(1, 'days').format('DD/MM/YYYY HH:mm'));
    const [localTo, setLocalTo] = useState(moment.utc().format('DD/MM/YYYY HH:mm'));
    const [localDate, setLocalDate] = useState(false);
    // store vars
    const monitoringStoreJSX = useSelector(state => state.monitoring);
    const dispatch = useDispatch();

    useEffect(() => {
        isMounted = true;
        setLocalDate(false)
        return () => { isMounted = false; }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!monitoringStore().fromToChanged) return;
		if (
			moment(monitoringStore().fromTo.from).utc(true) !==
				moment(localFrom).valueOf() &&
			moment(monitoringStore().fromTo.to).utc(true) !==
				moment(localTo).valueOf()
		) {
			setLocalDate(false);
		}
        if (localDate) return;
        if (monitoringStore().fromTo && isMounted) {
            setLocalFrom(moment.utc(monitoringStore().fromTo.from).format('DD/MM/YYYY HH:mm'))
            setLocalTo(moment.utc(monitoringStore().fromTo.to).format('DD/MM/YYYY HH:mm'))
        }
    // eslint-disable-next-line
    }, [monitoringStoreJSX.fromToChanged]);

    const onDateChange = () => {
        alertsUtils.init(dispatch);
        const from = moment.utc(localFrom, 'DD/MM/YYYY HH:mm').valueOf();
        const to = moment.utc(localTo, 'DD/MM/YYYY HH:mm').isSame(moment(), 'day')
           ? moment().utc().valueOf()
           : moment.utc(localTo, 'DD/MM/YYYY HH:mm').valueOf();
        setLocalDate(true)
        if ((moment(to).diff(moment(from), 'hours') < 0) || (localFrom === "Invalid date") || (localTo === "Invalid date")){
            setLocalFrom(moment.utc(monitoringStore().fromTo.from).format('DD/MM/YYYY HH:mm'))
            setLocalTo(moment.utc(monitoringStore().fromTo.to).format('DD/MM/YYYY HH:mm'))
            return alert("Invalid period! End date must be bigger than start date");
        }
        monitoringUtils.updateFromTo(dispatch, { from, to });
    }

    return (
        <div className="date-range-performance flex-centered-end marg-b-10">
            <Tooltip
                placement="left"
                className="marg-r-15"
                height="auto"
                component={<InformationIcon className="svg-path marg-r-15 pointer" />}
                title={
                    <span className="pad-8">
                        Selecting durations longer than 24 hours will be
                        displayed in hourly intervals. If the selected period is
                        less than 24 hours, it will be displayed in minutes
                    </span>
                }
            />
            <DatePicker
                format="DD/MM/YYYY"
                name="pickerDateTimeRange"
                label="From:"
                isUTC={true}
                onChange={(val) =>
                    setLocalFrom(moment(val).format("DD/MM/YYYY HH:mm"))
                }
                value={localFrom}
                withTime={true}
                classNames={{ element: "ds-input as-picker-btn from" }}
                isNotGroup={true}
                extraProps={{
                    InputAdornmentProps: { position: "start" },
                    disableFuture: true,
                    PopoverProps: {
                        className: "date-range-performance-picker-popOver",
                        style: { zIndex: 1000000}
                    }
                }}
            />
            <DatePicker
                format="DD/MM/YYYY"
                name="pickerDateTimeRange"
                label="To:"
                isUTC={true}
                onChange={(val) =>
                    setLocalTo(moment(val).format("DD/MM/YYYY HH:mm"))
                }
                value={localTo}
                withTime={true}
                classNames={{ element: "ds-input as-picker-btn to" }}
                isNotGroup={true}
                extraProps={{
                    InputAdornmentProps: { position: "start" },
                    disableFuture: true,
                    PopoverProps: {
                        className: "date-range-performance-picker-popOver",
                        style: { zIndex: 1000000}
                    }
                }}
            />
            <button
                type="btn"
                className="main-cta marg-l-1"
                onClick={onDateChange}
            >
                <div className="main-cta__text">Apply</div>
            </button>
        </div>
    );
}

export default DateRange;