import hullStorage from "../../components/monitoringCategories/localStorages/hullStorage";

export const hullInfoNamesMapper = {
  'breadth': 'Breadth (Mld)',
  'lengthOverall': 'Length Overall',
  'lengthPerpendiculars': 'Length BP',
  'depth': 'Depth (Mld)',
  'td': 'Design Draft',
  'ts': 'Scantling Draft',
  'keelToMast': 'Keel To Mast'
};

export const updateHullInfoTableJson = (id, data, updateState, extraChartConfigs, widget) => {
  hullStorage.setHullInfoData(data);
  updateState(id, data, widget?.id);
};
