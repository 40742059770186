const boStorage = {
    fuelBarChartData: null,
    fuelLineChartData: null,
    boDetailsChartData: null,
    boilersRunningTimeData: null,

    setFuelBarChartData: (data) => boStorage.fuelBarChartData = data,
    setFuelLineChartData: (data) => boStorage.fuelLineChartData = data,
    setBoDetailsChartData: (data) => boStorage.boDetailsChartData = data,
    setBoilersRunningTimeData: (data) => boStorage.boilersRunningTimeData = data,

    resetBoStorage: () => {
      boStorage.setFuelBarChartData(null);
      boStorage.setFuelLineChartData(null);
      boStorage.setBoDetailsChartData(null);
      boStorage.setBoilersRunningTimeData(null);
    },
  
    isGlobalStateEmpty: () => {
      return !boStorage.fuelBarChartData || !boStorage.fuelLineChartData || !boStorage.boDetailsChartData || !boStorage.boilersRunningTimeData;
    }
  }
  
  export default boStorage;
