import moment from "moment";
import GlobalUtilities from 'Utilities/global';

const initialMonitoringState = {
    fromToChanged: false,
    vesselDataHistory: null,
    resetLegView: false,
    fromTo: {
        from: null, 
        to: null
    },
    lessThanADayPeriod: {
        status: false, 
    },
    //detailed data are waypoint view mode
    detailedData: {
        status: false, 
        dateTime: null, 
        from: null, 
        to: null, 
        guidesStart: null, 
        guidesEnd: null
    },
    ciiFromTo: {
        from: null, 
        to: null
    },
    ciiSearchingYear: {selectedPickerYear: moment(GlobalUtilities.nowOrDemo()).year()}
}

// monitoring reducer
const monitoring = (state = initialMonitoringState, action) => {
    switch(action.type) {
        case 'monitoring/setFromTo': {
            return {
                ...state,
                fromTo: action.payload
            };
        }
        case 'monitoring/setFromToChanged': {
            return {
                ...state,
                fromToChanged: action.payload
            };
        }
        case 'monitoring/setVesselDataHistory': {
            return {
                ...state,
                vesselDataHistory: action.payload
            };
        }
        case 'monitoring/setResetLegView': {
            return {
                ...state,
                resetLegView: action.payload
            };
        }
        case 'monitoring/setDetailedData': {
            return {
                ...state,
                detailedData: action.payload
            };
        }
        case 'monitoring/setCiiDatesFromTo': {
            return {
                ...state,
                ciiFromTo: action.payload
            };
        }
        case 'monitoring/setCiiSearchingDatesFromTo': {
            return {
                ...state,
                ciiSearchingYear: action.payload
            };
        }
        case 'monitoring/setLessThanADayPeriod': {
            return {
                ...state,
                lessThanADayPeriod: action.payload
            };
        }
        default: 
            return state;
    }
}

export default monitoring;