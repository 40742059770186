import moment from 'moment';
import Utils from 'Utilities/global';
import {drawerUtils} from 'components/drawer/drawer.js';
import { vesselGroupStore, vesselStore, vesselGroupUtils, vesselUtils } from 'common/store/storeUtils';
import chartOptions from 'components/charts/lineChart/react-lineChart';

const WIDGETS_WITH_INNER_REQUESTS = ['pvCurves', 'fvCurves'];
const WIDGETS_WITHOUT_REPORTS = ['mapVessel', 'mapGroup', 'mapCompany', 'legMapVessel'];

const dashboardUtils = {
    // dashboard mappers
    // note: dashboardTimeSpans needs to be a function and not an object, 
    //       in order for the Utils.nowOrDemo function to be read'
    dashboardTimespans: (timestamp) => {
        if(timestamp === 'DAILY') {
            return {
                from: moment(Utils.nowOrDemo()).utc().startOf('day').valueOf(),
                to: Utils.nowOrDemo()
            }
        } 
        if(timestamp === 'WEEKLY') {
            return {
                from: moment(Utils.nowOrDemo()).utc().subtract(7, 'days').valueOf(),
                to: Utils.nowOrDemo()
            }
        } 
        if(timestamp === 'MONTHLY') {
            return {
                from: moment(Utils.nowOrDemo()).utc().subtract(6, 'months').valueOf(),
                to: Utils.nowOrDemo()
            }
        } 
        if(timestamp === 'CUSTOM') {
            return {
                from: 1609493766000,
                to: 1610271366000
            }
        } 
    },
    dashboardProperties: {
        EXPAND_MIN_LIMIT: 4,
        EXPAND_MAX_LIMIT: 8,
        PREVENT_DRAG_DEFAULTS: ["svg", ".map-container"],
        timespansObject: {},
        cols: {lg: 12, md: 12, sm: 6, xs: 4, xxs: 2},
        breakpoints: {xs: 460, sm: 748, lg: 1000 },
        layoutsObject: [],
        rowHeight: 25,
        showEnterpriseIndication: false
    },
    // dashboard functions
    currentBreakpoint: (currentWidth) => {
        let breakpointKey = "lg";
        Object.keys(dashboardUtils.dashboardProperties.breakpoints).forEach(key => {
            if (currentWidth > dashboardUtils.dashboardProperties.breakpoints[key]) {
                breakpointKey = key;
            }
        })

        return breakpointKey;
    },
    handleToggleDrawer: (drawerClose, setDrawerClose, setExpandDrawer) => {
        setDrawerClose(!drawerClose);
        if (drawerClose) setExpandDrawer(false);
        drawerUtils.toggleDrawer(drawerClose, '.widget-drawer');
        document.querySelectorAll('.widget-library-toggle').forEach(el => el.classList.toggle('active'))
    },
    expandDrawer: (expandDrawer, setExpandDrawer) => {
        setExpandDrawer(!expandDrawer);
        drawerUtils.toggleExpandDrawer(expandDrawer, '.widget-drawer');
    },
    getVesselIdsByInputName: (inputName, level) => {
        return level === "VESSEL"
            ? [vesselStore().allVessels.filter(vessel => vessel.name === inputName)[0].vesselId]
            : vesselUtils.getVesselsIdsFromVesselGroupId(vesselGroupStore().allVesselGroups.filter(vesselGroup => vesselGroup.name === inputName)[0].id)
    },
    getWidgetIndex: (ids, level) => {
        if(level === "VESSEL") return vesselUtils.getVesselIndex(ids);
        else if(level === "GROUP") return vesselGroupUtils.getVesselGroupIndex(ids);
    },
    randomInRange: (from, to) => {
        const r = Math.random();
        return Math.floor(r * (to - from) + from);
    },
    constructWidgetLayout: (widget, allWidgets) => {
        let newWidgetId = dashboardUtils.randomInRange(0, 1000);
        const newPosition = dashboardUtils.constructLayout(widget, dashboardUtils.dashboardProperties.layoutsObject);

        widget.layout = {
            i: newWidgetId.toString(),
            y: Infinity,
            w: widget.layout.w,
            h: widget.layout.h,
            x: newPosition.x,
            minW: widget.layout.minW,
            minH: widget.layout.minH
        }
        widget.id = newWidgetId;

        while (allWidgets.some(el => parseInt(el.id) === parseInt(widget.id))) {
            // Change id of the widget picked in widget library, if it has already been declared
            widget.id = newWidgetId;
            widget.layout.id = newWidgetId.toString();
            widget.layout.i = newWidgetId.toString();
            newWidgetId++;
        }

        return widget;
    },
    constructLayout: (widget, layout) => {
        const rowColumns = 12, heightColumns = 30;

        let x = 0, y = 0, w = widget.layout.w, h = widget.layout.h, arr = [], found = false;

        for (let i = 0; i < rowColumns; i++) {
            arr.push([]);
            for (let j = 0; j < heightColumns; j++) {
                arr[i].push(1);
            }
        }

        layout.forEach((e) => {
            for (let i = 0; i < e.w; i++) {
                for (let j = 0; j < e.h; j++) {
                    arr[e.x + i][e.y + j] = 0;
                }
            }
        });

        const checkIfExists = (arr, w, h, x, y) => {
            let res = true;
            for (let i = x; i < x + w; i++) {
                for (let j = y; j < y + h; j++) {
                    if (!arr[i][j]) res = false;
                }
            }
            return res;
        };
        if (checkIfExists(arr, w, h, x, y)) found = true;

        // recursively check if width and height are less than stated above
        while (!found && y + h <= heightColumns) {

            // if already full width, go to next row
            if (x + w === rowColumns) {
                x = 0;
                y++;
            } else {
                x++;
            }
            if (checkIfExists(arr, w, h, x, y)) found = true;
        }

        return {x, y, w, h};
    },
    widgetLisenseState: widget => {
        let vesselObj;

        switch (widget?.license) {
            case "ALL":
                vesselObj = vesselStore().allVessels;
                break;
            case "PREMIUM":
                vesselObj = vesselStore().allVessels.filter(vessel => vessel.isPremium)
                break;
            case "LIGHT":
                vesselObj = vesselStore().allVessels.filter(vessel => !vessel.isPremium)
                break;
            default:
                vesselObj = vesselStore().allVessels;
        }

        return vesselObj;
    },
    widgetsWithoutReports: (widget) => {
        return WIDGETS_WITHOUT_REPORTS.includes(widget.widgetId);
    },
    // these widgets are making the appropriate api calls inside them and not outside (from payloads.index)
    widgetsWithInnerRequestsOnly: (widget) => {
        return WIDGETS_WITH_INNER_REQUESTS.includes(widget.widgetId);
    },
    // this kind of widgets need some initial data value so that the noData component is not shown on the initial render before the requests are done
    initialValuesForWidgetsWithInnerRequests: (widgetId) => {

        // create an object with widgetIds as keys and for each key give the appropriate value
        const widgetsWithInitialDataObj = WIDGETS_WITH_INNER_REQUESTS.reduce((accumulator, value) => {
            return {...accumulator, [value]: {...chartOptions()}}
        }, {});

        return widgetsWithInitialDataObj[widgetId];
    },
    addWidgetWithoutModal: widget => {
        return widget.level === "COMPANY";
    }
}

export default dashboardUtils;