/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const AutocompleteField = (props) => {

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: option => option.title,
        limit: 10
    });

    return (
        <div className={`input-group ds-input ${props.classNames.wrapper}`}>
            <Autocomplete
                filterOptions={props.filterOptions ? createFilterOptions(props.filterOptions) : filterOptions}
                id={props.name}
                autoHighlight
                options={props.autocompleteOptions}
                onChange={props.onChange}
                getOptionLabel={props.getOptionLabel}
                renderOption={props.renderOption}
                openOnFocus={false}
                onInputChange={props.onInputChange}
                clearOnEscape={true}
                getOptionSelected={props.getOptionSelected}
                forcePopupIcon={false}
                value={props.value}
                noOptionsText={props.noOptionsText}
                closeIcon=""
                loading={props.loading}
                renderInput={
                    (params) => <TextField {...params} onMouseDownCapture={(e) => e.stopPropagation()} label={props.label} variant="standard" fullWidth
                />}
            />
            {props.renderError()}
        </div>
    );
};

AutocompleteField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    renderError: PropTypes.func,
    autocompleteOptions: PropTypes.array,
    noOptionsText: PropTypes.string,
    onInputChange: PropTypes.func,
    renderOption: PropTypes.func,
    classNames: PropTypes.object,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
};

export default AutocompleteField;