import React, {useState} from 'react';
import {ReactComponent as SmallArrow} from "../../assets/img/app/global-imgs/small-arrow.svg";
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextButton from 'components/buttons/textButton';

const LoadMoreContainer = props => {

        const [isExpanded, setIsExpanded] = useState(false);
        const [showLoader, setShowLoader] = useState(false);
        const [loadMoreCTA, setLoadMoreCTA] = useState("See More");

        const loadMoreClasses = () => {
            return classnames('load-more-container__body d-flex flex-wrap',
                {'collapsed': !isExpanded && props.children.length > 0 && props.children.length > props.limit},
                {'expanded': isExpanded && props.children.length > 0 && props.children.length > props.limit},
            );
        }

        const iconClasses = () => {
            return classnames('svg-path', {'rotate-180': !isExpanded});
        }

        const loaderClasses = () => {
            return classnames({'d-none': !showLoader});
        }

        const handleExpandClick = () => {
            if (!isExpanded) {
                setShowLoader(true);

                setTimeout(() => {
                    setShowLoader(false);
                    setIsExpanded(true);
                    setLoadMoreCTA("See Less");
                }, 1000)

            } else {
                setLoadMoreCTA("See More");
                setIsExpanded(false);
            }
        }

        return (
            <div className="load-more-container">
                <div className={loadMoreClasses()}>
                    {props.children.length > 0 && props.children.map((childProps, index) => (
                        <props.component {...childProps} key={index}/>
                    ))}
                </div>

                {props.children.length > 0 && props.children.length > props.limit &&
                <>
                    <div className="d-flex justify-content-center">
                        <CircularProgress
                            className={loaderClasses()}
                            style={{'color': '#3366FF', marginTop: "10px"}}
                            thickness={5}/>
                    </div>

                    <div className="see-more-cta flex-centered-end">
                        <TextButton 
                            components={[
                                loadMoreCTA,
                                <SmallArrow className={iconClasses()}/>
                            ]}
                            extraProps={{onClick: handleExpandClick}}
                        />
                    </div>
                </>
                }

            </div>
        );
    }
;

export default LoadMoreContainer;
