import React from 'react';
import HullInfo from 'components/footers/hullInfo';
import classnames from 'classnames';
import { keys } from 'lodash';
import { CONFIDENCE_MAPPER } from 'widgets/vesselData/foulingPenaltyOvertime';

const HullFoulingPenaltyOvertimeFooter = (props) => {
    const getTileClasses = category => classnames(
        'tile smallTile',
        {[CONFIDENCE_MAPPER[category]?.classes]: keys(CONFIDENCE_MAPPER).includes(category)}
    );

    return(
        <div className='flex-space-between full-width'>
            {
                props?.categories?.length 
                    ? <div className='marg-l-10'> LEGEND: </div>
                    : null 
            }
            <div className='flex-space-between full-width marg-r-15 widget-title-legend'>
                <div className='infoSection'>
                    {
                        props?.categories?.map((category, index) => {
                            return(
                                <div key={index} className='flex-centered'>
                                    <div className={getTileClasses(category)} />
                                    <div className='tile__text'> 
                                        { CONFIDENCE_MAPPER[category]?.footerText }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            { !props?.widget && <div>
                <HullInfo />
            </div> }
        </div>
    )
};
 
export default HullFoulingPenaltyOvertimeFooter;