import React, {useEffect, useState} from 'react';
import {ReactComponent as NoReportsFound} from 'assets/img/app/reports-imgs/noReportsFound.svg';
import moment from 'moment';

const ReportPeriod = props => {

    const [noReports, setNoReports] = useState(false);

    useEffect(() => {
        setNoReports(props.data.noReports);
    }, [props.data.noReports]);

    const getDateRangeTitle = dateRange => {
        if(!dateRange) return;
        const days = moment.duration(moment(dateRange.timestampTo).diff(moment(dateRange.timestampFrom))).days();
        let hours = moment(dateRange.timestampTo).diff(moment(dateRange.timestampFrom), 'hours', true);
        if(days >= 1) hours = (hours - (days*24)).toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
        else hours = hours.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
        const dayWording = days === 1 ? ' day' : ' days';
        const hourWording = hours === 1 ? ' hr' : ' hrs';

        return `${days > 0 ? days + dayWording : ''} ${(hours > 0 && hours !== '24') ? hours + hourWording : ''}`;
    }

    const getDateRangeSubtitle = dateRange => {
        if(!dateRange) return;
        const from = moment(dateRange.timestampFrom).utc().format('DD/MM HH:mm');
        const to = moment(dateRange.timestampTo).utc().format('DD/MM HH:mm');

        return `${from} - ${to}`;
    }

    return (
        <div className="report-period">
            {!noReports?
                <>
                    <div className="report-period__diff">
                        {getDateRangeTitle(props?.data?.reportPeriod)}
                    </div>
                    <div className="report-period__dates">
                        {getDateRangeSubtitle(props?.data?.reportPeriod)}
                    </div>
                </> : <>
                    <div className="report-period__noReports">
                        <NoReportsFound className="svg-g-path"/>
                        No reports found
                    </div>
                </>
            }
        </div>
    );
}

export default ReportPeriod;
