import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField, InputAdornment  } from '@material-ui/core';

const AlphaNumericField = (props) => {
    const [textFieldProps, setTextFieldProps] = useState({});

    const setValue = (value) => {
        value = typeof value === 'string' ? value : String(value);
        switch(props.type) {
            case 'alphaNumeric':
                return value.replace(/[^0-9A-Za-z]/g, '');
            case 'integer':
                return value || '';
            case 'character':
                return value.replace(/[^A-Za-z]/g, '');
            case 'upperCase':
                return value.replace(/[^A-Za-z]/g, '').toUpperCase();
            case 'lowerCase':
                return value.replace(/[^A-Za-z]/g, '').toLowerCase();
            case 'nonSpace':
                return value.replace(/\s/g, '');
            default:
                return value;
        }
    };

    useEffect(() => {
        let inputPropsObj = {...props};
        
        if( ['alphaNumeric',
            'integer',
            'positiveInteger',
            'noNegativeInteger',
            'character',
            'upperCase',
            'lowerCase',
            'nonSpace',
            'textarea',
            'number',
            'positiveNumber',
            'noNegativeNumber'].indexOf(props.type) !== -1
        ) inputPropsObj.type = 'text';

        // start code for material ui
        if (props.type === 'textarea') {
            inputPropsObj.multiline = true;
            inputPropsObj.rows = 1;
            inputPropsObj.rowsMax = 3;
        }
        if (props.extraImg || props.unit) {
            let extra = props.extraImg || props.unit;
            switch (extra) {
                case 'm3':
                    extra = <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary">m<span className="sub">3</span></p>;
                    break;
                case 'Kg/m3':
                    extra = <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary">Kg/m<span className="sub">3</span></p>;
                    break;
                default:
                    // do nothing
            }
            inputPropsObj.InputProps = {};
            inputPropsObj.InputProps.endAdornment = extra === '(8451)'
                ? <InputAdornment position="end">&#8451;</InputAdornment>
                : <InputAdornment position="end">{extra}</InputAdornment>;
        }
        // end code for material ui
        setTextFieldProps(inputPropsObj);

    // eslint-disable-next-line
    }, [props]);


    return (
        <div className={`input-group ${props.wrapperClassName}`}>
            <Field name={props.name} render={({field}) => <TextField {...textFieldProps} value={setValue(field.value || '')} />} />
        </div>
    );
};

AlphaNumericField.propTypes = {
    wrapperClassName: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    extraImg: PropTypes.node,
    ...Field.propTypes
};

AlphaNumericField.defaultProps = {
    extraImg: '',
};

export default AlphaNumericField;