import React from 'react';
import {widgetLibraryUtils} from "components/widgetLibrary/widgetLibrary.js";
import {ReactComponent as AddIcon} from "assets/img/app/dashboard-imgs/addWidget.svg";
import classnames from 'classnames';
import LicenseLabel from 'components/labels/licenseLabel/licenseLabel';
import TimeInfoLabel from 'components/labels/timeInfoLabel/timeInfoLabel';
import { dashboardStore, dashboardUtils } from 'common/store/storeUtils';
import { useDispatch } from 'react-redux';

const WidgetItem = (props) => {
    // store vars
    const dispatch = useDispatch();

    const cardText = props.license === 'PREMIUM' ? 'Enterprise' : 'Light';

    const widgetItemClasses = () => {
        return classnames('widget-item marg-t-15', {'pressed': dashboardStore().widgetToAdd.id === props.id && dashboardStore().showAddWidgetModal});
    }

    const handleAddClick = () => {
        dispatch({ type: 'dashboard/setWidgetToAdd', payload: props });
        if (!dashboardUtils.addWidgetWithoutModal(props)) dispatch({ type: 'dashboard/setShowAddWidgetModal', payload: true });
    }

    return (
        <div className={widgetItemClasses()}>
            <div className="widget-item__header d-flex flex-column justify-content-center">
                <div className="widget-item__header__title headline3">{props.name}</div>
                <div className="widget-item__header__subtitle headline3">
                    <span className="tiny-label-light">
                        {widgetLibraryUtils.displayLevel(props.level)}
                    </span>
                    <TimeInfoLabel timespan={props?.timespan[0]} />
                </div>
            </div>
            <div className="widget-item__body">
                {props.thumbnail && <img src={require(`assets/img/app/widgetThumbnails${props.thumbnail}`)} alt=""/>}
            </div>
            {(dashboardUtils.dashboardProperties.showEnterpriseIndication && props.license !== 'ALL') && <LicenseLabel text={cardText} />}
            <div className="widget-item__footer">
                <button className="text-cta add-widget" onClick={() => handleAddClick()}>
                    <AddIcon className="addIcon svg-line-dark"/> ADD
                </button>
            </div>
        </div>
    )
}

export default WidgetItem;
