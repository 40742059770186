import Highcharts from 'highcharts';
import {userStore} from 'common/store/storeUtils';

const pieChartOptions = () => {
    (userStore().user?.theme !== 'ds-white') ? Highcharts.setOptions(Highcharts.darkTheme)
        : Highcharts.setOptions(Highcharts.whiteTheme);

    let options = {
        chart: {
            type: 'pie',
            spacing: [0, 0, 0, 0],
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
        },
        title: {
            text: '',
            align: 'center',
            verticalAlign: 'middle',
            style: '',
            y: 0
        },

        tooltip: { enabled: false },
        exporting: { enabled: false },

        plotOptions: {
            pie: {
                dataLabels: { enabled: false },
                center: ['50%', '50%'],
                size: '100%',
                borderWidth: 0
            }
        },

        series: [{
            type: 'pie',
            size: '100%',
            innerSize: '93%',
            showInLegend: false,
            colors: [],
            data: [],
        }]
    }

    return options
}

export default pieChartOptions;