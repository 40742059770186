import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import AlertView from 'components/alerts/alertView';
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import DropDown from "components/core/DropDown";
import {ReactComponent as NotifyIcon} from 'assets/img/app/topbar-imgs/notifications.svg';
import {ReactComponent as SettingsIcon} from 'assets/img/app/sidebar-imgs/settings-icon.svg';
import { alertsUtils } from 'common/store/storeUtils';
import { useSelector, useDispatch } from 'react-redux';

let isMounted = false;

const AlertsBell = (props) => {
  const [alerts, setAlerts] = useState([]);

  // store vars
  const alertsStoreJSX =  useSelector(state => state.alerts);
  const dispatch = useDispatch();

  const getAlertsCount = alertsCount => {
    if(alertsCount > 9) return '9+';
    return alertsCount;
  };

  const initFun = () => {
    alertsUtils.getLatestsAlerts().then( result => {
      if(!isMounted) return;
      if (result.data) setAlerts(result.data.alerts);
      if (result.data) dispatch({ type: 'alerts/setUninspectedAlertsNum', payload: result.data.unreadCount });
    });
  };

  useEffect(() => {
    isMounted = true;
    alertsUtils.init(dispatch);
    initFun();

    return () => {
      isMounted = false;
    }
  // eslint-disable-next-line
  }, []);

  return ( 
    <div className="alerts-bell-wrapper flex-centered">
        {(alertsStoreJSX.uninspectedAlertsNum > 0 || alertsStoreJSX.uninspectedAlertsNum.length > 0) &&
          <div className="alerts-bell-wrapper__counter-circle d-flex justify-content-center align-items-center">
            {getAlertsCount(alertsStoreJSX.uninspectedAlertsNum)}
          </div>
        }

        <div className="svg-g-pathMask flex-centered notify-wrapper"><NotifyIcon/></div>
        <DropDown buttonLabel={''} classNames={{button:"alerts-bell-wrapper__alerts-dropdown", menu:"hasArrow"}}
          showCaret={false}
          onOpen={initFun}
          render={({closeDropDown}) => {
            return (
                <React.Fragment>
                  <div className="alerts-bell-wrapper__header flex-space-between headline3">
                    <div className="alerts-bell-wrapper__header__title">ALERTS</div>
                    <div className="alerts-bell-wrapper__header__setings-icon flex-centered" onClick={() => {
                      props.history.push("/settings");
                      closeDropDown();
                    }}><Tooltip title="SETTINGS" component={<SettingsIcon className="svg-path" />} /></div>
                  </div>
                  <div className='alerts-bell-wrapper__body'>
                    { alerts.map( alert => (
                        <div key={alert.id}>
                        <div className="alerts-bell-wrapper__alert-group flex-centered-start" key={alert.id} onClick={() => {
                          closeDropDown();
                        }}>
                          <AlertView alert={ { ...alert, alertBell: true } } alerts={alerts} updateAlerts={setAlerts} fromAlertsBell={true}/>
                        </div>
                        <div className="alerts-bell-wrapper__seperator"></div>
                        </div>
                    ))}
                  </div>
                  <div className="alerts-bell-wrapper__footer flex-space-between"
                      onClick={() => {
                        props.history.push("/alerts");
                        closeDropDown();
                      }}
                  >
                    <div className="alerts-bell-wrapper__footer__text d-flex justify-content-center align-items-center">
                      <div className="headline3 text-center">
                        See all alerts
                      </div>
                    </div>
                  </div>
                </React.Fragment>
            );
          }} />
      </div>
   );
}
 
export default withRouter(AlertsBell);
