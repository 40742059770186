import React from "react";
import classnames from "classnames";
import moment from "moment";
import PistonsGasWaterTabs from './pistonsGasWaterTabs';
import Utilities from 'Utilities/global';

const PistonTabs = (props) => { 
    const activeTab = (index, noGasData, noWaterData) => {
        const active = !(noGasData && noWaterData);
        return classnames("pistonsMonitoring__tabs__body__piston flex-centered-start marg-b-15", {"active": active && props.data.chartData.activePiston === index} );
    }

    const onTabsClick = (index, noDataGas, noDataWater) => {
        if(noDataGas && noDataWater) return; 
        props.data.updateParentState(index);
    }

    return (
        <div className="pistonsMonitoring__tabs">
            <div className="pistonsMonitoring__tabs__header">
                <div className="pistonsMonitoring__tabs__header__title">
                    {moment(props.data.from).isValid() ? moment.utc(props.data.from).format('DD/MM HH:mm') : '-'} - {moment(props.data.to).isValid() ? moment.utc(props.data.to).format('DD/MM HH:mm') : '-'}
                </div>
                <div className="pistonsMonitoring__tabs__header__subtitle smallGrey">
                    (deviation, min, max values)
                </div>
            </div>
            <div className="pistonsMonitoring__tabs__body">
                {[...Array(props.data.pistonsTotalNum)].map((e, index) => {
                    const noDataGas = props.data.pistonsTabData['cylExhGasOutTempNo'+(index+1)] === null;
                    const noDataWater = props.data.pistonsTabData['cylJCFWOutTempNo'+(index+1)] === null;
                    return(
                        <div className={activeTab(index, noDataGas, noDataWater)} key={`pistonTab_${index}`} onClick={() => onTabsClick(index, noDataGas, noDataWater)}>
                            <div className = {classnames("pistonsMonitoring__tabs__body__piston__title", {"title-disabled": noDataGas && noDataWater})} >Piston {index+1}</div>
                            <div className="pistonsMonitoring__tabs__body__piston__info flex-centered">
                                <PistonsGasWaterTabs noData = {noDataGas} classes={{boxType: 'exhGas-box', tooltip: 'box-disabled box-fullHeight'}} values={{ total: Utilities.roundToDigits(props.data.pistonsTabData['cylExhGasOutTempNo'+(index+1)], 1), min: Utilities.roundToDigits(props.data.pistonsTabData['minCylExhGasOutTempNo'+(index+1)], 1), max: Utilities.roundToDigits(props.data.pistonsTabData['maxCylExhGasOutTempNo'+(index+1)], 1) }} />
                                <PistonsGasWaterTabs noData = {noDataWater} classes={{boxType: 'cylJc-box marg-l-10', tooltip: 'box-disabled box-fullHeight'}}values={{ total: Utilities.roundToDigits(props.data.pistonsTabData['cylJCFWOutTempNo'+(index+1)], 1), min: Utilities.roundToDigits(props.data.pistonsTabData['minCylJCFWOutTempNo'+(index+1)], 1), max: Utilities.roundToDigits(props.data.pistonsTabData['maxCylJCFWOutTempNo'+(index+1)], 1) }} />
                            </div>
                        </div>
                    )
            })}     
            </div>
        </div>
    );
}
export default PistonTabs;