import React, { useEffect, useState } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

const LoadHistoryLineChart = (props) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        let optionsTemp = {...props?.data?.chartData};
        if(props?.options?.height) optionsTemp = {...optionsTemp, chart: {height: props?.options?.height}};
        
        optionsTemp.exporting.filename = props?.exportingChartTitle;

        setOptions(optionsTemp);
    // eslint-disable-next-line
    }, [props.data]);

    return (
        <div className="ge-load-history-wrapper">
            {props.data !== "" && (
                <HighchartsReact highcharts={Highcharts} options={options} constructorType={"stockChart"}/>
            )}
        </div>
    )
}

export default LoadHistoryLineChart;
