import _ from "lodash";
import FormElementStore from "common/store/Forms/formElement";
import moment from 'moment';

const baseFormUtils = {
    // vars
    schema: {},
    prevValues: {},
    hidden: {},
    disabled: {},

    // functions
    createValues: (initValues, schema) => {
        const values = {};

        Object.keys(schema).forEach(key => {
            if (schema[key].type === 'array' || schema[key].type === 'table' || schema[key].type === 'selectionBoxesArray') {
                values[key] = [];

                if (initValues.hasOwnProperty(key) && initValues[key].length > 0) {
                    initValues[key].forEach(obj => {
                        values[key].push(baseFormUtils.createValues(obj, schema[key].structure));
                    });
                } else {
                    values[key].push(baseFormUtils.createValuesFromSchema(schema[key].structure));
                }
            } else {
                values[key] = initValues.hasOwnProperty(key) ? initValues[key] : schema[key].value;
            }
        });
        return values;
    },
    createValuesFromSchema: (newObject) => {
        const object = {};
        Object.keys(newObject).forEach(element => {
            if (newObject[element].structure) {
                object[element] = [];
                object[element].push(baseFormUtils.createValuesFromSchema(newObject[element].structure));
            } else {
                object[element] = newObject[element].value;
            }
        });
        return object;
    },
    addSubArray: (newObject, arrayHelpers, index) => {
        arrayHelpers.insert(index + 1, baseFormUtils.createValuesFromSchema(newObject));
    },
    deleteSubArray: (arrayHelpers, index) => {
        arrayHelpers.remove(index);
    },
    getValues: (baseFormInstance) => {
        return baseFormUtils.getKey('value', baseFormInstance.schema);
    },
    getKey: (key, object, parents = []) => {
        let values = {};

        if(object) Object.keys(object).forEach(field => {
            switch (object[field].type) {
                case 'array': case 'table': case 'selectionBoxesArray':
                    values = _.mergeWith({}, values, baseFormUtils.getKey(key, object[field].structure, [...parents, field]));
                    break;
                default:
                    if (object[field].hasOwnProperty(key)) {
                        if (parents.length === 0) {
                            object[field].hasOwnProperty(key) && (values[field] = object[field][key]);
                        } else {
                            parents.reduce((prevVal, nextVal) => {
                                if (!prevVal.hasOwnProperty(nextVal)) {
                                    prevVal[nextVal] = [];
                                    prevVal[nextVal].push({});
                                }
                                return prevVal[nextVal][0]
                            }, values);
                            parents.reduce((prevVal, nextVal) => prevVal[nextVal][0], values)[field] = object[field][key];
                        }
                    }
            }
        });
        return values;
    },
    filterOutNotNeededValues: (values, path = [], baseFormInstance) => {
        let newValues = { ...values };
        const deleteKey = (key) => {
            const fullPathKey = FormElementStore.generateName([...path, key]);
            if (baseFormInstance.hidden.hasOwnProperty(fullPathKey) && baseFormInstance.hidden[fullPathKey]) {
                delete newValues[key];
            }
        };
        Object.keys(values).forEach(key => {
            if (Array.isArray(values[key])) {
                const isMulti = values[key].filter(obj => !(obj instanceof Object)).length > 0;
                if (isMulti) {
                    deleteKey(key);
                } else {
                    newValues[key] = values[key].filter(obj => obj instanceof Object).map((obj, index) => {
                        return baseFormUtils.filterOutNotNeededValues(obj, [...path, key, index], baseFormInstance);
                    });
                }
            } else {
                deleteKey(key);
            }
        });
        return newValues;
    },
    updateState: (key, value, error, baseFormInstance) => {
        baseFormInstance.prevValues = {
            ...baseFormInstance.prevValues, 
            [key]: {
                value: value,
                error: error
            }   
        }
    },
    returnError: (getErrorFromState, fullPathKey, error, value, baseFormInstance) => {
        if (getErrorFromState) {
            return baseFormInstance.prevValues[fullPathKey].error;
        }
        baseFormUtils.updateState(fullPathKey, value, error, baseFormInstance);
        return error;
    },
    uniqueOfTypeErrorMessage: (values) => {
        let errorMessage = '';
        switch (_.keys(values)[0]) {
            case 'bunkerings':
                let fuelType = (values[_.keys(values)][0]['oilType'] === 'lOil') ? 'lube oil' : 'fuel';
                errorMessage = `You cannot add a bunkering event of the same ${fuelType} type`;
                break;
            default:
                return false;
        }
        return errorMessage;
    },
    // @todo make validation async
    validation: /*async*/ (values, schema, key, path, subValues, baseFormInstance) => {
        const fullPathKey = FormElementStore.generateName([...path, key]);
        const parentValue = FormElementStore.getFormik('values', values || subValues, path);
        const value = parentValue[key];
        const getErrorFromState = baseFormInstance.prevValues.hasOwnProperty(fullPathKey) && baseFormInstance.prevValues[fullPathKey].hasOwnProperty('value') && baseFormInstance.prevValues[fullPathKey].value === value;
        const { validation = {}, type, label = '', errorMessage = {} } = schema[key];
        let error = '';

        if (baseFormInstance.hidden.hasOwnProperty(fullPathKey) && baseFormInstance.hidden[fullPathKey]) {
            return false;
        }

        for (let rule in validation) {
            switch (rule) {
                case 'isSubmitting':
                    if(validation[rule].length && validation[rule][0]()) {
                        error = errorMessage[rule] || `incorrect old password`;
                        validation[rule][1]();
                        return baseFormUtils.returnError(false, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'required':
                    if ((typeof value === "boolean" && !value) || value.length === 0) {
                        error = errorMessage[rule] || `${/*label ? label :*/ 'Field'} is required!`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'requiredField':
                    if ((typeof value === "boolean" && !value) || value.length === 0) {
                        error = errorMessage[rule] || `${label ? label : 'Field'} is required!`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'requiredEvents':
                    if (value.length === 0) {
                        error = `You must select one event before moving to the next step`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'oneOfAll':
                    let hasError = true;
                    error = `You need to enter consumption for at least one engine`;
                    validation[rule].forEach(element => { if (parentValue[element] !== '') hasError = false });
                    if (hasError) { return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance) }
                    break;
                case 'steamingTimeValidation':
                    let hasErrorss = true;
                    error = `You need to enter steaming time either hours or minutes`;
                    validation[rule].forEach(element => { if (parentValue[element] !== '') hasErrorss = false });
                    if (hasErrorss) { return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance) }
                    break;
                case 'minValue':
                    if (value && value < validation[rule]) {
                        error = `${/*label ? label : */'Field'} must be at least ${validation[rule]}!`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'maxValue':
                    if (value && value > validation[rule]) {
                        error = `${/*label ? label : */'Field'} must be at most ${validation[rule]}!`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'minLength':
                    if (value && value.length < validation[rule]) {
                        error = `${/*label ? label : */'Field'} must be at least ${validation[rule]} characters!`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'wrongPass':
                    if(getErrorFromState === 'Invalid Data') baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    break;
                case 'atLeastOneNumber':
                    if (value && !(/\d/.test(value)) ) {
                        error = ` `;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'atLeastEightCharacters':
                    if (value && !(/[A-Za-z0-9!@#$%^&*()_+"|:]{8,}/).test(value)) {
                        error = ` `;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'regex':
                    if (value) {
                        let testRegex = new RegExp(validation[rule]);
                        if (!testRegex.test(value)) {
                            error = errorMessage[rule] || `Enter a valid ${label ? label.toLowerCase() : 'field'}`;
                            return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                        }
                    }
                    break;
                case 'onlyLatinCharacters':
                    if (value && !(/^[A-Za-z!@%$#^&*()+-_]*$/.test(value)) ) {
                        error = ` `;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'atLeastOneSymbol':
                    if (value && !(! /^[a-zA-Z0-9]+$/.test(value)) ) {
                        error = ` `;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'noSlashes':
                    if (value && (value.includes("/") || value.includes("\\")) ) {
                        error = `No slashes allowed`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'maxLength':
                    if (value && value.length > validation[rule]) {
                        error = `${/*label ? label : */'Field'} must be at most ${validation[rule]} characters!`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'equalTo':
                    if (value && parentValue.hasOwnProperty(validation[rule]) && value !== parentValue[validation[rule]]) {
                        // const equalToLabel = schema.hasOwnProperty(validation[rule]) && schema[validation[rule]].label ?
                        //     schema[validation[rule]].label : validation[rule];
                        error = `Passwords don't match`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'notEqualTo':
                    if (value && parentValue.hasOwnProperty(validation[rule]) && value === parentValue[validation[rule]]) {
                        const equalToLabel = schema.hasOwnProperty(validation[rule]) && schema[validation[rule]].label ?
                            schema[validation[rule]].label : validation[rule];
                        error = `${label ? label : 'Field'} and ${equalToLabel} must have different values!`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'notSamePorts':
                    if (value && parentValue.hasOwnProperty(validation[rule]) && value === parentValue[validation[rule]]) {
                        error = `Arrival port should be other than the departure port!`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'uniqueOfType':
                    let hasErrors = false;
                    _.keys(values).forEach(key => {
                        if (values[key].length > 1 && values[key][0][validation[rule]] && values[key][1][validation[rule]]) {
                            if (values[key][0][validation[rule]] === values[key][1][validation[rule]]) hasErrors = true;
                        }
                    })
                    error = baseFormUtils.uniqueOfTypeErrorMessage(values);
                    if (hasErrors) { return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance) }
                    break;
                case 'greaterThan':
                    if (value && parentValue.hasOwnProperty(validation[rule])) {
                        if ((type === 'datePicker' && value.valueOf() < parentValue[validation[rule]].valueOf()) || (type !== 'datePicker' && value < parentValue[validation[rule]])) {
                            const greaterThanLabel = schema.hasOwnProperty(validation[rule]) && schema[validation[rule]].label ?
                                schema[validation[rule]].label : validation[rule];
                            error = `${label ? label : 'Field'} must be greater than ${greaterThanLabel}!`;
                            return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                        }
                    }
                    break;
                case 'requiredFields':
                    if (!value && validation[rule].filter(field => parentValue[field]).length) {
                        error = `Please fill the upper field`;
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'maxDate':
                    let errorLabel = "Field";
                    if (value.length !== 0 && (value.valueOf() > validation[rule].valueOf())) {
                        label.toLowerCase().includes('departure') ? errorLabel = 'Departure' : errorLabel = 'Arrival';
                        error = `${errorLabel} should be up to a present date`;
                        return baseFormUtils.returnError(false, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                case 'arrivalDateLessThan':
                    if (value && parentValue.hasOwnProperty(validation[rule])) {
                        if ((moment(moment.utc(value).toDate()).isBefore(moment.utc(parentValue[validation[rule]]).toDate())) || moment(moment.utc(value).toDate()).isSame(moment.utc(parentValue[validation[rule]]).toDate())) {
                            error = `Arrival should be after the departure date!`;
                            return baseFormUtils.returnError(false, fullPathKey, error, value, baseFormInstance);
                        }
                    }
                    break;
                case 'twoMonthsTimeSpan':
                    if (value.length !== 0 && parentValue[validation[rule]].length !== 0) {
                        if (moment(moment.utc(value).subtract(2, 'months').toDate()).isAfter(moment.utc(parentValue[validation[rule]]).toDate())) {
                            error = `Leg duration should not exceed 2 months`;
                            return baseFormUtils.returnError(false, fullPathKey, error, value, baseFormInstance);
                        }
                    }
                    break;
                case 'noSpaces':
                    if (value.length !== 0) {
                        if (/\s/.test(value)) {
                            error = `No spaces allowed`;
                            return baseFormUtils.returnError(false, fullPathKey, error, value, baseFormInstance);
                        }
                    }
                    break;
                case 'async':
                    if (value) {
                        if (getErrorFromState) {
                            return baseFormInstance.prevValues[fullPathKey].error;
                        }
                        /*return */
                        validation[rule](value, values || subValues).then(r => {
                            baseFormUtils.updateState(fullPathKey, value, r, baseFormInstance);
                            //console.log('async error:', r);
                            return r;
                        });
                        /*let async = await validation[rule](value, values || subValues);
                        this.updateState(fullPathKey, value, async);
                        console.log('async error:', async);
                        return async;*/
                    }
                    break;
                case 'custom':
                    if ((typeof value === "boolean" && !value) || value.length) {
                        error = errorMessage[rule] || validation[rule](value);
                        return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
                    }
                    break;
                default:
                    return false;
            }
        }
        if (type === 'email' && value !== '' && !(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/u.test(value))) {
            error = `Invalid ${/*label ? label : */'email address'}!`;
            return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
        }
        if (type === 'positiveNumber' && value !== '' && (!(/^\d*\.?\d*$/.test(value)) || ((/^\d*\.?\d*$/.test(value)) && value <= 0))) {
            error = `${/*label ? label : */'Field'} should contain only positive values!`;
            return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
        }
        if (type === 'positiveInteger' && value !== '' && ((/[^0-9]/g.test(value)) || (!(/[0-9]/g.test(value)) && value <= 0))) {
            error = `${/*label ? label : */'Field'} should be positive integer!`;
            return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
        }
        if (type === 'noNegativeNumber' && value !== '' && !(/^\d*\.?\d*$/.test(value))) {
            error = `${/*label ? label : */'Field'} should be non negative number!`;
            return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
        }
        if (type === 'noNegativeInteger' && value !== '' && (/[^0-9]/g.test(value))) {
            error = `${/*label ? label : */'Field'} should be non negative integer!`;
            return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
        }
        if (type === 'number' && value !== '' && !(/^\d*\.?\d*$/.test(isNaN(Math.abs(value)) ? 'a' : Math.abs(value)))) {
            error = `${/*label ? label : */'Field'} should be number!`;
            return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
        }
        if (type === 'integer' && value !== '' && (/[^0-9]/g.test(isNaN(Math.abs(value)) ? 'a' : Math.abs(value)))) {
            error = `${/*label ? label : */'Field'} should be integer!`;
            return baseFormUtils.returnError(getErrorFromState, fullPathKey, error, value, baseFormInstance);
        }
        return false;
    },
    getErrors: /*async*/ (values, schema, parent = [], allValues = null, baseFormInstance) => {
        const errors = {};
        /*Promise.all(*/Object.keys(values).forEach(key => {
            //return /*Promise.all(*/Object.keys(values).map(key => {
            let innerErrors = false;
            if (Array.isArray(values[key]) && schema[key].type !== 'multiSelect') {
                innerErrors = /*Promise.all(*/values[key].map((subValue, index) => {
                    return baseFormUtils.getErrors(subValue, schema[key].structure, [...parent, key, index], allValues || values, baseFormInstance);
                })/*)*/;
                //console.log(innerErrors);
                let countEmpty = innerErrors.filter(error => Object.keys(error).length === 0).length;
                innerErrors.length > 0 && innerErrors.length !== countEmpty && (errors[key] = innerErrors);
            } else {
                innerErrors = /*await*/ baseFormUtils.validation(allValues, schema, key, [...parent], values, baseFormInstance)/*.then(resp => {
                    //console.log(resp);
                    resp && (errors[key] = resp);
                });*/
                //console.log(innerErrors);
                innerErrors && (errors[key] = innerErrors);
            }
        })/*)*/;

        return errors;
    },
    validate: (values, baseFormInstance) => {
        // console.log(values);
        const errors = baseFormUtils.getErrors(values, baseFormInstance.schema, [], null, baseFormInstance)/*.then(errors => {
            console.log('validation result', errors);
            if (Object.keys(errors).length) {
                throw errors;
            }
        })*/;
        //console.log(errors, this.prevValues);
        return errors;
    },
    hasErrors: (errors, touched) => {
        let hasError = false;
        errors && Object.keys(errors).forEach(key => {
            /*if (errors[key] instanceof Array) {
                errors[key].forEach(element => {
                    this.hasErrors(element) && (hasError = true);
                });
            } else */if (typeof errors[key] === 'object') {
                baseFormUtils.hasErrors(errors[key], touched[key] || {}) && (hasError = true);
            } else {
                errors[key] && touched[key] && (hasError = true);
            }
        });

        return hasError;
    },
    updateFieldAbilityToRender: (values, schema, path = [], baseFormInstance) => {
        if(schema) Object.keys(schema).forEach(field => {
            if (schema[field].hasOwnProperty('showOn')) {
                if (schema[field].showOn !== false) {
                    let newHidden = {};
                    Object.keys(schema[field].showOn).forEach(key => {
                        newHidden[FormElementStore.generateName([...path, field])] = Array.isArray(values[key])
                            ? values[key].filter(value => schema[field].showOn[key].indexOf(value) !== -1).length === 0
                            : schema[field].showOn[key].indexOf(values[key]) === -1;
                    });
                    Object.keys(newHidden).length > 0 && (baseFormInstance.hidden = {...baseFormInstance.hidden, ...newHidden } );
                } else {
                    baseFormInstance.hidden = {
                        ...baseFormInstance.hidden,
                        [FormElementStore.generateName([...path, field])]: true 
                    };
                }
            }
            if (schema[field].hasOwnProperty('validation') && schema[field].validation.hasOwnProperty('disabled')) {
                /**
                 * disabledOperator enum = ['AND', 'OR']
                 */
                const disabledOperator = schema[field].validation.hasOwnProperty('disabledOperator') ?
                    schema[field].validation.disabledOperator : 'AND';
                let newDisabled = {};
                Object.keys(schema[field].validation.disabled).forEach(key => {
                    let value = !values[key];
                    if (key === 'always') {
                        value = schema[field].validation.disabled[key];
                    } else if (schema[field].validation.disabled[key].length > 0) {
                        value = schema[field].validation.disabled[key].indexOf(values[key]) !== -1
                    }
                    const index = FormElementStore.generateName([...path, field]);
                    newDisabled[index] = newDisabled.hasOwnProperty(index) ? (disabledOperator === 'AND' ? newDisabled[index] && value : newDisabled[index] || value) : value;
                });
                Object.keys(newDisabled).length > 0 && (baseFormInstance.disabled = {...baseFormInstance.disabled, ...newDisabled});
            }
            if (schema[field].type === 'array' || schema[field].type === 'table' || schema[field].type === 'selectionBoxesArray') {
                values[field].forEach((value, index) => {
                    baseFormUtils.updateFieldAbilityToRender(value, schema[field].structure, [...path, field, index], baseFormInstance);
                });
            }
        });
    },
    fieldProps: (fields, field, formikHelpers, renderAsterisk, group, baseFormInstance) => {
        const props = {
            type: fields[field].type,
            name: field,
            renderAsterisk,
            formikHelpers,
            hidden: baseFormInstance.hidden,
            disabled: baseFormInstance.disabled,
        };
        FormElementStore.passDownProps.forEach(val => {
            fields[field].hasOwnProperty(val) && (props[val] = fields[field][val]);
        });
        !group && (props.key = field);
        return props;
    },
    groupFields: (fields, group) => {
        let groupFields = {};
        Object.keys(fields)
            .filter(field => fields[field].group === group)
            .forEach(field => groupFields[field] = fields[field]);
        return groupFields;
    }
}

export default baseFormUtils;