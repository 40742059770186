import React from 'react';
import Popper from '@material-ui/core/Popper';
import { ReactComponent as CloseIcon } from 'assets/img/app/global-imgs/close.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/app/global-imgs/delete.svg';
import { ReactComponent as EditIcon } from 'assets/img/app/global-imgs/edit.svg';
import { ReactComponent as VesselIcon } from 'assets/img/app/timeline-imgs/vessel-icon.svg';
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import PopoverUtils from 'components/popover/popoverUtils';
import { vesselStore, vesselUtils, calendarUtils } from 'common/store/storeUtils';

const Popover = (props) => {
    const id = props.popoverOpen ? 'popover' : undefined;
    const eventData = props.vEvent;

    const title = PopoverUtils.popOverTitle(eventData).title;
    const subtitle = PopoverUtils.popOverTitle(eventData).subtitle;
    const body = PopoverUtils.popoverBody(eventData);

    const eventColor = eventData.eventColor ? eventData.eventColor : calendarUtils.Filters().filter(filter => filter.id === eventData.type)[0].color;
    const imgUrl = eventData.imgUrl ? eventData.imgUrl : calendarUtils.Filters().filter(filter => filter.id === eventData.type)[0].imgUrl;
    const vesselClass = eventData.vesselClass ? eventData.vesselClass : `vessel${vesselUtils.fleetVesselsIds().findIndex(elmt => elmt === vesselStore().selectedVessel)}`;
    const vesselName = eventData.vesselName ? eventData.vesselName : vesselUtils.getSelectedVesselObj()?.name;

    return (
        <Popper id={id} open={props.popoverOpen} anchorEl={props.anchorEl} placement="right">
            <div className={`eventPopover eventPopover_${eventData && eventData.type}`}>
                <div className="eventPopover__header flex-centered flex-space-between pad-l-15 pad-r-15">
                    <div className="eventPopover__header__title d-flex">
                        <div className="eventPopover__header__title__icon flex-centered" style={{ backgroundColor: eventColor }}>
                            <img src={imgUrl} alt="" />
                        </div>
                        <div className="eventPopover__header__title__text">
                            <span className="headline2 title">{title}</span><br />
                            <span className="small-label-light subtitle">{subtitle}</span>
                        </div>
                    </div>
                    <div className="eventPopover__header__title__actions flex-centered">
                        {props.canEdit && <>
                            <div className="action-button flex-centered" onClick={props.popoverEdit}>
                                <Tooltip title="Edit" component={<EditIcon className="svg-path action editAction" />} />
                            </div>
                            <div className="action-button flex-centered" onClick={props.popoverDelete}>
                                <Tooltip title="Delete" component={<DeleteIcon className="svg-path action deleteAction" />} />
                            </div>
                        </>}
                        <div className="action-button flex-centered" onClick={props.popoverClose}>
                            <CloseIcon className="svg-path action closeAction" />
                        </div>
                    </div>
                </div>
                <div className="eventPopover__body flex-centered-col-start">
                    {body.map((item, index) => (
                        <div className={`eventPopover__body__item d-flex ${item[3]}`} key={index}>
                            <div className="eventPopover__body__item__icon">
                                {item[3] === "responsiblePerson" ? <span className="gradiendBackground">{item[2]}</span> : item[2] && item[2] }
                            </div>
                            <div className={`eventPopover__body__item__info__${item[3]}`}>
                                <div className="eventPopover__body__item__value popover-value">
                                    {item[3] === "array" ?
                                        <React.Fragment>
                                            <div className="d-flex fuelInfo-container">
                                                {item[0].map((arrItem, index) => <div key={arrItem}>{arrItem}</div>)}
                                            </div>
                                            <div className="d-flex fuelInfo-container">
                                                {item[1].map((arrItem, index) => <div key={arrItem}>{arrItem}</div>)}
                                            </div>
                                        </React.Fragment>
                                        : item[1]}
                                </div>
                                <div className="eventPopover__body__item__label popover-label">
                                    {item[3] !== "array" && item[0]}
                                </div>
                                </div>
                        </div>
                    ))}
                </div>
                <div className="eventPopover__footer d-flex">
                    <div className={`eventPopover__footer__icon d-flex ${vesselClass}`}>
                        <VesselIcon />
                    </div>
                    <div className="eventPopover__footer__text small-label">
                        {vesselName}
                    </div>
                </div>
            </div>
        </Popper>
    );
}

export default Popover;
