import React from 'react';
import AlertsTypeImgMapper from 'components/alerts/alertsTypeImgMapper';
import {ReactComponent as ClockPointers} from 'assets/img/app/alerts-imgs/clockPointers.svg';


const AlertCircleSymbol = props => {
    
    const getBgColor = () => {
        if(props.severity === 'HIGH') return 'var(--high_severity)';
        if(props.severity === 'MEDIUM') return 'var(--medium_severity)';
        return 'var(--low_severity)';
    }


    return (
        <div className="alert-symbol-container relative marg-r-15" style={{transform: props.alertBell ? 'scale(0.7)' : 'scale(1)'}}>
            <div className="alert-symbol-container__circle-bg d-flex justify-content-center align-items-center" style={{backgroundColor: getBgColor()}}>
                <AlertsTypeImgMapper type={props.alertType} />
                {props.overtime && <div className="alert-symbol-container__circle-bg__clock d-flex justify-content-center align-items-center">
                    <ClockPointers />
                </div>}
            </div>
        </div>
    );
}
 
export default AlertCircleSymbol;