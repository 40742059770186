import lineChartStockOptions from "components/charts/lineChart/react-lineChartStock";
import meStorage from 'components/monitoringCategories/localStorages/meStorage';
import chartUtils from 'Utilities/charts';
import { vesselUtils } from 'common/store/storeUtils';
import { downloadChartInCSVform, downloadChartInXLSXform } from "Utilities/highchartsUtils";
import moment from "moment";


const LoadLineChartChartAxis = {
    id: 'meLoad-axis',
    title: {text: "Engine Load (%)"},
    min: 0,
    tickPixelInterval: 150,
    opposite: false
};
    
const LoadLineChartChartData = {
    name: '', data: [], tooltipTitle: 'ME Load', yAxis: 'meLoad-axis',
    titleSuffix: '(UTC)', yMax: 300, yMin: 0, tooltip: {valueSuffix: ' %', valueDecimals: 2}
};

const LoadLineChartPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    timeGroup: 'HOUR',
    metrics: [{
        metricCategory: "MAIN_ENGINE",
        subIds: [1],
        metricData: [{
            metricName: "load"
        }]
    }]
};

const updateLoadLineChartJson = (id, data, updateState, extraChartConfigs, widget) => {
    meStorage.setLoadLineChartData(data);
    let meLoadOverTimeJSON = Object.assign({}, lineChartStockOptions());
    meLoadOverTimeJSON.chart = {...meLoadOverTimeJSON.chart, height: 430, marginTop: 50, marginLeft: 90, marginRight: 37}
    meLoadOverTimeJSON.legend = {enabled: false};
    meLoadOverTimeJSON.xAxis.title = {...meLoadOverTimeJSON.xAxis.title, text: 'Time (UTC)'}
    meLoadOverTimeJSON.yAxis = {...LoadLineChartChartAxis};
    meLoadOverTimeJSON.series[0] = JSON.parse(JSON.stringify(LoadLineChartChartData));
    meLoadOverTimeJSON.series[0].data = data[0] ? [...data[0].values[0].value] : [];
    meLoadOverTimeJSON.tooltip.borderColor = chartUtils.tootlipBorderColor.premiumLight();

    //if detailed data exists with map bullet clicked or alert clicked, add the plotLines and plotBands (guides) to line charts
    if(extraChartConfigs && extraChartConfigs.plotLines) {
      let plotGuides = extraChartConfigs.plotLines.setReportsPlotLineGuides(data);
      if (plotGuides?.xAxis) {
        meLoadOverTimeJSON.xAxis = Object.assign({}, meLoadOverTimeJSON.xAxis, plotGuides.xAxis);
      }
    }


    meLoadOverTimeJSON.exporting.filename = `${vesselUtils.getVesselName(widget.vesselIds[0])}-MAIN ENGINE LOAD HISTORY`;
    
    meLoadOverTimeJSON.exporting.buttons.contextButton= {
        menuItems: [
            "download",
            "customSeparator",
            {
                text: "XLSX",
                onclick: function () {
                    let name = `${vesselUtils.getVesselName(widget.vesselIds[0])}-MAIN ENGINE LOAD HISTORY`
                    name = name.replace(/\./g, "");
                    let sheetsName   = name;
                    
                    if (sheetsName.length > 30) {
                        sheetsName = "MAIN ENGINE LOAD HISTORY"
                    }

                    let headers = this.getDataRows(true)[0]
                    const rows = this.getDataRows(true);
                    const newRows = rows.map(row => row.map((value, index) => 
                        index === 0 ? moment.utc(value).format('DD-MM-YYYY HH:mm:ss') : value))
                    
                    headers = headers.slice(0, headers.length).map((val) => val === "Time (UTC)" ? "Datetime" : "Engine Load (%)");

                    let updatedows = [headers, ...newRows.slice(1, newRows.length)];            
                                    
                    downloadChartInXLSXform({xlsxRows: updatedows, sheetsName: sheetsName, name: name});
                }
            },
            {
                text: "CSV",
                onclick: function () {
                    let name = `${vesselUtils.getVesselName(widget.vesselIds[0])}-MAIN ENGINE LOAD HISTORY`
                    name = name.replace(/\./g, "");

                    let headers = this.getDataRows(true)[0]
                    const rows = this.getDataRows(true);
                    const newRows = rows.map(row => row.map((value, index) => 
                        index === 0 ? moment.utc(value).format('DD-MM-YYYY HH:mm:ss') : value))

                    headers = headers.slice(0, headers.length).map((val) => val === "Time (UTC)" ? "Datetime" : "Engine Load (%)");

                    let updatedows = [headers, ...newRows.slice(1, newRows.length)];            
                                    
                    const csvContent = updatedows.map(row => row.join(',')).join('\n');

                    downloadChartInCSVform({csvContent, name: name});

                }
            },
            "downloadPDF", "downloadPNG", "downloadJPEG", "downloadSVG",
        ]
    }


    const chartHasData = meLoadOverTimeJSON.series.find(serie => serie?.data?.length > 0);
    if(!chartHasData) meLoadOverTimeJSON = {};
    
    updateState(id, meLoadOverTimeJSON, widget?.id);
};


export { LoadLineChartPayload, updateLoadLineChartJson };
