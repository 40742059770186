import React from 'react';
import {withRouter} from 'react-router-dom';
import {ReactComponent as VesselIcon} from 'assets/img/app/monitoring-imgs/ship-icon.svg';
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import {vesselStore, vesselUtils, vesselGroupUtils} from 'common/store/storeUtils';
import GlobalUtilities from 'Utilities/global';
import { useDispatch } from 'react-redux'; 

const VesselNameBox = (props) => {
    // store vars
    const dispatch = useDispatch();

    const getVesselGroupId = () => {
        if (props?.data?.vesselObj?.vesselGroupId) return props?.data?.vesselObj?.vesselGroupId;
        return vesselStore().allVessels.filter(vessel => vessel.name === props?.data?.vesselName)[0]?.vesselGroupId;
    }

    const styleObj = {
        borderColor: GlobalUtilities.vesselBgColor(vesselGroupUtils.getFleetVesselsFromFleetId(getVesselGroupId()), props?.data?.vesselName),
    }

    // update css variable onmouseoverDynamicColor according to vessel color
    const root = document.documentElement;

    const onHoverHandler = () => {
        root.style.setProperty('--onmouseoverDynamicColor', GlobalUtilities.vesselBgColor(vesselGroupUtils.getFleetVesselsFromFleetId(getVesselGroupId()), props?.data?.vesselName));
    }

    const vesselClickHandler = () => {
        if (props.location.pathname.includes('dailyGroupReport') && !props.data.noReports) {
            const vessel = vesselUtils.getFleetVessels(dispatch).find(vessel => vessel.name === props.data.vesselName);
            dispatch({ type: 'vessel/setSelectedVessel', payload: vessel.vesselId });
            props.history.push({
                pathname: '/reports/dailyVesselReport',
                state: {pushFromFleetReport: true, reportPeriod: props.data.reportPeriod}
            });
        }
    }

    return (
        <div className="vessel-name-box">
            {!props.data.noReports ?
                <div className="vessel-name-box__rect d-flex justify-content-start align-items-center dynamicHoverColor pointer"
                     onClick={props?.data?.clickable ? props?.data?.onClick ? props?.data?.onClick : vesselClickHandler : () => {}} 
                     style={styleObj}
                     onMouseEnter={onHoverHandler}
                >
                    <VesselIcon fill={GlobalUtilities.vesselBgColor(vesselGroupUtils.getFleetVesselsFromFleetId(getVesselGroupId()), props?.data?.vesselName)} />
                    <div className="table-label-element">
                        {props.data.vesselName}
                    </div>
                </div> :
                <Tooltip title="No report available" component={<div
                    className="vessel-name-box__rect d-flex justify-content-start align-items-center not-allowed"
                    onClick={props?.data?.clickable ? vesselClickHandler : () => {}} 
                    style={styleObj}
                    onMouseEnter={onHoverHandler}
                >
                    <VesselIcon fill={GlobalUtilities.vesselBgColor(vesselGroupUtils.getFleetVesselsFromFleetId(getVesselGroupId()), props?.data?.vesselName)} />
                    <div className="table-label-element">
                        {props.data.vesselName}
                    </div>
                </div>}/>

            }

        </div>
    );
}

export default withRouter(VesselNameBox);
