import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import MapComponent from 'components/map/map';
import Layout from 'components/core/Layout';
import AlertWaste from 'components/alerts/alertWaste';
import FuelOverconsumption from 'components/alerts/fuelOverconsumprion';
import RouteMonitoring from 'components/alerts/routeMonitoring';
import PistonsMonitoring from 'components/alerts/pistonsMonitoring';
import AlertsPayloads from 'components/alerts/alertsPayloads';
import service from 'common/js/service';
import { useDispatch } from 'react-redux';

let pistonsData = [];

const FuelConsumption = props => {
    const [widgets, setWidgets] = useState({ layout: [] });
    const [excessFuel, setExcessFuel] = useState('-');
    const [fuelWaste, setFuelWaste] = useState('-');
    const [fuelPricesDatetime, setFuelPricesDatetime] = useState('-');
    const [pistonsTabData, setPistonsTabData] = useState({});
    const [pistonsTotalNum, setPistonsTotalNum] = useState(0);
    const [pistonsChartData, setPistonsChartData] = useState({});
    const [routeMonitoringData, setRouteMonitoringData] = useState({});
    const [requestOnGoing, setRequestOnGoing] = useState({
        waste: false,
        routeMonitoring: false,
        pistons: false,
    });

    // store vars
    const dispatch = useDispatch();

    const updateRequestOnGoing = (key, value) => {
        setRequestOnGoing((currentRequestOnGoing) => {return {...currentRequestOnGoing, [key]: value}});
    }

    const getWidgetsPreferences = () => {
        const title = <div className="flex-space-between full-width marg-r-15 widget-title-legend">
            <div>PISTONS</div>
            <div>LEGEND: <div className="tile tile__green" /> Exhaust Gas Outlet Temp <div className="tile tile__blue" /> Jacket Cooling Fresh Water Outlet Temp </div>
        </div>;

        return {
            className: 'padding-left-right-7',
            layout: [{
                columns: [{
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 4 },
                    className: 'padding-left-right-7',
                    layout: [{
                        columns: [{
                            grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                            components: [
                                { id: 'fuelOverconsumption', title: "", type: 'text', component: FuelOverconsumption, data: {}, style: { height: '137px', padding: '15px' }, props: {} }
                            ]
                        },
                        {
                            grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                            components: [
                                { id: 'waste', title: "", type: 'text', component: AlertWaste, data: {}, style: { height: '256px' }, props: {} }
                            ]
                        }]
                    }]
                }, {
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 8 },
                    className: 'padding-left-right-7',
                    layout: [{
                        columns: [{
                            grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                            components: [
                                { id: 'route', title: "ROUTE", type: 'map', component: MapComponent, data: {}, style: { height: '335px' }, props: {} }
                            ]
                        }
                        ]
                    }]
                }],
            }, {
                columns: [{
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                    components: [
                        { id: 'routeMonitoring', title: "ROUTE MONITORING", type: 'lineChart', component: RouteMonitoring, data: {}, style: { height: '1050px' }, props: { } }
                    ]
                }
                ]
            },
            {
                columns: [{
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                    components: [
                        { id: 'pistons', type: 'lineChart', component: PistonsMonitoring, data: {}, style: { height: 'auto' }, props: { }, title }
                    ]
                }
                ]
            }
            ]
        };
    };

    // Pistons Section - Get Data for Piston Synced Charts for selected Piston Tab
    const getPistonsData = (tab) => {
        if (!tab) tab = 0;
        let pistonsChartData = [], tempPistonsOriginalData = JSON.parse(JSON.stringify(pistonsData));

        if (tempPistonsOriginalData.length > 0) {
            tempPistonsOriginalData.forEach(serie => {
                if (serie.metricName === 'cylExhGasOutTempNo' + (tab + 1)) pistonsChartData[0] = serie;
                if (serie.metricName === 'cylJCFWOutTempNo' + (tab + 1)) pistonsChartData[1] = serie;
            });

            // Remove condition below - on init chart is not starting if first piston is empty
            // if(pistonsChartData[0]?.value?.length === 0 && pistonsChartData[1]?.value?.length === 0) return setPistonsChartData({});

            setPistonsChartData({
                series: [
                    {data: pistonsChartData[0].value},
                    {data: pistonsChartData[1].value}
                ],
                activePiston: tab
            });
        }
    }

    // Pistons Section - Update pistons data according to selected piston tab
    const updatePistonsComponent = (value) => {
        getPistonsData(value);
    }

    // Route Monitoring Section - Get data
    const getRouteMonitoringData = (result) => {
        setRouteMonitoringData(result);
    }

    const initAlertsPage = (activeAlert, firstTime) => {
        const tmpAlert = activeAlert;
        AlertsPayloads.sensor.fromDate = tmpAlert.startingDate;
        AlertsPayloads.sensor.toDate = tmpAlert.endingDate;
        AlertsPayloads.sensor.vessels = [tmpAlert.vesselId];

        if (!firstTime) service.getAnomaliesForMapBullets({
            vesselId: tmpAlert.vesselId,
            from: tmpAlert.startingDate,
            to: tmpAlert.endingDate,
            granularity: 30,
            type: tmpAlert.alertType,
            timeUnit: 'minute'
        }).then(result => {
            dispatch({ type: 'alerts/setAlertMapBulletsAnomalies', payload: result.data });
        });

        updateRequestOnGoing('waste', true);
        service.getAlertFuel({ vesselId: tmpAlert.vesselId, fromDate: tmpAlert.startingDate, toDate: tmpAlert.endingDate }).then(result => {
            updateRequestOnGoing('waste', false);
            setExcessFuel(result.data ? result.data.fuelOverConsumption || '-' : '-');
            setFuelWaste(result.data.moneyWaste);
            setFuelPricesDatetime(result.data.fuelPricesDatetime || '-')
        });

        updateRequestOnGoing('routeMonitoring', true);
        service.getAlertSensorData(AlertsPayloads.sensor).then(result => {
            updateRequestOnGoing('routeMonitoring', false);
            // + alert type
            service.getAlertAnomalies({
                from: tmpAlert.startingDate,
                to: tmpAlert.endingDate,
                vesselId: tmpAlert.vesselId,
                type: tmpAlert.alertType,
                timeUnit: 'minute'
            }).then(anomalies => {
                let tempArray = [{ value: [] }, { value: [] }, { value: [] }, { value: [] }, { value: [] }, { value: [] }], pistonsDataTemp = [];

                tempArray[0] = { metricName: 'anomalies', value: anomalies.data ? anomalies.data : { data: [] } };
                if (result.data.series.length > 0) {
                    result.data.series[0].values.forEach(serie => {
                        if (serie.metricName === 'power') tempArray[1] = serie || [];
                        if (serie.metricName === 'rpm') tempArray[2] = serie || [];
                        if (serie.metricName === 'foVolConsumption') tempArray[3] = serie || [];
                        if (serie.metricName === 'foInPress') tempArray[4] = serie || [];
                        if (serie.metricName === 'foInTemp') tempArray[5] = serie || [];
                        if (serie.metricName.includes('cyl')) pistonsDataTemp.push(serie);
                    });
                }

                pistonsData = pistonsDataTemp;
                getPistonsData(0)
                getRouteMonitoringData(tempArray);
            });
        });

        updateRequestOnGoing('pistons', true);
        service.getAlertPistons({ vesselId: tmpAlert.vesselId, fromDate: tmpAlert.startingDate, toDate: tmpAlert.endingDate }).then(result => {
            let string = 'cylExhGasOutTemp', cylNum = 0, keyArray = [];
            updateRequestOnGoing('pistons', false);

            if (!result.data) return;

            Object.keys(result.data).forEach(key => {
                keyArray = key.split('No');
                if (key.includes(string) && parseInt(keyArray[1]) > cylNum) cylNum = parseInt(keyArray[1]);
            });

            setPistonsTabData(result.data);
            setPistonsTotalNum(cylNum);
        });
    }

    const alertsCalls = (activeAlertTemp) => {
        service.getAnomaliesForMapBullets({
            vesselId: activeAlertTemp.vesselId,
            from: activeAlertTemp.startingDate,
            to: activeAlertTemp.endingDate,
            granularity: 30,
            type: activeAlertTemp.alertType,
            timeUnit: 'minute'
        }).then(result => {
            dispatch({ type: 'alerts/setAlertMapBulletsAnomalies', payload: result.data });
        });

        setWidgets(getWidgetsPreferences());

        initAlertsPage(activeAlertTemp, true);
    }

    //useEffect here------------------------------------------

    useEffect(() => {
        if(props.activeAlert?.id) alertsCalls(props.activeAlert);
    // eslint-disable-next-line
    }, [props.activeAlert]);

    //-------------------------------------------------------------

    const data = {
        route: {
            reports: true,
            alerts: true,
        },
        fuelOverconsumption: {
            data: Object.keys(props.activeAlert).length === 0 ? {}
                : {...props.activeAlert},
            loading: requestOnGoing.fuelOverconsumption,
        },
        waste: {
            excessFuel: excessFuel,
            fuelWaste: fuelWaste,
            activeAlert: {...props.activeAlert},
            fuelPricesDatetime: fuelPricesDatetime,
            loading: requestOnGoing.waste,
        },
        routeMonitoring: {
            chartData: routeMonitoringData,
            loading: requestOnGoing.routeMonitoring,
        },
        pistons: {
            chartData: pistonsChartData,
            pistonsTotalNum: pistonsTotalNum,
            updateParentState: updatePistonsComponent,
            from: props.activeAlert.startingDate,
            to: props.activeAlert.endingDate,
            pistonsTabData: pistonsTabData,
            requestOnGoing: requestOnGoing.pistons,
        }
    }

    const createLayoutProps = (data, props = widgets) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
                    ('id' in newComponent) && !('element' in newComponent) && (newComponent.id in data) && (newComponent.props = data[newComponent.id]);
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    }

    return (
        <Layout {...createLayoutProps(data)} />
    )
}

export default withRouter(FuelConsumption);
