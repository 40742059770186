import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as InfoIcon } from 'assets/img/app/global-imgs/infoSmallDisabledIcon.svg';
import classnames from "classnames";

const AutocompleteRenderedOption = ({ optionType, content }) => {
    const getOptionClasses = () => {
        return classnames('autocomplete-title', {'messageOption': optionType === 'messageOption'})
    };
    
    return(
        (optionType === 'basicOption' || optionType === 'messageOption')
            ? <div className={getOptionClasses()}>
                {content}
            </div>
            : (optionType === 'disabledMessageOption')
                ? <div className='messageOption disabledOption flex-centered'>
                    <div className='infoIcon'>
                        <InfoIcon/>
                    </div>
                    <div className='autocomplete-title marg-l-10'>
                        {content}
                    </div>
                </div>
            : <></>
    )
};

AutocompleteRenderedOption.propTypes = {
    optionType: PropTypes.string,
    content: PropTypes.string
}

export default AutocompleteRenderedOption;