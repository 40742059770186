import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ChipComponent = ({icon, text, extraClasses, extraProps}) => {
    const getChipClasses = () => {
        return classnames(
            'chip', 
            {[extraClasses]: extraClasses}
        );
    }

    return(
        <div className={getChipClasses()} {...extraProps}>
            {icon && icon}
            {text}
        </div>
    )
}

ChipComponent.propTypes = {
    icon: PropTypes.element,
    text: PropTypes.string,
    extraClasses: PropTypes.string,
    extraProps: PropTypes.shape({})
}

export default ChipComponent;