import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import moment from 'moment';
import {ReactComponent as LogoImage} from "assets/img/app/deepsea-logo.svg";
import {ReactComponent as CloseIcon} from 'assets/img/app/global-imgs/close.svg';
import {ReactComponent as TermsErrorIcon} from 'assets/img/app/global-imgs/termsError.svg';
import { useDispatch, useSelector } from 'react-redux';
import {termsStore, termsUtils} from 'common/store/storeUtils';

const TermsModal = () => {
    let anchors = {};
    const [termsDisplay, setTermsDisplay] = useState('current');

    // vars for JSX
    const termsStoreJSX = useSelector(state => state.terms);

    const dispatch = useDispatch();

    //set react refs for scrolling on clicks!
    (termsStore().currentTerms?.data?.length > termsStore().futureTerms?.data?.length ? termsStore().currentTerms?.data : termsStore().futureTerms?.data)
        .forEach((section, i) => {anchors[`anchor${i}`] = React.createRef();});

    useEffect(() => {
        if(termsStore().modalShow) termsUtils.termsFlow(dispatch, termsStore().hasReceivedTerms);
    // eslint-disable-next-line
    }, [termsStoreJSX.modalShow]);

    const renderError = (text) => <div className="flex-centered-col marg-t-40 terms-error section-title">
        <TermsErrorIcon className="svg-path marg-b-40" />
        {text}
    </div>;

    return (
        <Dialog open={termsStoreJSX.modalShow} onClose={() => dispatch({ type: 'terms/setTerms', payload: {key: 'show_modal', value: false} })} aria-labelledby="terms-dialog-title" className="centered-modal terms-dialog">
            <DialogTitle id="terms-dialog-title">
                <LogoImage className="modal-terms-logo svg-g-path" />
                <div className="close-button" onClick={() => dispatch({ type: 'terms/setTerms', payload: {key: 'show_modal', value: false} })}>
                    <div className="close-button__img">
                        <CloseIcon className="svg-defs-path-g-maskUse" />
                    </div>
                </div>
            </DialogTitle>
            {
                termsStoreJSX.errorServer !== false || (termsStoreJSX.errorCurrent !== false && termsStoreJSX.errorNew !== false)
                ? <DialogContent>
                    <div className="flex-centered-col">
                        <div className="terms-header marg-b-10">Terms & Conditions</div>
                        {renderError(termsStoreJSX.errorServer)}
                    </div>
                </DialogContent>
                : termsStoreJSX.loadTerms &&
                <DialogContent>
                    <div className="flex-centered-col">
                        <div className="terms-header marg-b-10">Terms & Conditions</div>
                        <div className="center-text terms-subHeader">ANNEX 1</div>
                        <div className="center-text terms-subHeader marg-b-10">
                            GENERAL TERMS & CONDITIONS (GTC) FOR A CLOUD SERVICE SUBSCRIPTION AGREEMENT
                        </div>
                        <div className="center-text terms-text">
                            {
                                termsStoreJSX.hasNewTerms ?
                                    <React.Fragment>
                                        <span className={`terms-version${termsDisplay === 'future' ? ' active' : ''}`}
                                              onClick={() => setTermsDisplay('future')}
                                        >
                                            Updated Version effective from {moment(termsStoreJSX.futureTerms?.date).utc().format('MMMM DD, YYYY')}
                                        </span>
                                        <span className="terms-separator"></span>
                                        <span className={`terms-version${termsDisplay === 'current' ? ' active' : ''}`}
                                              onClick={() => setTermsDisplay('current')}
                                        >
                                            Current Version
                                        </span>
                                    </React.Fragment> :
                                    `Effective from ${moment(termsStoreJSX.currentTerms?.date).utc().format('MMMM DD, YYYY')}`
                            }
                        </div>
                    </div>
                    {termsStoreJSX.errorCurrent !== false && termsDisplay === 'current' && renderError(termsStoreJSX.errorCurrent)}
                    {termsStoreJSX.errorNew !== false && termsDisplay === 'future' && renderError(termsStoreJSX.errorNew)}
                    <div className="flex-centered-start marg-t-40">
                        {((termsStoreJSX.errorCurrent === false && termsDisplay === 'current') || (termsStoreJSX.errorNew === false && termsDisplay === 'future')) &&
                            <React.Fragment>
                                <div className='anchor-area'>
                                    {(termsDisplay === 'current' ? termsStoreJSX.currentTerms?.data : termsStoreJSX.futureTerms?.data)
                                        .map((section, i) => {
                                        return <Anchor
                                            key={`anchor${i}`}
                                            reference={anchors[`anchor${i}`]}
                                            title={section.title}
                                            indicator={section.indicator}
                                        />;
                                    })}
                                </div>
                                <div className='scrollable-area'>
                                    {(termsDisplay === 'current' ? termsStoreJSX.currentTerms?.data : termsStoreJSX.futureTerms?.data)
                                        .map((section, i) => {
                                        return <AnchorTarget
                                            key={`anchorTarget${i}`}
                                            reference={anchors[`anchor${i}`]}
                                            title={section.title}
                                            indicator={section.indicator}
                                            paragraphs={section.paragraphs || []}
                                            text={section.text || ''}
                                        />;
                                    })}
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </DialogContent>
            }
        </Dialog>
    )
};

const Anchor = ({reference, title, indicator}) => {
    return <div className="terms-anchor d-flex marg-b-10" onClick={() => reference.current.scrollIntoView()}>
        <div className="marg-r-5">{indicator}</div>
        <div>{title}</div>
    </div>;
};

const AnchorTarget = ({reference, text, paragraphs, title, indicator}) => {
    let props = {className: "marg-b-40"};
    !(reference === undefined) && (props.ref = reference);

    const renderParagraphs = (paragraphs) => {
        return paragraphs.map(paragraph => {
            return <div key={`paragraph${paragraph.indicator}`}>
                <div className="d-flex marg-b-10">
                    <div className="terms-paragraph paragraph-indicator">{paragraph.indicator}</div>
                    <div className="terms-text paragraph-text">{paragraph.content}</div>
                </div>
                <div className="marg-b-10 marg-l-50">
                    {'paragraphs' in paragraph && renderParagraphs(paragraph.paragraphs)}
                </div>
            </div>;
        });
    };

    return (
        <div {...props}>
            {!(title === undefined) && <div className="terms-title d-flex marg-b-30">
                <div className="marg-r-5">{indicator}</div>
                <div>{title}</div>
            </div>}
            {text !== '' && <div className="d-flex marg-b-10">
                <div className="terms-text paragraph-text">{text}</div>
            </div>}
            {renderParagraphs(paragraphs)}
        </div>
    );
};

export default TermsModal;