import meStorage from "../../components/monitoringCategories/localStorages/meStorage";


const setBarTablesDataFormat = (barsTableData) => {
  let tmpObj = {};
  barsTableData.forEach(obj => {
    if (obj.value.length === 0) return;
    tmpObj[obj.metricName] = obj.value
  });
  return tmpObj;
};

export const pistonsChartPayload = {
  xAxis: "TIME",
  withNavigationStatus: true,
  aggregation: "AVG",
  metrics: [{
    metricCategory: "MAIN_ENGINE",
    subIds: [1],
    metricData: []
  }]
}

export const pistonLineChartData = {
  pistonsChartMetrics: [
    'cylExhGasOutTemp', 'cylJCFWOutTemp', 'cylPistonCOOutTemp', 'scavAirFireDetTemp'
  ],
  chartAxes: {},              // Dynamically generated in buildNavigationChartAxes method
  pistonsLineChartTabs: [],   // Dynamically generated in buildNavigationChartTabs method
  navigationMapper: {},       // Dynamically generated in buildNavigationMapper method
  chartSeries: {}             // Dynamically generated in buildNavigationChartSeries method
}

export const buildPistonsChartPayload = (result) => {
  pistonsChartPayload.metrics[0].metricData = [];

  if (!result?.noCyllinders) result.noCyllinders = 12;
  for (let i = 1; i <= result.noCyllinders; i++) {
    pistonLineChartData.pistonsChartMetrics.forEach((metric, index) => {
      pistonsChartPayload.metrics[0].metricData.push({'metricName': `${metric}No${i}`});
    })
  }

  // Navigation Mapper - Build Navigation Mapper object
  buildNavigationMapper(result.noCyllinders);

  // Navigation Chart - Build Tabs Object
  buildNavigationChartTabs(result.noCyllinders);

  // Navigation Chart - Build Axes Object
  buildNavigationChartAxes(result.noCyllinders);

  // Navigation Chart - Build Series Object
  buildNavigationChartSeries(result.noCyllinders);
}

const buildNavigationMapper = noCyllinders => {
  pistonLineChartData.navigationMapper = {};

  for (let cylNum = 1; cylNum <= noCyllinders; cylNum++) {
    const tempArr = pistonLineChartData.pistonsChartMetrics.map((metric) => {
      return `${metric}No${cylNum}`
    })

    pistonLineChartData.navigationMapper[`p${cylNum}`] = tempArr;
  }

}

const buildNavigationChartTabs = noCyllinders => {
  pistonLineChartData.pistonsLineChartTabs = [];
  for (let cylNum = 1; cylNum <= noCyllinders; cylNum++) {
    pistonLineChartData.pistonsLineChartTabs.push({title: `P${cylNum}`, name: `p${cylNum}`});
  }

}

const buildNavigationChartAxes = noCyllinders => {
  pistonLineChartData.chartAxes = {};

  for (let cylNum = 1; cylNum <= noCyllinders; cylNum++) {
    pistonLineChartData.chartAxes[`p${cylNum}`] = [
      { id: 'exhTemp-axis', title: { text: 'Exh. Gas Out. Temp (°C)', offset: 60}, height: '100px', top: '60px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
      { id: 'jackCool-axis', title: { text: 'Jacket Cooling Fresh Water Out. Temp (°C)', offset: 60 }, height: '100px', top: '210px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
      { id: 'pistCool-axis', title: { text: 'Piston Cooling Oil Out. Temp (°C)', offset: 60 }, height: '100px', top: '360px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
      { id: 'scavFire-axis', title: { text: 'Scavenge Air Fire Detection Temp (°C)', offset: 60 }, height: '100px', top: '510px', allowDecimals: false, lineWidth: 0, offset: 2, opposite: false },
    ]
  }
}

const buildNavigationChartSeries = noCyllinders => {
  pistonLineChartData.chartSeries = {};
  for (let cylNum = 1; cylNum <= noCyllinders; cylNum++) {
    pistonLineChartData.chartSeries[`p${cylNum}`] = [
      { id: `cylExhGasOutTempNo${cylNum}`, name: 'Exh. Gas Out. Temp', data: [], tooltipTitle: 'Exh. Gas Out. Temp', type: 'line', yAxis: 'exhTemp-axis',
        titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
      { id: `cylJCFWOutTempNo${cylNum}`, name: 'Jacket Cooling Fresh Water Out. Temp', data: [], tooltipTitle: 'Jacket Cooling Fresh Water Out. Temp', type: 'line', yAxis: 'jackCool-axis',
        titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
      { id: `cylPistonCOOutTempNo${cylNum}`, name: 'Piston Cooling Oil Out. Temp', data: [], tooltipTitle: 'Piston Cooling Oil Out. Temp', type: 'line', yAxis: 'pistCool-axis',
        titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } },
      { id: `scavAirFireDetTempNo${cylNum}`, name: 'Scavenge Air Fire Detection Temp', data: [], tooltipTitle: 'Scavenge Air Fire Detection Temp', type: 'line', yAxis: 'scavFire-axis',
        titleSuffix: '(UTC)', tooltip: { valueSuffix: ' °C', valueDecimals: 2 } }
    ]
  }

}
export const updatePistonsChartJson = (id, data, updateState, extraChartConfigs, widget) => {
  meStorage.setPistonsData(data);
  let pistonsLineChartData = (data[0]?.values) ? setBarTablesDataFormat(data[0]?.values) : [];

  updateState(id, pistonsLineChartData, widget?.id);
}
