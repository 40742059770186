import React from 'react';
import NoData from "components/noData/noData";
import InfoValuesGridWithIcon from "../../components/infoValuesGridWithIcon/infoValuesGridWithIcon";

const TravellingInfoWidget = props => {
    const travellingInfoData = {
        style: props.style,
        data: props.data[0].data,
        icon: props.data[0].icon
    };

    return (
        <>
            {Object.keys(travellingInfoData.data).length > 0 ? <InfoValuesGridWithIcon {...travellingInfoData} /> :
                <NoData displayNoDataIcon={true} height={180} className="svg-path-no-data marg-b-5"
                        text={"No data to display at the moment"}/>}
        </>
    );
}

export default TravellingInfoWidget;
