import React, {useState} from 'react';
import SetLegModal from 'components/modals/setLegModal';
import {ReactComponent as NoDataIcon} from 'assets/img/app/global-imgs/no-data.svg';


const FullPageNoReportMessage = props => {
    const [modalShow, setModalShow] = useState(false);

    const setUserLeg = values => {
        setModalShow(false);
        props.selectUserLeg(values);
    }

    return (
        <React.Fragment>
        <div className="full-page-no-report-message d-flex justify-content-center align-items-center">
            <div className="full-page-no-report-message__container d-flex justify-content-center align-items-center flex-column">
                <NoDataIcon className="svg-path-no-data marg-b-5" />
                <div className="full-page-no-report-message__container__text section-title marg-b-15">We haven't found an available leg</div>
                <button type="btn" className="main-cta big-cta" onClick={() => setModalShow(true)}>
                    <div className="main-cta__text">Search Leg</div> 
                </button>
            </div>
        </div>
        <SetLegModal show={modalShow} onHide={() => setModalShow(false)} onSave={setUserLeg} data={{}} />
        </React.Fragment>
    );
}
 
export default FullPageNoReportMessage;