import React, { useState, useEffect } from 'react';
import SyncCharts from "components/charts/genericSyncCharts/syncCharts";
import syncChartsOptions from "components/charts/lineChart/react-lineChartStock.js";

const HullFoulingPenaltyOvertime = ({ data }) => {
    const [syncCharts, setSyncCharts] = useState(syncChartsOptions());

    useEffect(() => {
        if(!data) return;
        setSyncCharts(data);
    }, [data]);

    const setNavigatorExtremes = (data, chartRef) => {  
        if(!data?.length) return;

        // the max time range of the handles to be 2 years
        const maxTimestamp = data[data.length - 1].x
        const minTimestamp = maxTimestamp - 63072000000 < data[0].x ? data[0].x : maxTimestamp - 63072000000;

        chartRef.current.chart.xAxis[0].setExtremes(
            minTimestamp, 
            maxTimestamp
        );
    };

    return(
        <div>
            {data !== "" && (
                <SyncCharts options={syncCharts} setNavigatorExtremes={setNavigatorExtremes} />
            )}
        </div>
    )
};

export default HullFoulingPenaltyOvertime;