import moment from 'moment';
import ThemeColors from 'sass/_themeColorsForJs.scss';
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import {monitoringStore, userStore} from 'common/store/storeUtils';

const GlobalUtilities = {
    signal: null,

    missingValuesLimit: 2,

    demoTimestamp: 1681549140000,

    toDate: timestamp => {
        return moment(timestamp).utc().format('YYYY-MM-DD HH:mm');
    },

    roundToDigits: (number, digits) => {
        number = parseFloat(number);
        if(number === 0) return 0;
        if(!number || isNaN(number) || number === null) return null;
        if(!isNaN(number)) {return parseFloat(parseFloat(number).toFixed(digits))};
    },

    renderNumber: (number, noDash) => {
        if ( (number === null || number === undefined || isNaN(number)) && !noDash ) return '-';
        if ( (number === null || number === undefined || isNaN(number)) && noDash ) return '';
        return number;
    },

    renderNumberWithNoZerosAfterDigit: (number, deimals) => {
        if(number === 0 || number === "0") return 0;
        if(!number) return '-';
        return GlobalUtilities.roundToDigits(number, deimals).toString().replace(/(\.0+)$/, '');
    },

    hasNumber: (string) => {
        return /\d/.test(string);
    },

    hasNotValue: value => {
        if(value !== undefined && value !== null) return false;
        return true;
    },

    removeNumbersFromString: string => {
        return string.replace(/\d+/g, '');
    },

    getThemeColors: {
        text: () => {
            const user = userStore().user;
            return (user.theme !== 'ds-white') ? 'white' : '#41484e';
        },
        backColor: () => {
            return ThemeColors['disabled_surface'];
        },
        gradient: () => {
            const user = userStore().user;
            return (user.theme !== 'ds-white')
            ? [{ linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 }, stops: [[0, '#5ee5c8'],[1, '#2859e4']] }]
            : [{ linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 }, stops: [[0, '#5ee5c8'],[1, '#2859e4']] }];
        },
        opacityGradient: () => {
            const user = userStore().user;
            return (user.theme !== 'ds-white')
            ? [{ linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 }, stops: [[0, 'rgba(94, 229, 200, 0.36)'],[1, 'rgba(40, 89, 228, 0.36)']] }]
            : [{ linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 }, stops: [[0, 'rgba(94, 229, 200, 0.36)'],[1, 'rgba(40, 89, 228, 0.36)']] }];
        },
        chartFormatGradient: () => {
            return {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                  [0, '#5ee5c8'],
                  [1, '#2859e4']
                ]
            }
        }
    },

    getOccupations: () => {
        return [
            {id: 1, name: 'COORDINATOR'}, {id: 2, name: 'FLEET_MANAGER'},
            {id: 3, name: 'HEAD_OF_TECHNICAL'}, {id: 4, name: 'DEEPSEA_DEV'},
            {id: 5, name: 'DEEPSEA_SUP'}
        ]
    },

    getUsersStatus: () => {
        return [
            {name: 'ACTIVE', id: 1},
            {name: 'PENDING', id: 2},
            {name: 'INACTIVE', id: 3}
        ]
    },

    getUsersTypes: () => {
        return [
            {name: 'USER', id: 1}, {name: 'ADMIN', id: 2},
            {name: 'GOD', id: 3}, {name: 'REGISTRATION', id: 4}
        ]
    },

    getNavigationStatus: status => {
        const mapper = {
            "TRAVELLING": { name: 'Travelling', color: ThemeColorsForCharts()['curious_blue'] },
            "ANCHORED": { name: 'Anchored', color: ThemeColorsForCharts()['green_bay'] },
            "MOORED": { name: 'Moored', color: ThemeColorsForCharts()['violet'] },
            "RESTRICTED_MANEUVERABILITY": { name: 'Restricted Maneuverability', color: ThemeColorsForCharts()['medium_severity'] },
            "NOT_UNDER_COMMAND": { name: 'Not Under Command', color: ThemeColorsForCharts()['amaranth_red'] },
            "UNDER_WAY_SAILING": { name: 'Sailing', color: ThemeColorsForCharts()['malibu_blue'] },
            "UNKNOWN": { name: 'Unknown', color: ThemeColorsForCharts()['disabled_surface'] },
            "DEPARTURE": { name: '', color: ThemeColorsForCharts()['amaranth'] },
            "ARRIVAL": { name: '', color: ThemeColorsForCharts()['tomato'] }
        }
        if (!mapper[status]) status = "UNKNOWN";
        return mapper[status];
    },

    getBoilerStatus: status => {
        const mapper = {
            "ON": { name: 'ON', color: ThemeColors['fine_status'] },
            "OFF": { name: 'OFF', color: ThemeColors['disabled_text'] },
        }
        if (!mapper[status]) status = "UNKNOWN";
        return mapper[status];
    },

    getDetailedGranularityPeriod: () => {
        if ((!monitoringStore().lessThanADayPeriod.status) && !monitoringStore().detailedData.status) return {from: null, to: null}
        const periodFrom = ((monitoringStore().lessThanADayPeriod.status) && !monitoringStore().detailedData.status) 
            ? monitoringStore().fromTo.from
            : monitoringStore().detailedData.from
        const periodTo = ((monitoringStore().lessThanADayPeriod.status) && !monitoringStore().detailedData.status) 
            ? monitoringStore().fromTo.to
            : monitoringStore().detailedData.to
        return {from: periodFrom, to: periodTo};
    },
    
    periodString : (data, pointDate, pointValue, period) => {
        return `${data?.value?.length === 1
            ? moment.utc(period.from).format('DD/MM/YYYY HH:mm')
            : data?.value[0]?.y === pointValue 
                ? moment.utc(period.from).format('DD/MM/YYYY HH:mm') 
                : moment(pointDate).startOf("date").format("DD/MM/YYYY HH:mm")} 
                -  
        ${data?.value?.length === 1
            ? moment.utc(period.to).format('DD/MM/YYYY HH:mm')
            : data?.value[0]?.y === pointValue
                ? moment(pointDate).endOf("date").format('DD/MM/YYYY HH:mm')
                : moment.utc(period.to).format('DD/MM/YYYY HH:mm')}`
    },

    fadeOut: (el, time) => {
        if(!el) return;
        let fadeEffect = setInterval(() => {
            if (!el.style.opacity || el.style.opacity === 0) el.style.opacity = 1;
            if (el.style.opacity > 0) el.style.opacity -= 0.2
            else {
                clearInterval(fadeEffect);
                el.style.display = 'none';
            }
        }, time);
    },

    getUserFullName: () => {
        const user = userStore().user;
        return `${user.firstName} ${user.lastName}`
    },

    isDemo: () => {
        return ( window.location.href.includes('-demo') && window.location.href.includes('deepsea.ai') );
    },

    nowOrDemo: () => {
        return GlobalUtilities.isDemo() ? GlobalUtilities.demoTimestamp : moment().utc().valueOf()
    },
    vesselBgColor: (vessels, currentVesselName) => {
        if(!vessels || !vessels?.length) return ThemeColors['fleetColor0'];
        
        const currentVessel = vessels.find(vessel => vessel.name === currentVesselName);
        const currentVesselIndex = vessels.indexOf(currentVessel);
        const color = currentVesselIndex <= 15
            ? ThemeColors[`fleetColor${currentVesselIndex}`]
            : ThemeColors[`fleetColor${ currentVesselIndex % 16 }`];

        return color || ThemeColors['fleetColor0'];
    },
    upperCaseFirstLetter: str => `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`,
    stringIsNumeric: str => {
        if(typeof str != 'string') return false;
        return !isNaN(str) && !isNaN(parseFloat(str));
    }
}

export default GlobalUtilities;
