import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CircularIconWrapper = ({icon, color, extraClasses}) => {
    
    const getExtraClasses = (extraClasses) => {
        return classnames('circularIconWrapper', {[extraClasses]: extraClasses});
    }

    return(
        <div 
            className={getExtraClasses(extraClasses)} 
            style={{backgroundColor: color}}
        >
            {icon}
        </div>
    )
}

CircularIconWrapper.propTypes = {
    icon: PropTypes.element,
    color: PropTypes.string,
    extraClasses: PropTypes.string
}

export default CircularIconWrapper;