import Utilities from 'Utilities/global';
import {licensing} from "../../common/store/licensing";
import { vesselUtils, reportsStore } from 'common/store/storeUtils';

const averageRpmPayload = {
    metrics: [
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {metricName: "rpm", aggregation: "AVG"}
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {metricName: "me_1_rpm_avg", aggregation: "AVG"}
            ]
        }
    ]
};

const averageRpm = (id, response, updateState, extraChartConfigs, widget) => {
    const data = [{
        values: [{}, {}]
    }];

    let noData = false;

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    if (response.series.length > 0) response.series[0].values.forEach(value => {
        if (value.metricName === "rpm" && value.metricCategory === "MAIN_ENGINE") data[0].values[0] = value;
        if (value.metricName === "me_1_rpm_avg" && value.metricCategory !== "MAIN_ENGINE") data[0].values[1] = value;
    });

    if ( 
        Utilities.hasNotValue(data[0]?.values[0]?.value) 
        && Utilities.hasNotValue(data[0]?.values[1]?.value)
    ) noData = true;
    else noData = false;

    let updateStateData = {};
    if (!noData) {
        let firstPart = [
            {
                value: data[0].values[0]?.value ? Utilities.renderNumber(Utilities.roundToDigits(data[0].values[0].value, 0)) + " " + (data[0].values[0].unit || "") : "-",
                color: isLight ? "ais" : "sensorsData"
            }
        ];

        const secondPart = reportsStore().isTelegramConfigured ? [
            {
                value: data[0].values[1]?.value ? Utilities.renderNumber(Utilities.roundToDigits(data[0].values[1].value, 0)) + " " + (data[0].values[1].unit || "")  : "-",
                color: "reportsData"
            }
        ] : null;

        if (isLight && !data[0].values[0].value) firstPart = null
    
        updateStateData = {
            firstPart,
            secondPart,
            firstDevider: firstPart && secondPart ? true : false,
        };
    }

    updateState(id, updateStateData, widget?.id);
}


export {averageRpmPayload, averageRpm};
