import React from 'react';

const PowerChartFooter = () => {
    return(
        <div className="full-width widget-footer-legend powerChartFooter small-label flex-centered-start marg-l-15">
            Fuel calorific value for Shop Test - ISO: 42.68 MJ/kg HFO: 40.17 MJ/kg and 41.00 MJ/kg
        </div>
    )
}

export default PowerChartFooter;