import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {ReactComponent as ArrowDropDownIcon} from 'assets/img/app/global-imgs/small-arrow.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import classnames from 'classnames';

export default function SplitButton(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const getSplittedButtonClasses = disabledAllOptions => classnames('splited-button', {'disabledSplittedButton': disabledAllOptions});

  const handleMenuItemClick = (event, index, option) => {
    setSelectedIndex(index);
    setOpen(false);
    props.onOptionClickListener(option);
  };

  const handleClick = () => {
    handleMenuItemClick(null, selectedIndex, props.dropdownOptions[selectedIndex]);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  useEffect(() => {
    if(!props.dropdownOptions.length) return;
    setSelectedIndex(props.dropdownOptions.findIndex(option => option?.name === props.defaultValue?.name));
  }, [props.dropdownOptions, props.defaultValue]);


  return (
    <div className='splittedButtonMenu'>
        <ButtonGroup 
          className={getSplittedButtonClasses(props.disabledAllOptions)} 
          variant="contained" 
          color="primary" 
          ref={anchorRef} 
          aria-label="split button" 
          disabled={props.disabledAllOptions}
        >
          <Button onClick={handleClick}>{props.dropdownOptions[selectedIndex]?.name}</Button>
          <Button
            color="primary"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon className="svg-path-main-text-color rotate-180" />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'right bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {props.dropdownOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        // disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index, option)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </div>
  );
}