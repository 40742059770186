import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import FuelConsumption from 'components/alerts/alertsDashboards/fuelConsumption';
import FuelConsumptionLight from 'components/alerts/alertsDashboards/fuelConsumptionLigt';
import SevereWindAlert from 'components/alerts/alertsDashboards/severeWind';
import FilteringFleetVesselsTopbar from 'components/fleetVesselsTopBar/filteringFleetVesselsTopBar';
import AlertView from 'components/alerts/alertView';
import { ReactComponent as NoAlerts } from 'assets/img/app/alerts-imgs/noAlerts.svg';
import Skeleton from '@material-ui/lab/Skeleton';
import {userStore, vesselGroupStore, vesselStore, alertsStore, vesselGroupUtils, alertsUtils} from 'common/store/storeUtils';
import {useDispatch, useSelector} from 'react-redux';
import service from 'common/js/service';
import GlobalUtilities from 'Utilities/global';

let isMounted = false;

const Alerts = () => {
    const [alerts, setAlerts] = useState([]);

    // store vars
    const dispatch = useDispatch();
    const vesselGroupStoreJSX =  useSelector(state => state.vesselGroup);
    const alertsStoreJSX = useSelector(state => state.alerts);

    // set the initial filtersGroup value to be the 'All fleets' object
    useEffect(() => {
        dispatch({ type: 'alerts/setFiltersGroup', payload: vesselGroupStoreJSX.allVesselGroupsObj });
    // eslint-disable-next-line
    }, []);

    const updateActiveAlert = alert => {
        const newAlerts = alerts.map(a => {
            if (a.active && a.id !== alert.id) a.active = false;
            if (a.id === alert.id) {
                a.active = true;
            }
            return a;
        });

        setAlerts(newAlerts)
    }

    const loadMore = () => {
        const date = moment(alerts[alerts.length - 1].startingDate).subtract(1, 'seconds').valueOf();

        let vesselIds = [];
        if( alertsStore().filtersVessel === -1 && alertsStore().filtersGroup.id !== -1 ) 
            vesselIds = vesselStore().allVessels.filter(vessel => vessel.vesselGroupId === alertsStore().filtersGroup.id).map(vessel => vessel.vesselId);
        if( alertsStore().filtersVessel !== -1 ) vesselIds = [alertsStore().filtersVessel];

        alertsUtils.getLatestsAlerts(date, vesselIds).then(result => {
            setAlerts(alerts.concat(result.data.alerts))
        });
    }

    const alertsCalls = (activeAlertTemp) => {
        if(!activeAlertTemp.read) {
            service.updateNotification({ 
                "alertId" : activeAlertTemp.id,
                "userId" : userStore().user.uuid
            });
            activeAlertTemp.read = true;
            dispatch({ type: 'alerts/setAlertUpdate', payload: activeAlertTemp });
            dispatch({ type: 'alerts/setUninspectedAlertsNum', payload: alertsStore().uninspectedAlertsNum - 1 });
        }
        
        dispatch({ 
            type: 'alerts/setAlertInspection', 
            payload: {
                status: true, 
                activeAlert: activeAlertTemp, 
                from: moment(activeAlertTemp.startingDate).utc().subtract(6, 'h').valueOf(), 
                to: moment(activeAlertTemp.endingDate).utc().add(6, 'h').valueOf()
            }
        });
        
        const vessel = vesselStore().allVessels.filter(vessel => vessel.vesselId === activeAlertTemp.vesselId)[0];
        vesselGroupUtils.setSelectedAndChange(dispatch, vesselGroupStore().allVesselGroups.filter(fleet => fleet.id === vessel.vesselGroupId)[0].id);
        dispatch({ type: 'vessel/setSelectedVessel', payload: activeAlertTemp.vesselId });
    }

    //first param is if this function is called by the onMount listener of page
    //and the second params are the vessels ids that the alert request needs
    //if ids is empty array then the request returns the alerts for all vessels
    const getAlertsInit = (init, ids) => {
        //we keep this log until the real request added
        alertsUtils.getLatestsAlerts(null, ids).then(result => {
            if( init ) isMounted = true;
            if (!result.data) return;

            setAlerts(result.data.alerts);

            const activeAlertTemp = alertsStore().activeAlert?.id ? alertsStore().activeAlert : result.data.alerts[0];
            if (!activeAlertTemp?.id) return;
            dispatch({ type: 'alerts/setActiveAlert', payload: activeAlertTemp });
        });
    }

    //useEffect here------------------------------------------

    useEffect(() => {
        const controller = new AbortController();
        GlobalUtilities.signal = controller.signal;
        
        getAlertsInit(true, []);

        return () => {
            controller.abort();
            isMounted = false;
            GlobalUtilities.signal = null;
            dispatch({ type: 'vessel/setSelectedVessel', payload: vesselStore().allVesselsObj.vesselId });
            alertsUtils.init(dispatch);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (alertsStore().activeAlert?.id) alertsCalls({...alertsStore().activeAlert});
    // eslint-disable-next-line
    },[alertsStoreJSX.activeAlert]);

    useEffect(() => {
        if (alertsStore().alertUpdate) {
            const index = alerts.findIndex(stateAlert => stateAlert.id === alertsStore().alertUpdate.id);
            const tempAlerts = [...alerts];
            tempAlerts[index] = alertsStore().alertUpdate;
            setAlerts(tempAlerts);
        }
    // eslint-disable-next-line
    },[alertsStoreJSX.alertUpdate]);

    useEffect(() => {
        if( !isMounted ) return;
        //here we are watching the filters changes
        //when filtersVessel coming -1 thats mean tha fleet changed only
        //and we need to call alerts request with fleet's (filtersGroup) vessels' ids
        //and if the filterVessel isn't -1 we need to call alerts request with filterVessel id only
        dispatch({ type: 'alerts/setActiveAlert', payload: {} });
        if( alertsStoreJSX.filtersVessel === -1 && alertsStoreJSX.filtersGroup.id === -1 ) getAlertsInit(false, []);
        if( alertsStoreJSX.filtersVessel === -1 && alertsStoreJSX.filtersGroup.id !== -1 ) 
            getAlertsInit(false, vesselStore().allVessels.filter(vessel => vessel.vesselGroupId === alertsStoreJSX.filtersGroup.id).map(vessel => vessel.vesselId));
        if( alertsStoreJSX.filtersVessel !== -1 ) getAlertsInit(false, [alertsStoreJSX.filtersVessel]);
    // eslint-disable-next-line
    }, [alertsStoreJSX.filtersVessel, alertsStoreJSX.filtersGroup]);

    //-------------------------------------------------------------

    return (
        <>
            <FilteringFleetVesselsTopbar />

            <div className="alerts-page main-content-padding">
                { /* <AlertToast {...alerts[0]} /> */ }

                <div className="section-title">
                    ALERTS
                    {/* <Tooltip title="SETTINGS" component={<SettingsIcon className="svg-path pointer"
                    onClick={() => props.history.push("/settings")}/>}/> */}
                </div>

                { /* <FiltersPanel /> */ }

                {
                    /* <div className="row marg-t-35 marg-b-15">
                        <div className="col-xl-4">
                            <div className="d-flex align-content-center justify-content-between alerts-page__read-unread-actions">
                                <div className="small-label-light">Show unread only</div>
                                <UnreadIcon className="svg-path pointer" />
                            </div>
                        </div>
                    </div> */
                }

                { 
                    alerts && alerts.length > 0
                    ?   <div className="row">
                            <div className="col-xl-4 marg-b-15"></div>
                            <div className="col-xl-4 alerts-page__alerts-list">
                                <div className="alerts-page__alerts-list__wrapper">
                                    {!alertsStoreJSX.activeAlert.id
                                        ? <Skeleton variant="rect" animation="wave"
                                                    width={"100%"}
                                                    height={150}
                                        />
                                        : null
                                    }
                                    {alerts.map(alert => (
                                        <div key={alert.id}
                                             className={`${alertsStoreJSX.activeAlert.id ? 'd-flex' : 'd-none'} alerts-page__alert-panel align-items-center label pointer`}
                                             onClick={() => updateActiveAlert(alert)}>
                                            <AlertView alert={alert} activeAlert={alertsStoreJSX.activeAlert}
                                                       alerts={alerts} />
                                        </div>
                                    ))}
                                    {alertsStoreJSX.activeAlert.id &&
                                        <button className="main-cta full-width" onClick={loadMore}>
                                            <div className="main-cta__text">Load More</div>
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="col-xl-8 alerts-page__body">
                                { !alertsStoreJSX.activeAlert.id && <div className="widget-panel"><Skeleton variant="rect" animation="wave" width={'100%'} height={450} /></div> }
                                { alertsStoreJSX.activeAlert && (alertsStoreJSX.activeAlert.alertType === 0) && <FuelConsumption activeAlert={alertsStoreJSX.activeAlert} /> }
                                { alertsStoreJSX.activeAlert && (alertsStoreJSX.activeAlert.alertType === 1) && <FuelConsumptionLight activeAlert={alertsStoreJSX.activeAlert} /> }
                                { alertsStoreJSX.activeAlert && (alertsStoreJSX.activeAlert.alertType === 2 || alertsStoreJSX.activeAlert.alertType === 3) && <SevereWindAlert activeAlert={alertsStoreJSX.activeAlert} /> }
                            </div>
                        </div>
                    :   <div className="noAlerts d-flex align-items-center justify-content-center flex-column">
                            <NoAlerts className="svg-path-no-data"/>
                            <div className="section-title">No available alerts!</div>
                        </div>
                }
            </div>
        </>
    )
}

export default withRouter(Alerts);