import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";
import classnames from 'classnames';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';


const Table = (props) => {
    const { items, columns, components, frameworkComponents, isLegReportsComponent, wrapperStyle, extraTableParams, autoSize } = props;

    const onGridReady = params => {
        autoSize ? params.columnApi.autoSizeAllColumns()
        : params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.setHeaderHeight(50);
    }

    const onGridSizeChanged = params => {
        autoSize ? params.columnApi.autoSizeAllColumns()
        : params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
    }

    const getWrapperClasses = (isLegReportsComponent) => {
        return classnames({'generalKPISWigdet': isLegReportsComponent});
    }

    return (
        <div style={wrapperStyle ? wrapperStyle : {width: '100%', height: '100%'}} className={getWrapperClasses(isLegReportsComponent)}>
            <AgGridReact 
                className="ag-theme-balham"
                sortable={true}
                minColWidth={autoSize ? 200 : '100%'}
                suppressDragLeaveHidesColumns={true}
                rowData={items}
                onGridReady={onGridReady}
                defaultColDef={{ autoHeight: true, sortable: true }}
                modules={AllCommunityModules}
                columnDefs={columns}
                components={components}
                onGridSizeChanged={onGridSizeChanged}
                domLayout='autoHeight'
                context={{ componentParent: this }}
                frameworkComponents={frameworkComponents} 
                isLegReportsComponent={isLegReportsComponent}
                {...extraTableParams}
            >
            </AgGridReact>
        </div>
    );
}

export default Table;