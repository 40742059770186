import moment from "moment";
import telegramUtils from 'common/js/events/telegramUtils';
import { ReactComponent as SmallThunder } from 'assets/img/app/events-imgs/small-thunder.svg';
import { baseFormUtils } from 'common/store/storeUtils';
import Group from "components/core/Forms/group";

let minutesAtSea, hoursAtSea;

const atelegramSchema = () => {
    return [
        {
            telegramDate: {
                type: 'datePicker',
                label: 'Arrival Date & Time (UTC)',
                group: 'General',
                value: '',
                viewports: { sm: 12, md: 12, lg: 6, xl: 6 },
                paddingViewports: { sm: 12, md: 12, lg: 6, xl: 6 },
                validation: {
                    withTime: true,
                    required: true
                },
                onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                    if (value === null) {
                        setFieldValue("telegramDate", { value: null });
                    }
                }
            },
            portName: {
                label: 'Arrival Port',
                group: 'General',
                value: '',
                type: 'autocomplete',
                portsAutocomplete: true,
                validation: {
                    required: true
                },
                viewports: { sm: 12, md: 12, lg: 12, xl: 12 },
                paddingViewports: { sm: 12, md: 12, lg: 6, xl: 6 },
                onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                    if (value !== null) {
                        setFieldValue("portName", value.port);
                    } else {
                        setFieldValue("portName", '');
                    }
                }
            },
            hoursAtSea: {
                viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 3 },
                type: 'positiveNumber',
                label: 'Hours',
                group: 'Steaming Time',
                value: '',
                unit: 'hrs',
                validation: {
                    steamingTimeValidation: ['hoursAtSea', 'minutesAtSea']
                },
                onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                    hoursAtSea = telegramUtils.removeWhiteSpaceAndCharacters(value);
                    handleChange('hoursAtSea', value, setFieldValue, setFieldTouched);
                }
            },
            minutesAtSea: {
                viewports: { xs: 12, sm: 12, md: 6, lg: 6, xl: 3 },
                type: 'positiveNumber',
                label: 'Minutes',
                group: 'Steaming Time',
                value: '',
                unit: 'min',
                validation: {
                    steamingTimeValidation: ['hoursAtSea', 'minutesAtSea']
                },
                onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                    minutesAtSea = telegramUtils.removeWhiteSpaceAndCharacters(value);
                    handleChange('minutesAtSea', value, setFieldValue, setFieldTouched);
                }
            },
            me1RpmAvg: {
                viewports: { xs: 12, sm: 12, md: 12, lg: 12, xl: 6 },
                type: 'positiveNumber',
                label: 'Average Main Engine RPM',
                group: 'Steaming Time',
                value: '',
                unit: 'rpm',
                validation: { required: true },
                onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                    handleChange('me1RpmAvg', value, setFieldValue, setFieldTouched);
                }
            },
            errors: {
                group: 'Steaming Time',
                type: 'custom',
                value: '',
                customRender: ({ arrayHelpers, values, error, isTouched, errors, touched, setFieldValue, setFieldTouched, name }) => {
                    return steamingTimeErrors(touched, errors);
                }
            },
            geDetails: {
                type: 'table',
                group: 'Generator Engine Details',
                label: {
                    title: 'Generator Engine Details',
                    index: 'Gen. Engine',
                    labelImage: SmallThunder
                },
                tableHeader: ["", "* Average Load ", "* Hours of Usage"],
                viewports: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                structure: {
                    ...telegramUtils.generatorStructure()
                }
            }
        },
        {
            generalValidation: {
                type: 'text',
                group: 'GroupedValidation',
                value: '',
                validation: {
                    requiredEvents: true
                },
                cssClasses: {
                    wrapper: 'generalValidationWrapper',
                    label: 'form-text',
                    element: 'generalValidation',
                    error: 'form-error'
                },
                viewports: { sm: 12, md: 12, lg: 12, xl: 12 },
                paddingViewports: { sm: 12, md: 12, lg: 6, xl: 6 }
            },
            telegramContent: {
                ...telegramUtils.telegramContentStructure()
            }


        }
    ];
};

const handleChange = (type, value, setFieldValue, setFieldTouched) => {
    setFieldValue(type, telegramUtils.removeWhiteSpaceAndCharacters(value));
}

const steamingTimeErrors = (touched, errors) => {
    if ((touched.hoursAtSea || touched.minutesAtSea) && !hoursAtSea && !minutesAtSea) {
        return errors.hoursAtSea || errors.minutesAtSea;
    } else {
        return '';
    }
}

const atelegramValues = (props, editMode, dateClicked) => {
    telegramUtils.updateTelegramValues(props);
    const passDownValues = telegramUtils.passDownValues(props);

    return [
        {
            geDetails: telegramUtils.generatorArray(props),
            telegramDate: editMode ? moment.utc(props.dateTo) : moment(dateClicked).set({ hour: moment().hour(), minute: moment().minutes(), second: moment().seconds() }).utc(),
            portName: props.portName || '',
            hoursAtSea: props.hoursAtSea || '',
            minutesAtSea: props.minutesAtSea || '',
            me1RpmAvg: props.me1RpmAvg || ''
        }, {
            telegramContent: editMode
                ? passDownValues.map((item) => baseFormUtils.createValuesFromSchema(item))
                : [],
            generalValidation: ''
        }
    ]
};

const atelegramGroups = [
    {
        'General': {
            ...Group,
            viewports: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            paddingViewports: "none",
            renderLabel: false,
            className: ''
        },
        'Steaming Time': {
            ...Group,
            viewports: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            paddingViewports: "none",
            renderLabel: true,
            hasAsterisk: true,
            groupLabel: 'headline2 marg-t-20',
            className: 'steamingTimeWrapper',
            groupWrapper: ''
        },
        'Error': {
            ...Group,
            viewports: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 },
            paddingViewports: "none",
            renderLabel: false,
            className: 'steamingTimeError',
            groupWrapper: ''
        },
        'Generator Engine Details': {
            ...Group,
            viewports: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            paddingViewports: "none",
            renderLabel: false,
            className: '',
            groupWrapper: ''
        }
    },
    {
        'GroupedValidation': {
            ...Group,
            viewports: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
            paddingViewports: "none",
            renderLabel: false,
            className: 'groupedValidation'
        }
    },
    {}
];

export { atelegramSchema, atelegramValues, atelegramGroups };