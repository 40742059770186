import hullStorage from "components/monitoringCategories/localStorages/hullStorage";
import moment from "moment";
import Utilities from "Utilities/global";
import { eventsUtils } from 'common/store/storeUtils';

let cleaningEventsArr = [], eventsTypesMap = eventsUtils.cleaningEventTypes;

export const getLastCleaningEvent = (result, isLight) => {
    let tempObj = { name: "-", date: "-" };
    let event = result;
    if (!result?.id || (isLight && eventsTypesMap[result?.maintenanceType] !== 'DRYDOCK')) return tempObj; // light vessels only show drydock cleaning events

    for(let j = 0; j < event.operations.length; j++) {
        let op = event.operations[j];
        if(op.location === 'HULL') {
            tempObj = {
                name: (eventsTypesMap[event.maintenanceType] === 'SEA' ? 'UNDER WATER' : eventsTypesMap[event.maintenanceType] || '-'),
                date:  moment(event.dateTo).isValid() ? moment(event.dateTo).utc().format("DD/MM/YYYY") : '-'
            }
            break;
        }
    };

    return tempObj;
};

export const setPowerPenaltyData = result => {
    let penalty = (result && result?.value) ? Utilities.roundToDigits(result.value, 1) : null;
    let powerPenaltyInnerText = (penalty || "-") + "%";
    if ( penalty < 0 ) {
        penalty = 0.1;
        powerPenaltyInnerText = "-";
    }
    if ( penalty === 0 ) {
        penalty = 0.1;
        powerPenaltyInnerText = "0%";
    }

    let powerPenaltyLastDate = "-";
    if ( result?.datetime ) powerPenaltyLastDate = moment(result.datetime).utc().format("DD/MM HH:mm");

    return {
        todayPowerPenalty: penalty,
        powerPenaltyInnerText,
        powerPenaltyLastDate
    }
}

export const getFoulingPenaltyTitle = (foulingPenalty) => {
    return `FOULING PENALTY AT ${setPowerPenaltyData(foulingPenalty?.data)?.powerPenaltyLastDate}`;
}

export const getLegFoulingPenaltyTitle = (foulingPenalty) => {
    return `LEG FOULING PENALTY AT ${setPowerPenaltyData(foulingPenalty?.data)?.powerPenaltyLastDate}`;
}

export const cleaningEventsUpdate = data => {
    cleaningEventsArr.push(data);
}

export const updateFoulingPenalty = (id, data, updateState, extraChartConfigs, widget) => {
    hullStorage.setFoulingPenalty(data);
    const tempCleaningEvent = {...cleaningEventsArr[0]};
    if(cleaningEventsArr?.length > 0) cleaningEventsArr.splice(0, 1);
    updateState(id, data ? {...data, cleaningEvents: tempCleaningEvent} : {}, widget?.id);
}
