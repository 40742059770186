import meStorage from 'components/monitoringCategories/localStorages/meStorage';
import ThemeColors from "sass/_themeColorsForJs.scss";
import chartOptions from "components/charts/lineChart/react-lineChart";
import chartUtils from 'Utilities/charts';
import { vesselUtils } from 'common/store/storeUtils';
import { csvXlsxExportForCurvesAndScatterWithDatetime, downloadChartInCSVform, downloadChartInXLSXform } from "Utilities/highchartsUtils";


let powerRpmCurvesData = {}, 
    powerRpmCurvesDataObj = {}, 
    scatterData = {};

const chartSeries = [  
    {name: 'Actual Data', data: [], tooltipTitle: 'Power', yAxis: 'power-axis', type: 'scatter', 
        color: ThemeColors['main_blue'], 
        customTooltipBorderColor: ThemeColors['main_blue'],
        marker: {enabled: true, symbol: 'circle'},
        tooltip: {valueSuffix: ' kW', valueDecimals: 0}
    },
    {name: 'Overload Limit', data: [], tooltipTitle: 'Overload Limit', yAxis: 'power-axis', color: ThemeColors['first_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'RPM: ', tooltip: {valueSuffix: ' kW', valueDecimals: 0}},
    {name: 'Torque Limit', data: [], tooltipTitle: 'Torque Limit', yAxis: 'power-axis', color: ThemeColors['second_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'RPM: ', tooltip: {valueSuffix: ' kW', valueDecimals: 0}},
    {name: 'Shop Test', data: [], tooltipTitle: 'Shop Test', yAxis: 'power-axis', color: ThemeColors['third_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'RPM: ', tooltip: {valueSuffix: ' kW', valueDecimals: 0}},
    {name: 'Nominal', data: [], tooltipTitle: 'Nominal', yAxis: 'power-axis', color: ThemeColors['forth_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'RPM: ', tooltip: {valueSuffix: ' kW', valueDecimals: 0}},
    {name: 'Light Propeller Curve', data: [], tooltipTitle: 'Light Propeller Curve', yAxis: 'power-axis', color: ThemeColors['fifth_reference_line'],
        titleSuffix:'Kn', tootltipXTitle: 'RPM: ', tooltip: {valueSuffix: ' kW', valueDecimals: 0}}
];

const yAxisData = [{id: 'power-axis', title: {text: "Power (kW)"}, opposite: false}];

const columnHeaderMapper = {
    'Actual Data': 'Actual Data (kW)',
    'Overload Limit': 'Overload Limit (kW)',
    'Torque Limit': 'Torque Limit (kW)',
    'Shop Test': 'Shop Test (kW)',
    'Nominal': 'Nominal (kW)',
    'Light Propeller Curve': 'Light Propeller Curve (kW)'
}

// the data that we set here are for the power-rpm curves (called in the prerequisite)
const setPowerRpmCurvesData = (data, widget) => {    
    if (widget?.inDashboard) powerRpmCurvesDataObj[widget.id] = data?.meCurves?.DEFAULT?.curves ? data.meCurves.DEFAULT.curves : {};
    else powerRpmCurvesData = data?.meCurves?.DEFAULT?.curves ? data.meCurves.DEFAULT.curves : {};

}

const fillSeriesWithData = (series, curvesData, scatterData) => {
        // fill series and update chart's legend for scatter data
        series[0].data = scatterData?.DEFAULT?.SCATTER_PLOT || [];
        series[0].showInLegend = series[0]?.data?.length;

        // fill series and update chart's legend for curves data 
        series[1].data = curvesData?.OVERLOAD_LIMIT || [];
        series[1].showInLegend = series[1]?.data?.length;

        series[2].data = curvesData?.TORQUE_LIMIT || [];
        series[2].showInLegend = series[2]?.data?.length;

        series[3].data = curvesData?.SHOP_TEST || [];
        series[3].showInLegend = series[3]?.data?.length;

        series[4].data = curvesData?.NOMINAL || [];
        series[4].showInLegend = series[4]?.data?.length;

        series[5].data = curvesData?.LIGHT_PROPELLER_CURVE || [];
        series[5].showInLegend = series[5]?.data?.length;
}

// the data that we receive here are for the scatter plots
const updatePowerRpmChart = (id, data, updateState, extraChartConfigs, widget) => {

    if(meStorage.powerRpmScatterPlotsData) scatterData = meStorage.powerRpmScatterPlotsData;
    else scatterData = data;

    if(meStorage.powerRpmData) powerRpmCurvesData = meStorage.powerRpmData;

    if (widget?.inDashboard) {
        powerRpmCurvesData = powerRpmCurvesDataObj[widget?.id];
        scatterData = data;
    }

    // handle empty data case, return empty object so that the noData component appears (show the NoData component when we do not have any scatter data)
    if(!scatterData) return updateState(id, {}, widget?.id);

    const chartData = Object.assign({}, chartOptions(':.2f', '%'));

    // set the series data of the chart
    chartData.yAxis = yAxisData;
    chartData.series = [...chartSeries];
    fillSeriesWithData(chartData.series, powerRpmCurvesData, scatterData);
    
    // set some chart options that are common in curves-scatter charts
    chartUtils.curvesAndScatterDataChart.setCommonChartOptions(chartData);

    // set extra options that are specific for this chart
    chartData.chart.height = 360;
    chartData.chart.marginTop = 95;
    chartData.legend.y = 15;
    chartData.xAxis.title = { text: "RPM", y: 10 };
    chartData.exporting.filename = `${vesselUtils.getVesselName(widget.vesselIds[0])}-MAIN ENGINE POWER VS RPM`;
    chartData.exporting.csv.columnHeaderFormatter = (item) => chartUtils.curvesAndScatterDataChart.setExportingColumns(item, columnHeaderMapper, 'RPM');


    chartData.exporting.buttons.contextButton= {
        menuItems: [
            "download",
            "customSeparator",
            {
                text: "XLSX",
                onclick: function () {
                    const dto = csvXlsxExportForCurvesAndScatterWithDatetime(this,'rpm', 'kW')
                    downloadChartInXLSXform({xlsxRows: dto.rows, sheetsName: dto.sheetsName.trim().length > 30 ? 'MAIN ENGINE POWER VS RPM' : dto.sheetsName, name: dto.name});
                }
            },
            {
                text: "CSV",
                onclick: function () {
                    const dto = csvXlsxExportForCurvesAndScatterWithDatetime(this,'rpm', 'kW')
                    const csvContent = dto.rows.map(row => row.join(',')).join('\n');
                    downloadChartInCSVform({csvContent, name: dto.name});
                }
            },
        ]
    }
    
    // a common formatter for creating the tooltip's UI for both scatter data and curves data
    const tooltipExtraOptions = {
        titleX: 'RPM: ',
        valueXSuffix: '',
        width: '130px'
    };
    chartData.tooltip.formatter = chartData.tooltip.formatter = function() {return chartUtils.curvesAndScatterDataChart.tooltipFormatter.call(this, tooltipExtraOptions)};
    
    if (!widget?.inDashboard) {
        meStorage.setPowerRpmData(powerRpmCurvesData);
        meStorage.setPowerRpmScatterPlotsData(scatterData);
    }

    powerRpmCurvesData = {};
    scatterData = {};

    return updateState(id, chartData, widget?.id);
}

export { updatePowerRpmChart, setPowerRpmCurvesData };
