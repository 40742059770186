import React, { useState } from 'react';
import MenuOptions from './menuOptions.jsx';

const DashboardMenuOptions = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const menuItemOptions = props.type !== 'DEEPSEA' ? [
        {
            label: 'Rename', action: () => props.handleTemplateRename(props.id, props.title)
        },
        {
            label: 'Duplicate', action: () => props.handleTemplateDuplicate(props.title, props.id)
        },
        {
            label: 'Delete', action: () => props.handleTemplateDelete(props.id)
        }
    ] : [
        {
            label: 'Duplicate', action: () => props.handleTemplateDuplicate(props.title, props.id)
        }
    ];

    const optionsHandleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const optionsHandleClose = (executeAction) => {
        setAnchorEl(null);
        if(executeAction) executeAction();
    };

    return(
        <MenuOptions menuItemOptions={menuItemOptions} anchorEl={anchorEl} optionsHandleClick={optionsHandleClick} optionsHandleClose={optionsHandleClose} menuClassName={props.menuClassName} iconClassName="dashboardOptionsIcon" iconWrapperClassName="dashboard-options-action-button flex-centered" />
    )
}

export default DashboardMenuOptions;
