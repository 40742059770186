import React from 'react';
import CircularIconWrapper from 'components/circularIconWrapper/circularIconWrapper';
import LabelWithIconHorizontal from 'components/labels/iconWithTextLabel/labelWithIconHorizontal';
import {ReactComponent as VesselIcon} from 'assets/img/app/monitoring-imgs/ship-icon.svg';


const VesselNameHorizontal = ({data: {vesselName: {vesselName, color}}}) => {

    return (
        <LabelWithIconHorizontal 
            data={{
                text: vesselName || '',
                icon: <CircularIconWrapper 
                    icon={<VesselIcon/>}
                    color={color || 'var(--main_blue'}
                />
            }}
        />
    );
}
 
export default VesselNameHorizontal;