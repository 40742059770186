import React from 'react';
import classnames from 'classnames';

const InfoDeviderBox = (props) => {
    const {firstPart, secondPart, thirdPart, firstDevider, secondDevider } = props.data;
    const {style} = props;

    const moreThanOneValue = (values, index) => classnames({ "pad-b-30": values.length > 1 && index === 0});

    return (
        <div className="flex-centered" style={style}>
            { (firstPart) && <div className="flex-centered-col">
                { firstPart.map((group, index) => (
                    <div key={index} className={`info-devider-box__group flex-centered-col ${moreThanOneValue(firstPart, index)}`}>
                        <div className="info-devider-box__info-label">{group.label}</div>
                        <div className={`info-devider-box__value info-value ${group.color}`}>{group.value}</div>
                    </div>
                ))}
            </div>}

            {(firstDevider) && <div className="info-devider-box__devider" /> }
            
            { (secondPart) && <div className="flex-centered-col">
                { secondPart.map((group, index) => (
                    <div key={index} className={`info-devider-box__group flex-centered-col ${moreThanOneValue(secondPart, index)}`}>
                        <div className="info-devider-box__info-label">{group.label}</div>
                        <div className={`info-devider-box__value info-value ${group.color}`}>{group.value}</div>
                    </div>
                ))}
            </div> }
            
            {(secondDevider) && <div className="info-devider-box__devider" /> }
            
            { (thirdPart) && <div className="flex-centered-col">
                { thirdPart.map((group,index) => (
                    <div key={index} className={`info-devider-box__group flex-centered-col ${moreThanOneValue(thirdPart, index)}`}>
                        <div className="info-devider-box__info-label">{group.label}</div>
                        <div className={`info-devider-box__value info-value ${group.color}`}>{group.value}</div>
                    </div>
                ))}
            </div>}
        </div>
    );
}

export default InfoDeviderBox;
