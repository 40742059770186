import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import _ from 'lodash';
import SelectField from "../SelectField";
import DatePicker from "../DatePicker";
import AutocompleteField from "../AutocompleteField";
import PortsAutocompleteField from "../../portsAutocomplete/PortsAutocompleteField";
import classnames from "classnames";
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import Utilities from 'Utilities/global';
import {
    FormElementStore,
    PasswordField,
    AlphaNumericField,
    SegmentButtons,
    TogglerButton,
    CheckBox,
    IconSegmentButtons
} from './';
import { ReactComponent as AddIcon } from 'assets/img/app/global-imgs/add-cross.svg';
import { ReactComponent as TrashIcon } from 'assets/img/app/global-imgs/trash.svg';
import { ReactComponent as FuelOilIcon } from 'assets/img/app/timeline-imgs/fuelOil.svg';
import { ReactComponent as PassRule } from 'assets/img/app/settings-imgs/passRule.svg';
import { ReactComponent as FailRule } from 'assets/img/app/settings-imgs/failRule.svg';
import { TextField } from '@material-ui/core';
import GridUtils from 'Utilities/gridUtils';
import { baseFormUtils } from 'common/store/storeUtils';

let init = false;

const FormElement = (props) => {
    const [rerenderForm, setRerenderForm] = useState(false);
    const [element, setElement] = useState('');
    const [extraClasses, setExtraClasses] = useState(true);

    // the store object contains all the necessary fields and functions for this specific formElement
    // it is initialized with a new instance of the imported FormElementStore object
    const store = FormElementStore.initFormElementStore(props, Object.create(FormElementStore));

    const initialOptions = Object.assign([], props.options);

    useEffect(() => {
        // execute the above code only when component updates (not when it first mounts)
        if(!init || !store) return;

        // handling to do
        if(!_.isEqual(store.formikHelpers, props.formikHelpers)) {
            store.formikHelpers = props.formikHelpers;
            setRerenderForm(true);
        }

        store.hidden = props.hidden;
        store.disabled = props.disabled;

    // eslint-disable-next-line
    }, [props.formikHelpers, props.hidden, props.disabled]);

    useEffect(() => {
        init = true;
    }, []);

    useEffect(() => {
        if(rerenderForm) setRerenderForm(false);
    }, [rerenderForm]);

    useEffect(() => {
        const fullPathKey = FormElementStore.generateName(store.initName);

        setExtraClasses(true);

        if (!(store.hidden.hasOwnProperty(fullPathKey) && store.hidden[fullPathKey])) {
            switch (props.type) {
                case 'invisible':
                    setElement('');
                    break;
                case 'custom':
                    setElement(props.customRender({
                        arrayHelpers: props.arrayHelpers,
                        value: store.getValue(store),
                        error: store.getError(store),
                        isTouched: store.isTouched(store),
                        errors: props.formikHelpers.errors,
                        touched: props.formikHelpers.touched,
                        setSubmitting: props.formikHelpers.setSubmitting,
                        setFieldValue: props.formikHelpers.setFieldValue,
                        setFieldTouched: props.formikHelpers.setFieldTouched,
                        name: props.name
                    }));
                    break;
                case 'array':
                    setElement(renderArray());
                    setExtraClasses(false);
                    break;
                case 'selectionBoxesArray':
                    setElement(renderSelectionBoxesArray());
                    setExtraClasses(false);
                    break;
                case 'table':
                    setElement(renderTable());
                    setExtraClasses(false);
                    break;
                case 'datePicker':
                    setElement(renderDatePicker());
                    break;
                case 'autocomplete':
                    setElement(renderAutocomplete());
                    break;
                case "toggler":
                    setElement(renderToggler());
                    break;
                case "checkbox":
                    setElement(renderDefault({ component: CheckBox }));
                    break;
                case "segmentButtons":
                    setElement(renderSegmentButtons());
                    break;
                case "iconSegmentButtons":
                    setElement(renderSegmentButtons(true));
                    break;
                case "select":
                    setElement(renderSelect());
                    break;
                case "multiSelect":
                    setElement(renderSelect(true));
                    break;
                case 'password':
                    setElement(renderDefault({ component: PasswordField }));
                    break;
                case 'resetPassword':
                    setElement(renderResetPasswordField());
                    break;
                case 'textarea':
                case "number":
                case "positiveNumber":
                case "noNegativeNumber":
                case "integer":
                case "positiveInteger":
                case "noNegativeInteger":
                case "alphaNumeric":
                case "character":
                case "upperCase":
                case "lowerCase":
                case "nonSpace":
                case 'email':
                case 'tel':
                case 'text':
                default:
                    setElement(renderDefault({ component: AlphaNumericField }));
            }
        } else setElement('');        
    // eslint-disable-next-line
    }, [props]);

    const renderLabel = () => {
        if (!props.canRenderLabel) return '';

        return (
            <label className={`${store.classNames(store).label}${store.isDisabled(false, store) ? ' disabled-label' : ''}`}>
                {props.renderAsterisk && props.validation.required && '* '}{props.label}
            </label>
        );
    }

    const renderError = () => {
        if (!props.canRenderError) return '';

        return (
            <div className={store.classNames(store).error}>
                {props.type === 'datePicker' || props.validation.requiredFields ? store.getError(store) : store.isTouched(store) && store.getError(store)}
            </div>
        );
    };

    const renderDefault = (fieldElement) => {
        return <fieldElement.component {...store.defaultInputProps(store)} />;
    }

    const renderArrayElements = (values, index, arrayHelpers) => {
        return Object.keys(values).map((element, i) => {
            const props = store.arrayDefaultProps(arrayHelpers, index, element, store);
            return <FormElement {...props} />;
        });
    }

    const renderTableElements = (values, index, arrayHelpers, extraProps) => {
        return Object.keys(values).map((element, i) => {
            const props = store.arrayDefaultProps(arrayHelpers, index, element, store);
            props.extraProps = extraProps;
            props.renderAsterisk = false;
            return <FormElement {...props} />;
        });
    }

    const renderProgressBar = (values, props, index) => {
        const progress = values[Object.keys(values)[0]] ? values[Object.keys(values)[0]] : 0;
        const LabelImage = props.label.labelImage;

        return (<div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
            <div className={`progressBar flex-centered ${progress > 0 ? 'hasProgress' : ''}`}>
                <LabelImage /><span className="progressBar__innerBar" style={{ width: `${progress}%` }}></span>
                <label>{props.label.index} {index + 1}</label>
            </div></div>)
    }

    const renderTableHeader = () => {
        if (!props.tableHeader) { return ''; }
        const colSpan = 12 / props.tableHeader.length;
        return (props.tableHeader.map((item) => (<div key={`tableHeader-${item}`} className={`col-${colSpan}`}> {item}</div>)))
    }

    const renderSelectionBoxesElements = (values, index, arrayHelpers, props) => {
        return Object.keys(values).map((element, i) => {
            const props = store.arrayDefaultProps(arrayHelpers, index, element, store);
            return <FormElement {...props} />;
        });
    }

    const addElClasses = () => {
        return classnames(
            GridUtils.getHiddenClasses(props.tile ? props.viewports : FormElement.defaultProps.viewports),
            "add-remove-btns marg-t-15 pointer",
            { "tile": props.tile }
        );
    }

    const renderFormChart = (values, arrayHelpers, props) => {
        const getFormChartTitle = (element, options) => {
            let chartTitle;
            options.forEach((option) => {
                Object.keys(element).map(k => (
                    (k.includes(_.capitalize(option.value))) && (chartTitle = option.label)
                ))
            })
            return chartTitle;
        }

        return <div className="formChart__wrapper flex-space-around">
            {values.map((element, i) => (
                <div className="formChart__wrapper__chart flex-end-centered" key={`formchart-${element[i]}-${i}`}>
                    {Object.keys(element).map(k => (
                        <div key={`formchart-${k}`} className="formChart__wrapper__chart__column" style={{ height: parseInt(element[k])/5 + 'px' }}>
                            <span>{(element[k] && element[k] > 0) ? Utilities.renderNumber(element[k]) : '-'} {props.structure[k].unit} </span>
                        </div>
                    ))}
                    <div className="formChart__wrapper__chart__title">
                        {getFormChartTitle(element, props.options)}
                    </div>
                </div>
            ))}
        </div>
    }

    const renderSelectionBoxesArray = () => {
        const borderClass = `full-height${props.withoutBackground ? '' : ' second-level-array'}`;
        let boxOptions = initialOptions, boxStructure = props.structure, childrenArrayHelpers = {}, newBoxStructure = [];
        const formChartOptions = props.formChart && props.formChart;

        const boxErrorClass = (values, index) => {
            let isTouched = false, hasError = false;
            _.keys(values).forEach(key => {
                store.getError(store) && store.getError(store)[index] && store.getError(store)[index][key] && (hasError = true);
                store.isTouched(store) && store.isTouched(store)[index] && store.isTouched(store)[index][key] && (isTouched = true);
            })
            if (hasError && isTouched) return 'boxError';
        }
        const selectionBoxErrorClass = () => {
            let selectionBoxErrorClass = '';
            if (!childrenArrayHelpers.form.values['generalValidation'] && (store.isTouched(store).length === 0 || store.isTouched(store)[0] === null) && store.getValue(store).length === 0) {
                selectionBoxErrorClass = 'selectionBoxError';
            }
            return selectionBoxErrorClass;
        }

        const addAction = (value, index) => {
            newBoxStructure = _.pick(boxStructure, _.keys(boxStructure).filter(type => type.includes(_.capitalize(value))));
            boxOptions[index] = { ...boxOptions[index], position: index + 1, fromAction: true };
            baseFormUtils.addSubArray(newBoxStructure, childrenArrayHelpers, index);
            return props.formikHelpers.isSubmitting && props.formikHelpers.setSubmitting(false);
        };

        const deleteAction = (index, arrayHelpers, value) => {
            let deleteIndex = index;
            boxOptions[index] = { ...boxOptions[index], position: null, fromAction: true };
            store.getValue(store).forEach((row, index) => {
                _.keys(row)[0].includes(_.capitalize(value)) && (deleteIndex = index);
            })
            baseFormUtils.deleteSubArray(arrayHelpers, deleteIndex);
            (arrayHelpers.form.values['telegramContent'].length === 1 && arrayHelpers.form.values['generalValidation']) && (arrayHelpers.form.values['generalValidation'] = '')
            props.formikHelpers.isSubmitting && props.formikHelpers.setSubmitting(false);
        };

        const loadValues = () => {
            boxOptions.forEach((option, index) => {
                store.getValue(store).forEach(valueRow => {
                    if (_.keys(valueRow)[0].includes(_.capitalize(option.value))) {
                        if (!boxOptions[index].fromAction) {
                            boxOptions[index] = { ...boxOptions[index], position: index + 1 }
                            childrenArrayHelpers.form.values['generalValidation'] = index + 1;
                        }
                    }
                })
            })
        }

        return (
            <React.Fragment>
                <FieldArray name={store.name}>
                    {arrayHelpers => {
                        childrenArrayHelpers = arrayHelpers;
                        loadValues();

                        return (
                            <React.Fragment>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="row no-row-marg full-width marg-b-15">
                                        <label className="form-text">{props.label.title}</label>
                                    </div>
                                </div>
                                <div className={`selection-box-wrapper flex-space-between ${GridUtils.getHiddenClasses(props.viewports)}`}>
                                    {boxOptions.map((option, optionIndex) => (
                                        <div className={`selection-box ${selectionBoxErrorClass()} selection-box-position-${optionIndex} marg-r-5`} style={{ order: optionIndex }} key={`${store.name}-${optionIndex}-wrapper`}>
                                            {!option.position ?
                                                <div className={`selection-box__button add-remove-btns flex-centered-col`} onClick={() => addAction(option.value, optionIndex)}>
                                                    <div className="add-cross marg-b-10"> <AddIcon className="svg-g-path" /> </div>
                                                    <div className="flex-space-between">
                                                        <div className="headline4 selection-box__button__label">{option.label}</div>
                                                        <div className="oil-icon marg-l-5"><FuelOilIcon className="svg-g-path" /></div>
                                                    </div>
                                                </div> : store.getValue(store).map((values, valuesIndex) => (
                                                    _.keys(values)[0].includes(_.capitalize(option.value)) && (
                                                        <div className={`${borderClass} ${boxErrorClass(values, valuesIndex)}`} key={`${store.name}-${valuesIndex}-wrapper`}>
                                                            <div className={`selection-box__form__title flex-space-between`}>
                                                                <div className="form-text">{initialOptions[optionIndex].label}</div>
                                                                <div className="trash-icon gray-hover action-button flex-centered" onClick={e => deleteAction(optionIndex, arrayHelpers, option.value)}>
                                                                    <Tooltip title="Delete" component={<TrashIcon className="svg-path action deleteAction" />} />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                {renderSelectionBoxesElements(values, valuesIndex, arrayHelpers, props)}
                                                            </div>
                                                        </div>)
                                                ))}
                                        </div>
                                    ))}
                                </div>

                                {formChartOptions.length > 0 && <div className={`formChart ${GridUtils.getHiddenClasses(props.viewports)}`}>
                                    {store.getValue(store).length > 0 && <React.Fragment>
                                        <div className="formChart__legend flex-centered-end">
                                            {formChartOptions.map((option, index) => (
                                                <div key={`legend-item-${index}`} className="flex-centered-start marg-l-15"><div className="tile" style={{ backgroundColor: option.color }}></div>{option.label}</div>
                                            ))}
                                        </div>
                                        {renderFormChart(store.getValue(store), arrayHelpers, props)}
                                    </React.Fragment>}
                                </div>}
                            </React.Fragment>
                        );
                    }}
                </FieldArray>
            </React.Fragment >
        );
    }

    const renderTable = () => {
        const borderClass = `full-height${props.withoutBackground ? '' : ' second-level-array'}`;
        return (
            <React.Fragment>
                <FieldArray name={store.name}>
                    {arrayHelpers => {
                        return (
                            <React.Fragment>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="full-width marg-t-20">
                                        <label className="form-text">{props.label.title}</label>
                                    </div>
                                </div>
                                <div className="full-width form-table">
                                    {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"> */}
                                        <div className="form-table__header form-text flex-centered">
                                            {renderTableHeader()}
                                        </div>
                                        {store.getValue(store).map((values, index) => (
                                            <div className={GridUtils.getHiddenClasses(props.viewports, 'form-table__row no-padding')} key={`${store.name}-${index}-wrapper`}>
                                                <div className={borderClass}>
                                                    <div className="row">
                                                        {renderProgressBar(values, props, index)}
                                                        {renderTableElements(values, index, arrayHelpers, props)}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    {/* </div> */}
                                </div>
                            </React.Fragment>
                        );
                    }}
                </FieldArray>
            </React.Fragment>
        );
    }

    const renderArray = () => {
        const borderClass = `full-height${props.withoutBackground ? '' : ' second-level-array'}`;
        let childrenArrayHelpers = {};
        const addAction = () => {
            baseFormUtils.addSubArray(props.structure, childrenArrayHelpers, store.getValue(store).length);
            props.formikHelpers.isSubmitting && props.formikHelpers.setSubmitting(false);
        };
        const deleteAction = (index, arrayHelpers) => {
            baseFormUtils.deleteSubArray(arrayHelpers, index);
            props.formikHelpers.isSubmitting && props.formikHelpers.setSubmitting(false);
        };
        return (
            <React.Fragment>
                {/*<div className={`${store.classNames().arrayWrapper}`}>
                    {props.label.title}
                </div>*/}
                <FieldArray name={store.name}>
                    {arrayHelpers => {
                        childrenArrayHelpers = arrayHelpers;
                        return (
                            <div className="row no-row-marg full-width">
                                {store.getValue(store).map((values, index) => (
                                    <div className={GridUtils.getHiddenClasses(props.viewports, 'marg-b-10')} key={`${store.name}-${index}-wrapper`}>
                                        <div className={borderClass}>
                                            <div className={`${props.tile ? (props.label.index === '' ? 'flex-centered-end' : 'flex-space-between') : 'd-flex'}`}>
                                                {props.label.index !== '' && <div className="headline4">{`${props.label.index} ${index + 1}`}</div>}
                                                {(store.getValue(store).length > 1) && <div className={`add-remove-btns pointer${props.tile ? '' : ' marg-l-15'}`}>
                                                    <div className="trash-icon gray-hover" onClick={e => deleteAction(index, arrayHelpers)}>
                                                        <Tooltip title="Delete" component={<TrashIcon className="svg-g-path" />} />
                                                    </div>
                                                </div>}
                                            </div>
                                            <div className="row">
                                                {renderArrayElements(values, index, arrayHelpers)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {(props.maxItems === 0 || props.maxItems > store.getValue(store).length) && <div className={addElClasses()} onClick={addAction}>
                                    <div className="flex-centered-start group">
                                        <div className="cta-icon">
                                            <div className="add-cross marg-r-15"><AddIcon className="svg-g-path" /></div>
                                            <div className="headline4">{props.label.add || 'Add'}</div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        );
                    }}
                </FieldArray>
            </React.Fragment>
        );
    }

    const ResetPasswordRules = (props) => {
        const ruleClasses = passRule => classnames("d-flex align-items-center form-description valid-texts", {'passed': passRule}, {'failed': !passRule});

        props.rules.forEach(rule=> {
            const tmpRexp = new RegExp(rule.regex);
            if (rule.extraRegex) {
                const extraRegexp = new RegExp(rule.extraRegex);
                rule.passRule = tmpRexp.test(props.value) && extraRegexp.test(props.value) && props.value.length;
            } else {
                rule.passRule = tmpRexp.test(props.value) && props.value.length;
            }
        })

        return (
            <>{props.rules.map((rule, index) => {
                return <div key={index} className={ruleClasses(rule.passRule)}>
                    {!rule.passRule ? <FailRule/> : <PassRule/>}
                    {rule.text}
                </div>
            })}</>
        )
    }

    const renderResetPasswordField = () => {
        return (
            <>
                <PasswordField {...store.defaultInputProps(store)} />
                <div className="marg-t-20 marg-b-15">
                    <ResetPasswordRules value={store.getValue(store)} rules={props.rules} />
                </div>
            </>
        );
    }

    const renderDatePicker = () => {
        return (
            <Field
                name={store.name}
                render={({ field }) => (
                    <DatePicker label={`${props.renderAsterisk && props.validation.required ? '* ' : ''}${props.label}`}
                        format="DD/MM/YYYY"
                        onChange={val => { store.onChangeElement(field.name, val, store) }}
                        value={field.value}
                        autoFocus={props.autoFocus}
                        disabled={store.isDisabled(false, store)}
                        classNames={store.classNames(store)}
                        renderError={renderError}
                        withTime={props.validation.withTime}
                        onlyTime={props.validation.onlyTime}
                        name={`material-${store.name}`} />
                )}
            />
        );
    }

    const renderAutocomplete = () => {
        const defaultInputProps = store.defaultInputProps(store);
        const AutocompleteComponent = props.portsAutocomplete ? PortsAutocompleteField : AutocompleteField;
        const initialValue = props.portsAutocomplete ? { port: store.getValue(store) } : store.getValue(store)

        return (
            <Field
                name={store.name}
                render={({ field }) => (
                    <AutocompleteComponent
                        {...defaultInputProps}
                        label={`${props.validation.required ? '* ' : ''} ${props.label}`}
                        onChange={(event, newValue) => {
                            store.onChangeElement(props.name, newValue, store)
                        }}
                        loading={props.loading}
                        autocompleteOptions={props.autocompleteOptions}
                        noOptionsText={props.noOptionsText}
                        value={initialValue}
                        onInputChange={props.onInputChange}
                        renderOption={props.renderOption}
                        disabled={store.isDisabled(false, store)}
                        classNames={store.classNames(store)}
                        name={`material-${store.name}`}
                        renderError={renderError}
                        renderInput={(params) => <TextField {...params} onInputChange={props.onInputChange} label={props.label} variant="standard" fullWidth />}
                    />
                )}
            />
        );

    }

    const renderToggler = () => {
        const defaultInputProps = store.defaultInputProps(store);
        // start code for material ui
        delete defaultInputProps.error;
        delete defaultInputProps.helperText;
        delete defaultInputProps.wrapperClassName;
        // end code for material ui
        return (
            <div className={`input-group ${store.classNames(store).wrapper}`}>
                <TogglerButton {...defaultInputProps} /*label={props.label}*/
                    displayLabelAtEnd={props.displayLabelAtEnd}
                    labelClass={store.classNames(store).label} />
            </div>
        );
    }

    const renderSegmentButtons = (hasIcon = false) => {
        const defaultInputProps = store.defaultInputProps(store);
        // start code for material ui
        delete defaultInputProps.error;
        delete defaultInputProps.helperText;
        delete defaultInputProps.wrapperClassName;
        // end code for material ui
        defaultInputProps.label = renderLabel();
        defaultInputProps.options = store.createOptions(store);
        defaultInputProps.renderError = renderError;
        defaultInputProps.boxError = store.getError(store) ? true : false;

        return (
            <div className={`input-group ${store.classNames(store).wrapper}`}>
                {hasIcon ? <IconSegmentButtons {...defaultInputProps} /> : <SegmentButtons {...defaultInputProps} />}
            </div>
        );
    }

    const renderSelect = (isMulti = false) => {
        const hasError = (props.validation.hasOwnProperty('requiredFields') || store.isTouched(store)) && store.getError(store);
        
        const propsObj = {
            name: `material-${store.name}`,
            options: store.createOptions(store),
            placeholder: props.placeholder,
            classNames: store.classNames(store),
            autoFocus: props.autoFocus,
            disabled: store.isDisabled(store.name, store),
            multi: isMulti,
            // start code for material ui
            label: `${props.renderAsterisk && props.validation.required ? '* ' : ''}${props.label}`,
            error: !!hasError,
            helperText: props.canRenderError && hasError ? hasError : '',
            // end code for material ui
        };

        return (
            <Field
                name={store.name}
                render={({ field }) => (                    
                    <SelectField 
                        {...propsObj}
                        value={field.value || (!isMulti ? "" : [""])}
                        onBlur={event => {
                            event.target.name = field.name;
                            props.formikHelpers.handleBlur(event);
                        }}
                        onChange={el => {store.onChangeElement(field.name, el.target.value, store)}} 
                    />
            )}
            />
        );
    }

    return(
        ((!rerenderForm) && (element !== '')) 
        && (
            extraClasses 
                ? <div className={GridUtils.getHiddenClasses(props.viewports)}>
                    {element}
                </div>
                : element
            )
    );
}

FormElement.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]).isRequired,
    formikHelpers: PropTypes.object.isRequired,
    renderAsterisk: PropTypes.bool.isRequired,
    labelImage: PropTypes.string,
    arrayHelpers: PropTypes.object,
    validation: PropTypes.object,
    rules: PropTypes.array,
    structure: PropTypes.object,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    options: PropTypes.array,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    noOptionsText: PropTypes.string,
    autoFocus: PropTypes.bool,
    hidden: PropTypes.object,
    disabled: PropTypes.object,
    displayLabelAtEnd: PropTypes.bool,
    cssClasses: PropTypes.object,
    autocompleteOptions: PropTypes.array,
    portsAutocomplete: PropTypes.bool,
    overwriteClassNames: PropTypes.bool,
    withoutBackground: PropTypes.bool,
    tile: PropTypes.bool,
    viewports: PropTypes.object,
    maxItems: PropTypes.number,
    loading: PropTypes.bool,
    canRenderLabel: PropTypes.bool,
    canRenderError: PropTypes.bool,
    boxError: PropTypes.bool,
    onChange: PropTypes.func,
    customRender: PropTypes.func,
    onInputChange: PropTypes.func,
    renderOption: PropTypes.func,
    parentStructure: PropTypes.object,
    unit: PropTypes.string
};

FormElement.defaultProps = {
    arrayHelpers: {},
    validation: {},
    rules: [],
    structure: {},
    label: '',
    options: [],
    autocompleteOptions: [],
    portsAutocomplete: false,
    placeholder: '',
    noOptionsText: '',
    autoComplete: 'off',
    autoFocus: false,
    boxError: false,
    displayLabelAtEnd: true,
    hidden: {},
    disabled: {},
    cssClasses: {
        //arrayWrapper: 'headline3 center-text-start marg-t-15 marg-b-10',
        wrapper: '',
        label: 'form-text',
        element: 'ds-input',
        error: 'form-error flex-centered-end'
    },
    overwriteClassNames: false,
    withoutBackground: false,
    tile: true,
    viewports: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    maxItems: 0,
    canRenderLabel: true,
    loading: false,
    canRenderError: true,
    onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => { },
    customRender: ({ arrayHelpers, values, error, isTouched, errors, touched, setSubmitting, setFieldValue, setFieldTouched, name }) => { },
    onInputChange: ({ event, value }) => { },
    renderOption: ({ option }) => { },
    parentStructure: {},
    unit: '',
};

export default FormElement;
