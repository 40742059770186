import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/core/Layout';
import WidgetPanel from 'components/widgetPanel/widgetPanel';
import GridUtils from 'Utilities/gridUtils';

const Column = props => {
    return (
    <>
        {/* if content is a nested layout call layout again. */}
        {props.layout.length ?
            <div className={`${GridUtils.getHiddenClasses(props.grid, props.className)}`}>
                <Layout layout={props.layout} className={`${props.className}`}/>
            </div>

        //  if content is a components array render panel component and the component in its body.
        : <React.Fragment>
            {props.components.map((component, index) => (
                <div key={index} className={`${GridUtils.getHiddenClasses(props.grid, props.className)}`}>
                    <WidgetPanel 
                        bodyClass={props.innerClass}
                        component={component.component}
                        compProps={component.props} 
                        compStyle={component.style}
                        loading={component.props.loading}
                        title={component.title}
                        footer={component.footer}
                        lightSvg={component.lightSvg}
                        noBoilersConfigSvg={component.noBoilersConfigSvg}
                        lightComp={component.lightComp}
                    />
                </div>
            ))}
        </React.Fragment> }
    </>)
};

Column.propTypes = {
    grid: PropTypes.object.isRequired,
    layout: PropTypes.array,
    components: PropTypes.array,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    className: PropTypes.string
};

Column.defaultProps = {
    layout: [],
    components: [],
    title: false,
    className: 'widgets-cols-padding padding-left-right-7'
};

export default Column;
