import service from 'common/js/service';
import {userStore} from 'common/store/storeUtils';
import { vesselGroupStore, vesselStore, vesselUtils } from 'common/store/storeUtils';

const vesselGroupUtils = {
    // when user selects a different vessel group, then the following 2 dispatches occur
    setSelectedAndChange: (dispatch, selectedVesselGroup) => {
        dispatch({ type: 'vesselGroup/setSelectedVesselGroup', payload: selectedVesselGroup});
        dispatch({ type: 'vesselGroup/setChangeVesselGroup', payload: true});
        vesselUtils.getFleetVessels(dispatch);
    },
    initialize: (dispatch, vesselGroups, ufa) => {
        if(vesselGroups) dispatch({ type: 'vesselGroup/setAllVesselGroups', payload: vesselGroups});
        if(ufa) {
            dispatch({ type: 'vesselGroup/setSelectedVesselGroup', payload: ufa.id});
            dispatch({ type: 'vesselGroup/setAssignedVesselGroup', payload: ufa});
        }
    },
    // find the selected vessel group and return its data
    getSelectedVesselGroupData: () => {
        return vesselGroupStore().allVesselGroups.filter(vesselGroup => vesselGroup.id === vesselGroupStore().selectedVesselGroup)[0] || vesselGroupStore().allVesselGroupsObj;
    },
    // find a vessel by id and return its vessel group
    getVesselGroupIdFromVesselId: (vesselIds) => {
        if (!vesselIds || vesselIds.length === 0) return;
        const firstVesselId = vesselIds[0];

        return vesselStore().allVessels.filter(vessel => vessel.vesselId === firstVesselId)[0].vesselGroupId;
    },
    // get all the vessels of a given fleet
    getFleetVesselsFromFleetId: (fleetId) => {
        if (!fleetId) return;
        return vesselStore().allVessels.filter(vessel => vessel.vesselGroupId === fleetId);
    },
    // get the name of the vesselGroup that a given vessel belongs to
    getVesselGroupNameFromVesselId: (vesselIds) => {
        if (!vesselIds || vesselIds.length === 0) return;
        const vesselGroupId = vesselGroupUtils.getVesselGroupIdFromVesselId(vesselIds);

        return vesselGroupStore().allVesselGroups.filter(vesselGroup => vesselGroup.id === vesselGroupId)[0].name;
    },
    getVesselGroupIdFromName: (vesselGroupName) => {
        return vesselGroupStore().allVesselGroups.filter(vesselGroup => vesselGroup.name === vesselGroupName)[0]?.id;
    },
    getVesselGroupIndex: (vesselIds) => {
        const foundVesselGroupId = vesselGroupUtils.getVesselGroupIdFromVesselId(vesselIds);
        const foundVesselGroupIndex = vesselGroupStore().allVesselGroups.findIndex(group => group.id === foundVesselGroupId);
        if(foundVesselGroupIndex > -1) return foundVesselGroupIndex > 9 ? foundVesselGroupIndex % 10 : foundVesselGroupIndex; // logic for returning the index with values between 0-9 even when the vessel group index exceeds 9 
    },
    add: async (dispatch, vesselGroup, all ) => {
        const result = await service.fleetAction({ vesselGroup, type: 'newFleet' });

        if(!result.error) dispatch({ type: 'vesselGroup/setAllVesselGroups', payload: [...all, result]});

        return result;
    },
    delete: async (dispatch, vesselGroup, all) => {
        const result = await service.fleetAction({vesselGroup, type:"deleteFleet"});

        if(!result.error) dispatch({ type: 'vesselGroup/setAllVesselGroups', payload: all.filter(oldVesselGroup => oldVesselGroup.id !== vesselGroup.id)});
        
        return result;
    },
    update: async (dispatch, vesselGroup, all) => {
        const result = await service.fleetAction({vesselGroup, type: 'updateFleet'});

        if(!result.error) {
            let vesselGroups = all;
            vesselGroups.forEach(oldVesselGroup => {
                if(oldVesselGroup.id === vesselGroup.id) {
                    oldVesselGroup.name = vesselGroup.name; 
                }
            });
            dispatch({ type: 'vesselGroup/setAllVesselGroups', payload: vesselGroups});
        }
        return result;
    },
    buildVesselGroupObject: (dispatch, r) => {
        const tmpArr = [],
            response = JSON.parse(JSON.stringify(r));

        // set the assignedVesselGroup to be the default group of the user and also delete the vessels field from every vesselGroup object
        response.companies.forEach(company => {
            company.vesselGroups.forEach(vesselGroup => {
                if(vesselGroup.id === userStore().user.defaultGroup)
                    dispatch({ type: 'vesselGroup/setAssignedVesselGroup', payload: {...vesselGroup}});
                vesselGroup.companyId = company?.id;
                delete vesselGroup.vessels;
            });
            tmpArr.push(company.vesselGroups);
        });

        return tmpArr[0];
    }
}

export default vesselGroupUtils;