import {BoilersUtils} from "../../Utilities/boilers";
import boStorage from "../../components/monitoringCategories/localStorages/boStorage";
import ThemeColors from "../../sass/_themeColorsForJs.scss";
import Utilities from 'Utilities/global';
import {licensing} from "../../common/store/licensing";
import { vesselUtils } from 'common/store/storeUtils';

let boilerTypes, boilerTypesObj = {}, noBoilersConfig = {};

export const setBoilersRunningTimeTypes = (data, widget) => {
    boilerTypes = data.filter(item => item.type && item.subId && BoilersUtils.acceptedBoilerTypes.includes(item?.type));

    boilerTypesObj[widget?.id] = boilerTypes;

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    (!boilerTypes.length && !isLight) ? noBoilersConfig[widget?.id] = true : noBoilersConfig[widget?.id] = false;

};

const combineRunningTimeWithType = (data, widget) => {
    let tempArr = [], _boilerTypes, typeCount = 1;
    _boilerTypes = boilerTypesObj[widget?.id];

    // Match boilers with data && filter out data with status = 0
    _boilerTypes.forEach(type => {
        data.forEach(el => {
            (el?.subId === type.subId && el?.status) && tempArr.push({...type, ...el})
        })
    })

    // Add label field in returned object - add index if multiple identical types exist
    tempArr.forEach((el, index) => {
        if (tempArr[index]?.type === tempArr[index + 1]?.type) {
            !Utilities.hasNumber(tempArr[index].label) && (tempArr[index].label = `${tempArr[index]?.type} ${typeCount}`);
            tempArr[index + 1].label = `${tempArr[index]?.type} ${typeCount+1}`;
            typeCount++;
        } else {
            !Utilities.hasNumber(tempArr[index].label) && (tempArr[index].label = tempArr[index]?.type);
            typeCount = 1;
        }
    })

    return tempArr;
}

export const updateBoilersRunningTime = async (id, data, updateState, extraChartConfigs, widget) => {
    let _data = Object.assign([], data);
    boStorage.setBoilersRunningTimeData(_data);

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    if(isLight) noBoilersConfig[widget?.id] = false;
    if(noBoilersConfig[widget?.id]) return updateState(id, {noBoilersConfig: noBoilersConfig[widget?.id]}, widget?.id);

    if (_data.length === 0) return updateState(id, _data, widget?.id);

    if (!boilerTypes || boilerTypes.length === 0) return updateState(id, [], widget?.id);

    const sensorsStyle = {
        path: {stroke: ThemeColors['sensor_color']},
        trail: {stroke: ThemeColors['disabled_surface']}
    }

    const missingDataStyle = {
        path: {stroke: ThemeColors['missing_first_stack_sensor']},
        trail: {stroke: ThemeColors['disabled_surface']}
    }

    let updatedData = {
        data: combineRunningTimeWithType(_data, widget),
        sensorsStyle: sensorsStyle,
        missingDataStyle: missingDataStyle
    }

    const hasData = _data.find(boiler => boiler?.missingData !== 100);
    if(!hasData) updatedData = {};

    updateState(id, updatedData, widget?.id);
}
