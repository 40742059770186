const meStorage = {
    fuelBarData: null,
    loadLineChartData: null,
    fuelLineChartData: null,
    turboChargersData: null,
    meInfoData: null,
    pistonsData: null,
    deviationData: null,
    generalBarsTableData: null,
    SFOCPowerData: null,
    SFOCPowerScatterPlotsData: null, 
    fuelPowerData: null,
    fuelPowerScatterPlotsData: null, 
    powerRpmData: null,
    powerRpmScatterPlotsData: null,
    SCAVPowerScatterPlotsData: null,
    TCRpmPowerData: null,
    TCRpmPowerScatterPlotsData: null,
    TCScavRpmScatterPlotsData: null,

    setFuelBarData: (data) => meStorage.fuelBarData = data,
    setLoadLineChartData: (data) => meStorage.loadLineChartData = data,
    setFuelLineChartData: (data) => meStorage.fuelLineChartData = data,
    setTurboChargersData: (data) => meStorage.turboChargersData = data,
    setMeInfoData: (data) => meStorage.meInfoData = data,
    setPistonsData: (data) => meStorage.pistonsData = data,
    setDeviationData: (data) => meStorage.deviationData = data,
    setGeneralBarsTableData: (data) => meStorage.generalBarsTableData = data,
    setSFOCPowerData: (data) => meStorage.SFOCPowerData = data,
    setSFOCPowerScatterPlotsData: (data) => meStorage.SFOCPowerScatterPlotsData = data,
    setFuelPowerData: (data) => meStorage.fuelPowerData = data,
    setFuelPowerScatterPlotsData: (data) => meStorage.fuelPowerScatterPlotsData = data,
    setPowerRpmData: (data) => meStorage.powerRpmData = data,
    setPowerRpmScatterPlotsData: (data) => meStorage.powerRpmScatterPlotsData = data,
    setSCAVPowerScatterPlotsData: (data) => meStorage.SCAVPowerScatterPlotsData = data,
    setTCRpmPowerData: (data) => meStorage.TCRpmPowerData = data,
    setTCRpmPowerScatterPlotsData: (data) => meStorage.TCRpmPowerScatterPlotsData = data,
    setTCScavRpmScatterPlotsData: (data) => meStorage.TCScavRpmScatterPlotsData = data,

    resetMeStorage: () => {
        meStorage.setFuelBarData(null);
        meStorage.setLoadLineChartData(null);
        meStorage.setFuelLineChartData(null);
        meStorage.setTurboChargersData(null);
        meStorage.setMeInfoData(null);
        meStorage.setPistonsData(null);
        meStorage.setDeviationData(null);
        meStorage.setGeneralBarsTableData(null);
        meStorage.setSFOCPowerData(null);
        meStorage.setSFOCPowerScatterPlotsData(null);
        meStorage.setFuelPowerData(null);
        meStorage.setFuelPowerScatterPlotsData(null);
        meStorage.setPowerRpmData(null);
        meStorage.setPowerRpmScatterPlotsData(null);
        meStorage.setSCAVPowerScatterPlotsData(null);
        meStorage.setTCRpmPowerData(null);
        meStorage.setTCRpmPowerScatterPlotsData(null);
        meStorage.setTCScavRpmScatterPlotsData(null);
    },

    isGlobalStateEmpty: () => {
        return !meStorage.fuelBarData || !meStorage.loadLineChartData || !meStorage.deviationData 
                || !meStorage.fuelLineChartData || !meStorage.turboChargersData 
                || !meStorage.meInfoData || !meStorage.pistonsData
                || !meStorage.SFOCPowerScatterPlotsData || !meStorage.fuelPowerScatterPlotsData
                || !meStorage.powerRpmScatterPlotsData || !meStorage.SCAVPowerScatterPlotsData
                || !meStorage.TCRpmPowerScatterPlotsData || !meStorage.TCScavRpmScatterPlotsData;
    }
}

export default meStorage;