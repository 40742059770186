import { configureStore } from '@reduxjs/toolkit';
import combinedReducer from 'common/store/Slices/combinedReducer';

export const store = configureStore({ 
    reducer: combinedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // show a warning regarding serializable check only if the check is longer than 300 ms 
                // (warnings are appearing only in development mode)
                warnAfter: 300,
                // do not throw the non-serializable date error when setting some specific redux variables
                ignoredPaths: ['reports.fromTo.from', 'reports.fromTo.to', 'calendar.currentDate', 'calendar.calendarCurrentEvent.modalDate'],
                ignoredActions: ['calendar/setCalendarCurrentEvent'],
            },
            // show a warning regarding immutable check only if the check is longer than 300 ms 
            // (warnings are appearing only in development mode)
            immutableCheck: { warnAfter: 300 }
        })
});

export default store;