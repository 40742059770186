import React, { useState } from 'react';
import DynamicWidget from "./dynamicWidget";
import _ from 'lodash';
import {WidthProvider, Responsive} from "react-grid-layout";
import Highcharts from "highcharts";
import Highstock from "highcharts/highstock";
import { useSelector, useDispatch } from 'react-redux';
import { dashboardUtils } from 'common/store/storeUtils';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DynamicWidgetList = props => {
    const [layouts, setLayouts] = useState({});

    // store vars
    const dashboardStoreJSX = useSelector(state => state.dashboard);
    const dispatch = useDispatch();

    const layoutChanged = (obj1, obj2) => {
        const _obj1 = JSON.parse(JSON.stringify(obj1));
        const _obj2 = JSON.parse(JSON.stringify(obj2));

        const isArrayEqual = function (x, y) {
            return _(x).differenceWith(y, _.isEqual).isEmpty();
        };

        _obj1.forEach(element => delete element.i)
        _obj2.forEach(element => delete element.i)

        return !isArrayEqual(_obj1, _obj2);
    }

    const onLayoutChange = (layout, newLayouts) => {
        const _widgets = Object.assign([], props.widgets), _newLayouts = Object.assign([], newLayouts);

        // If layout has changed, inform store to run PUT method
        if (dashboardUtils.dashboardProperties.layoutsObject.length !== 0 && layoutChanged(layout, dashboardUtils.dashboardProperties.layoutsObject)) dispatch({ type: 'dashboard/setTemplateHasChanges', payload: true });

        // For each widget, store the new layout when adding, moving, resizing the widgets in grid
        _widgets.forEach(_widget => {
            if (_newLayouts[dashboardUtils.currentBreakpoint(window.screen.width)]) {
                _newLayouts[dashboardUtils.currentBreakpoint(window.screen.width)].forEach(_layout => {
                    if (_widget.id === parseInt(_layout.i)) {
                        _widget.layout = _layout;
                    }
                })
            }
        })


        // Store layout in variable to access it in later additions
        dashboardUtils.dashboardProperties.layoutsObject = layout;

        // Store the widgets with the updated layout
        props.setWidgets(_widgets);

        // Set layouts object to state
        setLayouts(newLayouts);
    }

    const onResize = (layout) => {
        // Reflow all charts on resize
        Highcharts.charts.forEach(chart => chart && chart.reflow());
        Highstock.charts.forEach(chart => chart && chart.reflow());
    }

    return (
        <React.Fragment>
            {Object.keys(props.widgets).length !== 0 &&
            <ResponsiveReactGridLayout
                className="layout"
                cols={dashboardUtils.dashboardProperties.cols}
                rowHeight={dashboardUtils.dashboardProperties.rowHeight}
                layouts={layouts}
                isDraggable={dashboardStoreJSX.editMode}
                draggableCancel={dashboardUtils.dashboardProperties.PREVENT_DRAG_DEFAULTS.join(',')}
                isResizable={dashboardStoreJSX.editMode}
                breakpoints={dashboardUtils.dashboardProperties.breakpoints}
                onResize={onResize}
                onLayoutChange={(layout, newLayouts) =>
                    onLayoutChange(layout, newLayouts)
                }
            >
                {props.widgets && props.widgets.map((widget, index) => (
                    <div key={widget.id} data-grid={widget.layout}>
                        <DynamicWidget
                            {...widget}
                            loading={props.loading}
                            editMode={dashboardStoreJSX.editMode}
                            handleEditClick={props.handleEditClick}
                            handleDeleteClick={props.handleDeleteClick}
                        />
                    </div>
                ))}
            </ResponsiveReactGridLayout>

            }
        </React.Fragment>
    );
}

export default DynamicWidgetList;
