import geStorage from "components/monitoringCategories/localStorages/geStorage";
import lineChartStockOptions from "components/charts/lineChart/react-lineChartStock";
import {licensing} from "../../common/store/licensing";
import chartUtils from 'Utilities/charts';
import { vesselStore, vesselUtils } from 'common/store/storeUtils';

let gesNum = 0;

export const loadHistoryPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    metrics: [
        {
            metricCategory: "GENERATOR_ENGINE",
            subIds: [],
            metricData: [
                {
                    metricName: "load"
                }
            ]
        }
    ]
};

export let loadHistoryPayload_light = {
    xAxis: "TIME",
    aggregation: "AVG",
    metrics:[
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: []
        }
    ]
};

// Dynamically fill metricData of loadHistoryPayload_light from generator engines number
const setPayloadLight = count => {
    loadHistoryPayload_light.metrics[0].metricData = [];
    for (let i = 1; i <= count; i++ ) {
        loadHistoryPayload_light.metrics[0].metricData.push({'metricName': `ge_${i}_load_avg_calc`});
    }
}

const yAxisData = [
    {
        id: 'loads-axis',
        title: {text: 'Load (%)'},
        min: 0,
        tickPixelInterval: 150,
        opposite: false
    }
]

export const setGesNum = (data, widget) => {
    if(Object.keys(data)?.length === 0) return;

    const vessel = vesselStore().selectedVessel !== -1 && vesselStore().selectedVessel !== undefined 
    ? vesselStore().selectedVessel 
    : widget?.vesselIds[0];

    gesNum = (data && data[vessel]) ? data[vessel]?.length : null;

    if(!gesNum) gesNum = 0;

    setPayloadLight(gesNum);
}

const getSeries = (response) => {
    let series = response[0].values.map(resp => ({
        name: `GE-${resp.subId}`,
        data: resp.value,
        tooltipTitle: `GE-${resp.subId}`,
        yAxis: 'loads-axis',
        titleSuffix: '(UTC)',
        yMin: 0,
        tooltip: {valueSuffix: ` ${resp.unit}`, valueDecimals: 2}
    }));
    return series;
}

const getSeriesLight = response => {

    // Parse float string values coming from response
    response[0].values.forEach(resp => {
        resp.value.forEach(value => {
            value.y = parseFloat(value?.y)
        })
    })

    return response[0].values.map((resp) => ({
        name: `GE-${resp.metricName.match(/\d+/)[0]}`,
        data: resp.value,
        tooltipTitle: `GE-${resp.metricName.match(/\d+/)[0]}`,
        yAxis: 'loads-axis',
        titleSuffix: '(UTC)',
        yMin: 0,
        tooltip: {valueSuffix: ` ${resp.unit}`, valueDecimals: 2}
    }));
}

export const loadHistory = (id, response, updateState, extraChartConfigs, widget) => {
    geStorage.setLoadHistoryData(response);
    
    if (response?.length === 0) return updateState(id, {}, widget?.id);

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    let loadHistoryJSON = {chartData: {...lineChartStockOptions()}};
    if (response[0] && response[0].values) {
        loadHistoryJSON.chartData.chart = {...loadHistoryJSON.chartData.chart, id: id};
        loadHistoryJSON.chartData.yAxis = yAxisData;
        loadHistoryJSON.chartData.series = isLight ? getSeriesLight(response) : getSeries(response);
        loadHistoryJSON.chartData.chart.height = 530;
        loadHistoryJSON.chartData.legend.itemDistance = 30;
        loadHistoryJSON.chartData.tooltip.borderColor = chartUtils.tootlipBorderColor.premiumLight();

        //if detailed data exists with map bullet clicked or alert clicked, add the plotLines and plotBands (guides) to line charts
        if (extraChartConfigs && extraChartConfigs.plotLines) {
            let plotGuides = extraChartConfigs.plotLines.setReportsPlotLineGuides(response);
            if (plotGuides?.xAxis) {
                loadHistoryJSON.chartData.xAxis = Object.assign({}, loadHistoryJSON.chartData.xAxis, plotGuides.xAxis);
            }
        }
    }

    updateState(id, loadHistoryJSON, widget?.id);

};
