import React from 'react';
import PropTypes from 'prop-types';
import {widgetLibraryUtils} from "components/widgetLibrary/widgetLibrary.js";

const TimeInfoLabel = ({timespan}) => {
    return (
        <div className={widgetLibraryUtils.timespanHandler(timespan)?.classes}>
            {widgetLibraryUtils.timespanHandler(timespan)?.label}
        </div>
    );
}

TimeInfoLabel.propTypes = {
    timespan: PropTypes.string
}

export default TimeInfoLabel;