import React, {useState, useEffect} from 'react';
import {CircularProgressbarWithChildren} from "react-circular-progressbar";
import {ReactComponent as GeIcon} from 'assets/img/app/monitoring-imgs/generator-engine-icon.svg';
import classnames from "classnames";
import Utilities from "../../Utilities/global";
import ProgessBarText from '../../components/monitoringCategories/geComponents/progressBarText';
import {useSelector} from 'react-redux';
import {reportsStore} from 'common/store/storeUtils';

const RunningTime = props => {
    const [sensorView, setSensorView] = useState(true);
    const [changeViewMode, setChangeViewMode] = useState(true);
    const [firstDataArray, setFirstDataArray] = useState([]);
    const [secondDataArray, setSecondDataArray] = useState([]);
    const [secondDataArrayStyle, setSecondDataArrayStyle] = useState([]);

    // store vars for JSX
    const reportsStoreJSX = useSelector(state => state.reports);

    const runningTimeTextClasses = (hasSensored) => {
        return classnames('info-value', {'sensor': hasSensored}, {'telegram': !hasSensored});
    }

    const runningTimeContainerClasses = () => {
        return classnames("running-time__container__item justify-content-center d-flex");
    }

    const hasMissingDataAboveLimit = (element) => {
        return element?.missingData > Utilities.missingValuesLimit;
    }

    // checks for missing data and returns the appropriate style
    const getCircularBarStyle = (element) => {
        return hasMissingDataAboveLimit(element) ? props?.data?.missingDataStyle : hasSensoredData() ? props?.data?.styles?.sensorsStyle :  props?.data?.styles?.reportsStyle;
    }

    const hasSensoredData = () => {
        return props?.data?.data?.sensors?.length > 0;
    }

    // in case premium vessel is not telegram configured, then only sensors data should be displayed, so hasTelegramData will be false
    const hasTelegramsData = () => {
        return (props?.isReportsWidget && !reportsStore().isTelegramConfigured) ? false : (props?.data?.data?.telegrams?.length > 0);
    }

    const checkIfBothSensorsAndTelegramsExist = () => {
        return hasSensoredData() && hasTelegramsData() && props?.isReportsWidget;
    }

    useEffect(() => {
        if(!props?.data?.data) return;
    
        setFirstDataArray(((hasSensoredData() && hasTelegramsData()) || (hasSensoredData())) ? props?.data?.data?.sensors : props?.data?.data?.telegrams);
        setSecondDataArray((hasSensoredData() && hasTelegramsData() && props?.isReportsWidget) ? props?.data?.data?.telegrams : []);
        setSecondDataArrayStyle(((hasSensoredData() && hasTelegramsData())) ? props?.data?.styles?.reportsStyle : {});

        // in case we have only sensored data or only telegrams, disable the capability for the user to change modes (from sensored to telegrams and vice versa)
        if(!hasSensoredData() || !props?.data?.data?.telegrams?.length > 0) setChangeViewMode(false);
        if(!hasSensoredData() && props?.data?.data?.telegrams?.length > 0) setSensorView(false); // if user has only telegrams data, then sensored view mode should be false
    // eslint-disable-next-line
    }, [reportsStoreJSX.isTelegramConfigured, props?.data?.data, props?.isReportsWidget]);

    return (
        <div className='running-time running-time-wrapper'>
            {firstDataArray.map((element, index) => (
                <div className="running-time__container" key={element.engineCode}>
                    <div className={runningTimeContainerClasses()}>
                        <div className="running-time__hours d-flex marg-r-25">
                            <div className="svg-path-main-text-color ico flex-centered marg-r-15"><GeIcon/></div>
                            <div className={`running-time__hours__text d-flex flex-column justify-content-center`}>
                                    {checkIfBothSensorsAndTelegramsExist() 
                                        ? <div className='textWrapper'>                        {/* case where we have both sensored and telegrams (reports page widget only) */}
                                            <div className={runningTimeTextClasses(true)}> 
                                                {element?.hours ? `${Utilities.renderNumberWithNoZerosAfterDigit(element?.hours, 1)} hrs` : "-"}
                                            </div> 
                                            <div className='textDivider'/>
                                            <div className={runningTimeTextClasses(false)}> 
                                                {secondDataArray[index]?.hours ? `${Utilities.renderNumberWithNoZerosAfterDigit(secondDataArray[index]?.hours, 1)} hrs` : "-"}
                                            </div> 
                                        </div>
                                        : <div className={runningTimeTextClasses(hasSensoredData())}>      {/* case where we have only sensored or telegrams */}
                                            {element?.hours ? `${Utilities.renderNumberWithNoZerosAfterDigit(element?.hours, 1)} hrs` : "-"}
                                        </div>
                                    }
                                <div className="label">ENGINE {element.engineCode}</div>
                                {hasMissingDataAboveLimit(element) && <div className="missing-data-text">({element?.missingData?.toFixed(1)}% Missing Data)</div>}
                            </div>
                        </div>
                        <div className="circular-chart" onMouseEnter={changeViewMode ? () => setSensorView(true) : () => {}}>
                            <CircularProgressbarWithChildren value={element?.loadAvg} strokeWidth={5} styles={getCircularBarStyle(element)}>
                            { (firstDataArray?.length > 0 && !secondDataArray?.length > 0) ? (
                                    <ProgessBarText 
                                        value={Utilities.renderNumber(Utilities.roundToDigits(element?.loadAvg, 2))}
                                        text={sensorView ? 'Sensor' : 'Report'}
                                        report={!sensorView}
                                />): (
                                    <div className="circular-chart-2" onMouseEnter={changeViewMode ? () => setSensorView(false) : () => {}} onMouseLeave={changeViewMode ? () => setSensorView(true) : () => {}}>
                                        <CircularProgressbarWithChildren value={secondDataArray[index]?.loadAvg} strokeWidth={6.7} styles={secondDataArrayStyle}> 
                                            <ProgessBarText 
                                                value={sensorView ? Utilities.renderNumber(Utilities.roundToDigits(element?.loadAvg, 2)) : Utilities.renderNumber(Utilities.roundToDigits(secondDataArray[index]?.loadAvg, 2))}
                                                text={sensorView ? 'Sensor' : 'Report'}
                                                report={!sensorView}
                                            />
                                        </CircularProgressbarWithChildren>
                                    </div>
                                )
                                }
                            </CircularProgressbarWithChildren>
                        </div>
                        {index % 2 !== 1 && <div className="divider"/>}
                    </div>
                </div>
            ))}
        </div>
    )
}


export default RunningTime;
