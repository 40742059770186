import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Layout from 'components/core/Layout';
import ReportsService from "common/js/reports";
import service from 'common/js/service';
import InfoValuesGridWithIcon from 'components/infoValuesGridWithIcon/infoValuesGridWithIcon';
import NavigationStatusChart from 'components/monitoringCategories/overviewComponents/navigationStatusChart';
import AddEventsButton from 'components/addEventsButton/addEventsButton';
import ComponentWithIcon from 'components/componentWithIcon/componentWithIcon';
import TableComp from 'components/table/table';
import EventTypeGroup from 'components/eventTypeGroup/eventTypeGroup';
import VesselRating from 'components/monitoringCategories/overviewComponents/vesselRating';
import FuelConsump from 'components/monitoringCategories/overviewComponents/fuelConsump'; 
import VesselMonitoringChart from 'components/monitoringCategories/overviewComponents/vesselMonitoringChart'
import { vesselLog, columnDefs } from 'widgets/events/VesselLog';
import { navigationStatusChartUtils } from 'widgets/vesselData/navigationChart'; 
import { navStatus, navigationStatusMilesPayload, setMiles } from 'widgets/vesselData/navigationStatus';
import { vesselRatingUpdate, vesselRatingPayload } from 'widgets/vesselData/vesselRatingJs';
import { fuelConsumpChartUpdate, fuelConsumpChartPayload, fuelConsumpChartPayloadLight } from 'widgets/vesselData/fuelConsumpChart';
import { fuelConsumpTotalsUpdate, fuelConsumpFcrPayload, fuelConsumpFcrPayloadLight } from 'widgets/vesselData/fuelConsumpTotalsUtils';
import { vesselMonitoringChartUpdate, vesselMonitoringChartPayload, vesselMonitoringChartPayloadLight } from 'widgets/vesselData/vesselMonitoringChartUtils';
import { ReactComponent as AddIcon } from 'assets/img/app/global-imgs/add-cross.svg';
import overviewStorage from './localStorages/overviewStorage';
import {licensing} from "common/store/licensing";
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import {monitoringStore, vesselStore, monitoringUtils} from 'common/store/storeUtils';
import {useDispatch, useSelector} from 'react-redux';
import GlobalUtilities from 'Utilities/global';

let isMounted = false;   

const MonitoringOverview = () => {
    const reportsService = new ReportsService();
    const [navigationStatusChartData, setNavigationStatusChartData] = useState([]);
    const [vesselLogData, setVesselLogData] = useState([]);
    const [navigationStatus, setNavigationStatus] = useState([{}, {}]);
    const [modalShow, setModalShow] = useState(false);
    const [telegramsModalShow, setTelegramsModalShow] = useState(false);
    const [vesselRating, setVesselRating] = useState({});
    const [fuelConsumpChartData, setFuelConsumpChartData] = useState([]);
    const [fuelConsumpTotals, setFuelConsumpTotals] = useState([]);
    const [vesselMonitoringChartData, setVesselMonitoringChartData] = useState([]);
    const [showTelegramsOnly, setShowTelegramsOnly] = useState(true);
    const [requestOnGoing, setRequestOnGoing] = useState({
        navigationStatus: false,
        vesselLog: false,
        vesselRating: false,
        fuelConsumpChart: false,
        vesselMonitoringChart: false,
    });

    // store vars
    const dispatch = useDispatch();
    const monitoringStoreJSX = useSelector(state => state.monitoring);

    const reportsComponents = [
        { widgetId: 'navigationStatus',
            inDashboard: false,
            vesselIds: [vesselStore().selectedVessel],
            prerequisite: { isReport: true, report: { widgetId: 'navMiles', singleRequest: { value: false }, payload: navigationStatusMilesPayload, type: 'text'}, callback: setMiles },
            singleRequest: { value: true, externalURequest: false, overwriteHeader: 'get-navigation-status' },
            payload: '',  
            type: 'text'
        },
        { widgetId: 'fuelConsumpChart', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false, overwriteHeader: 'get-highchart-period-report' }, payload: fuelConsumpChartPayload, type: 'text' },
        { widgetId: 'vesselRating', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false, overwriteHeader: 'get-vessel-class'}, payload: vesselRatingPayload, type: 'text'},
        { widgetId: 'fuelConsumpTotals', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: fuelConsumpFcrPayload, type: 'text' },
        { widgetId: 'vesselLog', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: { value: true, request: () => { return { method: service.getVesselEvents,
            params: { value: vesselStore().selectedVessel, startingDate: monitoringStore().fromTo.from, endingDate: monitoringStore().fromTo.to }}}}}, payload: {}, type: 'table'},
        { widgetId: 'vesselMonitoringChart', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: true, externalURequest: false }, payload: vesselMonitoringChartPayload, type: 'basicChart' }
    ];

    const getTitlesFromWidgetIds = (widgetId) => {
        const idsTitlesMapper = {
            vesselRating: 'VESSEL RATING',
            fuelConsump: '',
            vesselMonitoringSyncedCharts: "VESSEL'S MONITORING",
            navigationStatus: 'NAVIGATION STATUS',
            detailsAtSea: '',
            detailsAtPort: '',
            addEventsButton: '',
            vesselLog: 'VESSEL LOG'
        }
        
        return idsTitlesMapper[widgetId];
    }

    const getWidgetsPreferences = () => {
        return {
            className: 'padding-left-right-7',
            layout: [{
                columns: [{
                    grid: { xs: 12, sm: 12, md: 12, lg: 4, xl: 4 },
                    components: [
                        { id: 'vesselRating', title: getTitlesFromWidgetIds('vesselRating'), component: VesselRating, data: {}, style: { height: '400px' }, props: { removeFromLayout: vesselRating === "" } }
                    ]
                }, {
                    grid: { xs: 12, sm: 12, md: 12, lg: 8, xl: 8 },
                    components: [
                        { id: 'fuelConsump', title: getTitlesFromWidgetIds('fuelConsump'), component: FuelConsump, data: {}, style: { height: '460px' }, props: { removeFromLayout: fuelConsumpChartData === "" } }
                    ]
                }]
            }, {
                columns: [{
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 8 },
                    className: 'padding-left-right-7',
                    layout: [{
                        columns: [{
                            grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                            components: [
                                { id: 'vesselMonitoringSyncedCharts', title: getTitlesFromWidgetIds('vesselMonitoringSyncedCharts'), component: VesselMonitoringChart, data: {}, style: { height: '750px' }, props: { removeFromLayout: vesselMonitoringChartData === "" } }
                            ]
                        }
                        ]
                    }]
                }, {
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 4 },
                    className: 'padding-left-right-7',
                    layout: [{
                        columns: [{
                            grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                            components: [
                                { id: 'navigationStatus', title: getTitlesFromWidgetIds('navigationStatus'), component: NavigationStatusChart, data: {}, style: { height: '296px' }, props: { removeFromLayout: navigationStatusChartData === ""} }
                            ]
                        }, {
                            grid: { xs: 12, sm: 12, md: 6, lg: 6, xl: 12 },
                            components: [
                                { id: 'detailsAtSea', title: getTitlesFromWidgetIds('detailsAtSea'), component: InfoValuesGridWithIcon, data: {}, style: { height: '213px' }, props: { removeFromLayout: navigationStatus[0] === "" } }
                            ]
                        },
                        {
                            grid: { xs: 12, sm: 12, md: 6, lg: 6, xl: 12 },
                            components: [
                                { id: 'detailsAtPort', title: getTitlesFromWidgetIds('detailsAtPort'), component: InfoValuesGridWithIcon, data: {}, style: { height: '213px' }, props: { removeFromLayout: navigationStatus[1] === "" } }
                            ]
                        }]
                    }]
                }],
            }, {
                columns: [{
                    grid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                    className: 'padding-left-right-7 vesselLogTable',
                    components: [
                        { component: AddEventsButton, id: 'addEventsButton', title: getTitlesFromWidgetIds('addEventsButton'), data: {}, style: { height: '0' }, props: { removeFromLayout: vesselLogData } },
                        { component: ComponentWithIcon, id: 'vesselLog', title: getTitlesFromWidgetIds('vesselLog'), data: {}, style: { height: 'auto' }, props: { removeFromLayout: vesselLogData === "" }, type: 'table' }
                    ]
                }
                ]
            }]
        };
    };


    const createLayoutProps = (data, props = getWidgetsPreferences()) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
                    if (('id' in newComponent) && !('element' in newComponent)) {
                        if (newComponent.id in data) newComponent.props = data[newComponent.id];
                    }
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    };

    const updateRequestOnGoing = (key, value) => {
        setRequestOnGoing((currenRequestOnGoing) => { return {...currenRequestOnGoing, [key]: value}});
    }

    const reportsResponseMapper = (data, widgetId) => {
        const mapper = {
            navigationStatus: navStatus,
            vesselLog: vesselLog,
            vesselRating: vesselRatingUpdate,
            fuelConsumpChart: fuelConsumpChartUpdate,
            fuelConsumpTotals: fuelConsumpTotalsUpdate,
            vesselMonitoringChart: vesselMonitoringChartUpdate
        }
        return mapper[widgetId](widgetId, data, updateStateMapper, null, reportsComponents.filter(report => report.widgetId === widgetId)[0]);
    }

    const updateStateMapper = (key, value) => {
        const updateMapper = {
            navigationStatus: setNavigationStatus,
            navigationStatusChart: setNavigationStatusChartData,
            vesselLog: setVesselLogData,
            vesselRating: setVesselRating,
            fuelConsumpChart: setFuelConsumpChartData,
            fuelConsumpTotals: setFuelConsumpTotals,
            vesselMonitoringChart: setVesselMonitoringChartData
        }
        return updateMapper[key](value);
    }

    const cachedResponsesMapper = (widgetId) => {
        const reportsResponseMapper = {
            navigationStatus: {
                navigationData: overviewStorage.navigationStatusData,
                milesData: overviewStorage.navigationStatusMilesData
            },
            vesselLog: overviewStorage.vesselLogData,
            vesselRating: overviewStorage.vesselRating,
            fuelConsumpChart: overviewStorage.fuelConsumpChartData,
            fuelConsumpTotals: overviewStorage.fuelConsumpTotalsData,
            vesselMonitoringChart: overviewStorage.vesselMonitoringChartData
        }

        return reportsResponseMapper[widgetId];
    }

    const initOverview = () => {
        registerWidgetsStore.setFromTo(monitoringStore().fromTo);
        
        reportsComponents.filter(comp => comp.widgetId === 'fuelConsumpChart')[0].payload = licensing.lightCondition() ? fuelConsumpChartPayloadLight : fuelConsumpChartPayload;
        reportsComponents.filter(comp => comp.widgetId === 'fuelConsumpChart')[0].type = licensing.lightCondition() ? 'text' : 'basicChart';
        reportsComponents.filter(comp => comp.widgetId === 'fuelConsumpChart')[0].singleRequest = licensing.lightCondition() ? { value: true, externalURequest: false, overwriteHeader: 'get-highchart-period-report' } : { value: true, externalURequest: false };
        reportsComponents.filter(comp => comp.widgetId === 'fuelConsumpTotals')[0].payload = licensing.lightCondition() ? fuelConsumpFcrPayloadLight : fuelConsumpFcrPayload;
        reportsComponents.filter(comp => comp.widgetId === 'vesselMonitoringChart')[0].payload = licensing.lightCondition() ? vesselMonitoringChartPayloadLight : vesselMonitoringChartPayload;
        
        //setting timeGroup for Vessel's monitoring chart
        reportsComponents.filter(comp => comp.widgetId === 'vesselMonitoringChart')[0].payload.timeGroup = 
            ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR'

        // if overview storage has saved data, then use them and don't make new requests to get the data
        if(!overviewStorage.isGlobalStateEmpty()) {
            reportsComponents.map((comp) => {
                return reportsResponseMapper(cachedResponsesMapper(comp.widgetId), comp.widgetId);
            });
            return;
        }
        reportsService.registerReports([vesselStore().selectedVessel], reportsComponents, reportsResponseMapper, updateRequestOnGoing, true);
    }

    const handleCloseModal = () => {
        setModalShow(false);
        setTelegramsModalShow(false);
        setTimeout(() => { reportsService.registerReports([vesselStore().selectedVessel], reportsComponents.filter(comp => comp.widgetId === 'vesselLog'), reportsResponseMapper, updateRequestOnGoing, true, true) }, 1000);
    }

    useEffect(() => {
        if (!monitoringStore().fromToChanged) return;
        if (!isMounted) return;
       
        //reset fromToChanged redux var in order to trigger the next fromTo dates
        dispatch({ type: 'monitoring/setFromToChanged', payload: false });

        //invalid period if more than 3 months (91 days)
        if(monitoringStore().fromTo.to - monitoringStore().fromTo.from > 7862400000) return;

        // local storage needs to be initialized, since requests need to be made again for detailedData mode
        monitoringUtils.resetLocalMonitoringStores();

        return initOverview();
        // eslint-disable-next-line
    }, [monitoringStoreJSX.fromToChanged]);

    useEffect(() => {
        if (!isMounted) return;
    
        // local storage needs to be initialized, since requests need to be made again for detailedData mode
        monitoringUtils.resetLocalMonitoringStores();
    
        // eslint-disable-next-line
      }, [monitoringStoreJSX.detailedData]);

    useEffect(() => {
        isMounted = true;
        const controller = new AbortController();
        GlobalUtilities.signal = controller.signal;

        if (!monitoringStore().fromTo.from && !monitoringStore().fromTo.to) return;

        initOverview();
        
        return () => {
            controller.abort();
            isMounted = false;
            GlobalUtilities.signal = null;
        }
    // eslint-disable-next-line
    }, []);

    const showTelegramsOnlyModal = () => {
        setShowTelegramsOnly(true);
        setTelegramsModalShow(true);
    }

    const showModal = () => {
        setShowTelegramsOnly(false);
        setModalShow(true);
    }

    const modalProps = {
        editEvent: false,
        modalDate: moment().toDate(),
        overviewPage: true,
        telegramsOnly: showTelegramsOnly
    }

    const data = {
        vesselRating: {
            data: vesselRating,
            allowTelegrams: monitoringUtils.allowTelegrams(),
            loading: requestOnGoing.vesselRating,
            // isLight: monitoringStore.lightCondition()
        },
        fuelConsump: {
            fuelConsumpTotals: fuelConsumpTotals,
            chartData: fuelConsumpChartData,
            addEventProps: {
                // Modal properties - Start
                ...modalProps,
                show: telegramsModalShow,
                onHide: () => { handleCloseModal() },
                // CTA properties - Start
                action: () => showTelegramsOnlyModal(),
                label: 'Insert Data',
                allowTelegrams: monitoringUtils.allowTelegrams(),
                isLight: licensing.lightCondition(),
                classNameWrapper: 'marg-t-30',
                classNameCta: 'main-cta'
            },
            exportingChartTitle: 'FUEL CONSUMPTION OVERVIEW', 
            loading: requestOnGoing.fuelConsumpChart,
        },
        vesselMonitoringSyncedCharts: {
            data: vesselMonitoringChartData,
            exportingChartTitle: getTitlesFromWidgetIds('vesselMonitoringSyncedCharts'),
            loading: requestOnGoing.vesselMonitoringChart,
        },
        detailsAtSea: navigationStatus[0],
        detailsAtPort: navigationStatus[1],
        navigationStatus: {
            data: navigationStatusChartData,
            genericChartOptions: navigationStatusChartUtils,
            progressChartOptions: navigationStatusChartUtils.progressChartOptions(),
            loading: requestOnGoing.navigationStatus,
        },
        addEventsButton: {
            // Modal properties - Start
            ...modalProps,
            show: modalShow,
            onHide: () => { handleCloseModal() },
            // CTA properties - Start
            action: () => showModal(),
            label: 'Add Event',
            icon: AddIcon,
            classNameWrapper: 'addEvent col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9',
            classNameCta: 'cta2-btn',
            classNameCtaText: 'main-cta__text'
        },
        vesselLog: {
            items: vesselLogData,
            columns: columnDefs,
            itemIdentifier: 'id',
            component: TableComp,
            frameworkComponents: { eventTypeGroup: EventTypeGroup },
            iconName: 'eventsBigIcon',
            componentGrid: { xs: 12, sm: 12, md: 12, lg: 12, xl: 8 },
            iconGrid: { xs: 'hidden', sm: 'hidden', md: 'hidden', lg: 'hidden', xl: 4 },
            loading: requestOnGoing.vesselLog,
        }
    }

    return (
        <div className="overview">
            <div className="overview__widgets">
                <Layout {...createLayoutProps(data)} />
            </div>
        </div>
    );
}

export default MonitoringOverview;
