import Highcharts from 'highcharts';
import moment from 'moment';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);
const chartOptions = (decimals, zPointUnit) => {
    if(!decimals) decimals = '';
    if(!zPointUnit) zPointUnit = '';
    let options = {
		chart: {
            height: 60,
            width: 250,
            backgroundColor: 'transparent'
        },
        
        title: '',
        
        exporting: {
            enabled: false
        },
        tooltip: {
            formatter: function() {
                return (`
                <div class="flex-centered-col">
                    <div class="highcharts-fonts tooltip-title"><b>${`${moment(this.point?.datetime).utc().format('MMMM YYYY')}`}</b></div>
                    <div class="flex-centered-col tooltip-values" style="width: 100%;">
                        <div class="flex-space-between highcharts-fonts" id="${this.series.name}" style="width: 100%;">
                            <div class="flex-space-between">
                                <div class="tooltip-circle" style="background-color:${this.point.color};"></div>
                                <div style="margin-right: 24px;">${this.series.name}: &nbsp;</div>
                            </div>
                            <div><b>${this.point.y.toFixed(2)} mt</b></div>
                        </div>
                        <div class="highcharts-fonts" style=" text-align: left; width: 100%;">
                            <div style="margin-left: 12px;">${this.point.missingValues > 0 ? this.point.missingValues + '% missing data' : ''}</div> 
                        </div>
                    </div>
                </div>`)
            },
            useHTML: true,
            outside: true
        },
    
        yAxis: {
                enabled: false,
            title: {
            enabled: false,
                text: 'Number of Employees'
            },
            labels: {
                enabled: false,
            },
            gridLineColor: 'transparent',
        },
    
        xAxis: {
                enabled: false,
            accessibility: {
                rangeDescription: 'Range: 2010 to 2017'
            },
            labels: {
                enabled: false,
            },
            lineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            minorTickLength: 0,
            tickLength: 0
        },
    
        legend: {
                enabled: false,
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
    
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: 2010,
                marker: {enabled: false},
            }
        },
    
        series: []
    };
    return options;
}
export default chartOptions;