import React from 'react';
import Utilities from 'Utilities/global';

const SimpleInfoTable = props => {
    const maskNullValue = (value) => {
        return isNaN(value) ? value : Utilities.renderNumber(value);
    }
    const tableInfo = props.tableInfo ? props.tableInfo : props.data;
    return (
        <div className="simple-info-table inside-side-padding">
            {Object.keys(tableInfo).filter(key => props.tableInfoNamesMapper[key]).map( (key, index) => (
                <div key={key} className={`simple-info-table__group infoMonitoringTable flex-centered-start ${index%2 === 0 ? 'black' : 'grey'}`}>
                    <div className="form-description simple-info-table__group__key">{props.tableInfoNamesMapper[key]}</div>
                    <div className="small-label-light simple-info-table__group__value">{`${maskNullValue(tableInfo[key])} ${(props?.unit && maskNullValue(tableInfo[key]) !== '-') ? props.unit : ''}`}</div>
                </div>
            ))}
        </div>
    );
};
 
export default SimpleInfoTable;
