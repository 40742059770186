import React from 'react';
import Utils from 'Utilities/global';

const DoubleData = props => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            {props.firstPart && <div>
                <div className={props.firstPart?.classNames} style={props.firstPart?.extraStyle}>{Utils.renderNumber(Utils.roundToDigits( props.firstPart?.value, 2 ))} {props.firstPart?.unit} {props.firstPart?.icon}</div>
            </div>}

            {props.enableDevider && props.firstPart && props.secondPart && <div className={`double-data-devider`} style={props.deviderExtraStyle}></div>}

            {props.secondPart && <div>
                <div className={props.secondPart?.classNames}  style={props.secondPart?.extraStyle}>{Utils.renderNumber(Utils.roundToDigits( props.secondPart?.value, 2 ))} {props.secondPart?.unit }  {props.secondPart?.icon}</div>
            </div>}
        </div>
    );
}
 
export default DoubleData;