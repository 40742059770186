import React from 'react';
import {licensing} from "common/store/licensing";
import {useSelector} from 'react-redux';

const LegendsCompinent = () => {
    // store vars for JSX
    const reportsStoreJSX = useSelector(state => state.reports);

    return (
        <div className="flex-centered-start full-height full-width padding-left-right-15">
            <div className="headline2">LEGEND:</div> 
            <div className={`${licensing.lightCondition() ? 'ais-tile' : 'sensor-tile'}`} style={{flexShrink: 0}}></div>
            <div className="headline2">{licensing.lightCondition() ? 'AIS Data' : 'Sensor Data'}</div>
            {reportsStoreJSX.isTelegramConfigured && 
            <>
                <div className="telegram-tile" style={{flexShrink: 0}}></div>
                <div className="headline2">Reports Data</div>
            </> }
        </div>
    );
}   
 
export default LegendsCompinent;