import React, { useState, useEffect } from 'react';
import pieChartOptions from 'components/charts/pieChart/pieChartOptions';
import PieChart from 'components/charts/pieChart/pieChart';

const CircleProgressChart = props => {

    const [pieChart, setPieChart] = useState(pieChartOptions());

    useEffect(() => {
        if(!props.progressChartOptions) return;

        let options = pieChartOptions();

        const {
            width, insideText, style, titleY, innerSize, colors, backColor,
            load, telegramPercentage, totalCO2, totalFuel, totalMiles, y
        } = props.progressChartOptions;

        options.chart.width = width;
        options.chart.height = width;
        options.title.text = insideText;
        options.title.style = style;
        options.title.y = titleY;
        options["series"].innerSize = innerSize || '93%';
        options.exporting.enabled = false;

        if(y) {
            options.series[0].data = [{
                y: telegramPercentage ,
                color: colors[0] ,
                totalCO2: totalCO2,
                totalFuel: totalFuel,
                totalMiles: totalMiles,
            }, {
                y: 100-telegramPercentage,
                color: backColor,
                totalCO2: totalCO2,
                totalFuel: totalFuel,
                totalMiles: totalMiles,
            }];
            options.series[0].states = {
                hover: {enabled : false, brightness: 1},
                inactive: {enabled : false},
                select: {enabled : false}
            }
        }
        if(props?.progressChartOptions?.load) {
            options.series[0].data = [{
                y: load * 2,
                color: colors[0]
            }, {
                y: 100,
                color: backColor,
            }];
        }
        if(props?.progressChartOptions?.withTooltip) {
            options.tooltip = {
                enabled: true,
                outside: true,
                useHTML: true,
                borderColor: colors[0],
                padding: 0,
                style: {
                    width: 'auto',
                    maxWidth: 'unset',
                },
                ...props?.progressChartOptions?.tooltip,
            };
        }
        
        setPieChart(options);
    }, [props.progressChartOptions]);


    return (
        <PieChart {...pieChart} />
    );
}

export default CircleProgressChart;
