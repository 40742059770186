import React from 'react';
import ModelDetailsUtils from 'components/modelDetails/modelDetailsUtils';
import PropTypes from 'prop-types';

const ArbitraryPvFvFooter = ({ curvesData }) => {
    return(
        <div className='generalWidgetFooter arbitraryPvFvFooter flex-centered-start small-label'>
            <div className='marg-r-10'>
                Data Status:
            </div>
            {
                Object.keys(curvesData).map((curveKey, index) => 
                    <div key={index} className='arbitraryPvFvFooter-info flex-centered'>
                        <div className='arbitraryPvFvFooter-info__category flex-centered-start'>
                            <div>{ModelDetailsUtils.getInfoOfGivenConfidenceLevel(curveKey)?.icon}</div>
                            <div className="arbitraryPvFvFooter-info__category__title marg-l-5 marg-r-5" style={ModelDetailsUtils.getInfoOfGivenConfidenceLevel(curveKey)?.style}>
                                {ModelDetailsUtils.getInfoOfGivenConfidenceLevel(curveKey)?.text}:
                            </div> 
                        </div>
                        <div className='arbitraryPvFvFooter-info__titles marg-l-5'>
                            {curvesData[curveKey].join(', ')}
                        </div>
                        {
                            (index < Object.keys(curvesData).length - 1)
                                && <div className='separator marg-l-10 marg-r-10' />
                        }
                    </div>   
                )
            }
        </div>  
    )
}

ArbitraryPvFvFooter.propTypes = {
    curvesData: PropTypes.shape({})
}

export default ArbitraryPvFvFooter;