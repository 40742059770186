import React, { useState, useEffect } from "react";
import SyncCharts from 'components/charts/genericSyncCharts/syncCharts';
import syncChartsOptions from 'components/charts/genericSyncCharts/syncChartsOptions';
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';

const WeatherMonitoring = (props) => {
    const [syncCharts, setSyncCharts] = useState(syncChartsOptions());
    let options = syncChartsOptions();

    const colorMapper = (severity, value, borderColor) => {
        if(value < 0 && !borderColor) return 'transparent';

        if( (!value && value !== 0) || value >= 0 || (value < 0 && borderColor) ) {
            if(severity === 'NONE' || !severity) return ThemeColorsForCharts()['main_blue'];
            if(severity === 'LOW') return ThemeColorsForCharts()['low_severity'];
            if(severity === 'MEDIUM') return ThemeColorsForCharts()['medium_severity'];
            if(severity === 'HIGH') return ThemeColorsForCharts()['high_severity'];
        }
    }

    const buildSerieDataWithAnomaliesColors = serieName => {
        const serieObj = JSON.parse(JSON.stringify(props.chartData?.series))
        const Serie = serieObj.find(serie => serie.name === serieName);

        if(!Serie.data) return [];
        Serie.data = Serie.data?.map( (point, index) => {

            // Find the severity at that particular x datetime
            const indexPoint = props.anomaliesData.filter(el => el.x === point.x)

            return {
                ...point,
                color: colorMapper(indexPoint[0]?.severity, point.y),
                borderColor: colorMapper(indexPoint[0]?.severity, null, true) }
        });

        return Serie?.data;
    }

    useEffect(() => {
        if(!props.chartData || !props.anomaliesData || Object.keys(props.anomaliesData).length === 0) return;

        options["yAxis"] = props.chartData.yAxis;
        options["series"] = props.chartData.series;
        options.chart.height = 610;
        options.defaultZoom = 1;
        options.elementId = 'weather-monitoring';

        options.series.find(serie => serie.name === "Wind").data = buildSerieDataWithAnomaliesColors('Wind');
        options.series.find(serie => serie.name === "WindArrows").data = buildSerieDataWithAnomaliesColors('WindArrows');

        setSyncCharts({...options});

    // eslint-disable-next-line
    }, [props]);

    useEffect(() => {
        if (!options?.series || !props.anomaliesData) return;
        options.series.find(serie => serie.name === "Wind").data = buildSerieDataWithAnomaliesColors('Wind');
        options.series.find(serie => serie.name === "WindArrows").data = buildSerieDataWithAnomaliesColors('WindArrows');

        setSyncCharts({...options});

        // eslint-disable-next-line
    }, [props.anomaliesData])

    return (
        <div className="pad-r-15"><SyncCharts options={syncCharts} /></div>
    );
}
export default WeatherMonitoring;
