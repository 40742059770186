import hullStorage from 'components/monitoringCategories/localStorages/hullStorage';
import chartOptions from "components/charts/lineChart/react-lineChart";
import Wind from 'Utilities/wind';
import chartUtils from 'Utilities/charts';


let predictedData = [];

const powerMonitoringPayload = { 
    xAxis: "TIME",
    aggregation: "AVG",
    timeGroup: 'HOUR',  
    metrics: [
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                { metricName: "rpm" },
                {metricName: "power"}
            ]
        },
        { 
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                { metricName: "stw_calc" },
                { metricName: "speed_overground" },
                { metricName: "apparent_wind_speed_calc" },
                { metricName: "apparent_wind_direction_calc" },
                { metricName: "apparent_wind_speed" },
                { metricName: "apparent_wind_angle" },
                { metricName: "true_wind_speed_calc" },
            ]
        }
    ]
};

const powerMonitoringPredictedPayload = {
    predictionCategory: "POWER",
    timeGroup: "HOUR",
    aggregation: "AVG",
    metrics: ["mean_prediction_nhf"]
}

const setPowerPredictedData = data => {
    if(!data?.length) return predictedData = [];
    predictedData = JSON.parse(JSON.stringify(data[0].values || []));
}

const metricsAndPositions = [
    {
        metricName: 'power',
        position: 0
    },
    {
        metricName: 'stw_calc',
        position: 1
    },
    {
        metricName: 'speed_overground',
        position: 2
    },
    {
        metricName: 'rpm',
        position: 5
    }
]

const findWindDirection = (data, series) => {
    let windDirection = data[0].values.find((keyGp) => keyGp.metricName === 'apparent_wind_direction_calc');
    if(Wind.noWindData(windDirection)) windDirection = data[0].values.find((keyGp) => keyGp.metricName === 'apparent_wind_angle');
    series[4].data = windDirection ? windDirection?.value : [];
}

const findMetric = (data, metric, series) => {
    const metricData = data[0].values.find((keyGp) => keyGp.metricName === metric.metricName);
    series[metric.position].data = metricData ? metricData.value : [];
    series[metric.position].unit = metricData ? metricData.unit : [];
}

const findAllMetrics = (response, series) => {
    // find values for wind speed and wind direction
    Wind.findWindSpeedWithPriority(response, ['apparent_wind_speed_calc', 'apparent_wind_speed', 'true_wind_speed_calc'], series, 3);

    findWindDirection(response, series);

    metricsAndPositions.forEach((metric) => {  // find values for the rest of the metrics
        findMetric(response, metric, series);
    });
}

const updatePowerMonitoringJSON = (id, response, updateState, extraChartConfigs, widget) => {
    hullStorage.setPowerMonitoringData(response);
    if(response?.length === 0) return updateState(id, {}, widget?.id);

    const series = [{},{},{},{},{},{}];

    let powerMonitoringChartJSON = { chartData: {...chartOptions()} };

    // restore the previous yAxis title options for this chart specifically (it will be removed after future chart redesign to sync-chart)
    const oldLeftTitlesOptions = { align: 'middle', reserveSpace: true, rotation: 270, x: 0, y: 30 }; 
    const oldRightTitlesOptions = { align: 'middle', reserveSpace: true, rotation: -270, x: 0, y: -25 }; 

    if(response[0] && response[0].values) {
        findAllMetrics(response, series);
        if(series[3]?.data?.length > 0) series[3].data = series[3].data.map((arr, index) => {
            const direction = series[4]?.data?.length > 0 ? series[4].data[index].y : '';
            return {
                x: arr.x,
                y: Wind.knotsToBeauforts(arr.y) === '-' ? null : Wind.knotsToBeauforts(arr.y),
                z: Wind.convertApparentWindAngle(direction) === '-' || direction?.length === 0 ? '' : ` (${Wind.convertApparentWindAngle(direction)}) `
            };
        });
        powerMonitoringChartJSON.chartData.chart = {...powerMonitoringChartJSON.chartData.chart, id: id};
        powerMonitoringChartJSON.chartData.yAxis = [
            {id: 'speed-axis', title: {text: 'STW (kn)', ...oldLeftTitlesOptions}, min: 0, tickPixelInterval: 150, opposite: false},
            {id: 'wind-axis', title: {text: 'WIND (BF)', ...oldRightTitlesOptions}, min: 0, tickPixelInterval: 150, opposite: true, labels: {x: 16}},
            {id: 'power-axis', title: {text: "FCR (kg/min)", ...oldLeftTitlesOptions}, min: 0, tickPixelInterval: 150, opposite: false},
            {id: 'rpm-axis', title: {text: 'RPM', ...oldRightTitlesOptions, y: -15}, min: 0, tickPixelInterval: 150, opposite: true, labels: {x: 16}}
        ];

        powerMonitoringChartJSON.chartData.series = [         
            {name: 'Actual Power', data: series[0].data, tooltipTitle: 'Actual Power', yAxis: 'power-axis', 
                showInLegend: series[0]?.data?.length, titleSuffix:'(UTC)', yMax: 35000, yMin: 0,
                tooltip: {valueSuffix: ' Kw', valueDecimals: 2}},
            {name: 'Cleanest State Power', data: predictedData, tooltipTitle: 'Cleanest State Power', yAxis: 'power-axis', 
                showInLegend: predictedData?.length, titleSuffix:'(UTC)', yMax: 35000, yMin: 0, 
                tooltip: {valueSuffix: ' Kw', valueDecimals: 2}},
            {name: 'STW', data: series[1].data, tooltipTitle: 'STW', 
                showInLegend: series[1]?.data?.length, titleSuffix:'(UTC)', yMax: 50, yMin: 0, yAxis: 'speed-axis', 
                tooltip: {valueSuffix: ' Kn', valueDecimals: 2}},
            {name: 'SOG', data: series[2].data, tooltipTitle: 'SOG', 
                showInLegend: series[2]?.data?.length, titleSuffix:'(UTC)', yMax: 50, yMin: 0, yAxis: 'speed-axis', 
                tooltip: {valueSuffix: ' Kn', valueDecimals: 2}},
            {name: 'Wind Speed', data: series[3].data, tooltipTitle: 'Wind Speed', 
                showInLegend: series[3]?.data?.length, titleSuffix:'(UTC)', yMin: 0, yAxis: 'wind-axis', 
                tooltip: {valueSuffix: ` Bf`, valueDecimals: 0}},
            {name: 'RPM', data: series[5].data, tooltipTitle: 'RPM', 
                showInLegend: series[5]?.data?.length, titleSuffix:'(UTC)', yMax: 120, yMin: 0, yAxis: 'rpm-axis', 
                tooltip: {valueSuffix: ' RPM', valueDecimals: 2}},
        ];

        powerMonitoringChartJSON.chartData.tooltip.borderColor = chartUtils.tootlipBorderColor.mixedData();

        const seriesNotEmpty = powerMonitoringChartJSON.chartData.series.find((serie) => serie?.data?.length > 0);
        if(!seriesNotEmpty) powerMonitoringChartJSON.chartData = {};

        if (extraChartConfigs && extraChartConfigs.plotLines) {
            let plotGuides = extraChartConfigs.plotLines.setReportsPlotLineGuides(response);
            if (plotGuides?.xAxis) {
                powerMonitoringChartJSON.chartData.xAxis = Object.assign({}, powerMonitoringChartJSON.chartData.xAxis, plotGuides.xAxis);
            }
        }
    };

    updateState(id, powerMonitoringChartJSON.chartData, widget?.id);
}

export { powerMonitoringPayload, powerMonitoringPredictedPayload, updatePowerMonitoringJSON, setPowerPredictedData };
