import moment from "moment";
import {ReactComponent as DrydockIcon} from 'assets/img/app/global-imgs/cleaning-event-dry.svg';
import {ReactComponent as SeaEventIcon} from 'assets/img/app/global-imgs/sea-events.svg';
import { baseFormUtils } from 'common/store/storeUtils';
import Group from "components/core/Forms/group";

const cleaningSchema = (editMode) => {
    return [
        {
            maintenanceType: {
                type: "iconSegmentButtons",
                label: "Maintenance Type",
                group: 'General',
                options: [
                    {value: 'drydock', label: 'Drydock', iconComponent: DrydockIcon},
                    {value: 'sea', label: 'Sea', iconComponent: SeaEventIcon}
                ],
                value: '',
                validation: {
                    required: true,
                    disabled: {
                        always: editMode
                    }
                },
                cssClasses: {
                    wrapper: '',
                    label: 'form-text',
                    element: 'ds-input',
                    error: 'form-error'
                }
            },
            dateFrom: {
                type: 'datePicker',
                label: 'Start Day',
                group: 'General',
                viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                value: '',
                validation: {
                    required: true
                },
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                    if (value === null) {
                        setFieldValue("dateFrom", "");
                    }
                }
            },
            dateTo: {
                type: 'datePicker',
                label: 'End Day',
                group: 'General',
                viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                value: '',
                validation: {
                    required: true,
                    greaterThan: 'dateFrom'
                },
                onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                    if (value === null) {
                        setFieldValue("dateTo", "");
                    }
                }
            },
            conductor: {
                type: 'text',
                label: 'Conductor',
                group: 'General',
                value: ''
            },
            port: {
                type: 'autocomplete',
                portsAutocomplete: true,
                label: 'Port',
                group: 'General',
                value: '',
                onChange: ({ values, value, parentValues, parentName, setFieldValue, setFieldTouched }) => {
                    if( value !== null ) {
                        setFieldValue("port", value.port);
                    } else {
                        setFieldValue("port", '');
                    }
                },
            },
        },
        {
            operations: {
                type: 'array',
                label: {
                    title: '',
                    index: 'Maintenance',
                    add: 'Add Maintenance',
                },
                viewports: {xs: 12, sm: 12, md: 6, lg: 6, xl: 6},
                structure: {
                    location: {
                        type: "segmentButtons",
                        label: "",
                        canRenderLabel: false,
                        options: [
                            {value: 'ME', label: 'Main Engine'},
                            {value: 'GE', label: 'Generator Engine'},
                            {value: 'HULL', label: 'Hull'}
                        ],
                        onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                            if (value !== '') {
                                setFieldValue(`${parentName}.system`, '');
                                setFieldValue(`${parentName}.part`, '');
                                setFieldValue(`${parentName}.action`, '');
                                setFieldValue(`${parentName}.otherAction`, '');
                                setFieldTouched(`${parentName}.system`, false);
                                setFieldTouched(`${parentName}.part`, false);
                                setFieldTouched(`${parentName}.action`, false);
                                setFieldTouched(`${parentName}.otherAction`, false);
                            }
                        },
                        value: '',
                        validation: {required: true}
                    },
                    system: {
                        type: "select",
                        label: "System",
                        options: [
                            {
                                location: ['ME'],
                                options: [
                                    {value: 'shaft', label: 'Shaft System'}
                                ]
                            },
                            {
                                location: ['GE'],
                                options: [
                                    {value: 'generator', label: 'Generator System'}
                                ]
                            },
                            {value: 'cooling', label: 'Cooling water System'},
                            {value: 'air', label: 'Control Air System'},
                            {value: 'lubrication', label: 'Lubrication System'},
                            {value: 'oil', label: 'Fuel Oil System'},
                            {value: 'pistons', label: 'Pistons'},
                            {value: 'tc', label: 'Turbo Chargers'}
                        ],
                        onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                            if (value !== '') {
                                setFieldValue(`${parentName}.part`, '');
                                setFieldValue(`${parentName}.action`, '');
                                setFieldValue(`${parentName}.otherAction`, '');
                                setFieldTouched(`${parentName}.part`, false);
                                setFieldTouched(`${parentName}.action`, false);
                                setFieldTouched(`${parentName}.otherAction`, false);
                            }
                        },
                        value: '',
                        showOn: {
                            location: ['ME', 'GE']
                        },
                        validation: {required: true}
                    },
                    part: {
                        type: "select",
                        label: "Part",
                        options: [
                            {
                                location: ['HULL'],
                                options: [
                                    {value: 'propeller', label: 'Propeller'},
                                    {value: 'bowThruster', label: 'Bow Thruster'},
                                    {value: 'seaChest', label: 'Sea Chest'},
                                    {value: 'fullHull', label: 'Full Hull'},
                                ]
                            },
                            {
                                location: ['HULL'],
                                helper: ['sea'],
                                options: [
                                    {value: 'hullSide', label: 'Hull Side'},
                                    {value: 'hullBottom', label: 'Hull Bottom'},
                                ]
                            },
                            {
                                location: ['HULL'],
                                helper: ['drydock'],
                                options: [
                                    {value: 'bulb', label: 'Bulb'},
                                ]
                            },
                            {
                                system: ['shaft'],
                                options: [
                                    {value: 'thrustBearing', label: 'Thrust Bearing'},
                                    {value: 'torquemeter', label: 'Torquemeter'},
                                    {value: 'crankShaftBearing', label: 'Crank shaft Bearing'},
                                    {value: 'camshaft', label: 'Camshaft'},
                                ]
                            },
                            {
                                system: ['cooling'],
                                options: [
                                    {value: 'pumps', label: 'Pumps'},
                                    {value: 'generator', label: 'Fresh Generator'},
                                    {value: 'preheater', label: 'Preheater'},
                                    {value: 'aircooler', label: 'Air Cooler'},
                                ]
                            },
                            {
                                system: ['air'],
                                options: [
                                    {value: 'compressors', label: 'Compressors'},
                                    {value: 'air', label: 'Air Start Valve'},
                                    {value: 'receiver', label: 'Receiver'},
                                    {value: 'exhaust', label: 'Exhaust Valve'},
                                ]
                            },
                            {
                                system: ['lubrication'],
                                options: [
                                    {value: 'filters', label: 'Filters'},
                                    {value: 'pumps', label: 'Pumps'},
                                ]
                            },
                            {
                                system: ['oil'],
                                options: [
                                    {value: 'pumps', label: 'Pumps'},
                                    {value: 'heater', label: 'Heater'},
                                    {value: 'injector', label: 'Injector'},
                                ]
                            },
                            {
                                system: ['pistons'],
                                options: [
                                    {value: 'seal', label: 'Seal'},
                                    {value: 'pin', label: 'Pin'},
                                    {value: 'bearing', label: 'Bearing'},
                                ]
                            },
                            {
                                system: ['tc'],
                                options: [
                                    {value: 'turbine', label: 'Turbine'},
                                    {value: 'compressor', label: 'Compressor'},
                                    {value: 'mainShaft', label: 'Main Shaft'},
                                    {value: 'bearing', label: 'Bearing'},
                                ]
                            },
                            {
                                system: ['generator'],
                                options: [
                                    {value: 'winding', label: 'Winding'}
                                ]
                            }
                        ],
                        onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                            if (value !== '') {
                                setFieldValue(`${parentName}.action`, '');
                                setFieldValue(`${parentName}.otherAction`, '');
                                setFieldTouched(`${parentName}.action`, false);
                                setFieldTouched(`${parentName}.otherAction`, false);
                            }
                        },
                        value: '',
                        showOn: {
                            location: ['ME', 'GE', 'HULL']
                        },
                        validation: {
                            required: true,
                            disabled: {
                                location: ['ME', 'GE'],
                                system: []
                            }
                        }
                    },
                    action: {
                        type: "select",
                        label: "Action",
                        options: [
                            {
                                location: ['ME', 'GE'],
                                options: [
                                    {value: 'overhauling', label: 'Overhauling'},
                                    {value: 'assembling', label: 'Assembling'},
                                    {value: 'tightening', label: 'Tightening'},
                                    {value: 'calibration', label: 'Calibration'},
                                ]
                            },
                            {
                                location: ['ME', 'GE'],
                                helper: ['drydock'],
                                options: [
                                    {value: 'clearance', label: 'Clearance'},
                                ]
                            },
                            {
                                part: ['seaChest', 'hullSide', 'hullBottom', 'fullHull', 'bulb'],
                                options: [
                                    {value: 'cleaning', label: 'Cleaning'},
                                ]
                            },
                            {
                                part: ['propeller', 'bowThruster'],
                                options: [
                                    {value: 'polishing', label: 'Polishing'},
                                ]
                            },
                            {
                                location: ['HULL'],
                                helper: ['drydock'],
                                options: [
                                    {value: 'rePainting', label: 'Re-Painting'},
                                ]
                            },
                            {
                                helper: ['drydock'],
                                part: ['propeller', 'bowThruster', 'seaChest', 'bulb'],
                                options: [
                                    {value: 'overhauling', label: 'Overhauling'},
                                ]
                            },
                            {value: 'other', label: 'Other Action'}
                        ],
                        onChange: ({values, value, parentValues, parentName, setFieldValue, setFieldTouched}) => {
                                setFieldValue(`${parentName}.otherAction`, '');
                                setFieldTouched(`${parentName}.otherAction`, false);
                        },
                        value: '',
                        showOn: {
                            location: ['ME', 'GE', 'HULL']
                        },
                        validation: {
                            required: true,
                            disabled: {
                                part: []
                            }
                        }
                    },
                    otherAction: {
                        type: 'text',
                        label: 'Other Action',
                        value: '',
                        validation: {
                            required: true
                        },
                        showOn: {
                            action: ['other']
                        }
                    }
                }
            },
            helper: {
                type: 'text',
                label: 'helper',
                value: '',
                showOn: false
            }
        }
    ];
};

const cleaningValues = (props, editMode, dateClicked) => {
    return [
        {
            maintenanceType: props.maintenanceType || '',
            dateFrom: editMode ? moment.utc(props.dateFrom).set({'hour': 12}) : moment(dateClicked).set({'hour': 12}).utc(),
            dateTo: editMode ? moment.utc(props.dateTo).set({'hour': 12}) : moment(dateClicked).set({'hour': 12}).utc(),
            conductor: props.conductor || '',
            port: props.port || ''
        },
        {
            helper: props.maintenanceType || '',
            operations: !props.hasOwnProperty('operations') || props.operations.length === 0
                ? [baseFormUtils.createValuesFromSchema(cleaningSchema(editMode)[1].operations.structure)]
                : props.operations.map(operation => baseFormUtils.createValues(operation, cleaningSchema(editMode)[1].operations.structure))
        }
    ]
};

const cleaningGroups = [
    {
        'General': {
            ...Group,
            viewports: {xs: 12, sm: 6, md: 6, lg: 6, xl: 6},
            paddingViewports: "none",
            renderLabel: false,
            className: ''
        }
    },
    {}
];

export {cleaningSchema, cleaningValues, cleaningGroups};