import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as ExpandViewIcon } from 'assets/img/app/global-imgs/expandViewIcon.svg';
import {
    getTableData,
    getTableTitle
} from 'components/monitoringCategories/hullComponents/hullPerformanceTableConf';
import TableSubColumns from "components/table/tableSubColumns/tableSubColumns";

const HullPerformanceTable = ({ items, modalView, setShowModal, widgetTitle, selectedOptions }) => {
    
    const getFooterClasses = (modalView) => {
        return classnames(
            'footerComponent',
            {
                'flex-space-between': !modalView, 
                'flex-centered-end': modalView
            }
        );
    }

    const tableData = items?.length && getTableData(items, selectedOptions)

    return(
        <div className={'hullPerformanceTable h-100 padding-left-right-15'}>
            <div className="tableComponent">
                {tableData
                    ? <TableSubColumns columns={getTableData(items, selectedOptions)}
                                       tableTitle={getTableTitle(selectedOptions, widgetTitle)}
                                       wrapperStyle={{width: '100%', height: modalView ? '100%' : '340px'}}
                    />
                    :
                    <div className='h-100 d-flex justify-content-center align-items-center'>No data available</div>}
            </div>
            <div className={getFooterClasses(modalView)}>
                {
                    !modalView
                        && <div className='footerComponent-leftSide flex-centered marg-l-15' onClick={() => setShowModal(true)}>
                            <div className='footerComponent-leftSide-title marg-r-10'>
                                Expand View
                            </div>
                            <ExpandViewIcon className='svg-path' />
                        </div>
                }
                <div className='flex-centered'>
                    <div>
                        Confidence Level:
                    </div>
                    <div className='flex-centered marg-l-20 marg-r-5'>
                        <div className='flex-centered'>
                            <div className='confidenceLevelBar lowLevel marg-r-5'/>
                            <span> Low </span>
                        </div>
                        <div className='flex-centered marg-l-15'>
                            <div className='confidenceLevelBar highLevel marg-r-5'/>
                            <span> High </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

HullPerformanceTable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})),
    modalView: PropTypes.bool,
    setShowModal: PropTypes.func,
    widgetTitle: PropTypes.string,
    selectedOptions: PropTypes.object
}

export default HullPerformanceTable;