import React from 'react';
import SimpleInfoTable from "components/table/simpleInfoTable/simpleInfoTable";
import { mainEngineInfoNamesMapper } from 'widgets/mainEngine/meInfoTable';

const mainEngineInfo = (props) => {
    return(
        <div>
            {props.data !== "" && (
                    <SimpleInfoTable tableInfo={props.data} tableInfoNamesMapper={mainEngineInfoNamesMapper} />
            )}
        </div>
    )
}
 
export default mainEngineInfo;