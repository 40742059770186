import React from 'react';
import PropTypes from 'prop-types';

const SourceInfoLabel = ({text, icon}) => {
    return (
        <div className="sourceInfoLabel">
            <div>
                {text}
            </div>
            {
                icon 
                && <div className='marg-l-10'> 
                        {icon} 
                    </div>
            }
        </div>
    );
}

SourceInfoLabel.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.element
}
 
export default SourceInfoLabel;