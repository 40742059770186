import React from 'react';
import {ReactComponent as VesselIcon} from "assets/img/app/dashboard-imgs/vesselIcon.svg";
import {ReactComponent as GroupIcon} from "assets/img/app/dashboard-imgs/groupIcon.svg";
import Tooltip from "../muiHTMLTooltip/muiTooltip";
import {ReactComponent as EditIcon} from "../../assets/img/app/global-imgs/edit.svg";
import {ReactComponent as DeleteIcon} from "../../assets/img/app/global-imgs/delete.svg";
import {ReactComponent as ConductorIcon} from 'assets/img/app/timeline-imgs/conductor.svg';
import {userStore, vesselGroupUtils, vesselUtils, dashboardUtils} from 'common/store/storeUtils';

const DynamicWidgetTitle = props => {

    const displayLevel = level => {
        let icon, label, className;

        if (level === 'GROUP') {
            icon = <GroupIcon className={`widget-icon-colors color-${dashboardUtils.getWidgetIndex(props?.vesselIds, level)}`} />;
            label = vesselGroupUtils.getVesselGroupNameFromVesselId(props.vesselIds).toUpperCase()
        } else if (level === 'VESSEL') {
            icon = <VesselIcon className={`widget-icon-colors color-${dashboardUtils.getWidgetIndex(props?.vesselIds[0], level)}`} />;
            label = vesselUtils.getVesselName(props.vesselIds[0]);
        } else if (level === 'COMPANY') {
            icon = <ConductorIcon />;
            label = (userStore().user?.company?.name).toUpperCase();
            className = "company-icon";
        }

        return {icon, label, className}
    }

    return (
        <React.Fragment>
            <div className="d-flex flex-column">
                <div>{props?.component?.props?.customTitleFunc ? props?.component?.props?.customTitleFunc(props?.component?.props?.data) : props.name}</div>
                <div className="d-flex align-items-center flex-row">
                    <div className={displayLevel(props.level).className}>
                        {displayLevel(props.level).icon}
                    </div>
                    <div className="table-label-element text-center">{displayLevel(props.level).label}</div>
                </div>
            </div>
            {props.editMode && <div className="d-flex">
                <div className="action-button flex-centered" onClick={() => props.handleEditClick(props)}>
                    <Tooltip title="Edit"
                             component={<EditIcon className="svg-path-fill-darkGray action editAction"/>}/>
                </div>
                <div className="action-button flex-centered" onClick={() => props.handleDeleteClick(props)}>
                    <Tooltip title="Delete"
                             component={<DeleteIcon className="svg-path-fill-darkGray action deleteAction"/>}/>
                </div>
            </div>}
        </React.Fragment>
    )
}

export default DynamicWidgetTitle;
