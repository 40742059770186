import React from 'react';
import GetImageComponent from 'components/getImageComponent/getImageComponent';
import CircularIconWrapper from 'components/circularIconWrapper/circularIconWrapper';
import LabelWithIconHorizontal from 'components/labels/iconWithTextLabel/labelWithIconHorizontal';

const EventTypeGroup = (props) => {

    const evetTypeColorsMapper = {
        DTELEGRAM: 'amaranth',
        ATELEGRAM: 'tomato',
        OTELEGRAM: 'cerulean',
        NTELEGRAM: 'green_bay',
        VESSEL_MAINTENANCE: 'curious_blue',
        VESSEL_CHARTER_AGREEMENTS: 'carrot_orange',
        VESSEL_BUNKERING: 'violet',
        VESSEL_MACHINERY_DAMAGES: 'ruby',
    }

    return (
        <LabelWithIconHorizontal 
            data={{
                text: props.data.niceType ? props.data.niceType : props.data.type,
                icon: <CircularIconWrapper 
                    icon={<GetImageComponent imageName={props.data.originalType} />}
                    color={`var(--${evetTypeColorsMapper[props.data.originalType]})`}
                    extraClasses='bigCircularWrapper'
                />
            }}
        />
    );
}
 
export default EventTypeGroup;
