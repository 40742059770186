class BaseException {
    constructor({json = {}, message = "", status = -1, statusText = ""})
    {
        this.json = json;
        this.message = message;
        this.status = status;
        this.statusText = statusText;
    }
}

const Unauthorized = class extends BaseException {};

const ServerError = class extends BaseException {};

const UnknownError = class extends BaseException {};

const NotFound = class extends BaseException {};

const BadRequest = class extends BaseException {};

const BadResponse = class extends BaseException {};

export {
    BaseException,
    Unauthorized,
    NotFound,
    ServerError,
    UnknownError,
    BadRequest,
    BadResponse
};