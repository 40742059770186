import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const AlertStatusLabel = ({status}) => {
    const getAlertStatusLabelClasses = (status) => {
        return classnames(
            'alertStatusLabel', 
            {'resolvedStatus': status === 'RESOLVED'}
        );
    }

    return (
        <div className={getAlertStatusLabelClasses(status)}>
            {status}
        </div>
    );
}

AlertStatusLabel.propTypes = {
    status: PropTypes.string
}
 
export default AlertStatusLabel;