import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const LineChart = (props) => {
    return (
        <HighchartsReact options={props.data} highcharts={Highcharts} />
    );
}
 
export default LineChart;