/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import AutocompleteField from "../core/AutocompleteField";
import service from 'common/js/service';

const PortsAutocompleteField = (props) => {

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const asyncCall = (event, newInput) => {
        setLoading(true);
        if (!newInput) {
            setOptions([]);
            return setLoading(false);  
        } else {
            service.getPorts(newInput).then(result => {
                setLoading(false);
                setOptions(result);
            })
        }
    }

    const renderPortOptions = option => {
        return (
            <div className="flex-centered-col-start portsList">
                <div className="portsList__title autocomplete-title">{option.port}</div>
                <div className="portsList__subtitle autocomplete-subtitle">{option.country}</div>
            </div>
        )
    }

    const autocompleteProps = {
        autocompleteOptions: options,
        noOptionsText: 'No ports available',
        loading: loading,
        value: props.value
    }

    const filterOptions = {
        matchFrom: 'start',
        stringify: option => option.port,
        limit: 10
    };

    useEffect(() => {
        return () => {
            setLoading(false);
            setOptions(null);
        }
    }, [])

    return (
        <AutocompleteField
            {...props}
            {...autocompleteProps}
            getOptionLabel={option => option && option.port}
            getOptionSelected={(option, value) => option.port === value.port}
            onInputChange={(event, newInputValue) => {
                asyncCall(event, newInputValue);
            }}
            renderOption={(option) => {
                return renderPortOptions(option);
            }}
            filterOptions={filterOptions}
        />
    );

};

export default PortsAutocompleteField;