import React, {useEffect, useState} from "react";
import ReportsService from "../../common/js/reports";
import Layout from 'components/core/Layout';
import Highcharts from "highcharts";
import chartOptions from "components/charts/lineChart/react-lineChart";
import hullStorage from './localStorages/hullStorage';
import {updateFoulingWaste, setWasteTones, foulingWastePayload} from "widgets/vesselData/foulingWaste.js";
import {powerMonitoringPayload, powerMonitoringPredictedPayload, updatePowerMonitoringJSON, setPowerPredictedData} from 'widgets/vesselData/powerMonitoring';
import {getFoulingPenaltyTitle} from 'widgets/vesselData/foulingPenalty.js';
import { updateFoulingPenaltyOvertime, getFoulingPenaltyOvertimeTitle } from 'widgets/vesselData/foulingPenaltyOvertime.js';
import HullPerformance from './hullComponents/hullPerformance';
import HullFoulingPenalty from './hullComponents/hullFoulingPenalty';
import HullFoulingPenaltyOvertime from './hullComponents/hullFoulingPenaltyOvertime';
import HullFoulingPenaltyFooter from '../footers/dynamicFooters/hullFoulingPenaltyFooter.jsx';
import HullFoulingPenaltyOvertimeFooter from '../footers/dynamicFooters/hullFoulingPenaltyOvertimeFooter.jsx'
import FoulingWaste from 'widgets/vesselData/foulingWaste.jsx';
import PanelHeaderWithTag from 'components/widgetPanel/panelHeaderWithTag';
import {licensing} from "common/store/licensing";
import HullPowerMonitoring from "./hullComponents/hullPowerMonitoring";
import registerWidgetsStore from 'common/store/registerWidgetsStore';
import { monitoringStore, vesselStore, monitoringUtils, vesselUtils } from 'common/store/storeUtils';
import { useDispatch, useSelector } from 'react-redux';
import service from 'common/js/service';
import GlobalUtilities from "Utilities/global";
import HullPerformanceHeaderInfoIcon from 'components/monitoringCategories/hullComponents/hullPerformanceHeaderInfoIcon';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

let isMounted = false;

const Hull = () => {
  const reportsService = new ReportsService();
  const [powerMonitoringChartJSON, setPowerMonitoringChartJSON] = useState();
  const [hullPerformance, setHullPerformance] = useState({...chartOptions()});
  const [foulingPenalty, setFoulingPenalty] = useState({});
  const [foulingWaste, setFoulingWaste] = useState({});
  const [requestOnGoing, setRequestOnGoing] = useState({
    powerMonitoringChartJSON: false,
    foulingWaste: false,
    foulingPenalty: false,
    hullPerformance: false,
    useHullPerformanceOuterLoader: true
  });
  const [emptyComponent, setHullShowEmptyComponent] = useState(true);

  // store vars
  const dispatch = useDispatch();
  const monitoringStoreJSX = useSelector(state => state.monitoring);
  const vesselStoreJSX = useSelector(state => state.vessel);

  const reportsComponentsLight = [
    { widgetId: 'foulingWaste',
      prerequisite: { value: true, request: () => {return {method: service.getFuelWasteAmount, params:
              { vesselId: vesselStore().selectedVessel, from: monitoringStore().fromTo.from.valueOf(), to: monitoringStore().fromTo.to.valueOf() }}}, callback: setWasteTones },
      inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getFuelWasteChart, params:
                { payload: foulingWastePayload, vesselId: vesselStore().selectedVessel, fromDate: monitoringStore().fromTo.from.valueOf(), toDate: monitoringStore().fromTo.to.valueOf() }}}}},
      payload: {},
      type: 'text'
    },
    { 
      widgetId: 'foulingPenalty',
      inDashboard: false, 
      vesselIds: [vesselStore().selectedVessel],
      singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getPredictedPowerPenaltyOvertime, params: { vessel: vesselStore().selectedVessel }}}}},
      payload: {},
      type: 'text'
    }
  ]

  const reportsComponents = [
    { 
      widgetId: 'foulingPenalty',
      inDashboard: false,
      vesselIds: [vesselStore().selectedVessel],
      singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getPredictedPowerPenaltyOvertime, params: { vessel: vesselStore().selectedVessel }}}}},
      payload: {},
      type: 'text'
    },
    { widgetId: 'powerMonitoringChartJSON',
        prerequisite: { isReport: true, report: { innerPrerequisite: true,
          widgetId: 'powerPredictedData', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: powerMonitoringPredictedPayload, type: 'basicChart'}, callback: setPowerPredictedData },
        inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: { value: false }, payload: powerMonitoringPayload,  type: 'basicChart',
    },

  ];

  /**This function fetched the selected vessel hull info. */
  const getHullInfo = () => {
    const from = monitoringStore().detailedData.status ? monitoringStore().detailedData.from : monitoringStore().fromTo.from;
    const to = monitoringStore().detailedData.status ? monitoringStore().detailedData.to : monitoringStore().fromTo.to;

    registerWidgetsStore.setFromTo({from, to});
    const reportsComponentsToDisplay = licensing.lightCondition() ? reportsComponentsLight : reportsComponents;

    powerMonitoringPredictedPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
    powerMonitoringPayload.timeGroup = ((monitoringStore().detailedData.status) || (monitoringStore().lessThanADayPeriod.status)) ? 'MINUTE' : 'HOUR';
    
    /**Check if there is cached content for hull info */
    if (!hullStorage.isGlobalStateEmpty()) {
      reportsComponentsToDisplay.map(comp => {
        return reportsResponseMapper(cachedResponsesMapper(comp.widgetId), comp.widgetId)
      });
      return;
    }

    reportsService.registerReports([vesselStore().selectedVessel], reportsComponentsToDisplay, reportsResponseMapper, updateRequestOnGoing, true);
  }

  const updateRequestOnGoing = (key, value) => {
    setRequestOnGoing(currentRequestOnGoing => {
      return {...currentRequestOnGoing, [key]: value}
    })
  }

  const setReportsPlotLineGuides = (data) => {
    let plotObject = { xAxis: {} };
    plotObject.xAxis = Object.assign({}, { plotBands: chartOptions().xAxis.plotBands }, { plotLines: chartOptions().xAxis.plotLines });

    const line = data[0].values.find((obj) => obj.value.length > 0) ? data[0].values.find((obj) => obj.value.length > 0).value : null;

    if (!line) return;
    plotObject.xAxis.plotBands.from = plotObject.xAxis.plotLines[0].value = monitoringStore().detailedData.status
      ? monitoringStore().detailedData.guidesStart
      : null;
    plotObject.xAxis.plotBands.to = plotObject.xAxis.plotLines[1].value = monitoringStore().detailedData.status
      ? monitoringStore().detailedData.guidesEnd
      : null;

    return plotObject;
  }

  const reportsResponseMapper = (data, widgetId) => {
    const mapper = {
      foulingWaste: updateFoulingWaste,
      foulingPenalty: updateFoulingPenaltyOvertime,
      powerMonitoringChartJSON: updatePowerMonitoringJSON,
    }
    return mapper[widgetId](widgetId, data, stateMapper, extraChartsConfigMapper(widgetId), reportsComponents.filter(report => report.widgetId === widgetId)[0]);
  }

  const stateMapper = (key, value) => {
    const mapper = {
      foulingWaste: setFoulingWaste,
      foulingPenalty: setFoulingPenalty,
      powerMonitoringChartJSON: setPowerMonitoringChartJSON,
    }

    return mapper[key](value);
  }

  const cachedResponsesMapper = widgetId => {
    const reportsResponseMapper = {
      foulingWaste: hullStorage.foulingWasteData,
      foulingPenalty: hullStorage.foulingPenalty,
      powerMonitoringChartJSON: hullStorage.powerMonitoringData,
    }
    return reportsResponseMapper[widgetId];
  }

  const extraChartsConfigMapper = widgetId => {
    if(!monitoringStore().detailedData.status) return null;

    const mapper = {
      foulingWaste: null,
      powerMonitoringChartJSON: {
        plotLines: {setReportsPlotLineGuides}
      },
    }

    return mapper[widgetId];
  }

  const updateHullComponents = () => {
    getHullInfo();
  }

  const getTitlesFromWidgetIds = (widgetId) => {
    const idsTitlesMapper = {
      hullPerformance: 'HULL PERFORMANCE',
      hullFoulingPenalty: 'FOULING PENALTY AT',
      powerMonitoringChartJSON: 'POWER MONITORING',
      foulingWaste: 'FOULING WASTE'
    }
    
    return idsTitlesMapper[widgetId];
  }

  const getWidgetsPreferences = () => {
    return {
      className: 'padding-left-right-7',
      layout: [{
        columns: [
          {
            grid: {xs: 12, sm: 12, md: 12, lg: 6, xl: 6},
            components: [
              { 
                // conditionally render the appropriate fouling penalty component (either HullFoulingPenalty or HullFoulingPenaltyOvertime)
                // depending on the response object
                id: 'hullFoulingPenalty',
                title: <PanelHeaderWithTag
                          title={foulingPenalty?.data?.value ? getFoulingPenaltyTitle(foulingPenalty) : getFoulingPenaltyOvertimeTitle()}
                          tag={'AI CALCULATED'}
                      />,
                // note: check first if we have data for the fouling over time widget and if not show the old fouling penalty widget
                // (the highcharts empty state of the old widget will appear in case this widget also does not have data)
                // we use the highcharts emopty state and not the noData component in order for the footer to be shown also in the empty state case
                component: foulingPenalty?.chartData
                  ? HullFoulingPenaltyOvertime
                  : HullFoulingPenalty,
                footer: foulingPenalty?.data?.value
                  ? <HullFoulingPenaltyFooter foulingPenalty={foulingPenalty}/>
                  : <HullFoulingPenaltyOvertimeFooter categories={foulingPenalty?.confidenceLevelCategories}/>,
                data: {},
                style: { height: '444px' },
                props: {} 
              }
            ]
          },
          {
            grid: {xs: 12, sm: 12, md: 12, lg: 6, xl: 6},
            components: [
              { 
                id: 'hullPerformance', 
                title: (!emptyComponent  && (requestOnGoing.useHullPerformanceOuterLoader && requestOnGoing.hullPerformance))
                          ? <Skeleton className='customSkeleton' animation="wave" width={'100%'} height={60} />
                          : <PanelHeaderWithTag 
                              title={getTitlesFromWidgetIds("hullPerformance")} 
                              tag={'AI CALCULATED'} 
                              icon={ 
                                <HullPerformanceHeaderInfoIcon 
                                  isLight={licensing.lightCondition()}
                                  vesselName={vesselUtils.getVesselName(vesselStoreJSX.selectedVessel)}
                                  date={
                                    // if the "TO" date is today, then we want to show the word 'today' instead of the date
                                    moment(monitoringStoreJSX.fromTo.to).utc().isSame(new Date(), 'day') 
                                      ? 'today'
                                      : `on ${moment(monitoringStoreJSX.fromTo.to).utc().format('DD/MM/YYYY')}`
                                  }
                                />
                              }
                            />,
                component: HullPerformance, data: {}, style: { minHeight: '444px' }, props: {} 
              }
            ]
          },
          {
            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
            components: [
              { id: 'foulingWaste', title: <PanelHeaderWithTag title={getTitlesFromWidgetIds('foulingWaste')} tag={'AI CALCULATED'} />,
                component: FoulingWaste, data: {}, style: { height: '442px' }, props: { removeFromLayout: !licensing.lightCondition() } }
            ]
          },
          {
            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
            components: [
              { id: 'powerMonitoringChartJSON', title: <PanelHeaderWithTag title={getTitlesFromWidgetIds('powerMonitoringChartJSON')} tag={'AI CALCULATED'} />,
                component: HullPowerMonitoring, data: {}, style: { height: 'auto' }, props: {removeFromLayout: licensing.lightCondition()} },
            ]
          }
        ]
      }]
    };
  };

  const createLayoutProps = (data, props = getWidgetsPreferences()) => {
    props.layout = props.layout.map(newLayout => {
      newLayout.columns = newLayout.columns.map(newColData => {
        ('components' in newColData) && (newColData.components = newColData.components.filter(comp => !comp.props.removeFromLayout).map(newComponent => {
          if (('id' in newComponent) && !('element' in newComponent)) {
            if (newComponent.id in data) newComponent.props = data[newComponent.id];
          }
          return newComponent;
        }));
        ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
        return newColData;
      });
      return newLayout;
    });
    return props;
  };

  const data = {
    foulingWaste: {
      data: {...foulingWaste, exporting: {...foulingWaste.exporting, filename: getTitlesFromWidgetIds('foulingWaste')}},
      loading: requestOnGoing.foulingWaste,
    },
    hullPerformance: {
      data: {...hullPerformance},
      requestInfo: {
        vesselId: vesselStoreJSX.selectedVessel,
        from: moment(monitoringStoreJSX.detailedData.status ? monitoringStoreJSX.detailedData.from : monitoringStoreJSX.fromTo.from).valueOf(), 
        to: moment(monitoringStoreJSX.detailedData.status ? monitoringStoreJSX.detailedData.to : monitoringStoreJSX.fromTo.to).valueOf()
      },
      setData: setHullPerformance,
      loader: requestOnGoing.hullPerformance,
      useOuterLoader: requestOnGoing.useHullPerformanceOuterLoader,
      setLoader: updateRequestOnGoing,
      emptyComponent: emptyComponent,
      setHullShowEmptyComponent: setHullShowEmptyComponent,
    },
    hullFoulingPenalty: {
      data: foulingPenalty?.data?.value ? foulingPenalty : foulingPenalty?.chartData,
      loading: requestOnGoing.foulingPenalty,
    },
    powerMonitoringChartJSON: {
      highcharts: Highcharts,
      data: {
        ...powerMonitoringChartJSON,
        exporting: {
          ...(powerMonitoringChartJSON?.exporting),
          filename: getTitlesFromWidgetIds('powerMonitoringChartJSON')
        }
      },
      loading: requestOnGoing.powerMonitoringChartJSON,
    }
  }

  useEffect(() => {
    if (!monitoringStore().fromToChanged) return;
    if (!isMounted) return;
   
    //reset fromToChanged redux var in order to trigger the next fromTo dates
    dispatch({ type: 'monitoring/setFromToChanged', payload: false });

    //invalid period if more than 3 months (91 days)
    if(monitoringStore().fromTo.to - monitoringStore().fromTo.from > 7862400000) return;

    // local storage needs to be initialized, since requests need to be made again for detailedData mode
    monitoringUtils.resetLocalMonitoringStores();

    return updateHullComponents();
    // eslint-disable-next-line
  }, [monitoringStoreJSX.fromToChanged]);

  useEffect(() => {
    if (!isMounted) return;

    // local storage needs to be initialized, since requests need to be made again for detailedData mode
    monitoringUtils.resetLocalMonitoringStores();

    if (monitoringStore().detailedData && monitoringStore().detailedData.status) return updateHullComponents();
    // eslint-disable-next-line
  }, [monitoringStoreJSX.detailedData]);

  useEffect(() => {
    if (!isMounted) return;
    if (!monitoringStore().resetLegView) return;

    // local storage needs to be initialized, since requests need to be made again for detailedData mode
    monitoringUtils.resetLocalMonitoringStores();

    dispatch({ type: 'monitoring/setResetLegView', payload: false});

    return updateHullComponents();
    // eslint-disable-next-line
  }, [monitoringStoreJSX.resetLegView]);

  useEffect(() => {
    isMounted = true;
    const controller = new AbortController();
    GlobalUtilities.signal = controller.signal;

    if (!monitoringStore().fromTo.from && !monitoringStore().fromTo.to) return;
    
    updateHullComponents();

    return () => {
      controller.abort();
      isMounted = false;
      GlobalUtilities.signal = null;
    };
    // eslint-disable-next-line
  }, []);

  return (
      <div className="hull-page">
        <div className="hull-page__widgets">
          <Layout {...createLayoutProps(data)} />
        </div>
      </div>
  );
}

export default Hull;
