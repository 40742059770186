import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ArbitraryPvFvUtils from 'widgets/vesselData/arbitraryPvFvUtils';
import GlobalUtilities from 'Utilities/global';
import AutocompleteRenderedOption from 'components/autocomplete/autocompleteRenderedOption';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ReactComponent as SearchIcon } from 'assets/img/app/global-imgs/searchIcon.svg';
import PropTypes from 'prop-types';

let typedNumberInRange = null;

const exactCarbonEmissionMessageOption = {
    text: 'To enter an exact carbon emission factor, please enter numbers between 1 and 4.',
    optionType: 'disabledMessageOption'
};

const ERROR_TEXTS = {
    notExist: 'This fuel type does not exist',
    canNotEmpty: 'Type of Fuel cannot be empty',
    outOfRange: 'This emission factor is invalid'
};

const TypeOfFuelAutoComplete = ({ fuelOilSelectedOption, setFuelOilSelectedOption, disabledAllOptions, setDisabledAllOptions, updateChart }) => {
    // options state var is used in order for the options to be updated when user is typing
    // the options are updated with a deep clone of the same values, in order for the selected option to be able to be re-selected
    const [options, setOptions] = useState(ArbitraryPvFvUtils.fuelOilOptions)
    const [error, setError] = useState({
        showError: false,
        errorText: ''
    });

    // when an error in the autocomplete field exists, we want to disable all the options (drafts, wind speeds, wind directions)
    // so that the user can not click them without choosing a fuel type option
    useEffect(() => {
        if(error.showError !== disabledAllOptions) setDisabledAllOptions(error.showError);
        // eslint-disable-next-line
    }, [error.showError]);

    const filterOptions = { 
        stringify: option => option?.text,
        ignoreCase: true
    };

    const customFilterOptionsFunction = (options, state) => {
        // this creates the default mui filter function (filterOptions can be passed as param)
        const defaultFilterOptionsFunction = createFilterOptions(filterOptions);
        // execute the default function and get the filtered options
        const defaultFilterOptions = defaultFilterOptionsFunction(options, state);
    
        const finalFilteredOptions = [
            exactCarbonEmissionMessageOption, // always show the exact carbon emission message as the first option
            // show the error message in the dropdown if it exists only in case there are not any matching options (either a number in range or a fuel type option)
            ...(error.showError && (error.errorText === ERROR_TEXTS.notExist || error.errorText === ERROR_TEXTS.outOfRange) && !defaultFilterOptions?.length && !typedNumberInRange) ? [{
                text: error.errorText,
                optionType: 'messageOption'
            }] : [],
            ...(typedNumberInRange) ? [{ text: typedNumberInRange, optionType: 'basicOption', type: typedNumberInRange }] : [],
            ...(!typedNumberInRange) ? defaultFilterOptions : []
        ];
    
        return finalFilteredOptions;
    };

    const onInputChange = (e, newInputValue, reason) => {
        // case where user types or deletes something
        // everytime a user is typing or deleting, an error will be shown
        if(reason === 'input') {
            setOptions(ArbitraryPvFvUtils.fuelOilOptions.map(option => ({...option})));
            
            if(typedNumberInRange) typedNumberInRange = null;

            // if user has deleted everything from the input, then show the appropriate 'can not be empty' error
            if(!newInputValue?.length) {
                return setError({
                    showError: true,
                    errorText: ERROR_TEXTS.canNotEmpty
                });
            }

            const typedInputExistsInOptions = ArbitraryPvFvUtils.fuelOilOptions.find(option => (option?.optionType === 'basicOption') && (option?.text.toLowerCase().includes(newInputValue.toLowerCase())));
            
            if(!typedInputExistsInOptions) {
                const inputIsANumber = GlobalUtilities.stringIsNumeric(newInputValue);
                
                if(inputIsANumber && parseFloat(newInputValue) >= 1 && parseFloat(newInputValue) <= 4) typedNumberInRange = newInputValue;

                // show the appropriate error depending on if the input is a number or not
                return setError({
                    showError: true,
                    errorText: inputIsANumber ? ERROR_TEXTS.outOfRange : ERROR_TEXTS.notExist
                });
            }

            // if none of the previous errors have been shown, then show the 'notExist' error
            setError({
                showError: true,
                errorText: ERROR_TEXTS.notExist
            });
        }
    };

    const onChange = (e, newInputValueObj, reason) => {
        // if user selects an option from the dropdown
        if(reason === 'select-option') {
            // in case the user clicks 'enter' without selecting a valid option, return
            if(newInputValueObj.text === '') return;

            const fuelOilSelectedOptionTemp = ArbitraryPvFvUtils.fuelOilOptions.find(option => option?.text === newInputValueObj.text);

            // the valueToUpdate will either be a valid numeric value (range 1 - 4) or a fuel oil type option
            const valueToUpdate = GlobalUtilities.stringIsNumeric(newInputValueObj.text)
                ? newInputValueObj
                : fuelOilSelectedOptionTemp
                ? fuelOilSelectedOptionTemp
                : null;

            if(!valueToUpdate) return;
            
            setFuelOilSelectedOption(valueToUpdate);

            updateChart(valueToUpdate);

            // when user selects an option from the dropdown, then remove any error if it exists
            if(error.showError) setError({
                showError: false,
                errorText: ''
            });
        }
    };

    return(
        <div className='typeOfFuelOptions-autocomplete ds-input marg-b-30 Mui-error'>
            <Autocomplete
                freeSolo
                disableClearable
                openOnFocus
                clearOnEscape={false}
                forcePopupIcon={false}
                classes={{ 'popper': 'typeOfFuelOptionsDropdown' }}
                options={options}
                filterOptions={customFilterOptionsFunction}
                getOptionLabel={option => (option && option?.text) || ''}
                getOptionSelected={(option, value) => option?.text !== value?.text}
                getOptionDisabled={option => option?.optionType !== 'basicOption'} // the options that just show a message to the user are disabled
                value={fuelOilSelectedOption}
                onChange={onChange}
                onInputChange={onInputChange}
                renderOption={(option) => 
                    <AutocompleteRenderedOption 
                        optionType={option?.optionType}
                        content={option?.text}
                    />
                }
                renderInput={ (params) => {
                    return <TextField
                        {...params}
                        onMouseDownCapture={(e) => e.stopPropagation()}
                        label='Type of Fuel'
                        variant='standard' 
                        fullWidth 
                        error={error.showError}
                        helperText={error.errorText}
                        InputProps={{ 
                            ...params.InputProps, 
                            endAdornment: <InputAdornment position="start"> <SearchIcon className='svg-path'/> </InputAdornment>
                        }}
                    />
                }}
            />
        </div>
    )
};

TypeOfFuelAutoComplete.propTypes = {
    fuelOilSelectedOption: PropTypes.shape({}),
    setFuelOilSelectedOption: PropTypes.func,
    updateChart: PropTypes.func,
    disabledAllOptions: PropTypes.bool,
    setDisabledAllOptions: PropTypes.func
}

export default TypeOfFuelAutoComplete;