import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import classnames from 'classnames';
import moment from 'moment';
import Utils from 'Utilities/global';
import Modal from 'components/modals/eventsModal';
import Toast from 'components/calendar/toastMessage';
import CustomEvent from 'components/calendar/customEvent'
import Popover from 'components/popover/popover'
import EventsUtilities from 'Utilities/events';
import { vesselStore, calendarStore, eventsUtils, calendarUtils } from 'common/store/storeUtils'
import { useSelector, useDispatch } from 'react-redux';

//   how to use calendar api example:
//   let calendarApi = this.calendarComponentRef.current.getApi()
//   calendarApi.gotoDate('2000-01-01') // call a method on the Calendar object
let calendarComponentRef = React.createRef(), calendarApi = null, undoDelete = false;

const Calendar = () => {
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [canEdit, setCanEdit] = useState(false)
    const [vEvent, setVEvent] = useState(null)
    const [toastOpen, setToastOpen] = useState(false)
    const [preventClickEvent, setPreventClickEvent] = useState(false);

    // store vars
    const vesselGroupStoreJSX = useSelector(state => state.vesselGroup);
    const vesselStoreJSX = useSelector(state => state.vessel);
    const eventsStoreJSX = useSelector(state => state.events);
    const calendarStoreJSX = useSelector(state => state.calendar);
    const userStoreJSX = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        // if initially no vessel group is selected, then select the user's default group
        if(vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id) dispatch({ type: 'vesselGroup/setSelectedVesselGroup', payload: userStoreJSX.user.defaultGroup });

        calendarApi = calendarComponentRef.current.getApi();
        calendarUtils.getEvents(dispatch, calendarStore().calendarFromTo.from, calendarStore().calendarFromTo.to);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!vesselGroupStoreJSX.changeVesselGroup) return;

        calendarUtils.getEvents(dispatch, calendarStore().calendarFromTo.from, calendarStore().calendarFromTo.to);
        
        popoverOpen && popoverClose();
        // eslint-disable-next-line
    }, [vesselGroupStoreJSX.changeVesselGroup]);

    useEffect(() => {
        if (vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId || !vesselStore().selectedVessel) return;

        calendarUtils.setCalendarFilteredCriteria(dispatch, null);
        
        popoverOpen && popoverClose();
        // eslint-disable-next-line
    }, [vesselStoreJSX.selectedVessel]);

    useEffect(() => {
        if (!eventsStoreJSX.eventsUpdated) return;
        
        dispatch({ type: 'events/setEventsUpdated', payload: false });
        
        calendarUtils.setCalendarEvents(dispatch, eventsStoreJSX.events, true);
        // eslint-disable-next-line
    }, [eventsStoreJSX.eventsUpdated]);

    // calendar methods
    const handleDateClick = (arg) => {
        popoverClose();
        dispatch({ 
            type: 'calendar/setCalendarCurrentEvent', 
            payload: {
                modalDate: arg.date, 
                modalShow: true
            } 
        });
    };

    // popover methods
    const popoverClose = () => {
        setPopoverOpen(false);
        dispatch({ type: 'calendar/setCalendarCurrentEvent', payload: {} });
    }

    const popoverDelete = () => {
        const clickedElement = anchorEl;
        clickedElement.parentElement.hidden = true;
        setPopoverOpen(false);
        setToastOpen(true);
        setPreventClickEvent(true);

        setTimeout(() => {
            toastCloseHandler();
            !undoDelete && eventsUtils.eventDeleteAction(dispatch, {event: eventsUtils.updateDeleteObject(vEvent)}).then(r => !r.error);
            undoDelete = false;
        }, 2000);
    }

    const popoverEdit = () => {
        const vEventTmp = {...vEvent, id: vEvent.id};
        setPopoverOpen(false);
        if (calendarUtils.canEditEvent(vEventTmp)) {
            dispatch({ type: 'calendar/setCalendarCurrentEvent', payload: {editEvent: vEventTmp} });
        }
    }

    // Toaster message methods
    const toastCloseHandler = () => {
        setToastOpen(false);
        setPreventClickEvent(false);
        popoverClose();
    }
    const toastOpenHandler = () => {
        setToastOpen(true)
    }
    const toastUndoHandler = () => {
        const clickedElement = anchorEl;
        clickedElement.parentElement.hidden = false;
        undoDelete = true;
        setToastOpen(false);
        setPreventClickEvent(false);
    }

    const handleEventClick = eventClickInfo => {
        const vEventTmp = {...eventClickInfo.event.extendedProps, id: eventClickInfo.event.id};
        EventsUtilities.selectedVessel = vEventTmp.vesselId;
        setCanEdit(calendarUtils.canEditEvent(vEventTmp))
        setVEvent(vEventTmp);
        setAnchorEl(eventClickInfo.jsEvent.target.offsetParent);
        setPopoverOpen(!popoverOpen);
    };

    // calendar events display
    const legendClickHandler =  legend => {
        document.querySelector("." + legend.class).classList.toggle("disabled");
        calendarUtils.setCalendarFilteredCriteria(dispatch, legend.id);
    };

    const legendsClasses = (legendClass, legendId) => {
        return classnames("calendar-legends__legend flex-centered-start " + legendClass,
            {'disabled': calendarStore().calendarFilteredCriteria.indexOf(legendId) !== -1});
    };

    const handlePreviousCalendarClick = () => {
        popoverClose();
        calendarUtils.calendarComponentPrevMonthHandler(dispatch);
        calendarApi.prev();
    }

    const handleNextCalendarClick = () => {
        popoverClose();
        calendarUtils.calendarComponentNextMonthHandler(dispatch);
        calendarApi.next();
    }

    const eventRender = (e) => {
        return <CustomEvent data={e.event.extendedProps}/>;
    }

    return (
        <div className="calendar-component">
            <div className="fc-buttons-container">
                <div className="fc-button-group flex-centered">
                    <button type="button"
                            onClick={handlePreviousCalendarClick}
                            className="main-cta-navigational navigationalLeft"
                            aria-label="prev">
                            <span className="fc-icon fc-icon-chevron-left">
                            </span>
                    </button>

                    <button type="button"
                            onClick={handleNextCalendarClick}
                            className="main-cta-navigational navigationalRight"
                            aria-label="next">
                            <span className="fc-icon fc-icon-chevron-right">
                            </span>
                    </button>
                </div>
            </div>

            <div className="calendar-legends flex-centered-start">
                {calendarUtils.Filters().map(legend => (
                    <div key={legend.title}
                         className={legendsClasses(legend.class, legend.id)}
                         onClick={() => legendClickHandler(legend)}
                    >
                        <div style={legend.bgcolor}/>
                        {legend.title}
                    </div>
                ))}
            </div>

            {/* initialDate={moment('2017-5-1').utc().toDate()} */}
            <FullCalendar headerToolbar={{center: 'title', right: 'today', left: null}}
                          events={calendarStoreJSX.calendarFilteredEvents}
                          ref={calendarComponentRef}
                          initialView="dayGridMonth"
                          initialDate={moment(Utils.nowOrDemo()).toDate()}
                          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                          eventClick={!preventClickEvent && handleEventClick}
                          dateClick={handleDateClick}
                          eventContent={eventRender}
            />

            {popoverOpen &&
            <Popover popoverOpen={popoverOpen}
                     popoverClose={popoverClose}
                     popoverEdit={popoverEdit}
                     popoverDelete={popoverDelete}
                     anchorEl={anchorEl}
                     canEdit={canEdit}
                     vEvent={vEvent}/>}

            <Modal show={calendarStoreJSX.calendarCurrentEvent.modalShow}
                   onHide={() => dispatch({ type: 'calendar/setCalendarCurrentEvent', payload: {} })}
                   editEvent={calendarStoreJSX.calendarCurrentEvent.editEvent}
                   onSave={eventsUtils.eventSaveAction}
                   onDelete={eventsUtils.eventDeleteAction}
                   title={calendarStoreJSX.calendarCurrentEvent.modalTitle}
                   eventObj={calendarStoreJSX.calendarCurrentEvent.eventObj}
                   eventVessel={calendarStoreJSX.calendarCurrentEvent.modalEventVessel}
                   dateClicked={calendarStoreJSX.calendarCurrentEvent.modalDate}/>

            {toastOpen &&
            <Toast
                data={vEvent}
                toastOpen={toastOpenHandler}
                toastUndo={toastUndoHandler}
                toastClose={toastCloseHandler}/>}

        </div>
    );

}

export default Calendar;