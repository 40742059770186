import Highcharts from "highcharts/highstock";
import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import {userStore} from 'common/store/storeUtils';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/windbarb")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts); 

const syncChartsOptions = (xAxisOnlyDays) => {

    (userStore().user?.theme !== 'ds-white') ? Highcharts.setOptions(Highcharts.darkTheme)
        : Highcharts.setOptions(Highcharts.whiteTheme);

    let options = {  

        colors: (userStore().user?.theme !== 'ds-white') ? [
            '#3F6FFF', '#B1A965', '#9A3BA5', '#68A4A2',
            '#E9627D','#DF9825', '#0289BA', '#B88986',
            '#50e3c2', '#ff8b84', '#275CE6', '#a51080' 
        ] : [
            '#3366FF', '#9F985B', '#772781', '#68A4A2',
            '#E63C5E','#E89E27', '#017EAC', '#B48683',
            '#50e3c2', '#ff8b84', '#275CE6', '#a51080'
        ],

        chart: { 
            height: 768,
            marginTop: 50,
            // marginRight: 30,
            // marginLeft: 90,
        },

        rangeSelector: {
            enabled: false
        },

        xAxis: {
            title: { 
                text: 'Time (UTC)',
                offset: 26,
            },
            // startOnTick: true,
            // endOnTick: true,
            offset: 14,
            tickLength: 0,
            type: 'datetime',
            labels: {
                align: 'center',
                allowOverlap: false,
                step: 2,
                formatter: function() {
                    if(xAxisOnlyDays) return Highcharts.dateFormat('%d/%m', this.value);
                    return Highcharts.dateFormat('%d/%m %H:%M', this.value);
                }
            },
            scrollbar: {},
            plotLines: []
        },

        tooltip: {
            headerFormat: '<div class="flex-centered-col" style="background-color: transparent">'+
                '<div class="highcharts-fonts"><b>'+
                    '{series.userOptions.tootltipXTitle}{point.x:%d/%m %H:%M}&nbsp;{series.userOptions.titleSuffix}</b></div>'+
                '<div class="flex-centered-col tooltip-values">',
            pointFormat:
                '<div class="flex-space-between highcharts-fonts" id="{series.name}">'+
                    '<span class="tooltip-circle" style="background-color: {point.color}"></span>'+
                    '<div>{series.userOptions.tooltipTitle}: &nbsp;</div>'+
                    '<div><b>{point.y} {point.z}</b></div>'+
                '</div>',
            footerFormat: '</div></div>',
            animation: false,
            useHTML: true,
            shared: false,
            split: true,
            padding: 8,
        },
        
        plotOptions: {
            line: {
                dataGrouping: {
                    enabled: false
                },
                states: {
                    hover: { animation: 0 }
                }
            },
            column: {
                dataGrouping: {
                    enabled: false
                }
            },
            series: {
                cursor: 'pointer',
                label: { connectorAllowed: false },
                marker: {enabled: false, symbol: 'circle'},
                connectNulls: false,
                turboThreshold: 90000000,
                borderRadius: 2
            },
        },

        exporting: {
            enabled: true,
            buttons: {contextButton: {}},
            chartOptions: {
                title: {},
                chart: {backgroundColor: ThemeColorsForCharts()['platform_background']}
            }
        },

        navigator: {},
        
        scrollbar: {},

        credits: { enabled: false },
    }

    return options;
}

export default syncChartsOptions;
