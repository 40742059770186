import keys from 'lodash/keys';
import classnames from "classnames";
import sortBy from 'lodash/sortBy';
import {userUtils, dashboardUtils} from 'common/store/storeUtils';

export const widgetLibraryUtils = {

    orderListMapper: {
        "HULL": "Hull",
        "ME": "Main Engine",
        "GE": "Generator Engine",
        "REPORTS": "Reports",
        "EFFICIENCY_RATINGS": "Efficiency Ratings",
        "BUNKERS_CONSUMPTION": "Bunkers Consumption",
        "BUNKERS_ROB": "Bunkers ROB",
        "BUNKERS_SUPPLIED": "Bunkers Supplied",
        "CO2_EMISSIONS": "CO2 emissions",
        "NAVIGATION_STATUS": "Navigation Status",
        "NAVIGATION_DATA": "Navigation Data",
        "EVENTS_LOG": "Events Log",
        "MAP": "Map",
        "BO": "Boilers",
    },

    timespansOrder: {
        "HOURLY": 1,
        "DAILY": 2,
        "WEEKLY": 3,
        "MONTHLY": 4,
        "LEG": 5
    },

    entitiesOrder: {
        "VESSEL": 1,
        "GROUP": 2,
        "COMPANY": 3,
    },

    sortByGroup: (object) => {
        let tempObj = {};
        keys(object).sort().forEach(key => {
            tempObj[key] = object[key];
        })
        return tempObj;
    },

    hasHigherTimespan: (widget1, widget2) => {
        return widgetLibraryUtils.timespansOrder[widget1?.timespan[0]] < widgetLibraryUtils.timespansOrder[widget2?.timespan[0]] ? -1 : 1;
    },

    hasHigherEntity: (widget1, widget2) => {
        return widgetLibraryUtils.entitiesOrder[widget1?.level] < widgetLibraryUtils.entitiesOrder[widget2?.level] ? -1 : widgetLibraryUtils.entitiesOrder[widget1?.level] > widgetLibraryUtils.entitiesOrder[widget2?.level] ? 1 : 0;
    },

    hasHigherOrder: (widget1, widget2) => { // first criterion of ordering is by entity (vessel > group > company), second criterion is by timespan (hourly, daily, weekly, monthly, leg)
        const hasHigherEntity = widgetLibraryUtils.hasHigherEntity(widget1, widget2);
        const hasHigherTimespan = widgetLibraryUtils.hasHigherTimespan(widget1, widget2);
        return hasHigherEntity !== 0 ? hasHigherEntity : hasHigherTimespan;
    },

    sortInsideGroup: (widgetGroups) => {
        keys(widgetGroups).forEach(groupKey => {
            widgetGroups[groupKey].sort((widget1, widget2) => {
                return widget1.name < widget2.name ? -1 : widget1.name > widget2.name ? 1 : widgetLibraryUtils.hasHigherOrder(widget1, widget2);
            });     
        });   
    },
    
    filterWidgetsByUserLicense: (widgets) => {
        const _widgets = Object.assign([], widgets);

        switch (userUtils.getLicenseSupported()) {
            case 'ALL':
                dashboardUtils.dashboardProperties.showEnterpriseIndication = true;
                return _widgets;
            case 'PREMIUM':
                return _widgets.filter(widget => widget.license !== 'LIGHT');
            case 'LIGHT':
                return _widgets.filter(widget => widget.license !== 'PREMIUM');
            default:
                return _widgets;
        }
    },

    normalizeResponse: (response) => {

        let _response = Object.assign([], response), normalizedObject = {};
        let currentGroup = null, tempArr = [];
        _response = widgetLibraryUtils.filterWidgetsByUserLicense(_response);

        sortBy(_response, [function (o) {
            return o.group
        }]).forEach((element, index) => {

            // store first category
            (index === 0) && (currentGroup = element.group);

            // loop through rest of object, and create a group top level object
            if (currentGroup === element.group) {
                tempArr.push(element);
            } else {
                normalizedObject[currentGroup] = tempArr;
                currentGroup = element.group;
                tempArr = [];
                tempArr.push(element);
            }

            // For the last group, add them also to the returned object
            if (index === (_response.length - 1)) {
                normalizedObject[currentGroup] = tempArr;
                currentGroup = element.group;
            }

        })

        const widgetsOrderedByGroup = widgetLibraryUtils.sortByGroup(normalizedObject);
        widgetLibraryUtils.sortInsideGroup(widgetsOrderedByGroup);
        return widgetsOrderedByGroup;
    },

    timespanHandler: (value) => {
        const classes = classnames(`timeInfoLabel`,
            {"daily": value === "DAILY"},
            {"weekly": value === "WEEKLY"},
            {"monthly": value === "MONTHLY"},
            {"leg": value === "LEG"},
        )

        const labelMapper = {
            "DAILY": "Daily",
            "WEEKLY": "Weekly",
            "MONTHLY": "Monthly",
            "LEG": "Leg",
        }

        return {label: labelMapper[value], classes};
    },

    displayLevel: value => {
        const mapper = {
            "GROUP": "Fleet Level",
            "VESSEL": "Vessel Level",
        }
        return mapper[value];
    }
}
